import { Box, Flex, Grid, GridItem, Heading, Icon, Text, useToast } from '@chakra-ui/react';
import Skeleton from 'react-loading-skeleton';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useAppContext, useAuthContext } from 'Context';
import useDocumentTitle from 'Helpers/useDocumentTitle';
import React, { useEffect, useState } from 'react';
import { RefreshCw } from 'react-feather';
import ExpectedInputDemandTable from './Components/ExpectedInputDemandTable';
import LimaToast from 'Components/Toast/LimaToast';
import useProductionCategoryQuery from 'Helpers/useProductionCategoryQuery';
import { varietyIsValid } from 'Helpers/helpers';
import { ProductionCategory } from 'Context/types';

const ProductionPlan = () => {
  const { trackPageView } = useMatomo();
  const { farm, variety } = useAppContext();
  const [lastUpdatedAt, setLastUpdatedAt] = useState('');
  const [expectedDemandTableNotSaved, setExpectedDemandTableNotSaved] = useState(false);
  const {
    functions: { getToken },
    variables: { user }
  } = useAuthContext();
  const toast = useToast();
  // Track page view
  useDocumentTitle('Production Plan | Lima Asili');

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const token = getToken();
  const actualsToastId = 'production-plan-save-toast';

  function handleTableUpdated(newValue: boolean) {
    setExpectedDemandTableNotSaved(newValue);
    // display unsaved changes toast
    if (!toast.isActive(actualsToastId) && newValue) {
      toast({
        id: actualsToastId,
        position: 'top-right',
        render: () => (
          <LimaToast
            status="warning"
            message={`Save before leaving the Production Plan page to prevent loss of the changes.`}
            marginTop="8vh"
            marginRight="3vw"
          />
        ),
        duration: null,
        isClosable: true
      });
    }
  }

  const [productionCategories, setProductionCategories] = useState<ProductionCategory[]>([]);
  const [loadingProductionCategories, setLoadingProductionCategories] = useState(false);

  const productionCategoriesQuery = useProductionCategoryQuery({
    currentUserEmail: user?.email,
    token: token,
    tenantHeader: farm?.organization?.internal_name ?? '',
    varietyId: varietyIsValid(farm, variety) && variety !== null ? variety.id : null
  });

  useEffect(() => {
    if (!productionCategoriesQuery.isLoading && productionCategoriesQuery.data) {
      setProductionCategories(productionCategoriesQuery.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionCategoriesQuery.data]);

  useEffect(() => {
    setLoadingProductionCategories(productionCategoriesQuery.isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionCategoriesQuery.isLoading]);

  useEffect(() => {
    // clear toast when component unmounts
    return () => {
      toast.closeAll();
    };
  });

  return (
    <Box
      width="100%"
      backgroundColor="white"
      boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
      padding={6}
      borderRadius={8}
      height={'85vh'}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Box mb={6}>
          <Heading
            data-cy="production-plan-heading"
            as="h4"
            mb="2"
            fontSize="1.35rem"
            fontWeight="normal"
          >
            Production Plan
          </Heading>
          <Text as="p">
            {farm ? (
              `${farm?.external_name}, ${farm?.city}: ${variety?.external_name}`
            ) : (
              <Skeleton count={1} />
            )}
          </Text>
        </Box>

        {lastUpdatedAt !== '' && (
          <Grid templateColumns="repeat(9, 1fr)" gap={0}>
            <GridItem colSpan={1}>
              <Icon as={RefreshCw} stroke={'info.500'} w={5} h={5} mr={2} />
            </GridItem>
            <GridItem colSpan={8}>
              <Text color={'info.500'}>Last updated at {lastUpdatedAt}</Text>
            </GridItem>
          </Grid>
        )}
      </Flex>
      {variety?.id && farm?.id && (
        <>
          {!loadingProductionCategories ? (
            <>
              {productionCategories.length > 0 ? (
                <ExpectedInputDemandTable
                  farm={farm}
                  variety={variety}
                  token={token}
                  handleHasUnsavedEdits={handleTableUpdated}
                  handleUpdatedAt={setLastUpdatedAt}
                  expectedDemandTableNotSaved={expectedDemandTableNotSaved}
                  productionCategories={productionCategories}
                />
              ) : (
                <Text>No production categories found for this variety.</Text>
              )}
            </>
          ) : (
            <Skeleton count={10} />
          )}
        </>
      )}
    </Box>
  );
};

export default ProductionPlan;
