import React from 'react';
import { Box, GridItem, Grid, Icon, Text } from '@chakra-ui/react';
import { ArrowRight } from 'react-feather';
import { separateComma } from 'Helpers/helpers';
import { useGraphContext } from 'Context/GraphContext';
import { useAppContext } from 'Context';
import { Circle } from 'react-feather';

const CustomTooltip = (props: any) => {
  const { duration, selectedYear, gradesAndRejectsTags, totalsDuration } = useGraphContext();
  const { variety } = useAppContext();
  const {
    data: {
      indexValue,
      id,
      data: { predicted_stems, actual_stems, actual_grading, predicted_grading, ...rest }
    }
  } = props;
  const basics = ['weeks_out_1', 'weeks_out_2', 'days_out_7', 'days_out_14'];
  const getTitle = () => {
    if (id === 'actual_stems') return 'Actual Production';
    if (id === 'predicted_stems') return 'Forecast Production';
    if (id === 'weeks_out_1' || id === 'days_out_7') return '1 Week Forecast';
    if (id === 'weeks_out_2' || id === 'days_out_14') return '2 Weeks Forecast';
    return 'Total Stems';
  };
  const getStems = () => {
    if (id === 'actual_stems') return actual_stems;
    if (id === 'predicted_stems') return predicted_stems;
    if (basics.includes(id)) return rest?.[id];
    // default response for grades and rejects
    return props.data.data.total;
  };
  const getGrades = () => {
    if (id === 'actual_stems') return actual_grading;
    if (id === 'predicted_stems') return predicted_grading;
    if (basics.includes(id)) return rest?.[`${id}_grading`];
    // default response
    const active = gradesAndRejectsTags
      ?.filter((el: any) => el.active && el.id)
      .map((el: any) => el.id);
    const newObj: { [key: string]: any } = {};
    active.forEach((tag: any) => {
      newObj[tag] = props.data.data.staticData[tag];
    });
    return newObj;
  };
  const getIndexName = () => {
    const month_names = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const month_names_short = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    if (duration == 'monthly') {
      const ind = month_names_short.findIndex((el: any) => el == indexValue);
      if (ind != -1) return month_names[ind];
    } else return indexValue;
  };
  const getBorderColor = (): string => {
    const colors = ['#626262', '#00B8FF', '#83DCFF', '#C9D2DA'];
    if (id === 'actual_stems') return colors[0];
    if (id === 'predicted_stems') return colors[1];
    if (id === 'weeks_out_1' || id === 'days_out_7') return colors[2];
    if (id === 'weeks_out_2' || id === 'days_out_14') return colors[3];
    else return '';
  };
  const getIconColor = (key: any): string => {
    const found = gradesAndRejectsTags.find((tag: any) => tag.id == key)?.fill;
    return found ?? '';
  };
  const hasIcon = (): boolean => {
    // helps us distinguish between tooltip for actuals vs forecasts and grades vs rejects
    return Object.keys(props.data.data)?.includes('staticData');
  };
  const keyHasValue = (key: any): any => {
    // this only works if the key matches the elements id
    const found = variety?.production_categories?.find((el: any) => el.id == key);
    return found ? found.external_name : undefined;
  };

  return (
    <Box
      style={{
        padding: 12,
        background: '#ffffff',
        boxShadow: '0px 1px 6px rgba(12, 12, 13, 0.15)',
        textAlign: 'center',
        borderRadius: '4px',
        border: `2px solid`,
        borderColor: `${getBorderColor()}`
      }}
    >
      <Text
        as="p"
        textAlign={'center'}
        color={'dark.coolGray.500'}
        style={{
          fontSize: '16px',
          marginBottom: '0.75rem'
        }}
        className={'readex-font'}
      >
        {hasIcon()
          ? totalsDuration == 'weekly'
            ? 'Week '
            : ''
          : duration == 'weekly'
          ? 'Week '
          : ''}
        {getIndexName()}, {selectedYear}
      </Text>
      <Text as="p" textAlign={'center'} color={'dark.coolGray.500'}>
        {getTitle()}
      </Text>
      <Text as="p" color={'primary.500'} fontSize={'18px'} className={'readex-font'} mb="0.5rem">
        {separateComma(getStems() as number)}
      </Text>
      <Grid templateColumns="repeat(1, 1fr)" gap={0} alignItems="left">
        {/* Grade split breakdown */}
        {Object.keys(getGrades()).map((key) =>
          keyHasValue(key) != undefined ? (
            <GridItem key={key} p={0.5}>
              <Text as="p" color={'dark.coolGray.500'} textAlign={'left'} display="flex">
                {hasIcon() && (
                  <Icon
                    as={Circle}
                    fill={getIconColor(key)}
                    color="transparent"
                    width={'16px'}
                    height={'16px'}
                    alignSelf={'center'}
                    my="auto"
                    justifySelf={'center'}
                    mr="0.25rem"
                  />
                )}
                {keyHasValue(key) + ':'}&nbsp;
                <Text as="span" color={'dark.coolGray.600'}>
                  {separateComma(getGrades()[key] as number) + ' Stems'}
                </Text>
                <Text as="span" color={'primary.500'}>
                  <Icon as={ArrowRight} w={4} h={4} stroke={'primary.500'} ml={2} />{' '}
                  {getStems() == 0 ? 0 : Math.round((getGrades()[key] / getStems()) * 100)}%
                </Text>
              </Text>
            </GridItem>
          ) : (
            ''
          )
        )}
        {Object.keys(getGrades()).map((key) => (
          <Box key={key}>
            {key == 'rejected' && (
              <GridItem p={0.5}>
                <Text as="p" color={'dark.coolGray.500'} textAlign={'left'} display="flex">
                  {hasIcon() && (
                    <Icon
                      as={Circle}
                      fill={getIconColor(key)}
                      color="transparent"
                      width={'16px'}
                      height={'16px'}
                      alignSelf={'center'}
                      my="auto"
                      justifySelf={'center'}
                      mr="0.25rem"
                    />
                  )}
                  {'Rejected:'}&nbsp;
                  <Text as="span" color={'dark.coolGray.600'}>
                    {separateComma(getGrades()[key] as number) + ' Stems'}
                  </Text>
                  <Text as="span" color={'primary.500'}>
                    <Icon as={ArrowRight} w={4} h={4} stroke={'primary.500'} ml={2} />{' '}
                    {getStems() == 0 ? 0 : Math.round((getGrades()[key] / getStems()) * 100)}%
                  </Text>
                </Text>
              </GridItem>
            )}
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default CustomTooltip;
