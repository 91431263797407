import React, { useDeferredValue, useEffect, useState } from 'react';
import {
  Checkbox,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Box,
  Flex,
  Icon,
  Input,
  InputLeftElement,
  Button as CKButton
} from '@chakra-ui/react';
import { Button } from 'Components';
import { ChevronDown, Map, Grid, Search } from 'react-feather';
import { TriangleDownIcon } from '@chakra-ui/icons';

type FilterDropdownProps = {
  items: any[];
  label: string;
  multi?: boolean;
  name?: string;
  showRadio?: boolean;
  showCheckbox?: boolean;
  selectedItems?: any[];
  onSelectFilter: (selection: string | string[]) => void;
  hasError?: boolean;
  onBlur: any;
  clearSelection: boolean;
  uniqueID: string; //this is the object param you want to be returned on submit i.e if you want an array of ids then pass 'id'
  displayParam: string; //this is the object param you want to use to filter and display as the label
  dataCy?: string;
  zIndex?: number;
  toggleAll?: boolean;
  searchable?: boolean; //TO DO: make search param a function
  onMouseLeave?: any;
  showSubmit?: boolean; //make sure to pass an onSubmit function for this to work
  onSubmit?: (selection: string | string[]) => void;
  buttonHeight?: string | number | undefined;
  extraFragment?: any;
  width?: string;
};

export default function MultiSelectDropdown({
  items,
  label,
  multi,
  selectedItems,
  onSelectFilter,
  hasError,
  onBlur,
  clearSelection,
  dataCy,
  name,
  zIndex,
  toggleAll,
  searchable,
  onMouseLeave,
  showSubmit,
  onSubmit,
  buttonHeight,
  extraFragment,
  uniqueID,
  displayParam,
  width
}: FilterDropdownProps): JSX.Element {
  const [selected, setSelected] = useState<string[]>([]);
  const [isInitial, setIsInitial] = useState(true);
  const [isClicked, setIsClicked] = useState<boolean>();
  const [mouseOut, setMouseOut] = useState<boolean>();
  const [allowSearch, setAllowSearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const deferredSearchValue = useDeferredValue(searchValue);
  const [filteredItems, setFilteredItems] = React.useState<any[]>(items);
  const onSelect = (selectedIndex: any) => {
    let sel: string[] = [];
    if (selected.includes(selectedIndex)) {
      sel = selected.filter((selection) => selection != selectedIndex);
    } else {
      sel = [...selected, selectedIndex];
    }
    setSelected(sel);
  };
  const getLabel = (id: string) => {
    return items.find((el: any) => el[uniqueID] == id)?.[displayParam];
  };
  const getBorderColor = () => {
    if (hasError) return '#db414e';
    else if (name == 'harvest-by-field') return 'primary.500';
    return 'dark.coolGray.200';
  };
  const getSpanColor = () => {
    if (name == 'harvest-by-field') return 'primary.500';
    return `${selected?.length ? '#565857' : 'dark.coolGray.300'}`;
  };
  const getWidth = () => {
    if (width) {
      return width;
    } else if (name == 'harvest-by-field') return '250px';
    return '350px';
  };
  const onMultiSelect = (type: string) => {
    if (type === 'all') {
      setSelected(items?.map((el: any) => el[uniqueID]));
    } else {
      setSelected([]);
    }
  };
  const filterByNames = (searchText: string) => {
    const _filteredItems = items?.filter((item) => {
      return item?.[displayParam]?.toLowerCase()?.includes(searchText.toLowerCase());
    });
    setFilteredItems(_filteredItems);
  };
  const handleOnSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setSearchValue(searchText);
    if (searchText === '') {
      setFilteredItems(items);
      return;
    }
    filterByNames(searchText);
  };
  useEffect(() => {
    if (clearSelection) {
      setSelected([]);
      setIsClicked(false);
      setMouseOut(false);
    }
  }, [clearSelection]);
  useEffect(() => {
    if (!isInitial) {
      onSelectFilter(multi ? selected : selected[0]);
    } else {
      if (selectedItems != undefined) {
        setSelected([...selectedItems]);
      }
      setIsInitial(false);
    }
    // only so that no selection is done  on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, multi]);
  useEffect(() => {
    if (isClicked && mouseOut) onBlur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked, mouseOut]);
  useEffect(() => {
    if (searchable) setAllowSearch(searchable);
  }, [searchable]);
  useEffect(() => {
    setFilteredItems(items ?? []);
  }, [items]);
  return (
    <Menu matchWidth closeOnSelect={!multi} isLazy>
      {({ onClose }) => (
        <Box
          __css={{
            zIndex: `${zIndex ?? 99}`
          }}
        >
          <Button
            onClick={() => setIsClicked(true)}
            data-cy={dataCy}
            as={MenuButton}
            theme="primary"
            rightIcon={name === 'harvest-by-field' ? <TriangleDownIcon /> : <ChevronDown />}
            leftIcon={name === 'harvest-by-field' ? <Grid /> : <Map />}
            height={buttonHeight ?? '2.5rem'}
            width={getWidth()}
            whiteSpace="nowrap"
            border="1.5px solid"
            borderColor={getBorderColor()}
            boxShadow={hasError ? '0 0 0 1px #db414e' : 'none'}
            px="1rem"
            __css={{
              '& > span': {
                color: `${getSpanColor()}`,
                fontWeight: 400,
                fontSize: '1rem',
                display: 'grid !important',
                gridTemplateColumns: '1rem 1fr 1rem',
                gridColumnGap: '0.25rem',
                width: '100%',
                textAlign: 'left',
                alignItems: 'center',
                textTransform: 'capitalize',

                '& > span:last-of-type > svg': {
                  transform: `${name != 'harvest-by-field' ? 'scale(2)' : 'scale(0.6)'}`,
                  '& > path': {
                    fill: `${name != 'harvest-by-field' ? 'dark.coolGray.300' : 'primary.500'}`
                  }
                },

                '& > span:first-of-type svg': {
                  stroke: `${name != 'harvest-by-field' ? 'dark.coolGray.300' : 'primary.500'}`,
                  transform: `${name != 'harvest-by-field' ? 'scale(2.8)' : 'scale(2)'}`
                }
              }
            }}
          >
            {!selected?.length ? (
              <>{label}</>
            ) : (
              <>
                {name === 'harvest-by-field' ? (
                  <>{label}</>
                ) : (
                  <>
                    {selected.length == 1
                      ? getLabel(selected[0])
                      : `${selected.length} farms selected`}
                  </>
                )}
              </>
            )}
          </Button>
          <MenuList
            zIndex={`${zIndex ?? 99}`}
            p={0}
            borderRadius="4"
            border="none"
            className="shadow-200"
            onMouseLeave={() =>
              onMouseLeave ? onMouseLeave() : isClicked ? setMouseOut(true) : () => {}
            }
            maxHeight={'45vh'}
            width={`${name != 'harvest-by-field' ? '350px' : '250px'}`}
            display="flex"
            flexDirection={'column'}
          >
            {(allowSearch || toggleAll) && (
              <Box padding="0.375rem 0.75rem" borderBottom="2px solid #eff0f1">
                {toggleAll && (
                  <Flex justifyContent={'space-between'}>
                    <CKButton
                      size="sm"
                      colorScheme="primary"
                      variant="solid"
                      onClick={() => onMultiSelect('all')}
                      isDisabled={selected.length === filteredItems.length}
                    >
                      Select All
                    </CKButton>
                    <CKButton
                      size="sm"
                      backgroundColor={'white !important'}
                      border={'1px solid'}
                      borderColor={'primary.500'}
                      color={'primary.500'}
                      onClick={() => onMultiSelect('clear')}
                      isDisabled={selected.length === 0}
                    >
                      Clear Selection
                    </CKButton>
                  </Flex>
                )}
                {allowSearch && (
                  <Box mt="0.475rem">
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <Icon as={Search} color="dark.coolGray.40" />
                      </InputLeftElement>
                      <Input
                        type="search"
                        placeholder="Search by name"
                        _placeholder={{ color: 'dark.coolGray.40' }}
                        outlineColor="dark.coolGray.20"
                        backgroundColor={'white'}
                        onChange={handleOnSearchInputChange}
                        value={deferredSearchValue}
                        borderRadius={'0.375rem'}
                      />
                    </InputGroup>
                  </Box>
                )}
              </Box>
            )}
            <Box overflowY={'auto'} className="custom-scrollbar" flex="7">
              <Box>{extraFragment}</Box>
              {filteredItems?.length ? (
                filteredItems?.map((item: any, index: number) => (
                  <MenuItem
                    as={InputGroup}
                    key={index}
                    role="button"
                    cursor="pointer"
                    backgroundColor="transparent !important"
                    _hover={{
                      backgroundColor: 'dark.coolGray.100 !important'
                    }}
                    data-cy={`multiselect-${item[displayParam]}`}
                  >
                    <Checkbox
                      onChange={() => onSelect(item[uniqueID])}
                      isChecked={selected.includes(item[uniqueID])}
                      colorScheme="primary"
                      _checked={{
                        boxShadow: 'none',
                        svg: {
                          stroke: 'white !important'
                        }
                      }}
                      color="dark.coolGray.200 !important"
                      width="100%"
                    >
                      {name === 'harvest-by-field' ? (
                        <Text as="span" color="black.400" fontSize={'1rem'}>
                          Field {item[displayParam]}&nbsp;&nbsp;&#40;{item.area_value}
                          {'m'}&sup2;&#41;
                        </Text>
                      ) : (
                        <Text as="span" color="black.400" fontSize={'1rem'}>
                          {item[displayParam]}
                        </Text>
                      )}
                    </Checkbox>
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  {searchable && !!deferredSearchValue ? `Field not found` : 'Nothing to filter'}
                </MenuItem>
              )}
            </Box>
            {showSubmit && (
              <Flex
                justifyContent="flex-end"
                padding="0.375rem 0.75rem"
                borderTop="2px solid #eff0f1"
              >
                <CKButton
                  size="sm"
                  colorScheme="primary"
                  variant="solid"
                  onClick={() => {
                    onSubmit ? onSubmit(multi ? selected : selected[0]) : {};
                    onClose();
                  }}
                >
                  Submit
                </CKButton>
              </Flex>
            )}
          </MenuList>
        </Box>
      )}
    </Menu>
  );
}
