import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { useGraphContext } from 'Context/GraphContext';
import FieldProductivityChart from './FieldProductivityChart';
import FieldProductivityPie from './FieldProductivityPie';
import FieldProductivityHeader from './FieldProductivityHeader';
import Skeleton from 'react-loading-skeleton';

function FieldProductivity() {
  const {
    reportFilterState,
    totalsDuration,
    fieldProductivity,
    selectedFieldProductivity,
    loadingFieldProductivity
  } = useGraphContext();
  const getSubLabel = () => {
    if (reportFilterState?.days?.length && totalsDuration == 'daily') {
      return `\u00A0 Days | ${reportFilterState?.days[0]} to ${
        reportFilterState?.days[reportFilterState?.days?.length - 1]
      }`;
    } else if (reportFilterState?.weeks?.length && totalsDuration == 'weekly') {
      return `\u00A0 Weeks | ${reportFilterState?.weeks[0]} to ${
        reportFilterState?.weeks[reportFilterState?.weeks?.length - 1]
      }`;
    }
    return '';
  };
  return (
    <Box
      width="100%"
      backgroundColor="white"
      boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
      p={6}
      borderRadius={8}
      mt="2rem"
    >
      <FieldProductivityHeader subLabel={getSubLabel()} multiSelectName="header-chart" />
      {loadingFieldProductivity ? (
        <Box mt="1.65rem">
          <Skeleton height={200} />
        </Box>
      ) : (
        <Flex width="100%" flexWrap={'wrap'} mt="1.65rem" justifyContent={'center'}>
          <Box
            flex="1"
            style={{
              minWidth: `calc(50vw - 200px)`
            }}
          >
            <FieldProductivityChart data={fieldProductivity} />
          </Box>
          <Box minWidth={'500px'}>
            <FieldProductivityPie field={selectedFieldProductivity} parentName="chart" />
          </Box>
        </Flex>
      )}
    </Box>
  );
}

export default FieldProductivity;
