import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { kFormatter } from 'Helpers/helpers';
import { useGraphContext } from 'Context/GraphContext';

const LineGraphCustomTooltip = (props: any) => {
  const { duration, sortedLines, selectedYear } = useGraphContext();
  const getVal = (type: any) => {
    const found = sortedLines
      .find((el: any) => el.id == type)
      ?.data?.find((item: any) => item.x == props?.point?.data?.x);
    return kFormatter(found ? found.y : 0, 0);
  };
  const getIndexName = () => {
    const month_names = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const month_names_short = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    if (duration == 'monthly') {
      const ind = month_names_short.findIndex((el: any) => el == props?.point.data.x);
      if (ind != -1) return month_names[ind];
    } else return props?.point.data.x;
  };
  return (
    <Box
      py={1.5}
      px={2}
      style={{
        background: '#ffffff',
        boxShadow: '0px 3px 6px 1.5px rgba(33, 33, 33, 0.1)',
        textAlign: 'center',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Text
        as="p"
        textAlign={'center'}
        color={'dark.coolGray.500'}
        style={{
          fontSize: '16px',
          marginBottom: '0.75rem'
        }}
        className={'readex-font'}
      >
        {duration == 'weekly' ? 'Week ' : ''}
        {getIndexName()}, {selectedYear}
      </Text>
      {sortedLines.map((item: any) => (
        <Box
          key={item.id}
          display={'flex'}
          alignContent={'flex-start'}
          paddingTop={'0.25rem'}
          paddingBottom={'0.25rem'}
        >
          <Text
            as="span"
            mx={2}
            style={{
              width: '16px',
              height: '16px',
              borderRadius: '1px',
              backgroundColor: `${item.color}`,
              alignSelf: 'center'
            }}
          ></Text>
          <Text
            as="p"
            textAlign={'center'}
            color={'dark.coolGray.600'}
            fontSize="14px"
            fontFamily="'Be Vietnam Pro', sans-serif"
            fontWeight="400"
          >
            {item.id}:
            <Text
              as="span"
              fontSize={'14px'}
              px={1.5}
              fontWeight="bold"
              color={'dark.coolGray.600'}
            >
              {getVal(item.id)}
            </Text>
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default LineGraphCustomTooltip;
