import React, { ReactNode, useEffect, useState } from 'react';
import { Box, Flex, Grid, GridItem, Img, Link, Tooltip } from '@chakra-ui/react';
import styled from 'styled-components';
import { LeftNav, TopNav } from 'Components';
import Logo from 'assets/images/lima-icon.svg';
import FullLogo from 'assets/images/lima-icon-and-name.svg';
import WhatsappIcon from 'assets/images/whatsapp-icon.svg';
import { useAppContext, useAuthContext } from 'Context';
// import { useLocation } from 'react-router-dom';
import CompleteRegistrationModal from './Components/CompleteRegistrationModal';

const Wrapper = styled(Grid)`
  min-height: 100vh;

  &.expanded {
    grid-template-columns: 260px calc(100% - 260px);
    transition: all 300ms ease-in-out;
  }

  &.collapsed {
    grid-template-columns: 100px calc(100% - 100px);
    transition: all 300ms ease-in-out;
  }

  & > * {
    min-height: inherit;
    transition: all 300ms ease-in-out;
  }
`;

export default function Authenticated({ children }: { children: ReactNode }) {
  const [collapsed, setCollapsed] = useState(true);
  const { sidebarExpanded, toggleSideBar } = useAppContext();
  const {
    variables: { user },
    functions: { getToken }
  } = useAuthContext();
  const token = getToken();

  useEffect(() => {
    setCollapsed(!sidebarExpanded);
  }, [sidebarExpanded]);

  return (
    <>
      <Box
        position="fixed"
        bottom="2rem"
        right={['3rem', '2rem']}
        zIndex={300}
        backgroundColor="#ffffff"
        padding={'0.5rem'}
        borderRadius={'1rem'}
        boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
      >
        <Tooltip
          hasArrow
          shouldWrapChildren
          label={'Need Help?'}
          transition={'all .1s ease-in-out'}
        >
          <Link href="https://wa.me/758228830" target={'_blank'}>
            <Img src={WhatsappIcon} height="2.5rem" />
          </Link>
        </Tooltip>
      </Box>
      {user !== null &&
        user !== undefined &&
        token !== '' &&
        user.onboarding_status !== 'complete' &&
        user.role === 'Admin' && <CompleteRegistrationModal user={user} token={token} />}
      <Wrapper className={collapsed ? 'collapsed' : 'expanded'}>
        <GridItem
          className="left-nav"
          width={sidebarExpanded ? '260px' : '100px'}
          id="left-nav"
          borderEnd="1px solid"
          borderEndColor="dark.coolGray.200"
          height="100vh"
          position="fixed"
          zIndex={100}
        >
          <Flex justifyContent="center" alignItems="center" height="100px">
            <Flex
              className="logo"
              width={collapsed ? '65px' : '140px'}
              height="56px"
              boxShadow={collapsed ? '2px 2px 2px #F5F5F5' : 'none'}
              justifyContent="center"
              alignItems="center"
            >
              <Img src={collapsed ? Logo : FullLogo} height="45px" />
            </Flex>
          </Flex>
          <LeftNav onToggleSideBar={toggleSideBar} isCollapsed={collapsed} />
        </GridItem>
        <GridItem
          width="100%"
          id="left-nav-placeholder"
          transition="all 300ms ease-in-out"
        ></GridItem>
        <GridItem
          className="content"
          position="relative"
          width="100%"
          transition="all 300ms ease-in-out"
        >
          <Flex
            className="top-nav"
            height="100px"
            width={`calc(100% - ${sidebarExpanded ? '260px' : '100px'})`}
            id="top-nav"
            borderBottom="1px solid"
            borderBottomColor="dark.coolGray.200"
            px={collapsed ? '2rem' : '4rem'}
            justifyContent="space-between"
            alignItems="center"
            position="fixed"
            top="0"
            left={sidebarExpanded ? '260px' : '100px'}
            transition="all 300ms ease-in-out"
            zIndex={101}
            backgroundColor="white.500"
          >
            <TopNav />
          </Flex>
          <Box
            as="main"
            className="main"
            id="main"
            overflowX="hidden"
            maxWidth="100%"
            width="100%"
            paddingRight={collapsed ? '2rem' : '4rem'}
            bg="dark.coolGray.100"
            marginTop="100px"
            minHeight="calc(100% - 100px)"
            px={8}
            py={8}
          >
            {children}
          </Box>
        </GridItem>
      </Wrapper>
    </>
  );
}
