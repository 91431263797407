import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Farm, User } from '../Context/types';
import { useCookies } from 'react-cookie';
import { httpClient } from './appDependenciesHelpers';

export type FarmsQueryInput = {
  currentUserEmail: string | null | undefined;
  token: string;
  forceInvalidateQuery?: boolean;
};

const useFarmsQuery = (input: FarmsQueryInput): UseQueryResult<Farm[], Error> => {
  const { currentUserEmail, forceInvalidateQuery, token } = input;
  const farmsQueryKeys =
    currentUserEmail === null || currentUserEmail === undefined
      ? ['farms']
      : ['farms', currentUserEmail];

  const [userCookies] = useCookies(['user']);
  const user: User | undefined = userCookies.user ?? undefined;

  async function getUserFarms(): Promise<Farm[]> {
    let farms: Farm[] = [];
    if (token) {
      const BASE_URL = `${process.env.REACT_APP_BE_URL}`;
      const res = await httpClient
        .getAxios()
        .get(`${BASE_URL}/farms`, {
          headers: user?.tenant_header
            ? {
                Authorization: token,
                'Tenant-Header': user?.tenant_header
              }
            : {
                Authorization: token
              },
          params: {
            include_organizations: true,
            include_varieties: true,
            include_production_categories: true,
            include_number_of_active_fields: true
          }
        })
        .catch(() => {});
      if (res && res?.status === 403) farms = [];
      else if (res?.data && res.data.length) {
        farms = res.data;
      }
    }
    return farms;
  }

  const userDoesNotBelongToAnOrganization =
    user?.user_group === null && user?.tenant_header === null;

  const getFarmsQuery = useQuery({
    queryKey: farmsQueryKeys,
    queryFn: getUserFarms,
    enabled:
      token !== '' &&
      (currentUserEmail !== null || currentUserEmail !== undefined) &&
      !userDoesNotBelongToAnOrganization
  });

  if (forceInvalidateQuery) {
    getFarmsQuery.refetch();
  }

  return getFarmsQuery;
};

export default useFarmsQuery;
