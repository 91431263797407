import {
  FarmProductionDuration,
  Yields,
  LineData,
  HarvestYieldSummary
} from './../Context/GraphContext';
import { BarDatum } from '@nivo/bar';
import { GraphData, PlotData } from 'Context/GraphContext';
import GraphRepository from 'domain/GraphRepository';
import {
  extractDigits,
  monthToNumber,
  getDurationFinalKeys,
  getObjectValue,
  sortBarData
} from 'Helpers/helpers';
import HttpClient from 'http/HttpClient';
import { MyBarDatum } from 'Pages/FarmProduction';
import { ReportFilterState } from 'Pages/FarmProduction/Components/ReportSectionCard';
import { Farm, GradingItem, Variety } from 'Context/types';
import moment from 'moment';

export type OrganizedYields = {
  formattedData: BarDatum[];
  maxPred: number;
  fields?: string[];
  periodWithMax?: any;
};

export type OrganizedPreds = {
  data: any[];
  maxPred: number;
};

export type GradesAndRejectsType = {
  rejected: number;
  totalHarvest: number;
  summedGrades: any;
  actuals: MyBarDatum[] | undefined;
  highestGradeVsReject: any;
  yMax: number;
};

export type FieldHarvestType = {
  dates: string[];
  detailed_view: { [key: string]: number }[];
  field_external_name: string;
  field_internal_name: string;
  harvests: number[];
  rejects: number[];
  variety_id: string;
};

export type GradesAndRejectsResponse = {
  dates: string[];
  detailed_view: { [key: string]: number }[];
  field_harvests: FieldHarvestType[];
  variety_external_name: string;
  variety_internal_name: string;
  harvests: number[];
  rejects: number[];
  production_category_ids: { [key: string]: number };
  query_metadata: {
    variety_id: string;
    milestone: null;
    duration_out: null;
    granularity: string;
    start_date: string;
    end_date: string;
    production_category_ids: null;
  };
};

export type FieldForecastType = {
  detailed_view: { [key: string]: number }[];
  forecasts: { [key: string]: number }[];
  rejects: number[];
};

export type ForecastByFieldResponse = {
  dates: string[];
  detailed_view: { [key: string]: number }[];
  field_forecasts: { [key: string]: FieldForecastType }[];
  variety_external_name: string;
  variety_internal_name: string;
  forecasts: any;
  rejects: number[];
  production_category_ids: { [key: string]: number };
  query_metadata: {
    variety_id: string;
    milestone: null;
    duration_out: null;
    granularity: string;
    start_date: string;
    end_date: string;
    production_category_ids: { [key: string]: number };
  };
};

export type ActiveFieldsType = {
  id: string;
  name: string;
  area_value: number;
  selected: boolean;
};

export type HarvestByFieldProps = {
  data: OrganizedYields | null;
  fields: ActiveFieldsType[] | null;
  summary?: HarvestYieldSummary[] | null;
  tags: any;
  displayYMax?: number;
};

export type MininumExpectedType = {
  minimum_expected: MininumExpectedItem[];
  average_grade_split: GradingItem[];
};

export type MininumExpectedItem = {
  period: string;
  min_expected_yield?: number;
  forecast: number;
  confidence?: number;
  grading: any;
};
class GraphRepositoryImpl implements GraphRepository {
  private httpClient: HttpClient;
  private NORMAL_PREFIX_URL = 'api/v1/';
  private BASE_URL = `${process.env.REACT_APP_BE_URL}`;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  sortAndOrganizeYields(
    activeYields: Yields,
    duration: FarmProductionDuration,
    selectedYear: string
  ): OrganizedYields {
    let data: BarDatum[] = [];
    let formattedData: BarDatum[] = [];
    let maxPred = 0;
    const preds: any[] = [];
    switch (duration) {
      case 'daily':
        const dailyActualYields: PlotData[] = activeYields?.actual_yields?.daily ?? [];
        const dailyPredYields: PlotData[] = activeYields?.latest_predictions?.daily ?? [];
        const dailyPreds: PlotData[] = activeYields?.predictions?.daily ?? [];
        const dailyFinalList: any = this.combineActualAndPredData(
          dailyActualYields,
          dailyPredYields,
          dailyPreds,
          duration,
          selectedYear
        );
        formattedData = dailyFinalList.map((el: any) => {
          preds.push(el.predicted_stems as number);
          preds.push(el.actual_stems as number);
          preds.push(el.days_out_7 as number);
          preds.push(el.days_out_14 as number);
          return el;
        });
        break;
      case 'weekly':
        const weeklyActualYields: PlotData[] = activeYields?.actual_yields?.weekly ?? [];
        const weeklyPredYields: PlotData[] = activeYields?.latest_predictions?.weekly ?? [];
        const weeklyPreds: PlotData[] = activeYields?.predictions?.weekly ?? [];
        const weeklyFinalList: any = this.combineActualAndPredData(
          weeklyActualYields,
          weeklyPredYields,
          weeklyPreds,
          duration,
          selectedYear
        );
        data = weeklyFinalList.sort((a: MyBarDatum, b: MyBarDatum) => {
          return parseInt(a.period) - parseInt(b.period);
        });
        formattedData = data.map((el: any) => {
          preds.push(el.predicted_stems as number);
          preds.push(el.actual_stems as number);
          preds.push(el.weeks_out_1 as number);
          preds.push(el.weeks_out_2 as number);
          return el;
        });
        break;
    }
    maxPred = Math.max(...preds);
    const organizedYields: OrganizedYields = {
      formattedData,
      maxPred
    };
    return organizedYields;
  }

  private combineActualAndPredData(
    actualData: PlotData[],
    predictedData: PlotData[],
    predictions: PlotData[],
    duration: FarmProductionDuration,
    selectedYear: string
  ) {
    const finalPreds: any[] = [];
    const finalKeys = getDurationFinalKeys(actualData, predictedData, duration, selectedYear);

    finalKeys?.forEach((key: any) => {
      const predVal: any = getObjectValue(predictedData, key);
      const actualVal: any = getObjectValue(actualData, key);
      let predictionObj = {};
      if (duration == 'daily') {
        const daily_preds = getObjectValue(predictions, key);
        predictionObj = {
          days_out_7: daily_preds?.days_out_7?.stems ?? 0,
          days_out_14: daily_preds?.days_out_14?.stems ?? 0,
          days_out_7_grading: daily_preds?.days_out_7?.grading ?? [],
          days_out_14_grading: daily_preds?.days_out_14?.grading ?? []
        };
      } else if (duration == 'weekly') {
        const weekly_preds = getObjectValue(predictions, key);
        predictionObj = {
          weeks_out_1: weekly_preds?.weeks_out_1?.stems ?? 0,
          weeks_out_2: weekly_preds?.weeks_out_2?.stems ?? 0,
          weeks_out_1_grading: weekly_preds?.weeks_out_1?.grading ?? [],
          weeks_out_2_grading: weekly_preds?.weeks_out_2?.grading ?? []
        };
      }

      const myVal: MyBarDatum = {
        period: key,
        actual_stems: actualVal?.stems ?? 0,
        predicted_stems: predVal?.stems ?? 0,
        hasActual: actualVal ? 'yes' : 'no',
        predicted_grading: predVal?.grading ?? [],
        actual_grading: actualVal?.grading ?? [],
        ...predictionObj
      };
      finalPreds.push(myVal);
    });
    return finalPreds;
  }

  sortAndOrganizePreds(
    activePreds: any,
    duration: FarmProductionDuration,
    variety: any
  ): OrganizedPreds {
    let data: any[] = [];
    let maxPred = 0;
    switch (duration) {
      case 'daily':
        data = this.combinePreds(activePreds.daily, variety);
        data?.forEach((el: any) => {
          el.data.sort((a: any, b: any) => {
            const aKey = monthToNumber(a.x.substring(0, 3));
            const bKey = monthToNumber(b.x.substring(0, 3));
            const numA = extractDigits(a.x);
            const numB = extractDigits(b.x);
            return aKey - bKey || numA - numB;
          });
          const values = Object.values(el.data);
          values?.forEach((val: any) => {
            if (val?.y > maxPred) maxPred = val.y;
          });
        });
        break;
      case 'weekly':
        data = this.combinePreds(activePreds.weekly, variety);
        data?.forEach((el: any) => {
          el.data.sort((a: any, b: any) => a.x - b.x);
          const values = Object.values(el.data);
          values?.forEach((val: any) => {
            if (val?.y > maxPred) maxPred = val.y;
          });
        });
        break;
      case 'monthly':
        data = this.combinePreds(activePreds.monthly, variety);
        data?.forEach((el: any) => {
          el.data.sort((a: any, b: any) => {
            return monthToNumber(a.x) - monthToNumber(b.x);
          });
          const values = Object.values(el.data);
          values?.forEach((val: any) => {
            if (val?.y > maxPred) maxPred = val.y;
          });
        });
        break;
    }
    const organizedPreds: OrganizedPreds = {
      data,
      maxPred
    };
    return organizedPreds;
  }

  formatGradesAndRejects(data: GradesAndRejectsResponse, duration: string): GradesAndRejectsType {
    const rejected = data?.rejects?.reduce((partialSum: number, a: number) => partialSum + a, 0);
    const totalHarvest = data?.harvests?.reduce(
      (partialSum: number, a: number) => partialSum + a,
      0
    );
    const summedGrades: { [key: string]: any } = {};
    Object.keys(data?.production_category_ids).forEach((grade: any) => {
      let mySum = 0;
      data?.detailed_view?.forEach((val: any) => {
        mySum = mySum + val[grade];
      });
      summedGrades[grade] = mySum;
    });
    const actuals: any[] = data?.dates?.map((item: string, ind: number) => {
      const period = duration === 'daily' ? moment(item).format('MMM D') : new Date(item).getWeek();
      const newObj: { [key: string]: any } = {
        period: period,
        rejectedDisplayValue: data?.rejects[ind],
        total: data?.harvests[ind] + data?.rejects[ind],
        ...data?.detailed_view[ind],
        staticData: {
          ...data?.detailed_view[ind],
          rejected: data?.rejects[ind]
        }
      };
      return newObj;
    });
    const yMax = Math.max(...actuals.map((o) => o.total));
    const highestGradeVsReject = actuals.find((actual: any) => actual.total === yMax);
    return {
      rejected,
      totalHarvest: totalHarvest + rejected,
      summedGrades,
      actuals,
      highestGradeVsReject,
      yMax
    };
  }

  formatHarvestByFields(
    data: GradesAndRejectsResponse,
    duration: string,
    prevActiveFields: ActiveFieldsType[]
  ): OrganizedYields {
    const selectedFields: string[] = [];
    prevActiveFields?.forEach((field: ActiveFieldsType) => {
      if (field.selected) selectedFields.push(field.name);
    });
    const withData = data?.field_harvests?.filter((item: any) =>
      Object.values(item).some((entry: any) =>
        entry.harvests.some((harvest: number) => harvest > 0)
      )
    );
    const fieldsWithValuesNames: any[] = [];
    const finalYields = data?.dates?.map((item: string, ind: number) => {
      const period = duration === 'daily' ? moment(item).format('MMM D') : new Date(item).getWeek();
      const fieldValuesPerDay: { [key: string]: any } = {};
      let fieldsWithValuesCount = 0;
      let maxHarvestPerPeriod = 0;
      let selectedFieldsTotal = 0;
      withData?.forEach((el: any) => {
        const fieldData: any = Object.values(el)[0];
        const field_external_name: any = Object.keys(el)[0];
        // console.log('el harvests', fieldData);
        if (fieldData.harvests[ind]) {
          fieldsWithValuesCount++;
          fieldsWithValuesNames.push(field_external_name);
          maxHarvestPerPeriod =
            maxHarvestPerPeriod + fieldData.harvests[ind] + fieldData.rejects[ind];
        }
        if (selectedFields.includes(field_external_name))
          selectedFieldsTotal = selectedFieldsTotal + fieldData.harvests[ind];
        fieldValuesPerDay[field_external_name] = fieldData.harvests[ind];
      });
      const newObj: { [key: string]: any } = {
        period: period,
        ...fieldValuesPerDay,
        active_fields: fieldsWithValuesCount,
        max: maxHarvestPerPeriod,
        selectedTotal: selectedFieldsTotal
      };
      return newObj;
    });
    return {
      formattedData: finalYields,
      maxPred: finalYields?.length ? Math.max(...finalYields.map((o: any) => o.selectedTotal)) : 0,
      fields: Array.from(new Set(fieldsWithValuesNames)) ?? []
    };
  }
  // NB: Uncomment the commented code to re-enable day/weeks out milestone functionality
  formatForecastsByField(
    latest: ForecastByFieldResponse,
    // milestones: ForecastByFieldResponse,
    duration: string,
    activeFields: ActiveFieldsType[]
  ) {
    const selectedFields = activeFields
      ?.filter((el: ActiveFieldsType) => el.selected)
      .map((el: ActiveFieldsType) => el.name);
    let latestForecasts: { [key: string]: any } = {};
    const milestoneForecasts: { [key: string]: any } = {};
    // let milestoneFilters: string[] = [];
    if (duration === 'weekly') {
      // milestoneFilters = ['weeks_out_1', 'weeks_out_2'];
    } else if (duration === 'daily') {
      // milestoneFilters = ['days_out_7', 'days_out_14'];
    }
    if (latest.forecasts?.every((el: any) => !el)) {
      // no data for next 4 weeks
      const newLatest = latest.dates?.map((date: string) => {
        const newItem: { [key: string]: any } = {};
        newItem['period'] =
          duration === 'daily' ? moment(date).format('MMM D') : new Date(date).getWeek();
        newItem['name'] = 'forecasted';
        newItem['max'] = 0;
        newItem['selectedTotal'] = 0;
        return newItem;
      });
      latestForecasts = {
        formattedData: newLatest,
        maxPred: 0,
        fields: []
      };
    } else {
      const fieldsWithValuesNames: string[] = [];
      const forecastsByPeriod: any[] = [];
      let maxPred = 0;
      let periodWithMax: { [key: string]: any } = {};
      latest.dates?.forEach((date: string, ind: number) => {
        const newItem: { [key: string]: any } = {};
        newItem['period'] =
          duration === 'daily' ? moment(date).format('MMM D') : new Date(date).getWeek();
        newItem['name'] = 'forecasted';
        let selectedTotal = 0;
        const max = latest.forecasts[ind];
        latest.field_forecasts?.map((data: any) => {
          const objKey = Object.keys(data)[0];
          const objVal: {
            detailed_view?: [];
            forecasts?: [];
            rejects?: [];
          } = Object.values(data)[0] ?? {};
          const forecastVal = objVal?.forecasts?.[ind] ?? 0;
          newItem[objKey] = forecastVal;
          if (selectedFields.includes(objKey)) {
            selectedTotal = selectedTotal + forecastVal;
            if (forecastVal) fieldsWithValuesNames.push(objKey);
          }
        });
        newItem['max'] = max;
        newItem['selectedTotal'] = selectedTotal;
        maxPred = Math.max(maxPred, selectedTotal);
        if (max === maxPred) periodWithMax = newItem;
        forecastsByPeriod.push(newItem);
      });
      latestForecasts = {
        formattedData: forecastsByPeriod,
        maxPred: maxPred,
        fields: Array.from(new Set(fieldsWithValuesNames)) ?? [],
        periodWithMax
      };
    }

    // NB: Uncomment the commented code to re-enable day/weeks out milestone functionality
    // if (milestones.forecasts?.every((el: any) => !Object.values(el)[0])) {
    //   // no data for next 4 weeks
    //   milestoneFilters?.forEach((filter: string) => {
    //     const newLatest = latest.dates?.map((date: string) => {
    //       const newItem: { [key: string]: any } = {};
    //       newItem['period'] =
    //         duration === 'daily' ? moment(date).format('MMM D') : new Date(date).getWeek();
    //       newItem['name'] = 'forecasted';
    //       newItem['max'] = 0;
    //       newItem['selectedTotal'] = 0;
    //       return newItem;
    //     });
    //     milestoneForecasts[filter] = {
    //       formattedData: newLatest,
    //       maxPred: 0,
    //       fields: []
    //     };
    //   });
    // } else {
    //   milestoneFilters?.forEach((filter: string) => {
    //     const fieldsWithValuesNames: string[] = [];
    //     const forecastsByPeriod: any[] = [];
    //     let maxPred = 0;
    //     let periodWithMax: { [key: string]: any } = {};
    //     milestones.dates?.forEach((date: string, ind: number) => {
    //       const newItem: { [key: string]: any } = {};
    //       newItem['period'] =
    //         duration === 'daily' ? moment(date).format('MMM D') : new Date(date).getWeek();
    //       newItem['name'] = 'forecasted';
    //       let selectedTotal = 0;
    //       const max = milestones.forecasts[ind][filter];
    //       milestones.field_forecasts?.map((data: any) => {
    //         const objKey = Object.keys(data)[0];
    //         const objVal: {
    //           detailed_view?: [];
    //           forecasts?: [];
    //           rejects?: [];
    //         } = Object.values(data)[0] ?? {};
    //         const forecastVal = objVal?.forecasts?.[ind][filter] ?? 0;
    //         newItem[objKey] = forecastVal;
    //         if (selectedFields.includes(objKey)) {
    //           selectedTotal = selectedTotal + forecastVal;
    //           if (forecastVal) fieldsWithValuesNames.push(objKey);
    //         }
    //       });
    //       newItem['max'] = max;
    //       newItem['selectedTotal'] = selectedTotal;
    //       maxPred = Math.max(maxPred, selectedTotal);
    //       if (max === maxPred) periodWithMax = newItem;
    //       forecastsByPeriod.push(newItem);
    //     });
    //     milestoneForecasts[filter] = {
    //       formattedData: forecastsByPeriod,
    //       maxPred: maxPred,
    //       fields: Array.from(new Set(fieldsWithValuesNames)) ?? [],
    //       periodWithMax
    //     };
    //   });
    // }
    return {
      latest: latestForecasts,
      ...milestoneForecasts
    };
  }

  getFilterYields(
    parameter: any,
    duration: FarmProductionDuration,
    filters: ReportFilterState
  ): any {
    if (filters) {
      const values = parameter?.[duration];
      if (duration == 'weekly' && filters?.weeks?.length) {
        return values.filter((value: any) => filters?.weeks?.includes(+Object.keys(value)?.[0]));
      } else if (duration == 'daily' && filters?.days?.length) {
        // removes the zeros in one digit days
        const days = filters?.days?.map(
          (day: string) => `${day.split(' ')[0]} ${parseInt(day.split(' ')[1])}`
        );
        return values.filter((value: any) => days?.includes(Object.keys(value)?.[0]));
      }
      // return yields?.actual_yields?.[duration];
    }
    return parameter?.[duration];
  }

  /**
   * Removes zeros from the end of the data array
   * @param data the data to remove zeros from
   */
  private removeEndZeros(arr: any[]): any[] {
    let i = arr.length - 1;
    while (i >= 0 && arr[i].y === 0) {
      i--;
    }
    return arr.slice(0, i + 1);
  }

  // sort objects using the key x which is a string number
  private sortData = (data: any[]) => {
    data.sort((a: any, b: any) => {
      const numA = extractDigits(a.x);
      const numB = extractDigits(b.x);
      return numA - numB;
    });
  };

  private combinePreds(data: any[], variety: any) {
    try {
      const actuals = [];
      const days_out_1 = [];
      const days_out_7 = [];
      const days_out_14 = [];
      const days_out_28 = [];
      if (variety.harvest_frequency == 'continuous') {
        for (let i = 0; i < data.length; i++) {
          const xValue = Object.keys(data[i])?.[0];
          actuals.push({
            x: xValue,
            y: data[i]?.[`${xValue}`]?.['actuals']?.stems
              ? data[i]?.[`${xValue}`]?.['actuals']?.stems
              : 0
          });
          days_out_1.push({
            x: xValue,
            y: data[i]?.[`${xValue}`]?.['days_out_1']?.stems
              ? data[i]?.[`${xValue}`]?.['days_out_1']?.stems
              : 0
          });
          days_out_7.push({
            x: xValue,
            y: data[i]?.[`${xValue}`]?.['days_out_7']?.stems
              ? data[i]?.[`${xValue}`]?.['days_out_7']?.stems
              : 0
          });
          days_out_14.push({
            x: xValue,
            y: data[i]?.[`${xValue}`]?.['days_out_14']?.stems
              ? data[i]?.[`${xValue}`]?.['days_out_14']?.stems
              : 0
          });
        }
        return [
          {
            id: 'Actuals',
            data: actuals,
            color: '#905ED1'
          },
          {
            id: 'Latest',
            data: days_out_1,
            color: '#68E5AA'
          },
          {
            id: '7 Days',
            data: days_out_7,
            color: '#FFC185'
          },
          {
            id: '2 Weeks',
            data: days_out_14,
            color: '#A1A1A1'
          }
        ];
      } else {
        for (let i = 0; i < data.length; i++) {
          const xValue = Object.keys(data[i])?.[0];
          actuals.push({
            x: xValue,
            y: data[i]?.[`${xValue}`]?.['actuals']?.stems
              ? data[i]?.[`${xValue}`]?.['actuals']?.stems
              : 0
          });
          days_out_1.push({
            x: xValue,
            y: data[i]?.[`${xValue}`]?.['days_out_7']?.stems
              ? data[i]?.[`${xValue}`]?.['days_out_7']?.stems
              : 0
          });
          days_out_14.push({
            x: xValue,
            y: data[i]?.[`${xValue}`]?.['days_out_14']?.stems
              ? data[i]?.[`${xValue}`]?.['days_out_14']?.stems
              : 0
          });
          days_out_28.push({
            x: xValue,
            y: data[i]?.[`${xValue}`]?.['days_out_28']?.stems
              ? data[i]?.[`${xValue}`]?.['days_out_28']?.stems
              : 0
          });
        }
        this.sortData(actuals);
        this.sortData(days_out_1);
        this.sortData(days_out_14);
        this.sortData(days_out_28);
        return [
          {
            id: 'Actuals',
            data: this.removeEndZeros(actuals),
            color: '#905ED1'
          },
          {
            id: 'Latest',
            data: this.removeEndZeros(days_out_1),
            color: '#68E5AA'
          },
          {
            id: '2 Weeks',
            data: this.removeEndZeros(days_out_14),
            color: '#A1A1A1'
          },
          {
            id: '4 Weeks',
            data: this.removeEndZeros(days_out_28),
            color: '#AFE4EB'
          }
        ];
      }
    } catch (error) {
      return [];
    }
  }

  async getGraphHarvests(
    token: string,
    tenant_header: string,
    variety_id: string,
    queryParams: any
  ): Promise<GraphData | null> {
    const res = await this.httpClient
      .getAxios()
      .get(
        `/varieties/${variety_id}/graph-harvests?start_date=${queryParams?.start_date}&end_date=${queryParams?.end_date}&granularity=${queryParams.granularity}&all_farms=${queryParams.all_farms}`,
        {
          headers: {
            Authorization: token,
            'tenant-header': tenant_header
          }
        }
      )
      .catch((err) => {
        return err;
      });
    return res;
  }

  async getGraphForecasts(
    token: string,
    tenant_header: string,
    variety_id: string,
    queryParams: any,
    requestType: string
  ): Promise<GraphData | null> {
    const res = await this.httpClient
      .getAxios()
      .get(
        `/varieties/${variety_id}/${requestType}-forecasts?start_date=${queryParams?.start_date}&end_date=${queryParams?.end_date}&granularity=${queryParams.granularity}&all_farms=${queryParams.all_farms}`,
        {
          headers: {
            Authorization: token,
            'tenant-header': tenant_header
          }
        }
      )
      .catch((err) => {
        return err;
      });
    return res;
  }

  async getGraphData(
    token: string,
    variety_id: string,
    farm_id: string,
    organization: string,
    date: string,
    period: string
  ): Promise<GraphData | null> {
    const res = await this.httpClient
      .getAxios()
      .get(
        `${this.NORMAL_PREFIX_URL}blocks/graph/data?variety_id=${variety_id}&farm_id=${farm_id}&organization=${organization}&date=${date}&period=${period}`,
        {
          headers: {
            Authorization: token
          }
        }
      )
      .catch((err) => {
        return err;
      });
    return res;
  }

  async getLineData(
    token: string,
    variety_id: string,
    farm_id: string,
    organization: string,
    date: string,
    period: string
  ): Promise<LineData | null> {
    const res = await this.httpClient
      .getAxios()
      .get(
        `${this.NORMAL_PREFIX_URL}predictions/graph/data?variety_id=${variety_id}&farm_id=${farm_id}&organization=${organization}&date=${date}&period=${period}`,
        {
          headers: {
            Authorization: token
          }
        }
      )
      .catch((err) => {
        return err;
      });
    return res;
  }

  async getDemandData(
    token: string,
    variety_id: string,
    farm_id: string,
    organization: string,
    selectedYear: string
  ): Promise<any | null> {
    const res = await this.httpClient
      .getAxios()
      .get(`${this.NORMAL_PREFIX_URL}demand/get-market-demand/${farm_id}`, {
        headers: {
          Authorization: token
        },
        params: {
          farm_id: farm_id,
          variety_id: variety_id,
          organization: organization,
          year: selectedYear
        }
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  parseParams = (params: any) => {
    const keys = Object.keys(params);
    let options = '';

    keys.forEach((key) => {
      const isParamTypeObject = typeof params[key] === 'object';
      const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

      if (!isParamTypeObject) {
        options += `${key}=${params[key]}&`;
      }

      if (isParamTypeObject && isParamTypeArray) {
        params[key].forEach((element: any) => {
          options += `${key}=${element}&`;
        });
      }
    });

    return options ? options.slice(0, -1) : options;
  };

  async getPrevHarvestYield(token: string, payload: any): Promise<any | null> {
    const res = await this.httpClient
      .getAxios()
      .get(`${this.NORMAL_PREFIX_URL}harvests/field-harvests/${payload.farm_id}`, {
        headers: {
          Authorization: token
        },
        params: {
          ...payload
        },
        paramsSerializer: (params) => this.parseParams(params)
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  async getActiveFields(
    token: string,
    variety_id: string,
    farm: Farm,
    year: string
  ): Promise<any | null> {
    const res = await this.httpClient
      .getAxios()
      .get(`${this.BASE_URL}/varieties/${variety_id}/fields`, {
        headers: {
          Authorization: token,
          'tenant-header': farm.organization.internal_name
        },
        params: {
          variety_id,
          field_status: 'all',
          include_area_value: true,
          year
        }
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  sortAndOrganizePrevYields(
    yields: any[],
    duration: FarmProductionDuration,
    reportFilterState: ReportFilterState,
    emptyGradeObj: { [key: string]: any }
  ): OrganizedYields {
    let finalYields: any[] = [];
    const fieldsWithValues: string[] = [];
    if (reportFilterState?.weeks || reportFilterState?.days) {
      let myArr: any[] = [];
      if (duration === 'weekly' && reportFilterState?.weeks) {
        myArr = reportFilterState?.weeks;
      } else if (duration === 'daily' && reportFilterState?.days) {
        myArr = reportFilterState?.days?.map(
          (day: string) => `${day.split(' ')[0]} ${parseInt(day.split(' ')[1])}`
        );
      }
      finalYields = myArr?.map((filter: any) => {
        const filterValue = duration === 'daily' ? filter : `${filter}`;
        const values = yields.find((item: any) => Object.keys(item)?.[0] == filterValue);
        let maximumYield = 0;
        if (values && Object.values(values)) {
          const objValues = Object.values(values)?.[0];
          if (objValues)
            Object.values(objValues)?.map((el: any) => {
              maximumYield = maximumYield + (el?.stems ?? 0) + (el?.rejected ?? 0);
            });
        }
        const obj: any = values
          ? this.mapStems(Object.values(values)?.[0], emptyGradeObj)
          : emptyGradeObj;
        const haveValues = Object.keys(obj)?.filter((el: any) => obj[el]);
        if (haveValues) fieldsWithValues.push(...haveValues);
        const myVal: any = {
          period: filter,
          ...obj,
          max: maximumYield,
          active_fields: values?.[filter]?.active_fields ?? 0,
          selectedTotal: Object.values(obj)?.reduce((a: any, b: any) => a + b, 0)
        };
        return myVal;
      });
    } else {
      const unsorted = yields.map((el: any) => {
        const values = yields.find((item: any) => Object.keys(item)?.[0] == Object.keys(el)?.[0]);
        let maximumYield = 0;
        Object.values(Object.values(values)?.[0] ?? {})?.map((el: any) => {
          maximumYield = maximumYield + (el?.stems ?? 0) + (el?.rejected ?? 0);
        });
        const obj: any = values
          ? this.mapStems(Object.values(values)?.[0], emptyGradeObj)
          : emptyGradeObj;
        const haveValues = Object.keys(obj)?.filter((el: any) => obj[el]);
        if (haveValues) fieldsWithValues.push(...haveValues);
        const myVal: any = {
          period: Object.keys(el)?.[0],
          ...obj,
          max: maximumYield,
          active_fields: values?.[Object.keys(el)?.[0]]?.active_fields ?? 0,
          selectedTotal: Object.values(obj)?.reduce((a: any, b: any) => a + b, 0)
        };
        return myVal;
      });
      finalYields = sortBarData(unsorted, duration);
    }
    return {
      formattedData: finalYields,
      maxPred: finalYields?.length ? Math.max(...finalYields.map((o: any) => o.selectedTotal)) : 0,
      fields: Array.from(new Set(fieldsWithValues)) ?? []
    };
  }

  mapStems = (data: any, emptyGradeObj: { [key: string]: any }) => {
    const keys = Object.keys(emptyGradeObj) ?? [];
    const obj: { [key: string]: any } = {};
    keys.map((el: any) => {
      obj[el] = data?.[el]?.[`stems`] ?? 0;
    });
    return obj;
  };

  reduceRejects = (data: any): number => {
    const keys = Object.keys(data)?.filter((el: any) => el !== 'active_fields') ?? [];
    let total = 0;
    keys?.map((el: any) => {
      total += data?.[el]?.rejected;
    });
    return total;
  };

  async getForecastedHarvestYield(
    token: string,
    variety_id: string,
    farm_id: string,
    period: string,
    fields: string[]
  ): Promise<any | null> {
    const res = await this.httpClient
      .getAxios()
      .get(`${this.NORMAL_PREFIX_URL}blocks/field-forecasts/${farm_id}`, {
        headers: {
          Authorization: token
        },
        params: {
          farm_id,
          variety_id,
          period,
          fields
        },
        paramsSerializer: (params) => this.parseParams(params)
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  async getMinimumExpectedConfidence(
    token: string,
    tenant_header: string,
    variety_id: string,
    all_farms: boolean
  ): Promise<any | null> {
    const res = await this.httpClient
      .getAxios()
      .get(`${this.BASE_URL}/varieties/${variety_id}/minimum-expected-yields`, {
        headers: {
          Authorization: token,
          'tenant-header': tenant_header,
          all_farms: all_farms
        },
        params: {
          variety_id
        },
        paramsSerializer: (params) => this.parseParams(params)
      })
      .catch((err) => {
        return err;
      });

    return res;
  }

  async getFieldProductivity(
    token: string,
    tenant_header: string,
    variety_id: string,
    queryParams: any
  ): Promise<any | null> {
    const res = await this.httpClient
      .getAxios()
      .get(
        `/varieties/${variety_id}/field-rejects?start_date=${queryParams?.start_date}&end_date=${queryParams?.end_date}`,
        {
          headers: {
            Authorization: token,
            'tenant-header': tenant_header
          }
        }
      )
      .catch((err) => {
        return err;
      });
    return res;
  }

  async sortAndOrganizeProductivity(data: any): Promise<any | null> {
    const formattedData: any[] = [];
    const formattedDataPMS: any[] = [];
    data?.field_names?.map((name: string, ind: number) => {
      const data_stems = {
        period: name,
        total_harvest: data?.field_harvests?.[ind] ?? 0,
        rejected: data?.harvest_rejections?.[ind] ?? 0,
        max: (data?.field_rejects?.[ind] ?? 0) + (data?.field_harvests?.[ind] ?? 0),
        graph_name: 'field_productivity',
        field_rejects: data?.field_rejects?.[ind] ?? 0,
        ...data?.detailed_harvests?.[ind]
      };
      formattedData.push(data_stems);
      const data_stems_pm = {
        period: name,
        total_harvest: data?.field_harvests_pms?.[ind] ?? 0,
        rejected: data?.harvest_rejections_pms?.[ind] ?? 0,
        max: (data?.field_rejects_pms?.[ind] ?? 0) + (data?.field_harvests?.[ind] ?? 0),
        graph_name: 'field_productivity',
        field_rejects: data?.field_rejects_pms?.[ind] ?? 0,
        ...data?.detailed_harvests_pms?.[ind]
      };
      formattedDataPMS.push(data_stems_pm);
    });
    return {
      data_pms: {
        formattedData: formattedDataPMS,
        maxPred: formattedDataPMS?.length ? Math.max(...formattedDataPMS.map((o: any) => o.max)) : 0
      },
      data: {
        formattedData: formattedData,
        maxPred: formattedData?.length ? Math.max(...formattedData.map((o: any) => o.max)) : 0
      }
    };
  }
}

export default GraphRepositoryImpl;
