import React, {
  MouseEventHandler,
  MouseEvent,
  ReactNode,
  useEffect,
  useState,
  useRef
} from 'react';
import {
  Box,
  ButtonProps,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react';
import { TriangleDownIcon } from '@chakra-ui/icons';
import { Button } from 'Components';
import { BasicDropDownItem, BasicDropDownProps, GraphDropDownProps } from 'Components/types';
import { useAppContext } from 'Context';
import { ChevronDown } from 'react-feather';
import { Farm, Variety } from 'Context/types';

export default function BasicDropDown({
  dataCy,
  dropdownListProps,
  items,
  children,
  triggerProps,
  showSelected,
  selectBorderColor,
  selectLeftIcon,
  onSelectFilter,
  onBlur,
  hasError,
  clearSelection,
  selectedItems,
  triggerButtonDisabled,
  ...props
}: BasicDropDownProps & MenuButtonProps & ButtonProps & GraphDropDownProps): JSX.Element {
  const [selected, setSelected] = useState<any>();
  const [selectedLabel, setSelectedLabel] = useState<ReactNode>();
  const [triggerCSS, setTriggerCSS] = useState({});
  const [trigger, setTrigger] = useState({});
  const [isClicked, setIsClicked] = useState<boolean>();
  const [mouseOut, setMouseOut] = useState<boolean>();
  const [menuItemWidth, setMenuItemWidth] = useState('initial');
  const { setFarm, farms, farm, setVariety, variety, varieties, fetchVarieties } = useAppContext();
  const iconStyles = {
    '& > span > svg': {
      '& > path, & > circle, & > line': {
        stroke: `${props.name === 'top_nav' ? 'black.lighter.300' : 'black.400'}`
      }
    }
  };
  useEffect(() => {
    setSelectedIndex(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (clearSelection) {
      setSelectedIndex(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSelection]);
  const parentButtonRef = useRef<any | undefined>(undefined);
  const [selectedIndex, setSelectedIndex] = useState<any>(props.name === 'manage' ? undefined : 0);
  const handleSelect = async (
    event: MouseEvent<HTMLButtonElement>,
    index: number,
    onClick: MouseEventHandler<HTMLButtonElement> | undefined,
    id?: any,
    label?: any
  ) => {
    if (showSelected) {
      setSelectedIndex(index);
    }
    if (id && props.name == 'fD') {
      switchFarmSelection(id, label);
    } else if (id && props.name == 'vD') {
      const found = varieties?.find((el: Variety) => el.id === id);
      if (found) {
        setVariety(found);
      }
    } else if (label && props.name == 'manage') onSelectFilter(label);
    onClick && (onClick.constructor.name === 'AsyncFunction' ? onClick(event) : onClick(event));
  };

  function switchFarmSelection(farmId: string, label: string) {
    let found: Farm | undefined;
    if (farmId === 'all') {
      found = farms?.find(
        (el: Farm) =>
          el.id === 'all' && label === `${el.external_name}, ${el.city?.replaceAll('_', ' ')}`
      );

      if (found) {
        fetchVarieties(found);
      }
    } else {
      found = farms?.find(
        (el: Farm) => el.id === farmId && label === `${el.external_name}, ${el.city}`
      );

      if (found) {
        setFarm(found);
      }
    }
  }

  useEffect(() => {
    if (selectedIndex !== undefined && showSelected && items.length) {
      if (!(selectedIndex > items.length - 1)) {
        const { label, ...rest } = items?.[selectedIndex] as BasicDropDownItem;
        setSelected(clearSelection ? null : rest);
        setSelectedLabel(clearSelection ? '' : label);
      } else {
        if (props.name === 'manage') return;
        setSelectedIndex(0);
        if (props.name === 'fD') {
          setFarm(farms?.[0]);
        }
      }
    }
    // set selected farm and variety to first item on initial load
    if (
      selectedIndex === undefined &&
      showSelected &&
      items.length &&
      (props.name === 'fD' || props.name === 'vD')
    )
      setSelectedIndex(0);
    // set label to 'nothing to select' if there are no items
    if (items.length === 0) setSelectedLabel('Nothing to select');
    if (items.length >= 0 && selectedIndex === undefined) setSelectedLabel('Please select an Item');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, showSelected, items]);

  // get appropriate selected index for farm dropdown depending on availability of farm
  useEffect(() => {
    if (props.name === 'fD') {
      let found;
      if (farm?.id == 'all' && location.pathname != '/dashboard') found = 0;
      found = items.findIndex(
        (el: any) =>
          el.id === farm?.id &&
          el.label == `${farm?.external_name}, ${farm?.city.replaceAll('_', ' ')}`
      );
      setSelectedIndex(found !== -1 ? found : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm]);

  // get appropriate selected index for variety dropdown depending on availability of variety
  useEffect(() => {
    if (props.name === 'vD') {
      const found = items.findIndex((el: any) => el.id == variety?.id);
      setSelectedIndex(found !== -1 ? found : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variety]);

  useEffect(() => {
    let css: any = {};
    if (triggerProps && triggerProps.__css) {
      const { __css, ...rest } = triggerProps;
      css = { ...__css };
      setTrigger(rest);
    } else if (triggerProps) {
      setTrigger(triggerProps);
    }
    const spanStyles = css['& > span'] ?? {};
    setTriggerCSS({
      ...css,
      ...{
        '& > span': {
          display: 'flex',
          alignItems: 'center',
          ...spanStyles,

          '& > span > svg': {
            transform: 'scale(0.8)',

            '&.drop-arrow > path': {
              fill: 'black.300'
            },

            '&.drop-arrow-colored > path': {
              fill: selectBorderColor ?? '#626262'
            },

            ...(spanStyles['& > span > svg'] ?? {})
          },

          '& > span:last-of-type > svg': {
            transform: `${props.name === 'manage' && 'scale(2)'}`
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerProps, selectBorderColor]);

  const border = (): string => {
    if (selectBorderColor) {
      return `solid 3px ${selectBorderColor}`;
    }
    return '';
  };
  const getRightIcon = () => {
    if (props.name === 'manage') return <ChevronDown stroke="#9AA5B1" />;
    else {
      return selectBorderColor ? (
        <TriangleDownIcon color={selectBorderColor} className="drop-arrow-colored" />
      ) : (
        <TriangleDownIcon className="drop-arrow" />
      );
    }
  };
  const getHeight = () => {
    if (props.name === 'aFd') return '2.5rem';
    else if (props.name === 'manage') return '5rem';
    return '3rem';
  };
  const getSpanColor = () => {
    if (props.name === 'manage') return selectedLabel ? '#565857' : 'dark.coolGray.300';
    return 'black.400';
  };
  const getItemColor = () => {
    if (props.name === 'top_nav') return 'black.lighter.300';
    if (props.name === 'manage') return 'black.lighter.400';
    return '';
  };
  useEffect(() => {
    if (isClicked && mouseOut && props.name == 'manage') onBlur(props.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked, mouseOut]);

  useEffect(() => {
    setMenuItemWidth(parentButtonRef?.current?.offsetWidth);
  }, [parentButtonRef?.current?.offsetWidth]);

  props;
  return (
    <Box ref={parentButtonRef}>
      <Menu autoSelect={!!showSelected} {...props}>
        {showSelected ? (
          <Button
            onClick={() => {
              setIsClicked(true);
            }}
            data-cy={dataCy}
            as={MenuButton}
            theme="white"
            border={border()}
            rightIcon={getRightIcon()}
            leftIcon={selectLeftIcon}
            height={getHeight()}
            width={props.name === 'manage' ? '350px' : 'auto'}
            borderColor={hasError ? '#db414e' : 'dark.coolGray.200'}
            boxShadow={hasError ? '0 0 0 1px #db414e' : 'none'}
            px="1rem"
            __css={triggerCSS}
            {...selected}
            {...trigger}
            isDisabled={triggerButtonDisabled}
          >
            {selectBorderColor ? (
              <Text paddingRight={10} color="black.400">
                {selectedLabel}
              </Text>
            ) : (
              <Text
                paddingLeft={2}
                color={getSpanColor()}
                fontSize={props.name === 'manage' ? `1rem` : 'initial'}
              >
                {selectedLabel ? (
                  <>{selectedLabel}</>
                ) : (
                  <>{props.name === 'manage' && `Select ${props.id}`}</>
                )}
              </Text>
            )}
          </Button>
        ) : (
          <Button
            onClick={() => {
              setIsClicked(true);
            }}
            as={MenuButton}
            theme="white"
            border={border()}
            leftIcon={selectLeftIcon}
            rightIcon={
              selectBorderColor ? (
                <TriangleDownIcon color={selectBorderColor} className="drop-arrow-colored" />
              ) : (
                <TriangleDownIcon className="drop-arrow" />
              )
            }
            height={getHeight()}
            width="auto"
            borderColor={hasError ? '#db414e' : 'dark.coolGray.200'}
            boxShadow={hasError ? '0 0 0 1px #db414e' : 'none'}
            px="1rem"
            __css={triggerCSS}
            {...trigger}
            isDisabled={triggerButtonDisabled}
          >
            <Text as="span" paddingLeft={2} color="black.400">
              {children}
            </Text>
          </Button>
        )}
        {items.length != 0 && (
          <MenuList
            data-cy={`${dataCy}-list`}
            p={0}
            borderRadius="4"
            maxHeight={'35vh'}
            overflowY={'auto'}
            minWidth={props.name === 'top_nav' ? `${menuItemWidth}` : 'initial'}
            border={props.name === 'top_nav' ? '0px' : 'initial'}
            boxShadow={props.name === 'top_nav' ? '0px 1px 6px rgba(12, 12, 13, 0.15)' : ''}
            className="custom-scrollbar"
            onMouseLeave={() => (isClicked ? setMouseOut(true) : () => {})}
            {...dropdownListProps}
          >
            {items.map(({ label, onClick, id, ...rest }, i) => (
              <Button
                data-cy={`${dataCy}-list-item-${i}`}
                as={MenuItem}
                key={i + '-basic-dropdown'}
                fontWeight="400"
                width="100%"
                justifyContent="flex-start"
                __css={iconStyles}
                _hover={{
                  backgroundColor: 'accentGreen.100'
                }}
                _focus={{
                  backgroundColor: i === selectedIndex ? 'accentGreen.100' : 'transparent'
                }}
                borderRadius="0"
                _first={{
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px'
                }}
                _last={{
                  borderBottomLeftRadius: '4px',
                  borderBottomRightRadius: '4px'
                }}
                backgroundColor={i === selectedIndex ? 'accentGreen.100' : 'white'}
                onClick={(e) => handleSelect(e, i, onClick, id, label)}
                {...rest}
                color={getItemColor()}
              >
                {label}
              </Button>
            ))}
          </MenuList>
        )}
      </Menu>
    </Box>
  );
}
