import * as yup from 'yup';

const schema = yup.object().shape({
  stem_density_farm_pred: yup
    .number()
    .required('Stem density is required and only numbers are allowed.')
    .test('only_numbers', 'Only numbers are allowed', (value) => /\d/g.test(String(value)))
    .min(0, 'Must be greater than or equal to 0')
    .max(1000, 'Must be less than or equal to 1000'),
  area: yup
    .number()
    .required('Area is required.')
    .test('only_numbers', 'Only numbers are allowed', (value) => /\d/g.test(String(value))),
  dieback: yup.number().required('Dieback is required.').min(0).max(100),
  loss: yup
    .number()
    .nullable()
    .min(0)
    .max(100)
    .test('only_numbers', 'Only numbers are allowed', (value) => /\d/g.test(String(value))),
  harvest_schedule: yup
    .array()
    .of(
      yup.object().shape({
        area: yup.number(),
        day: yup.string(),
        dieback: yup.number(),
        grading: yup
          .array()
          .of(yup.object())
          .test('sum', 'The grade split total should not exceed 100%', (rows = [], ctx) => {
            const total = rows.reduce((total, row) => {
              total = total as number;
              let grade_amount = 0;
              Object.keys(row).forEach((grade_item) => {
                if (row[grade_item] !== undefined && Number(row[grade_item])) {
                  grade_amount += +row[grade_item];
                }
              });
              return +((total as number) + (grade_amount as number));
            }, 0);
            return +total <= ctx.parent.stems;
          })
      })
    )
    .required(),
  observation: yup.string().nullable(),
  harvest_frequency: yup.string().nullable()
});

export default schema;
