import React, { useState, useEffect } from 'react';
import { Box, GridItem, Grid, Icon, Text } from '@chakra-ui/react';
import { separateComma } from 'Helpers/helpers';
import { FarmProductionDuration, FilterDatum, useGraphContext } from 'Context/GraphContext';
import { Circle, ArrowRight } from 'react-feather';

function HarvestByFieldTooltip(props: any) {
  const {
    data: { indexValue, data }
  } = props;
  const {
    totalsDuration,
    historicalsYear,
    prevHarvestTags,
    selectedYear,
    forecastedHarvestTags,
    duration
  } = useGraphContext();
  const [tooltipDuration, setTooltipDuration] = useState<FarmProductionDuration>();
  const [tags, setTags] = useState<FilterDatum[]>();
  const [year, setYear] = useState<string>();
  useEffect(() => {
    if (data?.name === 'forecasted') {
      setTags(forecastedHarvestTags);
      setYear(selectedYear);
      setTooltipDuration(duration);
    } else {
      setTags(prevHarvestTags);
      setYear(historicalsYear);
      setTooltipDuration(totalsDuration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const getIndexName = () => {
    const month_names = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const month_names_short = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    if (tooltipDuration == 'monthly') {
      const ind = month_names_short.findIndex((el: any) => el == indexValue);
      if (ind != -1) return month_names[ind];
    } else return indexValue;
  };
  const getIconColor = (key: any): string => {
    const found = tags?.find((tag: any) => tag.id === key)?.fill;
    return found ?? '';
  };
  const getValue = (name: string): number => {
    return data?.[name] ?? 0;
  };
  return (
    <Box
      style={{
        padding: 12,
        background: '#ffffff',
        boxShadow: '0px 1px 6px rgba(12, 12, 13, 0.15)',
        textAlign: 'center',
        borderRadius: '4px',
        border: `2px solid`,
        borderColor: ''
      }}
    >
      <Text
        as="p"
        textAlign={'center'}
        color={'dark.coolGray.500'}
        style={{
          fontSize: '16px',
          marginBottom: '0.75rem'
        }}
        className={'readex-font'}
      >
        {tooltipDuration == 'weekly' ? 'Week ' : ''}
        {getIndexName()}, {year}
      </Text>
      <Text as="p" textAlign={'center'} color={'dark.coolGray.500'}>
        Total Yield
      </Text>
      <Text as="p" color={'primary.500'} fontSize={'18px'} className={'readex-font'} mb="0.5rem">
        {separateComma(data.max)}
      </Text>
      <Grid templateColumns="repeat(1, 1fr)" gap={0} alignItems="left">
        {Object.keys(data)
          ?.filter((key: string) => tags?.some((tag: any) => tag.id == key))
          ?.map((field, ind: number) => (
            <Box key={ind}>
              {data[field] && (
                <GridItem key={ind} p={0.5}>
                  <Text as="p" color={'dark.coolGray.500'} textAlign={'left'} display="flex">
                    <Icon
                      as={Circle}
                      fill={getIconColor(field)}
                      color="transparent"
                      width={'16px'}
                      height={'16px'}
                      alignSelf={'center'}
                      my="auto"
                      justifySelf={'center'}
                      mr="0.25rem"
                    />
                    {field + ':'}&nbsp;
                    <Text as="span" color={'dark.coolGray.600'}>
                      {separateComma(getValue(field))}
                    </Text>
                    <Text as="span" color={'primary.500'}>
                      <Icon as={ArrowRight} w={4} h={4} stroke={'primary.500'} ml={2} />{' '}
                      {getValue(field) == 0 ? 0 : Math.round((getValue(field) / data?.max) * 100)}%
                    </Text>
                  </Text>
                </GridItem>
              )}
            </Box>
          ))}
      </Grid>
    </Box>
  );
}

export default HarvestByFieldTooltip;
