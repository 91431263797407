import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

// Define a type for the slice state
interface CounterState {
  count: number;
  initialLoad: boolean;
}

// Define the initial state using that type
const initialState: CounterState = {
  count: 0,
  initialLoad: false
};

export const counterSlice = createSlice({
  name: 'counter',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    increment: (state) => {
      state.count += 1;
    },
    decrement: (state) => {
      state.count -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.count += action.payload;
    },
    setInitialLoad: (state, action: PayloadAction<boolean>) => {
      state.initialLoad = action.payload;
    }
  }
});

export const { increment, decrement, incrementByAmount, setInitialLoad } = counterSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.counter.count;

export default counterSlice.reducer;
