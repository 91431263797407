import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import {
  Box,
  Flex,
  Heading,
  Text,
  Radio,
  RadioGroup,
  Stack,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody
} from '@chakra-ui/react';
import { FilterDatum, useGraphContext } from 'Context/GraphContext';
import { Circle } from 'react-feather';
import { GraphKeys, MultiSelectDropdown } from 'Components';
import { dynamicSort, separateComma } from 'Helpers/helpers';

type HeaderProps = {
  subLabel: string;
  multiSelectName: string;
};
function FieldProductivityHeader(props: HeaderProps) {
  const { subLabel, multiSelectName } = props;
  const currentYear = moment().format('YYYY');
  const {
    setFieldProductivityFilters,
    loadingPrevActiveFields,
    currentProductivityTab,
    setCurrentProductivityTab,
    productivityFields,
    setProductivityFields,
    fieldProductivityFilters,
    setLoadingFieldProductivity,
    fieldProductivityTags,
    fieldProductivity,
    setFieldProductivity,
    quickActionFilters,
    setQuickActionFilters,
    originalFieldProductivity,
    primaryProductivityTags,
    loadingFieldProductivity
  } = useGraphContext();
  const [stemsMetric, setStemsMetric] = React.useState(fieldProductivityFilters?.stems);
  const [displayFieldFilter, setDisplayFieldFilter] = useState<boolean>(true);
  const [selectedFields, setSelectedFields] = useState<any[] | null>(null);
  const [dropdownItems, setDropdownItems] = useState<any[] | null>(null);
  const [headerTags, setHeaderTags] = useState<FilterDatum[] | null>(null);
  const [activeTags, setActiveTags] = useState<string[] | null>(primaryProductivityTags);
  const setStatus = (id: any) => {
    setLoadingFieldProductivity(true);
    headerTags?.map((tag: any) => {
      if (tag.id === id) {
        tag.active = !tag.active;
        return tag;
      }
      return tag;
    });
    setHeaderTags(headerTags ?? []);
    setActiveTags(
      headerTags?.filter((tag: FilterDatum) => tag.active)?.map((tag: FilterDatum) => tag.id) ?? []
    );
    setTimeout(() => {
      setLoadingFieldProductivity(false);
    }, 10);
  };
  const onSelectFilter = () => {
    return (selection: any | any[]) => {
      setSelectedFields(selection ?? []);
    };
  };
  const onSubmit = () => {
    return () => {
      if (
        fieldProductivityFilters?.fields?.filter((x) => !selectedFields?.includes(x))?.length ||
        selectedFields?.filter((x) => !fieldProductivityFilters?.fields?.includes(x))?.length
      ) {
        setCurrentProductivityTab(multiSelectName);
        const newFields = productivityFields?.map((field: any) => {
          field.selected = selectedFields?.includes(field._id);
          return field;
        });
        setProductivityFields(newFields ?? []);
        const newValues: any[] =
          quickActionFilters?.map((tag: any) => {
            tag.active = false;
            return tag;
          }) ?? [];
        setQuickActionFilters(newValues);
      }
    };
  };
  const adjustMax = (tags: string[] | null) => {
    const newData = fieldProductivity?.formattedData?.map((data: any) => {
      let newMax = 0;
      tags?.forEach((tag: string) => (newMax += data?.[`${tag}`]));
      data['max'] = newMax;
      return data;
    });
    const newMax = newData?.length ? Math.max(...newData.map((o: any) => o.max)) : 0;
    setFieldProductivity({ formattedData: newData, maxPred: newMax });
  };
  const extraFragment = () => {
    let element = <></>;
    if (fieldProductivity?.maxPred && productivityFields?.length && productivityFields?.length > 5)
      element = (
        <Box padding="0.375rem 0.75rem">
          {quickActionFilters?.map((val: any, ind: number) => (
            <MenuItem
              key={ind}
              onClick={() => setFields(val.id)}
              _hover={{
                backgroundColor: 'dark.coolGray.100 !important'
              }}
              backgroundColor="transparent !important"
              fontSize={'1rem'}
              color={val.active ? 'green.500' : 'black.400'}
              fontWeight={val.active ? 600 : 400}
            >
              {val.value}
              {val.label}
            </MenuItem>
          ))}
        </Box>
      );
    return element;
  };
  const setFields = (id: string) => {
    setDropdownItems(null);
    const newValues: any[] =
      quickActionFilters?.map((tag: any) => {
        tag.active = tag.id === id;
        return tag;
      }) ?? [];
    const sorted =
      originalFieldProductivity?.formattedData?.sort(dynamicSort(id, 'desc'))?.slice(0, 5) ?? [];
    const fields = getFieldIDs(sorted);
    const newFields = productivityFields?.map((field: any) => {
      field.selected = fields?.includes(field._id);
      return field;
    });
    setCurrentProductivityTab(multiSelectName);
    setProductivityFields(newFields ?? []);
    setQuickActionFilters(newValues);
  };
  const getFieldIDs = (data: any[]) => {
    return data?.map((el: any) => {
      return productivityFields?.find((field: any) => field.name == el.period)?.id ?? '';
    });
  };
  const [noDataFields, setNoDataFields] = useState<any[] | null>(null);
  useEffect(() => {
    setFieldProductivityFilters({ ...fieldProductivityFilters, stems: stemsMetric });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stemsMetric]);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (currentProductivityTab && currentProductivityTab !== multiSelectName) {
      setDisplayFieldFilter(false);
      timeout = setTimeout(() => {
        setDisplayFieldFilter(true);
      }, 10);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldProductivityFilters?.fields]);
  useEffect(() => {
    setHeaderTags(
      fieldProductivityTags?.filter((tag: FilterDatum) => primaryProductivityTags.includes(tag.id))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldProductivityTags]);
  useEffect(() => {
    //rests max depending on selected tags
    adjustMax(activeTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTags]);
  useEffect(() => {
    setDropdownItems(productivityFields);
  }, [productivityFields]);
  useEffect(() => {
    setNoDataFields(fieldProductivity?.formattedData?.filter((el: any) => !el.max) ?? null);
  }, [fieldProductivity?.formattedData]);
  return (
    <Box>
      <Flex alignItems={'flex-start'} justifyContent="space-between" flexWrap={'wrap'} gap="1.6rem">
        <Box textAlign={'left'}>
          <Heading as="h4" mb="2" fontSize="1.35rem" fontWeight="normal" color="dark.coolGray.600">
            Field Productivity Overview
          </Heading>
          <Text as="p" color="dark.coolGray.500">
            {`${currentYear} ${subLabel}` || <Skeleton count={1} />}
          </Text>
        </Box>
        <Box display="flex" gap="1rem" alignItems={'center'} flexWrap="wrap">
          <Box>
            <RadioGroup onChange={setStemsMetric} value={stemsMetric} colorScheme="primary">
              <Stack direction="row" gap="1.5rem">
                <Radio value="stems" size="md">
                  Stems
                </Radio>
                <Radio value="stemspm2" size="md">
                  Stems/{'m'}&sup2;
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>
          <Box>
            {loadingPrevActiveFields ? (
              <Box>
                <Skeleton height={'2.5rem'} width={220} />
              </Box>
            ) : (
              <>
                {!!dropdownItems?.length && displayFieldFilter && (
                  <MultiSelectDropdown
                    dataCy="harvest-by-field"
                    label={`Field${dropdownItems?.length > 1 ? 's' : ''}`}
                    items={dropdownItems ?? []}
                    onSelectFilter={onSelectFilter()}
                    onBlur={() => {}}
                    multi
                    name="harvest-by-field"
                    selectedItems={
                      dropdownItems
                        ?.filter((field: any) => field.selected)
                        .map((field: any) => field._id) ?? []
                    }
                    clearSelection={false}
                    zIndex={100}
                    toggleAll
                    searchable
                    showSubmit
                    onSubmit={onSubmit()}
                    extraFragment={extraFragment()}
                    uniqueID="_id"
                    displayParam="name"
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent={'space-between'} alignItems={'flex-start'} flexWrap="wrap" my="1rem">
        <Flex gap={'0.75rem'} flexWrap="wrap">
          {headerTags?.map((tag: any, i: number) => (
            <Box
              key={i}
              bg={tag.bg}
              padding="0.55rem 0.75rem"
              fontSize={'16px'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              justifyContent={'center'}
              borderRadius={'8px'}
              opacity={tag.active ? `100%` : '50%'}
              onClick={() => setStatus(tag.id)}
              cursor={'pointer'}
            >
              <Text
                fontWeight={'400'}
                fontSize="20"
                alignItems={'center'}
                display="flex"
                color="dark.coolGray.600"
              >
                {tag.label}&nbsp;&nbsp;|&nbsp;&nbsp;
                <Text as="span" fontSize="27" color={tag.fill}>
                  {separateComma(
                    fieldProductivity?.formattedData?.reduce(
                      (accumulator: any = 0, object: { [key: string]: any }) => {
                        return accumulator + object?.[tag.id];
                      },
                      0
                    ) ?? 0
                  )}
                </Text>
              </Text>
            </Box>
          ))}
        </Flex>
        <Box>
          <GraphKeys
            showTooltip={true}
            onClick={setStatus}
            graphKeys={headerTags ?? []}
            leftOffset={'0'}
            icon={Circle}
            clickable
          ></GraphKeys>
        </Box>
      </Flex>
      {!!noDataFields?.length && !!fieldProductivity?.maxPred && !loadingFieldProductivity && (
        <>
          <Text className="text-muted" as="span">
            {noDataFields?.length} of the selected fields do not have data for the given
            filters.&nbsp;
          </Text>
          <Box display={'inline-block'}>
            <Popover>
              <PopoverTrigger>
                <Text textDecoration={'underline'} cursor="pointer" className="text-muted">
                  See more
                </Text>
              </PopoverTrigger>
              <PopoverContent
                bg="white"
                color="dark.coolGray.500"
                width="fit-content"
                maxWidth="35vw"
              >
                <PopoverHeader color={'green.500'} fontWeight="500">
                  <Text>Field names</Text>
                </PopoverHeader>
                <PopoverBody>
                  {noDataFields
                    ?.map((el: any) => el.period)
                    ?.sort()
                    ?.join(',  ')}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </>
      )}
    </Box>
  );
}

export default FieldProductivityHeader;
