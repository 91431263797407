import React, { useState, useEffect } from 'react';
import { Box, Flex, Table, Text, Thead, Tbody, Td, Tr, Th } from '@chakra-ui/react';
import Skeleton from 'react-loading-skeleton';
import styles from './../../../../Styles/Table.module.scss';
import { useGraphContext } from 'Context/GraphContext';
import { separateComma, sortTableColumn } from 'Helpers/helpers';
import SortIcon from 'Pages/Home/Components/SortIcon';
import GradesAndRejectsHeader from './GradesAndRejectsHeader';
import { GradesAndRejectsProps } from './GradeAndRejectsChart';

type HarvestByFieldTableHeaderType = 'normal' | 'grade-split';
export type HarvestByFieldTableHead = {
  label: string;
  type: HarvestByFieldTableHeaderType;
  accessor: string;
  sortable: boolean;
  isNumeric: boolean;
};

function GradesAndRejectsTable(props: GradesAndRejectsProps) {
  const { data } = props;
  const { totalsDuration, loadingGradesAndRejects, gradesAndRejectsTags } = useGraphContext();
  const [displayData, setDisplayData] = useState<any[]>(data?.actuals ?? []);
  const [isRejectActive, setIsRejectActive] = useState<boolean>(false);
  useEffect(() => {
    setDisplayData(data?.actuals ?? []);
  }, [data]);
  const findIdByLabel = (label: string) => {
    const item = gradesAndRejectsTags.find((entry) => entry.label === label);
    return item ? item.id : null;
  };
  const findIdByID = (id: string) => {
    const item = gradesAndRejectsTags.find((entry) => entry.id === id);
    return item ? item.label : null;
  };
  const tableHeaders: HarvestByFieldTableHead[] = [
    {
      label: `Harvest ${totalsDuration == 'weekly' ? 'Week' : 'Day'}`,
      type: 'normal',
      accessor: 'period',
      sortable: true,
      isNumeric: true
    },
    {
      label: 'Harvest Yield',
      type: 'normal',
      accessor: 'total',
      sortable: true,
      isNumeric: true
    },
    {
      label: 'Rejected',
      type: 'normal',
      accessor: 'rejectedDisplayValue',
      sortable: true,
      isNumeric: true
    }
  ];
  const [colSpan, setColSpan] = useState<number>(0);
  const [validKeys, setValidKeys] = useState<any[]>([]);
  const [validLabel, setValidLabel] = useState<any[]>([]);

  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState<string | null>(null);
  const [isSorting, setIsSorting] = useState(false);

  const onSort = (accessor: string) => {
    setIsSorting(true);
    const sortOrder = accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);
    const sorted = sortTableColumn(displayData ?? [], accessor, sortOrder);
    setDisplayData(sorted ?? []);
    setIsSorting(false);
  };
  const onSubSort = (id: string) => {
    const label = findIdByID(id);
    setIsSorting(true);
    const sortOrder = label === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(label ?? '');
    setOrder(sortOrder);
    const sorted = sortTableColumn(displayData ?? [], id, sortOrder);
    setDisplayData(sorted ?? []);
    setIsSorting(false);
  };
  useEffect(() => {
    setIsRejectActive(gradesAndRejectsTags.some((item) => item.id === 'rejected' && item.active));
  }, [gradesAndRejectsTags, validKeys]);
  useEffect(() => {
    const label =
      gradesAndRejectsTags
        .filter((tag: any) => tag.id !== 'rejected' && tag.active)
        .map((tag: any) => tag.label) ?? [];
    const key =
      gradesAndRejectsTags
        .filter((tag: any) => tag.id !== 'rejected' && tag.active)
        .map((tag: any) => tag.id) ?? [];
    setColSpan(label?.length ?? 1);
    setValidLabel(label);
    setValidKeys(key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradesAndRejectsTags]);
  const activeTags = () => {
    return gradesAndRejectsTags?.filter((el: any) => el.active)?.length ?? 0;
  };
  const rejectActive = () => {
    const active = gradesAndRejectsTags?.filter((el: any) => el.id !== 'rejected' && el.active);
    return active.length;
  };
  return (
    <Box width="100%" data-cy="table-box" overflow="hidden">
      <GradesAndRejectsHeader data={data} />
      {loadingGradesAndRejects ? (
        <Skeleton height={200} style={{ marginTop: '4px' }} />
      ) : (
        <>
          {displayData.length > 0 && activeTags() && !!data?.yMax ? (
            <Box className="gray-border-05" mt="1rem">
              <Flex
                maxHeight={'700'}
                w="100%"
                whiteSpace={'nowrap'}
                className={`custom-scrollbar`}
                overflowY="auto"
              >
                <Box position="sticky" top="0" left="0" zIndex={100} height="100%" flex={1}>
                  <Table className={`${styles.table__content} gray-border`} size="sm">
                    <Thead backgroundColor="dark.coolGray.100" pos="sticky" top={0} zIndex="100">
                      <Tr>
                        {tableHeaders.map((header, index) => {
                          if (header.type === 'normal') {
                            if (header.label === 'Rejected' && !isRejectActive) {
                              // If "Reject" is not active, simply skip this iteration of the loop
                              return null;
                            } else {
                              return (
                                <Th
                                  className={`gray-border`}
                                  key={index + header.accessor}
                                  height="91px"
                                >
                                  <Text
                                    color="green.500"
                                    className={styles.table__actual__header}
                                    display="flex"
                                    gap="0.5rem"
                                    alignItems={'center'}
                                    justifyContent="center"
                                  >
                                    {header.label}
                                    {header.sortable && (
                                      <SortIcon
                                        data-cy={`sort_${header.accessor}`}
                                        isNumeric={header.isNumeric}
                                        sort={() => onSort(header.accessor)}
                                        disabled={isSorting}
                                        isSorting={header.accessor === sortField && !!order}
                                      />
                                    )}
                                  </Text>
                                </Th>
                              );
                            }
                          }
                        })}
                      </Tr>
                    </Thead>
                    <Tbody className={`${styles.table__content__body}`} bgColor="white">
                      <>
                        {!!displayData?.length &&
                          displayData?.map((item: any, index: number) => (
                            <Tr key={index}>
                              <Td
                                textAlign="center"
                                className="gray-border"
                                data-cy={'actual_inputs[' + index + '].day'}
                              >
                                {item.period}
                              </Td>
                              <Td textAlign="center" className="gray-border">
                                {separateComma(item.total)}
                              </Td>
                              {isRejectActive && (
                                <Td textAlign="center" className="gray-border">
                                  {separateComma(item.rejectedDisplayValue)}
                                </Td>
                              )}
                            </Tr>
                          ))}
                      </>
                    </Tbody>
                  </Table>
                </Box>
                {!!rejectActive() && (
                  <Box flex={1}>
                    <Table className={`${styles.table__content} gray-border`} size="sm">
                      <Thead
                        backgroundColor="dark.coolGray.100"
                        color="green.500"
                        pos="sticky"
                        top={0}
                        zIndex="docked"
                        className={`${styles.table__header}`}
                      >
                        <Tr width="100%">
                          {!!colSpan && (
                            <Th
                              className="gray-border"
                              textAlign="center"
                              color="green.500"
                              colSpan={colSpan}
                              height="42px"
                            >
                              <Text color="green.500" className={styles.table__actual__header}>
                                Grading
                              </Text>
                            </Th>
                          )}
                        </Tr>
                        <Tr>
                          {!!validLabel?.length &&
                            validLabel?.map((field, index) => (
                              <Th className="gray-border" key={index + field} height="42px">
                                <Text
                                  color="green.500"
                                  className={styles.table__actual__header}
                                  display="flex"
                                  gap="0.5rem"
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                >
                                  {field}
                                  <SortIcon
                                    data-cy={`sort_${field}`}
                                    isNumeric={true}
                                    sort={() => onSubSort(findIdByLabel(field) ?? '')}
                                    disabled={isSorting}
                                    isSorting={field === sortField && !!order}
                                  />
                                </Text>
                              </Th>
                            ))}
                        </Tr>
                      </Thead>
                      <Tbody className={`${styles.table__content__body}`}>
                        {!!displayData?.length &&
                          displayData?.map((item: any, index: number) => (
                            <Tr key={index}>
                              {validKeys?.map((field, ind) => {
                                return (
                                  <Td textAlign="center" key={ind} className={'gray-border'}>
                                    {separateComma(item?.[field])}
                                  </Td>
                                );
                              })}
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </Box>
                )}
              </Flex>
            </Box>
          ) : (
            <Text align={'left'} mt="1rem">
              No data available for the selected filter parameters.
            </Text>
          )}
        </>
      )}
    </Box>
  );
}

export default GradesAndRejectsTable;
