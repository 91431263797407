import React, { useState, useEffect } from 'react';
import { Box, Flex, Table, Text, Thead, Tbody, Td, Tr, Th } from '@chakra-ui/react';
import { HarvestByFieldProps } from 'data/GraphRepositoryImpl';
import Skeleton from 'react-loading-skeleton';
import styles from './../../../../../Styles/Table.module.scss';
import { FilterDatum, useGraphContext } from 'Context/GraphContext';
import HarvestByFieldHeader from './HarvestByFieldHeader';
import { separateComma, sortTableColumn } from 'Helpers/helpers';
import SortIcon from 'Pages/Home/Components/SortIcon';

type HarvestByFieldTableHeaderType = 'normal' | 'field-split';
export type HarvestByFieldTableHead = {
  label: string;
  type: HarvestByFieldTableHeaderType;
  accessor: string;
  sortable: boolean;
  isNumeric: boolean;
};

function HarvestByFieldTable(props: HarvestByFieldProps) {
  const { data, fields, tags } = props;
  const { reportFilterState, totalsDuration, loadingPrevHarvestByField } = useGraphContext();
  const tableHeaders: HarvestByFieldTableHead[] = [
    {
      label: `Harvest ${totalsDuration == 'weekly' ? 'Week' : 'Day'}`,
      type: 'normal',
      accessor: 'period',
      sortable: true,
      isNumeric: true
    },
    {
      label: 'Total Harvest',
      type: 'normal',
      accessor: 'max',
      sortable: true,
      isNumeric: true
    },
    {
      label: 'Active Fields',
      type: 'normal',
      accessor: 'active_fields',
      sortable: true,
      isNumeric: true
    },
    {
      label: 'Harvest Per Field',
      type: 'field-split',
      accessor: 'field',
      sortable: false,
      isNumeric: true
    }
  ];
  const [colSpan, setColSpan] = useState<number>(0);
  const [validKeys, setValidKeys] = useState<any[]>([]);
  const getSubLabel = () => {
    if (reportFilterState?.days?.length && totalsDuration == 'daily') {
      return `\u00A0 Days | ${reportFilterState?.days[0]} to ${
        reportFilterState?.days[reportFilterState?.days?.length - 1]
      }`;
    } else if (reportFilterState?.weeks?.length && totalsDuration == 'weekly') {
      return `\u00A0 Weeks | ${reportFilterState?.weeks[0]} to ${
        reportFilterState?.weeks[reportFilterState?.weeks?.length - 1]
      }`;
    }
    return '';
  };
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState<string | null>(null);
  const [isSorting, setIsSorting] = useState(false);
  const [displayData, setDisplayData] = useState<any[]>(
    data?.formattedData?.filter((el: any) => el.max) ?? []
  );
  const onSort = (accessor: string) => {
    setIsSorting(true);
    const sortOrder = accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);
    const sorted = sortTableColumn(displayData ?? [], accessor, sortOrder);
    setDisplayData(sorted ?? []);
    setIsSorting(false);
  };
  useEffect(() => {
    setColSpan(tags?.withData?.length ?? 1);
    setValidKeys(tags?.withData?.map((el: FilterDatum) => el.label));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);
  useEffect(() => {
    setDisplayData(data?.formattedData?.filter((el: any) => el.max) ?? []);
  }, [data]);
  return (
    <Box width="100%" data-cy="table-box" overflow="hidden">
      <HarvestByFieldHeader
        fields={fields ?? []}
        subLabel={getSubLabel()}
        multiSelectName="harvest-table"
        noDataFields={tags?.withoutData}
      />
      {loadingPrevHarvestByField ? (
        <Skeleton height={200} style={{ marginTop: '6px' }} />
      ) : (
        <>
          {!!data?.formattedData && !!validKeys?.length ? (
            <Box className="gray-border-05" mt="6px">
              <Flex
                maxHeight={'700'}
                w="100%"
                whiteSpace={'nowrap'}
                className={`custom-scrollbar`}
                overflowY="auto"
              >
                <Box position="sticky" top="0" left="0" height="100%" zIndex={3}>
                  <Table className={`${styles.table__content} gray-border`} size="sm">
                    <Thead backgroundColor="dark.coolGray.100" pos="sticky" top={0}>
                      <Tr>
                        {tableHeaders.map((header, index) => {
                          if (header.type === 'normal') {
                            return (
                              <Th
                                className={`gray-border`}
                                key={index + header.accessor}
                                height="91px"
                              >
                                <Text
                                  color="green.500"
                                  className={styles.table__actual__header}
                                  display="flex"
                                  gap="0.5rem"
                                  alignItems={'center'}
                                >
                                  {header.label}
                                  {header.sortable && (
                                    <SortIcon
                                      data-cy={`sort_${header.accessor}`}
                                      isNumeric={header.isNumeric}
                                      sort={() => onSort(header.accessor)}
                                      disabled={isSorting}
                                      isSorting={header.accessor === sortField && !!order}
                                    />
                                  )}
                                </Text>
                              </Th>
                            );
                          }
                        })}
                      </Tr>
                    </Thead>
                    <Tbody className={`${styles.table__content__body}`} bgColor="white">
                      {!!displayData?.length &&
                        displayData?.map((item: any, index: number) => (
                          <Tr key={index}>
                            <Td
                              textAlign="center"
                              className="gray-border"
                              data-cy={'actual_inputs[' + index + '].day'}
                            >
                              {item.period}
                            </Td>
                            <Td textAlign="center" className="gray-border">
                              {separateComma(item.max)}
                            </Td>
                            <Td textAlign="center" className="gray-border">
                              {separateComma(item.active_fields)}
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
                <Box flex={1} zIndex={2}>
                  <Table className={`${styles.table__content} gray-border`} size="sm">
                    <Thead
                      backgroundColor="dark.coolGray.100"
                      color="green.500"
                      pos="sticky"
                      top={0}
                      zIndex={'docked'}
                      className={`${styles.table__header}`}
                    >
                      <Tr width="100%">
                        {!!colSpan && (
                          <Th
                            className="gray-border"
                            textAlign="center"
                            color="green.500"
                            colSpan={colSpan}
                            height="42px"
                          >
                            <Text color="green.500" className={styles.table__actual__header}>
                              Harvest by Field
                            </Text>
                          </Th>
                        )}
                      </Tr>
                      <Tr>
                        {!!validKeys?.length &&
                          validKeys?.map((field, index) => (
                            <Th className="gray-border" key={index + field} height="42px">
                              <Text
                                color="green.500"
                                className={styles.table__actual__header}
                                display="flex"
                                gap="0.5rem"
                                alignItems={'center'}
                                justifyContent={'center'}
                              >
                                {field}
                                <SortIcon
                                  data-cy={`sort_${field}`}
                                  isNumeric={true}
                                  sort={() => onSort(field)}
                                  disabled={isSorting}
                                  isSorting={field === sortField && !!order}
                                />
                              </Text>
                            </Th>
                          ))}
                      </Tr>
                    </Thead>
                    <Tbody className={`${styles.table__content__body}`}>
                      {!!displayData?.length &&
                        displayData?.map((item: any, index: number) => (
                          <Tr key={index}>
                            {validKeys?.map((field, ind) => {
                              return (
                                <Td textAlign="center" key={ind} className={'gray-border'}>
                                  {separateComma(item?.[field])}
                                </Td>
                              );
                            })}
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
              </Flex>
            </Box>
          ) : (
            <Text my="1.65rem" textAlign={'left'}>
              No data available for the selected filter parameters.
            </Text>
          )}
        </>
      )}
    </Box>
  );
}

export default HarvestByFieldTable;
