import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/react';
import Skeleton from 'react-loading-skeleton';
import { FilterDropDown, MultiSelectDropdown } from 'Components';
import { useGraphContext } from 'Context/GraphContext';
import { ActiveFieldsType } from 'data/GraphRepositoryImpl';
import { separateComma } from 'Helpers/helpers';
import moment from 'moment';
import { Calendar } from 'react-feather';

type HeaderProps = {
  fields: ActiveFieldsType[];
  subLabel: string;
  multiSelectName: string;
  noDataFields: any;
};

function HarvestByFieldHeader(props: HeaderProps) {
  const { fields, subLabel, multiSelectName, noDataFields } = props;
  const durationList = ['Daily', 'Weekly'];
  const {
    setTotalsDuration,
    totalsDuration,
    loadingPrevHarvestByField,
    setPrevActiveFields,
    loadingPrevActiveFields,
    setSelectedPrevFields,
    prevHarvestYieldSummary,
    selectedPrevFields,
    currentHistoricalsTab,
    setCurrentHistoricalsTab,
    prevHarvestYields
  } = useGraphContext();
  const onSelectDuration = () => {
    return (selection: number | number[]) => {
      switch (selection) {
        case 0:
          setTotalsDuration('daily');
          break;
        case 1:
          setTotalsDuration('weekly');
          break;
        case 2:
          setTotalsDuration('monthly');
          break;
        default:
          break;
      }
    };
  };
  const onSelectFilter = () => {
    return (selection: any | any[]) => {
      setSelectedFields(selection ?? []);
    };
  };
  const onMouseLeave = () => {
    return () => {
      if (
        selectedPrevFields?.filter((x) => !selectedFields?.includes(x))?.length ||
        selectedFields?.filter((x) => !selectedPrevFields?.includes(x))?.length
      ) {
        setCurrentHistoricalsTab(multiSelectName);
        setSelectedPrevFields(selectedFields ?? []);
        const finalActive = fields?.map((el: ActiveFieldsType) => {
          el['selected'] = selectedFields?.includes(el.id) ?? false;
          return el;
        });
        setPrevActiveFields(finalActive ?? []);
      }
    };
  };
  const [displayDurationFilter, setDisplayDurationFilter] = useState<boolean>(true);
  const [displayFieldFilter, setDisplayFieldFilter] = useState<boolean>(true);
  const [selectedDuration, setSelectedDuration] = useState<number>(1);
  const currentYear = useMemo(() => {
    return moment().format('YYYY');
  }, []);
  const [selectedFields, setSelectedFields] = useState<any[] | null>(null);
  useEffect(() => {
    if (totalsDuration) {
      const found = totalsDuration === 'weekly' ? 1 : 0;
      setSelectedDuration(found);
    }
  }, [totalsDuration]);
  useEffect(() => {
    setDisplayDurationFilter(false);
    const initializeTimeout = setTimeout(() => {
      setDisplayDurationFilter(true);
    }, 10);
    return () => {
      clearTimeout(initializeTimeout);
    };
  }, [selectedDuration]);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (currentHistoricalsTab && currentHistoricalsTab !== multiSelectName) {
      setDisplayFieldFilter(false);
      timeout = setTimeout(() => {
        setDisplayFieldFilter(true);
      }, 10);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrevFields?.length]);
  return (
    <Box>
      <Flex alignItems={'flex-start'} justifyContent="space-between" flexWrap={'wrap'} gap="1.6rem">
        <Box textAlign={'left'}>
          <Heading as="h4" mb="2" fontSize="1.35rem" fontWeight="normal" color="dark.coolGray.600">
            Harvest by Field
          </Heading>
          <Text as="p" color="dark.coolGray.500">
            {`${currentYear} ${subLabel}` || <Skeleton count={1} />}
          </Text>
        </Box>
        <Flex alignItems={'center'} gap="0.75rem" flexWrap={'wrap'}>
          {displayDurationFilter && (
            <FilterDropDown
              label={`${totalsDuration}`}
              items={durationList}
              selectedItem={selectedDuration}
              onSelectFilter={onSelectDuration()}
              name="harvest-by-field"
              LeftIcon={Calendar}
            />
          )}
          {loadingPrevActiveFields ? (
            <Box>
              <Skeleton height={'2.5rem'} width={220} />
            </Box>
          ) : (
            <>
              {!!fields?.length && displayFieldFilter && (
                <MultiSelectDropdown
                  dataCy="harvest-by-field"
                  label={`Field${fields?.length > 1 ? 's' : ''}`}
                  items={Array.from(fields ?? [])}
                  onSelectFilter={onSelectFilter()}
                  onBlur={() => {}}
                  multi
                  name="harvest-by-field"
                  selectedItems={selectedPrevFields ?? []}
                  clearSelection={false}
                  zIndex={100}
                  toggleAll
                  searchable
                  showSubmit
                  onSubmit={onMouseLeave()}
                  uniqueID="id"
                  displayParam="external_name"
                />
              )}
            </>
          )}
        </Flex>
      </Flex>
      {!loadingPrevHarvestByField && (
        <Flex gap={'0.75rem'} flexWrap="wrap" mt="1.65rem">
          {prevHarvestYieldSummary?.map((el: any, i: number) => (
            <Box
              key={i}
              bg="#EBFCF4"
              padding="0.55rem 0.75rem"
              fontSize={'16px'}
              minWidth="180px"
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              justifyContent={'center'}
              borderRadius={'8px'}
            >
              <Flex alignItems={'center'} gap="0.15rem">
                <Text fontSize={'16px'} color="dark.coolGray.500">
                  {el.label}
                </Text>
              </Flex>
              <Text
                fontWeight={'400'}
                fontSize={'22px'}
                lineHeight={'33px'}
                color="neutral.600"
                mt="0.75rem"
                mb="0.25rem"
              >
                {separateComma(el.value)}
              </Text>
            </Box>
          ))}
        </Flex>
      )}
      {!!noDataFields?.length && !!prevHarvestYields?.maxPred && !loadingPrevHarvestByField && (
        <Box textAlign={'left'} my="0.5rem">
          <Text className="text-muted" as="span">
            {noDataFields?.length} of the selected fields do not have data for the given
            filters.&nbsp;
          </Text>
          <Box display={'inline-block'}>
            <Popover>
              <PopoverTrigger>
                <Text textDecoration={'underline'} cursor="pointer" className="text-muted">
                  See more
                </Text>
              </PopoverTrigger>
              <PopoverContent
                bg="white"
                color="dark.coolGray.500"
                width="fit-content"
                maxWidth="35vw"
              >
                <PopoverHeader color={'green.500'} fontWeight="500">
                  <Text>Field names</Text>
                </PopoverHeader>
                <PopoverBody>{noDataFields?.sort()?.join(',  ')}</PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default HarvestByFieldHeader;
