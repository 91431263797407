import { Flex, Icon, IconButton, Text, useToast } from '@chakra-ui/react';
import React from 'react';
import { AlertTriangle, CheckCircle, Slash, X } from 'react-feather';
import styles from './LimaToast.module.scss';

export type LimaToastProps = {
  status: 'success' | 'error' | 'warning';
  message: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
};

const LimaToast = (props: LimaToastProps) => {
  const { status, message, marginTop, marginLeft, marginRight, marginBottom } = props;
  const toast = useToast();

  const handleCloseToast = () => {
    toast.closeAll();
  };

  switch (status) {
    case 'success':
      return (
        <Flex
          data-cy="success-toast-message"
          alignItems="center"
          className={styles.success}
          justifyContent="space-between"
          p={2}
          mt={marginTop}
          ml={marginLeft}
          mb={marginBottom}
          mr={marginRight}
          gap={4}
          width={'fit-content'}
        >
          <Icon boxSize="20px" as={CheckCircle} color="#00B91D" />
          <Text>{message}</Text>
          <IconButton
            variant="ghost"
            onClick={handleCloseToast}
            aria-label="Close toast"
            icon={<Icon as={X} color="#00B91D" />}
            className="no_outline_button"
          />
        </Flex>
      );
    case 'error':
      return (
        <Flex
          data-cy="error-toast-message"
          alignItems="center"
          justifyContent="space-between"
          className={styles.error}
          p={2}
          mt={marginTop}
          ml={marginLeft}
          mb={marginBottom}
          mr={marginRight}
          gap={4}
          width={'fit-content'}
        >
          <Icon boxSize="20px" as={Slash} color="#D21122" />
          <Text>{message}</Text>
          <IconButton
            variant="ghost"
            onClick={handleCloseToast}
            aria-label="Close toast"
            icon={<Icon as={X} color="#D21122" />}
            className="no_outline_button"
          />
        </Flex>
      );
    case 'warning':
      return (
        <Flex
          data-cy="warning-toast-message"
          alignItems="center"
          justifyContent="space-between"
          className={styles.warning}
          p={2}
          mt={marginTop}
          ml={marginLeft}
          mb={marginBottom}
          mr={marginRight}
          gap={4}
          width={'fit-content'}
        >
          <Icon boxSize="20px" as={AlertTriangle} color="#F8B610" />
          <Text>{message}</Text>
          <IconButton
            variant="ghost"
            onClick={handleCloseToast}
            aria-label="Close toast"
            icon={<Icon as={X} color="#F8B610" />}
            className="no_outline_button"
          />
        </Flex>
      );
    default:
      return <></>;
  }
};

export default LimaToast;
