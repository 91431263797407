import AuthRepositoryImpl from 'data/AuthRepositoryImpl';
import GraphRepositoryImpl from 'data/GraphRepositoryImpl';
import UserBlocksRepositoryImpl from 'data/UserBlocksRepositoryImpl';
import AuthRepository from 'domain/AuthRepository';
import GraphRepository from 'domain/GraphRepository';
import UserBlocksRepository from 'domain/UserBlocksRepository';
import HttpClient from 'http/HttpClient';

class DashboardAppDeps {
  private static instance: DashboardAppDeps;

  private constructor() {}

  public static getInstance(): DashboardAppDeps {
    if (!DashboardAppDeps.instance) {
      DashboardAppDeps.instance = new DashboardAppDeps();
    }
    return DashboardAppDeps.instance;
  }

  public provideHttpClient(): HttpClient {
    return HttpClient.getInstance();
  }

  public provideAuthRepository(): AuthRepository {
    return new AuthRepositoryImpl();
  }

  public provideUserBlocksRepository(httpClient: HttpClient): UserBlocksRepository {
    return new UserBlocksRepositoryImpl(httpClient);
  }

  public provideGraphRepository(httpClient: HttpClient): GraphRepository {
    return new GraphRepositoryImpl(httpClient);
  }
}

export default DashboardAppDeps;
