import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { Circle } from 'react-feather';

export type TotalHarvestVsLossLegendProps = {
  cardBg: string;
  circleFill: string;
  label: string;
  value: number;
  isPercent?: boolean;
};

const TotalHarvestVsLossLegend = (props: TotalHarvestVsLossLegendProps) => {
  const { cardBg, circleFill, label, value, isPercent } = props;
  return (
    <Box
      bg={cardBg}
      padding="10px 10px"
      fontSize={'16px'}
      minWidth="130px"
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      justifyContent={'center'}
      borderRadius={'8px'}
    >
      <Flex alignItems={'center'} gap="0.15rem">
        <Circle fill={circleFill} color="transparent" width="16px"></Circle>
        <Text fontSize={'16px'} color="dark.coolGray.500">
          {label}
        </Text>
      </Flex>
      <Text
        fontWeight={'400'}
        fontSize={'22px'}
        lineHeight={'33px'}
        color="neutral.600"
        mt="0.75rem"
        mb="0.25rem"
      >
        {isPercent ? `${value}%` : value.toLocaleString()}
      </Text>
    </Box>
  );
};

export default TotalHarvestVsLossLegend;
