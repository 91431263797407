import React from 'react';
import {
  Input as CKInput,
  InputProps,
  FormLabel as CKFormLabel,
  FormLabelProps,
  Textarea as CkTextarea,
  TextareaProps,
  Checkbox as CkCheckbox,
  CheckboxProps,
  useToast
} from '@chakra-ui/react';
import { numberInputOnWheelPreventChange } from 'Helpers/helpers';
import LimaToast from 'Components/Toast/LimaToast';

const focus = {
  outline: 'none',
  borderColor: 'info.400'
};
const hover = {
  borderColor: 'dark.coolGray.200'
};
const exceptThisSymbols = ['e', 'E', '+', '-'];
export function Input(props: InputProps): JSX.Element {
  const { id } = props;
  const toast = useToast();
  const showWarningToast = (message: string) => {
    toast({
      position: 'top-right',
      render: () => (
        <LimaToast status="warning" message={message} marginTop={'10vh'} marginRight={'3vw'} />
      ),
      duration: 5000,
      isClosable: true
    });
  };
  return (
    <CKInput
      _placeholder={{ fontWeight: 400, color: 'dark.coolGray.300' }}
      color="black.lighter.400"
      borderRadius={4}
      borderWidth="1.5px"
      borderColor="dark.coolGray.200"
      errorBorderColor="error.400"
      outline="none"
      pl={id === 'orgName' ? '3' : '9'} // temporary solution for unwanted padding
      _focusWithin={focus}
      _focus={focus}
      _hover={hover}
      onWheel={(e) => {
        // e.preventDefault();
        e.target.dispatchEvent(new Event('blur'));
      }}
      onKeyDown={(e) =>
        props.type == 'number' ? exceptThisSymbols.includes(e.key) && e.preventDefault() : null
      }
      onPaste={(e: any) => {
        if (props.type == 'number') {
          setTimeout(() => {
            if (
              !e?.target?.value ||
              exceptThisSymbols.some((substring) => e?.target?.value.includes(substring))
            ) {
              showWarningToast('Only numbers are allowed');
            }
          }, 1000);
        }
      }}
      {...props}
    />
  );
}

export function Textarea(props: TextareaProps): JSX.Element {
  return (
    <CkTextarea
      _placeholder={{ fontWeight: 400, color: 'dark.coolGray.300' }}
      borderRadius={4}
      borderColor="dark.coolGray.200"
      color="black.lighter.400"
      borderWidth="1.5px"
      outline="none"
      errorBorderColor="error.400"
      pl="9"
      _focusWithin={focus}
      _focus={focus}
      _hover={hover}
      padding="0.5rem 1rem"
      {...props}
    />
  );
}

export const InputWithIcon = (props: InputProps): JSX.Element => {
  return <Input onWheel={numberInputOnWheelPreventChange} pl="9" {...props} />;
};

export const FormLabel = (props: FormLabelProps): JSX.Element => {
  return <CKFormLabel color="black.lighter.400" fontWeight={400} fontSize="16px" {...props} />;
};

export const Checkbox = (props: CheckboxProps): JSX.Element => {
  return <CkCheckbox borderColor="dark.coolGray.300" color="black.lighter.400" {...props} />;
};
