import DashboardAppDeps from 'di/DashboardAppDeps';
import AuthRepository from 'domain/AuthRepository';
import UserBlocksRepository from 'domain/UserBlocksRepository';
import HttpClient from 'http/HttpClient';

const appDeps: DashboardAppDeps = DashboardAppDeps.getInstance();
const httpClient: HttpClient = appDeps.provideHttpClient();
const userBlocksRepository: UserBlocksRepository = appDeps.provideUserBlocksRepository(httpClient);
const authRepository: AuthRepository = appDeps.provideAuthRepository();
const graphRepository = appDeps.provideGraphRepository(httpClient);

export { appDeps, httpClient, userBlocksRepository, authRepository, graphRepository };
