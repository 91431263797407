import { Demand, Farm, ProductionCategory, Variety } from 'Context/types';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Flex,
  Icon,
  Spinner,
  Table,
  Th,
  Thead,
  Tr,
  Text,
  Tbody,
  Td,
  Input,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react';
import { Button, FilterDropDown } from 'Components';
import { Calendar, Save } from 'react-feather';
import {
  findDigitsInString,
  formatToServerFormat,
  getWeeksYear,
  varietyIsValid
} from 'Helpers/helpers';
import { Field, Formik, FormikProps, useFormikContext } from 'formik';
import { DemandDataInputSchema } from 'Validations';
import styles from './../../../Styles/Table.module.scss';
import { Tab } from 'Components/Navigation';
import { httpClient } from 'Helpers/appDependenciesHelpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useDemandQuery from '../useDemandQuery';
import { useAuthContext } from 'Context';
import { useSearchParams } from 'react-router-dom';

type GroupedUpdateDemandData = {
  newData: Demand[];
  deletedData: Demand[];
  updatedData: Demand[];
};

export type SummaryTableProps = {
  farm: Farm;
  variety: Variety;
  token: string;
  handleHasUnsavedEdits: (value: boolean) => void;
  handleUpdatedAt: (date: string) => void;
  expectedDemandTableNotSaved: boolean;
  productionCategories: ProductionCategory[];
};

type DemandDataHolder = {
  demand_inputs: DemandData[];
};

type DemandData = {
  variety_id: string;
  grading: Demand[];
  totalYieldValue: number;
  week: number;
  entryDate: string;
};

type LoadingStates = {
  loadingExpectedDemand: boolean;
  addingExpectedDemand: boolean;
  updatingExpectedDemand: boolean;
  deletingExpectedDemand: boolean;
  sorting: boolean;
};

const ExpectedInputDemandTable = (props: SummaryTableProps) => {
  const {
    farm,
    variety,
    token,
    handleUpdatedAt,
    expectedDemandTableNotSaved,
    productionCategories
  } = props;
  const varietyId = variety.id;
  const [shouldReloadValues, setShouldReloadValues] = useState(false);
  const formRef = useRef();
  const productionPlanInputsEndRef = useRef<HTMLTableCellElement>(null);
  const [productionPlanTableHeaders, setProductionPlanTableHeaders] = useState<string[]>([]);
  const [years, setYears] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>('Year ' + moment().year().toString());
  const [selectedYearDigit, setSelectedYearDigit] = useState<number>(moment().year());
  const [demandData, setDemandData] = useState<DemandData[]>([]);
  const [initialValues, setInitialValues] = useState<DemandDataHolder>({ demand_inputs: [] });
  const [prevValues, setPrevValues] = useState<DemandDataHolder>({ demand_inputs: [] });
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({
    loadingExpectedDemand: false,
    updatingExpectedDemand: false,
    deletingExpectedDemand: false,
    addingExpectedDemand: false,
    sorting: false
  });
  const [loading, setLoading] = useState(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<string>('');
  const {
    variables: { user }
  } = useAuthContext();
  const BASE_URL = process.env.REACT_APP_BE_URL;
  const queryClient = useQueryClient();
  const currentDemandQueryKeys: (string | null | undefined)[] = [];
  const [searchParams, setSearchParams] = useSearchParams();

  const populateYearPicker = () => {
    // current year + and - 1 year using moment js
    const currentYear = moment().year();
    setSelectedYearDigit(currentYear);
    const years_number = [currentYear - 1, currentYear, currentYear + 1];
    const years_string = years_number.map((year) => 'Year ' + year.toString());
    setYears(years_string);
  };

  useEffect(() => {
    populateYearPicker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(
      loadingStates.loadingExpectedDemand ||
        loadingStates.updatingExpectedDemand ||
        loadingStates.deletingExpectedDemand ||
        loadingStates.addingExpectedDemand ||
        loadingStates.sorting
    );
  }, [
    loadingStates.loadingExpectedDemand,
    loadingStates.updatingExpectedDemand,
    loadingStates.deletingExpectedDemand,
    loadingStates.addingExpectedDemand,
    loadingStates.sorting
  ]);

  useEffect(() => {
    if (demandData?.length > 0) {
      const max: Demand = extractDemandListFromDemandData(demandData).reduce(function (
        a: Demand,
        b: Demand
      ) {
        if (a.updated_at && b.updated_at) {
          return a.updated_at > b.updated_at ? a : b;
        } else {
          return (a?.id ?? 0) > (b?.id ?? 0) ? a : b;
        }
      });
      if (process.env.REACT_APP_ENVIRONMENT !== 'testing') {
        setLastUpdatedAt(
          moment
            .utc(max.updated_at ?? max.entry_date)
            .tz(moment.tz.guess())
            .format('DD-MMM-YYYY , h:mm a')
        );
      } else {
        setLastUpdatedAt(
          moment
            .utc(max.updated_at ?? max.entry_date)
            .tz('Africa/Nairobi')
            .format('DD-MMM-YYYY , h:mm a')
        );
      }
    } else {
      setLastUpdatedAt('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demandData]);

  useEffect(() => {
    handleUpdatedAt(lastUpdatedAt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdatedAt]);

  const [demandDataToScrollTo, setDemandDataToScrollTo] = useState<DemandData | null>(null);

  const startDateFromSelectedYearDigit = (): string => {
    return formatToServerFormat(moment().isoWeekYear(selectedYearDigit).startOf('year').toDate());
  };

  const endDateFromSelectedYearDigit = (): string => {
    return formatToServerFormat(moment().isoWeekYear(selectedYearDigit).endOf('year').toDate());
  };

  useEffect(() => {
    setSearchParams({
      start_date: startDateFromSelectedYearDigit(),
      end_date: endDateFromSelectedYearDigit()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYearDigit]);

  const populateTableHeaders = (productionCategories: ProductionCategory[]) => {
    const titles = productionCategories.map((prodCat) => prodCat.external_name);
    const headers = ['', 'Week Number', 'Total Stem Target', ...titles];
    setProductionPlanTableHeaders(headers);
  };

  useEffect(() => {
    populateTableHeaders(productionCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionCategories]);

  const demandQuery = useDemandQuery({
    currentUserEmail: user?.email,
    token: token,
    startDate: searchParams.get('start_date') ?? startDateFromSelectedYearDigit(),
    endDate: searchParams.get('end_date') ?? endDateFromSelectedYearDigit(),
    varietyId: varietyIsValid(farm, variety) ? variety.id : null,
    farmId: varietyIsValid(farm, variety) ? farm.id : null,
    tenantHeader: varietyIsValid(farm, variety) ? farm?.organization?.internal_name : '',
    onDemandQueryKeyCreated: (queryKey: (string | null | undefined)[]) => {
      currentDemandQueryKeys.push(...queryKey);
    }
  });

  const getIsoWeekFromDateString = (date: string): number => {
    return moment(date).isoWeek();
  };

  const sortDemandData = async (
    data: Demand[],
    currentSelectedYear: number
  ): Promise<DemandData[]> => {
    return new Promise((resolve, reject) => {
      try {
        const groupedData: DemandData[] = [];
        const _noOfWeeks = getWeeksYear(currentSelectedYear);
        for (let i = 1; i <= _noOfWeeks; i++) {
          const weekData = data.filter(
            (demand) => getIsoWeekFromDateString(demand.entry_date) === i
          );
          const entryDate = weekData.length ? weekData[0].entry_date : '';
          const variety_id = weekData.length ? weekData[0].variety_id : '';
          let totalYieldValue = 0;
          for (let j = 0; j < weekData.length; j++) {
            const demand = weekData[j];
            totalYieldValue += demand?.yield_value ?? 0;
          }
          const demandData: DemandData = {
            variety_id: variety_id,
            grading: weekData,
            totalYieldValue: totalYieldValue,
            week: i,
            entryDate: entryDate
          };
          groupedData.push(demandData);
        }
        resolve(groupedData);
      } catch (error) {
        reject(error);
      }
    });
  };

  const prepareDemandData = async (
    data: Demand[],
    currentSelectedYear: number,
    prodCategories: ProductionCategory[]
  ): Promise<DemandData[]> => {
    return new Promise(async (resolve, reject) => {
      try {
        const sortedDemandData = await sortDemandData(data, currentSelectedYear);
        const _demandData: DemandData[] = sortedDemandData.filter(
          (demand: DemandData) => demand.entryDate !== ''
        ) as DemandData[];
        if (_demandData.length) {
          setDemandDataToScrollTo(_demandData[_demandData.length - 1]);
        }
        const _noOfWeeks = getWeeksYear(currentSelectedYear);
        // add empty rows for missing weeks
        for (let i = 1; i <= _noOfWeeks; i++) {
          const weekExists = _demandData.find((demand) => demand.week === i);
          if (!weekExists) {
            const firstDateOfTheIsoWeek = moment()
              .isoWeekYear(currentSelectedYear)
              .isoWeek(i)
              .toDate();
            const entryDate = formatToServerFormat(firstDateOfTheIsoWeek);
            const grading = prodCategories.map((prodCat) => {
              return {
                id: 0,
                variety_id: varietyId,
                production_category_id: prodCat.id,
                entry_date: entryDate
              };
            });
            const dd = {
              variety_id: varietyId,
              grading: grading,
              totalYieldValue: 0,
              week: i,
              entryDate: ''
            };
            _demandData.push(dd);
          } else {
            // add empty rows for missing production categories
            const grading = prodCategories.map((prodCat) => {
              const gradeExists = weekExists.grading.find(
                (grade) => grade.production_category_id === prodCat.id
              );
              if (!gradeExists) {
                return {
                  id: 0,
                  variety_id: varietyId,
                  production_category_id: prodCat.id,
                  entry_date: weekExists.entryDate
                };
              } else {
                return gradeExists;
              }
            });
            weekExists.grading = grading;
          }
        }
        // sort by week
        _demandData.sort((a, b) => (a.week > b.week ? 1 : -1));
        resolve(_demandData);
      } catch (error) {
        reject(error);
      }
    });
  };

  // fix for loading expected demand not setting to false
  useEffect(() => {
    if (loadingStates.loadingExpectedDemand && !demandQuery.isLoading) {
      setLoadingStates({ ...loadingStates, loadingExpectedDemand: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingStates]);

  const handleDemandData = async (
    data: Demand[],
    currentSelectedYear: number,
    prodCategories: ProductionCategory[]
  ) => {
    setLoadingStates({ ...loadingStates, sorting: true });
    const _demandData: DemandData[] = await prepareDemandData(
      data,
      currentSelectedYear,
      prodCategories
    );
    setDemandData([]);
    setPrevValues({ demand_inputs: [] });
    setInitialValues({ demand_inputs: [] });
    setTimeout(() => {
      setLoadingStates({ ...loadingStates, sorting: false });
      setDemandData([..._demandData]);
      setPrevValues({ demand_inputs: [..._demandData] });
      setInitialValues({ demand_inputs: [..._demandData] });
      setShouldReloadValues(true);
    }, 500);
  };

  useEffect(() => {
    setLoadingStates({ ...loadingStates, loadingExpectedDemand: demandQuery.isLoading });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demandQuery.isLoading]);

  useEffect(() => {
    if (!demandQuery.isLoading && demandQuery.data) {
      handleDemandData(demandQuery.data ?? [], selectedYearDigit, productionCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demandQuery.data, productionCategories]);

  useEffect(() => {
    setShouldReloadValues(true);
  }, [initialValues]);

  useEffect(() => {
    props.handleHasUnsavedEdits(formRef.current ? formRef.current : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef.current]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (shouldReloadValues) {
      // formik `enableReinitialize` is almost infinite so we only do it for a short time
      timeout = setTimeout(() => {
        setShouldReloadValues(false);
      }, 10);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shouldReloadValues]);

  // scroll latest actuals into view
  const scrollToBottom = () => {
    if (productionPlanInputsEndRef && productionPlanInputsEndRef.current) {
      const options: ScrollIntoViewOptions = {
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      };
      productionPlanInputsEndRef.current.scrollIntoView(options);
    }
  };

  // TODO: scroll to bottom when new actuals are added
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (initialValues.demand_inputs.length > 0) {
      timeout = setTimeout(() => scrollToBottom(), 500);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [initialValues.demand_inputs.length]);

  const onSelectYear = (index: number) => {
    if (years.length === 3) {
      const yearStr = years[index];
      const yearDig = findDigitsInString(yearStr) ?? 0;
      setSelectedYear(yearStr);
      setSelectedYearDigit(yearDig);
    }
  };

  const extractDemandListFromDemandData = (data: DemandData[]): Demand[] => {
    const demandList: Demand[] = [];
    data.forEach((demandData) => {
      const grading = demandData.grading;
      grading.forEach((grade) => {
        const _grade: Demand = {
          id: grade.id ?? 0,
          variety_id: grade.variety_id ?? varietyId,
          production_category_id: grade.production_category_id,
          entry_date: grade.entry_date ?? '',
          yield_value: grade.yield_value ?? 0
        };
        demandList.push(_grade);
      });
    });
    return demandList;
  };

  const addDemandData = async (newDemandData: Demand[]) => {
    await httpClient
      .getAxios()
      .post(`${BASE_URL}/demands`, newDemandData, {
        headers: {
          Authorization: token,
          'Tenant-Header': farm.organization.internal_name
        }
      })
      .catch(() => {});
  };

  const createDemandDataMutation = useMutation({
    mutationKey: ['createDemandData'],
    mutationFn: addDemandData,
    onMutate: () => {
      // reset errors
      createDemandDataMutation.reset();
      setLoadingStates({ ...loadingStates, addingExpectedDemand: true });
    },
    onError: () => {
      // An error happened!
      setLoadingStates({ ...loadingStates, addingExpectedDemand: false });
    },
    onSuccess: () => {
      setLoadingStates({ ...loadingStates, addingExpectedDemand: false });
      queryClient.invalidateQueries({ queryKey: currentDemandQueryKeys });
    },
    onSettled: () => {
      // Error or success... doesn't matter!
      setLoadingStates({ ...loadingStates, addingExpectedDemand: false });
    }
  });

  const updateDemandData = async (updatedDemandData: Demand[]) => {
    await httpClient
      .getAxios()
      .put(`${BASE_URL}/demands`, updatedDemandData, {
        headers: {
          Authorization: token,
          'Tenant-Header': farm.organization.internal_name
        }
      })
      .catch(() => {});
  };

  const updateDataMutation = useMutation({
    mutationKey: ['updateDemandData'],
    mutationFn: updateDemandData,
    onMutate: () => {
      // reset errors
      updateDataMutation.reset();
      setLoadingStates({ ...loadingStates, updatingExpectedDemand: true });
    },
    onError: () => {
      // An error happened!
      setLoadingStates({ ...loadingStates, updatingExpectedDemand: false });
    },
    onSuccess: () => {
      setLoadingStates({ ...loadingStates, updatingExpectedDemand: false });
      queryClient.invalidateQueries({ queryKey: currentDemandQueryKeys });
    },
    onSettled: () => {
      // Error or success... doesn't matter!
      setLoadingStates({ ...loadingStates, updatingExpectedDemand: false });
    }
  });

  const deleteDemandData = async (deletedDemandData: Demand[]) => {
    const promises = deletedDemandData.map((demand) => {
      return httpClient
        .getAxios()
        .delete(`${BASE_URL}/demands/${demand.id}`, {
          headers: {
            Authorization: token,
            'Tenant-Header': farm.organization.internal_name
          }
        })
        .catch(() => {});
    });
    await Promise.all(promises);
  };

  const deleteDataMutation = useMutation({
    mutationKey: ['deleteDemandData'],
    mutationFn: deleteDemandData,
    onMutate: () => {
      // reset errors
      deleteDataMutation.reset();
      setLoadingStates({ ...loadingStates, deletingExpectedDemand: true });
    },
    onError: () => {
      // An error happened!
      setLoadingStates({ ...loadingStates, deletingExpectedDemand: false });
    },
    onSuccess: () => {
      setLoadingStates({ ...loadingStates, deletingExpectedDemand: false });
      queryClient.invalidateQueries({ queryKey: currentDemandQueryKeys });
    },
    onSettled: () => {
      // Error or success... doesn't matter!
      setLoadingStates({ ...loadingStates, deletingExpectedDemand: false });
    }
  });

  const findDeletedData = (currV: Demand[], prevV: Demand[]): Demand[] => {
    return prevV.filter(
      (prevValue) =>
        !currV.find((currValue) => currValue.id === prevValue.id && currValue.yield_value)
    );
  };

  const findUpdatedData = (currV: Demand[], prevV: Demand[]): Demand[] => {
    return currV.filter((currValue) =>
      prevV.find(
        (prevValue) =>
          prevValue.id === currValue.id &&
          currValue.yield_value &&
          prevValue.yield_value !== currValue.yield_value
      )
    );
  };

  const groupDataToUpdate = async (values: DemandDataHolder): Promise<GroupedUpdateDemandData> => {
    return new Promise((resolve, reject) => {
      try {
        const _currValues = extractDemandListFromDemandData(values.demand_inputs);
        const _prevValues = extractDemandListFromDemandData(prevValues.demand_inputs).filter(
          (prevValue) => prevValue.id !== 0
        );
        const filteredCurrValues = _currValues.filter((currValue) => currValue.id !== 0);
        // new data has id = 0
        const newData = _currValues.filter(
          (currValue) => currValue.id === 0 && currValue.yield_value && currValue.yield_value > 0
        );
        // deleted data has id > 0 but not in current values
        const deletedData = findDeletedData(filteredCurrValues, _prevValues);
        // updated data has id > 0 and in current values and not in deleted values
        const updatedData = findUpdatedData(filteredCurrValues, _prevValues);
        resolve({
          newData: newData,
          deletedData: deletedData,
          updatedData: updatedData
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = async (values: DemandDataHolder, actions: any) => {
    setLoadingStates({ ...loadingStates, sorting: true });
    //const demandsToCreate = await fetchDemandsToCreate(values);
    const { newData, updatedData, deletedData } = await groupDataToUpdate(values);
    setLoadingStates({ ...loadingStates, sorting: false });
    if (newData.length > 0) {
      createDemandDataMutation.mutate(newData);
    }
    if (updatedData.length > 0) {
      updateDataMutation.mutate(updatedData);
    }
    if (deletedData.length > 0) {
      deleteDataMutation.mutate(deletedData);
    }
  };

  const productionCategoryFromProductionCategoryId = (
    prodCatId: number
  ): ProductionCategory | null => {
    const prodCat = productionCategories.find((prodCat) => prodCat.id === prodCatId);
    return prodCat ? prodCat : null;
  };

  const calculateTotalValueFromDemandData = (data: DemandData): number => {
    let totalYieldValue = 0;
    let totalRejectedYieldValue = 0;
    data.grading.forEach((grade: Demand) => {
      if (grade.yield_value) {
        const prodCat: ProductionCategory | null = productionCategoryFromProductionCategoryId(
          grade.production_category_id
        );
        // category type is 'reject' add to total rejected yield value
        if (prodCat !== null && prodCat.category_type === 'reject') {
          totalRejectedYieldValue += +grade.yield_value;
        } else {
          totalYieldValue += +grade.yield_value;
        }
      }
    });
    return totalYieldValue - totalRejectedYieldValue;
  };

  const FormObserver: React.FC = () => {
    const formik = useFormikContext<DemandDataHolder>();
    useEffect(() => {
      // updating state for unsaved edits
      props.handleHasUnsavedEdits(formik.dirty ? formik.dirty : false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);
    return <></>;
  };

  return (
    <Flex flexDir="column">
      {initialValues && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={DemandDataInputSchema}
          validateOnChange={false}
          validateOnMount={true}
          enableReinitialize={shouldReloadValues}
        >
          {({ isValid, handleSubmit, values, dirty }: FormikProps<DemandDataHolder>) => (
            <form id={'actuals_input_form'} className="login__form form" onSubmit={handleSubmit}>
              <FormObserver />
              <Tabs defaultIndex={1} align="start" variant="enclosed" mt={18} pos="relative">
                <Box
                  w="100%"
                  pos="absolute"
                  sx={{
                    top: '0',
                    right: '0',
                    zIndex: 100,
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Box display={'flex'} justifyContent="flex-end">
                    <Button
                      data-cy="btn-save-entries"
                      width="auto"
                      theme="primary"
                      type="submit"
                      size="sm"
                      mr={4}
                      disabled={!dirty && !loading && isValid ? true : false}
                    >
                      {loading ? (
                        <>
                          <Box px={1.5}></Box>
                          <Spinner color="white.500" size="sm" />
                        </>
                      ) : (
                        <Icon as={Save} stroke={'white.500'} w={4} h={4} mr={1} />
                      )}
                      Save New Entries
                    </Button>
                    <FilterDropDown
                      label={selectedYear}
                      items={years}
                      selectedItem={1}
                      onSelectFilter={onSelectYear}
                      name="production-plan"
                      LeftIcon={Calendar}
                    />
                  </Box>
                </Box>
                <TabList borderBottom={'0px'} zIndex={1}>
                  <Tab isDisabled></Tab>
                  <Tab>
                    {expectedDemandTableNotSaved ? (
                      <Text className="green-circle" as="span"></Text>
                    ) : (
                      ''
                    )}
                    Expected Demand
                  </Tab>
                </TabList>
                <TabPanels pt={4} border={'1.5px solid #CBD2D9'} borderRadius={'4px'}>
                  <TabPanel></TabPanel>
                  <TabPanel>
                    <Box
                      className={`custom-scrollbar `}
                      overflowY="auto"
                      maxHeight={'60vh'}
                      marginTop={-15}
                      my="auto"
                    >
                      <Table className={`${styles.table__content} gray-border`} size="sm">
                        <Thead
                          backgroundColor="dark.coolGray.100"
                          pos="sticky"
                          top={-0.5}
                          zIndex="docked"
                        >
                          <Tr>
                            {productionPlanTableHeaders.map((header, index) => {
                              return (
                                <Th className="gray-border" key={index + header}>
                                  <Text color="green.500" className={styles.table__actual__header}>
                                    {header}
                                  </Text>
                                </Th>
                              );
                            })}
                          </Tr>
                        </Thead>
                        <Tbody className={`${styles.table__content__body}`}>
                          <>
                            {values.demand_inputs.map((item, index) => (
                              <Tr
                                key={
                                  index +
                                  (item != null && item.variety_id != null
                                    ? item.variety_id
                                    : 'added')
                                }
                              >
                                {productionPlanTableHeaders.map((header, idx) => {
                                  return (
                                    <Td
                                      className="gray-border"
                                      key={idx + header}
                                      textAlign="center"
                                      color={'black.lighter.400'}
                                      ref={
                                        demandDataToScrollTo?.week === item.week
                                          ? productionPlanInputsEndRef
                                          : undefined
                                      }
                                    >
                                      {header === '' ? (
                                        <Text color="black.lighter.400">{index + 1}</Text>
                                      ) : header === 'Week Number' ? (
                                        <Text color="black.lighter.400">
                                          {item.week != null ? 'Week ' + item.week : '-'}
                                        </Text>
                                      ) : header === 'Total Stem Target' ? (
                                        <Field
                                          as={Input}
                                          data-cy={`demand_inputs['${index}'].totalYieldValue`}
                                          name={`demand_inputs['${index}'].totalYieldValue`}
                                          id={`demand_inputs['${index}'].totalYieldValue`}
                                          variant="unstyled"
                                          placeholder="-"
                                          color="dark.coolGray.400"
                                          type={'number'}
                                          isReadOnly={true}
                                          value={calculateTotalValueFromDemandData(item)}
                                        />
                                      ) : (
                                        <Field
                                          as={Input}
                                          data-cy={`demand_inputs['${index}'].grading['${
                                            idx - 3
                                          }'].yield_value`}
                                          name={`demand_inputs['${index}'].grading['${
                                            idx - 3
                                          }'].yield_value`}
                                          id={`demand_inputs['${index}'].grading['${
                                            idx - 3
                                          }'].yield_value`}
                                          variant="unstyled"
                                          placeholder="-"
                                          color="dark.coolGray.400"
                                          type={'number'}
                                          isReadOnly={false}
                                        />
                                      )}
                                    </Td>
                                  );
                                })}
                              </Tr>
                            ))}
                          </>
                        </Tbody>
                      </Table>
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </form>
          )}
        </Formik>
      )}
    </Flex>
  );
};

export default ExpectedInputDemandTable;
