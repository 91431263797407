import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Box
} from '@chakra-ui/react';
import { TriangleDownIcon } from '@chakra-ui/icons';
import { Button } from 'Components';
import { Icon } from 'react-feather';

type FilterDropdownProps = {
  items: string[];
  label: string;
  multi?: boolean;
  name?: string;
  showRadio?: boolean;
  showCheckbox?: boolean;
  selectedItem?: number | string;
  onSelectFilter: (selection: any | number[]) => void;
  onBlur?: any;
  hasError?: boolean;
  LeftIcon?: Icon;
  color?: string;
  disabled?: boolean;
};

export default function BasicDropDown({
  items,
  label,
  multi,
  selectedItem,
  onSelectFilter,
  name,
  onBlur,
  hasError,
  LeftIcon,
  color,
  disabled
}: FilterDropdownProps): JSX.Element {
  const [selected, setSelected] = useState<number[]>([]);
  const [isInitial, setIsInitial] = useState(true);
  const [isClicked, setIsClicked] = useState<boolean>();
  const [mouseOut, setMouseOut] = useState<boolean>();
  const onSelect = (selectedIndex: string | number) => {
    selectedIndex = parseInt(selectedIndex + '');
    let sel: number[] = [];
    if (multi) {
      if (selected.includes(selectedIndex)) {
        sel = selected.filter((selection) => selection !== selectedIndex);
      } else {
        sel = [...selected, selectedIndex];
      }
    } else {
      sel.push(selectedIndex);
    }
    setSelected(sel);
  };

  useEffect(() => {
    if (!isInitial) {
      onSelectFilter(multi ? selected : selected[0]);
    } else {
      if (selectedItem != undefined) {
        onSelect(selectedItem);
      }
      setIsInitial(false);
    }
    // only so that no selection is done  on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, multi]);
  const getHeight = () => {
    const names: string[] = ['grades-and-rejects', 'harvest-by-field', 'manage'];
    if (names.includes(`${name}`)) return '2.5rem';
    return '2rem';
  };
  const getWidth = () => {
    if (name == 'manage') return '350px';
    return '220px';
  };
  useEffect(() => {
    if (isClicked && mouseOut && name == 'manage') onBlur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked, mouseOut]);
  return (
    <Menu matchWidth closeOnSelect={!multi} isLazy>
      <Button
        onClick={() => setIsClicked(true)}
        onMouseLeave={() => (isClicked ? setMouseOut(true) : () => {})}
        data-cy={`filter-dropdown-${name}`}
        as={MenuButton}
        theme="primary"
        rightIcon={name == 'manage' ? <></> : <TriangleDownIcon />}
        leftIcon={LeftIcon ? <LeftIcon /> : <></>}
        height={getHeight()}
        width={getWidth()}
        whiteSpace="nowrap"
        border="1.5px solid"
        borderColor={color ? color : 'primary.500'}
        boxShadow={hasError ? '0 0 0 1px #db414e' : 'none'}
        px="1rem"
        style={{
          zIndex: 99
        }}
        disabled={disabled ? disabled : false}
        __css={{
          '& > span': {
            color: color ? color : 'primary.500',
            fontWeight: 400,
            fontSize: `${name == 'manage' ? '1rem' : '0.929rem'}`,
            display: 'grid !important',
            gridTemplateColumns: '1rem 1fr 1rem',
            gridColumnGap: '0.25rem',
            width: '100%',
            textAlign: 'left',
            alignItems: 'center',
            textTransform: 'capitalize',

            '& > span:last-of-type > svg': {
              transform: `${name == 'manage' ? 'scale(2)' : 'scale(0.6)'}`,

              '& > path': {
                fill: `${color ? color : 'primary.500'}`
              }
            },

            '& > span:first-of-type svg': {
              stroke: `${color ? color : 'primary.500'}`,
              transform: `${name == 'manage' ? 'scale(2.8)' : 'scale(2)'}`
            }
          }
        }}
      >
        {name != 'manage' ? <>{label}</> : <>{selected.length ? items[selected[0]] : label}</>}
      </Button>
      <MenuList minWidth="max-content" p={0} borderRadius="4" border="none" className="shadow-200">
        {items.length ? (
          multi ? (
            items.map((item: string, index: number) => (
              <MenuItem
                as={InputGroup}
                key={item + index}
                role="button"
                cursor="pointer"
                backgroundColor="transparent !important"
                _hover={{
                  backgroundColor: 'dark.coolGray.100 !important'
                }}
                data-cy={`filter-${item}`}
              >
                <Checkbox
                  onChange={() => onSelect(index)}
                  isChecked={selected.includes(index)}
                  colorScheme="primary"
                  _checked={{
                    boxShadow: 'none',
                    svg: {
                      stroke: 'white !important'
                    }
                  }}
                  color="dark.coolGray.200"
                  width="100%"
                >
                  <Text as="span" color="black.lighter.300">
                    {item}
                  </Text>
                </Checkbox>
              </MenuItem>
            ))
          ) : (
            <>
              {name === 'farm-production' ? (
                <>
                  {items.map((item: string, index: number) => (
                    <Box key={index + '-basic-dropdown'}>
                      <Button
                        as={MenuItem}
                        fontWeight="400"
                        width="100%"
                        justifyContent="flex-start"
                        _hover={{
                          backgroundColor: 'accentGreen.100'
                        }}
                        _focus={{
                          backgroundColor:
                            index === selected?.[0] ? 'accentGreen.100' : 'transparent'
                        }}
                        borderRadius="0"
                        _first={{
                          borderTopLeftRadius: '4px',
                          borderTopRightRadius: '4px'
                        }}
                        _last={{
                          borderBottomLeftRadius: '4px',
                          borderBottomRightRadius: '4px'
                        }}
                        backgroundColor={index === selected?.[0] ? 'accentGreen.100' : 'white'}
                        color={'black.lighter.300'}
                        onClick={() => onSelect(index)}
                        data-cy={`filter-${item}-${name}`}
                      >
                        {item}
                      </Button>
                    </Box>
                  ))}
                </>
              ) : (
                <RadioGroup onChange={onSelect} marginTop={0}>
                  <Stack p={0}>
                    {items.map((item: string, index: number) => (
                      <MenuItem
                        key={item + index}
                        role="button"
                        cursor="pointer"
                        backgroundColor="transparent !important"
                        _hover={{
                          backgroundColor: 'dark.coolGray.100 !important'
                        }}
                        style={{ marginTop: '0' }}
                        data-cy={`filter-${item}`}
                      >
                        <Radio
                          value={index + ''}
                          colorScheme="primary"
                          color="dark.coolGray.200"
                          width="100%"
                          marginTop={0}
                          borderColor={
                            index == selected?.[0] ? 'green.500 !important' : 'dark.coolGray.200'
                          }
                          borderWidth={index == selected?.[0] ? '4px' : '2px'}
                        >
                          <Text
                            as="span"
                            color="black.lighter.300"
                            textTransform={name == 'manage' ? 'capitalize' : 'initial'}
                          >
                            {item}
                          </Text>
                        </Radio>
                      </MenuItem>
                    ))}
                  </Stack>
                </RadioGroup>
              )}
            </>
          )
        ) : (
          <MenuItem>Nothing to filter</MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}
