import React from 'react';

import { ResponsiveBar, Bar, BarDatum } from '@nivo/bar';
import CustomBarComponent from './CustomBarComponent';
import { FarmProductionDuration, useGraphContext } from 'Context/GraphContext';
import { line } from 'd3-shape';
import { nivoTheme } from './FarmProductionBarChart';

export type CombinedBarAndLineChartProps = {
  data: BarDatum[];
  yMax: number;
  selectedYear: string;
  durationKeys: string[];
  durationColors: string[];
  lineData: any[];
  groupMode: 'grouped' | 'stacked';
  lineKey: string;
  tooltip: any;
  indexBy: string;
  layout?: 'horizontal' | 'vertical';
  duration?: FarmProductionDuration;
  extendYmax?: boolean;
};

const CombinedBarAndLineChart = (props: CombinedBarAndLineChartProps) => {
  const {
    duration,
    data,
    yMax,
    selectedYear,
    durationKeys,
    durationColors,
    groupMode,
    lineKey,
    tooltip,
    indexBy,
    layout,
    extendYmax
  } = props;

  const legend = (): string => {
    switch (duration) {
      case 'weekly':
        return 'Weeks | ' + selectedYear;
      case 'monthly':
        return 'Months | ' + selectedYear;
      case 'daily':
        return 'Days | ' + selectedYear;
      default:
        return '';
    }
  };

  const dayGraphWidth = (): number => {
    return data.length * 45;
  };

  const Line = (props: any) => {
    const bars = props.bars;
    const unique = bars
      .map((item: any) => item.data.indexValue)
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index);

    const xScale = props.xScale;
    const yScale = props.yScale;

    const lineGenerator = line()
      .x((bar: any) => xScale(bar) + bars[0].width * 0.5)
      .y((bar: any) => yScale(getY(bar)));

    return (
      <>
        <path
          d={lineGenerator(unique) as string}
          fill="none"
          stroke={durationColors[durationKeys.indexOf(lineKey)]}
          style={{ pointerEvents: 'none', strokeWidth: '2' }}
        />
      </>
    );
  };

  const getY = (yValue: any) => {
    const found = data?.find((el: any) => el.period === yValue)?.rejectedDisplayValue;
    return found ?? 0;
  };

  const { setSelectedFieldProductivity, fieldProductivity } = useGraphContext();

  const onClick = (data: any) => {
    if (data.graph_name === 'field_productivity') {
      const found = fieldProductivity?.formattedData?.find(
        (field: any) => field.period === data?.period
      );
      setSelectedFieldProductivity(found ?? null);
    }
  };
  const view = () => {
    switch (duration) {
      case 'daily':
        return data.length < 50 ? (
          <>
            <ResponsiveBar
              theme={nivoTheme}
              groupMode={groupMode}
              layout={layout ?? 'vertical'}
              colors={durationColors}
              data={data}
              keys={durationKeys}
              indexBy={indexBy}
              valueScale={{ type: 'linear' }}
              padding={0.2}
              margin={{ top: 10, right: 0, bottom: 56, left: 0 }}
              enableGridY={true}
              enableGridX={true}
              maxValue={extendYmax ? yMax * 1.3 : yMax}
              axisLeft={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -35
              }}
              barComponent={CustomBarComponent}
              tooltip={tooltip}
              layers={
                durationKeys?.includes(lineKey)
                  ? ['grid', 'axes', 'bars', Line, 'markers']
                  : ['grid', 'axes', 'bars', 'markers']
              }
              valueFormat={(value) => {
                // format number to have comma as thousand separator
                return value.toLocaleString();
              }}
            />
          </>
        ) : (
          <>
            <Bar
              height={700}
              width={dayGraphWidth()}
              theme={nivoTheme}
              groupMode={groupMode}
              layout={layout ?? 'vertical'}
              colors={durationColors}
              data={data}
              keys={durationKeys}
              indexBy={indexBy}
              valueScale={{ type: 'linear' }}
              padding={0.4}
              margin={{ top: 10, right: 0, bottom: 56, left: 0 }}
              enableGridY={true}
              enableGridX={true}
              maxValue={extendYmax ? yMax * 1.3 : yMax}
              axisLeft={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -35
              }}
              barComponent={CustomBarComponent}
              tooltip={tooltip}
              indexScale={{ type: 'band', round: false }}
              layers={
                durationKeys?.includes(lineKey)
                  ? ['grid', 'axes', 'bars', Line, 'markers']
                  : ['grid', 'axes', 'bars', 'markers']
              }
              valueFormat={(value) => {
                // format number to have comma as thousand separator
                return value.toLocaleString();
              }}
            />
          </>
        );
      case 'weekly':
        return (
          <ResponsiveBar
            theme={nivoTheme}
            groupMode={groupMode}
            layout={layout ?? 'vertical'}
            colors={durationColors}
            data={data}
            keys={durationKeys}
            indexBy={indexBy}
            valueScale={{ type: 'linear' }}
            padding={0.4}
            margin={{ top: 10, right: 0, bottom: 56, left: 0 }}
            enableGridY={true}
            enableGridX={true}
            maxValue={extendYmax ? yMax * 1.3 : yMax}
            axisLeft={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              legend: legend(),
              legendPosition: 'middle',
              legendOffset: 40
            }}
            barComponent={CustomBarComponent}
            tooltip={tooltip}
            layers={
              durationKeys?.includes(lineKey)
                ? ['grid', 'axes', 'bars', Line, 'markers']
                : ['grid', 'axes', 'bars', 'markers']
            }
            valueFormat={(value) => {
              // format number to have comma as thousand separator
              return value.toLocaleString();
            }}
          />
        );
      case 'monthly':
        return (
          <ResponsiveBar
            theme={nivoTheme}
            groupMode={groupMode}
            layout={layout ?? 'vertical'}
            colors={durationColors}
            data={data}
            keys={durationKeys}
            indexBy={indexBy}
            valueScale={{ type: 'linear' }}
            padding={0.65}
            margin={{ top: 10, right: 0, bottom: 60, left: 0 }}
            enableGridY={true}
            enableGridX={true}
            maxValue={extendYmax ? yMax * 1.3 : yMax}
            axisLeft={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              legend: legend(),
              legendPosition: 'middle',
              legendOffset: 40
            }}
            barComponent={CustomBarComponent}
            tooltip={tooltip}
            valueFormat={(value) => {
              // format number to have comma as thousand separator
              return value.toLocaleString();
            }}
          />
        );
      default:
        return (
          <>
            {data?.some((el: any) => el.graph_name === 'field_productivity') && (
              <ResponsiveBar
                theme={nivoTheme}
                groupMode={groupMode}
                layout={layout ?? 'vertical'}
                colors={durationColors}
                data={data}
                keys={durationKeys}
                indexBy={indexBy}
                valueScale={{ type: 'linear' }}
                padding={0.4}
                margin={{
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 90
                }}
                enableGridY={true}
                enableGridX={true}
                axisBottom={null}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  format: (v: any) => {
                    return v.substring(0, 8) + (v.length > 8 ? '...' : '');
                  }
                }}
                barComponent={CustomBarComponent}
                tooltip={tooltip}
                layers={
                  durationKeys?.includes(lineKey)
                    ? ['grid', 'axes', 'bars', Line, 'markers']
                    : ['grid', 'axes', 'bars', 'markers']
                }
                onClick={onClick}
                maxValue={extendYmax ? yMax * 1.1 : yMax}
                animate={true}
              />
            )}
          </>
        );
    }
  };

  return view();
};

export default CombinedBarAndLineChart;
