import React from 'react';
import { ActualsContext, AppContext, AuthContext, useAuthContext } from './Context';
import './Styles/app.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MyRoute, OTHER_ROUTES, PRIVATE_ROUTES, PUBLIC_ROUTES } from './routes';
import { ThemeProvider } from 'styled-components';

import { ChakraProvider } from '@chakra-ui/react';
import { theme, styledTheme } from './chakra';
import Authenticated from 'Layout/Authenticated';
import Guest from 'Layout/Guest';
import { ManageVarieties, ManageFarms, ManageUsers, NotFound } from 'Pages';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import GraphContext from 'Context/GraphContext';
import DashboardAppDeps from 'di/DashboardAppDeps';
import HttpClient from 'http/HttpClient';
import { AuthenticatedAdmin } from 'Pages/Settings/Components';
import { MatomoProvider, createInstance, useMatomo } from '@jonkoops/matomo-tracker-react';
import { useCookies } from 'react-cookie';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const appDeps: DashboardAppDeps = DashboardAppDeps.getInstance();
const httpClient: HttpClient = appDeps.provideHttpClient();
const graphRepository = appDeps.provideGraphRepository(httpClient);

function App(): JSX.Element {
  const renderRoute = (route: MyRoute, isPrivate?: Boolean) => {
    const Wrapper = isPrivate ? Authenticated : Guest;
    return (
      <Route
        path={route.path}
        key={route.path}
        element={
          <Wrapper>
            <route.element />
          </Wrapper>
        }
      >
        {route.nested?.length
          ? route.nested.map((route: MyRoute) => renderRoute(route, route?.private))
          : null}
      </Route>
    );
  };

  const renderOtherRoute = (route: MyRoute) => {
    return (
      <Route path={route.path} key={route.path} element={<route.element />}>
        {route.nested?.length ? route.nested.map((route: MyRoute) => renderRoute(route)) : null}
      </Route>
    );
  };

  const {
    variables: { user }
  } = useAuthContext();

  const [userCookie] = useCookies(['user']);
  const siteId = process.env.REACT_APP_ENVIRONMENT === 'production' ? 3 : 4;
  const matomoInstance = createInstance({
    urlBase: 'https://lima.matomo.cloud',
    siteId: siteId,
    userId: user?.email
      ? user.email
      : userCookie?.user?.email
      ? userCookie?.user?.email
      : undefined, // optional, default value: `undefined`.
    // trackerUrl: 'https://lima.matomo.cloud/tracking.php', // optional, default value: `${urlBase}matomo.php`
    // srcUrl: 'https://lima.matomo.cloud/tracking.js', // optional, default value: `${urlBase}matomo.js`
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: {
      // optional, enabled by default
      active: true, // optional, default value: true
      seconds: 10 // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: {
      // optional, default value: {}
      // any valid matomo configuration, all below are optional
      disableCookies: true,
      setSecureCookie: true,
      setRequestMethod: 'POST'
    }
  });

  const { enableLinkTracking } = useMatomo();
  enableLinkTracking();

  const queryClient = new QueryClient();

  return (
    <MatomoProvider value={matomoInstance}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <ThemeProvider theme={styledTheme}>
            <BrowserRouter>
              <AppContext>
                <AuthContext>
                  <ActualsContext>
                    <GraphContext graphRepository={graphRepository}>
                      <Routes>
                        {PRIVATE_ROUTES.map((route: MyRoute) => renderRoute(route, true))}
                        {PUBLIC_ROUTES.map((route: MyRoute) => renderRoute(route))}
                        <Route path="/settings" key="settings" element={<AuthenticatedAdmin />}>
                          <Route index element={<ManageFarms />} />
                          <Route path="farms" element={<ManageFarms />} />
                          <Route path="varieties" element={<ManageVarieties />} />
                          <Route path="members" element={<ManageUsers />} />
                        </Route>
                        {OTHER_ROUTES.map((route: MyRoute) => renderOtherRoute(route))}
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </GraphContext>
                  </ActualsContext>
                </AuthContext>
              </AppContext>
            </BrowserRouter>
          </ThemeProvider>
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </MatomoProvider>
  );
}

export default App;
