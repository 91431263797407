import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { Square } from 'react-feather';
import { snakeToTitleCase } from 'Helpers/helpers';

const CustomTooltip = (props: any) => {
  return (
    <Box
      style={{
        padding: 12,
        background: '#ffffff',
        boxShadow: '0px 1px 6px rgba(12, 12, 13, 0.15)',
        textAlign: 'center',
        zIndex: '999'
      }}
    >
      <Flex alignItems={'center'} gap="0.15rem">
        <Square fill={props.color} color="transparent" width="16px"></Square>
        <Text fontSize={'0.9rem'} color="dark.coolGray.500">
          <Text as={'span'} fontWeight="400">
            {snakeToTitleCase(props.data.id)} :{' '}
          </Text>
          {`${props.data.formattedValue}`}
        </Text>
      </Flex>
    </Box>
  );
};

export default CustomTooltip;
