import { BarDatum } from '@nivo/bar';
import { useQuery } from '@tanstack/react-query';
import { Demand, GraphDemandData } from 'Context/types';
import { httpClient } from 'Helpers/appDependenciesHelpers';
import moment from 'moment';
import useDemandQuery from 'Pages/ProductionPlan/useDemandQuery';
import { useEffect, useState } from 'react';

export type ActualsVsForecastQueryInput = {
  farmId: string;
  currentUserEmail: string;
  token: string;
  varietyId: string;
  startDate: string;
  endDate: string;
  granularity: 'days' | 'weeks' | 'months';
  tenantHeader: string;
  enabled: boolean;
  forceRefresh?: boolean;
};

export type ActualsVsForecastQuery = {
  isLoading: boolean;
  barDatum: BarDatum[];
  demandData: GraphDemandData[];
  yMax: number;
  hasNext: boolean;
  hasPrevious: boolean;
};

type ProductionCategoryIds = {
  [key: string]: string;
};

type ForecastHarvestsQueryMetadata = {
  variety_id: string;
  milestone: string;
  duration_out: number;
  granularity: 'days' | 'weeks' | 'months';
  start_date: string;
  end_date: string;
  production_category_ids: ProductionCategoryIds;
};

type ForecastHarvestResponse = {
  variety_internal_name: string;
  variety_external_name: string;
  dates: string[];
  rejects: number[];
  detailed_view: { [key: string]: number }[];
  query_metadata: ForecastHarvestsQueryMetadata;
};

type ForecastResponse = ForecastHarvestResponse & {
  forecasts: number[];
  field_forecasts: any;
};

type HarvestsResponse = ForecastHarvestResponse & {
  harvests: number[];
  field_harvests: any;
};

type MilestoneForecast = { [key: string]: number };

type MilestoneForecastResponse = ForecastHarvestResponse & {
  field_forecasts: any;
  forecasts: MilestoneForecast[];
};

const useActualsVsForecastQuery = (
  queryInput: ActualsVsForecastQueryInput
): ActualsVsForecastQuery => {
  const {
    varietyId,
    startDate,
    endDate,
    granularity,
    tenantHeader,
    enabled,
    forceRefresh,
    token,
    currentUserEmail,
    farmId
  } = queryInput;
  const queryEnabled =
    !!currentUserEmail &&
    !!token &&
    !!varietyId &&
    !!startDate &&
    !!endDate &&
    !!granularity &&
    !!tenantHeader &&
    enabled;
  const BASE_URL = `${process.env.REACT_APP_BE_URL}`;
  const forecastQueryKeys = [
    'forecast',
    currentUserEmail,
    varietyId,
    farmId,
    startDate,
    endDate,
    granularity
  ];
  const harvestsQueryKeys = [
    'harvests',
    currentUserEmail,
    varietyId,
    farmId,
    startDate,
    endDate,
    granularity
  ];
  const milestoneForecastsQueryKeys = [
    'milestoneForecasts',
    currentUserEmail,
    varietyId,
    farmId,
    startDate,
    endDate,
    granularity
  ];
  const isAllFarms = farmId === 'all';
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState<BarDatum[]>([]);
  const [demandData, setDemandData] = useState<GraphDemandData[]>([]);
  const [yMax, setYMax] = useState(0);
  const [hasNext, setHasNext] = useState(true);
  const [hasPrevious, setHasPrevious] = useState(true);

  useEffect(() => {
    setRefresh(forceRefresh ?? false);
  }, [forceRefresh]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (refresh) {
      timeout = setTimeout(() => {
        setRefresh(false);
      }, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [refresh]);

  async function getMilestoneForecasts(): Promise<MilestoneForecastResponse | null> {
    let milestoneForecastResponse: MilestoneForecastResponse | null = null;
    if (token) {
      const res = await httpClient
        .getAxios()
        .get(`${BASE_URL}/varieties/${varietyId}/milestone-forecasts`, {
          headers: {
            Authorization: token,
            'Tenant-Header': tenantHeader
          },
          params: {
            start_date: startDate,
            end_date: endDate,
            granularity: granularity,
            all_farms: isAllFarms
          }
        })
        .catch(() => {});
      if (res && res?.status === 403) milestoneForecastResponse = null;
      else if (res?.data) {
        milestoneForecastResponse = res.data;
      }
    }
    return milestoneForecastResponse;
  }

  async function getForecastData(): Promise<ForecastResponse | null> {
    let forecastResponse: ForecastResponse | null = null;
    if (token) {
      const res = await httpClient
        .getAxios()
        .get(`${BASE_URL}/varieties/${varietyId}/latest-forecasts`, {
          headers: {
            Authorization: token,
            'Tenant-Header': tenantHeader
          },
          params: {
            start_date: startDate,
            end_date: endDate,
            granularity: granularity,
            all_farms: isAllFarms
          }
        })
        .catch(() => {});
      if (res && res?.status === 403) forecastResponse = null;
      else if (res?.data) {
        forecastResponse = res.data;
      }
    }
    return forecastResponse;
  }

  async function getHarvestData(): Promise<HarvestsResponse | null> {
    let harvestResponse: HarvestsResponse | null = null;
    if (token) {
      const res = await httpClient
        .getAxios()
        .get(`${BASE_URL}/varieties/${varietyId}/graph-harvests`, {
          headers: {
            Authorization: token,
            'Tenant-Header': tenantHeader
          },
          params: {
            start_date: startDate,
            end_date: endDate,
            granularity: granularity,
            all_farms: isAllFarms
          }
        })
        .catch(() => {});
      if (res && res?.status === 403) harvestResponse = null;
      else if (res?.data) {
        harvestResponse = res.data;
      }
    }
    return harvestResponse;
  }

  const getDemandDataQuery = useDemandQuery({
    currentUserEmail,
    token,
    startDate,
    endDate,
    varietyId,
    farmId: null,
    tenantHeader,
    forceInvalidateQuery: refresh,
    enabled: queryEnabled
  });

  const getForecastQuery = useQuery({
    queryKey: forecastQueryKeys,
    queryFn: getForecastData,
    enabled: queryEnabled
  });

  const getHarvestsQuery = useQuery({
    queryKey: harvestsQueryKeys,
    queryFn: getHarvestData,
    enabled: queryEnabled
  });

  // NB: Uncomment the commented code to re-enable day/weeks out milestone functionality
  // const milestoneForecastsQuery = useQuery({
  //   queryKey: milestoneForecastsQueryKeys,
  //   queryFn: getMilestoneForecasts,
  //   enabled: queryEnabled
  // });

  const allDataLoaded =
    !getForecastQuery.isLoading && !getHarvestsQuery.isLoading && !getDemandDataQuery.isLoading;
  // &&   !milestoneForecastsQuery.isLoading;

  const getIsoWeekFromDate = (date: string): number => {
    return moment(date).isoWeek();
  };

  const getDayFromDate = (date: string): string => {
    return moment(date).format('MMM DD');
  };

  const getMilestoneDurationKey = (weeksOut: number): string => {
    if (granularity === 'days') {
      return `days_out_${weeksOut * 7}`;
    } else {
      return `weeks_out_${weeksOut}`;
    }
    // TODO: fix here when adding monthly granularity
  };

  const getMilestoneGradingDurationKey = (weeksOut: number): string => {
    if (granularity === 'days') {
      return `days_out_${weeksOut * 7}_grading`;
    } else {
      return `weeks_out_${weeksOut}_grading`;
    }
  };

  // NB: Uncomment the commented code to re-enable day/weeks out milestone functionality
  const mergeData = (
    forecastData: ForecastResponse | null,
    harvestData: HarvestsResponse | null
    // milestoneForecastData: MilestoneForecastResponse | null
  ): BarDatum[] => {
    const barDatum: BarDatum[] = [];
    // if (forecastData && harvestData && milestoneForecastData) {
    if (forecastData && harvestData) {
      const { dates, forecasts } = forecastData;
      const { harvests } = harvestData;
      const forecastDataLength = forecasts.length;
      const harvestDataLength = harvests.length;
      const dataLength = Math.max(forecastDataLength, harvestDataLength);
      let yMax = 0;
      for (let i = 0; i < dataLength; i++) {
        const date = dates[i];
        const forecast = forecasts[i];
        const harvest = harvests[i];
        yMax = Math.max(yMax, forecast, harvest);
        const forecastDetailView = forecastData.detailed_view[i];
        const harvestDetailView = harvestData.detailed_view[i];
        // NB: Uncomment the commented code to re-enable day/weeks out milestone functionality
        // const weeksOneOut =
        //   milestoneForecastData.forecasts[i][getMilestoneDurationKey(1) as keyof MilestoneForecast];
        // const weeksTwoOut =
        //   milestoneForecastData.forecasts[i][getMilestoneDurationKey(2) as keyof MilestoneForecast];
        // const weeksOneOutDetailedView =
        //   milestoneForecastData.detailed_view[i][
        //     getMilestoneDurationKey(1) as keyof MilestoneForecast
        //   ];
        // const weeksTwoOutDetailedView =
        //   milestoneForecastData.detailed_view[i][
        //     getMilestoneDurationKey(2) as keyof MilestoneForecast
        //   ];
        const barDatumItem: BarDatum | any = {
          period:
            granularity === 'days' ? getDayFromDate(date) : getIsoWeekFromDate(date).toString(),
          actual_stems: harvest,
          predicted_stems: forecast,
          has_actual: harvest > 0 ? 'yes' : 'no',
          predicted_grading: forecastDetailView,
          actual_grading: harvestDetailView
          // NB: Uncomment the commented code to re-enable day/weeks out milestone functionality
          // [getMilestoneDurationKey(1) as keyof MilestoneForecast]: weeksOneOut,
          // [getMilestoneDurationKey(2) as keyof MilestoneForecast]: weeksTwoOut,
          // [getMilestoneGradingDurationKey(1) as keyof MilestoneForecast]: weeksOneOutDetailedView,
          // [getMilestoneGradingDurationKey(2) as keyof MilestoneForecast]: weeksTwoOutDetailedView
        };
        barDatum.push(barDatumItem);
      }
      setYMax(yMax);
    }
    if (granularity === 'weeks') {
      // sort barDatum by period
      barDatum.sort((a, b) => {
        const aPeriod = parseInt(a.period.toString());
        const bPeriod = parseInt(b.period.toString());
        if (aPeriod < bPeriod) return -1;
        if (aPeriod > bPeriod) return 1;
        return 0;
      });
    }
    return barDatum;
  };

  const yearFromStartDate = moment(startDate).year();
  const yearFromEndDate = moment(endDate).year();

  const dateStringToWeek = (dateString: string): number => {
    return moment(dateString).isoWeek();
  };

  const convertDemandToDetailedView = (demand: Demand): { [key: string]: number } => {
    return {
      [demand.production_category_id]: demand.yield_value ?? 0
    };
  };

  const convertMultipleDemandToDetailedView = (demands: Demand[]): { [key: string]: number } => {
    const detailedView: { [key: string]: number } = {};
    for (let i = 0; i < demands.length; i++) {
      const demand = demands[i];
      const dv = convertDemandToDetailedView(demand);
      Object.keys(dv).forEach((key) => {
        if (detailedView[key]) {
          detailedView[key] += dv[key];
        } else {
          detailedView[key] = dv[key];
        }
      });
    }
    return detailedView;
  };

  const sortDemandData = (demandData: Demand[]): GraphDemandData[] => {
    const year = Math.max(yearFromStartDate, yearFromEndDate);
    const isoWeeksInYear = moment().year(year).isoWeeksInYear();
    const sortedDemandData: GraphDemandData[] = [];
    for (let i = 1; i <= isoWeeksInYear; i++) {
      const weekData = demandData.filter((demand) => dateStringToWeek(demand.entry_date) === i);
      const variety_id = weekData.length ? weekData[0].variety_id : '';
      let totalYieldValue = 0;
      for (let j = 0; j < weekData.length; j++) {
        const demand = weekData[j];
        totalYieldValue += demand?.yield_value ?? 0;
      }
      const dd: GraphDemandData = {
        _id: '',
        created_at: '',
        updated_at: '',
        variety: variety_id,
        farm_id: '',
        stems: totalYieldValue,
        week: i,
        year: year.toString(),
        grading: convertMultipleDemandToDetailedView(weekData)
      };
      sortedDemandData.push(dd);
    }
    return sortedDemandData;
  };

  // NB: Uncomment the commented code to re-enable day/weeks out milestone functionality
  useEffect(() => {
    if (allDataLoaded) {
      const barDatum = mergeData(
        getForecastQuery.data ?? null,
        getHarvestsQuery.data ?? null
        // milestoneForecastsQuery.data ?? null
      );
      const sortedDemandData = sortDemandData(getDemandDataQuery.data ?? []);
      setData([...barDatum]);
      setDemandData([...sortedDemandData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allDataLoaded,
    getForecastQuery.data,
    getHarvestsQuery.data,
    getDemandDataQuery.data
    // milestoneForecastsQuery.data
  ]);

  useEffect(() => {
    const currYear = moment().year();
    const selectedYear = Math.max(yearFromStartDate, yearFromEndDate);
    if (currYear === selectedYear) {
      setHasNext(yMax > 0);
      setHasPrevious(yMax > 0);
    } else if (currYear > selectedYear) {
      setHasNext(true);
      setHasPrevious(yMax > 0);
    } else if (currYear < selectedYear) {
      setHasNext(yMax > 0);
      setHasPrevious(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yMax]);

  if (refresh) {
    getForecastQuery.refetch();
    getHarvestsQuery.refetch();
    getDemandDataQuery.refetch();
    // milestoneForecastsQuery.refetch();
  }

  return {
    isLoading:
      getForecastQuery.isLoading || getHarvestsQuery.isLoading || getDemandDataQuery.isLoading,
    //|| milestoneForecastsQuery.isLoading,
    barDatum: data,
    demandData: demandData,
    yMax: yMax,
    hasNext: hasNext,
    hasPrevious: hasPrevious
  };
};

export default useActualsVsForecastQuery;
