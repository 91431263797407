import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Text
} from '@chakra-ui/react';
import { InputDate, InputWithIcon, Textarea } from 'Components';
import { Field } from 'formik';
import React from 'react';
import { Icon } from 'react-feather';
import { colors } from 'theme';

type BlockInput = {
  _key?: string;
  hasError: boolean;
  isTouched: boolean;
  error?: string;
  name: string;
  type: string;
  placeholder: string;
  LeftIcon?: Icon;
  RightIcon?: Icon;
  label?: string;
  isBlock?: boolean;
  helpText?: string;
  date?: string;
  required?: boolean;
  byLima?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  onCustomChange?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  onBlur?: () => void;
};

export default function BlockInput({
  hasError,
  isTouched,
  error,
  name,
  type,
  placeholder,
  LeftIcon,
  RightIcon,
  label,
  isBlock,
  helpText,
  byLima,
  onCustomChange,
  onBlur,
  date,
  required,
  hidden,
  disabled,
  _key,
  ...props
}: InputProps & BlockInput): JSX.Element {
  return (
    <>
      {!hidden && (
        <GridItem key={_key} className={isBlock ? 'block' : ''}>
          <FormControl mb={6} isInvalid={hasError && isTouched}>
            {label && (
              <FormLabel htmlFor={name} fontWeight={400} color="black.300">
                {label}
                {required && <span className="required">*</span>}
              </FormLabel>
            )}
            <InputGroup
              className={`${type === 'date' ? 'date-input' : 'text-input'}`}
              data-cy={`new_input_${name}`}
            >
              {LeftIcon && (
                <InputLeftElement pointerEvents="none">
                  <LeftIcon color={colors.input.placeholder} strokeWidth={'2px'} />
                </InputLeftElement>
              )}
              {type === 'date' ? (
                <Field
                  as={InputDate}
                  id={name}
                  type={type}
                  name={name}
                  date={date}
                  placeholder={placeholder}
                  hasError={hasError}
                  onChange={(value: string | undefined) =>
                    onCustomChange && onCustomChange(name, value, true)
                  }
                  onBlur={onBlur}
                  {...props}
                  disabled={disabled}
                />
              ) : (
                <Field
                  as={type === 'textarea' ? Textarea : InputWithIcon}
                  id={name}
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  className={hasError ? 'is-invalid' : ''}
                  {...props}
                  disabled={disabled}
                />
              )}
              {RightIcon && (
                <InputRightElement>
                  <RightIcon color={colors.input.placeholder} strokeWidth={'2px'} />
                </InputRightElement>
              )}
            </InputGroup>
            {helpText && (
              <Text mb={0} mt={1} color="black.300" as="p" fontSize="14px" fontWeight={400}>
                {byLima ? (
                  <Text
                    mb={0}
                    mt={1}
                    color="green.300"
                    as="span"
                    fontSize="14px"
                    fontWeight={400}
                    mr={1}
                  >
                    <Text className="green-circle" as="span"></Text>
                    {' By Lima: '}
                  </Text>
                ) : (
                  ''
                )}
                {helpText}
              </Text>
            )}
            {hasError && <FormErrorMessage>{error}</FormErrorMessage>}
          </FormControl>
        </GridItem>
      )}
    </>
  );
}
