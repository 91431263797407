import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { RangeType } from 'rsuite/esm/DateRangePicker';
import moment from 'moment';

enum Quarter {
  First = 'first',
  Second = 'second',
  Third = 'third',
  Fourth = 'fourth'
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const firstQuarter = months.slice(0, 3);
const secondQuarter = months.slice(3, 6);
const thirdQuarter = months.slice(6, 9);
const fourthQuarter = months.slice(9, 12);

const getCurrentMonth = (): number => {
  return moment().month();
};

const monthToQuarter = (month: number): Quarter => {
  if (month >= 0 && month <= 2) {
    return Quarter.First;
  } else if (month >= 3 && month <= 5) {
    return Quarter.Second;
  } else if (month >= 6 && month <= 8) {
    return Quarter.Third;
  } else {
    return Quarter.Fourth;
  }
};

const currentMonth = getCurrentMonth();
const currentQuarter = monthToQuarter(currentMonth);

const quarterMonthArray = (quarter: Quarter): string[] => {
  switch (quarter) {
    case Quarter.First:
      return firstQuarter;
    case Quarter.Second:
      return secondQuarter;
    case Quarter.Third:
      return thirdQuarter;
    case Quarter.Fourth:
      return fourthQuarter;
    default:
      return [];
  }
};

const monthStringToNumber = (monthString: string): number => {
  return moment(monthString, 'MMM').month();
};

const monthNumberToFirstDate = (month: number): Date => {
  return moment().month(month).startOf('month').toDate();
};

const monthNumberToLastDate = (month: number): Date => {
  return moment().month(month).endOf('month').toDate();
};

const firstMonthOfQuarter = (quarter: Quarter): number => {
  const quarterArray = quarterMonthArray(quarter);
  return monthStringToNumber(quarterArray[0]);
};

const lastMonthOfQuarter = (quarter: Quarter): number => {
  const quarterArray = quarterMonthArray(quarter);
  return monthStringToNumber(quarterArray[quarterArray.length - 1]);
};

const findTheQuarterBefore = (quarter: Quarter): Quarter => {
  switch (quarter) {
    case Quarter.First:
      return Quarter.First;
    case Quarter.Second:
      return Quarter.First;
    case Quarter.Third:
      return Quarter.Second;
    case Quarter.Fourth:
      return Quarter.Third;
    default:
      return Quarter.First;
  }
};

export type CustomPredefinedDateRange =
  | 'Last Week'
  | 'Last 2 Weeks'
  | 'Last Month'
  | 'Current Month'
  | 'Last 2 Months'
  | 'Current Quarter'
  | 'Last Quarter'
  | 'Current Year'
  | 'All Ranges';

const allPossiblePredefinedRanges: RangeType[] | undefined = [
  {
    label: 'Last Week', // consider week starts on Monday
    value: [
      startOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 }),
      endOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 })
    ],
    placement: 'left',
    closeOverlay: false
  },
  {
    label: 'Last 2 Weeks',
    value: [
      startOfWeek(subDays(new Date(), 14), { weekStartsOn: 1 }),
      endOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 })
    ],
    placement: 'left',
    closeOverlay: false
  },
  {
    label: 'Last Month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left',
    closeOverlay: false
  },
  {
    label: 'Current Month',
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
    placement: 'left',
    closeOverlay: false
  },
  {
    label: 'Last 2 Months',
    value: [startOfMonth(addMonths(new Date(), -2)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left',
    closeOverlay: false
  },
  {
    label: 'Current Quarter',
    value: [
      startOfMonth(monthNumberToFirstDate(firstMonthOfQuarter(currentQuarter))),
      endOfMonth(monthNumberToLastDate(lastMonthOfQuarter(currentQuarter)))
    ],
    placement: 'left',
    closeOverlay: false
  },
  {
    label: 'Last Quarter',
    value: [
      startOfMonth(
        monthNumberToFirstDate(firstMonthOfQuarter(findTheQuarterBefore(currentQuarter)))
      ),
      endOfMonth(monthNumberToLastDate(lastMonthOfQuarter(findTheQuarterBefore(currentQuarter))))
    ],
    placement: 'left',
    closeOverlay: false
  },
  {
    label: 'Current Year',
    value: [startOfMonth(monthNumberToFirstDate(0)), endOfMonth(monthNumberToLastDate(11))],
    placement: 'left',
    closeOverlay: false
  }
];

const createPreDefinedDateRanges = (
  predefinedRanges: CustomPredefinedDateRange[],
  placement?: 'left' | 'bottom' | undefined,
  closeOverlay?: boolean | undefined
): RangeType[] | undefined => {
  const preDefinedRanges: RangeType[] = [];

  predefinedRanges.forEach((range: CustomPredefinedDateRange) => {
    const foundRange = allPossiblePredefinedRanges?.find(
      (possibleRange: RangeType) => possibleRange.label === range
    );

    if (foundRange) {
      preDefinedRanges.push({
        label: foundRange.label,
        value: foundRange.value,
        placement: placement || 'left',
        closeOverlay: closeOverlay || false
      });
    }
  });

  return preDefinedRanges;
};

export { createPreDefinedDateRanges };
