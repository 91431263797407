import { Farm, Harvest, Variety } from 'Context/types';
import moment from 'moment';
import crypto from 'crypto-js';
import { PlotData } from 'Context/GraphContext';
import _ from 'lodash';
import { BarDatum } from '@nivo/bar';
import { CombinedFilters } from 'Pages/ActualData/Components/ActualInputTable';
import { ActiveFieldsType } from 'data/GraphRepositoryImpl';

/**
 * @description This function formats a number with comma
 * @param val number to separate with comma
 * @returns formatted number with comma e.g. 1000000 -> 1,000,000
 */
const separateComma = (val: number): string => {
  if (val === null || val === undefined) {
    return '';
  }
  // remove sign if negative
  let sign = 1;
  if (val < 0) {
    sign = -1;
    val = -val;
  }
  // trim the number decimal point if it exists
  const num = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString();
  const len = num.toString().length;
  let result = '';
  let count = 1;

  for (let i = len - 1; i >= 0; i--) {
    result = num.toString()[i] + result;
    if (count % 3 === 0 && count !== 0 && i !== 0) {
      result = ',' + result;
    }
    count++;
  }

  // add number after decimal point
  if (val.toString().includes('.')) {
    result = result + '.' + val.toString().split('.')[1];
  }
  // return result with - sign if negative
  return sign < 0 ? '-' + result : result;
};

/**
 * @description This function checks if two objects are the same
 * @param x object x to compare
 * @param y object y to compare
 * @param propertyToCheck property to check if objects are the same
 * @returns true if objects are the same, false otherwise
 */
const objectsSame = (x: any, y: any, propertyToCheck: any): boolean => {
  if (x === undefined || y === undefined) {
    return false;
  }
  if (x === null || y === null) {
    return false;
  }
  let objectsAreSame = true;
  for (const propertyName in x) {
    if (
      x[propertyName] !== y[propertyName] &&
      (propertyToCheck === null || propertyName === propertyToCheck)
    ) {
      objectsAreSame = false;
      break;
    }
  }
  return objectsAreSame;
};

/**
 * @description This function extracts digits from a string if it exists
 * @param str the string to extract digits from
 * @returns digits from the string
 */
const extractDigits = (str: string): number => {
  return parseInt(str.replace(/\D/g, ''));
};

/**
 * @description This function converts a month to a number in the format MMM
 * @param month month to convert to number in the format MMM
 * @returns converted month to number
 */
const monthToNumber = (month: string): number => {
  return moment(month, 'MMM').month();
};

/**
 * Checks if the object is empty
 * @param obj any js object
 * @returns boolean whether the object is empty or not
 */
const isEmpty = (obj: any): boolean => {
  return (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

/**
 * @description This function formats a number with k, M, G, T, P, E if it is large enough to be formatted with k or larger e.g. 1000000 -> 1M
 * @param num number to format
 * @param digits number of digits to show
 * @returns formatted number with k, M, G, T, P, E e.g. 1000000 -> 1M
 */
const kFormatter = (num: number, digits: number): string => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i: number;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

const isWeek = (_variety: Variety | null | undefined): boolean => {
  const isWeekOrHarvestDate = _variety?.frequency_type !== 'daily' ? 'Harvest Date' : 'Week';
  return isWeekOrHarvestDate === 'Week';
};

// extract year from string "2022-11-30 07:39:36"
const extractYear = (date: string): number => {
  // substring(0, 4) extracts the year from the date string
  return parseInt(date.substring(0, 4));
};

const yearStrToNum = (year: string | undefined): number => {
  try {
    const yearNum = parseInt(year ?? '');
    return yearNum;
  } catch (error) {
    return 0;
  }
};

const formatProductionSummaryDate = (
  date: string,
  variety: Variety | null | undefined,
  harvest: Harvest
): string => {
  if (date && typeof date === 'string' && variety?.frequency_type !== 'daily' && !harvest.year) {
    return moment(date).format('DD-MMM-YYYY');
  }
  if (isWeek(variety)) {
    return `Week ${harvest.day}, ${yearStrToNum(harvest.year)}`;
  }
  return date;
};

const formatProductionSummaryNumber = (value: string | number): string | number => {
  try {
    return new Intl.NumberFormat('en', { maximumSignificantDigits: 5 }).format(
      parseFloat(value + '')
    );
  } catch (error) {
    return value;
  }
};

/**
 * @description accepts day of month e.g 23 and returns its suffix e.g rd for 23rd
 * @param number a date of month between 1-31
 * @returns date suffix i.e st, nd, rd or th
 */
const nthDateNumber = (number: number) => {
  if (number > 3 && number < 21) return 'th';
  switch (number % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const numberInputOnWheelPreventChange = (e: any) => {
  // Prevent the input value change
  e.target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

const SECRET = process.env.REACT_APP_COOKIE_SECRET as string;

const encrypt = (value: string): string | undefined => {
  try {
    return crypto.AES.encrypt(crypto.enc.Utf8.parse(value), SECRET).toString();
  } catch (error) {
    return;
  }
};

const decrypt = (cypher: string): string | undefined => {
  let result;
  try {
    const dec = crypto.AES.decrypt(cypher, SECRET);
    return dec.toString(crypto.enc.Utf8);
  } catch (error) {
    return result;
  }
};

const NORMAL_PREFIX_URL = 'api/v1/';

Date.prototype.getWeek = function () {
  const date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7)
  );
};

/**
 * @description accepts a year and returns number of weeks in the year
 * @param year
 * @returns a number which is the length of weeks in the given year
 */
const getWeeksYear = (year: number) => {
  return new Date(year, 11, 28).getWeek();
};

/**
 * @description accepts start date and end date and returns all dates between the two dates
 * @param start date and end date
 * @returns array which contains all days between the given dates
 */
const getAllDays = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const date = new Date(start.getTime());

  const dates = [];

  while (date <= end) {
    const newDate = new Date(date).toDateString().split(' ');
    dates.push(`${newDate[1]} ${parseInt(newDate[2])}`);
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

/**
 * @description accepts a string and returns the number found in the string if it exists e.g "Year 2021" -> 2021, "Year" -> null
 * @param str input string
 * @returns number found in the string
 */
const findDigitsInString = (str: string): number | null => {
  try {
    // find digits in string
    const numStrMatchArray = str.match(/\d+/g);
    if (numStrMatchArray) {
      return parseInt(numStrMatchArray[0]);
    }
  } catch (error) {
    return null;
  }
  return null;
};

const getDurationFinalKeys = (
  actualData: PlotData[],
  predData: PlotData[],
  duration: string,
  selectedYear: string
): any[] => {
  let finalKeys: any[];
  if (duration == 'weekly') {
    const numberOfWeeks = getWeeksYear(+selectedYear);
    finalKeys = _.range(1, numberOfWeeks + 1).map((el: number) => el.toString());
  } else {
    const actualKeys = actualData?.map((el: any) => Object.keys(el)[0]) ?? [];
    const predKeys = predData?.map((el: any) => Object.keys(el)[0]) ?? [];
    const predDays = Array.from(new Set([...predKeys, ...actualKeys])).sort((a: any, b: any) => {
      const aKey = monthToNumber(a.substring(0, 3));
      const bKey = monthToNumber(b.substring(0, 3));
      const numA = extractDigits(a);
      const numB = extractDigits(b);
      return aKey - bKey || numA - numB;
    });
    finalKeys = getAllDays(
      `${predDays[0]} ${selectedYear}`,
      `${predDays[predDays.length - 1]} ${selectedYear}`
    );
  }
  return finalKeys;
};

const getObjectValue = (array: any[], key: string): any => {
  const found = array?.find((entry: any) => Object.keys(entry)[0] === key);
  if (found) return Object.values(found)[0];
  return null;
};

const sortBarData = (array: any[], duration: string) => {
  switch (duration) {
    case 'daily':
      array?.sort((a: any, b: any) => {
        const aKey = monthToNumber(a.period.substring(0, 3));
        const bKey = monthToNumber(b.period.substring(0, 3));
        const numA = extractDigits(a.period);
        const numB = extractDigits(b.period);
        return aKey - bKey || numA - numB;
      });
      break;
    case 'weekly':
      array?.sort((a: any, b: any) => a.period - b.period);
      break;
    case 'monthly':
      array?.forEach((el: any) => {
        el.data.sort((a: any, b: any) => {
          return monthToNumber(a.period) - monthToNumber(b.period);
        });
      });
      break;
  }
  return array;
};

const sortTableColumn = (data: any[], sortField: any, sortOrder: 'desc' | 'asc'): any[] => {
  if (sortField) {
    const sorted = [...(data ?? [])].sort((a, b) => {
      if (a[sortField] === null) return 1;
      if (b[sortField] === null) return -1;
      if (a[sortField] === null && b[sortField] === null) return 0;
      return (
        a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
          numeric: true
        }) * (sortOrder === 'asc' ? 1 : -1)
      );
    });
    return sorted;
  } else return [];
};

/**
 * @description accepts a snake case string and converts it to Title Case
 * @param str input snake case string e.g variable_name
 * @returns string title case string e.g Variable Name
 */
const snakeToTitleCase = (s: string) =>
  s
    .replace(/^[-_]*(.)/, (_: string, c: string) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_: string, c: string) => ' ' + c.toUpperCase()); // First char after each -/_

/**
 * @description This function filters harvests by field and date
 * @param harvests
 * @param filters
 * @returns filtered harvests
 */
const filterHarvests = (harvests: Harvest[], filters: CombinedFilters | null): Harvest[] => {
  const _filteredHarvestsByField: Harvest[] = [];
  const _selectedFieldIds = new Set((filters?.fields ?? []).map((field) => field.id));
  const _selectedFieldNames = new Set((filters?.fields ?? []).map((field) => field.name));

  if (_selectedFieldNames.size > 0) {
    for (const harvest of harvests) {
      if (
        _selectedFieldNames.size === 0 ||
        (harvest.field_id != '' && _selectedFieldIds.has(harvest.field_id)) ||
        (harvest.field?.name && _selectedFieldNames.has(harvest.field?.name))
      ) {
        _filteredHarvestsByField.push(harvest);
      }
    }
  }

  const _filteredHarvestsByDate: Harvest[] = [];
  const _startDate = filters?.startDate;
  const _endDate = filters?.endDate;

  if (_startDate && _endDate) {
    for (const actual of _filteredHarvestsByField) {
      let _actualDate = new Date(actual.day);
      if (actual.date) {
        _actualDate = new Date(actual.date);
      }
      //TODO: if any module other that the harvest data module is affected then re-anable this line
      // if (actual.year) {
      //   _actualDate = moment().year(+actual.year).week(+actual.day).day('Monday').toDate();
      // }
      if (moment(_actualDate).isBetween(_startDate, _endDate, 'days', '[]')) {
        _filteredHarvestsByDate.push(actual);
      }
    }
  } else {
    _filteredHarvestsByDate.push(..._filteredHarvestsByField);
  }
  return _filteredHarvestsByDate;
};

// HOW TO USE: array.sort(dynamicSort(property, order));
const dynamicSort = (property: any, order: 'desc' | 'asc') => {
  let sort_order = 1;
  if (order === 'desc') {
    sort_order = -1;
  }
  return function (a: any, b: any) {
    // a should come before b in the sorted order
    if (a[property] < b[property]) {
      return -1 * sort_order;
      // a should come after b in the sorted order
    } else if (a[property] > b[property]) {
      return 1 * sort_order;
      // a and b are the same
    } else {
      return 0 * sort_order;
    }
  };
};

function formatHarvestByFieldTags(
  _activeFields: ActiveFieldsType[],
  _harvestYieldsColors: string[]
) {
  const selected = _activeFields?.filter((el: ActiveFieldsType) => el.selected);
  const myArr = selected?.length ? selected : _activeFields;
  const tags: any[] = myArr?.map((el: ActiveFieldsType, ind: number) => {
    const newObj: { [key: string]: any } = {};
    newObj['id'] = el.name;
    newObj['label'] = el.name;
    newObj['active'] = true;
    newObj['fill'] = _harvestYieldsColors?.[ind] ?? '';
    return newObj;
  });
  return tags ?? [];
}

/**
 * @description This function formats a date to the server date format
 * @param date input date
 * @returns formatted date
 */
const formatToServerFormat = (date: Date): string => {
  return moment(date).format('YYYY-MM-DDTHH:mm:ss');
};

/**
 * @description This function converts a date string in server format to a date object
 * @param date input date string
 * @returns date object
 */
const serverDateFormatToDateObject = (date: string): Date => {
  return moment(date).toDate();
};

/**
 * @description This function checks if a variety is valid for a farm i.e if the farm has the variety
 * @param farm
 * @param variety
 * @returns true if variety is valid for farm, false otherwise
 */
const varietyIsValid = (
  farm: Farm | null | undefined,
  variety: Variety | null | undefined
): boolean => {
  const variety_is_valid = farm?.varieties?.some((farm_variety) => {
    return farm_variety?.id === variety?.id;
  });
  return variety_is_valid ?? false;
};

/**
 * @description This function finds an organization by it's id/external_name
 * @param array of organizations
 * @param organization property to filter by
 * @returns organization if found and null if not found
 */
const getOrganization = (organizations: any[], value: any) => {
  //check for id if only one organization exists and external name if user changes organization selection
  const org = organizations?.find((org: any) => org.id === value || org.external_name === value);
  return org ?? null;
};

const enumerateDaysBetweenDates = (startDate: any, endDate: any) => {
  const now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('YYYY-MM-DDTHH:mm:ss'));
    now.add(1, 'days');
  }
  return dates;
};

export {
  separateComma,
  objectsSame,
  extractDigits,
  monthToNumber,
  isEmpty,
  kFormatter,
  formatProductionSummaryDate,
  formatProductionSummaryNumber,
  nthDateNumber,
  extractYear,
  numberInputOnWheelPreventChange,
  encrypt,
  decrypt,
  NORMAL_PREFIX_URL,
  yearStrToNum,
  getWeeksYear,
  getAllDays,
  findDigitsInString,
  getDurationFinalKeys,
  getObjectValue,
  sortBarData,
  sortTableColumn,
  snakeToTitleCase,
  filterHarvests,
  dynamicSort,
  formatHarvestByFieldTags,
  formatToServerFormat,
  serverDateFormatToDateObject,
  varietyIsValid,
  getOrganization,
  enumerateDaysBetweenDates
};
