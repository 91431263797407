import React from 'react';
import { Flex, Icon } from '@chakra-ui/react';
import { Button } from 'Components';
import {
  BarChart2,
  ChevronRight,
  Cloud,
  Database,
  HelpCircle,
  Columns,
  Settings,
  Calendar
} from 'react-feather';
import NavItem, { NavItemProps } from './NavItem';
import { useAuthContext } from 'Context';

export default function LeftNav({
  onToggleSideBar,
  isCollapsed
}: {
  onToggleSideBar: () => void;
  isCollapsed: boolean;
}): JSX.Element {
  const {
    variables: { user }
  } = useAuthContext();
  const navItems: NavItemProps[] = [
    {
      to: '/dashboard',
      label: 'Dashboard',
      icon: BarChart2,
      isCollapsed,
      locked: false
    },
    {
      to: '/farm-production',
      label: 'Farm Production',
      icon: Columns,
      isCollapsed
    },
    {
      to: '/harvest-data',
      label: 'Harvest Data',
      locked: false,
      icon: Database,
      isCollapsed
    },
    {
      to: '/production-plan',
      label: 'Production Plan',
      locked: false,
      icon: Calendar,
      isCollapsed
    },
    {
      to: '/weather',
      label: 'Weather',
      locked: true,
      icon: Cloud,
      isCollapsed
    }
  ];
  const bottomNavItems: NavItemProps[] = [
    {
      to: '/help',
      label: 'Help',
      icon: HelpCircle,
      locked: true,
      isCollapsed
    },
    {
      to: '/settings/farms',
      label: 'Settings',
      locked: user?.role !== 'Admin' && user?.role !== 'SuperAdmin',
      icon: Settings,
      isCollapsed
    }
  ];

  return (
    <Flex direction="column" position="relative" px={4} pt={5} height="calc(100% - 100px)">
      <Button
        display="flex"
        justifyContent="center"
        theme="white"
        alignItems="center"
        minWidth="1.5rem"
        maxWidth="1.5rem"
        height="1.5rem"
        maxHeight="1.5rem"
        borderRadius="50% !important"
        boxShadow="2px 2px 2px #F5F5F5"
        border="1px solid"
        borderColor="black.100"
        _focus={{
          boxShadow: '2px 2px 2px #F5F5F5'
        }}
        position="absolute"
        right="-0.75rem"
        top="24px"
        p={0}
        onClick={onToggleSideBar}
        className={isCollapsed ? 'collapsed' : 'expanded'}
        zIndex="10"
        bg="white"
        __css={{
          transform: 'rotate(0)',
          transition: 'all 300ms ease-in-out',
          '&.expanded': {
            transform: 'rotate(-180deg)',
            transition: 'all 300ms ease-in-out'
          }
        }}
      >
        <Icon as={ChevronRight} />
      </Button>
      <Flex
        className="custom-scrollbar"
        direction="column"
        width="100%"
        justifyContent="space-between"
        height="100%"
        overflowY={'auto'}
      >
        <Flex
          className="nav-section"
          direction="column"
          width="100%"
          justifyContent="space-between"
          height="100%"
        >
          <Flex
            className="nav-section"
            direction="column"
            width="100%"
            justifyContent="center"
            height="fit-content"
          >
            {navItems.map((item) => (
              <NavItem {...item} key={item.to} />
            ))}
          </Flex>
          <Flex
            className="nav-section"
            direction="column"
            width="100%"
            justifyContent="center"
            height="fit-content"
            marginBottom="8"
          >
            {bottomNavItems.map((item) => (
              <NavItem {...item} key={item.to} />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
