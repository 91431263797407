import React from 'react';

export type DelayedLoadingStateInput = {
  loadingState: boolean;
  delayMs: number;
};

const useDelayedLoadingState = (input: DelayedLoadingStateInput): boolean => {
  const { loadingState, delayMs } = input;
  const [state, setState] = React.useState<boolean>(loadingState);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!loadingState) {
      timeout = setTimeout(() => {
        setState(false);
      }, delayMs);
    } else {
      setState(true);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [delayMs, loadingState]);

  return state;
};

export default useDelayedLoadingState;
