import React, { useState, useEffect, useRef } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { nivoTheme } from './../FarmProductionBarChart';
import { ResponsiveBar } from '@nivo/bar';
import CombinedBarAndLineChart from './../CombinedBarAndLineChart';
import { FilterDatum, useGraphContext } from 'Context/GraphContext';
import FieldProductivityTooltip from './FieldProductivityTooltip';
import { separateComma } from 'Helpers/helpers';

function FieldProductivityChart(props: { data: any }) {
  const { data } = props;
  const {
    fieldProductivityTags,
    primaryProductivityTags,
    fieldProductivityFilters,
    quickActionFilters,
    xAxisWidth,
    setXAxisWidth
  } = useGraphContext();
  const getDurationKeys = (): string[] => {
    return (
      fieldProductivityTags
        ?.filter((tag: any) => tag.active && primaryProductivityTags?.includes(tag.id))
        .map((tag: any) => tag.id) ?? []
    );
  };
  const [displayData, setDisplayData] = useState<any[]>(
    data?.formattedData?.filter((el: any) => el.max) ?? []
  );
  const getDurationColors = (): string[] => {
    return (
      fieldProductivityTags
        ?.filter((tag: any) => tag.active && primaryProductivityTags?.includes(tag.id))
        .map((tag: any) => tag.fill) ?? []
    );
  };
  const primaryTagsActive = () => {
    return fieldProductivityTags?.some(
      (tag: FilterDatum) => primaryProductivityTags?.includes(tag.id) && tag.active
    );
  };
  const graphWrapper = useRef<any | undefined>(undefined);
  useEffect(() => {
    const found = quickActionFilters?.find((fil: any) => fil.active);
    if (found) {
      setDisplayData(data?.formattedData.sort((a: any, b: any) => a[found.id] - b[found.id]));
    }
    const dataToPlot = data?.formattedData?.filter((el: any) => el.max);
    setDisplayData(dataToPlot);
  }, [data?.formattedData, quickActionFilters]);
  useEffect(() => {
    const width = graphWrapper?.current?.offsetWidth;
    setXAxisWidth(width);
  }, [graphWrapper, graphWrapper?.current?.offsetWidth, setXAxisWidth]);
  useEffect(() => {
    const handleResize = () => {
      setXAxisWidth(graphWrapper?.current?.offsetWidth);
      return () => {};
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box width="100%">
      {!!data?.maxPred && primaryTagsActive() ? (
        <>
          <Box style={{ display: 'flex', textAlign: 'center' }} flexDirection={'column'}>
            <Box
              style={{
                maxHeight: 700,
                overflowY: 'scroll',
                overflowX: 'hidden',
                width: '100%'
              }}
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                  height: '6px'
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                  height: '12px'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#D5D5D5',
                  borderRadius: '4px'
                }
              }}
            >
              <Box
                height={displayData?.length * 30}
                width={'100%'}
                minHeight="500px"
                ref={graphWrapper}
                id="myGraph"
              >
                <CombinedBarAndLineChart
                  data={displayData}
                  yMax={data?.maxPred}
                  selectedYear={''}
                  lineData={[]}
                  durationKeys={getDurationKeys()}
                  durationColors={getDurationColors()}
                  groupMode="stacked"
                  lineKey=""
                  tooltip={FieldProductivityTooltip}
                  indexBy="period"
                  layout="horizontal"
                  extendYmax
                />
              </Box>
            </Box>
            {xAxisWidth && (
              <Box alignSelf="flex-start">
                <Box height="35px" alignSelf="flex-start" width={xAxisWidth}>
                  <ResponsiveBar
                    theme={nivoTheme}
                    colors={getDurationColors()}
                    data={displayData}
                    keys={getDurationKeys()}
                    indexBy={'period'}
                    valueScale={{ type: 'linear' }}
                    padding={0.4}
                    layout="horizontal"
                    margin={{ top: 0, right: 0, bottom: 35, left: 90 }}
                    enableGridY={true}
                    enableGridX={true}
                    axisLeft={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      format: (value: number | undefined) =>
                        separateComma((value ? value / 1000 : 0) ?? 0)
                    }}
                    layers={['axes', 'grid', 'markers']}
                    maxValue={data?.maxPred * 1.1}
                  />
                </Box>
                <Text
                  fontFamily={'"Be Vietnam Pro", sans-serif'}
                  color="rgb(82, 96, 109)"
                  textAlign={'center'}
                  ml="90"
                >
                  {fieldProductivityFilters?.stems == 'stems' && (
                    <Text as="span" fontSize={'20'}>
                      Stem count in thousands
                    </Text>
                  )}
                  {fieldProductivityFilters?.stems == 'stemspm2' && (
                    <Text as="span" fontSize={'20'}>
                      Stem count per m&sup2; in thousands
                    </Text>
                  )}
                </Text>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Text mb="1.65rem" textAlign={'left'}>
          No graph data available for the selected filter parameters.
        </Text>
      )}
    </Box>
  );
}

export default FieldProductivityChart;
