import React, { useEffect, useState } from 'react';
import _uniqueId from 'lodash/uniqueId';
import { ButtonProps, Icon } from '@chakra-ui/react';
import { Button } from 'Components';
import { ArrowDown } from 'react-feather';
import styles from './SortIcon.module.scss';

export default function SortIcon({
  isNumeric,
  sort,
  isSorting,
  ...props
}: ButtonProps & {
  isNumeric: boolean;
  sort: (order: 'asc' | 'desc' | null) => void;
  isSorting: boolean;
}): JSX.Element {
  const [order, setOrder] = useState<'asc' | 'desc' | null>(null);
  const [id] = useState(_uniqueId('sort-icon-'));
  const handleSort = () => {
    if (order === 'asc') {
      setOrder('desc');
      sort('desc');
    } else if (order === 'desc') {
      setOrder(null);
      sort(null);
    } else {
      setOrder('asc');
      sort('asc');
    }
  };

  useEffect(() => {
    const svg = document.getElementById(id)?.querySelector('svg');
    svg?.setAttribute('viewBox', '0 0 32 32');
    const line = svg?.querySelector('svg > line');
    const polyline = document.getElementById(id)?.querySelector('svg > polyline');
    line?.setAttribute('y1', '0');
    line?.setAttribute('y2', '32');
    line?.setAttribute('x1', '16');
    line?.setAttribute('x2', '16');
    polyline?.setAttribute('points', '24 20 16 32 8 20');
  }, [id]);

  useEffect(() => {
    if (!isSorting) {
      setOrder(null);
    }
  }, [isSorting]);
  return (
    <Button
      as="span"
      role="button"
      id={id}
      className={`${styles.icon}  ${!order ? styles.icon__unsorted : ''}`}
      display="inline-flex"
      alignItems="center"
      p={0}
      bg="transparent"
      fontWeight={300}
      ms={1}
      {...props}
      onClick={handleSort}
    >
      <Icon
        as={ArrowDown}
        strokeWidth="2px"
        height="24px"
        stroke="black.500"
        className={styles.icon__icon}
        y1={-5}
      />
      <span className={styles.icon__type}>
        {order === 'desc' ? (
          <>
            <span>{isNumeric ? 9 : 'z'}</span>
            <span>{isNumeric ? 1 : 'a'}</span>
          </>
        ) : (
          <>
            <span>{isNumeric ? 1 : 'a'}</span>
            <span>{isNumeric ? 9 : 'z'}</span>
          </>
        )}
      </span>
    </Button>
  );
}
