import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Icon,
  Flex,
  Text
} from '@chakra-ui/react';

import styles from './BlockDetails.module.scss';
import CycleConditionForm from './CycleCondition';
import BlockCarousel from './Carousel';
import Comment from './Comment';
import moment from 'moment-timezone';
import { Button } from 'Components';
import { Maximize2 } from 'react-feather';
import {
  BlockComment,
  CycleCondition,
  FieldComment,
  FieldProperties,
  GradingItem,
  HarvestSchedule,
  JointBlockCycle
} from 'Context/types';
import { useAppContext } from 'Context';
import Skeleton from 'react-loading-skeleton';
import { NotificationToast } from 'Components/Cards';

export default function HomeBlocDetails({
  isComplete,
  data
}: {
  isComplete: boolean;
  data: FieldProperties;
}): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cycleFormData, setCycleFormData] = useState<CycleCondition | undefined>();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { farm } = useAppContext();
  const [comments, setComments] = useState<FieldComment[]>([]);
  const [hasMoreComments, setHasMoreComments] = useState(true);
  const [currentPopupImageIndex, setCurrentPopupImageIndex] = useState(currentImageIndex);

  const { blockImages, limaActiveModules } = useAppContext();

  const initializePagination = useCallback(() => {
    if (data?.comments?.length > 0) {
      if (data?.comments?.length <= 5) {
        setHasMoreComments(false);
        setComments(data.comments);
      }
      const first5Comments = data?.comments.slice(0, 5);
      setComments(first5Comments);
    } else {
      setComments(data.comments);
    }
  }, [data?.comments]);

  useEffect(() => {
    initializePagination();
  }, [initializePagination]);

  useEffect(() => {
    if (comments?.length >= data?.comments?.length) {
      setHasMoreComments(false);
    } else {
      setHasMoreComments(true);
    }
  }, [comments?.length, data?.comments?.length]);

  const loadMoreComments = () => {
    if (comments.length >= data.comments.length) {
      setHasMoreComments(false);
      return;
    }
    const nextComments = data.comments.slice(comments.length, comments.length + 5);
    setComments([...comments, ...nextComments]);
  };

  const onImageChange = (item: number) => {
    setCurrentImageIndex(item);
  };

  const onPopupChange = (item: number) => {
    setCurrentPopupImageIndex(item);
  };

  useEffect(() => {
    // const {
    //   id,
    //   stem_density_farm_pred,
    //   yield_pred,
    //   first_date_harvest_pred,
    //   area,
    //   dieback,
    //   loss,
    //   packhouse_rejection,
    //   // archive_block,
    //   harvest_schedule,
    //   first_date_harvest_farm_pred,
    //   last_date_harvest_farm_pred,
    //   grading
    // } = data;

    // const comment = '';

    setCycleFormData({
      id: data.field.id,
      stem_density_farm_pred: data?.stem_density_entry?.stem_density_value ?? 0,
      area: data?.area_entry?.area_value ?? 0,
      yield_pred: data?.field?.forecast_summary?.yields, //TODO: Calculate yield pred from daily stems
      dieback: data?.dieback_entry?.dieback_value ?? 0,
      loss: data?.loss_entry?.loss_value ?? 0,
      packhouse_rejection: 0,
      first_date_harvest_pred: data?.field?.forecast_summary?.first_harvest_date,
      first_date_harvest_farm_pred: data?.first_harvest_date,
      last_date_harvest_farm_pred: data?.last_harvest_date,
      harvest_schedule: data?.harvest_schedule,
      grading: data?.field.grading
    } as CycleCondition);
  }, [data, isComplete]);

  return (
    <Box>
      <Box
        backgroundColor="white"
        padding={6}
        borderRadius={4}
        boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
        marginBottom={6}
      >
        <Box className={styles.table__header}>
          <Heading
            as="h4"
            className="table__header__title"
            mb="2"
            fontSize="1.35rem"
            fontWeight="normal"
          >
            {isComplete ? 'Completed Field Cycle' : 'Field Cycle Conditions'}
          </Heading>
          <Text
            mb="2"
            fontSize="0.9rem"
            fontWeight="normal"
            color="dark.coolGray.500"
            data-cy="field-name"
          >
            {data?.field?.name ? (
              <>Field {data?.field?.name}</>
            ) : (
              <NotificationToast
                type="info"
                message={'Please select a field to view cycle conditions'}
              />
            )}
          </Text>
        </Box>
        <CycleConditionForm data={cycleFormData as CycleCondition} />
      </Box>
      {/* Image Carousel */}
      {/* {limaActiveModules.images && farm?.with_lima_forecasting && (
        // TODO: Below code is disabled since there is no api in v2
        <Box
          my={8}
          borderRadius={4}
          boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
          maxHeight="max-content"
          position="relative"
          _hover={{
            '& > .carousel-inline-title': {
              display: 'block'
            }
          }}
        >
          <Box
            background="white"
            textAlign="center"
            py={2}
            borderTopRadius={4}
            position="absolute"
            top={0}
            left={0}
            zIndex={5}
            width="100%"
            color="black.500"
            display="none"
            className="carousel-inline-title"
          >
            <Text color="black.lighter.400">
              {data.name} Captured{' '}
              {moment(blockImages[currentImageIndex]?.image_datetime).format('MMMM Do, h.mm A')}
            </Text>
            <Button
              as="span"
              position="absolute"
              right="2"
              top="0"
              backgroundColor="transparent !important"
              onClick={onOpen}
            >
              <Icon as={Maximize2} fill="black.300" />
            </Button>
          </Box>
          <BlockCarousel
            images={blockImages}
            onChange={onImageChange}
            isInline
            selectedItem={currentImageIndex}
          />
          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay backgroundColor="rgba(44, 46, 45, 0.85)" />
            {blockImages[currentImageIndex]?.url == null ? (
              <Skeleton height={600} style={{ marginTop: 40 }} />
            ) : (
              <ModalContent
                maxWidth="1170px"
                width="100%"
                maxHeight="650px"
                height="650px"
                backgroundColor="transparent"
              >
                <ModalBody backgroundColor="transparent">
                  <BlockCarousel
                    images={data.images}
                    arrowsXPositions={-55}
                    borderRadius={0}
                    selectedItem={currentImageIndex}
                    onChange={onPopupChange}
                  />
                  <Text mt="3" fontSize="1rem" fontWeight="normal" color="white" textAlign="center">
                    {data.name} Captured{' '}
                    {moment
                      .utc(blockImages[currentPopupImageIndex]?.image_datetime)
                      .tz(moment.tz.guess())
                      .format('MMMM Do, h.mm A')}
                  </Text>
                </ModalBody>
              </ModalContent>
            )}
          </Modal>
        </Box>
      )} */}

      <Box
        backgroundColor="white"
        padding={6}
        borderRadius={4}
        boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
      >
        <Box className={styles.table__header}>
          <Heading
            as="h4"
            className="table__header__title"
            mb="2"
            fontSize="1.35rem"
            fontWeight="normal"
          >
            Log
          </Heading>
          <Text mb="2" fontSize="0.9rem" fontWeight="normal" color="dark.coolGray.500">
            {data?.field?.name ? (
              <>Field {data.field.name}</>
            ) : (
              <NotificationToast type="info" message={'Please select a field to view logs'} />
            )}
          </Text>
          <Box>
            {/* <InfiniteScroll
              dataLength={comments.length}
              next={loadMoreComments}
              hasMore={hasMoreComments}
              loader={
                <Flex
                  flexDir="column"
                  w="100%"
                  align="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <Flex alignSelf="center">
                    <Spinner />
                    <Text ml={3}>Loading more comments...</Text>
                  </Flex>
                </Flex>
              }
            > */}
            {comments?.length > 0 &&
              comments.map(
                (
                  { entry_text, created_at, user, lima_user, comment_type }: FieldComment,
                  index: number
                ) => (
                  <Comment
                    index={index}
                    display_name={lima_user?.full_name ?? user?.full_name}
                    message={entry_text}
                    time={created_at}
                    key={created_at + (lima_user?.full_name ?? user?.full_name) + index}
                    comment_type={comment_type}
                    color={comment_type == 1 ? 'primary' : comment_type == 2 ? 'black' : 'info'}
                  />
                )
              )}
            {hasMoreComments && (
              <Flex
                flexDir="column"
                w="100%"
                align="center"
                alignContent="center"
                alignItems="center"
              >
                <Flex mt={4} alignSelf="center">
                  <Button theme="primary" onClick={loadMoreComments}>
                    <Text color="white" letterSpacing={0.2} ml={3}>
                      Load more comments
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            )}
            {/* </InfiniteScroll> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
