import * as yup from 'yup';
import { countriesAndProvinces } from 'data/countries';

const schema = yup.object().shape({
  name: yup.string().required('Farm name is required.'),
  location: yup
    .string()
    // city and country should be comma separated
    .test(
      'location',
      'Please enter a valid city and country separated by one comma e.g. "Nairobi, Kenya"',
      function (value) {
        if (!value) return true;
        const count = value?.match(/[,]/g)?.length;
        // should only contain one comma
        if (count && count > 1) return false;
        const invalid = [0, -1, value.length - 1];
        // comma cannot be non-existent, at beginning or end
        return !invalid.includes(value.indexOf(','));
      }
    )
    // country should exist and be valid
    .test('location', 'Please provide a valid country', function (value) {
      if (!value) return true;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [city, country] = value.split(',');
      // country must have value
      if (!country?.trim()) return false;
      // country must be valid
      const found = countriesAndProvinces.find(
        (el: any) => el.name.toLowerCase() === country.trim().toLowerCase()
      );
      return !!found;
    })
    // city and country should not be the same
    .test('location', 'City and country should not be the same', function (value) {
      if (!value) return true;
      const [city, country] = value.split(',');
      if (!country?.trim() || !city?.trim()) return true;
      return city.trim().toLowerCase() !== country.trim().toLowerCase();
    })
    .required('City and country is required.'),
  orgs: yup.string().nullable().required('Organization is required.')
});

export default schema;
