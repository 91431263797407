import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import {
  Field,
  BlockImage,
  CompleteCycle,
  CycleCondition,
  Farm,
  ForecastProduction,
  HarvestSchedule,
  LimaActiveModules,
  NewBlockCondition,
  User,
  Variety,
  FieldProperties,
  Organization,
  ForecastProductionGradeItem
} from './types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGraphContext } from 'Context/GraphContext';
import { useCookies } from 'react-cookie';
import crypto from 'crypto-js';
import { FarmOnboardSteps } from 'Layout/Components/CompleteRegistrationModal';
import { useToast } from '@chakra-ui/react';
import LimaToast from 'Components/Toast/LimaToast';
import { formatToServerFormat, NORMAL_PREFIX_URL } from 'Helpers/helpers';
import { httpClient, userBlocksRepository } from 'Helpers/appDependenciesHelpers';

const SECRET = process.env.REACT_APP_COOKIE_SECRET as string;

declare global {
  interface Window {
    Cypress?: Cypress.Cypress;
  }
}

type AppContextType = {
  sidebarExpanded: boolean;
  isSaving: boolean;
  isDownloadingHarvestSchedule: boolean;
  setIsDownloadingHarvestSchedule: (value: boolean) => void;
  newBlockAdded: boolean;
  fieldsUpdated: boolean;
  cycleFormError: boolean;
  toggleSideBar: () => void;
  farm: Farm | null;
  setFarm: (farm: Farm | null) => void;
  setFieldsUpdated: (value: boolean) => void;
  farms: Farm[];
  setFarms: (farms: Farm[]) => void;
  fieldStatus: string;
  setFieldStatus: (status: string) => void;
  setOrganizations: (organizations: string[]) => void;
  fields: Field[];
  appAuth: boolean;
  setAppAuth: (auth: boolean) => void;
  setFields: (fields: Field[]) => void;
  selectedCycle: FieldProperties | null;
  setSelectedCycle: (cycle: FieldProperties | null) => void;
  varieties: Variety[];
  variety: Variety | null;
  setVariety: (variety: Variety | null) => void;
  varietyIsCyclic: () => boolean;
  setVarieties: (varieties: Variety[]) => void;
  updateFieldProperties: (values: CycleCondition, token: string) => void;
  updateHarvestScheduleDates: (values: FieldProperties) => void | HarvestSchedule[];
  completeCycle: (cycle: CompleteCycle, token: string) => void;
  farmFields: { [key: string]: Field[] };
  setFarmBlocks: (blocks: { [key: string]: Field[] }) => void;
  selectedHarvest: HarvestSchedule[];
  setSelectedHarvest: (harvest_schedule: HarvestSchedule[]) => void;
  addNewBlock: (newBlockConditionValues: NewBlockCondition, token: string) => void;
  startNewCycle: (
    token: string,
    tenant_header: string,
    field_id: string
  ) => void | Promise<AxiosResponse<any, any>>;
  reverseNewCycle: (
    token: string,
    tenant_header: string,
    field_id: string
  ) => void | Promise<AxiosResponse<any, any>>;
  uprootBlock: (
    token: string,
    tenant_header: string,
    field_id: string
  ) => void | Promise<AxiosResponse<any, any>>;
  handleFieldOptionsError: (status: number) => void;
  blockCycleUpdated: boolean;
  getPresignedUrls(token: string, blockId: string): void | Promise<void>;
  setBlockImages: (images: BlockImage[]) => void;
  blockImages: BlockImage[];
  downloadHarvestSchedule: (token: string, tenant_header: string, field_id: string) => void;
  limaActiveModules: LimaActiveModules;
  currentFarmOnboardStep: FarmOnboardSteps;
  setCurrentFarmOnboardStep: (step: FarmOnboardSteps) => void;
  onBoardingModalActive: boolean;
  setOnboardingModalActive: (value: boolean) => void;
  onboardingModalLoading: boolean;
  setOnboardingModalLoading: (value: boolean) => void;
  loadingFarms: boolean;
  setLoadingFarms: (value: boolean) => void;
  organizations: Organization[];
  fetchVarieties: (farm: Farm) => void;
};

const MyContext = createContext<AppContextType>({
  sidebarExpanded: false,
  toggleSideBar: () => {},
  farm: null,
  setFarm: () => {},
  farms: [],
  setFarms: () => {},
  fieldStatus: 'active',
  setFieldStatus: () => {},
  setOrganizations: () => {},
  fields: [],
  setFields: () => {},
  selectedCycle: null,
  setSelectedCycle: () => {},
  varieties: [],
  variety: null,
  setVariety: () => {},
  varietyIsCyclic: () => false,
  setVarieties: () => {},
  updateFieldProperties: () => {},
  completeCycle: () => {},
  isSaving: false,
  isDownloadingHarvestSchedule: false,
  setIsDownloadingHarvestSchedule: () => {},
  newBlockAdded: false,
  fieldsUpdated: false,
  setFieldsUpdated: () => {},
  farmFields: {},
  setFarmBlocks: () => {},
  selectedHarvest: [],
  setSelectedHarvest: () => {},
  cycleFormError: false,
  appAuth: true,
  setAppAuth: () => {},
  addNewBlock: () => {},
  startNewCycle: () => {},
  reverseNewCycle: () => {},
  uprootBlock: () => {},
  handleFieldOptionsError: () => {},
  blockCycleUpdated: false,
  updateHarvestScheduleDates: () => {},
  getPresignedUrls: () => {},
  blockImages: [],
  setBlockImages: () => {},
  downloadHarvestSchedule: () => {},
  currentFarmOnboardStep: FarmOnboardSteps.DONE,
  setCurrentFarmOnboardStep: () => {},
  onBoardingModalActive: false,
  setOnboardingModalActive: () => {},
  onboardingModalLoading: false,
  setOnboardingModalLoading: () => {},
  limaActiveModules: {
    status_report: true,
    growth_stages: false,
    cycle_conditions: true,
    images: false,
    farm_production: true,
    forecast_trend: false,
    minimum_expected_harvest: false,
    minimum_expected_grade_split: true,
    weekly_graded_table: true,
    forecast_harvest_by_field_chart: true,
    forecast_harvest_by_field_table: true,
    historical_harvest_by_field_chart: true,
    historical_harvest_by_field_table: true
  },
  loadingFarms: false,
  setLoadingFarms: () => {},
  organizations: [],
  fetchVarieties: () => {}
});

export const useAppContext = () => useContext(MyContext);

export default function AppContext({ children }: { children: ReactNode }): JSX.Element {
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDownloadingHarvestSchedule, setIsDownloadingHarvestSchedule] = useState(false);
  const [newBlockAdded, setNewBlockAdded] = useState(false);
  const [fieldsUpdated, setFieldsUpdated] = useState(false);
  const [blockCycleUpdated, setBlockCycleUpdated] = useState(false);
  const [farm, setFarm] = useState<Farm | null>(null);
  const [selectedCycle, setSelectedCycle] = useState<FieldProperties | null>(null);
  const [farms, setFarms] = useState<Farm[]>([]);
  const [loadingFarms, setLoadingFarms] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [fields, setFields] = useState<Field[]>([]);
  const [farmFields, setFarmBlocks] = useState<{ [key: string]: Field[] }>({});
  const [variety, setVariety] = useState<Variety | null>(null);
  const varietyIsCyclic = function () {
    return variety?.harvest_frequency_days != undefined && variety?.harvest_frequency_days > 1;
  };
  const [fieldStatus, setFieldStatus] = useState<string>('active'); //used to query for fields by field status
  const [blockImages, setBlockImages] = useState<BlockImage[]>([]);
  const [varieties, setVarieties] = useState<Variety[]>([]);
  const [cycleFormError, setCycleFormError] = useState(false);
  const [appAuth, setAppAuth] = useState(true);
  const [selectedHarvest, setSelectedHarvest] = useState<HarvestSchedule[]>([]);
  const [cookies] = useCookies(['lima_auth', 'lima_auth_refresh']);
  const [currFarmOnboardStep, setCurrFarmOnboardStep] = useState<FarmOnboardSteps>(
    FarmOnboardSteps.DONE
  );
  const [onboardingmodalactive, setonboardingmodalactive] = useState(false);
  const [onboardingmodalloading, setonboardningmodalloading] = useState(false);
  const toggleSideBar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const { graphData } = useGraphContext();
  const [fetchingOrganizations, setFetchingOrganizations] = useState(false);
  // Edit this and the type in order to control which sub module is enabled or disabled
  // for major urls e.g /farm-production use the locked attribute in the LeftNav Component to enable or disable
  const [limaActiveModules] = useState<LimaActiveModules>({
    status_report: true,
    growth_stages: false,
    cycle_conditions: true,
    images: false,
    farm_production: true,
    forecast_trend: false,
    minimum_expected_harvest: false,
    minimum_expected_grade_split: true,
    weekly_graded_table: true,
    forecast_harvest_by_field_chart: true,
    forecast_harvest_by_field_table: true,
    historical_harvest_by_field_chart: true,
    historical_harvest_by_field_table: true
  });
  const [userCookie, setUserCookie] = useCookies(['user']);
  const [user, setUser] = useState<User | null>(null);
  const BASE_URL = `${process.env.REACT_APP_BE_URL}`;
  const toast = useToast();

  useEffect(() => {
    if (userCookie.user) {
      setUser(userCookie.user);
    }
  }, [userCookie]);

  const updateFieldProperties = async (
    {
      stem_density_farm_pred,
      yield_pred,
      area,
      dieback,
      loss,
      cycle_start_date,
      first_date_harvest_pred,
      comment,
      first_date_harvest_farm_pred,
      last_date_harvest_farm_pred,
      harvest_schedule,
      grading,
      harvest_frequency
    }: CycleCondition,
    token: string
  ) => {
    // TODO: Refactor this code to work with new FieldProperties type
    // const cycle: FieldProperties = {
    //   ...selectedCycle,
    //   ...stem_density_farm_pred,
    //   area,
    //   dieback,
    //   loss,
    //   cycle_start_date,
    //   first_date_harvest_pred,
    //   grading
    // } as JointBlockCycle;
    // const comments = [];
    // comment &&
    //   comments.push({
    //     comment_str: comment,
    //     comment_datetime: new Date().toISOString(),
    //     owner: '',
    //     by_lima: false
    //   });
    // const { date, last_date_harvest_pred, stem_density_pred, stage } = cycle;
    // // converting harvest schedule stems back into percentages
    // const update_harvest_schedule: HarvestSchedule[] = [];
    // harvest_schedule.forEach(function (value, hsIndex) {
    //   const newGrades = [...value.grading];
    //   // TODO: convert all grades to percentages
    //   newGrades.forEach((grade, index) => {
    //     Object.keys(grade).map((grade_item) => {
    //       let grade_stems = newGrades[index][grade_item];
    //       if (isNaN(parseFloat(String(grade_stems)))) grade_stems = 0;
    //       newGrades[index] = {
    //         ...newGrades[index],
    //         [grade_item]: +grade_stems != 0 ? (+grade_stems / value.stems) * 100 : 0
    //       };
    //     });
    //   });
    //   const harvest_schedule_item = {
    //     ...value,
    //     stems: varietyIsCyclic() ? ((value.stems / yield_pred) as number) : value.stems,
    //     grading: newGrades,
    //     area: varietyIsCyclic() ? value.area : area,
    //     loss: !varietyIsCyclic() && hsIndex == 0 ? loss : value.loss
    //   };
    //   update_harvest_schedule.push(harvest_schedule_item);
    // });
    // const updateDs = {
    //   block_in_update: {
    //     name: cycle.name,
    //     first_date_harvest_pred: cycle.first_date_harvest_pred,
    //     last_date_harvest_actual: cycle.last_date_harvest_actual
    //   },
    //   entry: {
    //     date,
    //     area,
    //     yield_pred,
    //     last_date_harvest_pred,
    //     first_date_harvest_pred,
    //     first_date_harvest_farm_pred,
    //     last_date_harvest_farm_pred,
    //     stem_density_pred,
    //     stem_density_farm_pred,
    //     stage,
    //     loss,
    //     dieback,
    //     grading
    //   },
    //   images: [],
    //   harvest_schedule: update_harvest_schedule,
    //   comments: comments
    // };

    // V2 CODE
    // Extract forecast productions from harvest schedule grades
    const forecast_productions: ForecastProduction[] = [];
    harvest_schedule.forEach(function (harvest_schedule_item) {
      harvest_schedule_item.grading.forEach((forecast_production_grade_item) => {
        Object.values(forecast_production_grade_item).map((forecast_production) => {
          if (forecast_production) {
            forecast_productions.push({
              ...forecast_production,
              yield_value: forecast_production?.yield_value ? forecast_production?.yield_value : 0
            });
          }
        });
      });
    });
    const update_past_forecasts = harvest_frequency == 'cyclic' ? true : false;
    const updateDs = {
      field: {
        //   id: selectedCycle?.field?.id,
        //   name: selectedCycle?.field?.name,
        //   parent_location: selectedCycle?.field?.parent_location,
        //   // location: { polygon: [{ lat: 0, long: 0 }] },
        //   average_stem_density: selectedCycle?.field?.average_stem_density,
        //   average_bed_width: selectedCycle?.field?.average_bed_width,
        //   active: selectedCycle?.field?.active,
        //   sub_sampled: selectedCycle?.field?.sub_sampled,
        //   planted_date: selectedCycle?.field?.planted_date,
        //   cutback_date: selectedCycle?.field?.cutback_date
        current_forecast: Math.round(yield_pred)
      },
      area_value: area,
      dieback_value: dieback / 100,
      loss_value: loss / 100,
      stem_density_value: stem_density_farm_pred,
      forecast_productions: forecast_productions,
      comment_str: comment
    };
    // don't send comment string when empty
    if (!comment) {
      delete (updateDs as { comment_str?: string }).comment_str;
    }
    if (token) {
      setIsSaving(true);
      const res = await httpClient
        .getAxios()
        .put(
          `${BASE_URL}/fields/${selectedCycle?.field?.id}/field-properties?update_past_forecasts=${update_past_forecasts}`,
          { ...updateDs },
          {
            headers: {
              Authorization: token,
              'tenant-header': farm?.organization.internal_name ?? ''
            }
          }
        )
        .catch(() => {
          setCycleFormError(true);
        });
      setIsSaving(false);
      // show the success toast
      if (res && (res.status === 200 || res.status === 201)) {
        toast({
          position: 'top-right',
          render: () => (
            <LimaToast
              status="success"
              message={`Field ${selectedCycle?.field?.name} cycle conditions have been saved`}
              marginTop="12vh"
              marginRight="3vw"
            />
          ),
          duration: 5000,
          isClosable: true
        });
      }
      if (res?.data?.field) {
        const upFieldProperties = res.data as FieldProperties;
        if (selectedCycle != null) {
          // update original harvest schedule with new decimal values
          // TODO: change back to update_harvest_schedule after code for update harvest schedule is updated
          // selectedCycle.harvest_schedule = update_harvest_schedule;
          selectedCycle.harvest_schedule = harvest_schedule;
          // reload comments
          selectedCycle.comments = upFieldProperties?.comments;
          selectedCycle.comment_str = '';
          setSelectedCycle(selectedCycle);
        }
        // DISABLED due to missing forecast summary and also fields list is reloaded
        // // const updatedBlocks = fields.map((field: Field) =>
        // //   field.id === upFieldProperties?.field?.id ? upFieldProperties?.field : field
        // // );
        // // setFields(updatedBlocks?.length ? updatedBlocks : []);
        // const farmBlNew = { ...farmFields };
        // farmBlNew[farm?.id as string] = updatedBlocks;
        // setFarmBlocks(farmBlNew);
        setIsSaving(false);
        setBlockCycleUpdated(true);
        setTimeout(() => {
          setBlockCycleUpdated(false);
        }, 1000);
      }
    }
  };

  const updateHarvestScheduleDates = (jointBlockCycle: FieldProperties): HarvestSchedule[] => {
    const harvest_schedule: HarvestSchedule[] = [];
    if (
      jointBlockCycle.field.forecast_summary.yields == null ||
      (jointBlockCycle.first_harvest_date == null &&
        jointBlockCycle.last_harvest_date == null &&
        varietyIsCyclic())
    ) {
      return [];
    }

    let first_date_harvest_farm_pred = moment(jointBlockCycle.first_harvest_date, 'YYYY-MM-DD');
    let last_date_harvest_farm_pred = moment(jointBlockCycle.last_harvest_date, 'YYYY-MM-DD');
    if (!varietyIsCyclic()) {
      first_date_harvest_farm_pred = moment(jointBlockCycle?.harvest_schedule[0].day, 'YYYY-MM-DD');
      last_date_harvest_farm_pred = moment(
        jointBlockCycle?.harvest_schedule[0].day,
        'YYYY-MM-DD'
      ).add(40, 'days');
    }

    const schedule_days =
      last_date_harvest_farm_pred.diff(first_date_harvest_farm_pred, 'days') + 1;
    const total_stems: number =
      jointBlockCycle.harvest_schedule != null && jointBlockCycle.harvest_schedule.length > 0
        ? jointBlockCycle.harvest_schedule
            .map((a) => a.stems)
            .reduce(function (a, b) {
              return a + b;
            })
        : 0;
    let i = 0;

    for (
      let curr_day = first_date_harvest_farm_pred;
      !curr_day.isAfter(last_date_harvest_farm_pred);
      curr_day.add(1, 'days')
    ) {
      let harvest_schedule_item = jointBlockCycle.harvest_schedule.find(
        ({ day }) => day === curr_day.format('YYYY-MM-DD')
      );
      if (harvest_schedule_item == undefined && varietyIsCyclic()) {
        // Creating a new harvest schedule item
        // New items are not created for continuous varieties
        const stems_no_round =
          jointBlockCycle.harvest_schedule == undefined
            ? ((jointBlockCycle.field.forecast_summary.yields / schedule_days) as number) //new item in a new harvest_schedule
            : 0; //new harvest_schedule item added to existing list
        const stems = Math.floor(stems_no_round);
        const newGrades: ForecastProductionGradeItem[] = [];
        if (variety != null) {
          variety.production_categories.forEach((production_category, index) => {
            const grade_stems =
              jointBlockCycle.field.grading != null &&
              jointBlockCycle.field.grading[index] != null &&
              jointBlockCycle.field.grading[index][production_category.id] != null
                ? +jointBlockCycle.field.grading[index][production_category.id]
                : 0;
            newGrades.push({
              [production_category.id]: {
                field_id: jointBlockCycle.field.id,
                production_category_id: production_category.id,
                entry_date: formatToServerFormat(curr_day.toDate()),
                yield_value: grade_stems != 0 && stems != 0 ? (stems * +grade_stems) / 100 : 0
              }
            });
          });
        }

        harvest_schedule_item = {
          id: i + '',
          _id: i + '',
          day: curr_day.format('YYYY-MM-DD'),
          stems: stems,
          stems_no_round: stems_no_round,
          grading: newGrades,
          stem_density: 0,
          loss: 0,
          dieback: 0,
          area: 0
        };
      } else {
        // retrieve existing harvest schedule item
        if (variety != null && harvest_schedule_item != undefined) {
          let stems_no_round = 0;

          if (varietyIsCyclic()) {
            stems_no_round =
              harvest_schedule_item.stems == 0 && total_stems == 0
                ? ((jointBlockCycle.field.forecast_summary.yields / schedule_days) as number)
                : harvest_schedule_item.stems * jointBlockCycle.field.forecast_summary.yields;
          } else {
            stems_no_round = harvest_schedule_item.stems_no_round;
          }
          const stems = Math.floor(stems_no_round);
          const newGrades: ForecastProductionGradeItem[] = [];
          variety.production_categories.forEach((production_category, index) => {
            if (
              harvest_schedule_item == null ||
              harvest_schedule_item.grading == null ||
              harvest_schedule_item.grading[index] == null ||
              !harvest_schedule_item?.grading[index][production_category.id] ||
              !harvest_schedule_item?.grading[index][production_category.id]?.yield_value ||
              harvest_schedule_item?.grading[index][production_category.id]?.yield_value == 0
            ) {
              // harvest schedule has does not have this grade
              newGrades.push({
                [production_category.id]: {
                  field_id: jointBlockCycle.field.id,
                  production_category_id: production_category.id,
                  entry_date: formatToServerFormat(curr_day.toDate()),
                  yield_value:
                    jointBlockCycle.field?.grading != null &&
                    jointBlockCycle.field?.grading[index] != null &&
                    jointBlockCycle.field?.grading[index][production_category.id] != null &&
                    varietyIsCyclic() &&
                    stems !== 0
                      ? Math.floor(
                          (+jointBlockCycle.field.grading[index][production_category.id] / 100) *
                            stems
                        )
                      : 0
                }
              });
            } else {
              // harvest schedule has does not have this grade
              const forecast_production_grade_item =
                harvest_schedule_item.grading[index][production_category.id];

              const yield_value =
                stems !== 0 &&
                forecast_production_grade_item != undefined &&
                forecast_production_grade_item.yield_value
                  ? varietyIsCyclic()
                    ? Math.floor((forecast_production_grade_item.yield_value / 100) * stems)
                    : Math.floor((forecast_production_grade_item.yield_value / 100) * stems)
                  : 0;

              newGrades.push({
                [production_category.id]: {
                  ...forecast_production_grade_item,
                  yield_value: yield_value
                }
              } as ForecastProductionGradeItem);
            }
          });

          harvest_schedule_item = {
            ...harvest_schedule_item,
            stems: stems,
            stems_no_round: stems_no_round,
            grading: newGrades
          };
        } else {
          const newGrades: ForecastProductionGradeItem[] = [];
          if (variety != null) {
            variety.production_categories.forEach((production_category) => {
              newGrades.push({
                [production_category.id]: {
                  field_id: jointBlockCycle.field.id,
                  production_category_id: production_category.id,
                  entry_date: formatToServerFormat(curr_day.toDate()),
                  yield_value: 0
                }
              });
            });
          }

          harvest_schedule_item = {
            id: i + '',
            _id: i + '',
            day: curr_day.format('YYYY-MM-DD'),
            stems: 0,
            stems_no_round: 0,
            grading: newGrades,
            stem_density: 0,
            loss: 0,
            dieback: 0,
            area: 0
          };
        }
      }
      if (harvest_schedule_item != undefined) {
        harvest_schedule.push(harvest_schedule_item);
      }
      i++;
    }
    return harvest_schedule;
  };

  const getPresignedUrls = async (token: string, blockId: string): Promise<void> => {
    if (token) {
      setBlockImages(await userBlocksRepository.getPresignedUrls(token, blockId));
    }
  };

  const handleFieldOptionsError = (status: number) => {
    if (status === 401) {
      // setAppAuth(false);
    }
  };

  const uprootBlock = async (
    token: string,
    tenant_header: string,
    field_id: string
  ): Promise<AxiosResponse<any, any>> => {
    return await httpClient.getAxios().put(
      `${BASE_URL}/fields/${field_id}/uproot`,
      {
        field_id: field_id
      },
      {
        headers: {
          Authorization: token,
          'tenant-header': tenant_header
        }
      }
    );
  };

  const reverseNewCycle = async (
    token: string,
    tenant_header: string,
    field_id: string
  ): Promise<AxiosResponse<any, any>> => {
    return await httpClient.getAxios().put(
      `${BASE_URL}/fields/${field_id}/reverse-start-new-cycle`,
      {
        field_id: field_id
      },
      {
        headers: {
          Authorization: token,
          'tenant-header': tenant_header
        }
      }
    );
  };

  const startNewCycle = async (
    token: string,
    tenant_header: string,
    field_id: string
  ): Promise<AxiosResponse<any, any>> => {
    return await httpClient.getAxios().put(
      `${BASE_URL}/fields/${field_id}/start-new-cycle`,
      {
        field_id: field_id
      },
      {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          'tenant-header': tenant_header
        }
      }
    );
  };

  const addNewBlock = async (values: NewBlockCondition, token: string) => {
    setIsSaving(true);
    const res = await httpClient
      .getAxios()
      .post(
        `fields`,
        {
          farm_id: farm?.id,
          variety_id: values.crop,
          name: values.block_name,
          parent_location: '',
          average_stem_density: 0,
          average_bed_width: 0,
          active: true,
          sub_sampled: true,
          area_value: values.area
          // forecast_summary: {
          //   first_harvest_date: values.planted_date,
          //   cutback_date: values.cutback_date
          // }
        },
        {
          headers: {
            Authorization: token,
            'tenant-header': farm?.organization.internal_name
              ? farm?.organization.internal_name
              : ''
          },
          params: {
            create_default_field_properties: true
          }
        }
      )
      .catch(() => {
        setIsSaving(false);
      });
    if (res?.status === 200 || res?.status === 201) {
      // show the success toast
      toast({
        position: 'top-right',
        render: () => (
          <LimaToast
            status="success"
            message={`Field ${values.block_name} has been added successfully`}
            marginTop="12vh"
            marginRight="3vw"
          />
        ),
        duration: 5000,
        isClosable: true
      });

      if (user?.role?.toLowerCase() === 'admin' && user?.onboarding_status !== 'complete') {
        await markOnboardingDone();
        setIsSaving(false);
      }
      const current = varieties.find((el: any) => el._id === res?.data?.variety);
      // resets variety to the variety the new field was added
      if (current) setVariety(current);
      setIsSaving(false);
      setNewBlockAdded(true);
      setTimeout(() => {
        setNewBlockAdded(false);
      }, 2000);
    } else {
      setIsSaving(false);
    }
  };

  const markOnboardingDone = async () => {
    const res = await httpClient
      .getAxios()
      .get(`${NORMAL_PREFIX_URL}organizations/complete-onboarding`, {
        headers: {
          Authorization: getToken()
        }
      })
      .catch(() => {});
    if (res?.data) {
      setCurrFarmOnboardStep(FarmOnboardSteps.DONE);
      await updateUser();
    }
  };

  const updateUser = async (): Promise<void> => {
    const res = await httpClient
      .getAxios()
      .get(`${NORMAL_PREFIX_URL}auth/cognito-profile`, {
        headers: {
          Authorization: getToken()
        }
      })
      .catch(() => {});
    if (res?.data) {
      const user: User = {
        ...res?.data,
        email: null
      };
      setUserCookie('user', user);
    }
  };

  const completeCycle = async (cycle: CompleteCycle, token: string) => {
    setIsSaving(true);
    const res = await httpClient
      .getAxios()
      .put(`${NORMAL_PREFIX_URL}blocks/complete/cycle/` + selectedCycle?.field.id, cycle, {
        headers: {
          Authorization: token
        }
      })
      .catch(() => {
        // if (error.response.status === 401) {
        //   setAppAuth(false);
        // }
        setCycleFormError(true);
        setIsSaving(false);
      });

    if (res?.data) {
      setIsSaving(false);
      const farmBlNew = { ...farmFields };
      farmBlNew[farm?.id as string] = farmBlNew[farm?.id as string].filter(
        ({ id }: Field) => id !== selectedCycle?.field.id
      );
      setSelectedCycle(null);
      setFarmBlocks(farmBlNew);
    }
  };

  const downloadHarvestSchedule = async (
    token: string,
    tenant_header: string,
    field_id: string
  ) => {
    setIsDownloadingHarvestSchedule(true);
    await httpClient
      .getAxios()
      .get(`${BASE_URL}/fields/${field_id}/download-harvest-schedule`, {
        headers: {
          Authorization: token,
          'tenant-header': tenant_header
        }
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(new Blob([response.data]));

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        let file_name = `harvest_schedule_${selectedCycle?.field.name}_`;
        if (varietyIsCyclic()) {
          file_name += `${selectedCycle?.first_harvest_date} to ${selectedCycle?.last_harvest_date}.csv`;
        } else {
          file_name += `${selectedCycle?.first_harvest_date} to ${
            selectedCycle?.forecast_productions[selectedCycle?.forecast_productions.length - 1]
              ?.entry_date
          }.csv`;
        }
        link.href = href;

        link.setAttribute('download', file_name);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        setIsDownloadingHarvestSchedule(false);
      })
      .catch(() => {
        setIsDownloadingHarvestSchedule(false);
      });
  };

  // useEffect(() => {
  //   if (farm && farmFields[farm.id as string]) {
  //     if (!selectedCycle) setSelectedCycle(null);
  //     setFields(farmFields[farm.id].filter((blocks: any) => blocks.variety === variety?._id));
  //   } else {
  //     setFields([]);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [farm, farmFields, variety, setVariety, setFarm]);

  useEffect(() => {
    if (!farms?.length) {
      setSelectedCycle(null);
      setFarm(null);
      setFarmBlocks({});
      setFields([]);
    } else {
      // reset farm on farms change only if there is no farm
      if (farm == null) {
        setFarm(farms[0]);
      } else {
        // this ensures that when varieties of the selected farm are editted the varieties are updated accurately
        const current = farms?.find((el: any) => el.id == farm.id);
        setFarm(current ? current : null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farms]);

  useEffect(() => {
    setVarieties([]);
    if (farm !== null) {
      setVarieties(farm?.varieties?.length ? farm.varieties : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm]);
  const fetchVarieties = async (farm: Farm) => {
    const response = await httpClient
      .getAxios()
      .get(`${BASE_URL}/organizations/varieties`, {
        headers: {
          Authorization: getToken(),
          'tenant-header': farm?.organization.internal_name ?? ''
        }
      })
      .catch(() => {});
    if (response && response.data) {
      const filtered_varieties = response.data.filter(
        (el: any) => el.tenant_name == farm?.organization.internal_name
      ) as Variety[];
      if (farm && farm.id == 'all') {
        setFarm({ ...farm, varieties: filtered_varieties });
        setVarieties(filtered_varieties);
      }
    }
  };

  useEffect(() => {
    if (varieties?.length) {
      // prevent variety reset when new block is added and when we switch from farm production
      const found = varieties?.find((el: any) => el._id == variety?.id);
      if (found) {
        setVariety(found);
      } else if (!newBlockAdded && !fieldsUpdated) setVariety(varieties[0]);
    } else {
      setVariety(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varieties]);

  const fetchOrganizations = async (token: string): Promise<string[]> => {
    setFetchingOrganizations(true);
    const header: { [x: string]: any } = {
      Authorization: token
    };
    if (user?.tenant_header) {
      header.push({ 'tenant-header': user?.tenant_header });
    }
    const res = await httpClient
      .getAxios()
      .get(`${BASE_URL}/organizations`, {
        headers: header
      })
      .catch(() => {
        setFetchingOrganizations(false);
      });
    setFetchingOrganizations(false);
    if (res?.data) {
      return res.data;
    }
    return [];
  };

  useEffect(() => {
    if (location.pathname == '/dashboard') {
      if (!farms?.find((farm) => farm.id === 'all') && organizations?.length > 0 && farms?.length) {
        organizations.forEach((organization) => {
          farms?.push({
            id: 'all',
            organization_id: organization.id,
            internal_name: 'All Farms',
            external_name: 'All Farms, ' + organization.external_name,
            organization: organization,
            country: '',
            city: '',
            varieties: organization.varieties,
            with_lima_forecasting: true
          });
        });
        setFarms(farms);
      }
    } else {
      if (farms?.find((farm) => farm?.id === 'all')) {
        const filteredFarms = farms?.filter(function (obj) {
          return obj.id !== 'all';
        });
        setFarms(filteredFarms?.length ? filteredFarms : []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, farms, organizations]); // added organizations to trigger when organizations are fetched

  const decrypt = (cypher: string): string | undefined => {
    let result;
    try {
      const dec = crypto.AES.decrypt(cypher, SECRET);
      return dec.toString(crypto.enc.Utf8);
    } catch (error) {
      return result;
    }
  };

  const getToken = (): string => {
    if (!cookies.lima_auth) {
      return '';
    }
    const token = decrypt(cookies.lima_auth as string);
    return token ?? '';
  };

  useEffect(() => {
    if (location.pathname == '/dashboard' || location.pathname == '/settings') {
      if (organizations.length === 0 && !fetchingOrganizations) {
        // TODO: re-enable this
        // fetch organizations when there are none
        const token = getToken();
        if (token)
          fetchOrganizations(token).then((orgs) => {
            setOrganizations(orgs);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations, location.pathname, fetchingOrganizations]);

  useEffect(() => {
    if (!onboardingmodalloading) {
      // check if cypress tests is running
      const isCypress = window.Cypress;
      if (currFarmOnboardStep !== FarmOnboardSteps.DONE && !isCypress) {
        // Should redirect only to the status report page (regardless of which of the links is clicked)
        const listOfRoutes = ['/farm-production', '/harvest-data'];
        if (
          listOfRoutes.includes(location.pathname) &&
          user &&
          user?.role?.toLowerCase() === 'admin' &&
          user?.onboarding_status !== 'complete'
        ) {
          navigate('/farm-production', { replace: true });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, currFarmOnboardStep, onboardingmodalloading, user]);

  return (
    <MyContext.Provider
      value={{
        isSaving,
        isDownloadingHarvestSchedule,
        setIsDownloadingHarvestSchedule,
        newBlockAdded,
        fieldsUpdated,
        setFieldsUpdated,
        cycleFormError,
        sidebarExpanded,
        toggleSideBar,
        farm,
        setFarm,
        farmFields,
        setFarmBlocks,
        selectedCycle,
        setSelectedCycle,
        selectedHarvest,
        setSelectedHarvest,
        farms,
        setFarms,
        fieldStatus,
        setFieldStatus,
        setOrganizations,
        fields,
        setFields,
        variety,
        setVariety,
        varietyIsCyclic,
        varieties,
        setVarieties,
        updateFieldProperties,
        completeCycle,
        appAuth,
        setAppAuth,
        addNewBlock,
        startNewCycle,
        reverseNewCycle,
        uprootBlock,
        handleFieldOptionsError,
        updateHarvestScheduleDates,
        blockCycleUpdated,
        getPresignedUrls,
        setBlockImages,
        blockImages,
        downloadHarvestSchedule,
        limaActiveModules,
        currentFarmOnboardStep: currFarmOnboardStep,
        setCurrentFarmOnboardStep: setCurrFarmOnboardStep,
        onBoardingModalActive: onboardingmodalactive,
        setOnboardingModalActive: setonboardingmodalactive,
        setOnboardingModalLoading: setonboardningmodalloading,
        onboardingModalLoading: onboardingmodalloading,
        loadingFarms,
        setLoadingFarms,
        organizations,
        fetchVarieties
      }}
    >
      {children}
    </MyContext.Provider>
  );
}
