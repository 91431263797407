import React, { useState, useEffect } from 'react';
import { Tabs, TabList, TabPanels, TabPanel, Tooltip } from '@chakra-ui/react';
import { Tab } from 'Components/Navigation';
import { GradesAndRejectsType } from 'data/GraphRepositoryImpl';
import GradesAndRejectsChart from './GradeAndRejectsChart';
import GradesAndRejectsTable from './GradeAndRejectsTable';
import { useAppContext } from 'Context';
import { useGraphContext } from 'Context/GraphContext';

export type GradesAndRejectsProps = {
  data: GradesAndRejectsType | null;
};
function GradesAndRejects(props: GradesAndRejectsProps) {
  const { data } = props;
  const { gradesAndRejectsTags } = useGraphContext();
  const { limaActiveModules } = useAppContext();
  const [displayYMax, setDisplayYMax] = useState<number>(0);
  useEffect(() => {
    setDisplayYMax(0);
    if (data) {
      const active = gradesAndRejectsTags.filter((el: any) => el.active)?.map((el: any) => el.id);
      let newMax = 0;
      active.forEach((key: any) => {
        if (key == 'rejected')
          newMax = newMax + data.highestGradeVsReject.staticData?.rejected ?? 0;
        else newMax = newMax + data?.highestGradeVsReject[key];
      });
      setDisplayYMax(newMax);
    }
  }, [gradesAndRejectsTags, data]);
  return (
    <>
      <Tabs pos="relative" align="end" variant="enclosed" mt="2rem">
        <TabList borderBottom={'0px'}>
          <Tab
            _selected={{
              bg: '#00A896',
              color: 'white'
            }}
          >
            Chart
          </Tab>
          <Tab
            _selected={{
              bg: '#00A896',
              color: 'white'
            }}
            isDisabled={!limaActiveModules.weekly_graded_table}
          >
            {limaActiveModules.weekly_graded_table ? (
              'Table'
            ) : (
              <Tooltip label="Coming Soon">Table</Tooltip>
            )}
          </Tab>
        </TabList>
        <TabPanels
          borderRadius={'4px'}
          backgroundColor="white"
          boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
        >
          <TabPanel>
            <GradesAndRejectsChart data={data} yMax={displayYMax} />
          </TabPanel>
          <TabPanel pb={4}>
            <GradesAndRejectsTable data={data} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default GradesAndRejects;
