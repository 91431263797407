import React, { useState, useEffect } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { FilterDropDown } from 'Components';
import { useGraphContext } from 'Context/GraphContext';
import { separateComma } from 'Helpers/helpers';
import Skeleton from 'react-loading-skeleton';
import { Calendar, Circle } from 'react-feather';
import { GradesAndRejectsProps } from './GradeAndRejectsChart';

function GradesAndRejectsHeader(props: GradesAndRejectsProps) {
  const { data } = props;
  const durationList = ['Daily', 'Weekly'];
  const {
    setTotalsDuration,
    reportFilterState,
    totalsDuration,
    loadingGradesAndRejects,
    setLoadingGradesAndRejects,
    gradesAndRejectsTags,
    setGradesAndRejectsTags
  } = useGraphContext();
  const today = new Date().toISOString().split('T')[0];
  const onSelectDuration = () => {
    return (selection: number | number[]) => {
      switch (selection) {
        case 0:
          setTotalsDuration('daily');
          break;
        case 1:
          setTotalsDuration('weekly');
          break;
        case 2:
          setTotalsDuration('monthly');
          break;
        default:
          break;
      }
    };
  };
  const setStatus = (id: any) => {
    setLoadingGradesAndRejects(true);
    gradesAndRejectsTags.map((tag: any) => {
      if (tag.id === id) {
        tag.active = !tag.active;
        return tag;
      }
      return tag;
    });
    setGradesAndRejectsTags([...gradesAndRejectsTags]);
    setTimeout(() => {
      setLoadingGradesAndRejects(false);
    }, 10);
  };
  const getValue = (grade: string) => {
    return data?.summedGrades[grade] ?? 0;
  };
  const getSubLabel = () => {
    if (reportFilterState?.days?.length && totalsDuration == 'daily') {
      return `\u00A0 Days | ${reportFilterState?.days[0]} to ${
        reportFilterState?.days[reportFilterState?.days?.length - 1]
      }`;
    } else if (reportFilterState?.weeks?.length && totalsDuration == 'weekly') {
      return `\u00A0 Weeks | ${reportFilterState?.weeks[0]} to ${
        reportFilterState?.weeks[reportFilterState?.weeks?.length - 1]
      }`;
    }
    return '';
  };

  const [reinitializeFilter, setReinitializeFilter] = useState<boolean>(true);
  const [selectedDuration, setSelectedDuration] = useState<number>(1);
  useEffect(() => {
    if (totalsDuration) {
      const found = totalsDuration === 'weekly' ? 1 : 0;
      setSelectedDuration(found);
    }
  }, [totalsDuration]);
  useEffect(() => {
    setReinitializeFilter(false);
    const initializeTimeout = setTimeout(() => {
      setReinitializeFilter(true);
    }, 10);
    return () => {
      clearTimeout(initializeTimeout);
    };
  }, [selectedDuration]);
  return (
    <Box>
      <Flex alignItems={'flex-start'} justifyContent="space-between">
        <Box>
          <Heading
            as="h4"
            mb="2"
            fontSize="1.35rem"
            fontWeight="normal"
            color="dark.coolGray.600"
            textTransform={'capitalize'}
          >
            {totalsDuration} Recorded Graded and Rejected
          </Heading>
          <Text as="p" color="dark.coolGray.500" align={'left'}>
            {`${reportFilterState?.years ?? today.split('-')[0]} ${getSubLabel()}` || (
              <Skeleton count={1} />
            )}
          </Text>
        </Box>
        {reinitializeFilter && (
          <FilterDropDown
            label={`${totalsDuration}`}
            items={durationList}
            selectedItem={selectedDuration}
            onSelectFilter={onSelectDuration()}
            name="grades-and-rejects"
            LeftIcon={Calendar}
          />
        )}
      </Flex>
      {!loadingGradesAndRejects && data?.actuals && (
        <>
          <Text fontWeight={'400'} fontSize="20" alignItems={'center'} display="flex" my="6px">
            Total Harvest&nbsp;&nbsp;|&nbsp;&nbsp;
            <Text as="span" fontSize="27" color="green.500">
              {separateComma(data?.totalHarvest ?? 0)}
            </Text>
          </Text>
        </>
      )}
      {!!data?.actuals?.length && !loadingGradesAndRejects && !!data.yMax && (
        <Flex gap={'0.75rem'} flexWrap="wrap">
          {gradesAndRejectsTags?.map((grade: any, i: number) => (
            <Box
              key={i}
              bg={grade.bg}
              padding="0.55rem 0.75rem"
              fontSize={'16px'}
              minWidth="160px"
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              justifyContent={'center'}
              borderRadius={'8px'}
              border={grade.active ? `3px solid ${grade.fill}` : ''}
              opacity={grade.active ? `100%` : '50%'}
              onClick={() => setStatus(grade.id)}
              cursor={'pointer'}
            >
              <Flex alignItems={'center'} gap="0.15rem">
                <Circle fill={grade.fill} color="transparent" width="16px"></Circle>
                <Text fontSize={'16px'} color="dark.coolGray.500">
                  {grade.label}
                </Text>
              </Flex>
              <Text
                fontWeight={'400'}
                fontSize={'22px'}
                lineHeight={'33px'}
                color="neutral.600"
                my="4px"
              >
                {separateComma(getValue(grade.id))}
              </Text>
              {!!data?.totalHarvest && (
                <Text color="dark.coolGray.500" fontSize={'16px'}>
                  {Math.round((+getValue(grade.id) / data.totalHarvest) * 100)}% of{' '}
                  {separateComma(data?.totalHarvest ?? 0)}
                </Text>
              )}
            </Box>
          ))}
        </Flex>
      )}
    </Box>
  );
}

export default GradesAndRejectsHeader;
