import React, { useEffect, useState } from 'react';
import { Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react';
import ForecastByFieldChart from './ForecastByFieldChart';
import ForecastByFieldTable from './ForecastByFieldTable';
import { FilterDatum, useGraphContext } from 'Context/GraphContext';
import { Tab } from 'Components/Navigation';
import { useAppContext } from 'Context';

function ForecastByField() {
  const { forecastedActiveFields, forecastedHarvestYields, forecastedHarvestTags } =
    useGraphContext();
  const { limaActiveModules } = useAppContext();
  const [tagsWithData, setTagsWithData] = useState<any>();
  const [displayYMax, setDisplayYMax] = useState<number>(0);
  useEffect(() => {
    setDisplayYMax(
      forecastedHarvestYields?.periodWithMax?.selectedTotal ?? forecastedHarvestYields?.maxPred
    );
    const withData: FilterDatum[] =
      forecastedHarvestTags?.filter((el: FilterDatum) =>
        forecastedHarvestYields?.fields?.includes(el.id)
      ) ?? [];
    const withoutData: string[] =
      forecastedHarvestTags
        ?.filter((el: FilterDatum) => !forecastedHarvestYields?.fields?.includes(el.id))
        ?.map((el: FilterDatum) => el.label) ?? [];
    setTagsWithData({ withData, withoutData });
  }, [forecastedHarvestTags, forecastedHarvestYields]);
  return (
    <>
      <Tabs pos="relative" align="end" variant="enclosed" mt="2rem">
        <TabList borderBottom={'0px'}>
          <Tab
            _selected={{
              bg: '#00A896',
              color: 'white'
            }}
            isDisabled={!limaActiveModules.forecast_harvest_by_field_chart}
          >
            Chart
          </Tab>
          <Tab
            _selected={{
              bg: '#00A896',
              color: 'white'
            }}
            isDisabled={!limaActiveModules.forecast_harvest_by_field_table}
          >
            Table
          </Tab>
        </TabList>
        <TabPanels
          borderRadius={'4px'}
          backgroundColor="white"
          boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
        >
          <TabPanel>
            <ForecastByFieldChart
              data={forecastedHarvestYields}
              fields={forecastedActiveFields}
              tags={tagsWithData}
              displayYMax={displayYMax}
            ></ForecastByFieldChart>
          </TabPanel>
          <TabPanel>
            <ForecastByFieldTable
              data={forecastedHarvestYields}
              fields={forecastedActiveFields}
              tags={tagsWithData}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default ForecastByField;
