import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Demand } from 'Context/types';
import { httpClient } from 'Helpers/appDependenciesHelpers';
import { useEffect } from 'react';

export type DemandQueryInput = {
  currentUserEmail: string | null | undefined;
  token: string;
  startDate: string;
  endDate: string;
  varietyId: string | null | undefined;
  farmId: string | null | undefined;
  tenantHeader: string;
  onDemandQueryKeyCreated?: (queryKey: (string | null | undefined)[]) => void;
  forceInvalidateQuery?: boolean;
  enabled?: boolean;
};

const useDemandQuery = (input: DemandQueryInput): UseQueryResult<Demand[], Error> => {
  const {
    currentUserEmail,
    forceInvalidateQuery,
    token,
    startDate,
    endDate,
    varietyId,
    farmId,
    tenantHeader,
    onDemandQueryKeyCreated,
    enabled = true
  } = input;
  const demandQueryKeys =
    (currentUserEmail === null || currentUserEmail === undefined) &&
    (varietyId === null || varietyId === undefined) &&
    (farmId === null || farmId === undefined) &&
    tenantHeader !== ''
      ? ['demand']
      : ['demand', currentUserEmail, varietyId, farmId, startDate, endDate, tenantHeader];

  useEffect(() => {
    if (onDemandQueryKeyCreated) {
      onDemandQueryKeyCreated(demandQueryKeys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demandQueryKeys]);

  async function getDemand(): Promise<Demand[]> {
    let demandItems: Demand[] = [];
    if (token) {
      const BASE_URL = `${process.env.REACT_APP_BE_URL}`;
      const res = await httpClient
        .getAxios()
        .get(`${BASE_URL}/varieties/${varietyId}/demands`, {
          headers: {
            Authorization: token,
            'Tenant-Header': tenantHeader
          },
          params: {
            start_date: startDate,
            end_date: endDate
          }
        })
        .catch(() => {});
      if (res?.data && res.data.length) {
        demandItems = res.data;
      }
    }
    return demandItems;
  }

  const getDemandQuery = useQuery({
    queryKey: demandQueryKeys,
    queryFn: getDemand,
    enabled:
      token !== '' &&
      (currentUserEmail !== null || currentUserEmail !== undefined) &&
      tenantHeader !== '' &&
      enabled
  });

  if (forceInvalidateQuery) {
    getDemandQuery.refetch();
  }

  return getDemandQuery;
};

export default useDemandQuery;
