import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Icon,
  InputGroup,
  InputLeftElement,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button as CKButton,
  Badge,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Spinner,
  IconButton,
  useToast,
  useDisclosure,
  Heading
} from '@chakra-ui/react';
import Button from 'Components/Button';
import styles from '../Settings.module.scss';
import {
  Mail,
  RefreshCcw,
  Edit3,
  Trash2,
  Settings,
  Circle,
  Save,
  Slash,
  User
} from 'react-feather';
import { colors } from 'theme';
import { FilterDropDown, MultiSelectDropdown } from 'Components';
import { Farm, ManageUser } from 'Context/types';
import { ManageUsersSchema } from 'Validations';
import Skeleton from 'react-loading-skeleton';
import LimaToast from 'Components/Toast/LimaToast';
import { BasicDropDown, InputWithIcon } from 'Components';
import { useAppContext, useAuthContext } from 'Context';
import { Field, Formik, FormikProps, useFormikContext } from 'formik';
import { Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody } from '@chakra-ui/react';
import _ from 'lodash';
import DashboardAppDeps from 'di/DashboardAppDeps';
import HttpClient from 'http/HttpClient';
import useDocumentTitle from 'Helpers/useDocumentTitle';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { getOrganization, objectsSame } from 'Helpers/helpers';
import { useCookies } from 'react-cookie';

function ManageUsers() {
  const { farm, farms, organizations } = useAppContext();

  useDocumentTitle('Member Settings | Lima Asili');
  const { trackPageView, pushInstruction } = useMatomo();
  const [userCookie] = useCookies(['user']);

  // tracking organization dimension
  useEffect(() => {
    pushInstruction('deleteCustomDimension', 1);
    pushInstruction('setCustomDimension', 1, farm?.organization?.external_name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm?.organization]);
  useEffect(() => {
    pushInstruction('deleteCustomDimension', 2);
    pushInstruction('setCustomDimension', 2, farm?.external_name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm]);

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const PREFIX_URL = `${process.env.REACT_APP_BE_URL}`;
  const appDeps: DashboardAppDeps = DashboardAppDeps.getInstance();
  const httpClient: HttpClient = appDeps.provideHttpClient();
  const initialValues: ManageUser = {
    email: '',
    org: '',
    farm: ['']
  };
  const [editValues, setEditValues] = useState<any>({
    email: '',
    farm: '',
    role: '',
    org: ''
  });
  const [formInitialValues, setFormInitialValues] = useState<any>(initialValues);
  const [users, setUsers] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]);

  const [organizationFarms, setOrganizationFarms] = useState<Farm[]>([]);
  const [orgsDropdownItems, setOrgsDropdownItems] = useState<any[]>([]);
  const [currentEdit, setCurrentEdit] = useState<any>();
  const {
    variables: { user },
    functions: { getToken }
  } = useAuthContext();
  const token = getToken();
  let queryHeaders: { [x: string]: any } = {
    Authorization: token
  };
  if (user?.tenant_header) queryHeaders = { ...queryHeaders, 'tenant-header': user?.tenant_header };
  const [clearSelection, setClearSelection] = useState<boolean>(false);
  const [clearFarmSelection, setClearFarmSelection] = useState<boolean>(false);
  const [shouldReloadValues, setShouldReloadValues] = useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentStatus, setCurrentStatus] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState<any>();
  const [loadingUsers, setLoadingUsers] = useState<boolean>();
  const showErrorToast = (message: string) => {
    toast({
      position: 'top-right',
      render: () => (
        <LimaToast status="error" message={message} marginTop={'22vh'} marginRight={'3vw'} />
      ),
      duration: 5000,
      isClosable: true
    });
  };

  const showSuccessToast = (message: string) => {
    toast({
      position: 'top-right',
      render: () => (
        <LimaToast status="success" message={message} marginTop={'22vh'} marginRight={'3vw'} />
      ),
      duration: 5000,
      isClosable: true
    });
  };

  const showWarningToast = (message: string) => {
    toast({
      position: 'top-right',
      render: () => (
        <LimaToast status="warning" message={message} marginTop={'22vh'} marginRight={'3vw'} />
      ),
      duration: 5000,
      isClosable: true
    });
  };

  const DeleteModal = () => {
    return (
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        blockScrollOnMount={true}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent boxShadow={'none'} borderRadius="10px" bg="white">
          <Box
            height="10px"
            backgroundColor={'#E4707A'}
            borderTopLeftRadius="10px"
            borderTopRightRadius="10px"
          ></Box>
          <ModalBody>
            <Box display="flex">
              <Icon
                as={Slash}
                strokeWidth="1.5px"
                height="2rem"
                width="2rem"
                color={'#D21122'}
                my={'24px'}
                mx="auto"
                alignSelf="center"
              />
            </Box>

            <Text color={'#DB414E'} fontSize="22px" textAlign="center">
              Warning: Account Deletion
            </Text>
            <Text color="#52606D" textAlign="center" my={'24px'}>
              By proceeding, you will{' '}
              <Text color="#d21122 !important" display="inline-flex" fontWeight="600" as="span">
                remove
              </Text>{' '}
              {currentStatus?.full_name ? currentStatus?.full_name : "the user's"} access to Lima
              Asili and they will no longer have access to the data related to all farms they are
              currently assigned to. Are you sure you want to proceed?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button onClick={onClose} className={styles.modal_close}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleStatus(currentStatus);
                }}
                background="#D21122 !important"
                color="#FFFFFF"
                width="61px"
                data-cy="proceed-to-delete"
              >
                Yes
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const fetchUsers = async () => {
    setLoadingUsers(true);
    setUsers([]);
    const response = await httpClient
      .getAxios()
      .get(`${PREFIX_URL}/organizations/users`, {
        headers: queryHeaders
      })
      .catch(() => {});
    if (response && response.data) {
      setUsers(response.data);
      setLoadingUsers(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    setRoles(['Admin', 'Member']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (organizations?.length) {
      const myOrgs = organizations?.map((item: any) => ({
        label: `${item.external_name}`,
        onClick: () => {},
        id: item.id
      }));
      const initials = { ...initialValues, orgs: myOrgs?.[0]?.id };
      setFormInitialValues(initials);
      setOrgsDropdownItems(
        [...myOrgs, { label: 'Lima Labs', onClick: () => {}, id: 'lima' }] ?? []
      );
      setTimeout(() => {
        setShouldReloadValues(true);
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations]);

  const handleSubmit = async (values: ManageUser, resetForm: any) => {
    const body = {
      email: values.email,
      farm_ids: values.farm
    };
    setIsSubmitting(true);
    let header: { [x: string]: any } = {
      Authorization: token
    };
    let invite_tenant_header = user?.tenant_header;
    if (user?.role == 'SuperAdmin' && values.org != 'Lima Labs') {
      const selectedOrganization = organizations.find(
        (organization) => organization.external_name == values.org
      );
      invite_tenant_header = selectedOrganization?.internal_name;
    }
    header = { ...header, 'tenant-header': invite_tenant_header };
    const res = await httpClient
      .getAxios()
      .post(
        `${PREFIX_URL}/users`,
        { ...body },
        {
          headers: header
        }
      )
      .catch((error) => {
        if (error && (error?.response?.status == 400 || error?.response?.status == 401))
          showErrorToast(error.response.data.detail);
        else showErrorToast('Invite has NOT been sent. Try again!');
      });
    if (res && (res?.status === 200 || res?.status === 201)) {
      showSuccessToast(`Success! An invite has been sent.`);
      resetForm({ values: { email: '', farm: '' } });
      setClearSelection(true);
      setClearFarmSelection(true);
      setTimeout(() => {
        setClearSelection(false);
        setClearFarmSelection(false);
      }, 5000);
      fetchUsers();
    }
    setIsSubmitting(false);
  };

  const editUser = async (user: any) => {
    setCurrentEdit(user);
    const org = getOrganization(organizations, user.farms?.[0]?.organization_id);
    const editValues = {
      email: user.email,
      farm: user.farms?.map((el: any) => el.id),
      role: user.role,
      id: user.id,
      org: org?.internal_name
    };
    setEditValues(editValues);
    setShouldReloadValues(true);
  };

  const handleEdit = async (values: ManageUser, resetForm: any) => {
    // ensure we only edit the current edit
    if (values.id !== currentEdit.id) return;
    const edit = { ...editValues };
    // do nothing if the values have not changed
    if (_.isEqual(values, edit)) {
      setCurrentEdit({});
      setEditValues({});
      return;
    }
    setIsLoading(true);
    const old = currentEdit?.farms?.map((el: any) => el.id);
    const to_remove = old.filter((el: string) => !values.farm.includes(el));
    const to_add = values.farm.filter((el: string) => !old.includes(el));
    const body = {
      cognito_username: currentEdit.username,
      full_name: currentEdit.full_name,
      org_role: 0,
      cognito_role: values.role,
      farms_to_add: to_add,
      farms_to_remove: to_remove
    };
    if (userCookie.user.tenant_header) {
      queryHeaders['tenant-header'] = userCookie.user.tenant_header;
    }
    const res = await httpClient
      .getAxios()
      .put(
        `${PREFIX_URL}/users/${currentEdit.id}`,
        { ...body },
        {
          headers: queryHeaders
        }
      )
      .catch(() => {
        showErrorToast(
          `${
            currentEdit?.full_name ? currentEdit?.full_name : 'User'
          } has been NOT been updated. Try again!`
        );
      });
    if (res && (res?.status === 200 || res?.status === 201)) {
      showSuccessToast(
        `${currentEdit?.full_name ? currentEdit?.full_name : 'User'} has been updated successfully`
      );
      resetForm({ values: { email: '', farm: '', role: '' } });
      fetchUsers();
      setCurrentEdit({});
      setEditValues({});
    }
    setIsLoading(false);
  };

  const editStatus = (user: any) => {
    if (user?.role === 'Admin') {
      showWarningToast('Contact support to delete an administrator.');
      return;
    }
    setCurrentStatus(user);
    onOpen();
  };

  const handleStatus = async (user: any) => {
    if (user?.role == 'Admin') {
      showWarningToast('Contact support to delete an administrator.');
      setCurrentStatus(null);
      return;
    }
    setIsLoading(true);
    onClose();
    const org = getOrganization(organizations, user.farms?.[0]?.organization_id);
    const res = await httpClient
      .getAxios()
      .delete(`${PREFIX_URL}/users/${user.id}`, {
        headers: {
          Authorization: token,
          'tenant-header': org.internal_name
        }
      })
      .catch(() => {
        showErrorToast(
          `${user?.full_name ? user?.full_name : 'User'} has been NOT been deleted. Try again!`
        );
      });
    if (res && res?.status === 204) {
      showSuccessToast(
        `${user?.full_name ? user?.full_name : 'User'} has been deleted successfully`
      );
      setCurrentStatus(null);
      fetchUsers();
    }
    setIsLoading(false);
  };

  const onSelectFilter = (type: string, setFieldValue: any) => {
    return (selection: any | any[]) => {
      if (type == 'role') setFieldValue(type, roles[selection]);
      else setFieldValue(type, selection?.length ? selection : null);
    };
  };
  const getSelection = () => {
    if (currentEdit) return currentEdit?.farms?.map((el: any) => el.id);
    else return [];
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (shouldReloadValues) {
      timeout = setTimeout(() => {
        setShouldReloadValues(false);
      }, 2000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shouldReloadValues]);

  const [formikValues, setFormikValues] = useState<ManageUser>({
    email: '',
    farm: [''],
    org: ''
  });
  const FormObserver: React.FC = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      const manageUser = values as ManageUser;
      if (!objectsSame(values, formikValues, null)) {
        const filteredFarms = farms.filter(
          (el: Farm) => el.organization.external_name == manageUser.org
        );
        setOrganizationFarms(filteredFarms != undefined ? filteredFarms : []);
        if (values) {
          if (manageUser.org != formikValues.org) {
            setClearFarmSelection(true);
            setTimeout(() => {
              setClearFarmSelection(false);
            }, 1000);
          }
          setFormikValues({ ...(values as ManageUser) });
        }
      }
    }, [values]);

    return <></>;
  };

  return (
    <>
      <DeleteModal />
      <Box p="1rem">
        <Heading fontSize={'1.688rem'} color="dark.coolGray.500" fontWeight={'400'} mb={3}>
          Invite Members
        </Heading>
        <hr
          style={{
            marginBottom: '2rem'
          }}
        />
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
          validationSchema={ManageUsersSchema}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({
            isValid,
            handleSubmit,
            errors,
            touched,
            dirty,
            setFieldValue,
            setTouched
          }: FormikProps<ManageUser>) => (
            <form onSubmit={handleSubmit}>
              <FormObserver />
              <Flex gap={10} alignItems={'flex-start'} flexWrap={'wrap'}>
                <Box mr={4}>
                  <FormControl isInvalid={!!errors.email && touched.email}>
                    <FormLabel
                      htmlFor="email"
                      fontSize={'16px'}
                      color={'black.300'}
                      mb={2}
                      fontWeight="400"
                    >
                      E-mail Address
                    </FormLabel>
                    <InputGroup width={'350px'}>
                      <InputLeftElement pointerEvents="none">
                        <Icon
                          as={Mail}
                          strokeWidth="1.5px"
                          height="24px"
                          width="auto"
                          color={colors.input.placeholder}
                          mx={2}
                        />
                      </InputLeftElement>
                      <Field
                        as={InputWithIcon}
                        id="email"
                        type="email"
                        name="email"
                        autoComplete="false"
                        placeholder="Member's e-mail address"
                        data-cy="new-user-email"
                      />
                    </InputGroup>
                    {errors && errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                  </FormControl>
                </Box>
                {userCookie.user.role == 'SuperAdmin' && organizations?.length > 1 && (
                  <Box>
                    <Text fontSize={'16px'} color={'black.300'} mb={2}>
                      Organization
                    </Text>
                    <BasicDropDown
                      dataCy={'new-farm-org'}
                      key={'org'}
                      id="org"
                      name="manage"
                      items={orgsDropdownItems}
                      showSelected={!!orgsDropdownItems.length}
                      onSelectFilter={onSelectFilter('org', setFieldValue)}
                      onBlur={(id: any) => {
                        if (id === 'org') {
                          setTouched({ ...touched, org: true });
                        }
                      }}
                      clearSelection={clearSelection}
                      hasError={!!(errors && errors.org && touched.org)}
                      matchWidth={true}
                      color="black.400"
                      width={'200px'}
                      triggerProps={{
                        width: '350px',
                        height: '43px',
                        minWidth: 'max-content',
                        border: '1.5px solid #CBD2D9',
                        __css: {
                          '& > span': {
                            display: 'grid !important',
                            gridTemplateColumns: '1fr 1rem',
                            gridColumnGap: '0.5rem',
                            width: '100%',
                            textAlign: 'left',
                            alignItems: 'center',
                            '& > .chakra-button__icon': {
                              marginInlineEnd: 0,
                              '& > svg': {
                                transform: 'scale(1)',

                                '&.drop-arrow': {
                                  transform: 'scale(0.7)'
                                }
                              }
                            }
                          }
                        }
                      }}
                      dropdownListProps={{
                        width: '350px',
                        border: '0px',
                        boxShadow: '0px 1px 6px 0px rgba(12, 12, 13, 0.15)'
                      }}
                    >
                      <Text fontWeight={400} color="dark.coolGray.300">
                        Select Organization
                      </Text>
                    </BasicDropDown>
                    {errors && errors.org && touched.org && (
                      <Text color="#E53E3E" marginTop="0.5rem" fontSize="0.875rem">
                        {errors.org}
                      </Text>
                    )}
                  </Box>
                )}
                <Box mr={2}>
                  <FormControl isInvalid={!!errors.farm && touched.farm}>
                    <FormLabel
                      htmlFor="farm"
                      fontSize={'16px'}
                      color={'black.300'}
                      mb={2}
                      fontWeight="400"
                    >
                      Assigned Farms
                    </FormLabel>
                    <MultiSelectDropdown
                      label="Select Farms"
                      items={Array.from(organizationFarms)}
                      onSelectFilter={onSelectFilter('farm', setFieldValue)}
                      onBlur={() => {
                        setTouched({ ...touched, farm: true });
                      }}
                      multi
                      name="manage"
                      hasError={!!(errors && errors.farm && touched.farm)}
                      selectedItems={[]}
                      clearSelection={clearFarmSelection}
                      dataCy="new-user-farms"
                      uniqueID="id"
                      displayParam="external_name"
                    />
                    {errors && errors.farm && touched.farm && (
                      <Text color="#E53E3E" marginTop="0.5rem" fontSize="0.875rem">
                        {errors.farm}
                      </Text>
                    )}
                  </FormControl>
                </Box>
                <Box alignSelf={'flex-start'} mt={8}>
                  <Button
                    theme="primary"
                    fontWeight="400"
                    fontSize="18px"
                    variant="solid"
                    borderColor="primary.500 !important"
                    color="white"
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    data-cy="invite-members-button"
                  >
                    <Icon
                      as={Mail}
                      strokeWidth="1.5px"
                      height="24px"
                      width="auto"
                      color="currentcolor"
                      mr={2}
                    />
                    Send Invite
                    {isSubmitting && (
                      <>
                        <Box px={1.5}></Box>
                        <Spinner color="white.500" size="sm" />
                      </>
                    )}
                  </Button>
                </Box>
              </Flex>
            </form>
          )}
        </Formik>
        <Flex
          justifyContent={'space-between'}
          alignContent={'center'}
          mb={3}
          mt={'3rem'}
          flexWrap="wrap"
        >
          <Heading
            fontSize={'1.688rem'}
            color="dark.coolGray.500"
            fontWeight={'400'}
            data-cy="tab-heading"
          >
            Manage Members
          </Heading>
          <CKButton
            backgroundColor={'transparent !important'}
            fontWeight="400"
            fontSize="18px"
            variant="solid"
            border={'1.5px solid'}
            borderColor="green.500"
            color="green.500"
            onClick={() => fetchUsers()}
            minWidth="fit-content"
          >
            <Icon
              as={RefreshCcw}
              strokeWidth="1.5px"
              height="24px"
              width="auto"
              color="currentcolor"
              mr={2}
            />
            Refresh Member Records
          </CKButton>
        </Flex>
        <hr
          style={{
            marginBottom: '2rem'
          }}
        />
        {users?.length == 0 ? (
          <>
            {loadingUsers ? (
              <Skeleton height={25} width={'100%'} />
            ) : (
              <Text>This organization has no members. Invite members to proceed.</Text>
            )}
          </>
        ) : (
          <>
            <Formik
              initialValues={editValues}
              onSubmit={(values, { resetForm }) => {
                handleEdit(values, resetForm);
              }}
              validationSchema={ManageUsersSchema}
              validateOnChange={true}
              validateOnBlur={true}
              enableReinitialize={shouldReloadValues}
            >
              {({
                isValid,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                setTouched
              }: FormikProps<ManageUser>) => (
                <form onSubmit={handleSubmit}>
                  <Table className={`${styles.table__content} ${styles.table__bordered}`} size="sm">
                    <Thead className={styles.table__content__head} zIndex="docked">
                      <Tr
                        className={`${styles.table__content__row} ${styles.table__content__head__row}`}
                      >
                        <Th textTransform="capitalize">Name</Th>
                        <Th textTransform="capitalize">E-mail Address</Th>
                        <Th textTransform="capitalize">Assigned Farms</Th>
                        <Th textTransform="capitalize">Role</Th>
                        <Th textTransform="capitalize">Status</Th>
                        <Th textTransform="capitalize">Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody className={`${styles.table__content__body}`}>
                      {users?.map((item, i) => (
                        <Tr
                          className={`${styles.table__content__row} ${styles.table__content__body__row}`}
                          key={i}
                        >
                          {currentEdit?.id === item.id ? (
                            <>
                              <Td>{item.full_name}</Td>
                              <Td color="#53A5FF">{currentEdit?.email}</Td>
                              <Td>
                                <FormControl isInvalid={!!errors.farm && touched.farm}>
                                  <MultiSelectDropdown
                                    label="Select Farm"
                                    items={Array.from(farms)}
                                    onSelectFilter={onSelectFilter('farm', setFieldValue)}
                                    onBlur={() => setTouched({ ...touched, farm: true })}
                                    multi
                                    name="manage"
                                    hasError={!!(errors && errors.farm && touched.farm)}
                                    selectedItems={getSelection()}
                                    clearSelection={clearSelection}
                                    dataCy="edit-member-farm"
                                    uniqueID="id"
                                    displayParam="external_name"
                                  />
                                  {errors && errors.farm && touched.farm && (
                                    <Text
                                      color="#E53E3E"
                                      marginTop="0.5rem"
                                      fontSize="0.875rem"
                                      textAlign={'left'}
                                    >
                                      {errors.farm}
                                    </Text>
                                  )}
                                </FormControl>
                              </Td>
                              <Td>
                                {currentEdit?.role != 'Member' ? (
                                  <Badge
                                    border="1px solid"
                                    borderColor={item.role != 'Member' ? '#EE7777' : '#53A5FF'}
                                    backgroundColor={item.role != 'Member' ? '#FCE4E4' : '#DDEDFF'}
                                    color="#52606D"
                                    textTransform={'capitalize'}
                                    className={`${styles.custom_badge}`}
                                  >
                                    <Icon
                                      as={item.role != 'Member' ? Settings : User}
                                      strokeWidth="2px"
                                      height="14.67px"
                                      width="auto"
                                      color="dark.coolGray.500"
                                      mr={'0.5rem'}
                                    />
                                    {item.role}
                                  </Badge>
                                ) : (
                                  <FormControl isInvalid={!!errors.role && touched.role}>
                                    <FilterDropDown
                                      label="Select Role"
                                      items={Array.from(roles)}
                                      selectedItem={roles.findIndex(
                                        (el: any) => el == currentEdit.role
                                      )}
                                      hasError={!!(errors && errors.role && touched.role)}
                                      onBlur={() => setTouched({ ...touched, role: true })}
                                      onSelectFilter={onSelectFilter('role', setFieldValue)}
                                      name="manage"
                                      data-cy="edit-member-role"
                                      color="dark.coolGray.300"
                                    />
                                    {errors && errors.role && touched.role && (
                                      <Text
                                        color="#E53E3E"
                                        marginTop="0.5rem"
                                        fontSize="0.875rem"
                                        textAlign={'left'}
                                      >
                                        {errors.role}
                                      </Text>
                                    )}
                                  </FormControl>
                                )}
                              </Td>
                              <Td>
                                <Badge
                                  border="1px solid"
                                  borderColor={!item.is_active ? '#7B8794' : '#00B91D'}
                                  backgroundColor={!item.is_active ? '#F5F5F5' : '#CCF1D2'}
                                  color={!item.is_active ? '#7B8794' : '#3E4C59'}
                                  className={`${styles.custom_badge}`}
                                  textTransform={'capitalize'}
                                >
                                  <Icon
                                    as={Circle}
                                    strokeWidth="2px"
                                    height="8px"
                                    width="auto"
                                    color={!item.is_active ? '#7B8794' : '#00B91D'}
                                    fill={!item.is_active ? '#7B8794' : '#00B91D'}
                                    mr={'0.5rem'}
                                  />
                                  {!item.is_active ? 'Not Active' : 'Active'}
                                </Badge>
                              </Td>
                              <Td>
                                <Flex justifyContent={'center'}>
                                  <IconButton
                                    aria-label="Save user edit"
                                    backgroundColor={'transparent'}
                                    height={'auto'}
                                    type="submit"
                                    className={styles.no_outline_button}
                                    disabled={!isValid || isLoading}
                                    icon={
                                      <Icon
                                        as={Save}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color="green.400"
                                      />
                                    }
                                    data-cy={`save-edit-${i}`}
                                  />
                                  <IconButton
                                    aria-label="Save user status"
                                    backgroundColor={'transparent'}
                                    height={'auto'}
                                    onClick={() => editStatus(item)}
                                    disabled={isLoading}
                                    className={styles.no_outline_button}
                                    icon={
                                      <Icon
                                        as={Trash2}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color={
                                          item.role == 'Admin' ? 'dark.coolGray.200' : '#DB414E'
                                        }
                                      />
                                    }
                                    data-cy={`delete-member-${i}`}
                                  />
                                </Flex>
                              </Td>
                            </>
                          ) : (
                            <>
                              <Td>{item.full_name}</Td>
                              <Td color="#53A5FF">{item.email}</Td>
                              <Td>
                                {item.farms?.length ? (
                                  <>
                                    {item.farms.map((farm: any, i: number) => (
                                      <Badge
                                        key={i}
                                        border="1px solid #53A5FF"
                                        backgroundColor={'#DDEDFF'}
                                        className={`${styles.custom_badge}`}
                                        m={'4px'}
                                        color={'#52606D !important'}
                                      >
                                        {farm.external_name}
                                      </Badge>
                                    ))}
                                  </>
                                ) : (
                                  <>-</>
                                )}
                              </Td>
                              <Td>
                                <Badge
                                  border="1px solid"
                                  borderColor={item.role != 'Member' ? '#EE7777' : '#53A5FF'}
                                  backgroundColor={item.role != 'Member' ? '#FCE4E4' : '#DDEDFF'}
                                  color="#52606D"
                                  textTransform={'capitalize'}
                                  className={`${styles.custom_badge}`}
                                >
                                  <Icon
                                    as={item.role != 'Member' ? Settings : User}
                                    strokeWidth="2px"
                                    height="14.67px"
                                    width="auto"
                                    color="dark.coolGray.500"
                                    mr={'0.5rem'}
                                  />
                                  {item.role}
                                </Badge>
                              </Td>
                              <Td>
                                <Badge
                                  border="1px solid"
                                  borderColor={!item.is_active ? '#7B8794' : '#00B91D'}
                                  backgroundColor={!item.is_active ? '#F5F5F5' : '#CCF1D2'}
                                  color={!item.is_active ? '#7B8794' : '#3E4C59'}
                                  className={`${styles.custom_badge}`}
                                  textTransform={'capitalize'}
                                >
                                  <Icon
                                    as={Circle}
                                    strokeWidth="2px"
                                    height="8px"
                                    width="auto"
                                    color={!item.is_active ? '#7B8794' : '#00B91D'}
                                    fill={!item.is_active ? '#7B8794' : '#00B91D'}
                                    mr={'0.5rem'}
                                  />
                                  {!item.is_active ? 'Not Active' : 'Active'}
                                </Badge>
                              </Td>
                              <Td>
                                <Flex justifyContent={'center'}>
                                  <IconButton
                                    aria-label="Save user edit"
                                    backgroundColor={'transparent'}
                                    height={'auto'}
                                    disabled={isLoading}
                                    onClick={() => {
                                      editUser(item);
                                    }}
                                    className={styles.no_outline_button}
                                    icon={
                                      <Icon
                                        as={Edit3}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color="green.400"
                                      />
                                    }
                                    data-cy={`edit-member-${i}`}
                                  />
                                  <IconButton
                                    aria-label="Save user status"
                                    backgroundColor={'transparent'}
                                    height={'auto'}
                                    onClick={() => editStatus(item)}
                                    className={styles.no_outline_button}
                                    disabled={isLoading}
                                    icon={
                                      <Icon
                                        as={Trash2}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color={
                                          item.role == 'Admin' ? 'dark.coolGray.200' : '#DB414E'
                                        }
                                      />
                                    }
                                    data-cy={`delete-member-${i}`}
                                  />
                                </Flex>
                              </Td>
                            </>
                          )}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </form>
              )}
            </Formik>
          </>
        )}
      </Box>
    </>
  );
}

export default ManageUsers;
