import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  FlexProps,
  GridProps,
  Grid,
  GridItem,
  Input,
  Text,
  Th,
  Thead,
  Tr,
  Table as CKTable,
  Tbody,
  Td,
  useToast
} from '@chakra-ui/react';
import { TableColumn, ToastProps } from 'Components/types';
import { Field, FormikErrors } from 'formik';
import styles from './Table.module.scss';
import { HarvestSchedule, CycleCondition, ProductionCategory } from 'Context/types';
import _ from 'lodash';
import { NotificationToast } from 'Components/Cards';
import { numberInputOnWheelPreventChange } from 'Helpers/helpers';
import LimaToast from 'Components/Toast/LimaToast';

type TableProps = FlexProps & {
  title?: string;
  subTitle?: ToastProps;
  data: any[];
  columns: TableColumn[];
  rowStyles?: GridProps;
  errors: FormikErrors<CycleCondition>;
  grades: ProductionCategory[];
};

export default function HarvestScheduleTable({
  subTitle,
  data,
  columns,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  rowStyles,
  errors,
  grades,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...props
}: TableProps): JSX.Element {
  const [displayData, setDisplayData] = useState<HarvestSchedule[]>([]);
  const toast = useToast();
  const showWarningToast = (message: string) => {
    toast({
      position: 'top-right',
      render: () => (
        <LimaToast status="warning" message={message} marginTop={'10vh'} marginRight={'3vw'} />
      ),
      duration: 5000,
      isClosable: true
    });
  };
  useEffect(() => {
    setDisplayData(data);
  }, [data]);

  const getColumns = function (count: any) {
    return `repeat(${count}, 1fr)`;
  };
  const exceptThisSymbols = ['e', 'E', '+', '-'];
  const handlePaste = (e: any) => {
    setTimeout(() => {
      if (
        !e?.target?.value ||
        exceptThisSymbols.some((substring) => e?.target?.value.includes(substring))
      ) {
        showWarningToast('Only numbers are allowed');
      }
    }, 1000);
  };
  return (
    <Box className={styles.table}>
      {/* Start head */}
      {/* Title */}
      <Box m={'0.7rem 0'}>
        {/* Sub title */}
        {subTitle && displayData.length > 0 && (
          <NotificationToast type={subTitle.type} message={subTitle.message} />
        )}
      </Box>

      <CKTable className={`${styles.table__content} ${styles.table__bordered}`} size="sm">
        <Thead className={styles.table__content__head} zIndex="docked">
          <Tr
            className={`${styles.table__content__row} ${styles.table__content__head__row}`}
            height="46px !important"
          >
            {columns.map((header, index) => {
              if (header.key !== 'grade_split') {
                return (
                  <Th key={header.name + index} fontSize={'14px'}>
                    <Flex alignItems="center" height="100%" width={'100px'}>
                      <Text textTransform="capitalize" marginRight={'1rem'} marginLeft={'1rem'}>
                        {header.name}
                      </Text>
                    </Flex>
                  </Th>
                );
              } else if (header.key === 'grade_split') {
                return (
                  <Th p={0} key={index} fontSize={'14px'}>
                    <Flex flexDir="column" p={0} m={0} width={'100%'}>
                      <Text textTransform="capitalize" p={2} textAlign="center">
                        {header.name}
                      </Text>
                      <Flex className={`${styles.table__content__head__grade_split_border_right}`}>
                        {grades != null
                          ? grades.map((grade, index) => (
                              <GridItem
                                flex="1"
                                textTransform="capitalize"
                                p={'0.5rem 0.25rem'}
                                borderTop="0px !important"
                                className={
                                  index <= grades.length - 2
                                    ? `${styles.table__content__head__grade_split_border_right}`
                                    : `${styles.table__content__head__grade_split_border_left}`
                                }
                                key={grade.id + '' + index}
                              >
                                {grade.external_name}
                              </GridItem>
                            ))
                          : ''}
                      </Flex>
                    </Flex>
                  </Th>
                );
              } else {
                return null;
              }
            })}
          </Tr>
        </Thead>
        <Tbody className={`${styles.table__content__body}`}>
          <>
            {displayData?.map((item: HarvestSchedule, index) => (
              <Tr
                height="42px !important"
                key={item._id + '_' + index}
                className={`${styles.table__content__row} ${styles.table__content__body__row}`}
              >
                {columns.map((column) => (
                  <Td alignItems="center" height="100%" key={item.id + column.key} p={2}>
                    <span className="value table-content" color="black.lighter.400 !important">
                      {/* grade split */}
                      {column.key == 'grade_split' ? (
                        <Grid templateColumns={getColumns(grades?.length)} gap={2} width={'100%'}>
                          {grades != null
                            ? grades.map((grade, gradeIndex) => (
                                <GridItem key={grade.id}>
                                  <Field
                                    as={Input}
                                    onWheel={numberInputOnWheelPreventChange}
                                    data-cy={
                                      'harvest_schedule[' +
                                      index +
                                      '].grading[' +
                                      gradeIndex +
                                      '][' +
                                      grade.id +
                                      '].yield_value'
                                    }
                                    name={
                                      'harvest_schedule[' +
                                      index +
                                      '].grading[' +
                                      gradeIndex +
                                      '][' +
                                      grade.id +
                                      '].yield_value'
                                    }
                                    variant="unstyled"
                                    placeholder="-"
                                    type="number"
                                    color="dark.coolGray.400"
                                    error={_.get(
                                      errors,
                                      'harvest_schedule[' +
                                        index +
                                        '].grading[' +
                                        gradeIndex +
                                        '][' +
                                        grade.id +
                                        '].yield_value'
                                    )}
                                    fontSize={'14px'}
                                    onKeyDown={(e: any) =>
                                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                                    }
                                    onPaste={(e: any) => {
                                      handlePaste(e);
                                    }}
                                    minWidth="5rem !important"
                                  />
                                </GridItem>
                              ))
                            : null}
                        </Grid>
                      ) : (
                        // day and stems
                        <Field
                          as={Input}
                          onWheel={numberInputOnWheelPreventChange}
                          data-cy={'harvest_schedule[' + index + '].' + column.key}
                          name={'harvest_schedule[' + index + '].' + column.key}
                          variant="unstyled"
                          placeholder="-"
                          color="dark.coolGray.400"
                          type={column.isDateTime ? 'text' : 'number'}
                          isRequired={column.isDateTime ? true : false}
                          isReadOnly={column.isDateTime ? true : false}
                          fontSize={'14px'}
                          onKeyDown={(e: any) =>
                            !column.isDateTime
                              ? exceptThisSymbols.includes(e.key) && e.preventDefault()
                              : null
                          }
                          onPaste={(e: any) => {
                            if (!column.isDateTime) {
                              handlePaste(e);
                            }
                          }}
                          minWidth="5rem !important"
                        />
                      )}
                    </span>
                  </Td>
                ))}
              </Tr>
            ))}
          </>
        </Tbody>
      </CKTable>
      {/* Old table
       <Flex
        className={`${styles.table__content} ${styles.table__bordered}`}
        {...props}
        direction="column"
        wrap="nowrap"
      >
        <Box className={styles.table__content__head}>
          <Flex
            wrap="nowrap"
            className={`${styles.table__content__row} ${styles.table__content__head__row} ${styles.table__content__row__harvest_schedule}`}
            {...rowStyles}
          >
            {columns.map((column) => (
              <Flex alignItems="center" height="100%" key={column.key}>
                <SimpleGrid columns={1} spacing={0} width={'100%'}>
                  <Text align="center" width="100%">
                    {column.name}
                  </Text>
                  {column.key == 'grade_split' ? (
                    <Grid templateColumns={getColumns(grades?.length)} gap={0}>
                      {grades != null
                        ? grades.map((grade, index) => (
                            <>
                              {Object.keys(grade).map((grade_item) => (
                                <GridItem
                                  className={
                                    index <= grades.length - 2
                                      ? `${styles.table__content__head__grade_split_border_right}`
                                      : `${styles.table__content__head__grade_split_border_left}`
                                  }
                                  key={grade_item + '' + index}
                                >
                                  {grade[grade_item]}
                                </GridItem>
                              ))}
                            </>
                          ))
                        : ''}
                    </Grid>
                  ) : null}
                </SimpleGrid>
              </Flex>
            ))}
          </Flex>
        </Box>
        <Box className={styles.table__content__body}>
          {displayData.map((item: HarvestSchedule, index) => (
            <Flex
              wrap="nowrap"
              key={item.day}
              className={`${styles.table__content__row} ${styles.table__content__body__row} ${styles.table__content__row__harvest_schedule}`}
              {...rowStyles}
            >
              {columns.map((column) => (
                <Flex alignItems="center" height="100%" key={column.key}>
                  <span className="value">
                    {column.key == 'grade_split' ? (
                      <Grid templateColumns={getColumns(grades?.length)} gap={2} width={'100%'}>
                        {grades != null
                          ? grades.map((grade, gradeIndex) => (
                              <>
                                {Object.keys(grade).map((grade_item) => (
                                  <GridItem key={grade_item}>
                                    <Field
                                      as={Input}
                                      name={
                                        'harvest_schedule[' +
                                        index +
                                        '].grading[' +
                                        gradeIndex +
                                        '].' +
                                        grade_item
                                      }
                                      variant="unstyled"
                                      placeholder="-"
                                      type="number"
                                      color="dark.coolGray.400"
                                      error={_.get(
                                        errors,
                                        'harvest_schedule[' +
                                          index +
                                          '].grading[' +
                                          gradeIndex +
                                          '].' +
                                          grade_item
                                      )}
                                    />
                                  </GridItem>
                                ))}
                              </>
                            ))
                          : ''}
                      </Grid>
                    ) : (
                      // day and stems
                      <Field
                        as={Input}
                        name={'harvest_schedule[' + index + '].' + column.key}
                        variant="unstyled"
                        placeholder="-"
                        color="dark.coolGray.400"
                        type={column.isDateTime ? 'text' : 'number'}
                        isRequired={column.isDateTime ? true : false}
                        isReadOnly={column.isDateTime ? true : false}
                      />
                    )}
                  </span>
                </Flex>
              ))}
            </Flex>
          ))}
        </Box>
      </Flex> */}
    </Box>
  );
}
