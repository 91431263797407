import { Box } from '@chakra-ui/react';
import React from 'react';

const TermsAndConditions = () => {
  const agreementRef5 = React.useRef<HTMLDivElement>(null);
  const agreementRef8 = React.useRef<HTMLDivElement>(null);
  const agreementRef9_5 = React.useRef<HTMLDivElement>(null);
  const agreementRef13 = React.useRef<HTMLDivElement>(null);
  const agreementRef14 = React.useRef<HTMLDivElement>(null);
  const agreementRef15 = React.useRef<HTMLDivElement>(null);

  const scrollToAgreement = (agreementNum: 5 | 8 | 9.5 | 13 | 14 | 15) => {
    switch (agreementNum) {
      case 5:
        agreementRef5?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 8:
        agreementRef8?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 9.5:
        agreementRef9_5?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 13:
        agreementRef13?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 14:
        agreementRef14?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 15:
        agreementRef15?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        break;
    }
  };

  return (
    <Box p="4rem" w="100vw" h="100vh">
      <div style={{ fontSize: '1rem', fontFamily: 'Arial' }}>
        <h1 style={{ fontSize: '2rem', fontWeight: 'bold' }}>LIMA Terms of Service</h1>

        <br />

        <p style={{ fontSize: '1rem', fontWeight: 'bold' }}>
          IT IS IMPORTANT THAT YOU READ AND UNDERSTAND THE FOLLOWING TERMS. BY CLICKING
          &quot;AGREE,&quot; YOU ARE AGREEING TO BE BOUND BY THESE TERMS AND CONDITIONS AND THAT
          YOUR ACCESS AND/OR USE OF THE PLATFORM AND THE SERVICES WILL BE GOVERNED BY THESE TERMS
          AND CONDITIONS.&nbsp;
        </p>

        <br />

        <p>
          <span style={{ fontWeight: 'bold' }}>This&nbsp;</span>
          <span style={{ fontWeight: 'bold', fontSize: '1.188rem' }}>Agreement&nbsp;</span>is made:
        </p>

        <br />

        <p>Between</p>

        <br />

        <div style={{ paddingLeft: '1rem' }}>
          <ol style={{ listStyleType: 'decimal' }}>
            <li style={{ textAlign: 'justify' }}>
              Lima Labs Limited a private limited liability company duly incorporated under the laws
              of Kenya of Mbagathi Ridge no 8, PO Box 379,00502, Nairobi Kenya (hereinafter called
              “Lima”).
            </li>
          </ol>

          <br />

          <p style={{ textAlign: 'justify' }}>&nbsp;&nbsp;And</p>

          <br />

          <ol style={{ listStyleType: 'decimal' }} start={2}>
            <li style={{ textAlign: 'justify' }}>
              The Customer, (hereinafter called “you” or the “User”).&nbsp;
            </li>
          </ol>
        </div>

        <br />
        <br />

        <p style={{ textAlign: 'justify' }}>
          (each is also referred to as “a Party” and together as “the Parties”).
        </p>

        <br />

        <p style={{ textAlign: 'justify' }}>
          This Agreement is effective as of the date you accept to be bound by it when signing up
          for an account with Lima (the “Effective Date”). If you are agree to the terms and
          conditions of this Agreement as the User, you represent and warrant that: (i) you have
          full legal authority to bind the User to this Agreement; (ii) you have read and understood
          this Agreement; and (iii) you agree, on behalf of the User, to adhere to and be bound by
          this Agreement. If you do not have the legal authority to bind the User, please do not
          agree to the terms and conditions of this Agreement. This Agreement governs the User’s
          access to and use of the Service.
        </p>

        <br />
        <br />

        <h2 style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
          Definitions
        </h2>

        <br />

        <div style={{ paddingLeft: '2rem' }}>
          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>&quot;Affiliate&quot;&nbsp;</strong>means any entity that directly or
              indirectly controls, is controlled by, or is under common control with a party.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>“Acceptable Use Policy” or “AUP”</strong> has the meaning given to it in
              Section 3.9 below.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>“Account”&nbsp;</strong>means the User&apos;s account with the Platform.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>&quot;Brand Features&quot;&nbsp;</strong>means the trade names, trademarks,
              service marks, logos, domain names, and other distinctive brand features of each
              party, respectively, as secured by such party from time to time.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>“Crop Monitoring”&nbsp;</strong>means the monitoring of crops using the Lima
              Solution.
            </li>

            <br />

            <li style={{ textAlign: 'justify' }}>
              <strong>&quot;Confidential Information&quot;&nbsp;</strong>means information that one
              party (or an Affiliate) discloses to the other party under this Agreement, and which
              is marked as confidential or would normally under the circumstances be considered
              confidential information, including any information handed over to such party in
              accordance with this Agreement. It does not include information that is independently
              developed by Lima, is rightfully given to the recipient by a third party without
              confidentiality obligations, or becomes public through no fault of the recipient.
              &nbsp; &nbsp;
            </li>

            <br />

            <li style={{ textAlign: 'justify' }}>
              <strong>“Data Management and Visualization”&nbsp;</strong>means the service features
              provided by Lima through the Platform.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>&quot;Data Privacy Statement&quot;&nbsp;</strong>means the statement available
              on Lima’s website setting out the terms on which Lima processes Personal Data.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>“Documentation”&nbsp;</strong>means all information provided to the User by
              Lima in the Platform from time to time in relation to the provision of the Services
              and includes the Service Agreement, TSS Form and Personal Data Privacy Statement.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>“Dataset”&nbsp;</strong>means a set of data in any format in the scope of the
              Services.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              “<strong>Equipment</strong>” means all fixtures, equipment, and other tangible assets
              including, but not limited to, cameras, sensors and drones, used or intended for use
              in or in connection with, or installed at the premises of the User by Lima or its
              sub-contractors, for the provision of the Services, all of which are owned by Lima or
              its sub-contractors.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>&nbsp;“End Users”&nbsp;</strong>means the individuals or entities that the
              User permits to use the Services
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>&quot;Fee Accrual Period</strong>&quot; means a calendar month or another
              period specified by Lima in the Platform. &nbsp; &nbsp;
            </li>

            <br />

            <li style={{ textAlign: 'justify' }}>
              <strong>&quot;Fees&quot;&nbsp;</strong>means the applicable fees for the Services and
              any applicable TSS Fees but excluding all Taxes as set out in the Service Agreement
              and TSS Form&nbsp;&nbsp;(as the case may be).
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>&quot;High Risk Activities&quot;&nbsp;</strong>means activities where the use
              or failure of the Services could lead to death, personal injury, or environmental
              damage (including but not limited to operation near nuclear facilities, air traffic
              control, life support systems, or weaponry).
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>“Initial Training Period”&nbsp;</strong>means the training period provided by
              Lima being 90 days before the Services are accessible via the Platform. &nbsp; &nbsp;
            </li>

            <br />

            <li style={{ textAlign: 'justify' }}>
              &quot;<strong>Intellectual Property</strong>&quot; means any, know-how (not in the
              public domain), invention (whether patented or not), Brand Features or copyright
              material (whether or not registered), processes, process methodology (whether patented
              or not), rights associated with goodwill and reputation under which a party may market
              or operate its business from time to time, and all other identical or similar
              intellectual property as may exist anywhere in the world which is not in the public
              domain whether or not registered, (together with all rights, interests or licences in
              or to any of those rights) and any applications for registration of such intellectual
              property; &nbsp; &nbsp;
            </li>

            <br />

            <li style={{ textAlign: 'justify' }}>
              &quot;<strong>Intellectual Property Rights</strong>&quot; means, rights in relation to
              any Intellectual Property in the Platform, the Services, the Lima Solution, Crop
              Monitoring, Data Management and Visualization and the Models, and all and any: (i)
              proprietary rights thereto; or (ii) any other right, title, authority or entitlement
              owned, held by or that belong to Lima in respect thereof, whether under license or
              otherwise;
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>&quot;Legal Process&quot;&nbsp;</strong>means a data disclosure request made
              that requires mandatory compliance under law, governmental regulation, court order,
              subpoena, warrant, governmental regulatory or agency request, or other valid legal
              authority, legal procedure, or similar process.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>“Lima Solution”&nbsp;</strong>means Lima’s proprietary system and method
              provided through the Platform to collect and analyze data for Crop Monitoring.
            </li>

            <br />

            <li style={{ textAlign: 'justify' }}>
              <strong>“Models”&nbsp;</strong>means machine learning, image recognition models
              including user manuals, source code and other materials sufficient for maintaining and
              modifying the software of the Platform, all of which are owned by Lima.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              “<strong>Personal Data</strong>” means any information relating to an identified or
              identifiable User or End User that is a natural person including a natural person
              representing a User or End User which is a legal entity.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>“Platform”&nbsp;</strong>means the console(s) and/or tool(s) and/or online
              services provided by Lima to the User for administering the Services, and which
              consists of the Models.
            </li>

            <br />

            <li style={{ textAlign: 'justify' }}>
              <strong>&quot;Services&quot;&nbsp;</strong>means any and all services provided by or
              through the Platform (including any associated services) which may be summarized as
              Crop Monitoring and Data Management and Visualization. &nbsp; &nbsp;
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              “<strong>Surviving Provisions</strong>” means Sections 5, 8, 9.5, 13, 14, and 15 (all
              inclusive) and any other provision of this Agreement which are expressed to continue
              in force after termination or by which by necessary implication must continue after
              termination.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>“Service Agreement”</strong> means the agreement completed and signed by a
              duly authorized representative of the User making an order for the Services and
              setting out the Fees for the Services.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              “<strong>Service Specific Terms</strong>” means the unique terms set out in the
              Service Agreement signed by or on behalf of the User.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>&quot;Term&quot;&nbsp;</strong>has the meaning set forth in Section 9 of this
              Agreement. &nbsp; &nbsp;
            </li>

            <br />

            <li style={{ textAlign: 'justify' }}>
              <strong>“TSS”&nbsp;</strong>means the technical support services provided by Lima to
              the User.
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              “<strong>TSS Fees</strong>”<strong>&nbsp;</strong>means the fees payable by the User
              to Lima in relation to the TSS<strong>.</strong>
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              “<strong>TSS Form</strong>” means the form completed
              <strong>&nbsp;</strong>and signed by a duly authorized representative of the User
              making an order for TSS and setting out the TSS Fees for the TSS. &nbsp; &nbsp;
            </li>
          </ul>

          <br />

          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              <strong>“User Data”&nbsp;</strong>means data provided to Lima by the User via the
              Services through the Account excluding Personal Data and includes data provided by End
              Users.
            </li>
          </ul>
        </div>

        <br />

        <ol style={{ listStyleType: 'decimal' }}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>Provision of the Services.</h2>
          </li>
        </ol>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>1.1 Services Use</strong>. Subject to this Agreement, during the Term, the User
          may: (a) use the Services, (b) use any software or functionality provided by Lima as part
          of the Services, including any associated applications, projects, and User Data. The User
          may not sublicense or transfer these rights unless in accordance with the terms of this
          Agreement.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>&nbsp;1.2 Platform.&nbsp;</strong>Lima will provide the Services by making the
          online Platform available to the User after the Initial Training Period. As part of
          receiving the Services, the User will have access to the Platform after the Initial
          Training Period has been completed by Lima, through which the User may then administer the
          Services.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>1.3 Facilities.&nbsp;</strong>All facilities used to store and process an
          application, the User Data, and the Personal Data will adhere to reasonable security
          standards no less protective than the security standards at facilities where Lima
          processes and stores its own information of a similar type. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>1.4 Data Location.&nbsp;</strong>Lima may process and store the User Data and
          Personal Data anywhere Lima, its agents, or cloud service providers maintain their
          servers. By using the Services, the User consents to this processing and storage of User
          Data. The User also acknowledges that Personal Data may be processed in accordance with
          Lima’s Personal Data Privacy Statement. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>1.5 Accounts.&nbsp;</strong>The User must have an Account with Lima, and is
          responsible for the information it provides to create the Account, the security of the
          Account, and its passwords for the Account, and for any use of its Account. If the User
          becomes aware of any unauthorized use of its password for its Account, the User must
          notify Lima as soon as possible. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>&nbsp;1.6 New Applications and Services.&nbsp;</strong>Lima may: (i) make new
          applications, tools, features or functionality available from time to time through the
          Services and (ii) expand the scope of the Services definition from time to time, the use
          of which may be contingent upon the User’s agreement to additional terms. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>1.7 Modifications.</strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          a). To the Services: Lima may make commercially reasonable updates to the Services from
          time to time. If Lima makes a material change to the Services, Lima may inform the User,
          provided that the User has subscribed with Lima to be informed about such change.
        </p>
        <p style={{ textAlign: 'justify' }}>
          b). To the Agreement: Lima may make changes to this Agreement, including pricing (and any
          linked documents) from time to time. Lima will provide at least 30 days&apos; advance
          notice for materially adverse changes to the pricing by either: (i) sending an email to
          the User&apos;s primary point of contact or (ii) posting a notice in the Platform
          notifications. If the User does not agree to the revised Agreement, it must stop using the
          Services. &nbsp; &nbsp;
        </p>
        <p style={{ textAlign: 'justify' }}>
          c). To the Personal Data Privacy Statement: Lima may only change the Personal Data Privacy
          Statement.&nbsp;
        </p>

        <br />

        <p style={{ textAlign: 'justify' }}>
          <strong>1.8 Electronic communications</strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          By using the Platform by electronic means, the User consents and acknowledges that any and
          all agreements, notices, disclosures, or any other communication satisfies any legal
          requirement, including to the requirement that such communications should be &quot;in
          writing&quot;.
        </p>

        <br />

        <ol style={{ listStyleType: 'decimal' }} start={2}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>Payment Terms.</h2>
          </li>
        </ol>

        <br />

        <p style={{ textAlign: 'justify' }}>
          <strong>2.1 Billing.&nbsp;</strong>Where an Service Agreement or TSS Form (as the case may
          be) is in place, and at the end of the applicable Fee Accrual Period, Lima will issue an
          invoice to the User, for all charges accrued based on (i) the User&apos;s use of the
          Services during the applicable Fee Accrual Period (including, if any, the relevant TSS
          Fees set forth in clause 6.2&nbsp;&nbsp;below); All Fees are due as set forth in the
          invoice and are payable within thirty (30) days or by the date specified by Lima in the
          invoice whichever is sooner (<strong>Payment Due Date</strong>). The User’s obligation to
          pay all Fees is non-cancellable. Lima’s measurement of User’s use of the Services is
          final. Lima has no obligation to provide multiple Invoices. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>2.2 Taxes.&nbsp;</strong>(a) Fees do not include any customs duties, value added
          tax, withholding taxes, income taxes or any other direct or indirect taxes which may be
          levied or charged by any national, state, local or other governmental authorities and
          agencies. The User is responsible for any Taxes, and the User will pay Lima for the
          Services without any reduction for Taxes. If Lima is obligated to collect or pay Taxes,
          the Taxes will be invoiced to the User. (b) If required under applicable law, the User
          will provide Lima with applicable tax identification information that Lima may require to
          ensure its compliance with applicable tax regulations and authorities in applicable
          jurisdictions. The User will be liable to pay (or reimburse Lima for) any taxes, interest,
          penalties or fines arising out of any mis-declaration by the User. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>2.3 Invoice Disputes &amp; Refunds.&nbsp;</strong>Any invoice disputes must be
          submitted prior to the Payment Due Date. If the parties determine that certain billing
          inaccuracies are attributable to Lima, Lima will not issue a corrected invoice, but will
          instead issue a credit note specifying the incorrect amount in the affected invoice. If
          the disputed invoice has not yet been paid, Lima will apply the credit note amount to the
          disputed invoice and the User will be responsible for paying the resulting net balance due
          on that invoice. To the fullest extent permitted by law, the User waives all claims
          relating to Fees unless claimed within sixty days after charged. Refunds (if any) are at
          the discretion of Lima and will only be in the form of credit for the Services. Nothing in
          this Agreement obligates Lima to extend credit to any party. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>2.4 Delinquent Payments; Suspension.&nbsp;</strong>Late payments may bear interest
          at the rate of 1.5% per month (or the highest rate permitted by law) from the Payment Due
          Date until paid in full. The User will be responsible for all reasonable expenses
          (including legal fees) incurred by Lima in collecting such delinquent amounts. If the User
          is late on payment for the Services, Lima may suspend the Services or terminate the
          Agreement for breach pursuant to Section 9.2. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>2.5 Invoicing.&nbsp;</strong>The User is obligated to pay all applicable Fees
          provided that Lima will number each invoice and attach the relevant Service Agreement to
          each invoice. &nbsp; &nbsp;
        </p>
        <br />
        <ol style={{ listStyleType: 'decimal' }} start={3}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>User Obligations.</h2>
          </li>
        </ol>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>&nbsp;3.1 Compliance.&nbsp;</strong>The User is solely responsible for its User
          Data and for making sure its User Data complies with the AUP Lima reserves the right to
          review the User Data for compliance with the AUP at reasonable times. User is responsible
          for ensuring all End Users comply with the User’s obligations under the AUP, the Service
          Specific Terms, and the restrictions in Sections 3.3 and 3.5 below.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>3.2 Privacy.&nbsp;</strong>The User understands that by using the Platform and the
          Services, Lima may collect and use of certain information about the User and its use of
          the Service, in accordance with Lima&apos;s Personal Data Privacy Statement. The User
          acknowledges that Lima’s processing of Personal Data shall be in accordance with its
          Personal Data Privacy Statement. Where necessary, the User shall obtain and maintain the
          End Users’ Consent to record and process the End Users’ Personal Data including their
          name, email address, employer name, employer location and their Job title for Lima to
          provide the End Users’ access to the Platform. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>&nbsp;3.3 Restrictions.&nbsp;</strong>The User will not, and will not allow third
          parties under its control, to: (a) copy, modify, create a derivative work of, reverse
          engineer, decompile, translate, disassemble, or otherwise attempt to extract any or all of
          the source code of the Services or the Platform (subject to Section 3.4 below and except
          to the extent such restriction is expressly prohibited by applicable law); (b) hold Lima
          responsible in any way for use of the Services for High Risk Activities; (c) sublicense,
          resell, or distribute any or all of the Services separate from any integrated Application;
          (d) create multiple Accounts to simulate or act as a single Account (respectively) or
          otherwise access the Services in a manner intended to avoid incurring Fees or exceed usage
          limits or quotas; (e) integrate the Services within a data or application marketplace
          which encompasses selling data products related to agriculture. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>3.4 Third Party Components.&nbsp;</strong>Third party components (which may
          include open source software) of the Services may be subject to separate license
          agreements. To the limited extent a third party license expressly supersedes this
          Agreement, that third party license governs the User’s use of that third party component.
          &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>3.5 Documentation.&nbsp;</strong>Lima may provide Documentation for the User’s use
          of the Services. The Documentation may specify restrictions on how the Services may be
          built or the Services may be used and the User will comply with any such restrictions
          specified.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>3.6 Copyright Policy.&nbsp;</strong>The User will not allow third parties under
          its control to &nbsp;use any Service to store, copy, or reproduce copyrighted material.
          Lima may determine whether copy righted material is being used legally or not through the
          input and cooperation of the copyright holder. Lima responds to notices of alleged
          copyright infringement and will terminate accounts of infringers according to applicable
          copyright laws. If the User thinks somebody is violating the User’s or the End Users’
          copyrights on the Platform and wants to notify Lima, the User can submit a notice by
          contacting{' '}
          <a href="mailto:support@lima.ag">
            <span style={{ color: '#0b4cb4' }}>support@lima.ag</span>
          </a>
          <span style={{ color: '#0b4cb4' }}>&nbsp; &nbsp;&nbsp;</span>
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>3.7 Access.&nbsp;</strong>The User grants or shall procure the unconditional and
          irrevocable grant to Lima and its Affiliates, employees, agents and sub-contractors,
          representatives during the term of this Agreement and for a duration of not less than
          three months following termination: (a) unfettered access to the User’s farm on which Lima
          Services are in use, has installed Equipment, or is operating Equipment, to install,
          replace, maintain, disconnect, test, repair such Equipment at any time; (b) permission to
          remove any of its Equipment from the User’s farm at any time; (c) permission to take
          environmental and weather data including air temperatures, humidity, soil temperatures and
          soil moisture from the User’s farm. The User gives Lima and its representatives, on
          reasonable notice, such access to premises, personnel, software, Equipment, information
          and farm records as Lima requires to perform its obligations under this Agreement to
          verify that the User is complying with its obligations under this Agreement and to
          ascertain the accuracy of User Data provided to Lima. The User shall reimburse Lima in
          respect of its reasonable costs incurred in carrying out of the access rights. &nbsp;
          &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>3.8 Facilities and assistance.&nbsp;</strong>The User agrees to provide Lima, at
          no cost, with such facilities and assistance, as may be required to perform Lima’s
          obligations under this Agreement including but not limited to: (a) adequate and continuous
          electricity supply to the equipment if required; (b) adequate security for Lima’s
          equipment within such parameters as Lima may notify the User. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>3.9 Acceptable Use Policy (AUP).&nbsp;</strong>The User agrees not to and not to
          allow third parties to use the Services: &nbsp; &nbsp;
        </p>
        <div style={{ paddingLeft: '2rem' }}>
          <ul style={{ listStyleType: 'disc' }}>
            <li style={{ textAlign: 'justify' }}>
              To violate, or encourage the violation of, the legal rights of others (for example,
              this may include but is not limited to allowing End Users to infringe or
              misappropriate the intellectual property rights); &nbsp; &nbsp;
            </li>
            <li style={{ textAlign: 'justify' }}>
              to engage in, promote or encourage illegal activity; &nbsp; &nbsp;
            </li>
          </ul>
        </div>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>&nbsp;3.10 Data Input:&nbsp;</strong>The User will provide Lima, on a daily basis,
          User Data required by Lima to ensure it can provide its services to a reasonable standard
          that Lima may specify from time to time and shall ensure that all such User Data is
          accurate, complete and not misleading. &nbsp; &nbsp;
        </p>
        <br />
        <ol style={{ listStyleType: 'decimal' }} start={4}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>Suspension.</h2>
          </li>
        </ol>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>4.1 AUP Violations.&nbsp;</strong>If Lima becomes aware that the User’s or any End
          User’s use of the Services violates the AUP, Lima will give the User notice of the
          violation by requesting that the User correct the violation. If the User fails to correct
          the violation within 24 hours of Lima’s request, then Lima may Suspend all or part of the
          User&apos;s use of the Services. Lima will resume the Services to such User at its sole
          discretion.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>&nbsp;4.2 Other Suspension.&nbsp;</strong>Notwithstanding Section 4.1 (AUP
          Violations) Lima may immediately suspend all or part of User&apos;s use of the Services
          if: (a) Lima believes User&apos;s or any End User&apos;s use of the Services could
          adversely impact the Services, other Users&apos; or their end users&apos; use of the
          Services, or the Lima and agent network or servers used to provide the Services; (b) there
          is suspected unauthorized third-party access to the Services; (c) Lima believes it is
          required to suspend immediately to comply with applicable law; or (d) User is in breach of
          Section 3.3 (Restrictions). Lima will at its sole discretion lift any such Suspension
          when, in Lima’s opinion, the circumstances giving rise to the Suspension have been
          resolved. At the User’s request, unless prohibited by applicable law, Lima will notify the
          User of the basis for the Suspension as soon as is reasonably possible.
        </p>

        <br />

        <ol style={{ listStyleType: 'decimal' }} start={5}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 ref={agreementRef5} style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>
              Intellectual Property Rights and Use of User Data.
            </h2>
          </li>
        </ol>

        <br />

        <p style={{ textAlign: 'justify' }}>
          <strong>5.1 Intellectual Property Rights.&nbsp;</strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          This Agreement does not grant either party any rights, implied or otherwise, to the other
          party&apos;s content or any of the other party&apos;s intellectual property. Lima owns all
          Intellectual Property Rights in the Services and Platform. The User shall not acquire any
          rights in Lima’s Intellectual Property. &nbsp; &nbsp;
        </p>
        <p style={{ textAlign: 'justify' }}>
          Lima grants the User a non-exclusive, non-transferable, limited license to use the
          Platform as provided by Lima as a part of the Services and in accordance with this
          Agreement; and the User shall not do anything contrary to the provisions of Section
          3.3.&nbsp;
        </p>
        <p style={{ textAlign: 'justify' }}>
          The use of the Platform or any part of the Services, except for use of the Services as
          permitted in this Agreement, is strictly prohibited and infringes on the Intellectual
          Property Rights of Lima and may subject the User to civil and criminal penalties,
          including possible monetary damages, for intellectual property right infringement.
        </p>

        <br />

        <p style={{ textAlign: 'justify' }}>
          <strong>5.2 Use of User Data.&nbsp;</strong>The<strong>&nbsp;</strong>User grants Lima the
          right to collect, compile, process, export, use and store the User Data (including any
          Intellectual Property Rights, Personal Information or Confidential Information contained
          therein) in order to provide and enhance the Services generally. The User acknowledges and
          agrees that Lima and its Affiliates may also use such User Data for their business
          purposes including but not limited to support, including the development and enhancement
          of derivative services, machine learning, statistical analysis, billing and reporting,
          provided however that Lima may not use the User Data for any purpose other than to provide
          the Services and the Platform, unless the User Data, to the extent that it is bundled with
          Personal Data, is anonymized such that the identity of Client, the name, block, area and
          location of the User’s farm cannot be ascertained (such data being “
          <strong>Obfuscated Data</strong>”). All right, title and interest in Obfuscated Data and
          Lima’s products, including any products derived from or enhanced with Obfuscated Data,
          shall at all times be and shall remain vested with Lima. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>5.3 User Feedback.&nbsp;</strong>If User provides Lima with feedback about the
          Services, then Lima may use that information without deference or obligation to the User.
        </p>

        <br />

        <ol style={{ listStyleType: 'decimal' }} start={6}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>
              Technical Support Services (TSS).
            </h2>
          </li>
        </ol>

        <br />

        <p style={{ textAlign: 'justify' }}>
          <strong>6.1 By User.&nbsp;</strong>The User is responsible for technical support of its
          processes, Datasets, or any other Services. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>6.2 By Lima.&nbsp;</strong>Subject to payment of applicable TSS Fees, Lima will
          provide TSS to the User during the Term in accordance with the Payment Terms and&nbsp;
          applicable guidelines.
        </p>

        <br />

        <ol style={{ listStyleType: 'decimal' }} start={7}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>Deprecation of Services.</h2>
          </li>
        </ol>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>7.1 Discontinuance of Services.&nbsp;</strong>Subject to Section 7.2, Lima may
          discontinue any Services or any portion or feature for any reason at any time without
          liability to the User.
        </p>

        <br />

        <p style={{ textAlign: 'justify' }}>
          <strong>7.2 Deprecation Policy.&nbsp;</strong>Lima will announce on the Platform in
          writing if it intends to discontinue or make backwards incompatible changes to the
          Services specified in the Documentation. Lima will use commercially reasonable efforts to
          continue to operate those Services versions and features for 30 days, unless (as Lima
          determines in its reasonable good faith judgment):
        </p>
        <p style={{ textAlign: 'justify' }}>
          (i) required by law or third party relationship (including if there is a change in
          applicable law or relationship), or
        </p>
        <p style={{ textAlign: 'justify' }}>
          (ii) doing so could create a security risk or substantial commercial, economic or material
          technical burden. &nbsp; &nbsp;
        </p>
        <p style={{ textAlign: 'justify' }}>
          The above policy is the &quot;Deprecation Policy.&quot; &nbsp; &nbsp;
        </p>
        <br />
        <ol style={{ listStyleType: 'decimal' }} start={8}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 ref={agreementRef8} style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>
              Confidential Information.
            </h2>
          </li>
        </ol>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>8.1 Obligations.&nbsp;</strong>The recipient will not disclose the Confidential
          Information, except to Affiliates, employees, agents or professional advisors who need to
          know it and who have agreed in writing (or in the case of professional advisors who are
          otherwise bound) to keep it confidential. The recipient will ensure that those people and
          entities use the received Confidential Information only to exercise rights and fulfil
          obligations under this Agreement, while using reasonable care to keep it confidential.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>&nbsp;8.2 Required Disclosure.&nbsp;</strong>Notwithstanding any provision to the
          contrary in this Agreement, the recipient may also disclose Confidential Information to
          the extent required by applicable Legal Process; provided that the recipient uses
          commercially reasonable efforts to: (i) promptly notify the other party of such disclosure
          before disclosing; and (ii) comply with the other party&apos;s reasonable requests
          regarding its efforts to oppose the disclosure. Notwithstanding the foregoing, subsections
          (i) and (ii) above will not apply if the recipient determines that complying with (i) and
          (ii) could: (a) result in a violation of Legal Process; (b) obstruct a governmental
          investigation; and/or (c) lead to death or serious physical harm to an individual. As
          between the parties, the User is responsible for responding to all third party requests
          concerning and is liable for its use and End Users&apos; use of the Services.
        </p>

        <br />

        <ol style={{ listStyleType: 'decimal' }} start={9}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>Term and Termination.</h2>
          </li>
        </ol>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>9.1 Agreement Term.&nbsp;</strong>The &quot;Term&quot; of this Agreement will
          begin on the Effective Date and continue until the Agreement is terminated as set forth in
          Section 9 of this Agreement.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>9.2 Termination for Breach.&nbsp;</strong>in addition to Lima’s rights to
          terminate as set out in this Agreement, either party may terminate this Agreement for
          breach if: (i) the other party is in material breach of the Agreement and fails to cure
          that breach within thirty days after receipt of written notice; (ii) the other party
          ceases its business operations or becomes subject to insolvency proceedings and the
          proceedings are not dismissed within ninety days; or (iii) the other party is in material
          breach of this Agreement more than two times notwithstanding any cure of such breaches.
          &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>9.3 Termination for Inactivity.&nbsp;</strong>Lima reserves the right to terminate
          the provision of the Service(s) upon 30 days advance notice if, for a period of 60 days
          (i) User and any Account in the User’s Team has not accessed the Platform or an
          application has had no network activity; and (ii) such application has not incurred any
          Fees for such Service(s).
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>9.4 Termination for Convenience.&nbsp;</strong>Either Party may terminate this
          Agreement for its convenience by providing 90 days’ advance notice in writing. &nbsp;
          &nbsp;
        </p>
        <br />
        <p ref={agreementRef9_5} style={{ textAlign: 'justify' }}>
          <strong>9.5 Effect of Termination.&nbsp;</strong>If the Agreement is terminated, then: (i)
          the rights granted by one party to the other will immediately cease; (ii) all Fees owed by
          the User to Lima are immediately due upon receipt of the final invoice and clauses 2.3,
          2.4 and 2.5 shall apply in relation to all Fees; (iii) the User will delete the Platform,
          any Model, Datasets, and any User Data; and (iv) upon request, each party will use
          commercially reasonable efforts to return to the other party or destroy all Confidential
          Information of the other party.
        </p>
        <br />
        <ol style={{ listStyleType: 'decimal' }} start={10}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>Publicity.</h2>
          </li>
        </ol>
        <br />
        <p style={{ textAlign: 'justify' }}>
          If the User wants to display Lima’s Brand Features in connection with its use of the
          Services, the User must obtain written permission from Lima. Lima may include the
          User&apos;s name or Brand Features in a list of Lima’s Users, online or in promotional
          materials unless agreed otherwise in writing. Lima may also verbally reference the User as
          a User of the Services. Neither party needs approval if it is repeating a public statement
          that is substantially similar to a previously-approved public statement. Any use of a
          party&apos;s Brand Features will inure to the benefit of the party holding intellectual
          property rights to those Brand Features. A party may revoke the other party&apos;s right
          to use its Brand Features under this Section with written notice to the other party and a
          reasonable period to stop the use provided that Lima reserves the right to immediately
          terminate this Agreement and the provision of the Services to the User in the event that
          the User revokes any right to us any of the User’s Brand Features.
        </p>

        <br />

        <ol style={{ listStyleType: 'decimal' }} start={11}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>
              Representations and Warranties.
            </h2>
          </li>
        </ol>
        <br />
        <p style={{ textAlign: 'justify' }}>
          &nbsp;Each party represents and warrants that: (a) it has full power and authority to
          enter into the Agreement; and (b) it will comply with all laws and regulations applicable
          to its provision, or use, of the Services, as applicable.
        </p>
        <br />
        <ol style={{ listStyleType: 'decimal' }} start={12}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>Disclaimer.</h2>
          </li>
        </ol>
        <br />
        <p style={{ textAlign: 'justify' }}>
          The Services are provided “as is.” Except as expressly provided for in this agreement,
          Lima and its suppliers do not make any other warranty of any kind, whether express,
          implied, statutory or otherwise, including warranties of merchantability, fitness for a
          particular use and non-infringement. Lima and its suppliers are not responsible or liable
          for the deletion of or failure to store any User Data and other communications maintained
          or transmitted through use of the Services. The User is solely responsible for securing
          and backing up its applications, models, annotations, and User Data. Neither Lima nor its
          suppliers, warrants that the operation of the software or the Services will be error-free
          or uninterrupted. Neither the software nor the Services are designed, manufactured, or
          intended for high risk activities. &nbsp; &nbsp;
        </p>
        <br />
        <ol style={{ listStyleType: 'decimal' }} start={13}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 ref={agreementRef13} style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>
              Limitation of Liability.
            </h2>
          </li>
        </ol>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>13.1 Limitation on Indirect Liability.&nbsp;</strong>To the maximum extent
          permitted by applicable law, neither Lima, nor Lima’s suppliers, will be liable under this
          Agreement for lost revenues or indirect, special, incidental, consequential, exemplary, or
          punitive damages (including damages for loss of profits, revenues, users, opportunities,
          goodwill, use, or data), even if the User has been advised or should have known that such
          damages were possible and even if direct damages do not satisfy a remedy.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>13.2 Limitation on Amount of Liability.&nbsp;</strong>To the maximum extent
          permitted by applicable law, neither Lima, nor Lima’s suppliers, may be held liable under
          this Agreement for more than the amount paid by the User to Lima under this Agreement
          during the six months prior to the event giving rise to liability.
        </p>

        <br />

        <p style={{ textAlign: 'justify' }}>
          13.3 Lima assumes no responsibility, and shall not be liable for, any damages to, or
          viruses that may infect your computer equipment or other property on account of your
          access to, use of, or browsing the website or your downloading of any materials, data,
          text, images, video, or audio from the Platform.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          13.4 &nbsp; &nbsp;Lima accepts no responsibility or liability whatsoever for any
          interruption or discontinuance of any or all functionality of this Platform, or if this
          website does not operate quickly and efficiently, whether the result of actions or
          omission of Lima, or a third party or otherwise.
        </p>

        <br />

        <ol style={{ listStyleType: 'decimal' }} start={14}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 ref={agreementRef14} style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>
              Indemnification.
            </h2>
          </li>
        </ol>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>14.1 By User.&nbsp;</strong>Unless prohibited by applicable law, the User will
          defend and indemnify Lima and its Affiliates against any loss or damage which Lima may
          suffer or incur as a result of the User&apos;s breach of this Agreement including loss or
          damage arising from claims in relation to: (i) any Application, Model, Annotation-related
          Service, User Data or User Brand Features; or (ii) User&apos;s, or End Users&apos;, use of
          the Services in violation of the AUP.
        </p>

        <br />

        <ol style={{ listStyleType: 'decimal' }} start={15}>
          <li style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '1.813rem' }}>
            <h2 ref={agreementRef15} style={{ fontWeight: 'bold', fontSize: '1.813rem' }}>
              Miscellaneous.
            </h2>
          </li>
        </ol>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>15.1 Notices.&nbsp;</strong>All notices must be in writing and addressed to the
          other party&apos;s primary point of contact. The email address for notices being sent to
          Lima is{' '}
          <a href="mailto:support@lima.ag">
            <span style={{ color: '#0b4cb4' }}>support@lima.ag</span>
          </a>
          . Notice will be treated as given on receipt as verified by written or automated receipt
          or by electronic log (as applicable).
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>15.2 Assignment.&nbsp;</strong>The User may not assign any part of this Agreement
          without the written consent of Lima which consent shall be at the sole discretion of Lima.
          Lima may, at its discretion, give its consent to the assignment of any part of this
          Agreement,&nbsp;&nbsp;to an Affiliate of the User where: (a) the assignee has agreed in
          writing to be bound by the terms of this Agreement; (b) the assigning party remains liable
          for obligations under the Agreement if the assignee defaults on them; and (c) the
          assigning party has notified the other party of the assignment. Any other attempt to
          assign is void.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>15.3 Force Majeure.&nbsp;</strong>Neither party will be liable for failure or
          delay in performance (excluding performance relating to payment of fees under this
          Agreement) to the extent caused by circumstances beyond its reasonable control provided
          that Lima may at its discretion terminate this Agreement in writing with immediate effect
          if the force majeure event persists for more that 6 months. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>15.4 No Agency.&nbsp;</strong>This Agreement does not create any agency,
          partnership or joint venture between the parties. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>15.5 No Waiver.&nbsp;</strong>Neither party will be treated as having waived any
          rights by not exercising (or delaying the exercise of) any rights under this Agreement.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>15.6 Severability.&nbsp;</strong>If any term (or part of a term) of this Agreement
          is or becomes invalid, illegal, or unenforceable, it shall be deemed modified to the
          minimum extent necessary to make it valid, legal and enforceable. If such modification is
          not possible, the relevant provision or part-provision shall be deemed deleted. Any
          modification to or deletion of a provision or part-provision under this Section shall not
          affect the validity and enforceability of the rest of this Agreement. &nbsp; &nbsp;
        </p>
        <p style={{ textAlign: 'justify' }}>
          If any provision or part-provision of this Agreement is invalid, illegal or unenforceable,
          the parties shall negotiate in good faith to amend such provision so that, as amended, it
          is legal, valid and enforceable, and, to the greatest extent possible, achieves the
          intended commercial result of the original provision. &nbsp; &nbsp;
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>15.7 No Third-Party Beneficiaries.&nbsp;</strong>This Agreement does not confer
          any benefits on
        </p>
        <p style={{ textAlign: 'justify' }}>
          &nbsp;any third party unless it expressly states that it does.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>15.8 Equitable Relief.&nbsp;</strong>Nothing in this Agreement will limit either
          party&apos;s ability to seek equitable relief.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>15.9 Governing Law.&nbsp;</strong>This Agreement shall be governed by and
          construed in accordance with Kenyan law. In the case of a dispute arising from this
          Agreement, the parties shall endeavour to resolve such dispute by negotiation in good
          faith. If the matter remains unresolved after negotiation, the courts of Kenya shall have
          exclusive jurisdiction to adjudicate any dispute arising under or in connection with this
          Agreement.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>15.10 Amendments.&nbsp;</strong>Except as set forth in Section 1.7, any amendment
          must be in writing, signed by both parties, and expressly state that it is amending this
          Agreement.
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>15.11 Survival.&nbsp;</strong>The following Sections will survive expiration or
          termination of this
        </p>
        <p style={{ textAlign: 'justify' }}>
          &nbsp;Agreement:{' '}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              scrollToAgreement(5);
            }}
          >
            5
          </span>
          ,{' '}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              scrollToAgreement(8);
            }}
          >
            8
          </span>
          ,{' '}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              scrollToAgreement(9.5);
            }}
          >
            9.5
          </span>
          ,{' '}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              scrollToAgreement(13);
            }}
          >
            13
          </span>
          ,{' '}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              scrollToAgreement(14);
            }}
          >
            14
          </span>
          , and{' '}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              scrollToAgreement(15);
            }}
          >
            15
          </span>
          .
        </p>
        <br />
        <p style={{ textAlign: 'justify' }}>
          <strong>15.12 Entire Agreement.&nbsp;</strong>This Agreement sets out all terms agreed
          between the parties and supersedes all other agreements between the parties relating to
          its subject matter. In entering into this Agreement, neither party has relied on, and
          neither party will have any right or remedy based on, any statement, representation or
          warranty (whether made negligently or innocently), except those expressly set out in this
          Agreement.
        </p>

        <br />
      </div>
    </Box>
  );
};

export default TermsAndConditions;
