import React from 'react';
import { Box, Icon, Text, Flex } from '@chakra-ui/react';
import { AlertTriangle, CheckCircle, Icon as ReactFeatherIcon, Slash, Info } from 'react-feather';

export type NotificationToastProps = {
  type?: 'warn' | 'error' | string;
  message: string;
};

export function NotificationToast(props: NotificationToastProps): JSX.Element {
  const getIcon = (): ReactFeatherIcon => {
    switch (props.type) {
      case 'warn':
        return AlertTriangle;
      case 'error':
        return Slash;
      case 'info':
        return Info;
      default:
        return CheckCircle;
    }
  };

  const getTextColor = (): string => {
    switch (props.type) {
      case 'warn':
        return 'dark.coolGray.500';
      case 'error':
        return 'error.500';
      default:
        return 'dark.coolGray.500';
    }
  };

  return (
    <Box
      mb="2"
      fontSize="0.9rem"
      fontWeight="normal"
      backgroundColor={props.type + '.100'}
      border="0.8px solid"
      borderColor={props.type + '.500'}
      borderRadius="4px"
      padding="8px"
    >
      <Flex alignContent={'center'}>
        <Box alignSelf={'center'}>
          <Icon
            as={getIcon()}
            stroke={props.type + '.500'}
            w={5}
            h={5}
            mr={'1rem'}
            alignSelf={'center'}
            display={'block'}
          />
        </Box>
        <Box alignSelf={'center'}>
          <Text
            fontSize="16px"
            lineHeight={'24px'}
            fontWeight={400}
            color={getTextColor()}
            textAlign="left"
            alignSelf={'center'}
            dangerouslySetInnerHTML={{ __html: props.message }}
          ></Text>
        </Box>
      </Flex>
    </Box>
  );
}
