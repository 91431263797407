import React, { useState, useEffect } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { GraphKeys } from 'Components';
import { useGraphContext } from 'Context/GraphContext';
import { HarvestByFieldProps } from 'data/GraphRepositoryImpl';
import { separateComma } from 'Helpers/helpers';
import { Circle } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { nivoTheme } from './../../FarmProductionBarChart';
import { Bar } from '@nivo/bar';
import CombinedBarAndLineChart from './../../CombinedBarAndLineChart';
import HarvestByFieldTooltip from './../../HarvestByField/HarvestByFieldTooltip';
import HarvestByFieldHeader from './HarvestByFieldHeader';

function HarvestByFieldChart(props: HarvestByFieldProps) {
  const { data, fields, tags } = props;
  const { reportFilterState, totalsDuration, loadingPrevHarvestByField } = useGraphContext();
  const today = new Date().toISOString().split('T')[0];
  const getSubLabel = () => {
    if (reportFilterState?.days?.length && totalsDuration == 'daily') {
      return `\u00A0 Days | ${reportFilterState?.days[0]} to ${
        reportFilterState?.days[reportFilterState?.days?.length - 1]
      }`;
    } else if (reportFilterState?.weeks?.length && totalsDuration == 'weekly') {
      return `\u00A0 Weeks | ${reportFilterState?.weeks[0]} to ${
        reportFilterState?.weeks[reportFilterState?.weeks?.length - 1]
      }`;
    }
    return '';
  };
  const [displayData, setDisplayData] = useState<any[]>(
    data?.formattedData?.filter((el: any) => el.max) ?? []
  );
  const formatLeftAxisValue = (value: number): string => {
    return separateComma(value);
  };
  const getDurationKeys = (): string[] => {
    return tags?.withData?.map((tag: any) => tag.id) ?? [];
  };
  const getDurationColors = (): string[] => {
    return tags?.withData?.map((tag: any) => tag.fill) ?? [];
  };
  useEffect(() => {
    setDisplayData(data?.formattedData?.filter((el: any) => el.selectedTotal) ?? []);
  }, [data]);

  return (
    <Box width="100%">
      <HarvestByFieldHeader
        fields={fields ?? []}
        subLabel={getSubLabel()}
        multiSelectName="harvest-chart"
        noDataFields={tags?.withoutData}
      />
      {loadingPrevHarvestByField ? (
        <Skeleton height={200} style={{ marginTop: '6px' }} />
      ) : (
        <>
          {data?.maxPred && !!displayData?.length ? (
            <>
              <Box mt="2rem">
                <GraphKeys
                  showTooltip={false}
                  onClick={() => {}}
                  graphKeys={tags?.withData ?? []}
                  leftOffset={'100'}
                  icon={Circle}
                  clickable={false}
                ></GraphKeys>
              </Box>
              <Box style={{ display: 'flex', textAlign: 'center' }}>
                <Bar
                  theme={nivoTheme}
                  height={700}
                  width={100}
                  data={displayData}
                  layers={['axes']}
                  keys={getDurationKeys()}
                  margin={{ top: 10, right: 0, bottom: 60, left: 100 }}
                  maxValue={data?.maxPred * 1.1}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Stem count in thousands',
                    legendPosition: 'middle',
                    legendOffset: -80,
                    format: (value: number | undefined) =>
                      formatLeftAxisValue((value ? value / 1000 : 0) ?? 0)
                  }}
                  axisBottom={null}
                  fill={[
                    {
                      match: {
                        id: 'actual_stems'
                      },
                      id: 'dots'
                    }
                  ]}
                />
                <Box
                  style={{
                    height: 700,
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                    width: '100%'
                  }}
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                      height: '6px'
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '6px',
                      height: '12px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#D5D5D5',
                      borderRadius: '4px'
                    }
                  }}
                >
                  <CombinedBarAndLineChart
                    duration={totalsDuration}
                    data={displayData}
                    yMax={data?.maxPred * 1.1}
                    selectedYear={'2023'}
                    lineData={[]}
                    durationKeys={getDurationKeys()}
                    durationColors={getDurationColors()}
                    groupMode="stacked"
                    lineKey=""
                    tooltip={HarvestByFieldTooltip}
                    indexBy="period"
                  />
                </Box>
              </Box>
              {totalsDuration === 'daily' && (
                <Center>
                  <Text fontSize={20} color={'rgb(0, 0, 0)'}>
                    {`${reportFilterState?.years ?? today.split('-')[0]} ${getSubLabel()}` || (
                      <Skeleton count={1} />
                    )}
                  </Text>
                </Center>
              )}
            </>
          ) : (
            <Text mt="1.65rem" textAlign={'left'}>
              No graph data available for the selected filter parameters.
            </Text>
          )}
        </>
      )}
    </Box>
  );
}

export default HarvestByFieldChart;
