import React, { useEffect, useState } from 'react';
import { FilterDatum, GraphPlotData } from 'Context/GraphContext';
import { useGraphContext } from 'Context/GraphContext';
import { Text, Box, Grid, GridItem, Icon, Flex } from '@chakra-ui/react';
import { ResponsivePie } from '@nivo/pie';
import { Circle } from 'react-feather';
import { separateComma } from 'Helpers/helpers';
import { GraphKeys } from 'Components';

function FieldProductivityPie(props: { field: any; parentName: string }) {
  const { field, parentName } = props;
  const { fieldProductivity, fieldProductivityTags, primaryProductivityTags } = useGraphContext();
  const [pieData, setPieData] = useState<GraphPlotData[] | null>(null);
  const [pieColors, setPieColors] = useState<string[] | null>(null);
  const theme: any = {
    labels: {
      text: {
        fontSize: '1rem',
        fontFamily: 'Be Vietnam Pro',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%' /* 27px */,
        letterSpacing: '-0.9px',
        color: '#464F51'
      }
    },
    legends: {
      text: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '0.8rem',
        fontStyle: 'normal',
        letterSpacing: '1rem',
        color: '#52606D'
      }
    }
  };
  const primaryTagsActive = () => {
    return fieldProductivityTags?.some(
      (tag: FilterDatum) => primaryProductivityTags.includes(tag.id) && tag.active
    );
  };
  const noData = [
    {
      id: '1',
      label: '',
      value: 70
    },
    {
      id: '2',
      label: '',
      value: 60
    },
    {
      id: '3',
      label: '',
      value: 40
    },
    {
      id: '4',
      label: '',
      value: 30
    },
    {
      id: '5',
      label: '',
      value: 50
    }
  ];
  const styles: any = {
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 96,
      color: '#475052',
      textAlign: 'center',
      width: '100%',
      pointerEvents: 'none',
      zIndex: 1
    }
  };
  const isActive = (id: string) => {
    return !!fieldProductivityTags?.find(
      (tag: FilterDatum) => tag.active && tag.id === id && id !== 'total_harvest'
    );
  };
  const getIconColor = (id: string) => {
    return fieldProductivityTags?.find((tag: FilterDatum) => tag.id === id)?.fill ?? '';
  };
  const getValue = (id: string) => {
    return pieData?.find((el: any) => el.id === id)?.value ?? 0;
  };
  useEffect(() => {
    setPieData(null); //used to cause graph to rerender
    setPieColors([]); //used to cause graph to rerender
    const active = fieldProductivityTags?.filter(
      (tag: any) => tag.active && tag.id !== 'total_harvest'
    );
    const newData: any[] = [];
    const newColors: string[] = [];
    active?.forEach((tag: any) => {
      if (field?.[tag?.id]) {
        const val = {
          id: tag?.id,
          label: tag?.label,
          value: field?.[tag?.id] ?? 0
        };
        newData.push(val);
        newColors.push(tag.fill);
      }
    });
    const initializeTimeout = setTimeout(() => {
      setPieColors(newColors ?? null);
      setPieData(newData ?? null);
    }, 10);
    return () => {
      clearTimeout(initializeTimeout);
    };
  }, [fieldProductivityTags, field]);
  return (
    <>
      {!!fieldProductivity?.maxPred && primaryTagsActive() && (
        <>
          {field ? (
            <>
              {pieData ? (
                <>
                  <Flex justifyContent={'center'}>
                    <GraphKeys
                      showTooltip={false}
                      onClick={() => {}}
                      graphKeys={fieldProductivityTags?.filter(
                        (el: any) => el.id !== 'total_harvest'
                      )}
                      icon={Circle}
                      clickable={false}
                    ></GraphKeys>
                  </Flex>
                  <Box width="700px" height="400px" marginLeft={'auto'} marginRight={'auto'}>
                    <ResponsivePie
                      data={pieData}
                      margin={{ top: 35, left: 50, right: 50, bottom: 30 }}
                      borderWidth={0.5}
                      borderColor={{
                        from: 'color',
                        modifiers: [['darker', 0.2]]
                      }}
                      arcLabel={(arc) => {
                        return `${arc.value.toLocaleString()}`;
                      }}
                      arcLinkLabel={(arc) => {
                        return `${arc.value.toLocaleString()}`;
                      }}
                      arcLinkLabelsColor={{ from: 'color' }}
                      enableArcLinkLabels={true}
                      enableArcLabels={false}
                      arcLinkLabelsSkipAngle={20}
                      isInteractive={true}
                      activeOuterRadiusOffset={0}
                      animate={false}
                      theme={theme}
                      colors={pieColors ?? []}
                      sortByValue
                      valueFormat={(value) => {
                        return value.toLocaleString();
                      }}
                      tooltip={() => (
                        <Box
                          style={{
                            padding: 12,
                            background: '#ffffff',
                            boxShadow: '0px 1px 6px rgba(12, 12, 13, 0.15)',
                            textAlign: 'center',
                            borderRadius: '4px',
                            border: `2px solid`,
                            borderColor: '',
                            fontSize: '16px'
                          }}
                        >
                          <Text
                            as="p"
                            textAlign={'center'}
                            color={'dark.coolGray.500'}
                            style={{
                              marginBottom: '0.75rem'
                            }}
                            className={'readex-font'}
                          >
                            Field {field?.period}
                          </Text>
                          <Grid templateColumns="repeat(1, 1fr)" gap={0} alignItems="left">
                            {fieldProductivityTags?.map((tag, ind: number) => (
                              <Box key={ind}>
                                {isActive(tag.id) && (
                                  <GridItem key={ind} p={0.5}>
                                    <Text
                                      as="p"
                                      color={'dark.coolGray.500'}
                                      textAlign={'left'}
                                      display="flex"
                                    >
                                      <Icon
                                        as={Circle}
                                        fill={getIconColor(tag.id)}
                                        color="transparent"
                                        width={'16px'}
                                        height={'16px'}
                                        alignSelf={'center'}
                                        my="auto"
                                        justifySelf={'center'}
                                        mr="0.25rem"
                                      />
                                      {tag.label + ':'}&nbsp;
                                      <Text as="span" color={'dark.coolGray.600'}>
                                        {separateComma(getValue(tag.id) ?? 0)}
                                      </Text>
                                    </Text>
                                  </GridItem>
                                )}
                              </Box>
                            ))}
                          </Grid>
                        </Box>
                      )}
                    />
                  </Box>
                  <Text textAlign={'center'} mt="1.65rem" fontWeight={'400'} fontSize="19px">
                    Field {field?.period} detailed view
                  </Text>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Box
              width="700px"
              height="400px"
              marginLeft={'auto'}
              marginRight={'auto'}
              position="relative"
            >
              <ResponsivePie
                data={noData}
                margin={{ top: 0, left: 20, right: 20 }}
                borderWidth={0}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]]
                }}
                enableArcLinkLabels={false}
                enableArcLabels={false}
                isInteractive={false}
                activeOuterRadiusOffset={0}
                animate={false}
                theme={theme}
                colors={['#FDF1F1', '#E5F6FF', '#F7F2FF', '#FFF5EB', '#EBFCF4']}
                sortByValue
              />
              <Box style={styles.overlay}>
                <Box width="60%">
                  <Text size={'1.8rem'} color={'green.500'} fontWeight={600} textAlign={'center'}>
                    No selection
                  </Text>
                  <Text textAlign={'center'} mt="1rem" fontWeight={500}>
                    Click on {parentName === 'table' ? `Show Details button` : `a given bar`} to
                    view field grade split
                  </Text>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default FieldProductivityPie;
