import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email address format!').required('Email is required.'),
  full_name: yup
    .string()
    .required('Name is required.')
    .matches(
      /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.']+$/,
      'Please enter valid name without special characters'
    )
    .max(40),
  password: yup
    .string()
    .required('Password is required.')
    .min(8, 'Password must be at least 8 characters')
    .minLowercase(1, 'Password must contain at least 1 lower case letter')
    .minUppercase(1, 'Password must contain at least 1 upper case letter')
    .minNumbers(1, 'Password must contain at least 1 number')
    .minSymbols(1, 'Password must contain at least 1 special character'),
  confirm_password: yup
    .string()
    .required('Password confirmation is required.')
    .test('passwords-match', 'Confirmation password must match password', function (value) {
      return this.parent.password === value;
    }),
  agreeToTerms: yup.boolean().required().is([true], 'You must agree to the Terms & Conditions'),
  code: yup.string().required('Code is required.')
});

export default schema;
