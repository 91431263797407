import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  FormControl,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  Img,
  Spinner,
  Text,
  Link,
  InputRightElement
} from '@chakra-ui/react';
import { Field, Formik, FormikProps } from 'formik';
import { Mail, Lock, Eye, EyeOff } from 'react-feather';
import { InputWithIcon, Button, Checkbox, FormLabel } from 'Components';
import { colors } from 'theme';

import { useAuthContext } from 'Context';
import Logo from 'assets/images/lima-icon.svg';
import { LoginSchema } from 'Validations';
import { LoginFields } from 'Context/types';
import { NotificationToast } from 'Components/Cards';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import useDocumentTitle from 'Helpers/useDocumentTitle';

export default function Login(): JSX.Element {
  const { trackPageView } = useMatomo();
  useDocumentTitle('Login | Lima Asili');

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues: LoginFields = {
    email: '',
    password: '',
    rememberMe: false
  };
  const [isVisible, setVisible] = useState(false);
  const {
    loadingStates,
    variables: { hasError, responseInfo },
    functions: { login, setHasError }
  } = useAuthContext();
  const { isLoading } = loadingStates;

  const handleSubmit = async (value: LoginFields) => {
    await login(value);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (hasError) {
      timeout = setTimeout(() => {
        setHasError(false);
      }, 5000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hasError, setHasError]);

  const onPasswordInput = (type: any, touched: any) => {
    if (type === '' && !touched.password) setVisible(false);
  };

  return (
    <Flex
      className="login"
      direction="column"
      height="100%"
      justifyContent="center"
      alignItems="center"
      mx="auto"
    >
      <Box width="400px">
        <Flex
          className="login__logo"
          width="80px"
          height="80px"
          justifyContent="center"
          alignItems="center"
          mb={6}
        >
          <Img src={Logo} />
        </Flex>
        <Heading
          as="h1"
          fontSize="4xl"
          fontWeight={400}
          color="black.500"
          mb={16}
          fontFamily="Readex Pro"
        >
          Welcome back
        </Heading>
        <Heading as="h1" fontSize="3xl" color="primary.500" fontWeight={400} mt={14} mb="10">
          Login
        </Heading>
        {hasError && <NotificationToast type="error" message={responseInfo} />}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={LoginSchema}
          validateOnBlur={true}
        >
          {({ isValid, handleSubmit, errors, touched, dirty }: FormikProps<LoginFields>) => (
            <form className="login__form form" onSubmit={handleSubmit}>
              <FormControl mb={6} isInvalid={!!errors.email && touched.email}>
                <FormLabel htmlFor="email">Email address</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Mail color={colors.input.placeholder} strokeWidth={1.5} />
                  </InputLeftElement>
                  <Field
                    as={InputWithIcon}
                    id="email"
                    type="email"
                    name="email"
                    autoComplete="true"
                    placeholder="user@email.com"
                  />
                </InputGroup>
                {errors && errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
              </FormControl>
              <FormControl mb={6} isInvalid={!!errors.password && touched.password}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Lock color={colors.input.placeholder} strokeWidth={1.5} />
                  </InputLeftElement>
                  <Field
                    as={InputWithIcon}
                    id="password"
                    name="password"
                    type={!isVisible ? 'password' : 'text'}
                    placeholder="At least 8 characters"
                    onInput={() => onPasswordInput('', touched)}
                  />
                  <InputRightElement onClick={() => setVisible(!isVisible)}>
                    {isVisible ? (
                      <Eye color={colors.input.placeholder} strokeWidth={1.5} />
                    ) : (
                      <EyeOff color={colors.input.placeholder} strokeWidth={1.5} />
                    )}
                  </InputRightElement>
                </InputGroup>
                {errors && errors.password && (
                  <FormErrorMessage>{errors.password}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mb={6} isInvalid={!!errors.rememberMe && touched.rememberMe}>
                <Field
                  as={Checkbox}
                  colorScheme="primary"
                  outline="none"
                  shadow="0"
                  name="rememberMe"
                  className="where-be-me"
                  _checked={{
                    boxShadow: 'none',
                    svg: {
                      stroke: 'white !important'
                    }
                  }}
                >
                  Remember me
                </Field>
                {errors && errors.rememberMe && (
                  <FormErrorMessage>{errors.rememberMe}</FormErrorMessage>
                )}
              </FormControl>
              <Box>
                <Button
                  width="100%"
                  theme="primary"
                  disabled={!isValid || !dirty || isLoading}
                  type="submit"
                >
                  Login
                  {isLoading && (
                    <>
                      <Box px={1.5}></Box>
                      <Spinner color="white.500" size="sm" />
                    </>
                  )}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Box pt={8}>
          {/* DO NOT DELETE , LINK WILL BE RE-ENABLE AT SOME POINT IN FUTURE */}
          {/* <Text as="p" color="dark.coolGray.400">
            Don’t have an account?{' '}
            <Link as={RouterLink} to="/register" color="primary.500" state={{ auth: '/register' }}>
              Sign up
            </Link>
          </Text> */}
          <Text as="p" mt={2} color="dark.coolGray.400">
            Forgot password?{' '}
            <Link as={RouterLink} to="/reset-password" color="primary.500">
              Reset Password
            </Link>
          </Text>
        </Box>
      </Box>
    </Flex>
  );
}
