import React, { SyntheticEvent } from 'react';
import { CustomProvider, DateRangePicker } from 'rsuite';
const { allowedRange } = DateRangePicker;
import { DateRange, DisabledDateFunction, RangeType } from 'rsuite/esm/DateRangePicker';
//import 'rsuite/dist/rsuite.min.css';
import 'rsuite/dist/rsuite-no-reset.min.css';
import './LimaDateRangePicker.scss';

export type LimaDateRangePickerProps = {
  startYearLimit?: number | undefined;
  endYearLimit?: number | undefined;
  initialValue?: DateRange | null;
  predefinedRanges?: RangeType[] | undefined;
  allowedDateRange?: Date[] | string[] | undefined;
  onChange: (values: DateRange | null, event: SyntheticEvent<Element, Event>) => void;
};

const LimaDateRangePicker = (props: LimaDateRangePickerProps) => {
  const {
    startYearLimit,
    endYearLimit,
    initialValue,
    predefinedRanges,
    allowedDateRange,
    onChange
  } = props;
  const handleOnChange = (values: DateRange | null, event: SyntheticEvent<Element, Event>) => {
    onChange(values, event);
  };
  const _allowedDateRange = (): DisabledDateFunction | undefined => {
    const startDate = allowedDateRange ? allowedDateRange[0] : null;
    const endDate = allowedDateRange && allowedDateRange.length > 0 ? allowedDateRange[1] : null;
    if (startDate && endDate && allowedRange) {
      return allowedRange(startDate, endDate);
    } else {
      return undefined;
    }
  };
  return (
    <CustomProvider theme="light">
      <DateRangePicker
        ranges={predefinedRanges}
        limitStartYear={startYearLimit ?? 2022}
        limitEndYear={endYearLimit}
        shouldDisableDate={_allowedDateRange()}
        isoWeek
        showWeekNumbers
        editable={false}
        format="dd-MMM-yyyy"
        placement="auto"
        appearance="default"
        placeholder="Select Time Range"
        character=" to "
        onChange={handleOnChange}
        value={initialValue}
      />
    </CustomProvider>
  );
};

export default LimaDateRangePicker;
