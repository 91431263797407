import { Harvest } from 'Context/types';
import { OrganizedPreds, OrganizedYields } from 'data/GraphRepositoryImpl';
import { CombinedFilters } from 'Pages/ActualData/Components/ActualInputTable';
import {
  ComputeMinimumExpectedHarvestWorker,
  FormatHarvestByFieldTagsWorker,
  SortAndOrganizeForecastedYieldsWorker,
  SortAndOrganizePredsWorker,
  SortAndOrganizeYieldsWorker
} from 'workers/workerTypes';

/**
 * @description This function filters harvests by field and date using a web worker
 * @param _actualsData
 * @param _combinedFilters
 * @returns filtered harvests
 */
function filterHarvestsWork(
  _actualsData: Harvest[],
  _combinedFilters: CombinedFilters | null
): Promise<Harvest[]> {
  return new Promise((resolve, reject) => {
    const worker = new Worker(new URL('../workers/filterharvestsWorker.ts', import.meta.url));

    worker.onmessage = (event: MessageEvent<Harvest[]>) => {
      resolve(event.data);
      worker.terminate();
    };

    worker.onerror = (error) => {
      reject(error);
      worker.terminate();
    };

    worker.postMessage({
      harvests: _actualsData,
      filters: _combinedFilters
    });
  });
}

/**
 * @description This function sorts and organizes preds using a web worker
 * @param inputData SortAndOrganizeYieldsWorker
 * @returns OrganizedYields
 */
function sortAndOrganizeYieldsWork(
  inputData: SortAndOrganizeYieldsWorker
): Promise<OrganizedYields> {
  return new Promise((resolve, reject) => {
    const worker = new Worker(
      new URL('../workers/sortAndOrganizeYieldsWorker.ts', import.meta.url)
    );

    worker.onmessage = (event: MessageEvent<OrganizedYields>) => {
      resolve(event.data);
      worker.terminate();
    };

    worker.onerror = (error) => {
      reject(error);
      worker.terminate();
    };

    worker.postMessage(inputData);
  });
}

/**
 * @description This function sorts and organizes preds using a web worker
 * @param inputData SortAndOrganizePredsWorker
 * @returns OrganizedPreds
 */
function sortAndOrganizePredsWork(inputData: SortAndOrganizePredsWorker): Promise<OrganizedPreds> {
  return new Promise((resolve, reject) => {
    const worker = new Worker(new URL('../workers/sortAndOrganizePredsWorker.ts', import.meta.url));

    worker.onmessage = (event: MessageEvent<OrganizedPreds>) => {
      resolve(event.data);
      worker.terminate();
    };

    worker.onerror = (error) => {
      reject(error);
      worker.terminate();
    };

    worker.postMessage(inputData);
  });
}

function computeMinimumExpectedHarvestWork(
  inputData: ComputeMinimumExpectedHarvestWorker
): Promise<any> {
  return new Promise((resolve, reject) => {
    const worker = new Worker(
      new URL('../workers/computeMinimumExpectedHarvestWorker.ts', import.meta.url)
    );

    worker.onmessage = (event: MessageEvent<any>) => {
      resolve(event.data);
      worker.terminate();
    };

    worker.onerror = (error) => {
      reject(error);
      worker.terminate();
    };

    worker.postMessage(inputData);
  });
}

function sortAndOrganizeForecastedYieldsWork(
  inputData: SortAndOrganizeForecastedYieldsWorker
): Promise<OrganizedYields> {
  return new Promise((resolve, reject) => {
    const worker = new Worker(
      new URL('../workers/sortAndOrganizeForecastedYieldsWorker.ts', import.meta.url)
    );

    worker.onmessage = (event: MessageEvent<OrganizedYields>) => {
      resolve(event.data);
      worker.terminate();
    };

    worker.onerror = (error) => {
      reject(error);
      worker.terminate();
    };

    worker.postMessage(inputData);
  });
}

function getListLengthWork(list: any[]): Promise<number> {
  return new Promise((resolve, reject) => {
    const worker = new Worker(new URL('../workers/computeListLength.ts', import.meta.url));

    worker.onmessage = (event: MessageEvent<number>) => {
      resolve(event.data);
      worker.terminate();
    };

    worker.onerror = (error) => {
      reject(error);
      worker.terminate();
    };

    worker.postMessage({ list });
  });
}

function formatHarvestByFieldTagsWork(inputData: FormatHarvestByFieldTagsWorker): Promise<any[]> {
  return new Promise((resolve, reject) => {
    const worker = new Worker(
      new URL('../workers/formatHarvestByFieldTagsWorker.ts', import.meta.url)
    );

    worker.onmessage = (event: MessageEvent<any[]>) => {
      resolve(event.data);
      worker.terminate();
    };

    worker.onerror = (error) => {
      reject(error);
      worker.terminate();
    };

    worker.postMessage(inputData);
  });
}

export {
  filterHarvestsWork,
  sortAndOrganizeYieldsWork,
  sortAndOrganizePredsWork,
  computeMinimumExpectedHarvestWork,
  sortAndOrganizeForecastedYieldsWork,
  getListLengthWork,
  formatHarvestByFieldTagsWork
};
