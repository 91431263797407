import React, { useEffect, useState } from 'react';
import { Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react';
import HarvestByFieldChart from './HarvestByFieldChart';
import HarvestByFieldTable from './HarvestByFieldTable';
import { FilterDatum, useGraphContext } from 'Context/GraphContext';
import { Tab } from 'Components/Navigation';
import { useAppContext } from 'Context';

function HarvestByField() {
  const { prevHarvestYields, prevActiveFields, prevHarvestTags } = useGraphContext();
  const { limaActiveModules } = useAppContext();
  const [tagsWithData, setTagsWithData] = useState<any>();
  useEffect(() => {
    const withData: FilterDatum[] =
      prevHarvestTags?.filter((el: FilterDatum) => prevHarvestYields?.fields?.includes(el.id)) ??
      [];
    const withoutData: string[] =
      prevHarvestTags
        ?.filter((el: FilterDatum) => !prevHarvestYields?.fields?.includes(el.id))
        ?.map((el: FilterDatum) => el.label) ?? [];
    setTagsWithData({ withData, withoutData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevHarvestYields?.fields]);
  return (
    <Tabs pos="relative" align="end" variant="enclosed" mt="2rem">
      <TabList borderBottom={'0px'}>
        <Tab
          _selected={{
            bg: '#00A896',
            color: 'white'
          }}
          isDisabled={!limaActiveModules.historical_harvest_by_field_chart}
        >
          {' '}
          Chart
        </Tab>
        <Tab
          _selected={{
            bg: '#00A896',
            color: 'white'
          }}
          isDisabled={!limaActiveModules.historical_harvest_by_field_table}
        >
          Table
        </Tab>
      </TabList>
      <TabPanels
        borderRadius={'4px'}
        backgroundColor="white"
        boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
      >
        <TabPanel>
          <HarvestByFieldChart
            data={prevHarvestYields}
            fields={prevActiveFields}
            tags={tagsWithData}
          ></HarvestByFieldChart>
        </TabPanel>
        <TabPanel>
          <HarvestByFieldTable
            data={prevHarvestYields}
            fields={prevActiveFields}
            tags={tagsWithData}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default HarvestByField;
