import * as yup from 'yup';

const schema = yup.object().shape({
  yield_actual: yup.number().required('Actual yield is required.'),
  packhouse_rejection: yup
    .number()
    .required('Packhouse rejection is required.')
    .min(0, 'Must be greater than or equal to 0')
    .max(100, 'Must be less than or equal to 100'),
  yield_grade_30_40: yup
    .number()
    .min(0, 'Must be greater than or equal to 0')
    .max(100, 'Must be less than or equal to 100'),
  yield_grade_41_50: yup
    .number()
    .min(0, 'Must be greater than or equal to 0')
    .max(100, 'Must be less than or equal to 100'),
  yield_grade_51_60: yup
    .number()
    .min(0, 'Must be greater than or equal to 0')
    .max(100, 'Must be less than or equal to 100'),
  yield_grade_61_70: yup
    .number()
    .min(0, 'Must be greater than or equal to 0')
    .max(100, 'Must be less than or equal to 100'),
  archive_block: yup.boolean().default(false),
  first_date_harvest_actual: yup.string().required('First harvest date is required.'),
  last_date_harvest_actual: yup.string().required('Last harvest date is required.'),
  stem_density_actual: yup
    .string()
    .required('Stem density is required.')
    .min(0, 'Must be greater than or equal to 0')
    .max(1000, 'Must be less than or equal to 1000'),
  comment: yup.string()
});

export default schema;
