import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Text,
  Flex,
  Box,
  Icon
} from '@chakra-ui/react';
import Button from 'Components/Button';
import { useAppContext, useAuthContext } from 'Context';
import { Farm, User, Variety } from 'Context/types';
import { httpClient } from 'Helpers/appDependenciesHelpers';
import useFarmsQuery from 'Helpers/useFarmsQuery';
import ProgressCheckMark, {
  OrganizationRegistrationProgress
} from 'Pages/RegisterOrganization/Components/ProgressCheckMark';
import React, { useEffect, useState } from 'react';
import { ArrowRight } from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './CompleteRegistrationModal.module.scss';
import SetUpCardStep from './SetUpCardStep';

export enum FarmOnboardSteps {
  ADD_FARMS,
  ADD_VARIETIES,
  INVITE_MEMBERS,
  ADD_FIELDS,
  DONE
}

export type CompleteRegistrationModalProps = {
  user: User;
  token: string;
};

const BASE_URL = process.env.REACT_APP_BE_URL;

const CompleteRegistrationModal = (props: CompleteRegistrationModalProps) => {
  const { user, token } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currStep, setCurrStep] = React.useState<FarmOnboardSteps>(FarmOnboardSteps.ADD_FARMS);
  const [currFarms, setCurrFarms] = React.useState<Farm[]>([]);
  const [numberOfBlocks, setNumberOfBlocks] = useState<number>(0);
  const [currVarieties, setCurrVarieties] = React.useState<Variety[]>([]);
  const [numberOfMembers, setNumberOfMembers] = useState<number>(0);
  const {
    functions: { setSkippedMembersAdd, getSkippedMembersAdd }
  } = useAuthContext();
  const { setCurrentFarmOnboardStep, setOnboardingModalActive, setOnboardingModalLoading } =
    useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [isFetchingFarms, setIsFetchingFarms] = React.useState<boolean>(true);
  const [isFetchingMembers, setIsFetchingMembers] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const onboardingDone = user.onboarding_status === 'complete';

  useEffect(() => {
    setIsLoading(isFetchingFarms || isFetchingMembers);
  }, [isFetchingFarms, isFetchingMembers]);

  useEffect(() => {
    setOnboardingModalLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    setOnboardingModalActive(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const farmsQuery = useFarmsQuery({
    currentUserEmail: user?.email,
    token: token,
    forceInvalidateQuery: true
  });

  useEffect(() => {
    setIsFetchingFarms(farmsQuery.isLoading);
  }, [farmsQuery.isLoading]);

  useEffect(() => {
    if (farmsQuery.data && !farmsQuery.isLoading) {
      const _farms = farmsQuery.data;
      setCurrFarms(_farms);
      let fields = 0;
      let hasAtLeastOneVariety = false;
      const _varieties: Variety[] = [];
      for (let i = 0; i < _farms.length; i++) {
        const f: Farm = _farms[i];
        if (f.number_of_active_fields) {
          fields += +f.number_of_active_fields;
        }
        if (f.varieties && f.varieties.length > 0) {
          hasAtLeastOneVariety = true;
          _varieties.push(...f.varieties);
        }
      }
      setNumberOfBlocks(fields);
      setCurrVarieties(_varieties);
      if (hasAtLeastOneVariety && _farms.length > 0 && fields > 0 && !onboardingDone) {
        markOnboardingDone();
      }
      fetchMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmsQuery.data]);

  const markOnboardingDone = async () => {
    setIsLoading(true);
    const res = await httpClient
      .getAxios()
      .get(`${BASE_URL}/auth/complete-onboarding`, {
        headers: {
          Authorization: token
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
    setIsLoading(false);
    if (res?.data) {
      setCurrStep(FarmOnboardSteps.DONE + 1);
      setSkippedMembersAdd(true);
    }
  };

  const fetchMembers = async () => {
    setIsFetchingMembers(true);
    const res = await httpClient
      .getAxios()
      .get(`${BASE_URL}/organizations/users`, {
        headers: user.tenant_header
          ? {
              Authorization: token,
              'Tenant-Header': user.tenant_header
            }
          : {
              Authorization: token
            }
      })
      .catch(() => {
        setIsFetchingMembers(false);
      });
    setIsFetchingMembers(false);
    if (res?.data) {
      if (res?.data?.length > 1) {
        setSkippedMembersAdd(true);
        setNumberOfMembers(res?.data?.length ?? 0);
      }
    }
  };

  // Do not remove this is a log helper
  // const convertCurrStepToString = (step: FarmOnboardSteps): string => {
  //   switch (step) {
  //     case FarmOnboardSteps.ADD_FARMS:
  //       return 'Add Farms';
  //     case FarmOnboardSteps.ADD_VARIETIES:
  //       return 'Add Varieties';
  //     case FarmOnboardSteps.INVITE_MEMBERS:
  //       return 'Invite Members';
  //     case FarmOnboardSteps.ADD_FIELDS:
  //       return 'Add Fields';
  //     case FarmOnboardSteps.DONE:
  //       return 'Done';
  //     default:
  //       return 'None';
  //   }
  // };

  useEffect(() => {
    setCurrentFarmOnboardStep(currStep - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currStep]);

  const checkForFarms = async () => {
    // log every length
    if (isLoading) {
      onClose();
      return;
    }
    if (currFarms.length === 0) {
      onOpen();
      return;
    }
    if (currVarieties.length === 0) {
      onOpen();
      return;
    }
    if (numberOfBlocks === 0 && user.onboarding_status !== 'complete') {
      onOpen();
      return;
    }
    // onOpen(); //TODO: remove this
    onClose();
  };

  useEffect(() => {
    if (!isFetchingFarms && !isFetchingMembers && !location.pathname.includes('settings')) {
      checkForFarms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currFarms,
    currVarieties,
    location.pathname,
    isFetchingFarms,
    isFetchingMembers,
    numberOfBlocks,
    isLoading,
    user
  ]);

  const validateSteps = () => {
    // if (true) {
    //   setCurrStep(FarmOnboardSteps.ADD_FARMS + 1);
    //   return;
    // }
    if (user.onboarding_status === 'complete' && currFarms.length > 0 && currVarieties.length > 0) {
      setCurrStep(FarmOnboardSteps.DONE + 1);
      return;
    }
    if (currFarms.length > 0 && currVarieties.length > 0 && numberOfBlocks > 0) {
      setCurrStep(FarmOnboardSteps.DONE + 1);
      return;
    }
    if (currFarms.length === 0) {
      setCurrStep(FarmOnboardSteps.ADD_FARMS + 1);
    }
    if (currFarms.length > 0 && currVarieties.length === 0 && numberOfBlocks === 0) {
      setCurrStep(FarmOnboardSteps.ADD_VARIETIES + 1);
    }
    if (currVarieties.length > 0 && currFarms.length > 0 && numberOfBlocks === 0) {
      const skipped = getSkippedMembersAdd();
      if (skipped) {
        setCurrStep(FarmOnboardSteps.INVITE_MEMBERS + 2);
      } else {
        setCurrStep(FarmOnboardSteps.INVITE_MEMBERS + 1);
      }
    }
    if (
      (numberOfBlocks > 0 && currFarms.length > 0 && currVarieties.length > 0) ||
      (numberOfMembers > 0 && currFarms.length > 0 && currVarieties.length > 0)
    ) {
      setCurrStep(FarmOnboardSteps.ADD_FIELDS + 1);
    }
    if (
      numberOfMembers > 0 &&
      currFarms.length > 0 &&
      currVarieties.length > 0 &&
      numberOfBlocks > 0
    ) {
      setCurrStep(FarmOnboardSteps.ADD_FIELDS + 1);
    }
  };

  useEffect(() => {
    validateSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currFarms, currVarieties, numberOfBlocks, numberOfMembers]);

  const btnText = (step: FarmOnboardSteps) => {
    switch (step - 1) {
      case FarmOnboardSteps.ADD_FARMS:
        return 'Proceed to add farms';
      case FarmOnboardSteps.ADD_VARIETIES:
        return 'Proceed to add varieties';
      case FarmOnboardSteps.INVITE_MEMBERS:
        return 'Proceed to invite members';
      case FarmOnboardSteps.ADD_FIELDS:
        return 'Proceed to add fields';
      case FarmOnboardSteps.DONE:
        return 'Proceed to status report';
      default:
        return 'Dismiss';
    }
  };

  const handleBtnClick = () => {
    switch (currStep - 1) {
      case FarmOnboardSteps.ADD_FARMS:
        onClose();
        navigate('/settings/farms', { replace: true });
        break;
      case FarmOnboardSteps.ADD_VARIETIES:
        onClose();
        navigate('/settings/varieties', { replace: true });
        break;
      case FarmOnboardSteps.INVITE_MEMBERS:
        onClose();
        navigate('/settings/members', { replace: true });
        break;
      case FarmOnboardSteps.ADD_FIELDS:
        onClose();
        if (location.pathname !== '/farm-production') {
          navigate('/farm-production', { replace: true });
        }
        break;
      case FarmOnboardSteps.DONE:
        onClose();
        if (location.pathname !== '/dashboard') {
          navigate('/dashboard', { replace: true });
        }
        break;
      default:
        onClose();
        break;
    }
  };

  const itemStatusFromNumber = (itemNumber: number) => {
    switch (itemNumber) {
      case 1:
        if (currStep === 1) return OrganizationRegistrationProgress.IN_PROGRESS;
        if (currStep > 1) return OrganizationRegistrationProgress.DONE;
        return OrganizationRegistrationProgress.TO_BE_DONE;
      case 2:
        if (currStep === 2) return OrganizationRegistrationProgress.IN_PROGRESS;
        if (currStep > 2) return OrganizationRegistrationProgress.DONE;
        return OrganizationRegistrationProgress.TO_BE_DONE;
      case 3:
        if (currStep === 3) return OrganizationRegistrationProgress.IN_PROGRESS;
        if (currStep > 3) return OrganizationRegistrationProgress.DONE;
        return OrganizationRegistrationProgress.TO_BE_DONE;
      case 4:
        if (currStep === 4) return OrganizationRegistrationProgress.IN_PROGRESS;
        if (currStep > 4) return OrganizationRegistrationProgress.DONE;
        return OrganizationRegistrationProgress.TO_BE_DONE;
      case 5:
        return OrganizationRegistrationProgress.DONE;
      default:
        return OrganizationRegistrationProgress.TO_BE_DONE;
    }
  };

  const conditionalDottedLines = (itemNumber: number) => {
    return (
      <>
        {itemStatusFromNumber(itemNumber) === OrganizationRegistrationProgress.DONE && (
          <Box w="140px" className={styles.dottedHorizontalLine} />
        )}
        {itemStatusFromNumber(itemNumber) === OrganizationRegistrationProgress.IN_PROGRESS && (
          <>
            <Box w="70px" className={styles.dottedHorizontalLine} />
            <Box w="69px" className={styles.dottedHorizontalLineGray} />
          </>
        )}
        {itemStatusFromNumber(itemNumber) === OrganizationRegistrationProgress.TO_BE_DONE && (
          <Box w="140px" className={styles.dottedHorizontalLineGray} />
        )}
      </>
    );
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent maxW="800px" bg="white">
        <Flex p={2} flexDir="column">
          <Text
            data-cy="onboarding-modal-title"
            alignSelf="center"
            mt="40px"
            mb="40px"
            as="h4"
            className={styles.heading}
          >
            Setup Your Farm
          </Text>
          <Flex mb="40px" ml="40px" mr="40px">
            <Box w="70px" className={styles.dottedHorizontalLine} />
            <ProgressCheckMark progress={itemStatusFromNumber(1)} itemNumber={1} />
            {conditionalDottedLines(1)}
            <ProgressCheckMark progress={itemStatusFromNumber(2)} itemNumber={2} />
            {conditionalDottedLines(2)}
            <ProgressCheckMark
              progress={
                numberOfMembers > 0
                  ? OrganizationRegistrationProgress.DONE
                  : itemStatusFromNumber(3)
              }
              itemNumber={3}
            />
            {conditionalDottedLines(3)}
            <ProgressCheckMark progress={itemStatusFromNumber(4)} itemNumber={4} />
            {itemStatusFromNumber(4) === OrganizationRegistrationProgress.DONE && (
              <Box w="70px" className={styles.dottedHorizontalLine} />
            )}
            {itemStatusFromNumber(4) === OrganizationRegistrationProgress.IN_PROGRESS && (
              <Box w="70px" className={styles.dottedHorizontalLine} />
            )}
            {itemStatusFromNumber(4) === OrganizationRegistrationProgress.TO_BE_DONE && (
              <Box w="70px" className={styles.dottedHorizontalLineGray} />
            )}
          </Flex>
          <Flex justifyContent="space-evenly" mb="40px" ml="10px" mr="10px">
            <SetUpCardStep
              icon={'map'}
              done={currFarms.length > 0}
              isCurrStep={currStep === FarmOnboardSteps.ADD_FARMS + 1}
              label={'Add farms'}
            />
            <SetUpCardStep
              icon={'leaf'}
              done={currVarieties.length > 0}
              isCurrStep={currStep === FarmOnboardSteps.ADD_VARIETIES + 1}
              label={'Add varieties'}
            />
            <SetUpCardStep
              icon={'people'}
              done={numberOfMembers > 0 || currStep === FarmOnboardSteps.ADD_FIELDS + 1}
              isCurrStep={currStep === FarmOnboardSteps.INVITE_MEMBERS + 1}
              label={'Invite members'}
            />
            <SetUpCardStep
              icon={'map'}
              done={numberOfBlocks > 0}
              isCurrStep={currStep === FarmOnboardSteps.ADD_FIELDS + 1}
              label={'Add Fields'}
            />
          </Flex>
        </Flex>
        <Flex mb="40px" mr="40px" ml="40px" justifyContent="space-between">
          <Flex flexDir="column" h="40px">
            {currStep === FarmOnboardSteps.INVITE_MEMBERS + 1 && (
              <Button
                width="auto"
                type="button"
                theme="cancel"
                h="100%"
                onClick={() => {
                  setSkippedMembersAdd(true);
                  setCurrStep(FarmOnboardSteps.ADD_FIELDS + 1);
                }}
                px={4}
                __css={{
                  border: '1px solid #818181',
                  borderColor: 'gray.400',
                  color: 'gray.400'
                }}
              >
                Skip
              </Button>
            )}
          </Flex>
          <Button onClick={handleBtnClick} alignSelf="end" width="auto" theme="primary">
            <Text data-cy="onboarding-proceed" fontWeight="normal">
              {btnText(currStep)}
            </Text>
            <Box px={1.5}></Box>
            <Icon as={ArrowRight} />
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default CompleteRegistrationModal;
