import React, { useEffect, useState } from 'react';
import { Button as CKButton, ButtonProps } from '@chakra-ui/react';

export type ButtonTheme =
  | 'primary'
  | 'secondary'
  | 'black'
  | 'success'
  | 'info'
  | 'warn'
  | 'error'
  | 'white'
  | 'cancel';

type MyButtonProps = ButtonProps & {
  theme?: ButtonTheme;
  disabled?: boolean;
};

export default function Button({
  children,
  theme,
  disabled,
  ...props
}: MyButtonProps): JSX.Element {
  const [color, setColor] = useState('primary.300');
  useEffect(() => {
    if (theme === 'white') {
      setColor('white');
    } else if (theme === 'cancel') {
      setColor('transparent');
    } else {
      setColor(`${theme}.300`);
    }
  }, [theme]);
  return (
    <CKButton
      colorScheme={theme}
      boxShadow="none"
      fontWeight={400}
      borderRadius={4}
      isDisabled={disabled}
      _disabled={{
        '&:hover, :hover': {
          cursor: 'not-allowed'
        }
      }}
      _focus={{
        boxShadow: 'none'
      }}
      {...props}
    >
      {children}
    </CKButton>
  );
}
