import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useAuthContext } from './Auth';
import { useAppContext } from './App';
import DashboardAppDeps from 'di/DashboardAppDeps';
import HttpClient from 'http/HttpClient';
import axios from 'axios';
import { Harvest, HarvestEntry, HarvestProductionCategory } from './types';
import { useToast } from '@chakra-ui/react';
import LimaToast from 'Components/Toast/LimaToast';
import moment from 'moment';
import { formatToServerFormat } from 'Helpers/helpers';
import _ from 'lodash';

type ActualsContextType = {
  fetchActualInputData: (start_date: string, end_date: string) => void;
  fetchProductionSummary: (start_date: string, end_date: string, forceRefresh: boolean) => void;
  updateActualData: (values: Harvest[], start_date: string, end_date: string) => void;
  loadingActuals: boolean;
  loadingSummary: boolean;
  error: string | null;
  actualsData: Harvest[];
  summaryData: Harvest[];
};

const MyContext = createContext<ActualsContextType>({
  fetchActualInputData: () => {},
  fetchProductionSummary: () => {},
  updateActualData: () => {},
  loadingActuals: false,
  loadingSummary: true,
  error: null,
  actualsData: [],
  summaryData: []
});

export const BASE_URL = `${process.env.REACT_APP_BE_URL}`;

export const useActualContext = () => useContext(MyContext);
const appDeps: DashboardAppDeps = DashboardAppDeps.getInstance();
const httpClient: HttpClient = appDeps.provideHttpClient();

export default function ActualsContext({ children }: { children: ReactNode }): JSX.Element {
  const {
    functions: { getToken }
  } = useAuthContext();
  const { variety, farm } = useAppContext();
  const toast = useToast();

  const [loadingActuals, setLoadingActuals] = useState<boolean>(false);
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [actualsData, setActualsData] = useState<Harvest[]>([]);

  const [summaryData, setSummaryData] = useState<Harvest[]>([]);
  const fetchActualInputData = async (start_date: string, end_date: string) => {
    if (!loadingActuals) {
      setLoadingActuals(true);
      const response = await axios
        .get(`${BASE_URL}/varieties/${variety?.id}/harvests`, {
          headers: {
            Authorization: getToken(),
            'tenant-header': farm?.organization.internal_name ?? ''
          },
          params: { variety_id: variety?.id, start_date, end_date }
        })
        .catch((err) => {
          setLoadingActuals(false);
          setError(err?.response?.data?.detail);
        });
      setLoadingActuals(false);
      // check if response is of type AxiosResponse<any, any>
      if (response && response?.data) {
        //----Start Build harvests from harvest
        // limiting created at timestamp to be used in distinguishing multiple harvest entries for the same entry date and field
        let harvest_data: HarvestEntry[] = response?.data;
        harvest_data = harvest_data.map((item) => {
          item.created_at = item.created_at.substring(0, 23);
          return item;
        });
        const actual_harvest_inputs: Harvest[] = [];
        const harvests_per_entry_date = _.groupBy(harvest_data, 'entry_date');
        const entry_dates = Object.keys(harvests_per_entry_date);
        const reject_production_category = variety?.production_categories.find(
          (production_category) => production_category.category_type == 'reject'
        );
        entry_dates.forEach((entry_date) => {
          // group harvests per field
          const harvests_per_field = _.groupBy(harvests_per_entry_date[entry_date], 'field_id');

          Object.keys(harvests_per_field).forEach((field_id) => {
            // group harvests per field, per entry date
            const harvests_per_field_separate_by_day = _.groupBy(
              harvests_per_field[field_id],
              'created_at'
            );
            Object.keys(harvests_per_field_separate_by_day).forEach((created_at) => {
              //distinguishing multiple harvest entries for the same entry date and field
              const harvest_productions: HarvestProductionCategory[] = [];
              let stems = 0;
              // harvests_per_field_separate_by_day[created_at].forEach((harvest_entry) => {
              variety?.production_categories.forEach((production_category) => {
                const harvest_entry = harvests_per_field_separate_by_day[created_at].find(
                  (harvest_entry) => harvest_entry.production_category_id == production_category.id
                );
                const yield_value = harvest_entry?.yield_value ?? 0;
                harvest_productions.push({
                  [production_category.id]: {
                    yield_value: yield_value,
                    harvest_id: harvest_entry?.id ?? null,
                    production_category_id: production_category.id
                  }
                } as HarvestProductionCategory);
                // compute total grade while excluding rejects
                if (production_category.id != reject_production_category?.id) {
                  stems += yield_value;
                }
              });
              // build harvest array
              actual_harvest_inputs.push({
                _id: field_id + '#' + entry_date,
                name: '',
                field_id: field_id,
                farm_id: '',
                variety: '',
                day: entry_date.substring(0, 10),
                stems: stems,
                harvest_productions: harvest_productions,
                created_at: harvests_per_field[field_id][0].created_at,
                updated_at: harvests_per_field[field_id][0].updated_at,
                year: harvests_per_field[field_id][0].entry_date.substring(0, 4)
              } as Harvest);
            });
          });
        });
        setActualsData([...actual_harvest_inputs]);
      }
    }
  };

  const fetchProductionSummary = async (
    start_date: string,
    end_date: string,
    forceRefresh?: boolean
  ) => {
    if (!farm || !variety) return;
    let summaryLen = summaryData.length;
    if (forceRefresh) {
      summaryLen = 0;
    }
    if (summaryLen > 0) return;
    setLoadingSummary(true);

    const token = getToken();
    const response = await httpClient
      .getAxios()
      .get(`${BASE_URL}/varieties/${variety.id}/harvest-production-summary`, {
        headers: {
          Authorization: token,
          'tenant-header': farm.organization.internal_name ?? ''
        },
        params: { variety_id: variety?.id, start_date, end_date }
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((err: any) => {
        setLoadingSummary(false);
        // setSummaryError(err.response.data.detail);
      });
    setLoadingSummary(false);
    // check if response is of type AxiosResponse<any, any
    if (response && response.data && response.data.length > 0) {
      // format summary data
      let summary_data: Harvest[] = response?.data;
      const reject_production_category = variety?.production_categories.find(
        (production_category) => production_category.category_type == 'reject'
      );
      summary_data = response?.data.map((item: any) => {
        item.date = item.date?.substring(0, 10) ?? '';
        item.year = item.date?.substring(0, 4);
        // format harvest productions
        item.harvest_productions = item.harvest_productions.map((harvest_production: any) => {
          return {
            [harvest_production.id]: {
              yield_value: harvest_production.yield_value,
              production_category_id: harvest_production.id
            }
          };
        });
        // get total stems without including rejects
        let stems = 0;
        item.harvest_productions.forEach((harvest_production: HarvestProductionCategory) => {
          if (
            reject_production_category?.id !=
            Object.values(harvest_production)[0].production_category_id
          )
            stems += Object.values(harvest_production)[0].yield_value;
        });
        item.stems = stems;
        return item;
      });

      setSummaryData([...summary_data]);
    } else {
      setSummaryData([]);
    }
  };

  const updateActualData = async (
    actual_inputs: Harvest[],
    start_date: string,
    end_date: string
  ) => {
    const createDs: HarvestEntry[] = [];
    const updateDs: HarvestEntry[] = [];
    // prepare data in harvest entry format
    actual_inputs.forEach((new_harvest) => {
      if (new_harvest.field_id != '' && new_harvest.day)
        new_harvest.harvest_productions.forEach((harvest_production) => {
          const harvest_entry = {
            id: Object.values(harvest_production)[0].harvest_id ?? null,
            field_id: new_harvest.field_id,
            // cycle_id: 0,
            production_category_id: +Object.keys(harvest_production)[0],
            entry_date: formatToServerFormat(moment(new_harvest.day, 'YYYY-MM-DD').toDate()),
            yield_value: Object.values(harvest_production)[0].yield_value
          } as HarvestEntry;

          // separating new entries from pre_existing entries
          if (Object.values(harvest_production)[0].harvest_id) {
            updateDs.push(harvest_entry);
          } else {
            createDs.push(harvest_entry);
          }
        });
    });
    const token = getToken();
    if (token) {
      setLoadingActuals(true);
      // --create api request
      if (createDs.length > 0) {
        const createResponse = await httpClient
          .getAxios()
          .post(`${BASE_URL}/harvests`, [...createDs], {
            headers: {
              Authorization: token,
              'tenant-header': farm?.organization.internal_name ?? ''
            }
          })
          .catch(() => {
            setLoadingActuals(false);
            setError(null);
          });

        // show the success toast
        if (createResponse && (createResponse.status === 200 || createResponse.status === 201)) {
          toast({
            position: 'top-right',
            render: () => (
              <LimaToast
                status="success"
                message={`New harvest data entries have been added!`}
                marginTop="12vh"
                marginRight="3vw"
              />
            ),
            duration: 5000,
            isClosable: true
          });
        }
      }
      if (updateDs.length > 0) {
        const updateResponse = await httpClient
          .getAxios()
          .put(`${BASE_URL}/harvests/`, [...updateDs], {
            headers: {
              Authorization: token,
              'tenant-header': farm?.organization.internal_name ?? ''
            }
          })
          .catch(() => {
            setLoadingActuals(false);
            setError(null);
          });
        if (updateResponse && (updateResponse.status === 200 || updateResponse.status === 201)) {
          toast({
            position: 'top-right',
            render: () => (
              <LimaToast
                status="success"
                message={`Existing harvest data entries have been updated!`}
                marginTop="12vh"
                marginRight="3vw"
              />
            ),
            duration: 5000,
            isClosable: true
          });
        }
        // --- end update api request
      }

      setLoadingActuals(false);
      fetchActualInputData(start_date, end_date);
    }
    // --END TODO
  };

  return (
    <MyContext.Provider
      value={{
        fetchActualInputData,
        fetchProductionSummary,
        updateActualData,
        loadingActuals,
        loadingSummary,
        actualsData,
        summaryData,
        error
      }}
    >
      {children}
    </MyContext.Provider>
  );
}
