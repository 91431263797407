import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Icon,
  Flex,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { Formik, FormikProps, useFormikContext } from 'formik';
import { Calendar, Download, Hash, Percent } from 'react-feather';
import { CycleConditionSchema, ContinuousCycleConditionSchema } from 'Validations';
import {
  CycleCondition,
  CycleFormItem,
  JointBlockCycle,
  HarvestSchedule,
  ForecastProductionGradeItem,
  FieldProperties
} from 'Context/types';
import BlockInput from './BlockInput';
import _, { isEmpty } from 'lodash';
import { Button } from 'Components';
import { useAppContext, useAuthContext } from 'Context';
import { TableColumn } from 'Components/types';
import HarvestScheduleTable from './HarvestScheduleTable';
import { NotificationToast } from 'Components/Cards';
import { objectsSame } from 'Helpers/helpers';
import { useAppDispatch } from 'redux/hooks';
import { setInitialLoad } from 'redux/counter';
import moment from 'moment';

export default function CycleConditionForm({ data }: { data: CycleCondition }): JSX.Element {
  const {
    updateFieldProperties,
    isSaving,
    selectedCycle,
    updateHarvestScheduleDates,
    farm,
    variety,
    isDownloadingHarvestSchedule,
    downloadHarvestSchedule,
    varietyIsCyclic
  } = useAppContext();

  const {
    functions: { getToken }
  } = useAuthContext();
  const initialFormItems: CycleFormItem<false>[] = [
    {
      name: 'stem_density_farm_pred',
      type: 'number',
      placeholder: '0',
      min: 0,
      max: 1000,
      step: 0.01,
      leftIcon: Hash,
      label: 'Stem Density',
      helpText: 'As stems per metre',
      byLima: true,
      hidden: !varietyIsCyclic()
    },
    {
      name: 'area',
      type: 'number',
      placeholder: '0',
      leftIcon: Hash,
      label: 'Area',
      helpText: 'As metres squared',
      byLima: true,
      hidden: false
    },
    {
      name: 'dieback',
      type: 'percentage',
      min: 0,
      max: 100,
      step: 0.01,
      placeholder: '0',
      leftIcon: Percent,
      label: 'Dieback',
      helpText: 'As % of total field',
      byLima: true,
      hidden: !varietyIsCyclic()
    },
    {
      name: 'loss',
      type: 'percentage',
      min: 0,
      max: 100,
      step: 0.01,
      placeholder: '0',
      leftIcon: Percent,
      label: 'Rejection',
      helpText: 'As % of total stems on field',
      hidden: false
    }
  ];
  const columns: TableColumn[] = [
    {
      name: 'Harvest Day',
      key: 'day',
      sortable: true,
      isDateTime: true
    },
    {
      name: 'Stems',
      key: 'stems',
      isNumeric: true,
      units: 'Stems'
    },
    {
      name: 'Grade Split',
      key: 'grade_split',
      isNumeric: true,
      units: '%'
    }
  ];
  const [formItems, setFormItems] = useState<CycleFormItem<false>[]>(initialFormItems);
  // current date using moment in the format of YYYY-MM-DD 2022-08-02
  const currentDate = moment().format('YYYY-MM-DD');
  const [initialValues, setInitialValues] = useState<CycleCondition>({
    id: 'defaultblock',
    stem_density_farm_pred: 0,
    yield_pred: 0,
    area: 0,
    dieback: 0,
    loss: 0,
    entries: [],
    packhouse_rejection: 0,
    cycle_start_date: currentDate,
    first_date_harvest_pred: currentDate,
    first_date_harvest_actual: currentDate,
    last_date_harvest_actual: currentDate,
    first_date_harvest_farm_pred: currentDate,
    last_date_harvest_farm_pred: currentDate,
    comment: '',
    harvest_schedule: [],
    previous_harvest_schedule: [],
    grading: [],
    harvest_frequency: variety?.frequency_type ?? ''
  });

  const dispatch = useAppDispatch();
  const [shouldReloadValues, setShouldReloadValues] = useState(true);
  const [harvestDatesMatch, setHarvestDatesMatch] = useState(true);
  const [userIsTyping, setUserIsTyping] = useState(false);
  const [totalStemsExceeded, setTotalStemsExceeded] = useState(
    varietyIsCyclic()
      ? {
          message: 'Harvest schedule total yield should match forecast yield',
          type: 'info'
        }
      : undefined
  );
  const [dataBeforeChange, setDataBeforeChange] = useState({
    first: data ? data.first_date_harvest_farm_pred : null,
    last: data ? data.last_date_harvest_farm_pred : null,
    yield_pred: data ? data.yield_pred : null
  });
  const [harvestSchedule, setHarvestSchedule] = useState<HarvestSchedule[] | []>(
    data ? data.harvest_schedule : []
  );
  const [formikValues, setFormikValues] = useState<CycleCondition>({
    id: 'defaultblock',
    stem_density_farm_pred: 0,
    yield_pred: 0,
    area: 0,
    dieback: 0,
    loss: 0,
    entries: [],
    packhouse_rejection: 0,
    cycle_start_date: currentDate,
    first_date_harvest_pred: currentDate,
    first_date_harvest_actual: currentDate,
    last_date_harvest_actual: currentDate,
    first_date_harvest_farm_pred: currentDate,
    last_date_harvest_farm_pred: currentDate,
    comment: '',
    harvest_schedule: [],
    previous_harvest_schedule: [],
    grading: [],
    harvest_frequency: variety?.frequency_type ?? ''
  });
  const [formInputsDisabled, setFormInputsDisabled] = useState(true);

  useEffect(() => {
    const updatedFormItems: CycleFormItem<false>[] = initialFormItems;
    if (variety != null && variety.production_categories != null) {
      variety.production_categories.map((grade, index) => {
        if (
          updatedFormItems.filter((e) => e.name === 'grading[' + index + '].' + grade.id).length ==
          0
        ) {
          updatedFormItems.push({
            name: 'grading[' + index + '].' + grade.id,
            error_name: 'grading',
            type: 'percentage',
            min: 0,
            max: 100,
            step: 0.01,
            placeholder: '0',
            leftIcon: Percent,
            label: 'Grade: ' + grade.external_name,
            helpText: 'Forecast % of ' + grade.external_name + ' stems',
            hidden: !varietyIsCyclic()
          });
        }
      });
    }

    setFormItems(
      updatedFormItems.concat([
        {
          name: 'first_date_harvest_pred',
          type: 'date',
          placeholder: 'd-mm-yyyy',
          rightIcon: Calendar,
          label: 'Harvest Date',
          helpText: '(Best) Forecast day of harvest',
          isBlock: true,
          byLima: true,
          hidden: !varietyIsCyclic()
        },
        {
          name: 'first_date_harvest_farm_pred',
          type: 'date',
          placeholder: 'd-mm-yyyy',
          rightIcon: Calendar,
          label: 'First Day of Harvest',
          helpText: 'Day harvesting starts',
          hidden: !varietyIsCyclic()
        },
        {
          name: 'last_date_harvest_farm_pred',
          type: 'date',
          placeholder: 'd-mm-yyyy',
          rightIcon: Calendar,
          label: 'Last Day of Harvest',
          helpText: 'Day harvesting ends',
          hidden: !varietyIsCyclic()
        },
        {
          name: 'comment',
          type: 'textarea',
          placeholder: 'Tell us what you observe',
          label: 'Comment',
          isBlock: true,
          helpText: 'Tell us what you observe',
          hidden: false
        }
      ])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variety]);

  useEffect(() => {
    if (data) {
      // enable / disable form input based on block selection
      if (data?.id === 'defaultblock') {
        setFormInputsDisabled(true);
      } else {
        setFormInputsDisabled(false);
      }

      let previous_harvest_schedule: HarvestSchedule[] = [];
      if (data?.harvest_schedule?.length > 0) {
        previous_harvest_schedule = [...data?.harvest_schedule];
      }
      let safeData: CycleCondition = {
        id: data.id ?? 0,
        stem_density_farm_pred: data.stem_density_farm_pred ?? 0,
        yield_pred: data.yield_pred ?? 0,
        area: data.area ?? 0,
        dieback: data.dieback ?? 0,
        loss: data.loss ?? 0,
        entries: data.entries ?? [],
        packhouse_rejection: data.packhouse_rejection ?? 0,
        cycle_start_date: data.cycle_start_date ?? currentDate,
        first_date_harvest_pred: data?.first_date_harvest_pred?.substring(0, 10) ?? currentDate,
        first_date_harvest_actual: data.first_date_harvest_actual ?? currentDate,
        last_date_harvest_actual: data.last_date_harvest_actual ?? currentDate,
        first_date_harvest_farm_pred:
          data?.first_date_harvest_farm_pred?.substring(0, 10) ?? currentDate,
        last_date_harvest_farm_pred:
          data?.last_date_harvest_farm_pred?.substring(0, 10) ?? currentDate,
        comment: '',
        harvest_schedule: data?.harvest_schedule ?? [],
        previous_harvest_schedule: previous_harvest_schedule ?? [],
        grading: data.grading ?? [],
        harvest_frequency: variety?.frequency_type ?? ''
      };
      let safeHarvestSchedule: HarvestSchedule[] = [];
      if (safeData.harvest_schedule.length > 0) {
        safeHarvestSchedule = safeData.harvest_schedule.map((item) => {
          return {
            ...item,
            stem_density: item.stem_density ?? 0,
            area: item.area ?? 0,
            dieback: item.dieback ?? 0,
            loss: item.loss ?? 0,
            grading: item.grading ?? 0
          };
        });
        safeData = {
          ...safeData,
          harvest_schedule: safeHarvestSchedule
        };
        setHarvestSchedule(safeHarvestSchedule);
      } else {
        setHarvestSchedule([]);
      }
      setDataBeforeChange({
        first: safeData.first_date_harvest_farm_pred,
        last: safeData.last_date_harvest_farm_pred,
        yield_pred: safeData.yield_pred
      });
      setInitialValues({
        ...safeData
      });
      dispatch(setInitialLoad(true));
      setShouldReloadValues(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); // this use effect should only when the data prop is initially set

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (shouldReloadValues) {
      // formik `enableReinitialize` is almost infinite so we only do it for a short time
      timeout = setTimeout(() => {
        setShouldReloadValues(false);
      }, 100);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shouldReloadValues]);

  useEffect(() => {
    //reload initial values when initialValues state changes
    setShouldReloadValues(true);
  }, [initialValues]);

  const handleSubmit = async (values: CycleCondition) => {
    // setInitialValues({ ...values, comment: '' });
    updateFieldProperties(values, getToken() ?? '');
  };
  const handleDownloadHarvestSchedule = async () => {
    downloadHarvestSchedule(getToken() ?? '', farm?.organization.internal_name ?? '', data.id);
  };

  // If you want to do something with the event declare the function as
  // const handleOnChange = (event: FormEvent) => {
  // and import FormEvent from react
  // currently declared as empty since it overrides on change but the form observer is handling everything else
  const handleOnChange = () => {
    setUserIsTyping(true);
  };

  useEffect(() => {
    let timeoutFn: NodeJS.Timeout;
    if (userIsTyping) {
      let timeout = 2000;
      if (process.env.REACT_APP_ENVIRONMENT == 'testing') {
        timeout = 2800;
      }
      timeoutFn = setTimeout(() => {
        setUserIsTyping(false);
      }, timeout);
    }
    return () => {
      if (timeoutFn) {
        clearTimeout(timeoutFn);
      }
    };
  }, [userIsTyping]);

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext();
    // if (!objectsSame(values, initialValues, null)) {
    //   setFormikValues(values as CycleCondition);
    // }

    useEffect(() => {
      const cycleConditionValues = values as JointBlockCycle;
      if (
        !objectsSame(values, formikValues, null) &&
        data?.id == initialValues?.id &&
        cycleConditionValues?.area >= 50 &&
        !userIsTyping &&
        data.id !== 'defaultblock'
      ) {
        const currValues = { ...(values as JointBlockCycle) };
        const previous_harvest_schedule: HarvestSchedule[] | void = [];

        //---handle change of values that affect yield_pred
        let yield_pred = cycleConditionValues.yield_pred;
        if (
          (!isEmpty(selectedCycle) &&
            formikValues?.id === cycleConditionValues.id &&
            (cycleConditionValues.stem_density_farm_pred > 0 ||
              (!varietyIsCyclic() &&
                cycleConditionValues?.harvest_schedule?.[0]?.stem_density > 0)) &&
            (formikValues?.stem_density_farm_pred !== cycleConditionValues.stem_density_farm_pred ||
              formikValues?.area !== cycleConditionValues.area ||
              formikValues?.dieback !== cycleConditionValues.dieback ||
              formikValues?.loss !== cycleConditionValues.loss)) ||
          (yield_pred == 0 &&
            (cycleConditionValues.first_date_harvest_farm_pred !== dataBeforeChange?.first ||
              cycleConditionValues.last_date_harvest_farm_pred !== dataBeforeChange?.last)) //when yield value is still zero but dates have been updated allow for harvest schedule updates
        ) {
          if (varietyIsCyclic()) {
            yield_pred =
              cycleConditionValues.stem_density_farm_pred *
              (1 - cycleConditionValues.dieback / 100) *
              (1 - cycleConditionValues.loss / 100) *
              cycleConditionValues.area;
            cycleConditionValues.yield_pred = yield_pred;
          } else {
            if (formikValues?.area !== cycleConditionValues.area) {
              // update stems in each harvest schedule item
              for (let index = 0; index < currValues.harvest_schedule.length; index++) {
                previous_harvest_schedule.push({
                  ...formikValues.previous_harvest_schedule[index]
                });
                const loss = index == 0 ? currValues.loss : currValues.harvest_schedule[index].loss;

                currValues.harvest_schedule[index].stems =
                  currValues.harvest_schedule[index]?.stem_density *
                  (1 - currValues.harvest_schedule[index]?.dieback / 100) *
                  (1 - loss / 100) *
                  currValues?.area;

                currValues.harvest_schedule[index].area = currValues?.area;
              }
            } else {
              // update stems for the first item
              cycleConditionValues.harvest_schedule[0].stems =
                cycleConditionValues.harvest_schedule[0]?.stem_density *
                (1 - cycleConditionValues.harvest_schedule[0]?.dieback / 100) *
                (1 - cycleConditionValues.loss / 100) *
                cycleConditionValues.area;
            }
          }
        }
        // console.log('previous_harvest_schedule', previous_harvest_schedule);
        //------ recalculate stem density
        if (!varietyIsCyclic()) {
          for (let index = 0; index < currValues.harvest_schedule.length; index++) {
            // calculation of stem_density
            if (
              cycleConditionValues?.harvest_schedule[index]?.stems !=
              formikValues?.harvest_schedule[index]?.stems
            ) {
              cycleConditionValues.harvest_schedule[index].stem_density =
                cycleConditionValues.harvest_schedule[index]?.stems /
                ((1 - cycleConditionValues.harvest_schedule[index]?.dieback / 100) *
                  (1 - cycleConditionValues.harvest_schedule[index]?.loss / 100) *
                  cycleConditionValues?.area);
            }
          }
        }

        //---handle misalignment of harvest date and first day of harvest
        if (varietyIsCyclic()) {
          if (
            cycleConditionValues.first_date_harvest_pred !=
            cycleConditionValues.first_date_harvest_farm_pred
          ) {
            setHarvestDatesMatch(false);
          } else {
            setHarvestDatesMatch(true);
          }
        }

        let harvest_schedule: HarvestSchedule[] | void = [];
        if (
          !isEmpty(selectedCycle) &&
          selectedCycle?.field.id === cycleConditionValues.id && //block has not been changed
          (cycleConditionValues.first_date_harvest_farm_pred !== dataBeforeChange?.first || //first date changed
            cycleConditionValues.last_date_harvest_farm_pred !== dataBeforeChange?.last || // last date changed
            !objectsSame((values as CycleCondition).grading, formikValues?.grading, null) || //cycle condition grading changed
            formikValues?.yield_pred !== cycleConditionValues.yield_pred || //any value affecting cycle condition yield_pred changed
            !objectsSame(
              cycleConditionValues.harvest_schedule as HarvestSchedule[],
              formikValues?.harvest_schedule,
              null
            ) ||
            !varietyIsCyclic()) && //harvest_schedule edited
          cycleConditionValues.yield_pred > 0 && //yield pred is changed to value greater than 0
          ((cycleConditionValues.first_date_harvest_farm_pred !== '' &&
            cycleConditionValues.last_date_harvest_farm_pred !== '' &&
            varietyIsCyclic()) ||
            !varietyIsCyclic()) &&
          cycleConditionValues.area > 0
        ) {
          //convert current entered stems back to % for conversion and update of grade split
          const updatedHarvestSchedule: HarvestSchedule[] = [];
          if (varietyIsCyclic()) {
            cycleConditionValues.harvest_schedule.forEach((harvest_schedule_item, hsIndex) => {
              const recalc_yield = currValues.yield_pred;

              // convert grade split from stems to percentage based on main entry grading
              const newGrades: ForecastProductionGradeItem[] = [];
              variety?.production_categories.forEach((production_category, index) => {
                const yield_value =
                  cycleConditionValues?.grading?.[production_category.id] != undefined
                    ? +cycleConditionValues?.grading?.[production_category.id]?.yield_value
                    : 0;
                newGrades[index] = {
                  ...harvest_schedule_item.grading[production_category.id],
                  yield_value: yield_value
                } as ForecastProductionGradeItem;
              });

              updatedHarvestSchedule.push({
                ...harvest_schedule_item,
                stems:
                  dataBeforeChange !== null && recalc_yield
                    ? // when the value has been edited do not convert back with the old non rounded value
                      harvest_schedule_item !== null &&
                      formikValues?.harvest_schedule[hsIndex]?.stems === harvest_schedule_item.stems
                      ? harvest_schedule_item.stems_no_round / recalc_yield
                      : harvest_schedule_item.stems / recalc_yield
                    : 0,
                grading: newGrades
              });
            });
          } else {
            cycleConditionValues.harvest_schedule.forEach((harvest_schedule_item, hsIndex) => {
              // convert grade split from stems to percentage based on main entry grading
              const newGrades: ForecastProductionGradeItem[] = [];

              variety?.production_categories.forEach((production_category, index) => {
                const prev_forecast_production_grade_item =
                  previous_harvest_schedule?.[hsIndex]?.grading[index][production_category.id];
                const forecast_production_grade_item =
                  harvest_schedule_item?.grading[index][production_category.id];

                const yield_value =
                  forecast_production_grade_item != undefined &&
                  prev_forecast_production_grade_item != undefined
                    ? +harvest_schedule_item?.stems != +previous_harvest_schedule[hsIndex]?.stems &&
                      previous_harvest_schedule[hsIndex]?.stems
                      ? //stems changed by editing area or rejection detectable here
                        (+prev_forecast_production_grade_item.yield_value /
                          +previous_harvest_schedule?.[hsIndex]?.stems) *
                        100
                      : !previous_harvest_schedule[hsIndex]?.stems
                      ? //stems edited directly hence previous_harvest_schedule is undefined but can be accessed from harvestSchedule state

                        (forecast_production_grade_item?.yield_value /
                          harvestSchedule[hsIndex]?.stems) *
                        100
                      : //stems same but grades possibly edited
                        (forecast_production_grade_item?.yield_value /
                          +harvest_schedule_item.stems) *
                        100
                    : forecast_production_grade_item?.yield_value
                    ? (forecast_production_grade_item?.yield_value /
                        harvestSchedule[hsIndex]?.stems) *
                      100
                    : 0;

                newGrades.push({
                  [production_category.id]: {
                    ...forecast_production_grade_item,
                    yield_value: yield_value
                  }
                } as ForecastProductionGradeItem);
              });

              updatedHarvestSchedule.push({
                ...harvest_schedule_item,
                grading: newGrades
              });
            });
          }

          harvest_schedule = updateHarvestScheduleDates({
            first_harvest_date: cycleConditionValues.first_date_harvest_farm_pred,
            last_harvest_date: cycleConditionValues.last_date_harvest_farm_pred,
            field: {
              forecast_summary: {
                yields: yield_pred
              },
              grading: cycleConditionValues.grading
            },
            harvest_schedule: updatedHarvestSchedule
          } as FieldProperties);
        } else {
          harvest_schedule = undefined;
        }
        if (harvest_schedule !== undefined) {
          cycleConditionValues.harvest_schedule = [...harvest_schedule];
          cycleConditionValues.previous_harvest_schedule = [...harvest_schedule];

          setHarvestSchedule(harvest_schedule);
        }

        //---check for excess harvest_schedule stems when schedule updated
        if (
          varietyIsCyclic() &&
          cycleConditionValues.harvest_schedule !== undefined &&
          cycleConditionValues.harvest_schedule.length > 0
        ) {
          let total_stems = 0;
          cycleConditionValues.harvest_schedule.forEach((obj) => {
            if (obj.stems > 0) {
              total_stems += obj.stems;
            }
          });
          if (Math.trunc(total_stems - yield_pred) > 0) {
            setTotalStemsExceeded({
              message:
                'Harvest schedule total yield exceeds forecast yield by ' +
                Math.trunc(total_stems - yield_pred),
              type: 'warn'
            });
          } else if (Math.trunc(total_stems - yield_pred) === 0) {
            setTotalStemsExceeded({
              message: 'Harvest schedule matches forecast yield',
              type: 'success'
            });
          } else {
            setTotalStemsExceeded({
              message: `Harvest schedule total yield is <b>less</b> forecast yield by <b>${Math.trunc(
                Math.abs(total_stems - yield_pred)
              )}</b>`,
              type: 'warn'
            });
          }
        }

        if (values) {
          setFormikValues({ ...(values as CycleCondition) });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIsTyping]);

    return <></>;
  };

  return (
    <>
      {initialValues && (
        <Formik
          initialValues={initialValues /**nonChangingInitialValues*/}
          onSubmit={(values, actions) => {
            handleSubmit(values).then(() => {
              actions.setSubmitting(false);
              actions.resetForm({
                values: { ...values, comment: '' }
              });
            });
          }}
          validationSchema={
            !varietyIsCyclic() ? ContinuousCycleConditionSchema : CycleConditionSchema
          }
          validateOnChange={true}
          validateOnMount={true}
          enableReinitialize={shouldReloadValues}
        >
          {({
            isValid,
            handleSubmit,
            handleReset,
            errors,
            touched,
            setFieldValue,
            values,
            dirty
          }: FormikProps<CycleCondition>) => (
            <form
              className="login__form form"
              onSubmit={handleSubmit}
              onChange={() => handleOnChange()}
            >
              <FormObserver />
              <Grid className="cycle_form">
                {formItems.map(
                  ({
                    name,
                    error_name,
                    type,
                    placeholder,
                    rightIcon,
                    leftIcon,
                    label,
                    isBlock,
                    hidden,
                    byLima,
                    ...props
                  }: CycleFormItem) => (
                    <BlockInput
                      error={_.get(errors, error_name ? error_name : name)}
                      name={name}
                      key={name}
                      _key={name}
                      type={type === 'percentage' ? 'number' : type}
                      hasError={_.get(errors, error_name ? error_name : name)}
                      isTouched={!!_.get(touched, error_name ? error_name : name)}
                      placeholder={placeholder}
                      RightIcon={rightIcon}
                      LeftIcon={leftIcon}
                      label={label}
                      isBlock={!!isBlock}
                      onCustomChange={setFieldValue}
                      onBlur={handleOnChange}
                      date={type === 'date' ? (_.get(values, name) as string) : ''}
                      hidden={hidden}
                      byLima={farm?.with_lima_forecasting ? byLima : false}
                      disabled={formInputsDisabled}
                      {...props}
                    />
                  )
                )}

                <GridItem width="100%" className="block">
                  {varietyIsCyclic() && !harvestDatesMatch ? (
                    <NotificationToast
                      data-cy="harvest_dates_misaligned"
                      type={'warn'}
                      message={'First day of harvest and forecast harvest date are misaligned'}
                    ></NotificationToast>
                  ) : (
                    ''
                  )}
                </GridItem>

                <GridItem
                  className="block"
                  display="flex"
                  justifyContent="space-between"
                  borderColor={'success.500'}
                  width={'100%'}
                >
                  <Accordion
                    data-cy="harvest_schedule_accordion"
                    allowToggle
                    width="100%"
                    mb={8}
                    css={{
                      border: '1.5px solid #66CBC0',
                      borderRadius: '4px'
                    }}
                  >
                    <AccordionItem>
                      <Tooltip
                        hasArrow
                        shouldWrapChildren
                        label={
                          harvestSchedule?.length
                            ? ''
                            : 'Please select a field to view the harvest schedule'
                        }
                        transition={'all .1s ease-in-out'}
                        as="div"
                        width="100%"
                      >
                        <AccordionButton
                          css={{
                            outline: 'none !important',
                            boxShadow: 'none !important',
                            outlineOffset: 'none !important'
                          }}
                          as="div"
                          // pointerEvents={harvestSchedule?.length ? 'initial' : 'none'} //TODO: enable this line once harvest schedule data is listing
                        >
                          <Flex
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            width={'100%'}
                          >
                            <AccordionIcon mr={3} />
                            <Flex
                              justifyContent={'space-between'}
                              alignItems={'center'}
                              width={'100%'}
                              p={'1px 0px'}
                            >
                              <Text>Harvest Schedule</Text>
                              <Box>
                                <Button
                                  width="auto"
                                  theme="primary"
                                  disabled={
                                    dirty || isDownloadingHarvestSchedule || formInputsDisabled
                                  }
                                  size="sm"
                                  onClick={handleDownloadHarvestSchedule}
                                >
                                  {isDownloadingHarvestSchedule ? (
                                    <Spinner color="white.500" size="xs" mr={1} />
                                  ) : (
                                    <Icon as={Download} stroke={'white.500'} w={4} h={4} mr={1} />
                                  )}
                                  Download
                                </Button>
                              </Box>
                            </Flex>
                          </Flex>
                        </AccordionButton>
                        <AccordionPanel pb={4} pt={0}>
                          {variety?.production_categories ? (
                            <HarvestScheduleTable
                              columns={columns}
                              data={harvestSchedule}
                              errors={errors}
                              title="Status Report"
                              subTitle={totalStemsExceeded}
                              grades={variety?.production_categories}
                            />
                          ) : (
                            ''
                          )}
                        </AccordionPanel>
                      </Tooltip>
                    </AccordionItem>
                  </Accordion>
                </GridItem>
                <GridItem
                  className="block button-block"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button
                    width="auto"
                    theme="primary"
                    disabled={!isValid || isSaving}
                    type="submit"
                  >
                    Save Conditions
                    {isSaving && (
                      <>
                        <Box px={1.5}></Box>
                        <Spinner color="white.500" size="sm" />
                      </>
                    )}
                  </Button>
                  <Button
                    width="auto"
                    theme="cancel"
                    type="button"
                    onClick={(e) => {
                      setDataBeforeChange({
                        first: data.first_date_harvest_farm_pred,
                        last: data.last_date_harvest_farm_pred,
                        yield_pred: data.yield_pred
                      });
                      // setInitialValues(initialValues);
                      handleReset(e);
                    }}
                    px={4}
                    __css={{
                      border: '1.5px solid',
                      borderColor: 'dark.coolGray.400',
                      color: 'dark.coolGray.400'
                    }}
                  >
                    Reset
                  </Button>
                </GridItem>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </>
  );
}
