import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Heading,
  Box,
  Grid,
  GridItem,
  Spinner,
  Select,
  FormLabel,
  Flex,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import Button from 'Components/Button';
import { NewBlockCondition, NewBlockFormItem } from 'Context/types';
import { Calendar, ChevronDown, Hash, Map } from 'react-feather';
import { Formik, FormikProps } from 'formik';
import { NewBlockConditionSchema } from 'Validations';
import BlockInput from './BlockInput';
import _ from 'lodash';
import { useAppContext, useAuthContext } from 'Context';
import { FarmOnboardSteps } from 'Layout/Components/CompleteRegistrationModal';
// import { useLocation } from 'react-router-dom';
import { useAnimation } from '@codechem/chakra-ui-animations';

const AddNewBlockModal = (props: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    addNewBlock,
    isSaving,
    varieties,
    newBlockAdded,
    variety,
    fields,
    // currentFarmOnboardStep,
    setCurrentFarmOnboardStep
    // onBoardingModalActive,
    // onboardingModalLoading
  } = useAppContext();
  const {
    functions: { getToken },
    variables: { user },
    loadingStates: { loadingBlocks }
  } = useAuthContext();
  const [modalVariety, setModalVariety] = useState<any>(variety);
  const [initialValues, setInitialValues] = useState<NewBlockCondition | undefined>();
  const [shouldReloadValues, setShouldReloadValues] = useState(true);
  const initialData: NewBlockCondition = {
    block_name: '',
    crop: variety ? variety.id : '',
    planted_date: '',
    cutback_date: '',
    area: 1
  };

  useEffect(() => {
    if (newBlockAdded) {
      setCurrentFarmOnboardStep(FarmOnboardSteps.DONE);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBlockAdded]);

  useEffect(() => {
    setInitialValues(initialData);
    setModalVariety(variety);
    setShouldReloadValues(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variety]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (shouldReloadValues) {
      // formik `enableReinitialize` is almost infinite so we only do it for a short time
      timeout = setTimeout(() => {
        setShouldReloadValues(false);
      }, 2000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shouldReloadValues]);

  const handleSubmit = (values: NewBlockCondition) => {
    addNewBlock(values, getToken());
  };

  const formItems: NewBlockFormItem[] = [
    {
      name: 'block_name',
      type: 'text',
      placeholder: '11A1',
      leftIcon: Map,
      label: 'Field Name',
      helpText: 'Field Identity',
      required: true
    },
    {
      name: 'crop',
      type: 'text',
      placeholder: '0',
      label: 'Variety',
      helpText: 'Variety planted on field',
      required: true
    },
    {
      name: 'planted_date',
      type: 'date',
      placeholder: 'Date',
      rightIcon: Calendar,
      label: 'Planted Date',
      helpText: 'Date the field was planted',
      required: true
    },
    {
      name: 'cutback_date',
      type: 'date',
      placeholder: 'Date',
      rightIcon: Calendar,
      label: 'Cutback Date',
      helpText: 'Last cutback date',
      required: false
    },
    {
      name: 'area',
      type: 'number',
      placeholder: '1,000',
      leftIcon: Hash,
      label: 'Area',
      helpText: 'Total area as metres squared',
      required: true
    }
  ];

  const [animationCount, setAnimationCount] = useState<number | 'infinite'>(0);
  useEffect(() => {
    if (
      fields.length === 0 &&
      !loadingBlocks &&
      location.pathname === '/' &&
      user &&
      user.role.toLocaleLowerCase() === 'admin'
    ) {
      setAnimationCount('infinite');
    } else {
      setAnimationCount(0);
    }
  }, [fields.length, loadingBlocks, user]);

  return (
    <>
      {props?.isLink ? (
        <Text
          color="primary.500"
          className="on_hover_underline"
          cursor="pointer"
          onClick={onOpen}
          display="inline"
        >
          Add fields{' '}
        </Text>
      ) : (
        <Button
          theme="primary"
          fontWeight="400"
          fontSize="0.8rem"
          height="2rem"
          variant="solid"
          borderColor="primary.500 !important"
          color="white"
          onClick={onOpen}
          disabled={!modalVariety}
          data-cy="add-new-field"
          // eslint-disable-next-line react-hooks/rules-of-hooks
          animation={useAnimation('pulse', {
            duration: 1000,
            iterationCount: animationCount
          })}
          margin={'1rem'}
        >
          Add New Field
        </Button>
      )}
      <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="white">
          <ModalHeader>
            <Heading
              as="h4"
              className="table__header__title"
              mb="2"
              fontSize="1.35rem"
              fontWeight="normal"
              data-cy="add-new-field-modal-title"
            >
              Add A New Field
            </Heading>
            <Heading
              as="h6"
              className="table__header__title__sub "
              mb="2"
              fontSize="0.9rem"
              fontWeight="normal"
              color="red.500"
            >
              *Required Fields
            </Heading>
          </ModalHeader>
          {initialValues && (
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={NewBlockConditionSchema}
              validateOnChange={true}
              validateOnMount={true}
              enableReinitialize={shouldReloadValues}
            >
              {({
                isValid,
                handleSubmit,
                errors,
                touched,
                dirty,
                setFieldValue,
                values,
                resetForm
              }: FormikProps<NewBlockCondition>) => (
                <ModalBody>
                  <form className="login__form form" onSubmit={handleSubmit}>
                    <Grid className="cycle_form">
                      {formItems.map(
                        ({
                          name,
                          type,
                          placeholder,
                          rightIcon,
                          leftIcon,
                          label,
                          isBlock,
                          ...props
                        }: NewBlockFormItem) =>
                          name !== 'crop' ? (
                            <BlockInput
                              error={_.get(errors, name)}
                              name={name}
                              key={name}
                              type={type === 'percentage' ? 'number' : type}
                              hasError={!!_.get(errors, name)}
                              isTouched={!!_.get(touched, name)}
                              placeholder={placeholder}
                              RightIcon={rightIcon}
                              LeftIcon={leftIcon}
                              label={label}
                              isBlock={!!isBlock}
                              onCustomChange={setFieldValue}
                              hidden={
                                name == 'cutback_date' &&
                                modalVariety?.harvest_frequency == 'continuous'
                                  ? true
                                  : false
                              }
                              date={type === 'date' ? (_.get(values, name) as string) : ''}
                              {...props}
                            />
                          ) : (
                            <GridItem key={name}>
                              <Flex direction="column">
                                <FormLabel
                                  htmlFor={name}
                                  fontWeight={400}
                                  color="black.300"
                                  fontSize="0.9rem"
                                >
                                  Variety <span className="required">*</span>
                                </FormLabel>
                                <Select
                                  name="crop"
                                  icon={<ChevronDown />}
                                  variant="outline"
                                  isRequired={true}
                                  onChange={(e: any) => {
                                    setFieldValue(name, e.target.value);
                                    setModalVariety(
                                      varieties.find((el: any) => el._id == e.target.value)
                                    );
                                  }}
                                  key={name}
                                  data-cy="variety-select"
                                  id="variety-select"
                                >
                                  <option selected disabled value="">
                                    Select variety
                                  </option>
                                  {varieties.map((item) => (
                                    <option
                                      key={item.id}
                                      value={item.id}
                                      selected={item.id == modalVariety?.id ? true : false}
                                    >
                                      {item.external_name}
                                    </option>
                                  ))}
                                </Select>
                                <Text mb={0} mt={1} color="black.300" as="p" fontSize="12px">
                                  Variety planted on field
                                </Text>
                              </Flex>
                            </GridItem>
                          )
                      )}
                      <GridItem
                        width="100%"
                        className="block button-block"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Button
                          width="auto"
                          theme="primary"
                          disabled={!isValid || !dirty || isSaving}
                          type="submit"
                        >
                          Save Field
                          {isSaving && (
                            <>
                              <Box px={1.5}></Box>
                              <Spinner color="white.500" size="sm" />
                            </>
                          )}
                        </Button>
                        <Button
                          width="auto"
                          theme="cancel"
                          type="button"
                          onClick={() => {
                            resetForm();
                            onClose();
                          }}
                          px={4}
                          __css={{
                            border: '1px solid',
                            borderColor: 'gray.400',
                            color: 'gray.400'
                          }}
                        >
                          Cancel
                        </Button>
                        {/* {currentFarmOnboardStep === FarmOnboardSteps.ADD_FIELDS ? (
                          <Tooltip
                            hasArrow
                            shouldWrapChildren
                            label="Add at least one field to continue"
                            transition={'all .1s ease-in-out'}
                          >
                            <Button
                              width="auto"
                              theme="cancel"
                              type="button"
                              disabled={true}
                              h="100%"
                              onClick={() => {}}
                              px={4}
                              __css={{
                                border: '1px solid',
                                borderColor: 'gray.400',
                                color: 'gray.400'
                              }}
                            >
                              Cancel
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button
                            width="auto"
                            theme="cancel"
                            type="button"
                            onClick={() => {
                              resetForm();
                              onClose();
                            }}
                            px={4}
                            __css={{
                              border: '1px solid',
                              borderColor: 'gray.400',
                              color: 'gray.400'
                            }}
                          >
                            Cancel
                          </Button>
                        )} */}
                      </GridItem>
                    </Grid>
                  </form>
                </ModalBody>
              )}
            </Formik>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddNewBlockModal;
