import {
  Box,
  Flex,
  Heading,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Tbody,
  Td
} from '@chakra-ui/react';
import { Circle } from 'react-feather';
import { FilterDatum, GraphPlotData, useGraphContext } from 'Context/GraphContext';
import { separateComma } from 'Helpers/helpers';
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { nivoTheme } from '../FarmProductionBarChart';
import { Bar } from '@nivo/bar';
import CombinedBarAndLineChart from '../CombinedBarAndLineChart';
import moment from 'moment';
import { ComputedDatum, ResponsivePie } from '@nivo/pie';
import { BasicTooltip } from '@nivo/tooltip';
import { FilterDropDown, GraphKeys } from 'Components';
import CustomTooltip from './CustomTooltip';
import { useAppContext } from 'Context';
import { isArray } from 'lodash';

function MinimumExpectedHarvestGraph() {
  const { farm, variety, limaActiveModules } = useAppContext();
  const { loadingMinimumExpected, fetchMinimumExpectedHarvest, minimumExpectedData, graphColors } =
    useGraphContext();
  const currentWeeks = [
    moment().add(1, 'weeks').isoWeek(),
    moment().add(2, 'weeks').isoWeek(),
    moment().add(3, 'weeks').isoWeek(),
    moment().add(4, 'weeks').isoWeek()
  ];
  const [yMax, setYMax] = useState<number>(10000);
  // fetch data from the api
  useEffect(() => {
    if (farm?.id && variety?.id) {
      const variety_is_valid = farm?.varieties?.some((farm_variety) => {
        return farm_variety?.id === variety?.id;
      });
      if (location.pathname == '/dashboard' && variety_is_valid) {
        fetchMinimumExpectedHarvest();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variety?.id, farm?.id, location.pathname]);

  const defaultPieData = [
    {
      id: 'default_data',
      label: '',
      value: 70
    },
    {
      id: '2',
      label: '',
      value: 60
    },
    {
      id: '3',
      label: '',
      value: 40
    },
    {
      id: '4',
      label: '',
      value: 60
    },
    {
      id: '5',
      label: '',
      value: 20
    }
  ];
  const [pieData, setPieData] = useState<GraphPlotData[]>(defaultPieData);

  const formatLeftAxisValue = (value: number): string => {
    return separateComma(value);
  };
  const getDurationKeys = (): string[] => {
    return ['forecast', 'min_expected_yield'];
  };
  const getDurationColors = (): string[] => {
    return ['#98DAFD', '#68E5AA'];
  };

  const margin = { top: 10, right: 150, bottom: 0, left: 150 };
  const styles: any = {
    root: {
      textAlign: 'center',
      position: 'relative',
      width: 600,
      height: 450
    },
    overlay: {
      position: 'absolute',
      top: 0,
      right: margin.right,
      bottom: 0,
      left: margin.left,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 96,
      color: '#475052',
      textAlign: 'center',
      // This is important to preserve the chart interactivity
      pointerEvents: 'none',
      zIndex: 1
    },
    table_cell: {
      borderRight: '2px solid #ffffff',
      textTransform: 'capitalize',
      color: '#5B6A75',
      backgroundColor: '#98DAFD21',
      fontsize: '1.125rem'
    },
    table_cell_green: {
      borderRight: '2px solid #ffffff',
      textTransform: 'capitalize',
      backgroundColor: '#D3F7E6',
      color: '#212121',
      fontsize: '1.125rem'
    }
  };

  const theme: any = {
    labels: {
      text: {
        fontSize: '1rem',
        fontFamily: 'Be Vietnam Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%' /* 27px */,
        letterSpacing: '-0.9px',
        color: '#464F51'
      }
    },
    legends: {
      text: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '0.79rem',
        fontStyle: 'normal',
        letterSpacing: '1rem',
        color: '#52606D'
      }
    }
  };

  // graph key tags
  const graphKeyTags: FilterDatum[] = [
    {
      id: 'forecast',
      label: 'Forecasted Yield',
      active: true,
      fill: getDurationColors()[0]
    },
    {
      id: 'min_expected_yield',
      label: 'Min Expected Yield',
      active: true,
      fill: getDurationColors()[1]
    }
  ];
  // pie chart dropdown vars
  const [selectedWeek, setSelectedWeek] = useState<number>(0);
  const weekDropdownList = ['Select a Week', ...currentWeeks.map((week: number) => 'Week ' + week)];
  const onSelectWeek = () => {
    return (selection: number | number[]) => {
      if (!isArray(selection)) {
        setSelectedWeek(selection);
      }
    };
  };

  // formating data when it changes or when a different week is selected for piechart
  useEffect(() => {
    // updating maximum plot value for graph
    setYMax(
      minimumExpectedData
        ?.map((el: any) => el.forecast)
        .reduce(function (a: any, b: any) {
          return a > b ? a : b;
        }, 0)
    );
    // formatting data for plotting in pie chart
    if (minimumExpectedData && selectedWeek !== 0) {
      const grading_items = Object.entries(minimumExpectedData[selectedWeek - 1].grading);

      const pie_data: GraphPlotData[] = [];
      if (grading_items.length > 0) {
        variety?.production_categories.forEach((grade, index) => {
          pie_data.push({
            id: grade.internal_name,
            label: grade.external_name,
            value: grading_items[index][1] as number
          });
        });

        // add the ungraded
        pie_data.push({
          id: 'ungraded',
          label: 'Ungraded',
          value: grading_items[grading_items.length - 1][1] as number
        });

        //prevent loading empty pie chart when all grades have 0
        const grading_total = pie_data.reduce((sum, pie_data_item) => sum + pie_data_item.value, 0);
        if (grading_total > 0) {
          setPieData([...pie_data]);
        } else {
          setPieData([...defaultPieData]);
        }
      } else {
        setPieData([...defaultPieData]);
      }
    } else {
      setPieData([...defaultPieData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumExpectedData, selectedWeek]);
  return (
    <>
      <Box
        width="100%"
        backgroundColor="white"
        boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
        py={4}
        px={6}
        borderRadius={8}
        mt="2rem"
      >
        <Flex alignItems={'flex-start'} flexWrap={'wrap'}>
          <Box flex={4} width={'100%'}>
            <Heading
              as="h4"
              mb="2"
              fontSize="1.35rem"
              fontWeight="normal"
              color="dark.coolGray.600"
            >
              Minimum Expected Harvest (From Week {currentWeeks[0] - 1})
            </Heading>
            {loadingMinimumExpected ? (
              <Skeleton height={500} style={{ marginTop: '1.65rem' }} />
            ) : (
              <>
                {minimumExpectedData ? (
                  <>
                    <TableContainer marginTop={6} className={'custom-scrollbar'}>
                      <Table variant="striped" colorScheme="accentGreen" size="sm">
                        <Thead>
                          <Tr>
                            <Td style={{ ...styles.table_cell, fontWeight: 'normal' }}>Week #</Td>
                            {minimumExpectedData?.map((minimum, index) => (
                              <Td
                                isNumeric
                                key={index + '_' + minimum.period}
                                style={{ ...styles.table_cell, fontWeight: 'normal' }}
                              >
                                Week {currentWeeks[index]}
                              </Td>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td style={styles.table_cell_green}>Min Expected Yield</Td>
                            {minimumExpectedData?.map((minimum, index) => (
                              <Td
                                isNumeric
                                key={index + '_' + minimum.period}
                                style={styles.table_cell_green}
                                backgroundColor="#D3F7E6"
                              >
                                {minimum.min_expected_yield &&
                                minimum.min_expected_yield != 0 &&
                                minimum.confidence ? (
                                  <>{`${separateComma(minimum.min_expected_yield)} (${separateComma(
                                    minimum.confidence
                                  )}%)`}</>
                                ) : (
                                  'N.A.'
                                )}
                              </Td>
                            ))}
                          </Tr>
                          <Tr>
                            <Td backgroundColor={'chartblue.20'} style={styles.table_cell}>
                              Forecasted
                            </Td>
                            {minimumExpectedData?.map((minimum_expected, index) => (
                              <Td
                                isNumeric
                                key={index + '_' + minimum_expected.period}
                                style={styles.table_cell}
                              >
                                {`${separateComma(minimum_expected.forecast)}`}
                              </Td>
                            ))}
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <Flex direction={'column'} marginTop={10}>
                      <GraphKeys
                        showTooltip={false}
                        onClick={() => {}}
                        graphKeys={graphKeyTags}
                        leftOffset={'auto'}
                        icon={Circle}
                        clickable={false}
                        justifyContent={'space-between'}
                      ></GraphKeys>
                      <Box style={{ display: 'flex', textAlign: 'center', width: '100%' }}>
                        <Bar
                          theme={nivoTheme}
                          height={400}
                          width={100}
                          data={minimumExpectedData}
                          layers={['axes']}
                          keys={getDurationKeys()}
                          margin={{ top: 10, right: 0, bottom: 62, left: 100 }}
                          maxValue={yMax * 1.3}
                          tooltip={undefined}
                          axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Stem count in thousands',
                            legendPosition: 'middle',
                            legendOffset: -80,
                            format: (value: number | undefined) =>
                              formatLeftAxisValue((value ? value / 1000 : 0) ?? 0)
                          }}
                          axisBottom={null}
                        />
                        <Box
                          style={{
                            height: 400,
                            overflowX: 'scroll',
                            overflowY: 'hidden',
                            width: '100%'
                          }}
                          css={{
                            '&::-webkit-scrollbar': {
                              width: '4px',
                              height: '6px'
                            },
                            '&::-webkit-scrollbar-track': {
                              width: '6px',
                              height: '12px'
                            },
                            '&::-webkit-scrollbar-thumb': {
                              background: '#D5D5D5',
                              borderRadius: '4px'
                            }
                          }}
                        >
                          <CombinedBarAndLineChart
                            duration={'weekly'}
                            data={minimumExpectedData}
                            yMax={yMax}
                            selectedYear={'2023'}
                            lineData={[]}
                            durationKeys={getDurationKeys()}
                            durationColors={getDurationColors()}
                            groupMode="grouped"
                            lineKey=""
                            tooltip={CustomTooltip}
                            indexBy="period"
                            extendYmax
                          />
                        </Box>
                      </Box>
                    </Flex>
                  </>
                ) : (
                  <Text my="1.65rem">No data available for the next 4 weeks.</Text>
                )}
              </>
            )}
          </Box>
          <Flex
            display={'flex'}
            direction={'column'}
            alignItems={'center'}
            flex={3}
            border={'1px solid rgba(82, 96, 109, 0.10)'}
            borderRadius={'0.5rem'}
            margin={6}
          >
            <>
              {minimumExpectedData ? (
                <>
                  {limaActiveModules.minimum_expected_grade_split ? (
                    <>
                      {loadingMinimumExpected ? (
                        <Skeleton
                          height={500}
                          width={'30vw'}
                          style={{ marginBottom: '1.65rem', marginTop: '4vh' }}
                        />
                      ) : (
                        <>
                          <Heading
                            as="h4"
                            mb="2"
                            fontSize="1.35rem"
                            fontWeight="normal"
                            color="dark.coolGray.600"
                            textAlign={'center'}
                            marginTop={50}
                            marginBottom={4}
                          >
                            Grade Split
                          </Heading>
                          <FilterDropDown
                            label={`${weekDropdownList[selectedWeek]}`}
                            items={[
                              'Select a Week',
                              ...currentWeeks.map((week: number) => 'Week ' + week)
                            ]}
                            selectedItem={selectedWeek}
                            onSelectFilter={onSelectWeek()}
                            color={'black.500'}
                          />
                          <div style={styles.root}>
                            <ResponsivePie
                              theme={theme}
                              data={pieData}
                              colors={graphColors?.map((el: any) =>
                                pieData[0].id == 'default_data' ? el.bg : el.icon
                              )}
                              margin={margin}
                              innerRadius={0}
                              valueFormat={(value) => {
                                // format number to have comma as thousand separator
                                return value.toLocaleString();
                              }}
                              // enableArcLabels={pieData[0].id != 'default_data'}
                              enableArcLabels={false}
                              enableArcLinkLabels={pieData[0].id != 'default_data'}
                              sortByValue={true}
                              arcLabel={(arc) => {
                                return `${arc.value.toLocaleString()}`;
                              }}
                              arcLinkLabel={(arc) => {
                                return `${arc.value.toLocaleString()}`;
                              }}
                              arcLinkLabelsColor={{ from: 'color' }}
                              arcLinkLabelsSkipAngle={20}
                              isInteractive={pieData[0].id != 'default_data'}
                              animate={false}
                              legends={
                                pieData[0].id == 'default_data'
                                  ? undefined
                                  : [
                                      {
                                        anchor: 'top',
                                        direction: 'row',
                                        justify: false,
                                        translateX: 0,
                                        translateY: 20,
                                        itemsSpacing: 0,
                                        itemWidth: 90,
                                        itemHeight: 18,
                                        itemTextColor: '#000',
                                        itemDirection: 'bottom-to-top',
                                        itemOpacity: 1,
                                        symbolSize: 18,
                                        symbolShape: 'circle',
                                        effects: [
                                          {
                                            on: 'hover',
                                            style: {
                                              itemTextColor: '#000'
                                            }
                                          }
                                        ]
                                      }
                                    ]
                              }
                              // tooltip={CustomTooltip}
                              tooltip={<R,>({ datum }: { datum: ComputedDatum<R> }) => (
                                <BasicTooltip
                                  id={datum.label}
                                  value={datum.formattedValue}
                                  enableChip={true}
                                  color={datum.color}
                                />
                              )}
                            />
                            {pieData[0].id == 'default_data' ? (
                              <div style={styles.overlay}>
                                <Heading
                                  as={'span'}
                                  size={'1.6rem'}
                                  color={'green.500'}
                                  fontWeight={600}
                                  zIndex={5}
                                >
                                  {selectedWeek == 0 ? `No selection` : `No Data`}
                                </Heading>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}
            </>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}

export default MinimumExpectedHarvestGraph;
