import axios, { AxiosInstance } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { User } from 'Context/types';
import crypto from 'crypto-js';
import { isArray, isEmpty } from 'lodash';
import { SubEvent } from 'sub-events';

export type TokenRefreshStatus = 'refreshing' | 'refreshed' | 'failed';

export type TokenRefreshEvent = {
  status: TokenRefreshStatus;
  data?: RefreshedTokenResponse;
  error?: string;
};
export type ToastEvent = {
  status: 'success' | 'error' | 'warning';
  message: string;
};

export type RefreshedTokenResponse = {
  id_token: string;
  refresh_token: string;
  access_token: string;
  user: User;
};

class HttpClient {
  private static instance: HttpClient;
  private axiosInstance?: AxiosInstance;
  private SECRET = process.env.REACT_APP_COOKIE_SECRET as string;
  private tenantHeader?: string;
  private static BASE_URL = `${process.env.REACT_APP_BE_URL}`;

  readonly onTokenRefresh: SubEvent<TokenRefreshEvent> = new SubEvent();
  readonly onToast: SubEvent<ToastEvent> = new SubEvent();

  private constructor() {}

  public static getInstance(): HttpClient {
    if (!HttpClient.instance) {
      HttpClient.instance = new HttpClient();
    }
    return HttpClient.instance;
  }

  private refreshAuthLogic(failedRequest: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const httpClient = HttpClient.getInstance();
      httpClient.onTokenRefresh.emit({ status: 'refreshing' });
      const encryptedRefreshToken = httpClient.getCookie('lima_auth_refresh');

      if (!encryptedRefreshToken) {
        httpClient.onTokenRefresh.emit({ status: 'failed', error: 'No refresh token' });
        reject('No refresh token');
      }
      const refreshToken = httpClient.decrypt(encryptedRefreshToken as string);
      if (!refreshToken) {
        httpClient.onTokenRefresh.emit({ status: 'failed', error: 'Invalid refresh token' });
        reject('Failed to decrypt refresh token');
      }
      httpClient.onToast.emit({
        status: 'warning',
        message: 'Session expired, attempting to refresh'
      });
      const response = await httpClient
        .getAxios()
        .post(`${HttpClient.BASE_URL}/auth/refresh-token-login`, {
          refresh_token: refreshToken
        })
        .catch((error) => {
          httpClient.onTokenRefresh.emit({ status: 'failed', error: error.message });
          reject('Failed to refresh token');
        });
      if (response && response.status === 200) {
        const resData: RefreshedTokenResponse = response.data;
        const { access_token, user } = resData;
        failedRequest.response.config.headers['Authorization'] = ('Bearer ' +
          access_token) as string;
        failedRequest.response.config.headers['Tenant-Header'] = user.tenant_header as string;
        httpClient.onTokenRefresh.emit({
          status: 'refreshed',
          data: resData
        });
        resolve(failedRequest);
      } else {
        httpClient.onTokenRefresh.emit({ status: 'failed', error: 'Failed to refresh token' });
        reject('Failed to refresh token: ' + response?.status);
      }
    });
  }

  private getCookie(cname: string): string | null {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  private decrypt(cypher: string): string | undefined {
    let result;
    try {
      const dec = crypto.AES.decrypt(cypher, this.SECRET);
      return dec.toString(crypto.enc.Utf8);
    } catch (error) {
      return result;
    }
  }

  public getAxios(): AxiosInstance {
    if (!this.axiosInstance) {
      this.axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BE_URL,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      createAuthRefreshInterceptor(this.axiosInstance, this.refreshAuthLogic, {
        pauseInstanceWhileRefreshing: true, // default: false
        statusCodes: [401] // default: [ 401 ]
      }); // https://github.com/Flyrell/axios-auth-refresh#available-options

      // response interceptor for handling common errors (e.g. HTTP 500)
      this.axiosInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => this.errorHandler(error.response)
      );
    }
    return this.axiosInstance;
  }

  private errorHandler(response: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const httpClient = HttpClient.getInstance();
      const errorCodes = [400, 403, 404, 429, 502, 422];
      // const successCodes = [201, 204];

      if (errorCodes.includes(response?.status)) {
        let errorMessage = '';
        if (!isArray(response.data.detail)) {
          // errror message is string
          errorMessage += response.data.detail;
        } else {
          // display first error of error array
          response.data.detail.forEach((detail: any) => {
            if (detail.loc[1] === 'refresh_token') {
              reject('skipping refresh token error');
            } else {
              errorMessage += `[${detail.loc[1]}]`;
              errorMessage += ' ' + detail.msg;
            }
          });
        }

        if (!isEmpty(errorMessage)) {
          httpClient.onToast.emit({ status: 'error', message: 'Error: ' + errorMessage });
          resolve(response);
        }
      } else {
        reject('Invalid toast');
      }
    });
  }
}

export default HttpClient;
