import * as yup from 'yup';
import { countriesAndProvinces } from 'data/countries';

const schema = yup.object().shape({
  orgName: yup
    .string()
    // name should not be empty
    .trim()
    // limit to 100 characters
    .max(100, 'Organization name cannot be more than 100 characters')
    // name should only contain letters, apostrophe and spaces
    .matches(/^[a-zA-Z\s']{1,}$/, 'Please enter valid name')
    .required('Organization name is required.'),
  cityAndCountry: yup
    .string()
    // should not be empty
    .trim()
    // city and country should be comma separated
    .test(
      'cityAndCountry',
      'Please enter your location in the format of "City, Country".\nFor example: Nanyuki, Kenya.',
      function (value) {
        if (!value) return true;
        const count = value?.match(/[,]/g)?.length;
        // should only contain one comma
        if (count && count > 1) return false;
        const invalid = [0, -1, value.length - 1];
        // comma cannot be non-existent, at beginning or end
        return !invalid.includes(value.indexOf(','));
      }
    )
    // country should exist and be valid
    .test(
      'cityAndCountry',
      'Please enter your location in the format of "City, Country".\nFor example: Nanyuki, Kenya.',
      function (value) {
        if (!value) return true;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [city, country] = value.split(',');
        // country must have value
        if (!country?.trim()) return false;
        // country must be valid
        const found = countriesAndProvinces.find(
          (el: any) => el.name.toLowerCase() === country.trim().toLowerCase()
        );
        return !!found;
      }
    )
    // city and country should not be the same
    .test('cityAndCountry', 'City and country should not be the same', function (value) {
      if (!value) return true;
      const [city, country] = value.split(',');
      if (!country?.trim() || !city?.trim()) return true;
      return city.trim().toLowerCase() !== country.trim().toLowerCase();
    })
    // limit to 100 characters
    .max(100, 'City and country cannot be more than 100 characters')
    .required('City and country is required.')
});

export default schema;
