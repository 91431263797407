import React, { useEffect, useState } from 'react';

import { Box, Flex, GridItem, Heading, Text, Link } from '@chakra-ui/react';
import { useAppContext, useAuthContext } from 'Context';
import styles from './Home.module.scss';
import { Block as BlockComponent, Table } from './Components';
import { Field, FieldProperties } from 'Context/types';
import { TableColumn } from 'Components/types';
import { Link as RouterLink } from 'react-router-dom';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import useDocumentTitle from 'Helpers/useDocumentTitle';
import moment from 'moment';

export default function FarmProduction() {
  const {
    sidebarExpanded,
    farm,
    selectedCycle,
    setSelectedCycle,
    updateHarvestScheduleDates,
    setBlockImages,
    fields,
    variety,
    limaActiveModules,
    varietyIsCyclic
  } = useAppContext();
  // Track page view
  useDocumentTitle('Farm Production | Lima Asili');
  const { trackPageView, pushInstruction } = useMatomo();

  // tracking organization dimension
  useEffect(() => {
    pushInstruction('deleteCustomDimension', 1);
    pushInstruction('setCustomDimension', 1, farm?.organization);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm?.organization]);

  useEffect(() => {
    pushInstruction('deleteCustomDimension', 2);
    //TODO:re-enable this
    //pushInstruction('setCustomDimension', 2, farm?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm]);

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    variables: { user },
    loadingStates
  } = useAuthContext();
  const { loadingBlocks } = loadingStates;
  const [data, setData] = useState<any[]>([]);
  const [stages, setStages] = useState<string[]>([]);
  const [showComplete, setShowComplete] = useState(false);
  const columns: TableColumn[] = [
    {
      name: 'Field',
      key: 'name',
      searchable: true,
      sortable: true,
      type: 'normal',
      hidden: false
    },
    {
      name: 'Forecast Harvest Date',
      key: 'forecast_summary.first_harvest_date',
      sortable: true,
      isDateTime: true,
      hasBadge: true,
      type: 'normal',
      hidden: false
    },
    {
      name: 'Week No.',
      key: 'forecast_summary.first_harvest_date_week',
      sortable: true,
      searchable: true,
      isDateTime: false,
      hasBadge: false,
      type: 'normal',
      hidden: false
    },
    {
      name: 'Forecast Yield',
      key: 'forecast_summary.yields',
      isNumeric: true,
      units: 'Stems',
      searchable: true,
      sortable: true,
      hasBadge: true,
      type: 'normal',
      hidden: false
    },
    // {
    //   name: 'Grade Split',
    //   key: 'grade_split',
    //   isNumeric: true,
    //   units: '%',
    //   searchable: true,
    //   sortable: true,
    //   hasBadge: true,
    //   type: 'grade-split',
    //   hidden: true
    // },
    {
      name: 'Growth Stage',
      key: 'stage',
      searchable: true,
      sortable: true,
      type: 'normal',
      hidden: !limaActiveModules.growth_stages || !varietyIsCyclic()
    },
    {
      name: 'Actions',
      key: 'actions',
      searchable: false,
      sortable: false,
      type: 'normal',
      hidden: false
    }
  ];

  useEffect(() => {
    const data: Field[] = [];
    const stages = new Set<string>();
    fields.forEach((block: Field) => {
      data.push({
        ...block,
        id: block.id,
        original_harvest_schedule: [],
        previous_harvest_schedule: [],
        forecast_summary: {
          ...block.forecast_summary,
          first_harvest_date_week:
            'Wk ' + moment(block.forecast_summary.first_harvest_date).isoWeek()
        }
      });
      stages.add(block?.stage);
    });
    setStages(Array.from(stages));
    if (selectedCycle?.field.id !== 'defaultblock') {
      if (data.length) {
        //--- The commented code below auto selects the first block and prepares it for harvest schedule auto-conversions
        // if you want first block autoselected enable this code
        // if (selectedCycle == null) {
        //   const jointBlockCycle = data[0] as JointBlockCycle;
        //   jointBlockCycle.original_harvest_schedule = jointBlockCycle.harvest_schedule;
        //   if (
        //     jointBlockCycle.harvest_schedule != undefined &&
        //     jointBlockCycle.harvest_schedule.length > 0 &&
        //     ((jointBlockCycle.first_date_harvest_farm_pred != null &&
        //       jointBlockCycle.last_date_harvest_farm_pred != null &&
        //       variety?.harvest_frequency == 'cyclic') ||
        //       variety?.harvest_frequency == 'continuous')
        //   ) {
        //     jointBlockCycle.original_harvest_schedule = jointBlockCycle.harvest_schedule;
        //     const harvest_schedule = updateHarvestScheduleDates(jointBlockCycle);
        //     if (harvest_schedule != undefined) {
        //       jointBlockCycle.harvest_schedule = harvest_schedule;
        //       jointBlockCycle.previous_harvest_schedule = harvest_schedule;
        //     }
        //   } else {
        //     jointBlockCycle.original_harvest_schedule = [];
        //   }
        //   setSelectedCycle(jointBlockCycle);
        //   setBlockImages(jointBlockCycle.images);
        // }

        //------ Clear the selected cycle when either farm or variety is changed
        // disable this section if you enable auto selection of the first block
        const defaultJointCycle = {} as FieldProperties;
        defaultJointCycle.field = {} as Field;
        defaultJointCycle.field.id = 'defaultblock';
        if (
          selectedCycle?.field.farm_id != farm?.id ||
          selectedCycle?.field.variety_id != variety?.id
        ) {
          setSelectedCycle(defaultJointCycle);
        }
      } else {
        if (!selectedCycle) setSelectedCycle(null);
      }
    }
    setData(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, selectedCycle, setSelectedCycle, setBlockImages, updateHarvestScheduleDates]);

  useEffect(() => {
    if (fields.length === 0 && !loadingBlocks) {
      setSelectedCycle(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, loadingBlocks]);

  return (
    <Box width="100%">
      <Box
        display="grid"
        gridColumnGap="6"
        className={`${styles.wrapper} ${sidebarExpanded ? styles.wrapper__thin : ''}`}
        height={'85vh'}
        gridTemplateColumns={'60% 40%'}
      >
        <GridItem
          width="100%"
          backgroundColor="white"
          margin={0}
          padding={6}
          borderRadius={8}
          boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
          alignSelf="start"
          height="85vh"
        >
          {/* TODO: re-enable this */}
          {variety?.production_categories ? (
            <Table
              columns={columns}
              data={[...data]}
              stages={stages}
              isSearchable
              title="Field Manager"
              subTitle={`${farm?.external_name}, ${farm?.city}`}
              setShowComplete={setShowComplete}
              grades={variety?.production_categories}
            />
          ) : (
            <>
              {!loadingBlocks && (
                <Box className={styles.table__header}>
                  <Flex justifyContent="space-between">
                    <div>
                      <Heading
                        as="h4"
                        className="table__header__title"
                        mb="2"
                        fontSize="1.35rem"
                        fontWeight="normal"
                      >
                        Field Manager
                      </Heading>
                      {farm && (
                        <Text
                          mb="2"
                          fontSize="0.9rem"
                          fontWeight="normal"
                          color="dark.coolGray.500"
                        >
                          {`${farm?.external_name}, ${farm?.city}`}
                        </Text>
                      )}
                    </div>
                  </Flex>
                  <Box marginTop={'2rem'}>
                    {farm && !variety && (
                      <Text>
                        This farm has no varieties.{' '}
                        {user?.role == 'Admin' && (
                          <Text as="span">
                            <Link
                              as={RouterLink}
                              to="/settings/varieties"
                              color="primary.500"
                              className="no_outline_button"
                            >
                              Add varieties
                            </Link>{' '}
                            to proceed.
                          </Text>
                        )}
                      </Text>
                    )}
                    {!farm && (
                      <Text>
                        This organization has no farms.{' '}
                        {user?.role == 'Admin' && (
                          <Text as="span">
                            <Link
                              as={RouterLink}
                              to="/settings/farms"
                              color="primary.500"
                              className="no_outline_button"
                            >
                              Add farms
                            </Link>{' '}
                            to proceed.
                          </Text>
                        )}
                      </Text>
                    )}
                  </Box>
                </Box>
              )}
            </>
          )}
        </GridItem>
        {selectedCycle && (
          <GridItem
            alignSelf="start"
            width="100%"
            margin={0}
            className={(sidebarExpanded ? 'content-thin' : 'content-wide') + ' custom-scrollbar'}
            overflowY="scroll"
            height="85vh"
            borderRadius="4px"
            p={1}
          >
            <BlockComponent data={selectedCycle} isComplete={showComplete} />
          </GridItem>
        )}
      </Box>
    </Box>
  );
}
