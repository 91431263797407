import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  FormControl,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  Img,
  Text,
  Link as CKLink,
  Spinner,
  InputRightElement
} from '@chakra-ui/react';
import { Field, Formik, FormikProps } from 'formik';
import { Mail, Lock, User, Eye, EyeOff } from 'react-feather';
import { InputWithIcon, Button, Checkbox, FormLabel } from 'Components';
import { colors } from 'theme';

import { useAuthContext } from 'Context';
import Logo from 'assets/images/lima-icon.svg';
import { RegisterSchema } from 'Validations';
import { RegisterFields } from 'Context/types';
import { NotificationToast } from 'Components/Cards';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import useDocumentTitle from 'Helpers/useDocumentTitle';

export default function Register(): JSX.Element {
  const { trackPageView } = useMatomo();
  useDocumentTitle('Register | Lima Asili');

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const initialValues: RegisterFields = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreeToTerms: false
  };

  //const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [isVisible, setVisible] = useState(false);
  const [cIsVisible, setCVisible] = useState(false);
  const {
    loadingStates,
    variables: { hasError, responseInfo, registerSuccess },
    functions: { register, setHasError }
  } = useAuthContext();
  const { isLoading } = loadingStates;

  const handleSubmit = async (values: RegisterFields) => {
    setEmail(values.email);
    register(values);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (hasError) {
      timeout = setTimeout(() => {
        setHasError(false);
      }, 5000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hasError, setHasError]);

  const onPasswordInput = (type: any, touched: any) => {
    if (type === 'confirm' && !touched.confirmPassword) setCVisible(false);
    else if (type !== 'confirm' && !touched.password) setVisible(false);
  };

  return (
    <Flex
      className="login"
      direction="column"
      height="100%"
      justifyContent="center"
      alignItems="center"
      mx="auto"
    >
      <Box width="400px">
        <Flex
          className="login__logo"
          width="80px"
          height="80px"
          justifyContent="center"
          alignItems="center"
          mb={6}
        >
          <Img src={Logo} />
        </Flex>
        <Heading as="h1" fontSize="4xl" mb={16} fontWeight={500} color="black.500">
          Welcome to Lima!
        </Heading>
        <Heading as="h1" fontSize="3xl" color="primary.500" fontWeight={500} mt={14} mb="10">
          Sign Up
        </Heading>
        {hasError && <NotificationToast type="error" message={responseInfo} />}
        {registerSuccess ? (
          <Box mb={4} color="info.500" h="50vh" fontSize="0.9rem" fontWeight="normal">
            <Text data-cy="check_email" fontSize={20}>
              Please check your e-mail.
            </Text>
            <Text color="gray.500" fontSize={18}>
              We have sent a code to{' '}
              <Text as="b" fontSize={18} data-cy="confirmation_email">
                {' '}
                {email}
              </Text>
              .
            </Text>
          </Box>
        ) : (
          <>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={RegisterSchema}
              validateOnBlur={true}
            >
              {({
                isValid,
                handleSubmit,
                errors,
                touched,
                dirty,
                values: { agreeToTerms }
              }: FormikProps<RegisterFields>) => (
                <form className="login__form form" onSubmit={handleSubmit}>
                  <FormControl mb={6} isInvalid={!!errors.name && touched.name}>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <User color={colors.input.placeholder} />
                      </InputLeftElement>
                      <Field
                        as={InputWithIcon}
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Jonathan Doe"
                      />
                    </InputGroup>
                    {errors && errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                  </FormControl>
                  <FormControl mb={6} isInvalid={!!errors.email && touched.email}>
                    <FormLabel htmlFor="email">Email address</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <Mail color={colors.input.placeholder} />
                      </InputLeftElement>
                      <Field
                        as={InputWithIcon}
                        id="email"
                        type="email"
                        name="email"
                        placeholder="user@email.com"
                      />
                    </InputGroup>
                    {errors && errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                  </FormControl>
                  <FormControl mb={6} isInvalid={!!errors.password && touched.password}>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <Lock color={colors.input.placeholder} />
                      </InputLeftElement>
                      <Field
                        as={InputWithIcon}
                        id="password"
                        name="password"
                        type={!isVisible ? 'password' : 'text'}
                        placeholder="At least 8 characters"
                        onInput={() => onPasswordInput('', touched)}
                      />
                      <InputRightElement onClick={() => setVisible(!isVisible)}>
                        {isVisible ? (
                          <Eye color={colors.input.placeholder} strokeWidth={1.5} />
                        ) : (
                          <EyeOff color={colors.input.placeholder} strokeWidth={1.5} />
                        )}
                      </InputRightElement>
                    </InputGroup>
                    {errors && errors.password && (
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    mb={6}
                    isInvalid={!!errors.confirmPassword && touched.confirmPassword}
                  >
                    <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <Lock color={colors.input.placeholder} />
                      </InputLeftElement>
                      <Field
                        as={InputWithIcon}
                        id="confirmPassword"
                        name="confirmPassword"
                        type={!cIsVisible ? 'password' : 'text'}
                        placeholder="Should match the password above"
                        onInput={() => onPasswordInput('confirm', touched)}
                      />
                      <InputRightElement onClick={() => setCVisible(!cIsVisible)}>
                        {cIsVisible ? (
                          <Eye color={colors.input.placeholder} strokeWidth={1.5} />
                        ) : (
                          <EyeOff color={colors.input.placeholder} strokeWidth={1.5} />
                        )}
                      </InputRightElement>
                    </InputGroup>
                    {errors && errors.confirmPassword && (
                      <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl mb={6} isInvalid={!!errors.agreeToTerms && touched.agreeToTerms}>
                    <Field
                      data-cy="agree_to_terms"
                      id="agree_to_terms"
                      as={Checkbox}
                      colorScheme="primary"
                      outline="none"
                      shadow="0"
                      name="agreeToTerms"
                      className="where-be-me"
                      _checked={{
                        boxShadow: 'none',
                        svg: {
                          stroke: 'white !important'
                        }
                      }}
                    >
                      I agree to the{' '}
                      <CKLink as={RouterLink} to="/terms-and-conditions" color="primary.500">
                        Terms &amp; Conditions
                      </CKLink>
                    </Field>
                    {errors && errors.agreeToTerms && (
                      <FormErrorMessage>{errors.agreeToTerms}</FormErrorMessage>
                    )}
                  </FormControl>
                  <Box>
                    <Button
                      width="100%"
                      theme="primary"
                      disabled={!isValid || !dirty || !agreeToTerms || isLoading}
                      type="submit"
                    >
                      Get Started
                      {isLoading && (
                        <>
                          <Box px={1.5}></Box>
                          <Spinner color="white.500" size="sm" />
                        </>
                      )}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
            <Box pt={8}>
              <Text as="p" color="dark.coolGray.400">
                Already a member?{' '}
                <CKLink as={RouterLink} to="/login" color="primary.500" state={{ auth: '/login' }}>
                  Log in
                </CKLink>
              </Text>
            </Box>
          </>
        )}
      </Box>
    </Flex>
  );
}
