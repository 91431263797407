import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Flex,
  FlexProps,
  GridProps,
  Heading,
  Icon,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  useDisclosure,
  Text,
  Spinner,
  Th,
  Thead,
  Tr,
  Table as CKTable,
  Tbody,
  Td
} from '@chakra-ui/react';
import { TableColumn } from 'Components/types';

import styles from './Table.module.scss';
import { Calendar, MoreVertical, Search } from 'react-feather';
import { colors } from 'theme';
import { Button, InputWithIcon } from 'Components';
import SortIcon from './SortIcon';
import { useAppContext, useAuthContext } from 'Context';
import { debounce } from 'lodash';
import { FilterDropDown } from 'Components/Dropdown';
import {
  GradingItem,
  HarvestSchedule,
  Field,
  ProductionCategory,
  FieldProperties,
  ForecastProductionGradeItem,
  ForecastSummary
} from 'Context/types';
import moment from 'moment';
import AddNewBlockModal from './AddNewBlockModal';
import FieldOptionsPromptModal, { FieldOptionType } from './FieldOptionsPromptModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { userBlocksRepository } from 'Helpers/appDependenciesHelpers';

type TableProps = FlexProps & {
  title?: string;
  subTitle?: string;
  isSearchable?: boolean;
  stages: string[];
  searchableColumns?: string[];
  data: any[];
  columns: TableColumn[];
  rowStyles?: GridProps;
  grades: ProductionCategory[];
};

type OrderType = 'asc' | 'desc' | null;
type SortableColumn = { column: string; order: OrderType; type: 'string' | 'number' | 'date' };

export default function Table({
  title,
  subTitle,
  isSearchable,
  stages,
  data,
  columns,
  setShowComplete,
  grades
}: TableProps & { setShowComplete: (val: boolean) => void }): JSX.Element {
  const [isShowingData, setIsShowingData] = useState(false); // this prevents columns from re-rendering everything when data is loaded
  const [localColumns] = useState<TableColumn[]>(columns);
  const [sortable, setSortable] = useState<SortableColumn[]>([]);
  const [canSort, setCanSort] = useState(false);
  const [searchable, setSearchable] = useState<string[]>([]);
  const [filters, setFilters] = useState<
    {
      filter: 'stage' | 'first_harvest_date' | 'status';
      value: any | any[];
      isDate?: boolean;
    }[]
  >([]);
  const [displayData, setDisplayData] = useState<Field[]>([]);
  const [hasMoreDisplayData, setHasMoreDisplayData] = useState(false);
  const [searchedData, setSearchedData] = useState<Field[]>([]);
  const [activeData, setActiveData] = useState<Field[]>([]); //this is the full range of active data
  const [isSorting, setIsSorting] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  //const deferredSearchQuery = useDeferredValue(searchQuery); TODO: re-enable this when tests are fixed
  const [currentFieldPromptType, setCurrentFieldPromptType] = useState<FieldOptionType>();
  const [currentField, setCurrentField] = useState<Field>();
  const [currColumnOrderKey, setCurrColumnOrderKey] = useState<string>(
    'forecast_summary.first_harvest_date'
  );
  const [blockSelected, setBlockSelected] = useState<boolean>(false);
  const {
    selectedCycle,
    setSelectedCycle,
    updateHarvestScheduleDates,
    newBlockAdded,
    blockCycleUpdated,
    variety,
    limaActiveModules,
    farm,
    setFieldStatus,
    fieldStatus,
    varietyIsCyclic
  } = useAppContext();
  const {
    functions: { getToken, getUserBlocksAndFarms },
    loadingStates
  } = useAuthContext();
  const { loadingBlocks } = loadingStates;
  const setColumnOrder = useCallback(
    (key: string) => {
      return (order: OrderType) => {
        const orders = Array.from(sortable);
        let canSort = false;
        setSortable(
          orders.map((col) => {
            // TDO: Continue sorting task by figuring out what this line does
            const newOrder = col.column === key ? order : null;
            canSort = !!order;
            return { ...col, order: newOrder };
          })
        );
        setCanSort(canSort);
        setIsSorting(true);
        setCurrColumnOrderKey(key);
      };
    },
    [sortable]
  );
  const updateSortableAndSearchableColumns = useCallback(() => {
    if (!isShowingData) {
      const sortableColumns: SortableColumn[] = [];
      const searchableColumns: string[] = [];
      localColumns.forEach((col) => {
        if (col.sortable) {
          if (col.key === 'forecast_summary.first_harvest_date') {
            sortableColumns.push({ column: col.key, order: 'asc', type: col.type });
          } else {
            sortableColumns.push({ column: col.key, order: null, type: col.type });
          }
        }
        if (col.searchable) {
          searchableColumns.push(col.key);
        }
      });
      setSortable(sortableColumns);
      setSearchable(searchableColumns);
    }
  }, [localColumns, isShowingData]); // change this at your own risk, LOL
  useEffect(() => {
    updateSortableAndSearchableColumns();
  }, [updateSortableAndSearchableColumns, data]); // data is important here to trigger this effect when data is loaded

  const searchDebounce = useMemo(() => {
    return debounce(setSearchQuery, 0);
  }, []);

  useEffect(() => {
    return () => {
      searchDebounce.cancel();
    };
  });

  useEffect(() => {
    if (!loadingBlocks && !blockSelected) {
      // update displayData with new data
      const _data = Array.from(data);
      setActiveData(_data);
      const _first20Items = _data.slice(0, 20);
      setDisplayData(_first20Items);
    } else if (!blockSelected) {
      setDisplayData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variety, data, loadingBlocks]);

  const filterByHarvestDateItems = [
    'This week',
    'Next 3 Days',
    'Next 7 Days',
    'Next 30 Days',
    'All time'
  ];

  const filterByHarvestDateValues = [
    [moment().startOf('week'), moment().endOf('week')],
    [moment().startOf('day'), moment().add(3, 'day').endOf('day')],
    [moment().startOf('day'), moment().add(7, 'day').endOf('day')],
    [moment().startOf('day'), moment().add(30, 'day').endOf('day')]
  ];

  const filterByStatusItems = ['All', 'Active', 'Uprooted'];

  const filterByStatusValues = ['all', 'active', 'uprooted'];

  const onSelectFilter = (name: 'stage' | 'first_harvest_date' | 'status') => {
    return (selection: number | number[]) => {
      if (filters.length) {
        const nf: {
          filter: 'stage' | 'first_harvest_date' | 'status';
          value: any | any[];
          isDate?: boolean;
        }[] = Array.from(filters).filter(({ filter }) => filter !== name);

        if (name === 'first_harvest_date') {
          if (filterByHarvestDateValues[selection as number]) {
            nf.push({
              filter: name,
              value: filterByHarvestDateValues[selection as number],
              isDate: true
            });
          }
        } else if (name === 'stage') {
          if (Array.isArray(selection) && selection.length) {
            nf.push({ filter: name, value: selection.map((i: number) => stages[i]) });
          }
        } else if (name === 'status') {
          if (filterByStatusValues[selection as number]) {
            nf.push({
              filter: name,
              value: filterByStatusValues[selection as number],
              isDate: false
            });
          }
        }
        setFilters(nf);
      } else {
        if (name === 'first_harvest_date') {
          setFilters([
            { filter: name, isDate: true, value: filterByHarvestDateValues[selection as number] }
          ]);
        } else if (name === 'stage') {
          setFilters([
            { filter: name, value: (selection as number[]).map((i: number) => stages[i]) }
          ]);
        } else if (name === 'status') {
          // setFilters([
          //   {
          //     filter: name,
          //     value: (selection as number[]).map((i: number) => filterByStatusValues[i])
          //   }
          // ]);
          setFieldStatus(filterByStatusValues[selection as number]);
        }
      }
    };
  };

  const getFieldProperties = async (field_id: string): Promise<FieldProperties> => {
    const token = getToken();
    let selected_field = {} as FieldProperties;
    if (token && farm && variety) {
      // setLoadingStates({ ...loadingStates, loadingBlocks: true });
      if (!selectedCycle) setSelectedCycle(null);
      const getFieldPropertiesResponse: any = await userBlocksRepository.getFieldProperties(
        token,
        farm?.organization.internal_name ? farm?.organization.internal_name : '',
        field_id
      );
      if (getFieldPropertiesResponse?.res?.data) {
        selected_field = getFieldPropertiesResponse.res.data;
      }
      // setLoadingStates({ ...loadingStates, isLoading: false });
      setTimeout(() => {
        // setLoadingStates({ ...loadingStates, loadingBlocks: false });
      }, 500);
    }
    return { ...selected_field };
  };

  const onSelectBlock = async (field: Field) => {
    // retrieve field properties from api
    const retrieved_field_properties = await getFieldProperties(field.id);
    const selectedFieldProperties = {
      ...retrieved_field_properties,
      field: { ...field },
      dieback_entry: {
        ...retrieved_field_properties?.dieback_entry,
        dieback_value: retrieved_field_properties?.dieback_entry?.dieback_value * 100 ?? 5 //when dieback is not provided use 0.05 as default
      },
      loss_entry: {
        ...retrieved_field_properties?.loss_entry,
        loss_value: retrieved_field_properties?.loss_entry?.loss_value * 100 ?? 5 //when dieback is not provided use 0.05 as default
      }
    };
    // build harvest schedule(date and daily stems) from forecast productions
    let first_harvest_date = moment(selectedFieldProperties.first_harvest_date, 'YYYY-MM-DD');
    let last_harvest_date = moment(selectedFieldProperties.last_harvest_date, 'YYYY-MM-DD');

    if (!varietyIsCyclic()) {
      first_harvest_date = moment(
        selectedFieldProperties?.forecast_productions[0]?.entry_date,
        'YYYY-MM-DD'
      );

      last_harvest_date = moment(
        selectedFieldProperties?.forecast_productions[
          selectedFieldProperties?.forecast_productions.length - 1
        ]?.entry_date,
        'YYYY-MM-DD'
      );
    }
    const field_properties_harvest_schedule = [] as HarvestSchedule[];
    const field_yield_pred = selectedFieldProperties?.forecast_productions?.reduce(function (
      sum,
      obj
    ) {
      return sum + obj.yield_value;
    },
    0);
    if (first_harvest_date && last_harvest_date) {
      for (
        let curr_day = first_harvest_date;
        !curr_day.isAfter(last_harvest_date);
        curr_day.add(1, 'days')
      ) {
        const daily_forecast_productions = selectedFieldProperties?.forecast_productions.filter(
          ({ entry_date }) => entry_date.substring(0, 10) === curr_day.format('YYYY-MM-DD')
        );

        const stems = daily_forecast_productions.reduce(function (sum, obj) {
          return sum + obj.yield_value;
        }, 0);

        // create a forecast production grade item, useful to have the production category id along with all the data within forecast production for grading
        const daily_forecast_production_grade_items: ForecastProductionGradeItem[] = [];
        variety?.production_categories.forEach((production_category) => {
          const forecast_production_grade_item = daily_forecast_productions.find(
            (forecast_production_grade_item) =>
              forecast_production_grade_item.production_category_id == production_category.id
          );
          if (forecast_production_grade_item) {
            const forecast_production_grade_item_yield_value =
              (forecast_production_grade_item.yield_value / stems) * 100;
            daily_forecast_production_grade_items.push({
              [production_category.id]: {
                ...forecast_production_grade_item,
                yield_value: forecast_production_grade_item_yield_value
              }
            });
          } else {
            //filling up the blank grades for easy retrieval during harvest schedule recalculations
            daily_forecast_production_grade_items.push({
              [production_category.id]: {
                field_id: field.id,
                production_category_id: production_category.id,
                entry_date: daily_forecast_productions[0].entry_date,
                yield_value: 0
              }
            });
          }
        });

        // add to harvest schedule list
        const harvest_schedule_item = {
          day: curr_day.format('YYYY-MM-DD'),
          // compute yield pred daily stems
          stems: stems / field_yield_pred,
          stems_no_round: stems,
          grading: daily_forecast_production_grade_items,
          // TODO: retrieve the below from v2 api or derive them from formula
          stem_density: selectedFieldProperties.stem_density_entry?.stem_density_value,
          dieback: selectedFieldProperties?.dieback_entry?.dieback_value ?? 5,
          loss: selectedFieldProperties?.loss_entry?.loss_value ?? 5,
          area: selectedFieldProperties?.area_entry?.area_value
        } as HarvestSchedule;
        field_properties_harvest_schedule.push(harvest_schedule_item);
      }
    }
    // ----end build build harvest schedule from forecast productions
    // ---- build grading for field
    let field_grading = [] as GradingItem[];
    variety?.production_categories.forEach((production_category) => {
      const grade_total = selectedFieldProperties.forecast_productions.reduce(function (sum, obj) {
        if (production_category.id === obj.production_category_id) {
          return sum + obj.yield_value;
        } else {
          return sum;
        }
      }, 0);
      field_grading.push({
        [production_category.id]: +((grade_total / field_yield_pred) * 100).toFixed(2)
      });
    });
    // ---- end build grading for field

    // convert percentage value from api to a no. of stems for harvest schedule
    if (selectedFieldProperties?.field?.id != selectedCycle?.field?.id) {
      if (
        field_properties_harvest_schedule != undefined &&
        field_properties_harvest_schedule.length > 0 &&
        ((selectedFieldProperties?.first_harvest_date != null &&
          selectedFieldProperties?.last_harvest_date != null &&
          varietyIsCyclic()) ||
          !varietyIsCyclic())
      ) {
        selectedFieldProperties.original_harvest_schedule = field_properties_harvest_schedule;
        const harvest_schedule = updateHarvestScheduleDates({
          ...selectedFieldProperties,
          field: {
            ...field,
            forecast_summary: {
              first_harvest_date: selectedFieldProperties.first_harvest_date,
              last_harvest_date: selectedFieldProperties.last_harvest_date,
              yields: field_yield_pred
            },
            grading: field_grading
          },
          harvest_schedule: field_properties_harvest_schedule
        });

        // console.log('harvest_schedule', field_properties_harvest_schedule, harvest_schedule);
        selectedFieldProperties.harvest_schedule = harvest_schedule as HarvestSchedule[];
        selectedFieldProperties.previous_harvest_schedule = harvest_schedule as HarvestSchedule[];
      } else {
        selectedFieldProperties.original_harvest_schedule = [];
      }
      // set grading items to undefined when null
      if (variety != null) {
        const newGrades: GradingItem[] = [];
        grades.forEach((grade, index) => {
          if (
            field_grading == null ||
            field_grading[index] == null ||
            field_grading[index]?.[grade.id] == null
          ) {
            // cycle conditions has does not have this grade
            newGrades.push({ [grade.id]: 0 });
          } else {
            // harvest schedule item already has its own grade
            newGrades.push({ ...field_grading[index] });
          }
        });
        field_grading = newGrades;

        selectedFieldProperties.field.grading = newGrades;
      }

      // TODO: Enable this call when images are brought back to asili
      // // get presigned urls
      // if (limaActiveModules.images && farm?.with_lima_forecasting) {
      //   setBlockImages(selectedFieldProperties.images);
      //   // get presigned urls if current farm uses lima forecasting
      //   getPresignedUrls(getToken(), selectedFieldProperties.id);
      // }
      setSelectedCycle({
        ...selectedFieldProperties,
        field: {
          ...field,
          forecast_summary: {
            first_harvest_date: selectedFieldProperties.first_harvest_date,
            last_harvest_date: selectedFieldProperties.last_harvest_date,
            yields: field_yield_pred
          },
          grading: field_grading
        }
      });
      setBlockSelected(true);
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (blockSelected) {
      timeout = setTimeout(() => {
        setBlockSelected(false);
      }, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [blockSelected]);

  const resetColumnSorting = useCallback(() => {
    const timeout = setTimeout(() => {
      setIsSorting(true);
      setCanSort(true);
      setColumnOrder(currColumnOrderKey);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [currColumnOrderKey, setColumnOrder]);

  useEffect(() => {
    if (filters.length) {
      const filtered = Array.from(data).filter((item) => {
        let isInAll = true;
        for (let i = 0; i < filters.length; i++) {
          const { filter, value, isDate } = filters[i];
          if (filter && value !== undefined) {
            if (isDate) {
              const date = moment(item.forecast_summary[filter as keyof Field]);
              if (value.length) {
                const withinRange = date.isSameOrAfter(value[0]) && date.isSameOrBefore(value[1]);
                isInAll = isInAll && withinRange;
              }
            } else {
              if (value !== '' && value !== undefined) {
                const val = item[filter as keyof Field];
                if (Array.isArray(value)) {
                  isInAll = isInAll && value.includes(val);
                } else {
                  isInAll = isInAll && value === val;
                }
              }
            }
          }
        }
        return isInAll;
      });
      resetColumnSorting();
      const first20Items = Array.from(filtered).slice(0, 20);
      setDisplayData(first20Items);
      setActiveData(filtered);
    } else {
      const first20Items = Array.from(data).slice(0, 20);
      setDisplayData(first20Items);
      setActiveData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]); // previously set dependencies caused a re-render hence calling reset at line 257 because a filter is active, this use effect needs to run only when filters are triggered

  const loadMoreData = () => {
    if (activeData.length > displayData?.length) {
      const next20Items = Array.from(activeData).slice(
        displayData?.length,
        displayData?.length + 20
      );
      setDisplayData([...displayData, ...next20Items]);
    } else if (activeData.length === displayData?.length) {
      setHasMoreDisplayData(false);
    }
  };

  useEffect(() => {
    if (displayData?.length >= activeData.length || displayData?.length === 0) {
      setHasMoreDisplayData(false);
    } else {
      setHasMoreDisplayData(true);
    }
  }, [displayData?.length, activeData.length]);

  useEffect(() => {
    resetColumnSorting();
    if (searchQuery) {
      const searched = data.filter((item) => {
        for (let i = 0; i < searchable.length; i++) {
          if (
            String(item[searchable[i] as keyof Field])
              .toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
      setSearchedData(searched);
      const first20Items = Array.from(searched).slice(0, 20);
      setDisplayData(first20Items);
      setActiveData(searched);
    } else {
      setSearchedData(data);
      const first20Items = Array.from(data).slice(0, 20);
      setDisplayData(first20Items);
      setActiveData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, searchable]);

  useEffect(() => {
    if (isSorting && canSort) {
      const sorted = Array.from(activeData).sort((a: Field, b: Field) => {
        const sortableObj: { [key: string]: string } = {};
        const sortableObjTypes: { [key: string]: string } = {};
        sortable.forEach((field) => {
          if (field.order) {
            sortableObj[field.column] = field.order;
            sortableObjTypes[field.column] = field.type;
          }
        });
        let ltGtEq;
        for (const key in sortableObj) {
          const val1 = key.includes('forecast_summary')
            ? a.forecast_summary[
                key.substring(key.lastIndexOf('.') + 1, key.length) as keyof ForecastSummary
              ]
            : a[key as keyof Field];
          const value1 =
            sortableObjTypes[key] === 'string'
              ? (val1 as string).toLowerCase()
              : sortableObjTypes[key] === 'date'
              ? new Date(val1 as string).valueOf()
              : (val1 as string);

          const val2 = key.includes('forecast_summary')
            ? b.forecast_summary[
                key.substring(key.lastIndexOf('.') + 1, key.length) as keyof ForecastSummary
              ]
            : b[key as keyof Field];

          const value2 =
            sortableObjTypes[key] === 'string'
              ? (b[key as keyof Field] as string).toLowerCase()
              : sortableObjTypes[key] === 'date'
              ? new Date(b.forecast_summary[key as keyof ForecastSummary] as string).valueOf()
              : (val2 as string);

          if (value1 > value2) {
            if (sortableObj[key] === 'asc') {
              ltGtEq = ltGtEq !== undefined ? ltGtEq && true : true;
            } else {
              ltGtEq = false;
            }
          } else if (value1 < value2) {
            if (sortableObj[key] === 'asc') {
              ltGtEq = false;
            } else {
              ltGtEq = ltGtEq !== undefined ? ltGtEq && true : true;
            }
          }
        }

        if (ltGtEq) {
          return 1;
        } else if (ltGtEq === false) {
          return -1;
        } else {
          return 0;
        }
      });
      setActiveData(sorted);
      const first20Items = Array.from(sorted).slice(0, 20);
      setDisplayData(first20Items);
    } else if (!canSort) {
      setActiveData(searchedData);
    }
    setIsSorting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSort, sortable, isSorting, searchedData]);

  const formatColumnValue = (
    value: string | number,
    isNumeric: boolean,
    isDate: boolean,
    columnName: string
  ) => {
    if (isNumeric) {
      return new Intl.NumberFormat('en', { maximumSignificantDigits: 5 }).format(
        parseFloat(value + '')
      );
    }
    // check if is date and is string
    if (isDate && typeof value === 'string') {
      // format date in this format 20-Aug-2022
      return moment(value).format('DD-MMM-YYYY');
    }
    return value;
  };

  useEffect(() => {
    if (newBlockAdded) {
      getUserBlocksAndFarms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBlockAdded]);

  useEffect(() => {
    if (newBlockAdded) {
      resetColumnSorting();
    }
    if (blockCycleUpdated) {
      resetColumnSorting();
    }
  }, [newBlockAdded, blockCycleUpdated, resetColumnSorting]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const getPadding = function (key: any) {
    return key == 'grade_split' ? '0px' : '16px';
  };

  const checkCyclic = function (header: any) {
    if (header.key == 'stage') return varietyIsCyclic();
    else return true;
  };

  // fix for the issue of not showing the data when the farm is changed
  useEffect(() => {
    if (
      farm &&
      selectedCycle &&
      farm.id !== selectedCycle.field.farm_id &&
      selectedCycle.field.id !== 'defaultblock'
    ) {
      const item = displayData.length ? displayData[0] : null;
      if (item !== null) {
        setIsShowingData(true);
        setShowComplete(false);
        // onSelectBlock(item);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm, displayData]);

  return (
    <Box className={styles.table} height={'100%'} overflowY={'hidden'} paddingBottom={'120px'}>
      {/* Start head */}
      {/* Title */}
      <Box className={styles.table__header}>
        <Flex justifyContent="space-between">
          <div>
            {title && (
              <Heading
                as="h4"
                className="table__header__title"
                mb="2"
                fontSize="1.35rem"
                fontWeight="normal"
                data-cy="report-heading"
              >
                {title}
              </Heading>
            )}
            {/* Sub title */}
            {subTitle && (
              <Text mb="2" fontSize="0.9rem" fontWeight="normal" color="dark.coolGray.500">
                {subTitle}
              </Text>
            )}
          </div>
          <AddNewBlockModal />
          <FieldOptionsPromptModal
            isOpen={isOpen}
            onClose={onClose}
            type={currentFieldPromptType}
            field={currentField}
          />
        </Flex>
        {/* FIlter Row */}
        <Flex justifyContent="space-between" pointerEvents={data.length == 0 ? 'none' : 'auto'}>
          {isSearchable ? (
            <>
              {loadingBlocks ? (
                <Skeleton height={25} width={200} />
              ) : (
                <InputGroup h={8} alignItems="center" width="max-content" mt={3}>
                  <InputLeftElement pointerEvents="none" ms="0.5" height="100%">
                    <Search color={colors.input.placeholder} size="16" />
                  </InputLeftElement>
                  <InputWithIcon
                    id="search"
                    data-cy="report-search"
                    type="text"
                    name="search"
                    placeholder="Search..."
                    pl={8}
                    py={2}
                    fontSize="0.929rem"
                    h={'100%'}
                    onChange={({ target: { value } }) => searchDebounce(value)}
                  />
                </InputGroup>
              )}
            </>
          ) : (
            <div></div>
          )}
          {/* Field Status Filter */}
          <Flex mt={3} zIndex={'11'}>
            {loadingBlocks ? (
              <Skeleton height={25} width={200} />
            ) : (
              <FilterDropDown
                label="Fields"
                items={filterByStatusItems}
                onSelectFilter={onSelectFilter('status')}
                name=""
                selectedItem={filterByStatusValues.indexOf(fieldStatus)}
                LeftIcon={Calendar}
              />
            )}
          </Flex>

          {varietyIsCyclic() ? (
            <Flex mt={3} zIndex={'11'}>
              {loadingBlocks ? (
                <Skeleton height={25} width={200} />
              ) : (
                <FilterDropDown
                  label="Forecast Harvest Date"
                  items={filterByHarvestDateItems}
                  onSelectFilter={onSelectFilter('first_harvest_date')}
                  name=""
                  LeftIcon={Calendar}
                />
              )}
              {limaActiveModules.growth_stages && (
                <>
                  <Spacer flexDirection="row" px={3} />
                  {loadingBlocks ? (
                    <Skeleton height={25} width={200} />
                  ) : (
                    <FilterDropDown
                      label="Growth Stage"
                      items={stages}
                      onSelectFilter={onSelectFilter('stage')}
                      multi
                      name=""
                      LeftIcon={Calendar}
                    />
                  )}
                </>
              )}
            </Flex>
          ) : (
            <></>
          )}
        </Flex>
        {/* End head */}
      </Box>
      {loadingBlocks ? (
        <Skeleton height={25} width={'100%'} />
      ) : (
        <>
          {displayData?.length !== 0 ? (
            <Box
              className={`${styles.table__content__body} custom-scrollbar `}
              overflowY="auto"
              height="100%"
              mt={8}
              id="myContainer"
              pb={6}
              minHeight={'40vh'}
            >
              <InfiniteScroll
                dataLength={displayData?.length}
                next={loadMoreData}
                hasMore={hasMoreDisplayData}
                loader={
                  <Flex
                    mt={4}
                    overflow="hidden"
                    flexDir="column"
                    w="100%"
                    align="center"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Flex alignSelf="center" pt={2} pb={2}>
                      <Spinner />
                      <Text ml={3}>Loading more data...</Text>
                    </Flex>
                  </Flex>
                }
                scrollableTarget="myContainer"
                endMessage={
                  <Text align="center" pt={2} pb={2}>
                    All data is loaded
                  </Text>
                }
              >
                <CKTable
                  className={`${styles.table__content}`}
                  size="sm"
                  top={-0.5}
                  data-cy="status-report"
                >
                  <Thead className={styles.table__content__head} zIndex="docked">
                    <Tr
                      className={`${styles.table__content__row} ${styles.table__content__head__row}`}
                      width={'100%'}
                    >
                      {localColumns.map((header) => {
                        if (header.type === 'normal' && checkCyclic(header) && !header.hidden) {
                          return (
                            <Th key={header.name}>
                              <Flex alignItems="center" height="100%">
                                <Text textTransform="capitalize">{header.name}</Text>
                                {header.sortable && (
                                  <SortIcon
                                    data-cy={`sort_${header.key}`}
                                    isNumeric={!!header.isNumeric || !!header.isDateTime}
                                    sort={setColumnOrder(header.key)}
                                    disabled={isSorting}
                                    isSorting={
                                      !!sortable.find(
                                        (col) => col.column === header.key && col.order
                                      )
                                    }
                                  />
                                )}
                              </Flex>
                            </Th>
                          );
                        }
                      })}
                    </Tr>
                  </Thead>
                  <Tbody className={`${styles.table__content__body}`}>
                    <>
                      {displayData?.map((item: Field) => (
                        <Tr
                          key={item.id + item.name}
                          className={`${styles.table__content__row} ${styles.table__content__body__row}`}
                          data-cy="status-rows"
                        >
                          {localColumns.map((column) => {
                            if (!column.hidden && checkCyclic(column)) {
                              return (
                                <Td
                                  alignItems="center"
                                  height="100%"
                                  key={item.id + column.key}
                                  data-cy={column.key}
                                  paddingLeft={getPadding(column.key)}
                                  paddingRight={getPadding(column.key)}
                                >
                                  <span
                                    className="value table-content"
                                    color="black.lighter.400 !important"
                                  >
                                    {/* grade split */}
                                    {column.key == 'grade_split' &&
                                    column?.units != null &&
                                    !column?.hidden ? (
                                      <></>
                                    ) : // day and stems
                                    item[column.key as keyof Field] != null ? (
                                      formatColumnValue(
                                        item[column.key as keyof Field] as string | number,
                                        !!column.isNumeric,
                                        column.key === 'forecast_summary.first_harvest_date',
                                        column.name
                                      )
                                    ) : item[column.key as keyof Field] == null &&
                                      column.key === 'actions' ? (
                                      ''
                                    ) : column.key.includes('forecast_summary') ? ( // retrieve key from forecast summary type and use to formate data
                                      formatColumnValue(
                                        item.forecast_summary[
                                          column.key.substring(
                                            column.key.lastIndexOf('.') + 1,
                                            column.key.length
                                          ) as keyof ForecastSummary
                                        ] as string | number,
                                        !!column.isNumeric,
                                        column.key === 'forecast_summary.first_harvest_date',
                                        column.name
                                      )
                                    ) : (
                                      '-'
                                    )}{' '}
                                    {item[column.key as keyof Field] != null
                                      ? column.key != 'grade_split'
                                        ? column.units
                                        : null
                                      : null}
                                  </span>
                                  {/* TODO: Re-enable code if badges feature is needed */}
                                  {/* {column.hasBadge && false && (
                                    <>
                                      {column.key === 'yield_pred' && item.yield_badge ? (
                                        <span
                                          className={`${styles.table__content__row__badge} ${
                                            item.yield_badge > 0
                                              ? styles.table__content__row__badge__up
                                              : ''
                                          }`}
                                        >
                                          <Icon
                                            as={ArrowDown}
                                            strokeWidth="1px"
                                            __css={{
                                              line: {
                                                fill: 'black.500'
                                              },
                                              polyline: {
                                                stroke: 'black.500'
                                              }
                                            }}
                                          />
                                          {Math.abs(item.yield_badge) + '%'}
                                        </span>
                                      ) : null}
                                      {column.key === 'stem_density_farm_pred' &&
                                      item.stem_density_badge ? (
                                        <span
                                          className={`${styles.table__content__row__badge} ${
                                            item.stem_density_badge > 0
                                              ? styles.table__content__row__badge__up
                                              : ''
                                          }`}
                                        >
                                          <Icon
                                            as={ArrowDown}
                                            strokeWidth="1px"
                                            __css={{
                                              line: {
                                                fill: 'black.500'
                                              },
                                              polyline: {
                                                stroke: 'black.500'
                                              }
                                            }}
                                          />
                                          {Math.abs(item.stem_density_badge) + '%'}
                                        </span>
                                      ) : null}
                                      {column.key === 'forecast_summary.first_harvest_date' &&
                                      item.harvest_date_badge ? (
                                        <span
                                          className={`${styles.table__content__row__badge} ${
                                            item.harvest_date_badge > 0
                                              ? styles.table__content__row__badge__up
                                              : ''
                                          }`}
                                        >
                                          <Icon
                                            as={ArrowDown}
                                            strokeWidth="1px"
                                            __css={{
                                              line: {
                                                fill: 'black.500'
                                              },
                                              polyline: {
                                                stroke: 'black.500'
                                              }
                                            }}
                                          />
                                          {Math.abs(Math.floor(item.harvest_date_badge)) + ' days'}
                                        </span>
                                      ) : null}
                                    </>
                                  )} */}
                                  {column.key === 'actions' && (
                                    <Flex alignItems="center" height="100%" key="actions">
                                      <Button
                                        data-cy={'btn-show-data-' + item.id}
                                        theme="primary"
                                        fontWeight="400"
                                        fontSize="0.8rem"
                                        height="2rem"
                                        variant={
                                          item.id === selectedCycle?.field.id ? 'outline' : 'solid'
                                        }
                                        borderColor="primary.500 !important"
                                        color={
                                          item.id === selectedCycle?.field.id
                                            ? 'primary.500 !important'
                                            : 'white'
                                        }
                                        onClick={() => {
                                          setIsShowingData(true);
                                          setShowComplete(false);
                                          onSelectBlock({ ...item });
                                        }}
                                        mr={4}
                                      >
                                        Show Data
                                      </Button>
                                      <Popover>
                                        <PopoverTrigger>
                                          <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            height="100%"
                                            key="more"
                                            role="button"
                                          >
                                            <Icon
                                              as={MoreVertical}
                                              height="22px"
                                              width="auto"
                                              strokeWidth="1.5px"
                                              stroke="black.400"
                                            />
                                          </Flex>
                                        </PopoverTrigger>
                                        <PopoverContent
                                          backgroundColor="white.500"
                                          width="170px"
                                          boxShadow="0px 1px 6px rgba(12, 12, 13, 0.15) !important"
                                          border="none !important"
                                          outline="none !important"
                                          outlineOffset="none !important"
                                        >
                                          {varietyIsCyclic() ? (
                                            <>
                                              <Button
                                                theme="white"
                                                color="black.300"
                                                fontWeight="normal"
                                                fontSize="0.85rem"
                                                justifyContent={'flex-start'}
                                                onClick={() => {
                                                  setCurrentField({ ...item });
                                                  setCurrentFieldPromptType(
                                                    FieldOptionType.StartNewCycle
                                                  );
                                                  onOpen();
                                                }}
                                              >
                                                Start New Cycle
                                              </Button>
                                              <Button
                                                theme="white"
                                                color="black.300"
                                                fontWeight="normal"
                                                fontSize="0.85rem"
                                                justifyContent={'flex-start'}
                                                onClick={() => {
                                                  setCurrentField({ ...item });
                                                  setCurrentFieldPromptType(
                                                    FieldOptionType.ReverseNewCycle
                                                  );
                                                  onOpen();
                                                }}
                                              >
                                                Reverse New Cycle
                                              </Button>
                                            </>
                                          ) : (
                                            ''
                                          )}

                                          <Button
                                            theme="white"
                                            color="error.400"
                                            fontWeight="normal"
                                            fontSize="0.85rem"
                                            justifyContent={'flex-start'}
                                            onClick={() => {
                                              setCurrentField({ ...item });
                                              setCurrentFieldPromptType(
                                                FieldOptionType.UprootBlock
                                              );
                                              onOpen();
                                            }}
                                          >
                                            Uproot Field
                                          </Button>
                                        </PopoverContent>
                                      </Popover>
                                    </Flex>
                                  )}
                                </Td>
                              );
                            } else if (checkCyclic(column)) {
                              return null;
                            }
                          })}
                        </Tr>
                      ))}
                    </>
                  </Tbody>
                </CKTable>
              </InfiniteScroll>
            </Box>
          ) : (
            <Box marginTop={'2rem'}>
              {!(filters.length || !!searchQuery) && (
                <Text>
                  This farm has no fields for the given variety. <AddNewBlockModal isLink={true} />
                  to proceed.
                </Text>
              )}
              {(filters.length || !!searchQuery) && (
                <Text data-cy="no-data">
                  There is no data matching your filters/search parameters.
                </Text>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
