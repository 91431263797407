import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Icon,
  InputGroup,
  InputLeftElement,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Spinner,
  IconButton,
  useToast,
  useDisclosure,
  Heading
} from '@chakra-ui/react';
import Button from 'Components/Button';
import styles from '../Settings.module.scss';
import { Map, Plus, Edit3, Trash2, MapPin, Save, Slash } from 'react-feather';
import { colors } from 'theme';
import { Field, Formik, FormikProps } from 'formik';
import { BasicDropDown, InputWithIcon } from 'Components';
import { Farm, ManageFarmFields } from 'Context/types';
import { useAppContext, useAuthContext } from 'Context';
import { ManageFarmsSchema } from 'Validations';
import Skeleton from 'react-loading-skeleton';
import LimaToast from 'Components/Toast/LimaToast';
import { Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody } from '@chakra-ui/react';
import _ from 'lodash';
import DashboardAppDeps from 'di/DashboardAppDeps';
import HttpClient from 'http/HttpClient';
import useDocumentTitle from 'Helpers/useDocumentTitle';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { getOrganization } from 'Helpers/helpers';

function ManageFarms() {
  const { farm, farms, loadingFarms, organizations } = useAppContext();
  const { trackPageView, pushInstruction } = useMatomo();
  useDocumentTitle('Farm Settings | Lima Asili');
  // tracking organization dimension
  useEffect(() => {
    pushInstruction('deleteCustomDimension', 1);
    pushInstruction('setCustomDimension', 1, farm?.organization);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm?.organization]);
  useEffect(() => {
    pushInstruction('deleteCustomDimension', 2);
    //TODO: re-enable this when the backend is ready
    // pushInstruction('setCustomDimension', 2, farm?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm]);
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const PREFIX_URL = `${process.env.REACT_APP_BE_URL}`;
  const appDeps: DashboardAppDeps = DashboardAppDeps.getInstance();
  const httpClient: HttpClient = appDeps.provideHttpClient();
  const initialValues: ManageFarmFields = {
    name: '',
    location: '',
    orgs: ''
  };
  const [editValues, setEditValues] = useState<any>({
    name: '',
    location: '',
    id: ''
  });
  const [formInitialValues, setFormInitialValues] = useState<any>(initialValues);
  const [currentEdit, setCurrentEdit] = useState<any>();
  const [currentDelete, setCurrentDelete] = useState<any>();
  const {
    functions: { getToken, getUserBlocksAndFarms }
  } = useAuthContext();
  const [isLoading, setIsLoading] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState<any>();
  const [orgsDropdownItems, setOrgsDropdownItems] = useState<any[]>([]);
  const token = getToken();
  const [shouldReloadValues, setShouldReloadValues] = useState(false);
  const [reloadForm, setReloadForm] = useState(false);
  const [clearSelection, setClearSelection] = useState<boolean>(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const showErrorToast = (message: string) => {
    toast({
      position: 'top-right',
      render: () => (
        <LimaToast status="error" message={message} marginTop={'22vh'} marginRight={'3vw'} />
      ),
      duration: 5000,
      isClosable: true
    });
  };
  const showSuccessToast = (message: string) => {
    toast({
      position: 'top-right',
      render: () => (
        <LimaToast status="success" message={message} marginTop={'22vh'} marginRight={'3vw'} />
      ),
      duration: 5000,
      isClosable: true
    });
  };
  const showWarningToast = (message: string) => {
    toast({
      position: 'top-right',
      render: () => (
        <LimaToast status="warning" message={message} marginTop={'22vh'} marginRight={'3vw'} />
      ),
      duration: 5000,
      isClosable: true
    });
  };

  const DeleteModal = () => {
    return (
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        blockScrollOnMount={true}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent boxShadow={'none'} borderRadius="10px" bg="white">
          <Box
            height="10px"
            backgroundColor={'#E4707A'}
            borderTopLeftRadius="10px"
            borderTopRightRadius="10px"
          ></Box>

          <ModalBody>
            <Box display="flex">
              <Icon
                as={Slash}
                strokeWidth="1.5px"
                height="2rem"
                width="2rem"
                color={'#D21122'}
                my={'24px'}
                mx="auto"
                alignSelf="center"
              />
            </Box>

            <Text color={'#DB414E'} fontSize="22px" textAlign="center">
              Warning: Data Deletion
            </Text>
            <Text color="#52606D" textAlign="center" my={'24px'}>
              This action will{' '}
              <Text as="span" color="#d21122 !important" display="inline-flex" fontWeight="600">
                permanently
              </Text>{' '}
              erase all data linked to {currentDelete?.external_name}. Are you sure you want to
              proceed?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button onClick={onClose} className={styles.modal_close}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleDelete(currentDelete);
                }}
                background="#D21122 !important"
                color="#FFFFFF"
                width="61px"
                data-cy="proceed-to-delete"
              >
                Yes
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  useEffect(() => {
    if (organizations?.length) {
      const myOrgs = organizations?.map((item: any) => ({
        label: `${item.external_name}`,
        onClick: () => {},
        id: item.id
      }));
      const initials = { ...initialValues, orgs: myOrgs?.[0]?.id };
      setFormInitialValues(initials);
      setOrgsDropdownItems(myOrgs ?? []);
      setTimeout(() => {
        setReloadForm(true);
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations]);

  const handleSubmit = async (values: ManageFarmFields, resetForm: any) => {
    const org = getOrganization(organizations, values.orgs);
    if (!org) return;
    const body = {
      external_name: values.name,
      internal_name: '',
      organization_id: org.id,
      country: values.location.split(',')[1].trim(),
      city: values.location.split(',')[0].trim(),
      weather_source_id: 1
    };
    setIsSubmitting(true);
    const res = await httpClient
      .getAxios()
      .post(
        `${PREFIX_URL}/farms`,
        { ...body },
        {
          headers: {
            Authorization: token,
            'tenant-header': org.internal_name
          }
        }
      )
      .catch(() => {
        showErrorToast('Farm has not been added. Try again!');
      });
    if (res && (res?.status === 200 || res?.status === 201)) {
      showSuccessToast('New farm has been added successfully');
      getUserBlocksAndFarms();
      setClearSelection(true);
      setTimeout(() => {
        setClearSelection(false);
      }, 5000);
      resetForm({ values: formInitialValues });
    }
    setIsSubmitting(false);
  };

  const editFarm = (farm: Farm) => {
    setCurrentEdit(farm);
    const editValues = {
      name: farm.external_name as String,
      location: `${farm.city}, ${farm.country}` as String,
      id: farm.id,
      orgs: farm?.organization_id
    };
    setEditValues(editValues);
    setTimeout(() => {
      setShouldReloadValues(true);
    }, 0);
  };

  const handleEdit = async (values: ManageFarmFields, resetForm: any) => {
    // ensure we only edit the current edit
    if (values.id !== currentEdit.id) return;
    const edit = { ...editValues };
    // do nothing if the values have not changed
    if (_.isEqual(values, edit)) {
      setCurrentEdit({});
      setEditValues({});
      return;
    }
    const org = getOrganization(organizations, values.orgs);
    if (!org) return;
    setIsLoading(true);
    const body = {
      external_name: values.name,
      internal_name: currentEdit.internal_name,
      organization_id: currentEdit.organization_id,
      country: values.location.split(',')[1].trim(),
      city: values.location.split(',')[0].trim(),
      weather_source_id: currentEdit.weather_source_id
    };
    const res = await httpClient
      .getAxios()
      .put(
        `${PREFIX_URL}/farms/${currentEdit.id}`,
        { ...body },
        {
          headers: {
            Authorization: token,
            'tenant-header': org?.internal_name
          }
        }
      )
      .catch(() => {
        showErrorToast('Farm has not been updated. Try again!');
      });
    if (res && (res?.status === 200 || res?.status === 201)) {
      showSuccessToast('Farm has been updated successfully');
      resetForm({ values: { name: '', location: '', orgs: '' } });
      getUserBlocksAndFarms();
      setCurrentEdit({});
      setEditValues({});
    }
    setIsLoading(false);
  };

  const deleteFarm = (farm: any) => {
    if (farm.number_of_active_fields) {
      showWarningToast("We're unable to delete the farm as it's connected to data.");
      return;
    }
    setCurrentDelete(farm);
    onOpen();
  };

  const handleDelete = async (farm: any) => {
    if (farm.number_of_active_fields) {
      showWarningToast("We're unable to delete the farm as it's connected to data.");
      return;
    }
    setIsLoading(true);
    onClose();
    const res = await httpClient
      .getAxios()
      .delete(`${PREFIX_URL}/farms/${farm.id}`, {
        headers: {
          Authorization: token,
          'tenant-header': farm?.organization?.internal_name
        }
      })
      .catch(() => {
        showErrorToast('Farm has not been deleted. Try again!');
      });
    if (res && res?.status === 204) {
      setCurrentDelete(null);
      showSuccessToast('Farm has been deleted successfully');
      getUserBlocksAndFarms();
    }
    setIsLoading(false);
  };

  const onSelectFilter = (type: string, setFieldValue: any) => {
    return (selection: any | any[]) => {
      setFieldValue(type, selection?.length ? selection : null);
    };
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (shouldReloadValues) {
      timeout = setTimeout(() => {
        setShouldReloadValues(false);
      }, 2000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shouldReloadValues]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (reloadForm) {
      timeout = setTimeout(() => {
        setReloadForm(false);
      }, 2000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [reloadForm]);

  return (
    <>
      <DeleteModal />
      <Box p="1rem" position={'relative'}>
        <Heading fontSize={'1.688rem'} color="dark.coolGray.500" fontWeight={'400'} mb={3}>
          Add New Farm
        </Heading>
        <hr
          style={{
            marginBottom: '2rem'
          }}
        />
        <Formik
          initialValues={formInitialValues}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
          validationSchema={ManageFarmsSchema}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize={reloadForm}
        >
          {({
            isValid,
            handleSubmit,
            errors,
            touched,
            dirty,
            setFieldValue,
            setTouched
          }: FormikProps<ManageFarmFields>) => (
            <form onSubmit={handleSubmit}>
              <Flex gap={10} alignItems={'flex-start'} flexWrap={'wrap'}>
                {organizations?.length > 1 && (
                  <Box>
                    <Text fontSize={'16px'} color={'black.300'} mb={2}>
                      Organization
                    </Text>
                    <BasicDropDown
                      dataCy={'new-farm-orgs'}
                      key={'orgs'}
                      id="organization"
                      name="manage"
                      items={orgsDropdownItems}
                      showSelected={!!orgsDropdownItems.length}
                      onSelectFilter={onSelectFilter('orgs', setFieldValue)}
                      onBlur={(id: any) => {
                        if (id === 'organization') {
                          setTouched({ ...touched, orgs: true });
                        }
                      }}
                      clearSelection={clearSelection}
                      selectedItems={0}
                      hasError={!!(errors && errors.orgs && touched.orgs)}
                      matchWidth={true}
                      color="black.400"
                      triggerProps={{
                        width: '350px',
                        height: '43px',
                        minWidth: 'max-content',
                        border: '1.5px solid #CBD2D9',
                        __css: {
                          '& > span': {
                            display: 'grid !important',
                            gridTemplateColumns: '1fr 1rem',
                            gridColumnGap: '0.5rem',
                            width: '100%',
                            textAlign: 'left',
                            alignItems: 'center',
                            '& > .chakra-button__icon': {
                              marginInlineEnd: 0,
                              '& > svg': {
                                transform: 'scale(1)',

                                '&.drop-arrow': {
                                  transform: 'scale(0.7)'
                                }
                              }
                            }
                          }
                        }
                      }}
                      dropdownListProps={{
                        width: '350px',
                        border: '0px',
                        boxShadow: '0px 1px 6px 0px rgba(12, 12, 13, 0.15)'
                      }}
                    >
                      <Text fontWeight={400} color="dark.coolGray.300">
                        Select Organization
                      </Text>
                    </BasicDropDown>
                    <Text mb={0} mt={1} color="black.300" as="p" fontSize="14px" fontWeight={400}>
                      Select the organization your farm belongs to
                    </Text>
                    {errors && errors.orgs && touched.orgs && (
                      <Text color="#E53E3E" marginTop="0.5rem" fontSize="0.875rem">
                        {errors.orgs}
                      </Text>
                    )}
                  </Box>
                )}
                <Box mr={4}>
                  <FormControl isInvalid={!!errors.name && touched.name}>
                    <FormLabel
                      htmlFor="name"
                      fontSize={'16px'}
                      color={'black.300'}
                      mb={2}
                      fontWeight="400"
                    >
                      Farm Name
                    </FormLabel>
                    <InputGroup width={'350px'}>
                      <InputLeftElement pointerEvents="none">
                        <Icon
                          as={Map}
                          strokeWidth="1.5px"
                          height="24px"
                          width="auto"
                          color={colors.input.placeholder}
                          mx={2}
                        />
                      </InputLeftElement>
                      <Field
                        as={InputWithIcon}
                        id="name"
                        type="text"
                        name="name"
                        autoComplete="false"
                        placeholder="Enter the farm name"
                        data-cy="new-farm-name"
                      />
                    </InputGroup>
                    {errors && errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                  </FormControl>
                </Box>
                <Box mr={4}>
                  <FormControl isInvalid={!!errors.location && touched.location}>
                    <FormLabel
                      htmlFor="location"
                      fontSize={'16px'}
                      color={'black.300'}
                      mb={2}
                      fontWeight="400"
                    >
                      Farm Location
                    </FormLabel>
                    <InputGroup width={'350px'}>
                      <InputLeftElement pointerEvents="none">
                        <Icon
                          as={MapPin}
                          strokeWidth="1.5px"
                          height="24px"
                          width="auto"
                          color={colors.input.placeholder}
                          mx={2}
                        />
                      </InputLeftElement>
                      <Field
                        as={InputWithIcon}
                        id="location"
                        type="text"
                        name="location"
                        autoComplete="false"
                        placeholder="Nairobi, Kenya"
                        data-cy="new-farm-location"
                      />
                    </InputGroup>
                    <Text mb={0} mt={1} color="black.300" as="p" fontSize="14px" fontWeight={400}>
                      Please enter your location in the format of &quot;City, Country&quot;.
                      <br /> For example: Nanyuki, Kenya.
                    </Text>
                    {errors && errors.location && (
                      <FormErrorMessage>{errors.location}</FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box alignSelf={'flex-start'}>
                  <Button
                    data-cy="add-farm-button"
                    theme="primary"
                    fontWeight="400"
                    fontSize="18px"
                    variant="solid"
                    borderColor="primary.500 !important"
                    color="white"
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                  >
                    <Icon
                      as={Plus}
                      strokeWidth="1.5px"
                      height="24px"
                      width="auto"
                      color="currentcolor"
                      mr={2}
                    />
                    Add New Farm
                    {isSubmitting && (
                      <>
                        <Box px={1.5}></Box>
                        <Spinner color="white.500" size="sm" />
                      </>
                    )}
                  </Button>
                </Box>
              </Flex>
            </form>
          )}
        </Formik>
        <Heading
          fontSize={'1.688rem'}
          color="dark.coolGray.500"
          fontWeight={'400'}
          mb={3}
          mt={'3rem'}
          data-cy="tab-heading"
        >
          Manage Farms
        </Heading>
        <hr
          style={{
            marginBottom: '2rem'
          }}
        />
        {farms.length == 0 ? (
          <>
            {loadingFarms ? (
              <Skeleton height={25} width={'100%'} />
            ) : (
              <Text>This organization has no farms. Add new farms to proceed.</Text>
            )}
          </>
        ) : (
          <>
            <Formik
              initialValues={editValues}
              onSubmit={(values, { resetForm }) => {
                handleEdit(values, resetForm);
              }}
              validationSchema={ManageFarmsSchema}
              validateOnMount={true}
              enableReinitialize={shouldReloadValues}
            >
              {({ isValid, handleSubmit, errors, touched }: FormikProps<ManageFarmFields>) => (
                <form onSubmit={handleSubmit}>
                  <Table className={`${styles.table__content} ${styles.table__bordered}`} size="sm">
                    <Thead className={styles.table__content__head} zIndex="docked">
                      <Tr
                        className={`${styles.table__content__row} ${styles.table__content__head__row}`}
                      >
                        <Th textTransform="capitalize">Farm Name</Th>
                        <Th textTransform="capitalize">Farm Location</Th>
                        <Th textTransform="capitalize">Active Fields</Th>
                        <Th textTransform="capitalize">Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody className={`${styles.table__content__body}`}>
                      {farms.map((item, i) => (
                        <Tr
                          className={`${styles.table__content__row} ${styles.table__content__body__row}`}
                          key={i}
                        >
                          {currentEdit?.id === item.id ? (
                            <>
                              <Td>
                                <FormControl isInvalid={!!errors.name && touched.name} mx={'auto'}>
                                  <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                      <Icon
                                        as={Map}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color={colors.input.placeholder}
                                        mx={2}
                                      />
                                    </InputLeftElement>
                                    <Field
                                      as={InputWithIcon}
                                      id="name"
                                      type="text"
                                      name="name"
                                      autoComplete="false"
                                      placeholder="Enter the farm name"
                                      data-cy="edit-farm-name"
                                    />
                                  </InputGroup>
                                  {errors && errors.name && (
                                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                                  )}
                                </FormControl>
                              </Td>
                              <Td>
                                <FormControl
                                  isInvalid={!!errors.location && touched.location}
                                  mx={'auto'}
                                >
                                  <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                      <Icon
                                        as={MapPin}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color={colors.input.placeholder}
                                        mx={2}
                                      />
                                    </InputLeftElement>
                                    <Field
                                      as={InputWithIcon}
                                      id="location"
                                      type="text"
                                      name="location"
                                      autoComplete="false"
                                      placeholder="Enter the farm location"
                                      data-cy="edit-farm-location"
                                    />
                                  </InputGroup>
                                  {errors && errors.location && (
                                    <FormErrorMessage>{errors.location}</FormErrorMessage>
                                  )}
                                </FormControl>
                              </Td>
                              <Td>{item.number_of_active_fields}</Td>
                              <Td>
                                <Flex justifyContent={'center'}>
                                  <IconButton
                                    aria-label="Save farm edit"
                                    backgroundColor={'transparent'}
                                    height={'auto'}
                                    type="submit"
                                    className={styles.no_outline_button}
                                    disabled={!isValid || isLoading}
                                    icon={
                                      <Icon
                                        as={Save}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color="green.400"
                                      />
                                    }
                                    data-cy={`save-farm-${i}`}
                                  />
                                  <IconButton
                                    aria-label="Save farm delete"
                                    backgroundColor={'transparent'}
                                    height={'auto'}
                                    onClick={() => {
                                      deleteFarm(item);
                                    }}
                                    className={styles.no_outline_button}
                                    disabled={isLoading}
                                    icon={
                                      <Icon
                                        as={Trash2}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color={
                                          item.number_of_active_fields !== 0
                                            ? 'dark.coolGray.200'
                                            : '#DB414E'
                                        }
                                      />
                                    }
                                    data-cy={`delete-farm-${i}`}
                                  />
                                </Flex>
                              </Td>
                            </>
                          ) : (
                            <>
                              <Td>{item.external_name}</Td>
                              <Td>
                                {item.city},&nbsp;{item.country}
                              </Td>
                              <Td>{item.number_of_active_fields}</Td>
                              <Td>
                                <Flex justifyContent={'center'}>
                                  <IconButton
                                    aria-label="Save farm edit"
                                    backgroundColor={'transparent'}
                                    height={'auto'}
                                    disabled={isLoading}
                                    onClick={() => {
                                      editFarm(item);
                                    }}
                                    className={styles.no_outline_button}
                                    icon={
                                      <Icon
                                        as={Edit3}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color="green.400"
                                      />
                                    }
                                    data-cy={`edit-farm-${i}`}
                                  />
                                  <IconButton
                                    aria-label="Save farm delete"
                                    backgroundColor={'transparent'}
                                    height={'auto'}
                                    disabled={isLoading}
                                    onClick={() => {
                                      deleteFarm(item);
                                    }}
                                    className={styles.no_outline_button}
                                    icon={
                                      <Icon
                                        as={Trash2}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color={
                                          item.number_of_active_fields !== 0
                                            ? 'dark.coolGray.200'
                                            : '#DB414E'
                                        }
                                      />
                                    }
                                    data-cy={`delete-farm-${i}`}
                                  />
                                </Flex>
                              </Td>
                            </>
                          )}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </form>
              )}
            </Formik>
          </>
        )}
      </Box>
    </>
  );
}

export default ManageFarms;
