import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Flex, Icon, Link, Text, Tooltip } from '@chakra-ui/react';
import { Lock } from 'react-feather';

export type NavItemProps = {
  to: string;
  label: string;
  icon: FC;
  locked?: boolean;
  isCollapsed: boolean;
};

export default function NavItem({
  to,
  label,
  icon,
  locked,
  isCollapsed
}: NavItemProps): JSX.Element {
  const getLabel = () => {
    if (label === 'Settings') return !!locked ? '' : label;
    return !!locked ? 'Coming Soon!' : label;
  };
  return (
    <Tooltip hasArrow shouldWrapChildren label={getLabel()} transition={'all .1s ease-in-out'}>
      <Link
        data-cy={`side-nav-menu-${to.replaceAll('/', '')}`}
        as={NavLink}
        to={to}
        state={{ current: to }}
        marginBottom="2px"
        display="grid"
        gridTemplateColumns={isCollapsed ? '1fr' : '86px 1fr'}
        height="70px"
        width="100%"
        position="relative"
        pointerEvents={!!locked ? 'none' : 'auto'}
        alignItems="center"
        color={!!locked ? 'black.100' : 'black.300'}
        textDecoration="none"
        _hover={{
          textDecoration: 'none',
          '& > .nav-icon > svg': {
            stroke: 'black.500 !important'
          },
          '& > .nav-label': {
            color: 'black.500',
            fontWeight: 400
          }
        }}
        borderRadius="4"
        _focus={{
          boxShadow: 'none'
        }}
        _activeLink={{
          bg: 'primary.100',
          '& > .nav-icon > svg': {
            stroke: 'black.500 !important'
          },
          '& > .nav-label': {
            color: 'black.500',
            fontWeight: 400
          }
        }}
      >
        <Flex
          className="nav-icon"
          justifyContent="center"
          alignItems="center"
          opacity={!!locked ? 0.8 : 1}
        >
          <Icon
            as={icon}
            strokeWidth="1.5px"
            height="25px"
            width="auto"
            stroke={!!locked ? 'black.100' : 'black.300'}
          />
        </Flex>
        {!isCollapsed && (
          <Text
            as="span"
            className="nav-label"
            color={!!locked ? 'black.100' : 'black.300'}
            fontSize="1rem"
          >
            {label}
          </Text>
        )}
        {!!locked && (
          <Flex
            className="locked"
            justifyContent="center"
            alignItems="center"
            display="block"
            position="absolute"
            right={0}
            top={2}
          >
            <Icon as={Lock} height="12px" width="auto" strokeWidth="1.5px" stroke="black.300" />
          </Flex>
        )}
      </Link>
    </Tooltip>
  );
}
