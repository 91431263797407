import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Icon,
  InputGroup,
  InputLeftElement,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Badge,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Spinner,
  IconButton,
  useToast,
  useDisclosure,
  Heading
} from '@chakra-ui/react';
import Button from 'Components/Button';
import styles from '../Settings.module.scss';
import { Edit3, Trash2, Feather, Plus, X, Slash, Save, Truck } from 'react-feather';
import { colors } from 'theme';
import { BasicDropDown } from 'Components';
import { Field, Formik, FormikProps } from 'formik';
import LimaToast from 'Components/Toast/LimaToast';
import { InputWithIcon } from 'Components';
import { Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody } from '@chakra-ui/react';
import Skeleton from 'react-loading-skeleton';
import { Farm, ManageVariety } from 'Context/types';
import { ManageVarietiesSchema } from 'Validations';
import { MultiSelectDropdown } from 'Components';
import { useAppContext, useAuthContext } from 'Context';
import { BasicDropDownItem } from 'Components/types';
import _ from 'lodash';
import DashboardAppDeps from 'di/DashboardAppDeps';
import HttpClient from 'http/HttpClient';
import useDocumentTitle from 'Helpers/useDocumentTitle';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { getOrganization, sortTableColumn } from 'Helpers/helpers';

function ManageVarieties() {
  const { farm, farms, organizations } = useAppContext();
  const [displayFarms, setDisplayFarms] = useState<Farm[]>([]);
  useDocumentTitle('Variety Settings | Lima Asili');
  const { trackPageView, pushInstruction } = useMatomo();

  // tracking organization dimension
  useEffect(() => {
    pushInstruction('deleteCustomDimension', 1);
    pushInstruction('setCustomDimension', 1, farm?.organization?.external_name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm?.organization]);
  useEffect(() => {
    pushInstruction('deleteCustomDimension', 2);
    pushInstruction('setCustomDimension', 2, farm?.external_name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm]);
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const PREFIX_URL = `${process.env.REACT_APP_BE_URL}`;
  const appDeps: DashboardAppDeps = DashboardAppDeps.getInstance();
  const httpClient: HttpClient = appDeps.provideHttpClient();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [input, setInput] = useState<any>('');
  const [tags, setTags] = useState<any>([]);
  const [uneditableTags, setUneditableTags] = useState<any>([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [varieties, setVarieties] = useState<any[]>([]);
  const [isClicked, setIsClicked] = useState<boolean>();
  const [isEClicked, setEIsClicked] = useState<boolean>();
  const initialValues: ManageVariety = {
    name: '',
    categories: null,
    unit: '',
    farms: null,
    frequency: 0,
    orgs: ''
  };
  const [editValues, setEditValues] = useState<any>({
    name: '',
    categories: null,
    unit: '',
    farms: null,
    frequency: '',
    id: '',
    orgs: ''
  });
  const {
    variables: { user },
    functions: { getToken, getUserBlocksAndFarms }
  } = useAuthContext();
  const token = getToken();
  let header: { [x: string]: any } = {
    Authorization: token
  };
  if (user?.tenant_header) header = { ...header, 'tenant-header': user?.tenant_header };
  const [orgsDropdownItems, setOrgsDropdownItems] = useState<any[]>([]);
  const [reloadForm, setReloadForm] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState<any>(initialValues);
  const [clearSelection, setClearSelection] = useState<boolean>(false);
  const [clearFarmSelection, setClearFarmSelection] = useState<boolean>(false);
  const [shouldReloadValues, setShouldReloadValues] = useState(false);
  const [isLoading, setIsLoading] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState<any>();
  const [loadingVarieties, setLoadingVarieties] = useState<boolean>();
  const [currentEdit, setCurrentEdit] = useState<any>();
  const [currentDelete, setCurrentDelete] = useState<any>();
  const [editTextarea, setEditTextarea] = useState<any>();
  const badgeColors: string[] = [
    '#03D372',
    '#79d2de',
    '#905ed1',
    '#626262',
    '#ff9734',
    '#ee7777',
    '#03D372',
    '#79d2de',
    '#905ed1',
    '#626262'
  ];
  const units: string[] = ['Stem'];
  const whiteBadges: number[] = [2, 3, 8, 9];
  const showErrorToast = (message: string) => {
    toast({
      position: 'top-right',
      render: () => (
        <LimaToast status="error" message={message} marginTop={'22vh'} marginRight={'3vw'} />
      ),
      duration: 5000,
      isClosable: true
    });
  };

  const showSuccessToast = (message: string) => {
    toast({
      position: 'top-right',
      render: () => (
        <LimaToast status="success" message={message} marginTop={'22vh'} marginRight={'3vw'} />
      ),
      duration: 5000,
      isClosable: true
    });
  };

  const showWarningToast = (message: string) => {
    toast({
      position: 'top-right',
      render: () => (
        <LimaToast status="warning" message={message} marginTop={'22vh'} marginRight={'3vw'} />
      ),
      duration: 5000,
      isClosable: true
    });
  };

  const DeleteModal = () => {
    return (
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        blockScrollOnMount={true}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent boxShadow={'none'} borderRadius="10px" bg="white">
          <Box
            height="10px"
            backgroundColor={'#E4707A'}
            borderTopLeftRadius="10px"
            borderTopRightRadius="10px"
          ></Box>
          <ModalBody>
            <Box display="flex">
              <Icon
                as={Slash}
                strokeWidth="1.5px"
                height="2rem"
                width="2rem"
                color={'#D21122'}
                my={'24px'}
                mx="auto"
                alignSelf="center"
              />
            </Box>

            <Text color={'#DB414E'} fontSize="22px" textAlign="center">
              Warning: Data Deletion
            </Text>
            <Text color="#52606D" textAlign="center" my={'24px'}>
              This action will{' '}
              <Text color="#d21122 !important" display="inline-flex" fontWeight="600" as="span">
                permanently
              </Text>{' '}
              erase {currentDelete?.external_name ? currentDelete?.external_name : 'the variety'}.
              <br></br>
              Are you sure you want to proceed?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button onClick={cancelDelete} className={styles.modal_close}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleDelete(currentDelete);
                }}
                background="#D21122 !important"
                color="#FFFFFF"
                width="61px"
                data-cy="proceed-to-delete"
              >
                Yes
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const unitDropdownItems: BasicDropDownItem[] = units.map((item) => ({
    label: `${item}`,
    onClick: () => {},
    id: item
  }));

  const fetchVarieties = async () => {
    setLoadingVarieties(true);
    setVarieties([]);
    const response = await httpClient
      .getAxios()
      .get(`${PREFIX_URL}/organizations/varieties`, {
        headers: header
      })
      .catch(() => {});
    if (response && response.data) {
      const sorted_varieties = sortTableColumn(response.data, 'external_name', 'asc');
      setVarieties(sorted_varieties);
      setLoadingVarieties(false);
    }
  };

  useEffect(() => {
    const orgs_with_farms = organizations?.filter((org: any) =>
      farms?.some((farm: any) => farm.organization_id === org.id)
    );
    if (orgs_with_farms?.length) {
      // update org dropdown items, filter farms by org and reset form initial values then reload form
      const myOrgs = orgs_with_farms?.map((item: any) => ({
        label: `${item.external_name}`,
        onClick: () => {},
        id: item.id
      }));
      setOrgsDropdownItems(myOrgs ?? []);
      const initials = { ...initialValues, orgs: myOrgs?.[0]?.id };
      setFormInitialValues(initials);
      if (organizations?.length > 1) {
        const filtered = farms?.filter((el: Farm) => el.organization.id === myOrgs?.[0]?.id);
        setDisplayFarms(filtered);
      } else setDisplayFarms(farms);
      setTimeout(() => {
        setReloadForm(true);
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farms, organizations]);

  useEffect(() => {
    fetchVarieties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserBlocksAndFarms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varieties]);

  const onChange = (e: any) => {
    const { value } = e.target;
    setInput(value);
  };
  const onKeyDown = (e: any, setFieldValue: any, action?: string) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (key === ',' || key === 'Enter') {
      if (trimmedInput.length && !tags.includes(trimmedInput)) {
        e.preventDefault();
        const newTags = [...tags, trimmedInput];
        setTags(newTags);
        setFieldValue('categories', newTags.length ? newTags : null);
        setInput('');
      } else if (trimmedInput.length && tags.includes(trimmedInput))
        showWarningToast('The tag already exists.');
    }

    if (action !== 'edit') {
      if (key === 'Backspace' && !input.length && tags.length && isKeyReleased) {
        const tagsCopy = [...tags];
        const poppedTag = tagsCopy.pop();
        e.preventDefault();
        setTags(tagsCopy);
        setFieldValue('categories', tagsCopy.length ? tagsCopy : null);
        setInput(poppedTag);
      }
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index: number, setFieldValue: any) => {
    const newTags = tags.filter((tag: any, i: number) => i !== index);
    setTags(newTags);
    setFieldValue('categories', newTags.length ? newTags : null);
  };

  const onSelectFilter = (type: string, setFieldValue: any) => {
    return (selection: any | any[]) => {
      if (type === 'orgs') {
        const filtered = farms?.filter((el: Farm) => el.organization.external_name === selection);
        setFieldValue(type, filtered?.[0]?.organization?.id ?? selection);
        setDisplayFarms(filtered);
        setClearFarmSelection(true);
        setTimeout(() => {
          setClearFarmSelection(false);
        }, 1000);
      } else setFieldValue(type, selection?.length ? selection : null);
    };
  };

  const getFreq = (val: any) => {
    let frequency_type = '';
    if (val == 1) frequency_type = 'daily';
    else if (val && val > 1 && val < 30) frequency_type = `every ${val} days`;
    else if (val && val > 30) frequency_type = `every ${Math.round(val / 30)} months`;
    return frequency_type;
  };

  const handleSubmit = async (values: ManageVariety, resetForm: any) => {
    const org = getOrganization(organizations, values.orgs);
    if (!org) return;
    header['tenant-header'] = org.internal_name;
    const body = {
      farm_ids: values.farms,
      ml_model_id: null,
      harvest_frequency_days: values.frequency,
      frequency_type: getFreq(values?.frequency),
      internal_name: '',
      external_name: values.name,
      species: '',
      cultivar: '',
      unit: values.unit,
      exportable_type: null,
      exportable_number_type: null,
      exportable_color: null,
      production_categories: values.categories?.map((el: any) => {
        const obj: { [key: string]: string } = {
          internal_name: `grade_${el.trim().replace(' ', '_')}`,
          external_name: el,
          category_type: 'grade'
        };
        return obj;
      })
    };
    setIsSubmitting(true);
    const res = await httpClient
      .getAxios()
      .post(
        `${PREFIX_URL}/varieties`,
        { ...body },
        {
          headers: header
        }
      )
      .catch((error) => {
        if (
          error &&
          (error?.response?.status == 400 ||
            error?.response?.status == 401 ||
            error?.response?.status == 422)
        )
          showErrorToast(error.response.data.detail);
        else showErrorToast('Variety has not been added. Try again!');
      });
    if (res && (res?.status === 200 || res?.status === 201)) {
      showSuccessToast(`New variety has been added successfully`);
      resetForm({ values: formInitialValues });
      setClearSelection(true);
      setClearFarmSelection(true);
      setTimeout(() => {
        setClearFarmSelection(false);
        setClearSelection(false);
      }, 5000);
      setTags([]);
      fetchVarieties();
      setIsClicked(false);
    }
    setIsSubmitting(false);
  };

  const editVariety = (variety: any) => {
    setCurrentEdit({});
    setInput('');
    setEditTextarea(true);
    setCurrentEdit(variety);
    setEIsClicked(false);
    const newTags = variety?.production_categories?.map((el: any) => el.external_name) ?? [];
    setTags(newTags);
    setUneditableTags(newTags);
    const editValues = {
      name: variety.external_name,
      categories: newTags,
      unit: variety.unit,
      farms: variety.farms?.map((el: any) => el.id),
      frequency: variety.harvest_frequency_days,
      id: variety.id,
      orgs: variety.tenant_name
    };
    setShouldReloadValues(true);
    setEditValues(editValues);
  };

  const handleEdit = async (values: ManageVariety, resetForm: any) => {
    // ensure we only edit the current edit
    if (values.id !== currentEdit.id) return;
    const edit = { ...editValues };
    // do nothing if the values have not changed
    if (_.isEqual(values, edit)) {
      setCurrentEdit({});
      setEditValues({});
      setTags([]);
      setInput('');
      setEditTextarea(false);
      return;
    }
    //update tenant header to that of the edit variety
    header = { ...header, 'tenant-header': values?.orgs, update_all_instances: true };
    const old = currentEdit?.farms?.map((el: any) => el.id);
    const to_remove = old.filter((el: string) => !values?.farms?.includes(el));
    const to_add = values?.farms?.filter((el: string) => !old.includes(el));
    const body = {
      ml_model_id: currentEdit?.ml_model_id,
      harvest_frequency_days: currentEdit?.harvest_frequency_days,
      frequency_type: currentEdit?.frequency_type,
      internal_name: currentEdit?.internal_name,
      external_name: values.name,
      species: currentEdit?.species,
      cultivar: currentEdit?.cultivar,
      unit: values.unit,
      exportable_type: currentEdit?.exportable_type,
      exportable_number_type: currentEdit?.exportable_number_type,
      exportable_color: currentEdit?.exportable_color,
      production_categories: values.categories
        ?.filter((el: any) => !editValues?.categories?.includes(el))
        .map((el: any) => {
          const obj: { [key: string]: string } = {
            internal_name: `grade_${el.trim().replace(' ', '_')}`,
            external_name: el,
            category_type: 'grade'
          };
          return obj;
        }),
      farms_to_add: to_add,
      farms_to_remove: to_remove
    };
    setIsLoading(true);
    const res = await httpClient
      .getAxios()
      .put(
        `${PREFIX_URL}/varieties/${currentEdit?.id}`,
        { ...body },
        {
          headers: header,
          params: {
            update_all_instances: true
          }
        }
      )
      .catch(() => {
        showErrorToast(
          `${
            currentEdit?.name ? currentEdit?.name : 'Variety'
          } has been NOT been updated. Try again!`
        );
      });
    if (res && (res?.status === 200 || res?.status === 201)) {
      showSuccessToast(
        `${res?.data?.name ? res?.data?.name : 'Variety'} has been updated successfully`
      );
      resetForm({ values: initialValues });
      fetchVarieties();
      setCurrentEdit({});
      setEditValues({});
      setTags([]);
      setUneditableTags([]);
      setInput('');
      setEditTextarea(false);
      setEIsClicked(false);
    }
    setIsLoading(false);
  };

  const deleteVariety = (variety: any) => {
    if (variety.no_of_fields) {
      showWarningToast("We're unable to delete the variety as it's connected to data.");
      return;
    }
    setCurrentDelete(variety);
    onOpen();
  };

  const cancelDelete = () => {
    onClose();
    setCurrentDelete(null);
  };

  const handleDelete = async (variety: any) => {
    if (variety.no_of_fields) {
      showWarningToast("We're unable to delete the variety as it's connected to data.");
      return;
    }
    setIsLoading(true);
    onClose();

    header['tenant-header'] = variety.tenant_name;
    const res = await httpClient
      .getAxios()
      .delete(`${PREFIX_URL}/varieties/${variety.id}`, {
        headers: header,
        params: {
          delete_all_instances: true
        }
      })
      .catch(() => {
        showErrorToast('Variety has not been deleted. Try again!');
      });
    if (res && res?.status === 204) {
      setCurrentDelete(null);
      showSuccessToast('Variety has been deleted successfully');
      fetchVarieties();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (shouldReloadValues) {
      timeout = setTimeout(() => {
        setShouldReloadValues(false);
      }, 2000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shouldReloadValues]);

  return (
    <>
      <DeleteModal />
      <Box p="1rem">
        <Heading fontSize={'1.688rem'} color="dark.coolGray.500" fontWeight={'400'} mb={3}>
          Add New Variety
        </Heading>
        <hr
          style={{
            marginBottom: '2rem'
          }}
        />
        <Formik
          initialValues={formInitialValues}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
          validationSchema={ManageVarietiesSchema}
          validateOnBlur={true}
          enableReinitialize={reloadForm}
        >
          {({
            isValid,
            handleSubmit,
            errors,
            touched,
            dirty,
            setFieldValue,
            setTouched
          }: FormikProps<ManageVariety>) => (
            <form onSubmit={handleSubmit}>
              <Flex
                alignItems={'flex-start'}
                flexWrap="wrap"
                justifyContent={'space-between'}
                className={styles.custom_flex}
              >
                {orgsDropdownItems?.length > 1 && (
                  <Box>
                    <Text fontSize={'16px'} color={'black.300'} mb={2}>
                      Organization
                    </Text>
                    <BasicDropDown
                      dataCy={'new-farm-orgs'}
                      key={'orgs'}
                      id="organization"
                      name="manage"
                      items={orgsDropdownItems}
                      showSelected={!!orgsDropdownItems.length}
                      onSelectFilter={onSelectFilter('orgs', setFieldValue)}
                      onBlur={(id: any) => {
                        if (id === 'organization') {
                          setTouched({ ...touched, orgs: true });
                        }
                      }}
                      clearSelection={clearSelection}
                      selectedItems={0}
                      hasError={!!(errors && errors.orgs && touched.orgs)}
                      matchWidth={true}
                      color="black.400"
                      triggerProps={{
                        width: '350px',
                        height: '43px',
                        minWidth: 'max-content',
                        border: '1.5px solid #CBD2D9',
                        __css: {
                          '& > span': {
                            display: 'grid !important',
                            gridTemplateColumns: '1fr 1rem',
                            gridColumnGap: '0.5rem',
                            width: '100%',
                            textAlign: 'left',
                            alignItems: 'center',
                            '& > .chakra-button__icon': {
                              marginInlineEnd: 0,
                              '& > svg': {
                                transform: 'scale(1)',

                                '&.drop-arrow': {
                                  transform: 'scale(0.7)'
                                }
                              }
                            }
                          }
                        }
                      }}
                      dropdownListProps={{
                        width: '350px',
                        border: '0px',
                        boxShadow: '0px 1px 6px 0px rgba(12, 12, 13, 0.15)'
                      }}
                    >
                      <Text fontWeight={400} color="dark.coolGray.300">
                        Select Organization
                      </Text>
                    </BasicDropDown>
                    <Text mb={0} mt={1} color="black.300" as="p" fontSize="14px" fontWeight={400}>
                      Select the organization your farm belongs to
                    </Text>
                    {errors && errors.orgs && touched.orgs && (
                      <Text color="#E53E3E" marginTop="0.5rem" fontSize="0.875rem">
                        {errors.orgs}
                      </Text>
                    )}
                  </Box>
                )}
                <Box>
                  <FormControl isInvalid={!!errors.name && touched.name}>
                    <FormLabel
                      htmlFor="name"
                      fontSize={'16px'}
                      color={'black.300'}
                      mb={2}
                      fontWeight="400"
                    >
                      Variety Name
                    </FormLabel>
                    <InputGroup width={'350px'}>
                      <InputLeftElement pointerEvents="none">
                        <Icon
                          as={Feather}
                          strokeWidth="1.5px"
                          height="24px"
                          width="auto"
                          color={colors.input.placeholder}
                          mx={2}
                        />
                      </InputLeftElement>
                      <Field
                        as={InputWithIcon}
                        id="name"
                        type="text"
                        name="name"
                        autoComplete="false"
                        placeholder="Enter variety grown"
                        data-cy="new-variety-name"
                      />
                    </InputGroup>
                    {errors && errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                  </FormControl>
                </Box>
                <Box>
                  <FormControl isInvalid={!!errors.frequency && touched.frequency}>
                    <FormLabel
                      htmlFor="frequency"
                      fontSize={'16px'}
                      color={'black.300'}
                      mb={2}
                      fontWeight="400"
                    >
                      Frequency of Harvest
                    </FormLabel>
                    <InputGroup width={'350px'}>
                      <InputLeftElement pointerEvents="none">
                        <Icon
                          as={Truck}
                          strokeWidth="1.5px"
                          height="24px"
                          width="auto"
                          color={colors.input.placeholder}
                          mx={2}
                        />
                      </InputLeftElement>
                      <Field
                        as={InputWithIcon}
                        id="frequency"
                        type="number"
                        name="frequency"
                        autoComplete="false"
                        placeholder="Enter harvest frequency days"
                        data-cy="new-variety-frequency"
                      />
                    </InputGroup>
                    <Text mb={0} mt={1} color="black.300" as="p" fontSize="14px" fontWeight={400}>
                      In days, how often is the variety harvested
                    </Text>
                    {errors && errors.frequency && (
                      <FormErrorMessage>{errors.frequency}</FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <Text fontSize={'16px'} color={'black.300'} mb={2}>
                    Unit
                  </Text>
                  <BasicDropDown
                    dataCy={'new-farm-unit'}
                    key={'unit'}
                    id="unit"
                    name="manage"
                    items={unitDropdownItems}
                    showSelected={!!unitDropdownItems.length}
                    onSelectFilter={onSelectFilter('unit', setFieldValue)}
                    onBlur={(id: any) => {
                      if (id === 'unit') {
                        setTouched({ ...touched, unit: true });
                      }
                    }}
                    clearSelection={clearSelection}
                    hasError={!!(errors && errors.unit && touched.unit)}
                    matchWidth={true}
                    color="black.400"
                    triggerProps={{
                      width: '350px',
                      height: '43px',
                      minWidth: 'max-content',
                      border: '1.5px solid #CBD2D9',
                      __css: {
                        '& > span': {
                          display: 'grid !important',
                          gridTemplateColumns: '1fr 1rem',
                          gridColumnGap: '0.5rem',
                          width: '100%',
                          textAlign: 'left',
                          alignItems: 'center',
                          '& > .chakra-button__icon': {
                            marginInlineEnd: 0,
                            '& > svg': {
                              transform: 'scale(1)',

                              '&.drop-arrow': {
                                transform: 'scale(0.7)'
                              }
                            }
                          }
                        }
                      }
                    }}
                    dropdownListProps={{
                      width: '350px',
                      border: '0px',
                      boxShadow: '0px 1px 6px 0px rgba(12, 12, 13, 0.15)'
                    }}
                  >
                    <Text fontWeight={400} color="dark.coolGray.300">
                      Select unit
                    </Text>
                  </BasicDropDown>
                  <Text mb={0} mt={1} color="black.300" as="p" fontSize="14px" fontWeight={400}>
                    Select the name of a unit of your variety
                  </Text>
                  {errors && errors.unit && touched.unit && (
                    <Text color="#E53E3E" marginTop="0.5rem" fontSize="0.875rem">
                      {errors.unit}
                    </Text>
                  )}
                </Box>
                <Box>
                  <Text fontSize={'16px'} color={'black.300'} mb={2}>
                    Grading Categories
                  </Text>
                  <div
                    className={`${styles.custom_textarea} ${
                      errors && errors.categories && touched.categories
                        ? `${styles.invalid_textarea}`
                        : ''
                    }`}
                    onClick={() => setIsClicked(true)}
                    onMouseLeave={() =>
                      isClicked ? setTouched({ ...touched, categories: true }) : () => {}
                    }
                  >
                    {!editTextarea && (
                      <>
                        <input
                          value={input}
                          placeholder="Enter a tag eg. 30cm, 40cm, 50cm"
                          onKeyDown={(e) => onKeyDown(e, setFieldValue)}
                          onKeyUp={onKeyUp}
                          onChange={onChange}
                          data-cy="new-farm-categories"
                        />
                        {tags.map((tag: any, ind: number) => (
                          <Badge
                            backgroundColor={badgeColors[ind]}
                            key={ind}
                            className={`${styles.custom_badge} ${styles.tag}`}
                            color={whiteBadges.includes(ind) ? '#FFFFFF !important' : '#2C2E2D'}
                          >
                            {tag}
                            <Icon
                              as={X}
                              strokeWidth="1.5px"
                              height="15px"
                              width="auto"
                              color="currentcolor"
                              ml={'0.5rem'}
                              onClick={() => deleteTag(ind, setFieldValue)}
                              cursor="pointer"
                            />
                          </Badge>
                        ))}
                      </>
                    )}
                  </div>
                  <Text mb={0} mt={1} color="black.300" as="p" fontSize="14px" fontWeight={400}>
                    List a maximum of 10 comma separated categories
                  </Text>
                  {errors && errors.categories && touched.categories && (
                    <Text color="#E53E3E" marginTop="0.5rem" fontSize="0.875rem">
                      {errors.categories}
                    </Text>
                  )}
                </Box>
                <Box>
                  <FormControl isInvalid={!!errors.farms && touched.farms}>
                    <FormLabel
                      htmlFor="farms"
                      fontSize={'16px'}
                      color={'black.300'}
                      mb={2}
                      fontWeight="400"
                    >
                      Farms Grown
                    </FormLabel>
                    <MultiSelectDropdown
                      dataCy="new-farm-farms"
                      label="Select Farms"
                      items={Array.from(displayFarms)}
                      onSelectFilter={onSelectFilter('farms', setFieldValue)}
                      onBlur={() => setTouched({ ...touched, farms: true })}
                      multi
                      name="manage"
                      hasError={!!(errors && errors.farms && touched.farms)}
                      selectedItems={[]}
                      clearSelection={clearFarmSelection}
                      uniqueID="id"
                      displayParam="external_name"
                    />
                    {errors && errors.farms && touched.farms && (
                      <Text color="#E53E3E" marginTop="0.5rem" fontSize="0.875rem">
                        {errors.farms}
                      </Text>
                    )}
                  </FormControl>
                </Box>
                <Box alignSelf={'flex-start'}>
                  <Button
                    data-cy="add-variety-button"
                    theme="primary"
                    fontWeight="400"
                    fontSize="18px"
                    variant="solid"
                    borderColor="primary.500 !important"
                    color="white"
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    alignSelf={'flex-end'}
                  >
                    <Icon
                      as={Plus}
                      strokeWidth="1.5px"
                      height="24px"
                      width="auto"
                      color="currentcolor"
                      mr={2}
                    />
                    Add New Variety
                    {isSubmitting && (
                      <>
                        <Box px={1.5}></Box>
                        <Spinner color="white.500" size="sm" />
                      </>
                    )}
                  </Button>
                </Box>
              </Flex>
            </form>
          )}
        </Formik>
        <Flex justifyContent={'space-between'} alignContent={'center'} mb={3} mt={'3rem'}>
          <Heading
            fontSize={'1.688rem'}
            color="dark.coolGray.500"
            fontWeight={'400'}
            data-cy="tab-heading"
          >
            Manage Varieties
          </Heading>
        </Flex>
        <hr
          style={{
            marginBottom: '2rem'
          }}
        />
        {varieties.length == 0 ? (
          <>
            {loadingVarieties ? (
              <Skeleton height={25} width={'100%'} />
            ) : (
              <Text>This organization has no varieties. Add varieties to proceed.</Text>
            )}
          </>
        ) : (
          <>
            <Formik
              initialValues={editValues}
              onSubmit={(values, { resetForm }) => {
                handleEdit(values, resetForm);
              }}
              validationSchema={ManageVarietiesSchema}
              validateOnBlur={true}
              validateOnChange={true}
              enableReinitialize={shouldReloadValues}
            >
              {({
                isValid,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                setTouched,
                dirty
              }: FormikProps<ManageVariety>) => (
                <form onSubmit={handleSubmit}>
                  <Table className={`${styles.table__content} ${styles.table__bordered}`} size="sm">
                    <Thead className={styles.table__content__head} zIndex="docked">
                      <Tr
                        className={`${styles.table__content__row} ${styles.table__content__head__row}`}
                      >
                        <Th textTransform="capitalize" colSpan={1}>
                          Variety Name
                        </Th>
                        <Th textTransform="capitalize" colSpan={1}>
                          Farms Grown
                        </Th>
                        <Th textTransform="capitalize" colSpan={1}>
                          Frequency Of Harvest (in days)
                        </Th>
                        <Th textTransform="capitalize" colSpan={1}>
                          Unit
                        </Th>
                        <Th textTransform="capitalize" colSpan={1}>
                          Grading Categories
                        </Th>
                        <Th textTransform="capitalize">Active Fields</Th>
                        <Th textTransform="capitalize" colSpan={1}>
                          Actions
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody className={`${styles.table__content__body}`}>
                      {varieties?.map((item, i) => (
                        <Tr
                          className={`${styles.table__content__row} ${styles.table__content__body__row}`}
                          key={i}
                        >
                          {currentEdit?.id === item.id ? (
                            <>
                              <Td verticalAlign={'top'}>
                                <FormControl isInvalid={!!errors.name && touched.name && dirty}>
                                  <InputGroup width={'350px'}>
                                    <InputLeftElement pointerEvents="none">
                                      <Icon
                                        as={Feather}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color={colors.input.placeholder}
                                        mx={2}
                                      />
                                    </InputLeftElement>
                                    <Field
                                      as={InputWithIcon}
                                      id="name"
                                      type="text"
                                      name="name"
                                      autoComplete="false"
                                      placeholder="Enter variety grown"
                                      data-cy="edit-variety-name"
                                    />
                                  </InputGroup>
                                  {errors && errors.name && dirty && (
                                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                                  )}
                                </FormControl>
                              </Td>
                              <Td verticalAlign={'top'} textTransform="capitalize">
                                <FormControl
                                  isInvalid={!!errors.farms && touched.farms && dirty}
                                  alignSelf="flex-start"
                                >
                                  <MultiSelectDropdown
                                    dataCy="edit-farm-farms"
                                    label="Select Farms"
                                    items={Array.from(displayFarms)}
                                    onSelectFilter={onSelectFilter('farms', setFieldValue)}
                                    onBlur={() => setTouched({ ...touched, farms: true })}
                                    multi
                                    name="manage"
                                    hasError={!!(errors && errors.farms && touched.farms && dirty)}
                                    selectedItems={editValues?.farms}
                                    clearSelection={clearFarmSelection}
                                    uniqueID="id"
                                    displayParam="external_name"
                                  />
                                  {errors && errors.farms && touched.farms && dirty && (
                                    <Text
                                      color="#E53E3E"
                                      marginTop="0.5rem"
                                      fontSize="0.875rem"
                                      textAlign="left"
                                    >
                                      {errors.farms}
                                    </Text>
                                  )}
                                </FormControl>
                              </Td>
                              <Td verticalAlign={'top'} textTransform={'capitalize'}>
                                {item.harvest_frequency_days}
                              </Td>
                              <Td verticalAlign={'top'}>
                                <Box>
                                  <BasicDropDown
                                    dataCy={'edit-farm-unit'}
                                    key={'unit'}
                                    id="unit"
                                    name="manage"
                                    items={unitDropdownItems}
                                    showSelected={!!unitDropdownItems.length}
                                    onSelectFilter={onSelectFilter('unit', setFieldValue)}
                                    onBlur={(id: any) => {
                                      if (id === 'unit') {
                                        setTouched({ ...touched, unit: true });
                                      }
                                    }}
                                    selectedItems={
                                      units.findIndex((el: any) => el === currentEdit?.unit) === -1
                                        ? undefined
                                        : units.findIndex((el: any) => el === currentEdit?.unit)
                                    }
                                    clearSelection={clearSelection}
                                    hasError={!!(errors && errors.unit && touched.unit)}
                                    matchWidth={true}
                                    color="black.400"
                                    triggerProps={{
                                      width: '350px',
                                      height: '43px',
                                      minWidth: 'max-content',
                                      border: '1.5px solid #CBD2D9',
                                      __css: {
                                        '& > span': {
                                          display: 'grid !important',
                                          gridTemplateColumns: '1fr 1rem',
                                          gridColumnGap: '0.5rem',
                                          width: '100%',
                                          textAlign: 'left',
                                          alignItems: 'center',
                                          '& > .chakra-button__icon': {
                                            marginInlineEnd: 0,
                                            '& > svg': {
                                              transform: 'scale(1)',

                                              '&.drop-arrow': {
                                                transform: 'scale(0.7)'
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }}
                                    dropdownListProps={{
                                      width: '350px',
                                      border: '0px',
                                      boxShadow: '0px 1px 6px 0px rgba(12, 12, 13, 0.15)'
                                    }}
                                  >
                                    <Text fontWeight={400} color="dark.coolGray.300">
                                      Select unit
                                    </Text>
                                  </BasicDropDown>
                                  <Text
                                    mb={0}
                                    mt={1}
                                    color="black.300"
                                    as="p"
                                    fontSize="14px"
                                    fontWeight={400}
                                    textAlign="left"
                                  >
                                    Select the name of a unit of your variety
                                  </Text>
                                  {errors && errors.unit && touched.unit && (
                                    <Text
                                      color="#E53E3E"
                                      marginTop="0.5rem"
                                      fontSize="0.875rem"
                                      textAlign="left"
                                    >
                                      {errors.unit}
                                    </Text>
                                  )}
                                </Box>
                              </Td>
                              <Td verticalAlign={'top'}>
                                <Box>
                                  <div
                                    className={`${styles.custom_textarea} ${
                                      errors && errors.categories && touched.categories
                                        ? `${styles.invalid_textarea}`
                                        : ''
                                    }`}
                                    onClick={() => setEIsClicked(true)}
                                    onMouseLeave={() =>
                                      isEClicked
                                        ? setTouched({ ...touched, categories: true })
                                        : () => {}
                                    }
                                  >
                                    <input
                                      value={input}
                                      placeholder="Enter a tag eg. 30cm, 40cm, 50cm"
                                      onKeyDown={(e) => onKeyDown(e, setFieldValue, 'edit')}
                                      onKeyUp={onKeyUp}
                                      onChange={onChange}
                                      key={currentEdit.id}
                                      data-cy="edit-farm-categories"
                                    />
                                    {editTextarea &&
                                      tags?.map((tag: any, i: number) => (
                                        <Badge
                                          backgroundColor={badgeColors[i]}
                                          key={i}
                                          className={`${styles.custom_badge} ${styles.tag}`}
                                          color={
                                            whiteBadges.includes(i)
                                              ? '#FFFFFF !important'
                                              : '#2C2E2D'
                                          }
                                        >
                                          {tag}
                                          {!uneditableTags?.includes(tag) && (
                                            <Icon
                                              as={X}
                                              strokeWidth="1.5px"
                                              height="15px"
                                              width="auto"
                                              color="currentcolor"
                                              ml={'0.5rem'}
                                              onClick={() => deleteTag(i, setFieldValue)}
                                              cursor="pointer"
                                            />
                                          )}
                                        </Badge>
                                      ))}
                                  </div>
                                  <Text
                                    mb={0}
                                    mt={1}
                                    color="black.300"
                                    as="p"
                                    fontSize="14px"
                                    fontWeight={400}
                                    textAlign="left"
                                  >
                                    List a maximum of 10 comma separated categories
                                  </Text>
                                  {errors && errors.categories && touched.categories && (
                                    <Text
                                      color="#E53E3E"
                                      marginTop="0.5rem"
                                      fontSize="0.875rem"
                                      textAlign="left"
                                    >
                                      {errors.categories}
                                    </Text>
                                  )}
                                </Box>
                              </Td>
                              <Td verticalAlign={'top'}>{item.number_of_fields}</Td>
                              <Td verticalAlign={'top'}>
                                <Flex justifyContent={'center'}>
                                  <IconButton
                                    aria-label="Save variety edit"
                                    backgroundColor={'transparent'}
                                    height={'auto'}
                                    type="submit"
                                    className={styles.no_outline_button}
                                    disabled={!isValid || isLoading}
                                    icon={
                                      <Icon
                                        as={Save}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color="green.400"
                                      />
                                    }
                                    data-cy={`save-edit-${i}`}
                                  />
                                  <IconButton
                                    aria-label="Save variety status"
                                    backgroundColor={'transparent'}
                                    height={'auto'}
                                    onClick={() => deleteVariety(item)}
                                    disabled={isLoading}
                                    className={styles.no_outline_button}
                                    icon={
                                      <Icon
                                        as={Trash2}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color={item.no_of_fields ? 'dark.coolGray.200' : '#DB414E'}
                                      />
                                    }
                                    data-cy={`delete-variety-${i}`}
                                  />
                                </Flex>
                              </Td>
                            </>
                          ) : (
                            <>
                              <Td colSpan={1}>{item.external_name}</Td>
                              <Td colSpan={1}>
                                {item.farms?.map((farm: any, i: number) => (
                                  <Badge
                                    key={i}
                                    border="1px solid #53A5FF"
                                    backgroundColor={'#DDEDFF'}
                                    className={`${styles.custom_badge}`}
                                    m={'4px'}
                                    color={'#52606D !important'}
                                  >
                                    {farm.external_name}
                                  </Badge>
                                ))}
                              </Td>
                              <Td colSpan={1} textTransform="capitalize">
                                {item.harvest_frequency_days}
                              </Td>
                              <Td colSpan={1}>{item.unit}</Td>
                              <Td colSpan={1} paddingInlineStart={0} paddingInlineEnd={0}>
                                <Flex
                                  alignContent={'center'}
                                  flexWrap={'wrap'}
                                  justifyContent={'center'}
                                  gap={'2px'}
                                >
                                  {item.production_categories
                                    ?.filter(
                                      (grade: any) => !grade.internal_name.includes('unknown')
                                    )
                                    ?.map((grade: any, i: number) => (
                                      <Badge
                                        backgroundColor={badgeColors[i]}
                                        key={i}
                                        className={`${styles.custom_badge} ${styles.tag}`}
                                        m={0}
                                        mx={'0.125rem'}
                                        flex={'0 0 33.333333%'}
                                        color={
                                          whiteBadges.includes(i) ? '#FFFFFF !important' : '#2C2E2D'
                                        }
                                      >
                                        <>{grade.external_name}</>
                                      </Badge>
                                    ))}
                                </Flex>
                              </Td>
                              <Td>{item.number_of_fields}</Td>
                              <Td colSpan={1}>
                                <Flex justifyContent={'center'}>
                                  <IconButton
                                    aria-label="Save variety edit"
                                    backgroundColor={'transparent'}
                                    height={'auto'}
                                    disabled={isLoading}
                                    className={styles.no_outline_button}
                                    onClick={() => editVariety(item)}
                                    icon={
                                      <Icon
                                        as={Edit3}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color="green.400"
                                      />
                                    }
                                    data-cy={`edit-variety-${i}`}
                                  />
                                  <IconButton
                                    aria-label="Save variety status"
                                    backgroundColor={'transparent'}
                                    height={'auto'}
                                    onClick={() => deleteVariety(item)}
                                    className={styles.no_outline_button}
                                    disabled={isLoading}
                                    icon={
                                      <Icon
                                        as={Trash2}
                                        strokeWidth="1.5px"
                                        height="24px"
                                        width="auto"
                                        color={item.no_of_fields ? 'dark.coolGray.200' : '#DB414E'}
                                      />
                                    }
                                    data-cy={`delete-variety-${i}`}
                                  />
                                </Flex>
                              </Td>
                            </>
                          )}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </form>
              )}
            </Formik>
          </>
        )}
      </Box>
    </>
  );
}

export default ManageVarieties;
