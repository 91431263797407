import { colors } from 'theme';

const styles = {
  global: {
    input: {
      color: 'teal.500',
      _hover: {
        textDecoration: 'underline'
      },
      _placeholder: {
        color: colors.error[300],
        fontWeight: 300
      }
    }
  }
};

export default styles;
