import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  Img,
  Spinner,
  Text,
  Link,
  InputRightElement
} from '@chakra-ui/react';
import { Field, Formik, FormikProps } from 'formik';
import { Eye, EyeOff, Hash, Lock, Mail } from 'react-feather';
import { InputWithIcon, Button } from 'Components';
import { colors } from 'theme';

import { useAuthContext } from 'Context';
import Logo from 'assets/images/lima-icon.svg';
import { ConfirmForgotPasswordSchema } from 'Validations';
import { ForgotPasswordFields } from 'Context/types';
import { NotificationToast } from 'Components/Cards';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import useDocumentTitle from 'Helpers/useDocumentTitle';

export default function ConfirmForgotPassword(): JSX.Element {
  const { trackPageView } = useMatomo();
  useDocumentTitle('Confirm Forgot Password | Lima Asili');

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [initialValues, setInitialValues] = useState<ForgotPasswordFields>({
    email: '',
    new_password: '',
    confirmPassword: ''
  });
  const [shouldReloadValues, setShouldReloadValues] = useState(true);
  const [isVisible, setVisible] = useState(false);
  const [cIsVisible, setCVisible] = useState(false);
  const {
    functions: { confirmForgotPassword, setHasError },
    variables: { hasError, responseInfo, confirmForgotPasswordSuccess },
    loadingStates
  } = useAuthContext();
  const { isLoading } = loadingStates;

  const handleLogin = async (values: ForgotPasswordFields) => {
    confirmForgotPassword(values);
  };

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    setEmail(query.get('email'));
    setCode(query.get('code'));
  }, [query]);

  useEffect(() => {
    if (email && code) {
      setInitialValues({
        email: email,
        code: code
      });
      setShouldReloadValues(true);
    }
  }, [email, code]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (shouldReloadValues) {
      // formik `enableReinitialize` is almost infinite so we only do it for a short time
      timeout = setTimeout(() => {
        setShouldReloadValues(false);
      }, 2000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shouldReloadValues]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (hasError) {
      timeout = setTimeout(() => {
        setHasError(false);
      }, 5000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hasError, setHasError]);

  const onPasswordInput = (type: any, touched: any) => {
    if (type === 'confirm' && !touched.confirmPassword) setCVisible(false);
    else if (type !== 'confirm' && !touched.new_password) setVisible(false);
  };

  return (
    <Flex
      className="login"
      direction="column"
      height="100%"
      justifyContent="center"
      alignItems="center"
      mx="auto"
    >
      <Box width="400px">
        <Flex
          className="login__logo"
          width="80px"
          height="80px"
          justifyContent="center"
          alignItems="center"
          mb={6}
        >
          <Img src={Logo} />
        </Flex>
        <Heading as="h1" fontSize="4xl" fontWeight={400} mb={16}>
          Welcome back
        </Heading>
        <Heading as="h1" fontSize="3xl" color="primary.500" fontWeight={400} mt={14} mb="10">
          Forgot Password
        </Heading>
        {hasError && <NotificationToast type="error" message={responseInfo} />}
        {confirmForgotPasswordSuccess && (
          <Box mb={4} color="info.500" fontSize="0.9rem" fontWeight="normal">
            Password reset successfully, please login to continue{' '}
            <Link as={RouterLink} to="/login" color="primary.500">
              here
            </Link>
          </Box>
        )}
        {initialValues && (
          <Formik
            initialValues={initialValues}
            onSubmit={handleLogin}
            validationSchema={ConfirmForgotPasswordSchema}
            validateOnBlur={true}
            enableReinitialize={shouldReloadValues}
          >
            {({
              isValid,
              handleSubmit,
              errors,
              touched,
              dirty,
              values
            }: FormikProps<ForgotPasswordFields>) => (
              <form className="login__form form" onSubmit={handleSubmit}>
                <FormControl mb={6} isInvalid={!!errors.email && touched.email}>
                  <FormLabel htmlFor="email">Email address</FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <Mail color={colors.input.placeholder} />
                    </InputLeftElement>
                    <Field
                      as={InputWithIcon}
                      id="email"
                      type="email"
                      name="email"
                      autoComplete="true"
                      placeholder="user@email.com"
                      value={values.email}
                    />
                  </InputGroup>
                  {errors && errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                </FormControl>
                <FormControl mb={6} isInvalid={!!errors.code && touched.code}>
                  <FormLabel htmlFor="email">Activation code</FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <Hash color={colors.input.placeholder} />
                    </InputLeftElement>
                    <Field
                      as={InputWithIcon}
                      id="code"
                      name="code"
                      type="number"
                      placeholder="At least 6 characters"
                      value={values.code}
                      readOnly={true}
                    />
                  </InputGroup>
                  {errors && errors.code && <FormErrorMessage>{errors.code}</FormErrorMessage>}
                </FormControl>
                <FormControl mb={6} isInvalid={!!errors.new_password && touched.new_password}>
                  <FormLabel htmlFor="new_password">New Password</FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <Lock color={colors.input.placeholder} />
                    </InputLeftElement>
                    <Field
                      as={InputWithIcon}
                      id="new_password"
                      type={!isVisible ? 'password' : 'text'}
                      name="new_password"
                      placeholder="At least 8 characters"
                      autoComplete="new-password"
                      value={values.new_password}
                      onInput={() => onPasswordInput('', touched)}
                    />
                    <InputRightElement onClick={() => setVisible(!isVisible)}>
                      {isVisible ? (
                        <Eye color={colors.input.placeholder} strokeWidth={1.5} />
                      ) : (
                        <EyeOff color={colors.input.placeholder} strokeWidth={1.5} />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {errors && errors.new_password && (
                    <FormErrorMessage>{errors.new_password}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mb={6} isInvalid={!!errors.confirmPassword && touched.confirmPassword}>
                  <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <Lock color={colors.input.placeholder} />
                    </InputLeftElement>
                    <Field
                      as={InputWithIcon}
                      id="confirmPassword"
                      name="confirmPassword"
                      type={!cIsVisible ? 'password' : 'text'}
                      placeholder="Should match the password above"
                      onInput={() => onPasswordInput('confirm', touched)}
                    />
                    <InputRightElement onClick={() => setCVisible(!cIsVisible)}>
                      {cIsVisible ? (
                        <Eye color={colors.input.placeholder} strokeWidth={1.5} />
                      ) : (
                        <EyeOff color={colors.input.placeholder} strokeWidth={1.5} />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {errors && errors.confirmPassword && (
                    <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
                  )}
                </FormControl>
                <Box>
                  <Button
                    data-cy="btn-reset-password"
                    width="100%"
                    theme="primary"
                    disabled={!isValid || !dirty || isLoading}
                    type="submit"
                  >
                    Reset Password
                    {isLoading && (
                      <>
                        <Box px={1.5}></Box>
                        <Spinner color="white.500" size="sm" />
                      </>
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        )}
        <Box pt={8}>
          <Text as="p" color="dark.coolGray.400">
            Don’t have an account?{' '}
            <Link as={RouterLink} to="/register" color="primary.500">
              Sign up
            </Link>
          </Text>
          <Text as="p" mt={2} color="dark.coolGray.400">
            Already confirmed account?{' '}
            <Link as={RouterLink} to="/login" color="primary.500">
              Log In
            </Link>
          </Text>
        </Box>
      </Box>
    </Flex>
  );
}
