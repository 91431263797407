import React from 'react';
import styles from './Settings.module.scss';
import { Box, Text, Icon, Tabs, TabList, TabPanels, Tab, Flex } from '@chakra-ui/react';
import { Feather, Map, Users } from 'react-feather';
import { NavLink, Outlet } from 'react-router-dom';

function Settings() {
  return (
    <Box
      width="100%"
      height="82vh"
      boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
      py={6}
      px={6}
      borderRadius={8}
      data-cy="manage-farms"
      my={'auto'}
      backgroundColor={'#FFFFFF'}
    >
      <Flex flexDirection={'column'} height={'100%'}>
        <Text mb={4} color="dark.coolGray.500" fontSize={'22px'}>
          Manage your preferences and account settings
        </Text>
        <hr
          style={{
            borderColor: '#CBD2D9'
          }}
        ></hr>
        <Tabs
          variant="unstyled"
          orientation="vertical"
          mt={4}
          width={'100%'}
          height={'100%'}
          overflowY={'hidden'}
        >
          <TabList borderRight={'1px solid #CBD2D9'} py={6} px={4} width={'300px'}>
            <Tab className={`${styles.no_outline} ${styles.nav_button}`}>
              <NavLink
                to="farms"
                className={({ isActive }) => (isActive ? `${styles.active_tab}` : undefined)}
                data-cy="manage-farms"
              >
                <Icon
                  as={Map}
                  strokeWidth="1.5px"
                  height="24px"
                  width="auto"
                  color="currentcolor"
                  mr={2}
                />
                Manage Farms
              </NavLink>
            </Tab>
            <Tab className={`${styles.no_outline} ${styles.nav_button}`}>
              <NavLink
                to="varieties"
                className={({ isActive }) => (isActive ? `${styles.active_tab}` : undefined)}
                data-cy="manage-varieties"
              >
                <Icon
                  as={Feather}
                  strokeWidth="1.5px"
                  height="24px"
                  width="auto"
                  color="currentcolor"
                  mr={2}
                />
                Manage Varieties
              </NavLink>
            </Tab>
            <Tab className={`${styles.no_outline} ${styles.nav_button}`}>
              <NavLink
                to="members"
                className={({ isActive }) => (isActive ? `${styles.active_tab}` : undefined)}
                data-cy="manage-members"
              >
                <Icon
                  as={Users}
                  strokeWidth="1.5px"
                  height="24px"
                  width="auto"
                  color="currentcolor"
                  mr={2}
                />
                Manage Members
              </NavLink>
            </Tab>
          </TabList>
          <TabPanels pl={'2rem'} overflowY={'auto'} className="custom-scrollbar custom-placeholder">
            <Outlet />
          </TabPanels>
        </Tabs>
      </Flex>
    </Box>
  );
}

export default Settings;
