import React from 'react';

import { ResponsiveBar, Bar, BarDatum } from '@nivo/bar';
import CustomBarComponent from './CustomBarComponent';
import CustomTooltip from './CustomTooltip';
import { FarmProductionDuration } from 'Context/GraphContext';
import { line } from 'd3-shape';

export type FarmProductionBarChartProps = {
  duration: FarmProductionDuration;
  data: BarDatum[];
  yMax: number;
  selectedYear: string;
  durationKeys: string[];
  durationColors: string[];
  demandData: any[];
};

export const nivoTheme = {
  grid: {
    line: {
      strokeDasharray: '2 2'
    }
  },
  axis: {
    legend: {
      text: {
        fontSize: 20,
        fontFamily: 'Be Vietnam Pro, sans-serif',
        color: '#52606d',
        fill: '#52606d'
      }
    },
    ticks: {
      text: {
        fontSize: 15,
        fontFamily: 'Be Vietnam Pro, sans-serif',
        color: '#52606d',
        fill: '#52606d'
      }
    }
  },
  legends: {
    text: {
      fontSize: 15,
      fontFamily: 'Be Vietnam Pro, sans-serif',
      color: '#52606d',
      fill: '#52606d'
    }
  }
};

const FarmProductionBarChart = (props: FarmProductionBarChartProps) => {
  const { duration, data, yMax, selectedYear, durationKeys, demandData, durationColors } = props;
  const legend = (): string => {
    switch (duration) {
      case 'weekly':
        return 'Weeks | ' + selectedYear;
      case 'monthly':
        return 'Months | ' + selectedYear;
      case 'daily':
        return 'Days | ' + selectedYear;
      default:
        return '';
    }
  };

  const dayGraphWidth = (): number => {
    return data.length * 45;
  };

  const Line = (props: any) => {
    const bars = props.bars;
    const unique = bars
      .map((item: any) => item.data.indexValue)
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index);

    const xScale = props.xScale;
    const yScale = props.yScale;

    const lineGenerator = line()
      .x((bar: any) => xScale(bar) + bars[0].width * 0.5 * durationKeys?.length)
      .y((bar: any) => yScale(getY(bar)));

    return (
      <>
        <path
          d={lineGenerator(unique) as string}
          fill="none"
          stroke="#66D998"
          style={{ pointerEvents: 'none', strokeWidth: '2' }}
        />
      </>
    );
  };

  const getY = (yValue: any) => {
    const found = demandData?.find((el: any) => el.week === +yValue)?.stems;
    return found ?? 0;
  };
  const view = () => {
    switch (duration) {
      case 'daily':
        return data.length < 50 ? (
          <ResponsiveBar
            theme={nivoTheme}
            groupMode="grouped"
            colors={durationColors}
            data={data}
            keys={durationKeys}
            indexBy="period"
            valueScale={{ type: 'linear' }}
            padding={0.2}
            margin={{ top: 10, right: 0, bottom: 40, left: 0 }}
            enableGridY={true}
            enableGridX={true}
            maxValue={yMax * 1.3}
            axisLeft={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -35
            }}
            barComponent={CustomBarComponent}
            tooltip={CustomTooltip}
          />
        ) : (
          <Bar
            height={700}
            width={dayGraphWidth()}
            theme={nivoTheme}
            groupMode="grouped"
            colors={durationColors}
            data={data}
            keys={durationKeys}
            indexBy="period"
            valueScale={{ type: 'linear' }}
            padding={0.4}
            margin={{ top: 10, right: 0, bottom: 56, left: 0 }}
            enableGridY={true}
            enableGridX={true}
            maxValue={yMax * 1.3}
            axisLeft={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -35
            }}
            barComponent={CustomBarComponent}
            tooltip={CustomTooltip}
            indexScale={{ type: 'band', round: false }}
          />
        );
      case 'weekly':
        return (
          <ResponsiveBar
            theme={nivoTheme}
            groupMode="grouped"
            colors={durationColors}
            data={data}
            keys={durationKeys}
            indexBy="period"
            valueScale={{ type: 'linear' }}
            padding={0.4}
            margin={{ top: 10, right: 0, bottom: 56, left: 0 }}
            enableGridY={true}
            enableGridX={true}
            maxValue={yMax * 1.3}
            axisLeft={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              legend: legend(),
              legendPosition: 'middle',
              legendOffset: 40
            }}
            barComponent={CustomBarComponent}
            tooltip={CustomTooltip}
            layers={
              durationKeys?.includes('production_plan')
                ? ['grid', 'axes', 'bars', Line, 'markers']
                : ['grid', 'axes', 'bars', 'markers']
            }
          />
        );
      case 'monthly':
        return (
          <ResponsiveBar
            theme={nivoTheme}
            groupMode="grouped"
            colors={durationColors}
            data={data}
            keys={durationKeys}
            indexBy="period"
            valueScale={{ type: 'linear' }}
            padding={0.65}
            margin={{ top: 10, right: 0, bottom: 60, left: 0 }}
            enableGridY={true}
            enableGridX={true}
            maxValue={yMax * 1.3}
            axisLeft={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              legend: legend(),
              legendPosition: 'middle',
              legendOffset: 40
            }}
            barComponent={CustomBarComponent}
            tooltip={CustomTooltip}
          />
        );
      default:
        return <></>;
    }
  };

  return view();
};

export default FarmProductionBarChart;
