import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email address!').required('Email is required.'),
  org: yup.string().required('Organization is required.'),
  farm: yup
    .array()
    .of(yup.string())
    .when('org', {
      is: 'Lima Labs',
      then: yup.array().of(yup.string()).nullable(),
      otherwise: yup.array().of(yup.string()).min(1, 'Farm is required.')
    })
});

export default schema;
