import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text
} from '@chakra-ui/react';
import { FilterDropDown, MultiSelectDropdown } from 'Components';
import { ForecastedYieldsFilters, useGraphContext } from 'Context/GraphContext';
import { separateComma } from 'Helpers/helpers';
import { ActiveFieldsType } from 'data/GraphRepositoryImpl';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { Calendar } from 'react-feather';

type HeaderProps = {
  fields: ActiveFieldsType[];
  subLabel: string;
  multiSelectName: string;
  noDataFields: any;
};
function ForecastByFieldHeader(props: HeaderProps) {
  const { fields, subLabel, multiSelectName, noDataFields } = props;
  const currentYear = moment().format('YYYY');
  const durationList = ['Daily', 'Weekly'];
  const forecastList = [
    { label: 'Latest Forecast', value_week: 'latest', value_day: 'latest' }
    // NB: Uncomment the commented code to re-enable day/weeks out milestone functionality
    // { label: '1 Week Forecast', value_week: 'weeks_out_1', value_day: 'days_out_7' },
    // { label: '2 Weeks Forecast', value_week: 'weeks_out_2', value_day: 'days_out_14' }
  ];
  const [forecastLabel, setForecastLabel] = useState<string>(forecastList[0].label);
  const [displayDurationFilter, setDisplayDurationFilter] = useState<boolean>(true);
  const [displayForecastFilter, setDisplayForecastFilter] = useState<boolean>(true);
  const [displayFieldFilter, setDisplayFieldFilter] = useState<boolean>(true);
  const [selectedDuration, setSelectedDuration] = useState<number>(1);
  const [selectedFields, setSelectedFields] = useState<any[] | null>(null);
  const {
    setDuration,
    duration,
    loadingForecastedHarvestByField,
    setForecastedActiveFields,
    loadingForecastedActiveFields,
    setSelectedForecastedFields,
    setForecastedYieldsFilters,
    selectedForecastedFields,
    forecastedActiveFields,
    selectedForecast,
    setSelectedForecast,
    currentForecastTab,
    setCurrentForecastTab,
    forecastedHarvestYields
  } = useGraphContext();
  const onSelectDuration = () => {
    return (selection: number | number[]) => {
      switch (selection) {
        case 0:
          setDuration('daily');
          break;
        case 1:
          setDuration('weekly');
          break;
        case 2:
          setDuration('monthly');
          break;
        default:
          break;
      }
    };
  };
  const onSelectForecast = () => {
    return (selection: number) => {
      setForecastLabel(forecastList[selection].label);
    };
  };
  const onSelectFilter = () => {
    return (selection: any | any[]) => {
      setSelectedFields(selection ?? []);
    };
  };
  const onMouseLeave = () => {
    return () => {
      if (
        selectedForecastedFields?.filter((x) => !selectedFields?.includes(x))?.length ||
        selectedFields?.filter((x) => !selectedForecastedFields?.includes(x))?.length
      ) {
        setCurrentForecastTab(multiSelectName);
        setSelectedForecastedFields(selectedFields ?? []);
        const finalActive = fields?.map((el: ActiveFieldsType) => {
          el['selected'] = selectedFields?.includes(el.id) ?? false;
          return el;
        });
        setForecastedActiveFields(finalActive ?? []);
      }
    };
  };
  useEffect(() => {
    setDisplayDurationFilter(false);
    const initializeTimeout = setTimeout(() => {
      setDisplayDurationFilter(true);
    }, 10);
    return () => {
      clearTimeout(initializeTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDuration]);
  useEffect(() => {
    setDisplayForecastFilter(false);
    const initializeTimeout = setTimeout(() => {
      setDisplayForecastFilter(true);
    }, 10);
    return () => {
      clearTimeout(initializeTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedForecast]);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (currentForecastTab && currentForecastTab !== multiSelectName) {
      setDisplayFieldFilter(false);
      timeout = setTimeout(() => {
        setDisplayFieldFilter(true);
      }, 10);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedForecastedFields?.length]);
  useEffect(() => {
    if (duration) {
      const found = duration === 'weekly' ? 1 : 0;
      setSelectedDuration(found);
    }
  }, [duration]);
  useEffect(() => {
    const forecast = duration == 'weekly' ? 'value_week' : 'value_day';
    const filters: ForecastedYieldsFilters = {
      forecast_duration:
        forecastList.find((el: any) => el.label === forecastLabel)?.[forecast] ?? ''
    };
    setForecastedYieldsFilters(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, forecastLabel]);
  useEffect(() => {
    const found = forecastList.findIndex((el: any) => el.label == forecastLabel);
    setSelectedForecast(found !== -1 ? found : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastLabel]);
  return (
    <Box>
      <Flex alignItems={'flex-start'} justifyContent="space-between" flexWrap={'wrap'} gap="1.6rem">
        <Box textAlign={'left'}>
          <Heading as="h4" mb="2" fontSize="1.35rem" fontWeight="normal" color="dark.coolGray.600">
            Harvest by Field
          </Heading>
          <Text as="p" color="dark.coolGray.500">
            {`${currentYear} ${subLabel}` || <Skeleton count={1} />}
          </Text>
        </Box>
        <Flex alignItems={'center'} gap="0.75rem" flexWrap={'wrap'}>
          {displayForecastFilter && (
            <FilterDropDown
              label={forecastLabel}
              items={forecastList.map((el: any) => el.label)}
              selectedItem={selectedForecast}
              onSelectFilter={onSelectForecast()}
              name="harvest-by-field"
              LeftIcon={Calendar}
            />
          )}
          {displayDurationFilter && (
            <FilterDropDown
              label={`${duration}`}
              items={durationList}
              selectedItem={selectedDuration}
              onSelectFilter={onSelectDuration()}
              name="harvest-by-field"
              LeftIcon={Calendar}
            />
          )}
          {loadingForecastedActiveFields ? (
            <Box>
              <Skeleton height={'2.5rem'} width={220} />
            </Box>
          ) : (
            <>
              {!!fields?.length && displayFieldFilter && (
                <MultiSelectDropdown
                  dataCy="harvest-by-field"
                  label={`Field${fields?.length > 1 ? 's' : ''}`}
                  items={fields ?? []}
                  onSelectFilter={onSelectFilter()}
                  onBlur={() => {}}
                  multi
                  name="harvest-by-field"
                  selectedItems={selectedForecastedFields ?? []}
                  clearSelection={false}
                  zIndex={100}
                  toggleAll
                  searchable
                  showSubmit
                  onSubmit={onMouseLeave()}
                  uniqueID="id"
                  displayParam="external_name"
                />
              )}
            </>
          )}
        </Flex>
      </Flex>
      {!loadingForecastedHarvestByField && (
        <Flex gap={'0.75rem'} flexWrap="wrap" mt="1.65rem">
          <Box
            bg="#EBFCF4"
            padding="0.55rem 0.75rem"
            fontSize={'16px'}
            minWidth="180px"
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            justifyContent={'center'}
            borderRadius={'8px'}
          >
            <Flex alignItems={'center'} gap="0.15rem">
              <Text fontSize={'16px'} color="dark.coolGray.500">
                Total Fields
              </Text>
            </Flex>
            <Text
              fontWeight={'400'}
              fontSize={'22px'}
              lineHeight={'33px'}
              color="neutral.600"
              mt="0.75rem"
              mb="0.25rem"
            >
              {separateComma(forecastedActiveFields?.length ?? 0)}
            </Text>
          </Box>
        </Flex>
      )}
      {!!noDataFields?.length &&
        !!forecastedHarvestYields?.maxPred &&
        !loadingForecastedHarvestByField && (
          <Box textAlign={'left'} my="0.5rem">
            <Text className="text-muted" as="span">
              {noDataFields?.length} of the selected fields do not have data for the given
              filters.&nbsp;
            </Text>
            <Box display={'inline-block'}>
              <Popover>
                <PopoverTrigger>
                  <Text textDecoration={'underline'} cursor="pointer" className="text-muted">
                    See more
                  </Text>
                </PopoverTrigger>
                <PopoverContent
                  bg="white"
                  color="dark.coolGray.500"
                  width="fit-content"
                  maxWidth="35vw"
                >
                  <PopoverHeader color={'green.500'} fontWeight="500">
                    <Text>Field names</Text>
                  </PopoverHeader>
                  <PopoverBody>{noDataFields?.sort()?.join(',  ')}</PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          </Box>
        )}
    </Box>
  );
}

export default ForecastByFieldHeader;
