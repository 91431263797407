import React, { useEffect } from 'react';
import { Box, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { useAppContext, useAuthContext } from 'Context';
import { Feather, MapPin, Power } from 'react-feather';
import { BasicDropDown } from 'Components';
import { BasicDropDownItem } from 'Components/types';
import Skeleton from 'react-loading-skeleton';
import { useGraphContext } from 'Context/GraphContext';
import { useCookies } from 'react-cookie';

export default function TopNav(): JSX.Element {
  const {
    variables: { user },
    functions: { logout },
    loadingStates
  } = useAuthContext();
  const { loadingBlocks } = loadingStates;
  const { varieties, farms } = useAppContext();
  const { loadingGraphData } = useGraphContext();
  const [userCookie] = useCookies(['user']);

  useEffect(() => {
    // logout the user if the user cookie is invalid or missing
    if (!userCookie?.user) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCookie]);

  const profileDropDownItems: BasicDropDownItem[] = [
    {
      leftIcon: <Power />,
      onClick: async () => await logout(),
      label: 'Log Out',
      minWidth: 'max-content'
    }
  ];

  const farmDropdownItems: BasicDropDownItem[] = farms.map((item) => ({
    label: `${item.external_name}, ${item.city.replaceAll('_', ' ')}`,
    onClick: () => {},
    id: item.id
  }));

  const varietyDropdownItems: BasicDropDownItem[] = varieties?.map((item) => ({
    label: `${item.external_name}`,
    id: item.id
  }));

  return (
    <>
      {location.pathname.includes('settings') ? (
        <Heading
          fontSize={'2.125rem'}
          color="dark.coolGray.600"
          fontWeight={'400'}
          mb={3}
          lineHeight="3.1875rem"
          data-cy="page-title"
        >
          Settings
        </Heading>
      ) : (
        <Flex className="top-nav-left">
          <BasicDropDown
            triggerButtonDisabled={loadingGraphData}
            dataCy="farm-dropdown"
            items={farmDropdownItems}
            showSelected={!!farmDropdownItems.length}
            onSelectFilter={() => undefined}
            matchWidth={true}
            color="black.400"
            triggerProps={{
              leftIcon: <Icon w="24px" h="24px" as={MapPin} />,
              width: '230px',
              minWidth: 'max-content',
              mr: 4,
              __css: {
                '& > span': {
                  display: 'grid !important',
                  gridTemplateColumns: '1rem 1fr 1rem',
                  gridColumnGap: '0.5rem',
                  width: '100%',
                  textAlign: 'left',
                  alignItems: 'center',
                  '& > .chakra-button__icon': {
                    marginInlineEnd: 0,
                    '& > svg': {
                      transform: 'scale(1)',

                      '&.drop-arrow': {
                        transform: 'scale(0.7)'
                      }
                    }
                  }
                }
              }
            }}
            dropdownListProps={{
              width: '230px',
              border: '0px',
              boxShadow: '0px 1px 6px 0px rgba(12, 12, 13, 0.15)'
            }}
            name="fD"
          >
            {loadingBlocks && (
              <Box ml={2}>
                <Skeleton height={25} width={200} />
              </Box>
            )}
            {farmDropdownItems.length === 0 && !loadingBlocks && (
              <Text paddingLeft={2} color="black.400" fontSize="initial">
                Nothing to select
              </Text>
            )}
          </BasicDropDown>
          <BasicDropDown
            triggerButtonDisabled={loadingGraphData}
            items={varietyDropdownItems}
            onSelectFilter={() => undefined}
            showSelected={!!varietyDropdownItems.length}
            matchWidth={true}
            color={'black.300'}
            triggerProps={{
              leftIcon: <Icon w="24px" h="24px" as={Feather} mr="4px" />,
              width: '190px',
              minWidth: 'max-content',
              __css: {
                '& > span': {
                  display: 'grid !important',
                  gridTemplateColumns: '1rem 1fr 1rem',
                  gridColumnGap: '0.5rem',
                  width: '100%',
                  textAlign: 'left',
                  alignItems: 'center',
                  '& > .chakra-button__icon': {
                    marginInlineEnd: 0,
                    '& > svg': {
                      transform: 'scale(1)',
                      '&.drop-arrow': {
                        transform: 'scale(0.7)'
                      }
                    }
                  }
                }
              }
            }}
            dropdownListProps={{
              width: '200px',
              border: '0px',
              boxShadow: '0px 1px 6px 0px rgba(12, 12, 13, 0.15)'
            }}
            name="vD"
          >
            {loadingBlocks && (
              <Box ml={2}>
                <Skeleton height={25} width={200} />
              </Box>
            )}
            {varietyDropdownItems.length === 0 && !loadingBlocks && (
              <Text paddingLeft={2} color="black.400" fontSize="initial">
                Nothing to select
              </Text>
            )}
          </BasicDropDown>
        </Flex>
      )}

      <Flex className="top-nav-right">
        <BasicDropDown items={profileDropDownItems} onSelectFilter={() => undefined} name="top_nav">
          {user ? `${user?.full_name}` : 'Name Here'}
        </BasicDropDown>
      </Flex>
    </>
  );
}
