import React from 'react';
import { FilterDatum } from 'Context/GraphContext';
import { Box, Badge, Tooltip, Icon } from '@chakra-ui/react';
import { Icon as FeatherIcon } from 'react-feather';

export type KeyColors = {
  activeTag: string;
  inactiveTag: string;
  backgroundHover: string;
  activeBackground: string;
  inactiveBackground: string;
};

export type KeyProps = {
  showTooltip: boolean;
  onClick: (id: string) => void;
  graphKeys: FilterDatum[];
  icon: FeatherIcon;
  clickable: boolean;
  colors?: KeyColors;
  leftOffset?: string;
  justifyContent?: string;
};

/**
 * @description This component generates graph keys/ legends where it is called
 * @param showTooltip boolean to enable/disable display of tooltip on hover
 * @param graphKeys FilterDatum[] array of the keys , labels , color and active state
 * @param clickable boolean that enables or disables onClick listener and event for the graph key
 * @param icon FeatherIcon that is display in each graph key in the color provided in the graphKeys param
 * @param justifyContent sets css value for justifyContent
 * @returns a JSX.Element element that contains graph keys
 */
export default function GraphKeys(props: KeyProps): JSX.Element {
  const {
    showTooltip,
    onClick,
    graphKeys,
    colors = {
      // default value for colors when not set
      activeTag: '#52606D',
      inactiveTag: '#818181',
      backgroundHover: '#f5f7fa',
      activeBackground: 'transparent',
      inactiveBackground: '#eeeeee'
    },
    leftOffset,
    icon,
    clickable,
    justifyContent
  } = props;
  return (
    <Box
      ml={leftOffset ?? 0}
      display="flex"
      flexDirection={'row'}
      gap={'5px'}
      my={2}
      flexWrap={'wrap'}
      justifyContent={justifyContent ?? 'initial'}
    >
      {graphKeys.map((tag: FilterDatum, i: number) => (
        <Box key={tag.id}>
          {showTooltip ? (
            <Tooltip
              hasArrow
              shouldWrapChildren
              label={`Click to ${tag.active ? 'disable' : 'enable'}`}
              transition={'all .1s ease-in-out'}
              as="div"
              width="100%"
            >
              <Badge
                backgroundColor={
                  tag.active ? `${colors.activeBackground}` : `${colors.inactiveBackground}`
                }
                key={i}
                display="flex"
                flexDirection={'row'}
                alignItems="center"
                gap={'4px'}
                cursor="pointer"
                border="0.5px solid #cbd2d9"
                borderRadius={'100px'}
                padding={'4px 10px 4px 7px'}
                boxShadow={`0px 1px 4px rgba(12, 12, 13, 0.15)`}
                textTransform={'capitalize'}
                fontWeight={tag.active ? '600' : '400'}
                fontSize="12"
                fontFamily="Be Vietnam Pro, sans-serif"
                color={tag.active ? `${colors.activeTag}` : `${colors.inactiveTag}`}
                _hover={{
                  backgroundColor: `${colors.backgroundHover}`
                }}
                onClick={() => onClick(tag.id)}
              >
                <Icon
                  as={icon}
                  fill={tag.fill}
                  color="transparent"
                  width={'1rem'}
                  height={'1rem'}
                />
                {tag.label}
              </Badge>
            </Tooltip>
          ) : (
            <>
              {clickable ? (
                <Badge
                  backgroundColor={
                    tag.active ? `${colors.activeBackground}` : `${colors.inactiveBackground}`
                  }
                  key={i}
                  display="flex"
                  flexDirection={'row'}
                  alignItems="center"
                  gap={'4px'}
                  cursor="pointer"
                  border="0.5px solid #cbd2d9"
                  borderRadius={'100px'}
                  padding={'4px 10px 4px 7px'}
                  boxShadow={`0px 1px 4px rgba(12, 12, 13, 0.15)`}
                  textTransform={'capitalize'}
                  fontWeight={tag.active ? '600' : '400'}
                  fontSize="12"
                  fontFamily="Be Vietnam Pro, sans-serif"
                  color={tag.active ? `${colors.activeTag}` : `${colors.inactiveTag}`}
                  _hover={{
                    backgroundColor: `${colors.backgroundHover}`
                  }}
                  onClick={() => onClick(tag.id)}
                >
                  <Icon
                    as={icon}
                    fill={tag.fill}
                    color="transparent"
                    width={'1rem'}
                    height={'1rem'}
                  />
                  {tag.label}
                </Badge>
              ) : (
                <Badge
                  key={i}
                  bgColor="transparent"
                  display="flex"
                  flexDirection={'row'}
                  alignItems="center"
                  gap={'4px'}
                  borderRadius={'100px'}
                  padding={'4px 10px 4px 7px'}
                  textTransform={'capitalize'}
                  fontWeight={'400'}
                  fontSize="12"
                  fontFamily="Be Vietnam Pro, sans-serif"
                  color={`${colors.activeTag}`}
                >
                  <Icon
                    as={icon}
                    fill={tag.fill}
                    color="transparent"
                    width={'1rem'}
                    height={'1rem'}
                  />
                  {tag.label}
                </Badge>
              )}
            </>
          )}
        </Box>
      ))}
    </Box>
  );
}
