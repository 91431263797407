import { Flex, Img } from '@chakra-ui/react';
//import { useAuthContext } from 'Context';
import Authenticated from 'Layout/Authenticated';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import FullLogo from 'assets/images/lima-icon-and-name.svg';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import useDocumentTitle from 'Helpers/useDocumentTitle';
import { useCookies } from 'react-cookie';

export default function NotFound(): JSX.Element {
  const { trackPageView } = useMatomo();
  useDocumentTitle('Not Found | Lima Asili');

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [userCookie] = useCookies(['user']);
  const isAuthenticated = userCookie?.user?.lima_auth;

  // const {
  //   variables: { isAuthenticated }
  // } = useAuthContext();
  if (isAuthenticated) {
    return (
      <Authenticated>
        <Flex
          w="100%"
          height="100%"
          minHeight="inherit"
          my="auto"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Img src={FullLogo} width="250px" />
        </Flex>
      </Authenticated>
    );
  }

  return <Navigate to="/login" />;
}
