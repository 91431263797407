import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import LineGraphCustomTooltip from './LineGraphCustomTooltip';
import { Datum } from '@nivo/legends';
import { nivoTheme } from './FarmProductionBarChart';
import { FarmProductionDuration } from 'Context/GraphContext';

export type FarmProductionLineGraphProps = {
  duration: FarmProductionDuration;
  data: any[];
  yMax: number;
  selectedYear: string;
  variety: any;
};

const FarmProductionLineGraph = (props: FarmProductionLineGraphProps) => {
  const { duration, data, yMax, selectedYear, variety } = props;
  /**
   * @description formats the legend string based on the duration selected by the user.
   * @returns formatted legend string e.g. 'Weeks | 2021'
   */
  const legend = (): string => {
    switch (duration) {
      case 'weekly':
        return 'Weeks | ' + selectedYear;
      case 'monthly':
        return 'Months | ' + selectedYear;
      case 'daily':
        return 'Days | ' + selectedYear;
    }
  };

  const getYMax = (): number | 'auto' | undefined => {
    return yMax * 1.1;
  };

  const datums = (): Datum[] => {
    const list: Datum[] = [];
    if (variety?.harvest_frequency == 'continuous') {
      const actual: Datum = {
        id: 'actuals',
        label: 'Actuals',
        hidden: false,
        color: '#905ED1',
        fill: '#905ED1'
      };
      const days_out_1: Datum = {
        id: 'days_out_1',
        label: 'Latest',
        hidden: false,
        color: '#68E5AA',
        fill: '#68E5AA'
      };
      const days_out_7: Datum = {
        id: 'days_out_7',
        label: '7 Days',
        hidden: false,
        color: '#FFC185',
        fill: '#FFC185'
      };
      const days_out_14: Datum = {
        id: 'days_out_14',
        label: '2 Weeks',
        hidden: false,
        color: '#A1A1A1',
        fill: '#A1A1A1'
      };
      list.push(actual);
      list.push(days_out_1);
      list.push(days_out_7);
      list.push(days_out_14);
    } else {
      const actual: Datum = {
        id: 'actuals',
        label: 'Actuals',
        hidden: false,
        color: '#905ED1',
        fill: '#905ED1'
      };
      const days_out_7: Datum = {
        id: 'days_out_7',
        label: 'Latest',
        hidden: false,
        color: '#68E5AA',
        fill: '#68E5AA'
      };
      const days_out_14: Datum = {
        id: 'days_out_14',
        label: '2 Weeks',
        hidden: false,
        color: '#A1A1A1',
        fill: '#A1A1A1'
      };
      const days_out_28: Datum = {
        id: 'days_out_28',
        label: '4 Weeks',
        hidden: false,
        color: '#AFE4EB',
        fill: '#AFE4EB'
      };
      list.push(actual);
      list.push(days_out_7);
      list.push(days_out_14);
      list.push(days_out_28);
    }

    return list;
  };
  const colors = () => {
    if (variety?.harvest_frequency == 'continuous') {
      return ['#905ED1', '#68E5AA', '#FFC185', '#A1A1A1'];
    } else return ['#905ED1', '#68E5AA', '#A1A1A1', '#AFE4EB'];
  };
  return (
    <ResponsiveLine
      data={data}
      colors={colors()}
      theme={nivoTheme}
      margin={{ top: 70, right: 60, bottom: 70, left: 25 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: getYMax(),
        stacked: false,
        reverse: false
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -35,
        legend: legend(),
        legendOffset: 45,
        legendPosition: 'middle'
      }}
      axisLeft={null}
      pointSize={duration === 'weekly' ? 0 : 12}
      pointColor="white"
      pointBorderWidth={3}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      lineWidth={3}
      useMesh={true}
      enableGridX={true}
      enableGridY={true}
      tooltip={LineGraphCustomTooltip}
      legends={[
        {
          data: datums(),
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: -50,
          itemTextColor: '#52606d',
          itemsSpacing: 3,
          itemDirection: 'left-to-right',
          itemWidth: 100,
          itemHeight: 20,
          itemOpacity: 1,
          symbolSize: 22,
          symbolShape: ({ fill }) => (
            <svg height="12" width="24" className="legend-svg">
              <line x1="0" y1="10" x2="200" y2="10" stroke={fill} strokeWidth="4" />
            </svg>
          ),
          symbolBorderColor: 'rgba(0, 0, 0, .5)'
        }
      ]}
    />
  );
};
export default FarmProductionLineGraph;
