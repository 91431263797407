import React, { useState, useEffect } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { GraphKeys } from 'Components';
import { useGraphContext } from 'Context/GraphContext';
import { HarvestByFieldProps } from 'data/GraphRepositoryImpl';
import { separateComma } from 'Helpers/helpers';
import { Circle } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { nivoTheme } from './../../FarmProductionBarChart';
import { Bar } from '@nivo/bar';
import CombinedBarAndLineChart from './../../CombinedBarAndLineChart';
import HarvestByFieldTooltip from './../../HarvestByField/HarvestByFieldTooltip';
import moment from 'moment';
import ForecastByFieldHeader from './ForecastByFieldHeader';

function ForecastByFieldChart(props: HarvestByFieldProps) {
  const { data, fields, tags, displayYMax } = props;
  const { duration, loadingForecastedHarvestByField } = useGraphContext();
  const getSubLabel = () => {
    const startWeek = moment().add(1, 'weeks').week();
    const endWeek = moment().add(4, 'weeks').week();
    const startDay = moment().add(1, 'weeks').startOf('week').add(1, 'day').format('MMM DD');
    const endDay = moment().add(4, 'weeks').endOf('week').add(1, 'day').format('MMM DD');
    if (duration == 'daily') {
      return `\u00A0 Days | ${startDay} to ${endDay}`;
    } else if (duration == 'weekly') {
      return `\u00A0 Weeks | ${startWeek} to ${endWeek}`;
    }
    return '';
  };
  const [displayData, setDisplayData] = useState<any[]>(
    data?.formattedData?.filter((el: any) => el.max) ?? []
  );
  const formatLeftAxisValue = (value: number): string => {
    return separateComma(value);
  };
  const getDurationKeys = (): string[] => {
    return tags?.withData?.map((tag: any) => tag.id) ?? [];
  };
  const getDurationColors = (): string[] => {
    return tags?.withData?.map((tag: any) => tag.fill) ?? [];
  };
  useEffect(() => {
    setDisplayData(data?.formattedData?.filter((el: any) => el.selectedTotal) ?? []);
  }, [data]);
  const currentYear = moment().format('YYYY');
  return (
    <Box width="100%">
      <ForecastByFieldHeader
        fields={fields ?? []}
        subLabel={getSubLabel()}
        multiSelectName="forecast-chart"
        noDataFields={tags?.withoutData}
      />
      {loadingForecastedHarvestByField ? (
        <Skeleton height={200} style={{ marginTop: '6px' }} />
      ) : (
        <>
          {data?.maxPred && !!displayData?.length ? (
            <>
              <Box>
                <GraphKeys
                  showTooltip={false}
                  onClick={() => {}}
                  graphKeys={tags?.withData ?? []}
                  leftOffset={'100'}
                  icon={Circle}
                  clickable={false}
                ></GraphKeys>
              </Box>
              <Box style={{ display: 'flex', textAlign: 'center' }}>
                <Bar
                  theme={nivoTheme}
                  height={700}
                  width={100}
                  data={displayData}
                  layers={['axes']}
                  keys={getDurationKeys()}
                  margin={{ top: 10, right: 0, bottom: 60, left: 100 }}
                  maxValue={displayYMax ? displayYMax * 1.1 : 0}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Stem count in thousands',
                    legendPosition: 'middle',
                    legendOffset: -80,
                    format: (value: number | undefined) =>
                      formatLeftAxisValue((value ? value / 1000 : 0) ?? 0)
                  }}
                  axisBottom={null}
                  fill={[
                    {
                      match: {
                        id: 'actual_stems'
                      },
                      id: 'dots'
                    }
                  ]}
                />
                <Box
                  style={{
                    height: 700,
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                    width: '100%'
                  }}
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                      height: '6px'
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '6px',
                      height: '12px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#D5D5D5',
                      borderRadius: '4px'
                    }
                  }}
                >
                  <CombinedBarAndLineChart
                    duration={duration}
                    data={displayData}
                    yMax={displayYMax ? displayYMax * 1.1 : 0}
                    selectedYear={currentYear}
                    lineData={[]}
                    durationKeys={getDurationKeys()}
                    durationColors={getDurationColors()}
                    groupMode="stacked"
                    lineKey=""
                    tooltip={HarvestByFieldTooltip}
                    indexBy="period"
                  />
                </Box>
              </Box>
              {duration === 'daily' && (
                <Center>
                  <Text fontSize={20} color={'rgb(0, 0, 0)'}>
                    {`${currentYear} ${getSubLabel()}` || <Skeleton count={1} />}
                  </Text>
                </Center>
              )}
            </>
          ) : (
            <Text mt="1.65rem" textAlign={'left'}>
              No graph data available for the selected filter parameters.
            </Text>
          )}
        </>
      )}
    </Box>
  );
}

export default ForecastByFieldChart;
