import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Heading, Icon, Grid, GridItem, useToast } from '@chakra-ui/react';
import { useAppContext, useAuthContext, useActualContext } from 'Context';
import Skeleton from 'react-loading-skeleton';
import { RefreshCw } from 'react-feather';
import { SummaryState } from './Components/SummaryTable';
import { Harvest, HarvestEntry } from 'Context/types';
import ActualInputTable from './Components/ActualInputTable';
import moment from 'moment-timezone';
import { formatToServerFormat, yearStrToNum } from 'Helpers/helpers';
import LimaToast from 'Components/Toast/LimaToast';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import useDocumentTitle from 'Helpers/useDocumentTitle';
import { start } from 'repl';

export default function HarvestData({}): JSX.Element {
  const { farm, variety, varietyIsCyclic } = useAppContext();

  // Track page view
  useDocumentTitle('Harvest Data | Lima Asili');
  const { trackPageView, pushInstruction } = useMatomo();

  // tracking organization dimension
  useEffect(() => {
    pushInstruction('deleteCustomDimension', 1);
    pushInstruction('setCustomDimension', 1, farm?.organization);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm?.organization]);
  useEffect(() => {
    pushInstruction('deleteCustomDimension', 2);
    //TODO:re-enable this
    //pushInstruction('setCustomDimension', 2, farm?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm]);

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { fetchActualInputData, actualsData, summaryData, fetchProductionSummary } =
    useActualContext();
  const {
    functions: { getToken }
  } = useAuthContext();
  const token = getToken();
  const {
    loadingStates: { loadingBlocks }
  } = useAuthContext();

  const [loadingSummary] = React.useState<boolean>(false);
  const [summaryError] = React.useState<string>('');
  const [summaryState, setSummaryState] = React.useState<SummaryState | null>(null);

  const [selectedTab, setSelectedTab] = useState('Actual');

  const [lastUpdatedAt, setLastUpdatedAt] = useState('');
  const [actualTableNotSaved, setActualTableNotSaved] = useState(false);
  const [startDate, setStartDate] = useState(
    formatToServerFormat(moment().startOf('date').subtract(3, 'months').toDate())
  );
  const [endDate, setEndDate] = useState(formatToServerFormat(moment().startOf('date').toDate()));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (farm && variety) {
      fetchProductionSummary(startDate, endDate, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm, variety]);

  // convert day string to number from the format "1"
  const convertDayToNumber = (day: string): number => {
    const dayNum = parseInt(day);
    return dayNum;
  };

  React.useEffect(() => {
    //detect and update summary changes
    const harvestDateOrWeek = varietyIsCyclic() ? 'Harvest Date' : 'Week';
    const isWeek = harvestDateOrWeek === 'Week';
    let sortedSummaryData = summaryData;
    if (!loadingSummary && summaryData.length > 0 && isWeek) {
      // sort summary data by year and week
      sortedSummaryData = summaryData.sort((a, b) => {
        const aYear = yearStrToNum(a.year);
        const bYear = yearStrToNum(b.year);
        const aWeek = convertDayToNumber(a.day);
        const bWeek = convertDayToNumber(b.day);
        if (aYear < bYear) {
          return -1;
        }
        if (aYear > bYear) {
          return 1;
        }
        if (aWeek < bWeek) {
          return -1;
        }
        if (aWeek > bWeek) {
          return 1;
        }
        return 0;
      });
    }

    setSummaryState({
      harvests: sortedSummaryData,
      loading: loadingSummary,
      error: summaryError
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryData, loadingSummary, summaryError, variety]);

  const toast = useToast();
  const actualsToastId = 'actual-save-toast';
  function handleTableUpdated(newValue: boolean) {
    setActualTableNotSaved(newValue);

    // display unsaved changes toast
    if (!toast.isActive(actualsToastId) && newValue) {
      toast({
        id: actualsToastId,
        position: 'top-right',
        render: () => (
          <LimaToast
            status="warning"
            message={`Save before leaving the Actuals page to prevent loss of the changes.`}
            marginTop="8vh"
            marginRight="3vw"
          />
        ),
        duration: null,
        isClosable: true
      });
    }
  }

  useEffect(() => {
    // clear toast when component unmounts
    return () => {
      toast.closeAll();
    };
  });

  useEffect(() => {
    // check if variety belongs to farm
    const variety_is_valid = farm?.varieties?.some((farm_variety) => {
      return farm_variety?.id === variety?.id;
    });

    if (variety && farm && variety_is_valid) {
      const isValidVarietyFromFarm = farm.varieties?.some((v) => v.id === variety.id) ?? false;
      if (isValidVarietyFromFarm) {
        fetchActualInputData(startDate, endDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm, variety]);

  useEffect(() => {
    if (actualsData.length > 0) {
      const max = actualsData.reduce(function (a: Harvest, b: Harvest) {
        return a.updated_at > b.updated_at ? a : b;
      });
      if (process.env.REACT_APP_ENVIRONMENT !== 'testing') {
        setLastUpdatedAt(
          moment.utc(max.updated_at).tz(moment.tz.guess()).format('DD-MMM-YYYY , h:mm a')
        );
      } else {
        setLastUpdatedAt(
          moment.utc(max.updated_at).tz('Africa/Nairobi').format('DD-MMM-YYYY , h:mm a')
        );
      }
    } else {
      setLastUpdatedAt('');
    }
  }, [actualsData]);

  return (
    <Box
      width="100%"
      backgroundColor="white"
      boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
      py={4}
      pl={6}
      pr={6}
      borderRadius={8}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Box mb={6}>
          <Heading as="h4" mb="2" fontSize="1.35rem" fontWeight="normal">
            {selectedTab} Farm Production
          </Heading>
          <Text as="p">
            {farm ? (
              `${farm?.external_name}, ${farm?.city}: ${variety?.external_name}`
            ) : (
              <Skeleton count={1} />
            )}
          </Text>
        </Box>

        {lastUpdatedAt !== '' && (
          <Grid templateColumns="repeat(9, 1fr)" gap={0}>
            <GridItem colSpan={1}>
              <Icon as={RefreshCw} stroke={'info.500'} w={5} h={5} mr={2} />
            </GridItem>
            <GridItem colSpan={8}>
              <Text color={'info.500'}>Last updated at {lastUpdatedAt}</Text>
            </GridItem>
          </Grid>
        )}
      </Flex>
      {farm && variety?.production_categories ? (
        <ActualInputTable
          farm={farm}
          variety={variety}
          token={token}
          grades={variety?.production_categories}
          handleHasUnsavedEdits={handleTableUpdated}
          actualTableNotSaved={actualTableNotSaved}
          setSelectedTab={setSelectedTab}
          summaryState={summaryState}
          defaultStartDate={startDate}
          defaultEndDate={endDate}
        />
      ) : (
        ''
      )}
    </Box>
  );
}
