import { BlockImage } from './../Context/types.d';
import { Farm, Variety } from 'Context/types';
import { UserBlocksResponse } from 'domain/models/UserBlocksResponse';
import UserBlocksRepository from 'domain/UserBlocksRepository';
import HttpClient from 'http/HttpClient';

class UserBlocksRepositoryImpl implements UserBlocksRepository {
  private httpClient: HttpClient;
  private NORMAL_PREFIX_URL = 'api/v1/';
  private BASE_URL = `${process.env.REACT_APP_BE_URL}`;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getVarieties(token: string, farmId: string): Promise<Variety[]> {
    const res = await this.httpClient
      .getAxios()
      .get(`${this.NORMAL_PREFIX_URL}varieties/list`, {
        headers: {
          Authorization: token
        },
        params: {
          farm_id: farmId
        }
      })
      .catch(() => {});
    let varieties: Variety[] = [];
    if (res?.data && (res?.data as Variety[])?.length) {
      varieties = res.data;
    }
    return varieties;
  }

  async getUserBlocks(token: string): Promise<UserBlocksResponse> {
    const farmsRes = await this.httpClient
      .getAxios()
      .get(`${this.NORMAL_PREFIX_URL}farms/current-user-farms`, {
        headers: {
          Authorization: token
        }
      })
      .catch(() => {});
    const farmIds: string[] = [];
    const farms: Farm[] = [];
    const blockPromises: Promise<any>[] = [];
    // TODO: refactor this
    // farmsRes?.data?.forEach(
    //   ({
    //     _id,
    //     group,
    //     location,
    //     name,
    //     blocks,
    //     organization,
    //     varieties,
    //     with_lima_forecasting
    //   }: Farm) => {
    //     farmIds.push(_id);
    //     farms.push({
    //       _id,
    //       group,
    //       location,
    //       name,
    //       blocks,
    //       organization,
    //       varieties,
    //       with_lima_forecasting
    //     });
    //     if (varieties != null && varieties.length > 0) {
    //       varieties.map((variety: any) => {
    //         blockPromises.push(
    //           this.httpClient
    //             .getAxios()
    //             .get(`${this.NORMAL_PREFIX_URL}blocks/get-farm-blocks/` + _id, {
    //               headers: {
    //                 Authorization: token
    //               },
    //               params: { variety_id: variety._id }
    //             })
    //         );
    //       });
    //     }
    //   }
    // );
    return {
      farmIds,
      farms,
      blockPromises
    };
  }

  async getVarietyBlocks(
    token: string,
    variety: Variety,
    tenant_header: string,
    field_status: string
  ): Promise<any> {
    const res = await this.httpClient
      .getAxios()
      .get(`${this.BASE_URL}/varieties/${variety.id}/fields`, {
        headers: {
          Authorization: token,
          sensitive: false,
          'tenant-header': tenant_header
        },
        params: { field_status: field_status, include_forecast_summary: true }
      });
    return {
      res
    };
  }
  async getFieldProperties(token: string, tenant_header: string, field_id: string): Promise<any> {
    const res = await this.httpClient
      .getAxios()
      .get(`${this.BASE_URL}/fields/${field_id}/field-properties`, {
        headers: {
          Authorization: token,
          sensitive: false,
          'tenant-header': tenant_header
        }
      });
    return {
      res
    };
  }

  async getPresignedUrls(token: string, blockId: string): Promise<BlockImage[]> {
    const res = await this.httpClient
      .getAxios()
      .get(`${this.NORMAL_PREFIX_URL}blocks/get-presigned-urls/${blockId}`, {
        headers: {
          Authorization: token
        },
        params: {
          block_id: blockId
        }
      })
      .catch(() => {});
    let block_images: BlockImage[] = [];
    if (res?.data && (res?.data?.images as BlockImage[])?.length) {
      block_images = res.data?.images;
    }
    return block_images;
  }
}

export default UserBlocksRepositoryImpl;
