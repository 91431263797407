import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { Button } from 'Components';
import { ButtonTheme } from 'Components/Button';
import LimaToast from 'Components/Toast/LimaToast';
import { useAppContext, useAuthContext } from 'Context';
import { Field } from 'Context/types';
import React, { useEffect, useState } from 'react';
import { AlertTriangle, Info, Slash } from 'react-feather';

export enum FieldOptionType {
  StartNewCycle,
  UprootBlock,
  ReverseNewCycle,
  None
}
export interface FieldOptionsPromptModalProps {
  isOpen: boolean;
  onClose: () => void;
  type?: FieldOptionType;
  field?: Field;
}

const FieldOptionsPromptModal = (props: FieldOptionsPromptModalProps) => {
  const { isOpen, onClose, type, field } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [newCycleError, setNewCycleError] = useState<string | null>(null);
  const [uprootError, setUprootError] = useState<string | null>(null);
  const [reverseError, setReverseError] = useState<string | null>(null);
  const [singleError, setSingleError] = useState<string | null>(null);
  const [hasError, setHasError] = useState(false);
  const {
    startNewCycle,
    reverseNewCycle,
    uprootBlock,
    farm,
    handleFieldOptionsError,
    setFieldsUpdated,
    selectedCycle,
    setSelectedCycle
  } = useAppContext();
  const {
    functions: { getToken, getUserBlocksAndFarms }
  } = useAuthContext();
  const toast = useToast();

  useEffect(() => {
    clearErrors();
  }, []);

  const clearErrors = () => {
    setHasError(false);
    setNewCycleError(null);
    setUprootError(null);
    setReverseError(null);
    setSingleError(null);
  };

  useEffect(() => {
    switch (type) {
      case FieldOptionType.StartNewCycle:
        if (newCycleError) {
          setSingleError(newCycleError);
        }
        break;
      case FieldOptionType.UprootBlock:
        if (uprootError) {
          setSingleError(uprootError);
        }
        break;
      case FieldOptionType.ReverseNewCycle:
        if (reverseError) {
          setSingleError(reverseError);
        }
        break;
      case FieldOptionType.None:
        setSingleError(null);
        break;
    }
  }, [newCycleError, uprootError, reverseError, type]);

  function btnTheme(type: FieldOptionType): ButtonTheme {
    switch (type) {
      case FieldOptionType.StartNewCycle:
        return 'warn';
      case FieldOptionType.UprootBlock:
        return 'error';
      case FieldOptionType.ReverseNewCycle:
        return 'warn';
      case FieldOptionType.None:
        return 'primary';
    }
  }

  const setError = (type: FieldOptionType, error: string | null) => {
    if (error === null) {
      clearErrors();
      return;
    }
    switch (type) {
      case FieldOptionType.StartNewCycle:
        setNewCycleError(error);
        break;
      case FieldOptionType.ReverseNewCycle:
        setReverseError(error);
        break;
      case FieldOptionType.UprootBlock:
        setUprootError(error);
        break;
    }
  };

  function title(type: FieldOptionType): string {
    switch (type) {
      case FieldOptionType.StartNewCycle:
        return 'Warning';
      case FieldOptionType.UprootBlock:
        return 'Warning: Field Deletion';
      case FieldOptionType.ReverseNewCycle:
        return 'Warning';
      case FieldOptionType.None:
        return '';
    }
  }

  function description(type: FieldOptionType): JSX.Element {
    switch (type) {
      case FieldOptionType.StartNewCycle:
        return (
          <>
            <Text textAlign="center" mb={0} mt={1} color="black.300" as="p" fontSize="14px">
              By starting a new cycle you are archiving <br /> current data, do you want to proceed?
            </Text>
          </>
        );
      case FieldOptionType.UprootBlock:
        return (
          <>
            <Text textAlign="center" mb={0} mt={1} color="black.300" as="p" fontSize="14px">
              This action will <span className="required">&nbsp;permanently&nbsp;</span> remove
              field <br /> <span className="bold">{field?.name} &nbsp;</span>from the status report,
              are you <br />
              <span className="bold">&nbsp;sure&nbsp;</span> you want to proceed?
            </Text>
          </>
        );
      case FieldOptionType.ReverseNewCycle:
        return (
          <>
            <Text textAlign="center" mb={0} mt={1} color="black.300" as="p" fontSize="14px">
              This action will revert this field to the previous cycle. <br /> Do you want to
              proceed?
            </Text>
          </>
        );
      case FieldOptionType.None:
        return <></>;
    }
  }

  function titleIconColor(type: FieldOptionType): string {
    switch (type) {
      case FieldOptionType.StartNewCycle:
        return 'warn';
      case FieldOptionType.UprootBlock:
        return 'error';
      case FieldOptionType.ReverseNewCycle:
        return 'warn';
      case FieldOptionType.None:
        return '';
    }
  }

  function icon(type: FieldOptionType) {
    switch (type) {
      case FieldOptionType.StartNewCycle:
        return AlertTriangle;
      case FieldOptionType.UprootBlock:
        return Slash;
      case FieldOptionType.ReverseNewCycle:
        return AlertTriangle;
      case FieldOptionType.None:
        return Info;
    }
  }

  async function handleYes(type: FieldOptionType) {
    clearErrors();
    setIsLoading(true);
    if (!field || !farm) {
      setIsLoading(false);
      onClose();
      return;
    }
    let res: AxiosResponse<any, any>;
    const token = getToken();
    let successMessage = '';
    try {
      switch (type) {
        case FieldOptionType.StartNewCycle:
          res = (await startNewCycle(
            token,
            farm.organization.internal_name,
            field.id
          )) as AxiosResponse;
          successMessage = ' new cycle has been started!';
          handleResponseCode(res);
          break;
        case FieldOptionType.UprootBlock:
          res = (await uprootBlock(
            token,
            farm.organization.internal_name,
            field.id
          )) as AxiosResponse;
          successMessage = 'has been uprooted';
          handleResponseCode(res, selectedCycle?.field.id === field.id);
          break;
        case FieldOptionType.ReverseNewCycle:
          res = (await reverseNewCycle(
            token,
            farm.organization.internal_name,
            field.id
          )) as AxiosResponse;
          successMessage = 'previous cycle has been reversed';
          handleResponseCode(res);
          break;
        case FieldOptionType.None:
          setIsLoading(false);
          onClose();
          break;
        default:
          setIsLoading(false);
          onClose();
      }

      toast({
        position: 'top-right',
        render: () => (
          <LimaToast
            status="success"
            message={`Field ${field.name}  ${successMessage}`}
            marginTop="12vh"
            marginRight="3vw"
          />
        ),
        duration: 5000,
        isClosable: true
      });
    } catch (error: any) {
      setIsLoading(false);
      showError(error.response.data.detail ?? 'Unknown Error!');
      setHasError(true);
      handleFieldOptionsError(error.response.status);
    }
  }

  function handleResponseCode(response: AxiosResponse, resetSelectedCycle?: boolean) {
    setIsLoading(false);
    if (response.status === 200 || response.status === 204) {
      setFieldsUpdated(true);
      setTimeout(() => {
        setFieldsUpdated(false);
      }, 2000);
      if (resetSelectedCycle) setSelectedCycle(null);
      getUserBlocksAndFarms();
      onClose();
    } else if (response.status === 422) {
      showError('Validation Error!');
    } else if (response.status === 401) {
      handleFieldOptionsError(response.status);
    }
  }

  const showError = (message: string) => {
    setError(type ?? FieldOptionType.None, message);
    setHasError(true);
    dismissError();
  };

  const dismissError = () => {
    setTimeout(() => {
      clearErrors();
    }, 5000);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          clearErrors();
          onClose();
        }}
        isCentered
        blockScrollOnMount={true}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent boxShadow={'none'} borderRadius="10px" bg="white">
          <Box
            height="10px"
            backgroundColor={`${titleIconColor(type ?? FieldOptionType.None)}.300`}
            borderTopLeftRadius="10px"
            borderTopRightRadius="10px"
          ></Box>
          <ModalBody>
            <Box display="flex">
              <Icon
                as={icon(type ?? FieldOptionType.None)}
                strokeWidth="1.5px"
                height="2rem"
                width="2rem"
                color={`${titleIconColor(type ?? FieldOptionType.None)}.500`}
                my={'24px'}
                mx="auto"
                alignSelf="center"
              />
            </Box>
            <Text
              color={`${titleIconColor(type ?? FieldOptionType.None)}.400`}
              fontSize="22px"
              textAlign="center"
            >
              {title(type ?? FieldOptionType.None)}
            </Text>

            {description(type ?? FieldOptionType.None)}
            {hasError && singleError ? (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>{singleError}</AlertTitle>
              </Alert>
            ) : (
              <Box h="12px"></Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Box w="100%">
              <Flex justifyContent="space-between">
                <Button
                  width="auto"
                  theme="cancel"
                  type="button"
                  onClick={onClose}
                  px={4}
                  __css={{
                    border: '1px solid',
                    borderColor: 'gray.400',
                    color: 'gray.400'
                  }}
                >
                  Cancel
                </Button>
                <Button
                  width="auto"
                  theme={btnTheme(type ?? FieldOptionType.None)}
                  type="button"
                  onClick={() => handleYes(type ?? FieldOptionType.None)}
                  disabled={isLoading}
                >
                  Yes
                  {isLoading && (
                    <>
                      <Box px={1.5}></Box>
                      <Spinner color="white.500" size="sm" />
                    </>
                  )}
                </Button>
              </Flex>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FieldOptionsPromptModal;
