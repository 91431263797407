import React from 'react';
import { Grid, GridItem, Icon, Text } from '@chakra-ui/react';
import moment from 'moment';
import { MessageSquare } from 'react-feather';

type CommentProps = {
  index: number;
  message: string;
  display_name: string;
  time: string;
  comment_type?: number;
  color: string;
};

export default function BlockComment({
  index,
  message,
  display_name,
  time,
  color
}: CommentProps): JSX.Element {
  return (
    <Grid gridTemplateColumns="100px 1fr">
      <GridItem py={4} px={3} borderRight="2px solid" borderColor={color + '.500'}>
        <Text as="p" mb="0" color={color + '.500'}>
          {moment(time).format('MMM Do')}
        </Text>
        <Text as="p" mb="0" color={color + '.500'}>
          {moment(time).format('h.mm A')}
        </Text>
      </GridItem>
      <GridItem py={4} px={6}>
        <Text as="p" mb="1.5" color={color + '.500'} data-cy={'comment_text#' + index}>
          {message}
        </Text>
        <Text
          as="p"
          mb="0"
          textAlign="right"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Text as="span" pe="4" color={color + '.500'}>
            {display_name}
          </Text>
          <Icon as={MessageSquare} stroke={color + '.500'} />
        </Text>
      </GridItem>
    </Grid>
  );
}
