export const colors = {
  primary: {
    500: '#00a896',
    400: '#33b9ab',
    300: '#66cbc0',
    200: '#99dcd5',
    100: '#cceeea'
  },
  secondary: {
    500: '#f38624',
    400: '#f59e50',
    300: '#f8b67c',
    200: '#facfa7',
    100: '#c3efd6'
  },
  black: {
    500: '#2c2e2d',
    400: '#565857',
    300: '#808281',
    200: '#ababab',
    100: '#d5d5d5',
    lighter: {
      400: '#565857',
      300: '#808281',
      200: '#ababab',
      100: '#d5d5d5'
    }
  },
  accentGreen: {
    500: '#9be4ba',
    400: '#afe9c8',
    300: '#9be4ba',
    200: '#87dead',
    100: '#e9f9f0'
  },
  green: {
    500: '#00A896',
    400: '#33B9AB',
    300: '#66CBC0',
    200: '#99DCD5',
    100: '#CCEEEA'
  },
  success: {
    500: '#00b91d',
    400: '#33c74a',
    300: '#66d577',
    200: '#99e3a5',
    100: '#ccf1d2'
  },
  info: {
    500: '#53a5ff',
    400: '#75b7ff',
    300: '#98c9ff',
    200: '#badbff',
    100: '#ddedff'
  },
  warn: {
    500: '#f8b610',
    400: '#f9c540',
    300: '#fbd370',
    200: '#fce29f',
    100: '#fef0cf'
  },
  error: {
    500: '#d21122',
    400: '#db414e',
    300: '#e4707a',
    200: '#eda0a7',
    100: '#f6cfd3'
  },
  neutral: {
    600: '#212121',
    500: '#4d4d4d',
    400: '#A6A6A6',
    300: '#CFCFCF',
    200: '#F5F5F5',
    100: '#FFFFFF'
  },
  gray: {
    500: '#626262',
    400: '#818181',
    300: '#A1A1A1',
    200: '#C0C0C0',
    100: '#E0E0E0'
  },
  white: {
    600: '#FFFFFF',
    500: '#FFFFFF',
    400: '#FFFFFF',
    300: '#FFFFFF',
    200: '#FFFFFF',
    100: '#FFFFFF'
  },
  chartblue: {
    100: '#79D2DE',
    80: '#94DBE5',
    60: '#AFE4EB',
    40: '#C9EDF2',
    20: '#E4F6F8'
  },
  input: {
    placeholder: '#9AA5B1'
  },
  dark: {
    coolGray: {
      600: '#3E4C59',
      500: '#52606D ',
      400: '#7B8794 ',
      300: '#9AA5B1 ',
      200: '#CBD2D9 ',
      100: '#F5F7FA',
      80: '#52606D',
      40: '#9AA5B1',
      20: '#CBD2D9',
      10: '#F5F7FA'
    }
  },
  teal: {
    500: '#00A896',
    400: '#33B9AB',
    300: '#66CBC0',
    200: '#99DCD5',
    100: '#CCEEEA'
  }
};
