import { CheckCircleIcon } from '@chakra-ui/icons';
import React from 'react';
import styles from '../../RegisterOrganization/RegisterOrganization.module.scss';

export enum OrganizationRegistrationProgress {
  TO_BE_DONE,
  IN_PROGRESS,
  DONE
}

export type ProgressCheckMarkProps = {
  progress: OrganizationRegistrationProgress;
  itemNumber: number;
};

const ProgressCheckMark = (props: ProgressCheckMarkProps) => {
  const { progress, itemNumber } = props;

  switch (progress) {
    case OrganizationRegistrationProgress.TO_BE_DONE:
      return (
        <div className={styles.todoCheckMark}>
          <div className={styles.centerAllContent}>{itemNumber}</div>
        </div>
      );
    case OrganizationRegistrationProgress.IN_PROGRESS:
      return (
        <div className={styles.checkIconWithText}>
          <div className={styles.innerCheckCircle}>
            <div className={styles.centerAllContent}>{itemNumber}</div>
          </div>
        </div>
      );
    case OrganizationRegistrationProgress.DONE:
      return <CheckCircleIcon boxSize="40px" color="primary.500" />;
    default:
      return null;
  }
};

export default ProgressCheckMark;
