import * as yup from 'yup';

const schema = yup.object().shape({
  block_name: yup.string().required('Field Name is required.'),
  crop: yup.string().required('Variety is required.'),
  planted_date: yup.date().required('Planted Date is required.'),
  cutback_date: yup.date(),
  area: yup.number().required('Area is required.').min(1)
});

export default schema;
