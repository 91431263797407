import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Center,
  Flex,
  Spacer,
  Spinner,
  Text,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  TabPanel
} from '@chakra-ui/react';
import { Bar, BarDatum } from '@nivo/bar';
import { useAppContext } from 'Context';
import { Button, FilterDropDown, GraphKeys } from 'Components';
import { Calendar, ChevronLeft, ChevronRight, Circle } from 'react-feather';
import { Direction, useGraphContext } from 'Context/GraphContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { separateComma } from 'Helpers/helpers';
import FarmProductionBarChart, { nivoTheme } from './Components/FarmProductionBarChart';
import FarmProductionLineGraph from './Components/FarmProductionLineGraph';
import { ResponsiveLine } from '@nivo/line';
import { Datum } from '@nivo/legends';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import useDocumentTitle from 'Helpers/useDocumentTitle';
import GradesAndRejects from './Components/GradesAndRejects/index';
import ReportSectionCard, { ReportFilterState } from './Components/ReportSectionCard';
import { Tab } from 'Components/Navigation';
import MinimumExpectedHarvestGraph from './Components/MinimumExpectHarvest/MinimumExpectedHarvestGraph';
import TotalHarvestVsLoss from './Components/TotalHarvestVsLoss/TotalHarvestVsLoss';
import ForecastByField from './Components/HarvestByField/Forecasts';
import HarvestByField from './Components/HarvestByField/Harvests';
import FieldProductivity from './Components/FieldProductivity';
import { sortAndOrganizePredsWork, sortAndOrganizeYieldsWork } from 'Helpers/workerHelpers';
import { SortAndOrganizePredsWorker, SortAndOrganizeYieldsWorker } from 'workers/workerTypes';
import { graphRepository } from 'Helpers/appDependenciesHelpers';
import useDelayedLoadingState from 'Helpers/useDelayedLoadingState';
import { OrganizedYields } from 'data/GraphRepositoryImpl';
import { useLocation, useNavigate } from 'react-router-dom';

export type MyBarDatum = {
  period: string;
  predicted_stems: number;
  actual_stems: number;
  hasActual: string;
  predicted_grading: any;
  actual_grading: any;
  [key: string]: any;
};

const defs = [
  {
    id: 'dots',
    type: 'patternDots',
    background: 'inherit',
    color: '#00A896',
    size: 4,
    padding: 1,
    stagger: true
  }
];

const durationList = ['Daily', 'Weekly'];

export default function Dashboard({}): JSX.Element {
  const { farm, variety, limaActiveModules, varietyIsCyclic } = useAppContext();
  // Track page view
  useDocumentTitle('Dashboard | Lima Asili');
  const { trackPageView, pushInstruction } = useMatomo();

  // tracking organization dimension
  useEffect(() => {
    pushInstruction('deleteCustomDimension', 1);
    pushInstruction('setCustomDimension', 1, farm?.organization);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm?.organization]);

  useEffect(() => {
    pushInstruction('deleteCustomDimension', 2);
    pushInstruction('setCustomDimension', 2, farm?.external_name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm]);

  React.useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [data, setData] = useState<BarDatum[]>([]);
  const [lineData, setLineData] = useState<any[]>([]);
  const [yMax, setYMax] = useState(0);
  const [demandMax, setDemandMax] = useState(0);
  const [predMax, setPredMax] = useState(0);
  const [lineMax, setLineMax] = useState(0);
  const [isProcessingActiveYields, setIsProcessingActiveYields] = useState(false);
  const [isProcessingActivePreds, setIsProcessingActivePreds] = useState(false);
  const [lineGraphWidth, setLineGraphWidth] = useState<any>('100%');
  const {
    timeTravel,
    activeYields,
    selectedYear,
    duration,
    setDuration,
    error,
    lineError,
    loadingGraphData,
    activePreds,
    loadingLineData,
    setSortedLines,
    demandData,
    filterTags,
    setFilterTags,
    setReportFilterState,
    gradesAndRejects,
    totalsDuration,
    actualVsForecastQuery
  } = useGraphContext();
  const [localBarDatum, setLocalBarDatum] = useState<BarDatum[]>(actualVsForecastQuery.barDatum);
  const activeYieldsMemo = useMemo(() => activeYields, [activeYields]); // a safer way to prevent infinite loop in useEffect below
  const activePredsMemo = useMemo(() => activePreds, [activePreds]);
  const _isProcessing = loadingGraphData || isProcessingActiveYields || isProcessingActivePreds;
  const isProcessing = useDelayedLoadingState({ loadingState: _isProcessing, delayMs: 1000 });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/dashboard', { replace: true }); // fix for root path not being redirected to dashboard
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    sortAndOrganizeYields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeYieldsMemo]);

  useEffect(() => {
    if (demandData?.length && duration === 'weekly') {
      setDemandMax(
        demandData
          ?.map((el: any) => el.stems)
          .reduce(function (a: any, b: any) {
            return a > b ? a : b;
          }, 0)
      );
    } else setDemandMax(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demandData]);

  useEffect(() => {
    setYMax(demandMax > predMax ? demandMax : predMax);
  }, [predMax, demandMax]);

  useEffect(() => {
    if (limaActiveModules.forecast_trend && farm?.with_lima_forecasting) {
      sortAndOrganizePreds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePredsMemo]);

  useEffect(() => {
    getLineWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineData?.[0]?.data?.length]);

  /**
   * @description Sorts and organizes the yields data as needed for the bar chart
   */
  const sortAndOrganizeYields = async () => {
    if (activeYields !== null && !isProcessingActiveYields) {
      setIsProcessingActiveYields(true);
      let organizedYields: OrganizedYields | undefined;
      if (window.Worker && !window.Cypress) {
        const _inputData: SortAndOrganizeYieldsWorker = {
          activeYields: activeYields,
          duration: duration,
          selectedYear: selectedYear
        };
        organizedYields = await sortAndOrganizeYieldsWork(_inputData).catch(() => {
          // fallback to main thread
          const _organizedYields = graphRepository.sortAndOrganizeYields(
            activeYields,
            duration,
            selectedYear
          );
          return _organizedYields;
        });
      } else {
        organizedYields = graphRepository.sortAndOrganizeYields(
          activeYields,
          duration,
          selectedYear
        );
      }
      if (organizedYields) {
        setPredMax(organizedYields.maxPred);
        setData(organizedYields.formattedData);
      } else {
        setPredMax(0);
        setData([]);
      }
      setIsProcessingActiveYields(false);
    }
  };

  const sortAndOrganizePreds = async () => {
    if (activePreds !== null && !isProcessingActivePreds) {
      setLineGraphWidth(undefined);
      setIsProcessingActivePreds(true);
      let organizedPreds;
      if (window.Worker && !window.Cypress) {
        const _inputData: SortAndOrganizePredsWorker = {
          activePreds: activePreds,
          duration: duration,
          variety: variety
        };
        organizedPreds = await sortAndOrganizePredsWork(_inputData).catch(() => {
          // fallback to main thread
          const _organizedPreds = graphRepository.sortAndOrganizePreds(
            activePreds,
            duration,
            variety
          );
          return _organizedPreds;
        });
      } else {
        organizedPreds = graphRepository.sortAndOrganizePreds(activePreds, duration, variety);
      }
      if (organizedPreds) {
        setLineMax(organizedPreds.maxPred);
        setLineData(organizedPreds.data);
        setSortedLines(organizedPreds.data);
      }
      setIsProcessingActivePreds(false);
    }
  };

  /**
   * @description formats the legend string based on the duration selected by the user.
   * @returns formatted legend string e.g. 'Weeks | 2021'
   */
  const legend = (): string => {
    switch (duration) {
      case 'weekly':
        return 'Weeks | ' + selectedYear;
      case 'monthly':
        return 'Months | ' + selectedYear;
      case 'daily':
        return 'Days | ' + selectedYear;
    }
  };

  /**
   * @description formats the value on the left axis of the graph. This is called when the graph is rendered. It formats the value to have a comma separator e.g. 1000 -> 1,000
   * @param value the value to be formatted
   * @returns the formatted value e.g. 1000 -> 1,000
   */
  const formatLeftAxisValue = (value: number): string => {
    return separateComma(value);
  };

  /**
   * @description handles the selection of the duration of the graph. This is called when the user clicks on the duration dropdown. It sets the duration state to the selected duration. It also sets the duration label to the selected duration. The duration label is used to display the selected duration on the dropdown. The duration state is used to determine the data to be displayed on the graph. The duration state is also used to determine the legend of the graph. The legend is the string that is displayed on the x-axis of the graph.
   * @returns void
   */
  const onSelectDuration = () => {
    return (selection: number | number[]) => {
      switch (selection) {
        case 0:
          setDuration('daily');
          break;
        case 1:
          setDuration('weekly');
          break;
        case 2:
          setDuration('monthly');
          break;
        default:
          break;
      }
    };
  };

  const getLineWidth = () => {
    if (duration === 'daily') {
      if (lineData?.[0]?.data?.length > 35) {
        const len = (lineData?.[0]?.data?.length / 34) * 100;
        setLineGraphWidth(`${len}%`);
      } else return setLineGraphWidth('100%');
    } else return setLineGraphWidth('100%');
  };

  const datums = (): Datum[] => {
    const list: Datum[] = [];
    if (!varietyIsCyclic()) {
      const actual: Datum = {
        id: 'actuals',
        label: 'Actuals',
        hidden: false,
        color: '#905ED1',
        fill: '#905ED1'
      };
      const days_out_1: Datum = {
        id: 'days_out_1',
        label: 'Latest',
        hidden: false,
        color: '#68E5AA',
        fill: '#68E5AA'
      };
      // const days_out_7: Datum = {
      //   id: 'days_out_7',
      //   label: '7 Days',
      //   hidden: false,
      //   color: '#FFC185',
      //   fill: '#FFC185'
      // };
      // const days_out_14: Datum = {
      //   id: 'days_out_14',
      //   label: '2 Weeks',
      //   hidden: false,
      //   color: '#A1A1A1',
      //   fill: '#A1A1A1'
      // };
      list.push(actual);
      list.push(days_out_1);
      // list.push(days_out_7);
      // list.push(days_out_14);
    } else {
      const actual: Datum = {
        id: 'actuals',
        label: 'Actuals',
        hidden: false,
        color: '#905ED1',
        fill: '#905ED1'
      };
      const days_out_7: Datum = {
        id: 'days_out_7',
        label: 'Latest',
        hidden: false,
        color: '#68E5AA',
        fill: '#68E5AA'
      };
      // Disable 2 weeks and 4 weeks out forecasts
      // const days_out_14: Datum = {
      //   id: 'days_out_14',
      //   label: '2 Weeks',
      //   hidden: false,
      //   color: '#A1A1A1',
      //   fill: '#A1A1A1'
      // };
      // const days_out_28: Datum = {
      //   id: 'days_out_28',
      //   label: '4 Weeks',
      //   hidden: false,
      //   color: '#AFE4EB',
      //   fill: '#AFE4EB'
      // };
      list.push(actual);
      list.push(days_out_7);
      // list.push(days_out_14);
      // list.push(days_out_28);
    }

    return list;
  };

  const colors = () => {
    if (!varietyIsCyclic()) {
      return ['#905ED1', '#68E5AA', '#FFC185', '#A1A1A1'];
    } else return ['#905ED1', '#68E5AA', '#A1A1A1', '#AFE4EB'];
  };

  const getDurationKeys = (): string[] => {
    return filterTags.filter((tag: any) => tag.active).map((tag: any) => tag.id);
  };

  const getDurationColors = (): string[] => {
    return filterTags.filter((tag: any) => tag.active).map((tag: any) => tag.fill);
  };

  const setStatus = (id: any) => {
    setIsProcessingActiveYields(true);
    filterTags.map((tag: any) => {
      if (tag.id === id) {
        tag.active = !tag.active;
        return tag;
      }
      return tag;
    });
    setFilterTags([...filterTags]);
    const newData = [...data];
    setData(newData);
    setIsProcessingActiveYields(false);
  };

  const filterTagsDelay = 800;
  const [changingFilterTags, setChangingFilterTags] = useState(false);
  const isChangingFilterTags = useDelayedLoadingState({
    loadingState: changingFilterTags,
    delayMs: filterTagsDelay
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!actualVsForecastQuery.isLoading && actualVsForecastQuery.barDatum) {
      setChangingFilterTags(true);
      setLocalBarDatum([]);
      timeout = setTimeout(() => {
        setLocalBarDatum([...actualVsForecastQuery.barDatum]);
        setChangingFilterTags(false);
      }, filterTagsDelay);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [filterTags, actualVsForecastQuery.barDatum, actualVsForecastQuery.isLoading]);

  const handleFilterStateUpdate = (filterState: ReportFilterState) => {
    setReportFilterState(filterState);
  };

  const [reinitializeFilter, setReinitializeFilter] = useState<boolean>(true);
  const [selectedDuration, setSelectedDuration] = useState<number>(1);

  useEffect(() => {
    if (duration) {
      const found = duration === 'weekly' ? 1 : 0;
      setSelectedDuration(found);
      if (duration !== 'weekly') {
        setDemandMax(0);
      } else {
        setDemandMax(
          demandData
            ?.map((el: any) => el.stems)
            .reduce(function (a: any, b: any) {
              return a > b ? a : b;
            }, 0)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  useEffect(() => {
    setReinitializeFilter(false);
    const timeout = setTimeout(() => {
      setReinitializeFilter(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, [selectedDuration]);

  if (!data) {
    return (
      <Flex direction="column" justifyContent="center" width="100%" height="100vh">
        <Center>
          <Spinner size="lg" />
        </Center>
      </Flex>
    );
  }

  return (
    <>
      <Tabs pos="relative" align="start" variant="enclosed">
        <TabList>
          <Tab>Forecast</Tab>
          <Tab>Historical Production</Tab>
        </TabList>
        <TabPanels pt={4} border={'1.5px solid #CBD2D975'} borderRadius={'4px'}>
          <TabPanel height={'77vh'} overflowY={'auto'} className="custom-scrollbar">
            <Box
              width="100%"
              backgroundColor="white"
              boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
              py={4}
              px={6}
              borderRadius={8}
              data-cy="graph-box"
            >
              <Flex justifyContent="space-between" alignItems="flex-start">
                <Box>
                  <Heading
                    as="h4"
                    mb="2"
                    fontSize="1.35rem"
                    fontWeight="normal"
                    color="dark.coolGray.600"
                  >
                    Actual vs Forecast Production
                  </Heading>
                  <Text data-cy="farm-production-farm-title" as="p" color="dark.coolGray.500">
                    {farm ? (
                      `${farm?.external_name}, ${farm?.city.replaceAll('_', ' ')}`
                    ) : (
                      <Skeleton count={1} />
                    )}
                  </Text>
                </Box>
                <Box>
                  <Flex direction="column" alignItems="center">
                    {reinitializeFilter && (
                      <FilterDropDown
                        label={`${duration}`}
                        items={durationList}
                        selectedItem={selectedDuration}
                        onSelectFilter={onSelectDuration()}
                        name="farm-production"
                        LeftIcon={Calendar}
                        disabled={isProcessing}
                      />
                    )}
                    <Spacer mt={2} />
                    <Flex w="100%" p={0} justifyContent="space-between" alignItems="center">
                      <Button
                        theme="white"
                        boxShadow="base"
                        padding={3}
                        width={10}
                        height={10}
                        marginLeft={0}
                        borderRadius="50%"
                        data-cy="farm-production-previous-year"
                        data-testid="previous-year-button"
                        onClick={() => timeTravel(Direction.YearBackward)}
                      >
                        <ChevronLeft color="black" />
                      </Button>
                      <Text mx={'auto'} color="dark.coolGray.500">
                        Year:&nbsp;{selectedYear}
                      </Text>
                      <Button
                        theme="white"
                        boxShadow="base"
                        padding={3}
                        marginRight={0}
                        width={10}
                        height={10}
                        borderRadius="50%"
                        data-cy="farm-production-next-year"
                        data-testid="next-year-button"
                        onClick={() => timeTravel(Direction.YearForward)}
                      >
                        <ChevronRight color="black" />
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
              {error && !isProcessing ? (
                <Text>{error}</Text>
              ) : (
                <>
                  {isProcessing || !variety ? (
                    <Skeleton height={600} style={{ marginTop: '0.5rem' }} />
                  ) : (
                    <Flex direction="column">
                      {actualVsForecastQuery.yMax && !isProcessing ? (
                        <>
                          <GraphKeys
                            showTooltip={true}
                            onClick={setStatus}
                            graphKeys={filterTags}
                            leftOffset={'100'}
                            icon={Circle}
                            clickable
                          ></GraphKeys>
                          <Box style={{ display: 'flex', textAlign: 'center' }}>
                            <Bar
                              theme={nivoTheme}
                              height={700}
                              width={100}
                              data={localBarDatum}
                              layers={['axes']}
                              keys={getDurationKeys()}
                              margin={{ top: 10, right: 0, bottom: 60, left: 100 }}
                              maxValue={actualVsForecastQuery.yMax * 1.3}
                              axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Stem count in thousands',
                                legendPosition: 'middle',
                                legendOffset: -80,
                                format: (value: number | undefined) =>
                                  formatLeftAxisValue((value ? value / 1000 : 0) ?? 0)
                              }}
                              axisBottom={null}
                              defs={defs}
                              fill={[
                                {
                                  match: {
                                    id: 'actual_stems'
                                  },
                                  id: 'dots'
                                }
                              ]}
                            />
                            <Box
                              style={{
                                height: 700,
                                overflowX: 'scroll',
                                overflowY: 'hidden',
                                width: '100%'
                              }}
                              css={{
                                '&::-webkit-scrollbar': {
                                  width: '4px',
                                  height: '6px'
                                },
                                '&::-webkit-scrollbar-track': {
                                  width: '6px',
                                  height: '12px'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  background: '#D5D5D5',
                                  borderRadius: '4px'
                                }
                              }}
                            >
                              {!isProcessing && (
                                <>
                                  {isChangingFilterTags ? (
                                    <Skeleton height={600} style={{ marginTop: '0.5rem' }} />
                                  ) : (
                                    <FarmProductionBarChart
                                      duration={duration}
                                      data={localBarDatum}
                                      yMax={actualVsForecastQuery.yMax}
                                      selectedYear={selectedYear}
                                      demandData={actualVsForecastQuery.demandData}
                                      durationKeys={getDurationKeys()}
                                      durationColors={getDurationColors()}
                                    />
                                  )}
                                </>
                              )}
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <Text my="0.65rem" textAlign={'left'}>
                          No graph data available for the selected filter parameters.
                        </Text>
                      )}
                    </Flex>
                  )}
                </>
              )}
              {duration === 'daily' &&
                !!localBarDatum.length &&
                !!actualVsForecastQuery.yMax &&
                !isProcessing && (
                  <Center>
                    <Text fontSize={19} color={'dark.coolGray.500'}>
                      {legend()}
                    </Text>
                  </Center>
                )}
            </Box>
            {limaActiveModules.forecast_trend && farm?.with_lima_forecasting && (
              <Box
                width="100%"
                boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
                py={4}
                px={5}
                pr={10}
                borderRadius={8}
                backgroundColor="white"
                mt={'2rem'}
              >
                <Box>
                  <Heading
                    as="h4"
                    mb="2"
                    fontSize="1.35rem"
                    fontWeight="normal"
                    color="dark.coolGray.600"
                  >
                    Forecast Trend
                  </Heading>
                  <Text data-cy="farm-production-farm-title" as="p" color="dark.coolGray.500">
                    {/* TODO:Re-enable this */}
                    {/* {`${farm?.name}, ${farm?.location.city}` || <Skeleton count={1} />} */}
                  </Text>
                </Box>
                <Box>
                  <Flex
                    p={0}
                    justifyItems={'flex-end'}
                    alignItems="center"
                    width={'fit-content'}
                    ml={'auto'}
                  >
                    <Button
                      theme="white"
                      boxShadow="base"
                      padding={3}
                      width={10}
                      height={10}
                      marginLeft={0}
                      borderRadius="50%"
                      data-cy="farm-production-line-previous-year"
                      data-testid="previous-line-year-button"
                      onClick={() => timeTravel(Direction.YearBackward)}
                    >
                      <ChevronLeft color="black" />
                    </Button>
                    <Text mx={'2rem'} color="dark.coolGray.500">
                      Year:&nbsp;{selectedYear}
                    </Text>
                    <Button
                      theme="white"
                      boxShadow="base"
                      padding={3}
                      marginRight={0}
                      width={10}
                      height={10}
                      borderRadius="50%"
                      data-cy="farm-production-line-next-year"
                      data-testid="next-line-year-button"
                      onClick={() => timeTravel(Direction.YearForward)}
                    >
                      <ChevronRight color="black" />
                    </Button>
                  </Flex>
                </Box>
                {lineError ? (
                  <Text>{lineError}</Text>
                ) : loadingLineData && lineGraphWidth ? (
                  <Skeleton height={700} style={{ marginTop: 40 }} />
                ) : (
                  <Box height="700" display={'flex'} width={'100%'}>
                    <Box
                      width={'100px'}
                      paddingRight={'0px'}
                      height={'700'}
                      display={'flex'}
                      alignItems={'flex-end'}
                    >
                      <ResponsiveLine
                        data={lineData}
                        colors={colors()}
                        theme={nivoTheme}
                        margin={{ top: 70, right: 0, bottom: 70, left: 80 }}
                        xScale={{ type: 'point' }}
                        yScale={{
                          type: 'linear',
                          min: 'auto',
                          max: lineMax * 1.1,
                          stacked: false,
                          reverse: false
                        }}
                        yFormat=" >-.2f"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          legend: 'Stem count in ‘000 (Thousands)',
                          legendOffset: -65,
                          legendPosition: 'middle',
                          format: (value: number | undefined) =>
                            separateComma((value ? value / 1000 : 0) ?? 0)
                        }}
                        pointSize={duration === 'daily' ? 0 : 12}
                        pointColor="white"
                        pointBorderWidth={3}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        lineWidth={3}
                        useMesh={true}
                        enableGridX={true}
                        enableGridY={true}
                        layers={['axes']}
                        legends={[
                          {
                            data: datums(),
                            anchor: 'top-left',
                            direction: 'row',
                            justify: false,
                            translateX: 0,
                            translateY: -50,
                            itemTextColor: '#52606d',
                            itemsSpacing: 3,
                            itemDirection: 'left-to-right',
                            itemWidth: 100,
                            itemHeight: 20,
                            itemOpacity: 1,
                            symbolSize: 22,
                            symbolShape: ({ fill }) => (
                              <svg height="12" width="24" className="legend-svg">
                                <line
                                  x1="0"
                                  y1="10"
                                  x2="200"
                                  y2="10"
                                  stroke={fill}
                                  strokeWidth="4"
                                />
                              </svg>
                            ),
                            symbolBorderColor: 'rgba(0, 0, 0, .5)'
                          }
                        ]}
                      />
                    </Box>
                    <Box
                      height="700"
                      width="100%"
                      overflowX={'auto'}
                      overflowY={'hidden'}
                      css={{
                        '&::-webkit-scrollbar': {
                          width: '4px',
                          height: '6px'
                        },
                        '&::-webkit-scrollbar-track': {
                          width: '6px',
                          height: '12px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#D5D5D5',
                          borderRadius: '4px'
                        }
                      }}
                    >
                      <Box width={lineGraphWidth} height="700">
                        <FarmProductionLineGraph
                          duration={duration}
                          data={lineData}
                          yMax={lineMax}
                          selectedYear={selectedYear}
                          variety={variety}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            {/* Minimum Expected Harvest */}
            {limaActiveModules.minimum_expected_harvest ? <MinimumExpectedHarvestGraph /> : ''}

            {/* Forecast Harvest by field */}
            {limaActiveModules.forecast_harvest_by_field_chart ||
            limaActiveModules.forecast_harvest_by_field_table ? (
              <ForecastByField />
            ) : (
              ''
            )}
          </TabPanel>
          <TabPanel height={'77vh'} overflowY={'auto'} className="custom-scrollbar">
            <ReportSectionCard
              currentlySelectedDuration={totalsDuration}
              updateFilterState={handleFilterStateUpdate}
            />
            <TotalHarvestVsLoss />
            <GradesAndRejects data={gradesAndRejects} />

            {/* Historical Harvest by field */}
            {limaActiveModules.forecast_harvest_by_field_chart ||
            limaActiveModules.forecast_harvest_by_field_table ? (
              <HarvestByField />
            ) : (
              ''
            )}
            {varietyIsCyclic() && <FieldProductivity />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
