import React, { useEffect } from 'react';
import { Box, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Text } from '@chakra-ui/react';
import { Harvest, ProductionCategory } from 'Context/types';
import styles from './../../../Styles/Table.module.scss';
import { useAppContext } from 'Context';
import {
  filterHarvests,
  formatProductionSummaryDate,
  formatProductionSummaryNumber
} from 'Helpers/helpers';
import { CombinedFilters } from './ActualInputTable';
import { filterHarvestsWork } from 'Helpers/workerHelpers';

type SummaryTableHeaderType = 'normal' | 'grade-split';

type SummaryTableHead = {
  label: string;
  type: SummaryTableHeaderType;
  gradeTitles?: string[];
};

export type SummaryTableProps = {
  grades: ProductionCategory[];
  state: SummaryState;
  combinedFilters: CombinedFilters | null;
};

export type SummaryState = {
  harvests: Harvest[];
  loading: boolean;
  error?: string;
};

const SummaryTable = (props: SummaryTableProps) => {
  const { state, grades, combinedFilters } = props;
  const { variety, varietyIsCyclic } = useAppContext();
  const [harvests, setHarvests] = React.useState<Harvest[]>(state.harvests);

  const extractGradeValues = (grades: ProductionCategory[]): string[] => {
    const titles: string[] = [];
    for (let i = 0; i < grades.length; i++) {
      const grade = grades[i];
      const title = grade.external_name;
      titles.push(title);
    }
    return titles;
  };

  const tableHeaders: SummaryTableHead[] = [
    {
      label: '',
      type: 'normal'
    },
    {
      label: varietyIsCyclic() ? 'Harvest Date' : 'Week',
      type: 'normal'
    },
    {
      label: 'Field Name',
      type: 'normal'
    },
    {
      label: 'Actual Graded',
      type: 'grade-split',
      gradeTitles: extractGradeValues(grades)
    },
    {
      label: 'Total Graded',
      type: 'normal'
    }
  ];

  const handleFiltersChange = async (
    _harvests: Harvest[],
    _combinedFilters: CombinedFilters | null
  ) => {
    let _filteredSummary: Harvest[] = [];
    if (window.Worker) {
      _filteredSummary = await filterHarvestsWork(_harvests, _combinedFilters).catch(() => {
        return [];
      });
    } else {
      _filteredSummary = filterHarvests(_harvests, _combinedFilters);
    }
    setHarvests([..._filteredSummary]);
  };

  useEffect(() => {
    handleFiltersChange(state.harvests, combinedFilters);
  }, [combinedFilters, state.harvests]);

  // TODO: do proper error handling here
  if (state.error) {
    return <div>{state.error}</div>;
  }

  return (
    <Flex flexDir="column">
      <TableContainer className="custom-scrollbar" h="60vh" overflowY="scroll">
        <Table className="gray-border" size="sm">
          <Thead backgroundColor="dark.coolGray.100" pos="sticky" top={-0.5} zIndex="docked">
            <Tr>
              {tableHeaders.map((header, index) => {
                if (header.type === 'normal') {
                  return (
                    <Th className="gray-border" key={index}>
                      <Text color="green.500" className={styles.table__actual__header}>
                        {header.label}
                      </Text>
                    </Th>
                  );
                } else if (header.type === 'grade-split') {
                  return (
                    <Th p={0} className="gray-border" key={index}>
                      <Flex flexDir="column">
                        <Box
                          as={Th}
                          color="green.500"
                          p={1}
                          className="gray-border-bottom"
                          textAlign="center"
                        >
                          <Text color="green.500" className={styles.table__actual__header}>
                            {header.label}
                          </Text>
                        </Box>
                        <Flex justifyContent="space-evenly">
                          {header.gradeTitles?.map((label, index) => (
                            <Box
                              p={1}
                              as={Th}
                              w="100%"
                              h="100%"
                              key={index}
                              className={
                                header.gradeTitles && index === header.gradeTitles?.length - 1
                                  ? ''
                                  : 'gray-border-right'
                              }
                              textAlign="center"
                              color="green.500"
                            >
                              <Text
                                color="green.500"
                                className={styles.table__actual__header}
                              >{`${label}`}</Text>
                            </Box>
                          ))}
                        </Flex>
                      </Flex>
                    </Th>
                  );
                }
              })}
            </Tr>
          </Thead>
          <Tbody>
            {harvests.map((item, index) => (
              <Tr key={index} color={'black.lighter.400'}>
                <Td textAlign="center" className="gray-border" color={'black.lighter.400'}>
                  {index + 1}
                </Td>
                <Td textAlign="center" className="gray-border" color={'black.lighter.400'}>
                  {formatProductionSummaryDate(item.date ?? '', variety, item)}
                </Td>
                <Td textAlign="center" className="gray-border" color={'black.lighter.400'}>
                  {item.field?.name}
                </Td>
                <Td p={0}>
                  <Flex justifyContent="space-evenly">
                    {item.harvest_productions.map((harvest_production, index) => (
                      // {variety?.production_categories.map((production_category, indexGrade) => (
                      <Box
                        as={Td}
                        // p={1}
                        // m={0}
                        w="100%"
                        h="100%"
                        key={index}
                        className={
                          item.harvest_productions && index === item.harvest_productions?.length - 1
                            ? 'remove-bottom-border'
                            : 'gray-border-right remove-bottom-border'
                        }
                        textAlign="center"
                        color={'black.lighter.400'}
                      >
                        {formatProductionSummaryNumber(
                          Object.values(harvest_production)[0].yield_value
                        )}
                      </Box>
                    ))}
                  </Flex>
                </Td>
                <Td textAlign="center" className="gray-border" color={'black.lighter.400'}>
                  {formatProductionSummaryNumber(item.stems)}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default SummaryTable;
