import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  User,
  LoginFields,
  RegisterFields,
  RegisterConfirmFields,
  ForgotPasswordFields
} from './types';
import { useNavigate } from 'react-router-dom';
import { Flex, Spinner, useToast } from '@chakra-ui/react';
import { CookiesProvider, useCookies } from 'react-cookie';
import moment from 'moment';
import { useAppContext } from './App';
import { ToastEvent, TokenRefreshEvent } from 'http/HttpClient';
import { RegisterResponse } from 'domain/models/RegisterResponse';
import { ConfirmRegisterResponse } from 'domain/models/ConfirmRegisterResponse';
import { ForgotPasswordResponse } from 'domain/models/ForgotPasswordResponse';
import { Subscription } from 'sub-events';
import { CookieSetOptions } from 'universal-cookie';
import LimaToast from 'Components/Toast/LimaToast';
import { FarmOnboardSteps } from 'Layout/Components/CompleteRegistrationModal';
import { isArray } from 'lodash';
import { decrypt, encrypt, sortTableColumn } from 'Helpers/helpers';
import { authRepository, httpClient, userBlocksRepository } from 'Helpers/appDependenciesHelpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LoginResponse } from 'domain/models/LoginResponse';
import useFarmsQuery from 'Helpers/useFarmsQuery';
/**
 * AuthCtxLoadingStates is the type for the loading states of the AuthContext component
 */
type AuthCtxLoadingStates = {
  isLoading: boolean;
  isRefreshingToken: boolean;
  loadingBlocks: boolean;
};

/**
 * AuthCtxFunctions is the type for the functions of the AuthContext component
 */
type AuthCtxFunctions = {
  login: (input: LoginFields) => void | Promise<boolean>;
  register: (input: RegisterFields) => void;
  confirmRegister: (input: RegisterConfirmFields) => void | Promise<boolean>;
  logout: () => void;
  forgotPassword: (input: ForgotPasswordFields) => void | Promise<boolean>;
  confirmForgotPassword: (input: ForgotPasswordFields) => void;
  getUserBlocksAndFarms(): void;
  setHasError: (value: boolean) => void;
  setResponseInfo: (value: string) => void;
  getToken: () => string;
  getRefreshToken: () => string;
  setUserCookie: (name: 'user', value: User | null, options?: CookieSetOptions | undefined) => void;
  setSkippedMembersAdd(skipped: boolean): void;
  getSkippedMembersAdd(): boolean;
};

/**
 * AuthCtxVariables is the type for the variables of the AuthContext component
 */
type AuthCtxVariables = {
  hasError: boolean;
  responseInfo: string;
  user: User | null;
  registerSuccess: boolean;
  forgotPasswordSuccess: boolean;
  confirmForgotPasswordSuccess: boolean;
};

/**
 * AuthContextType is the type for the AuthContext component
 */
type AuthContextType = {
  loadingStates: AuthCtxLoadingStates;
  functions: AuthCtxFunctions;
  variables: AuthCtxVariables;
};

/**
 * MyAuthContext is the context for the AuthContext component
 */
const MyAuthContext = createContext<AuthContextType>({
  loadingStates: {
    isLoading: true,
    isRefreshingToken: false,
    loadingBlocks: false
  },
  functions: {
    setHasError: () => {},
    setResponseInfo: () => {},
    login: () => {},
    register: () => {},
    logout: () => {},
    confirmRegister: () => {},
    getToken: () => '',
    getRefreshToken: () => '',
    forgotPassword: () => {},
    confirmForgotPassword: () => {},
    getUserBlocksAndFarms: () => {},
    setUserCookie: () => {},
    setSkippedMembersAdd: () => {},
    getSkippedMembersAdd: () => false
  },
  variables: {
    hasError: false,
    responseInfo: '',
    user: null,
    registerSuccess: false,
    forgotPasswordSuccess: false,
    confirmForgotPasswordSuccess: false
  }
});

/**
 * AuthContextProvider is the provider for the AuthContext component
 * @returns JSX.Element - the AuthContextProvider component
 */
export const useAuthContext = () => useContext(MyAuthContext);

/**
 * AuthContext is the context for the authentication
 * @param param0 - the children of the component
 * @returns JSX.Element - the AuthContext component
 */
export default function AuthContext({ children }: { children: ReactNode }): JSX.Element {
  const [user, setUser] = useState<User | null>(null);
  const [loadingStates, setLoadingStates] = useState<AuthCtxLoadingStates>({
    isLoading: false,
    isRefreshingToken: false,
    loadingBlocks: true
  });
  const [start, setStart] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [responseInfo, setResponseInfo] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies([
    'lima_auth',
    'lima_auth_refresh',
    'lima_last_path',
    'skipped_members_add'
  ]);
  const [pathCookie, setLastPath] = useCookies(['lima_last_path']);
  const [userCookie, setUserCookie, removeUserCookie] = useCookies(['user']);
  const navigate = useNavigate();
  const toast = useToast();
  const {
    setFarms,
    setFarm,
    appAuth,
    setAppAuth,
    //setOrganizations,
    variety,
    farm,
    blockCycleUpdated,
    setFields,
    selectedCycle,
    setSelectedCycle,
    fieldsUpdated,
    newBlockAdded,
    currentFarmOnboardStep,
    fieldStatus
  } = useAppContext();
  const location = useLocation();
  //const BASE_URL = `${process.env.REACT_APP_BE_URL}`;
  const queryClient = useQueryClient();
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);
  const [confirmForgotPasswordSuccess, setConfirmForgotPasswordSuccess] = useState(false);

  /**
   * handleToastSubscription is the function to handle the toast subscription
   * @param toastEvent - the toast event
   */
  const handleToastSubscription = async (toastEvent: ToastEvent) => {
    switch (toastEvent.status) {
      case 'success':
        toast({
          position: 'top-right',
          render: () => (
            <LimaToast
              status="success"
              message={toastEvent.message}
              marginTop="12vh"
              marginRight="3vw"
            />
          ),
          duration: 5000,
          isClosable: true
        });
        break;
      case 'error':
        toast({
          position: 'top-right',
          render: () => (
            <LimaToast
              status="error"
              message={toastEvent.message}
              marginTop="12vh"
              marginRight="3vw"
            />
          ),
          duration: 5000,
          isClosable: true
        });
        break;
    }
  };

  const calculateExpiresIn = (): number => {
    // expires in seconds, 30 days from now
    const secondsNow = moment().unix();
    const expSeconds = 60 * 60 * 24 * 30;
    return secondsNow + expSeconds;
  };

  /**
   * handleTokenRefreshSubscription is the function to handle the token refresh subscription
   * @param tokenRefreshEvent - the token refresh event
   */
  const handleTokenRefreshSubscription = (tokenRefreshEvent: TokenRefreshEvent) => {
    switch (tokenRefreshEvent.status) {
      case 'refreshing':
        setLoadingStates({ ...loadingStates, isRefreshingToken: true });
        break;
      case 'refreshed':
        setLoadingStates({ ...loadingStates, isRefreshingToken: false });
        const data = tokenRefreshEvent.data;
        if (data) {
          saveLimaCookies({
            user: data.user,
            accessToken: data.access_token,
            refreshToken: data.refresh_token,
            expiresIn: calculateExpiresIn(),
            rememberMe: true
          });
          queryClient.invalidateQueries();
        }
        break;
      case 'failed':
        setLoadingStates({ ...loadingStates, isRefreshingToken: false });
        logout();
        break;
    }
  };

  /**
   * This use effect handles both the token refresh and toast subscriptions
   */
  useEffect(() => {
    // subscribe on component will mount
    const toastSubscription: Subscription = httpClient.onToast.subscribe(handleToastSubscription);
    const tokenRefreshSubscription: Subscription = httpClient.onTokenRefresh.subscribe(
      handleTokenRefreshSubscription
    );
    return () => {
      // unsubscribe on component will unmount
      toastSubscription.cancel();
      tokenRefreshSubscription.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This use effect handles setting the last path when the user is authenticated and the path changes
   */
  useEffect(() => {
    const isAuthenticated = userCookie?.user?.lima_auth !== undefined;
    const pathNameIsNotRegisterOrganization = location.pathname !== '/register-organization';
    if (isAuthenticated && pathNameIsNotRegisterOrganization) {
      setLastPath('lima_last_path', location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCookie.user, location.pathname]);

  const setSkippedMembersAdd = (skipped: boolean) => {
    setCookie('skipped_members_add', skipped, {
      path: '/',
      expires: moment().add(30, 'days').toDate(),
      secure: true,
      sameSite: 'strict'
    });
  };

  const getSkippedMembersAdd = (): boolean => {
    if (cookies.skipped_members_add) {
      try {
        return (cookies.skipped_members_add as string) === 'true';
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  };

  /**
   * This function gets the token from the cookies and decrypts it
   * @returns string - the decrypted token or an empty string
   */
  const getToken = (): string => {
    if (!cookies.lima_auth) {
      return '';
    }
    const token = decrypt(cookies.lima_auth as string);
    return token ?? '';
  };

  /**
   * This function gets the refresh token from the cookies and decrypts it
   * @returns string - the decrypted refresh token or an empty string
   */
  const getRefreshToken = (): string => {
    if (!cookies.lima_auth_refresh) {
      return '';
    }
    const refreshToken = decrypt(cookies.lima_auth_refresh as string);
    return refreshToken ?? '';
  };

  const loginMutation = useMutation({
    mutationKey: ['login'],
    mutationFn: ({
      email,
      password
    }: {
      email: string;
      password: string;
      rememberMe?: boolean;
    }) => {
      return authRepository.login(email, password);
    },
    onMutate: () => {
      // mutation is about to happen, reset the error and loading states
      loginMutation.reset();
      setLoadingStates({ ...loadingStates, isLoading: true });
    },
    onSuccess: (data: LoginResponse, { rememberMe }) => {
      setHasError(false);
      setLoadingStates({ ...loadingStates, isLoading: false });
      handleLoginResponse(data, rememberMe ?? false);
    },
    onError: (error: any) => {
      setLoadingStates({ ...loadingStates, isLoading: false });
      updateResponseInfo(true, error?.response?.data?.detail);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSettled: (data: LoginResponse | undefined, error: any | undefined) => {
      // error or success doesn't matter, this will always run last
      setLoadingStates({ ...loadingStates, isLoading: false });
      queryClient.invalidateQueries({ queryKey: ['login'] });
    }
  });

  const handleLoginResponse = (data: LoginResponse, rememberMe: boolean) => {
    if (data.error) {
      updateResponseInfo(true, data.error ?? 'Unknown error');
      return;
    }
    const accessToken = data.access_token;
    const expiresIn: number = calculateExpiresIn();
    const refreshToken = data.refresh_token;
    const user: User = data.user;
    saveLimaCookies({ user, accessToken, refreshToken, expiresIn, rememberMe });
    const userDoesNotBelongToAnOrganization =
      user.user_group === null && user.tenant_header === null;
    if (userDoesNotBelongToAnOrganization) {
      navigate('/register-organization', { replace: true });
    } else {
      const path: string = pathCookie.lima_last_path;
      const pathIsNotAnyOfTheAuthRoutes =
        !path?.includes('login') &&
        !path?.includes('register') &&
        !path?.includes('reset-password') &&
        !path?.includes('confirm-forgot-password') &&
        !path?.includes('accept-invitation') && //reset-password
        !path?.includes('terms-and-conditions');
      if (path && pathIsNotAnyOfTheAuthRoutes) {
        // just in case the last path was the register organization page
        navigate(path ? path : '/dashboard', { replace: true });
      } else {
        navigate('/dashboard', { replace: true });
      }
    }
  };

  const saveLimaCookies = ({
    user,
    refreshToken,
    accessToken,
    expiresIn,
    rememberMe
  }: {
    user: User;
    refreshToken: string;
    accessToken: string;
    expiresIn: number;
    rememberMe: boolean;
  }) => {
    setUser(user);
    setUserCookie('user', user, {
      path: '/',
      expires: moment()
        .add(60 * 60 * 24 * 30, 's')
        .toDate(),
      secure: true,
      sameSite: 'strict'
    });
    let expires: Date | undefined;
    if (rememberMe) {
      const expTime = 60 * expiresIn ?? 3600;
      expires = moment().add(expTime, 's').toDate();
    }
    const accessTokenHash = encrypt('Bearer ' + accessToken);
    setCookie('lima_auth', accessTokenHash, {
      path: '/',
      expires,
      secure: true,
      sameSite: 'strict'
    });
    const refreshTokenHash = encrypt(refreshToken);
    setCookie('lima_auth_refresh', refreshTokenHash, {
      path: '/',
      expires: moment()
        .add(60 * 60 * 24 * 30, 's')
        .toDate(),
      secure: true,
      sameSite: 'strict'
    });
    setLastPath('lima_last_path', location.pathname);
  };

  /**
   * This async function logs in the user with the provided email, password and remember me flag
   * @param param0 - the email, password and remember me flag
   * @returns boolean - true if the login was successful, false otherwise
   */
  const login = ({ email, password, rememberMe }: LoginFields) => {
    loginMutation.mutate({ email, password, rememberMe });
  };

  const registerMutation = useMutation({
    mutationKey: ['register'],
    mutationFn: ({
      name,
      email,
      password,
      confirmPassword,
      agreeToTerms
    }: {
      name: string;
      email: string;
      password: string;
      confirmPassword: string;
      agreeToTerms: boolean;
    }) => {
      return authRepository.register(name, email, password, confirmPassword, agreeToTerms);
    },
    onMutate: () => {
      // mutation is about to happen, reset the error and loading states
      setRegisterSuccess(false);
      registerMutation.reset();
      setLoadingStates({ ...loadingStates, isLoading: true });
    },
    onSuccess: (data: RegisterResponse) => {
      setHasError(false);
      setLoadingStates({ ...loadingStates, isLoading: false });
      handleRegisterResponse(data);
    },
    onError: (error: any) => {
      setLoadingStates({ ...loadingStates, isLoading: false });
      updateResponseInfo(true, error?.response?.data?.detail);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSettled: (data: RegisterResponse | undefined, error: any | undefined) => {
      // error or success doesn't matter, this will always run last
      setLoadingStates({ ...loadingStates, isLoading: false });
      queryClient.invalidateQueries({ queryKey: ['register'] });
    }
  });

  const handleRegisterResponse = (data: RegisterResponse) => {
    if (data.error) {
      updateResponseInfo(true, data?.error ?? 'Unknown error');
      return;
    }
    setHasError(false);
    setRegisterSuccess(true);
  };

  /**
   * This async function registers a user with the provided name, email, password, confirm password and agree to terms flag
   * @param param0 - the name, email, password, confirm password and agree to terms flag
   * @returns boolean - true if the registration was successful, false otherwise
   */
  const register = async ({
    name,
    email,
    password,
    confirmPassword,
    agreeToTerms
  }: RegisterFields) => {
    registerMutation.mutate({
      name,
      email,
      password,
      confirmPassword,
      agreeToTerms
    });
  };

  /**
   * This async function confirms the registration of a user with the provided email and code
   * @param param0 - the email and code
   * @returns boolean - true if the registration was confirmed, false otherwise
   */
  const confirmRegister = async ({ email, code }: RegisterConfirmFields): Promise<boolean> => {
    setLoadingStates({ ...loadingStates, isLoading: true });
    const confirmRegisterResponse: ConfirmRegisterResponse = await authRepository.confirmRegister(
      email,
      code
    );
    if (confirmRegisterResponse.hasError) {
      updateResponseInfo(true, confirmRegisterResponse?.response?.data?.detail);
    }
    const res = confirmRegisterResponse.response;
    setLoadingStates({ ...loadingStates, isLoading: false });
    if (res?.data && res.data.success && res.data.message === 'User confirmation successful') {
      setHasError(false);
      setLoadingStates({ ...loadingStates, isLoading: false });
      navigate('/login', { replace: true });
      return true;
    } else {
      setHasError(true);
      setLoadingStates({ ...loadingStates, isLoading: false });
    }
    return false;
  };

  const forgotPasswordMutation = useMutation({
    mutationKey: ['forgotPassword'],
    mutationFn: ({ email }: { email: string }) => {
      return authRepository.forgotPassword(email);
    },
    onMutate: () => {
      // mutation is about to happen, reset the error and loading states
      forgotPasswordMutation.reset();
      setLoadingStates({ ...loadingStates, isLoading: true });
      setForgotPasswordSuccess(false);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data: ForgotPasswordResponse) => {
      setHasError(false);
      setLoadingStates({ ...loadingStates, isLoading: false });
      handleForgotPasswordResponse();
    },
    onError: (error: any) => {
      setLoadingStates({ ...loadingStates, isLoading: false });
      updateResponseInfo(true, error?.response?.data?.detail);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSettled: (data: ForgotPasswordResponse | undefined, error: any | undefined) => {
      // error or success doesn't matter, this will always run last
      setLoadingStates({ ...loadingStates, isLoading: false });
      queryClient.invalidateQueries({ queryKey: ['forgotPassword'] });
    }
  });

  const handleForgotPasswordResponse = () => {
    setHasError(false);
    setForgotPasswordSuccess(true);
  };

  /**
   * This async function sends a password reset email to the provided email
   * @param param0 - the email
   * @returns boolean - true if the email was sent, false otherwise
   */
  const forgotPassword = ({ email }: ForgotPasswordFields) => {
    forgotPasswordMutation.mutate({ email });
  };

  const confirmPasswordResetMutation = useMutation({
    mutationKey: ['confirmForgotPassword'],
    mutationFn: ({
      email,
      code,
      new_password,
      confirmPassword
    }: {
      email: string;
      code: string;
      new_password: string;
      confirmPassword: string;
    }) => {
      return authRepository.confirmForgotPassword(email, code, new_password, confirmPassword);
    },
    onMutate: () => {
      // mutation is about to happen, reset the error and loading states
      confirmPasswordResetMutation.reset();
      setLoadingStates({ ...loadingStates, isLoading: true });
      setConfirmForgotPasswordSuccess(false);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data: ForgotPasswordResponse) => {
      setHasError(false);
      setLoadingStates({ ...loadingStates, isLoading: false });
      handleConfirmForgotPasswordResponse();
    },
    onError: (error: any) => {
      setLoadingStates({ ...loadingStates, isLoading: false });
      updateResponseInfo(true, error?.response?.data?.detail);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSettled: (data: ForgotPasswordResponse | undefined, error: any | undefined) => {
      // error or success doesn't matter, this will always run last
      setLoadingStates({ ...loadingStates, isLoading: false });
      queryClient.invalidateQueries({ queryKey: ['confirmForgotPassword'] });
    }
  });

  const handleConfirmForgotPasswordResponse = () => {
    setHasError(false);
    setConfirmForgotPasswordSuccess(true);
  };

  /**
   * This async function confirms the password reset of a user with the provided email, code, new password and confirm password
   * @param param0 - the email, code, new password and confirm password
   * @returns boolean - true if the password reset was confirmed, false otherwise
   */
  const confirmForgotPassword = async ({
    email,
    code,
    new_password,
    confirmPassword
  }: ForgotPasswordFields) => {
    if (code && new_password && confirmPassword) {
      confirmPasswordResetMutation.mutate({
        email,
        code,
        new_password,
        confirmPassword
      });
    } else {
      updateResponseInfo(true, 'Please fill in all the fields');
    }
  };

  const handleLogoutResponse = () => {
    removeCookie('lima_auth');
    removeCookie('lima_auth_refresh');
    removeUserCookie('user');
    setUser(null);
    setFarms([]);
    setFarm(null);
    if (
      currentFarmOnboardStep !== FarmOnboardSteps.DONE &&
      location.pathname.includes('settings') &&
      user?.onboarding_status !== 'complete'
    ) {
      // reset last path to '/dashboard'
      // setLastPath('lima_last_path', '/dashboard');
      removeCookie('lima_last_path');
      navigate('/login', { replace: true });
    }
    navigate('/login', { replace: true });
    // else {
    //   // TODO: fix this bug
    //   removeCookie('lima_last_path');
    //   navigate('/login', { replace: true });
    // }
    // invalidate everything in query client
    queryClient.invalidateQueries();
  };

  const logoutMutation = useMutation({
    mutationKey: ['logout'],
    mutationFn: () => {
      return authRepository.logout(getToken());
    },
    onMutate: () => {
      // mutation is about to happen, reset the error and loading states
      logoutMutation.reset();
      setLoadingStates({ ...loadingStates, isLoading: true });
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data: any) => {
      setHasError(false);
      setLoadingStates({ ...loadingStates, isLoading: false });
      handleLogoutResponse();
    },
    onError: (error: any) => {
      setLoadingStates({ ...loadingStates, isLoading: false });
      updateResponseInfo(true, error?.response?.data?.detail);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSettled: (data: any | undefined, error: any | undefined) => {
      // error or success doesn't matter, this will always run last
      setLoadingStates({ ...loadingStates, isLoading: false });
      queryClient.invalidateQueries({ queryKey: ['logout'] });
    }
  });

  /**
   * This async function logs out the user and removes the auth token from the cookies, also resets vital context variables
   * @returns boolean - true if the user was logged out, false otherwise
   */
  const logout = () => {
    logoutMutation.mutate();
  };

  /**
   * This async function fetches the blocks of a variety and sets the blocks state variable
   */
  const getVarietyBlocks = async (): Promise<void> => {
    const token = getToken();
    if (token && farm && variety) {
      setLoadingStates({ ...loadingStates, loadingBlocks: true });
      if (!selectedCycle) setSelectedCycle(null);
      const varietyBlocksResponse: any = await userBlocksRepository.getVarietyBlocks(
        token,
        variety,
        farm?.organization.internal_name ? farm?.organization.internal_name : '',
        fieldStatus
      );
      if (varietyBlocksResponse?.res?.data) {
        setFields(varietyBlocksResponse?.res?.data?.length ? varietyBlocksResponse.res.data : []);
      }
      setLoadingStates({ ...loadingStates, isLoading: false });
      setTimeout(() => {
        setLoadingStates({ ...loadingStates, loadingBlocks: false });
      }, 500);
    } else {
      setFields([]);
    }
  };

  /**
   * This useEffect function fetches the blocks of a variety when the variety is updated or when there's CRUD to the blocks/block cycle
   */
  useEffect(() => {
    const variety_is_valid = farm?.varieties?.some((farm_variety) => {
      return farm_variety?.id === variety?.id;
    });
    if (
      (location.pathname === '/farm-production' || location.pathname === '/harvest-data') &&
      variety_is_valid
    ) {
      getVarietyBlocks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    variety?.id,
    farm?.id,
    blockCycleUpdated,
    fieldsUpdated,
    newBlockAdded,
    location.pathname,
    fieldStatus
  ]);

  const farmsQuery = useFarmsQuery({
    currentUserEmail: userCookie?.user?.email,
    token: getToken()
  });

  useEffect(() => {
    setFarms(farmsQuery.data ?? []);
    let sorted_farms = [];
    if (farmsQuery?.data?.length)
      sorted_farms = sortTableColumn(farmsQuery.data, 'external_name', 'asc');
    setFarms(sorted_farms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmsQuery.data]);

  const getUserBlocksAndFarms = (): void => {
    farmsQuery.refetch();
  };

  /**
   * This useEffect function checks if the user is authenticated and calls the getUserBlocksAndFarms function
   */
  useEffect(() => {
    setLoadingStates({ ...loadingStates, isLoading: false });
    setStart(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  /**
   * This useEffect sets the user state variable to the user cookie value
   */
  useEffect(() => {
    if (userCookie.user) {
      setUser(userCookie.user);
    }
  }, [userCookie]);

  /**
   * This useEffect logs the user out if the user is not authenticated and the appAuth state variable is true
   */
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!appAuth) {
      logout();
      timeout = setTimeout(() => {
        setAppAuth(true);
      }, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appAuth]);

  /**
   * This function sets the responseInfo and hasError variables
   * @param error - boolean value that determines if the responseInfo is an error or not
   * @param responseInfo - the responseInfo string or array of strings that will be set to the responseInfo variable and will be displayed to the user
   */
  const updateResponseInfo = (error: boolean, responseInfo: string): void => {
    let errorMessage = 'Error: ';
    if (!isArray(responseInfo)) {
      errorMessage += responseInfo;
    } else {
      responseInfo.forEach((detail: any) => {
        errorMessage += `[${detail.loc[1]}]`;
        errorMessage += ' ' + detail.msg;
      });
    }
    setResponseInfo(errorMessage);
    setHasError(error);
  };

  return (
    <CookiesProvider>
      <MyAuthContext.Provider
        value={{
          loadingStates,
          functions: {
            login,
            register,
            logout,
            getToken,
            setHasError,
            setResponseInfo,
            confirmRegister,
            forgotPassword,
            confirmForgotPassword,
            getUserBlocksAndFarms,
            getRefreshToken,
            setUserCookie,
            setSkippedMembersAdd,
            getSkippedMembersAdd
          },
          variables: {
            user,
            responseInfo,
            hasError,
            registerSuccess,
            forgotPasswordSuccess,
            confirmForgotPasswordSuccess
          }
        }}
      >
        {loadingStates.isLoading && start ? (
          <Flex width="100vw" height="100vh" justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        ) : (
          children
        )}
      </MyAuthContext.Provider>
    </CookiesProvider>
  );
}
