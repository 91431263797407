import { FunctionComponent } from 'react';

import {
  ConfirmForgotPassword,
  ForgotPassword,
  FarmProduction,
  Login,
  Register,
  AcceptInvitation
} from './Pages';
import Dashboard from 'Pages/FarmProduction';
import HarvestData from 'Pages/ActualData';
import RegisterOrganization from 'Pages/RegisterOrganization';
import ProductionPlan from 'Pages/ProductionPlan';
import TermsAndConditions from 'Pages/TermsAndConditions';

export type NavItem = {
  path: string;
  label: string;
  element: FunctionComponent;
  exact?: boolean;
  private?: boolean;
};

export type MyRoute = NavItem & {
  nested?: NavItem[];
};

/**
 * Example usage of routing structure
 * 
 * ```js
    { 
        path: '/home',
        label: 'Home',
        element: <Home />

        nested: [
            {
                path: ':home-child',
                label: 'Home Child',
                element: <HomeChild />
            }
        ]
    }
   ```
 */

export const PRIVATE_ROUTES: MyRoute[] = [
  {
    path: '/',
    label: 'Dashboard',
    element: Dashboard,
    exact: true
  },
  {
    path: '/dashboard',
    label: 'Dashboard',
    element: Dashboard,
    exact: true
  },
  {
    path: '/farm-production',
    label: 'Farm Production',
    element: FarmProduction,
    exact: true
  },
  {
    path: '/harvest-data',
    label: 'Harvest Data',
    element: HarvestData,
    exact: true
  },
  {
    path: '/production-plan',
    label: 'Production Plan',
    element: ProductionPlan,
    exact: true
  }
];
export const OTHER_ROUTES: MyRoute[] = [
  {
    path: 'register-organization',
    label: 'Register Organization',
    element: RegisterOrganization,
    exact: true
  },
  {
    path: 'terms-and-conditions',
    label: 'Terms and Conditions',
    element: TermsAndConditions,
    exact: true
  }
];
export const PUBLIC_ROUTES: MyRoute[] = [
  {
    path: 'login',
    label: 'Login',
    element: Login,
    exact: true
  },
  {
    path: 'register',
    label: 'Register',
    element: Register,
    exact: true
  },
  {
    path: 'reset-password',
    label: 'Forgot Password',
    element: ForgotPassword,
    exact: true
  },
  {
    path: 'confirm-forgot-password',
    label: 'Reset Password',
    element: ConfirmForgotPassword,
    exact: true
  },
  {
    path: 'accept-invitation',
    label: 'Accept Invitation',
    element: AcceptInvitation,
    exact: true
  }
];
