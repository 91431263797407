import React from 'react';
import { Box, GridItem, Grid, Icon, Text } from '@chakra-ui/react';
import { separateComma } from 'Helpers/helpers';
import { FilterDatum, useGraphContext } from 'Context/GraphContext';
import { Circle } from 'react-feather';

function FieldProductivityTooltip(props: any) {
  const {
    data: { indexValue, data }
  } = props;
  const { fieldProductivityTags, primaryProductivityTags } = useGraphContext();
  const isActive = (id: string) => {
    return !!fieldProductivityTags?.find((tag: FilterDatum) => tag.active && tag.id === id);
  };
  const getIconColor = (id: string) => {
    return fieldProductivityTags?.find((tag: FilterDatum) => tag.id === id)?.fill ?? '';
  };
  const getTagLabel = (id: string) => {
    return fieldProductivityTags?.find((tag: FilterDatum) => tag.id === id)?.label ?? '';
  };
  return (
    <Box
      style={{
        padding: 12,
        background: '#ffffff',
        boxShadow: '0px 1px 6px rgba(12, 12, 13, 0.15)',
        textAlign: 'center',
        borderRadius: '4px',
        border: `2px solid`,
        borderColor: '',
        fontSize: '16px'
      }}
    >
      <Text
        as="p"
        textAlign={'center'}
        color={'dark.coolGray.500'}
        style={{
          marginBottom: '0.75rem'
        }}
        className={'readex-font'}
      >
        Field {indexValue}
      </Text>
      <Grid templateColumns="repeat(1, 1fr)" gap={0} alignItems="left">
        {primaryProductivityTags?.map((tag, ind: number) => (
          <Box key={ind}>
            {isActive(tag) && (
              <GridItem key={ind} p={0.5}>
                <Text as="p" color={'dark.coolGray.500'} textAlign={'left'} display="flex">
                  <Icon
                    as={Circle}
                    fill={getIconColor(tag)}
                    color="transparent"
                    width={'16px'}
                    height={'16px'}
                    alignSelf={'center'}
                    my="auto"
                    justifySelf={'center'}
                    mr="0.25rem"
                  />
                  {getTagLabel(tag) + ':'}&nbsp;
                  <Text as="span" color={'dark.coolGray.600'}>
                    {separateComma(data?.[`${tag}`] ?? 0)}
                  </Text>
                </Text>
              </GridItem>
            )}
          </Box>
        ))}
      </Grid>
    </Box>
  );
}

export default FieldProductivityTooltip;
