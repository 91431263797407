import React, { useState } from 'react';
import { Tab as CKTab, TabProps, Text } from '@chakra-ui/react';

export default function Tab({ children, ...props }: TabProps): JSX.Element {
  const [color] = useState('green');
  const [isSelected] = useState(props['aria-selected']);
  return (
    <CKTab
      color={'dark.coolGray.300'}
      border={'none'}
      _selected={{
        color: `${color}.500`,
        border: '1.5px solid',
        borderColor: `${color}.200`,
        borderBottom: '20px !important',
        backgroundColor: 'white'
      }}
      _focus={{
        boxShadow: 'none'
      }}
      {...props}
    >
      {isSelected ? <Text className="green-circle" as="span"></Text> : props['aria-selected']}
      {children}
    </CKTab>
  );
}
