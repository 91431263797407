import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useAuthContext } from './Auth';
import { useAppContext } from './App';
import moment from 'moment';
import GraphRepository from 'domain/GraphRepository';
import {
  GradesAndRejectsType,
  ActiveFieldsType,
  OrganizedYields,
  MininumExpectedItem
} from 'data/GraphRepositoryImpl';
import { ReportFilterState } from 'Pages/FarmProduction/Components/ReportSectionCard';
import { GraphColor } from './types';
import {
  computeMinimumExpectedHarvestWork,
  formatHarvestByFieldTagsWork
} from 'Helpers/workerHelpers';
import { formatHarvestByFieldTags, formatToServerFormat, varietyIsValid } from 'Helpers/helpers';
import useActualsVsForecastQuery, {
  ActualsVsForecastQuery
} from 'Pages/FarmProduction/helpers/useActualsVsForecastQuery';

export type RawPlotData = {
  stems: number;
  rejected: number;
  grading: Object;
};

export type PlotData = {
  [key: string]: RawPlotData;
};

export type Period = {
  daily: PlotData[];
  monthly: PlotData[];
  weekly: PlotData[];
};

export type Yields = {
  actual_yields: Period;
  latest_predictions: Period;
  predictions: Period;
};

export type GraphData = {
  [key: string]: any;
};

export type LineData = {
  [key: string]: any;
};

export enum Direction {
  YearForward,
  YearBackward
}

export type FarmProductionDuration = 'daily' | 'weekly' | 'monthly';

export type FilterDatum = {
  id: string;
  label: string;
  active: boolean;
  fill: string;
  bg?: string;
};

export type HarvestYieldSummary = {
  label: string;
  value: any;
  id: string;
};
export type GraphPlotData = {
  id: string;
  label: string;
  value: number;
};

export type ForecastedYieldsFilters = {
  forecast_duration: string;
};

export type FieldProductivityFilters = {
  stems: string;
  fields: string[];
};

type GraphContextType = {
  loadingGraphData: boolean;
  loadingDemandData: boolean;
  activeYields: Yields | null;
  fetchLineData: (forceRefresh?: boolean) => void;
  loadingLineData: boolean;
  activePreds: Yields | null;
  timeTravel: (direction: Direction) => void;
  selectedYear: string;
  duration: FarmProductionDuration;
  setDuration: (duration: 'daily' | 'weekly' | 'monthly') => void;
  totalsDuration: FarmProductionDuration;
  setTotalsDuration: (duration: 'daily' | 'weekly' | 'monthly') => void;
  value: string | number;
  error: string | null;
  lineError: string | null;
  graphData: GraphData | null;
  demandData: any | null;
  lineData: LineData | null;
  sortedLines: any | null;
  setSortedLines: (sortedLines: any) => void;
  filterTags: FilterDatum[] | [];
  setFilterTags: (tag: FilterDatum[]) => void;
  fetchGraphHarvests: (queryParams: {
    start_date: any;
    end_date: any;
    granularity: string;
  }) => void;
  minimumExpectedData: MininumExpectedItem[] | undefined;
  setMinimumExpectedData: (value: MininumExpectedItem[]) => void;
  fetchMinimumExpectedHarvest: () => any;
  gradesAndRejects: GradesAndRejectsType | null;
  reportFilterState: ReportFilterState;
  setReportFilterState: (state: ReportFilterState) => void;
  loadingGradesAndRejects: boolean;
  gradesAndRejectsTags: FilterDatum[] | [];
  setGradesAndRejectsTags: (tag: FilterDatum[]) => void;
  setLoadingGradesAndRejects: (value: boolean) => void;
  loadingPrevHarvestByField: boolean;
  setLoadingPrevHarvestByField: (value: boolean) => void;
  loadingMinimumExpected: boolean;
  setLoadingMinimumExpected: (value: boolean) => void;
  loadingPrevActiveFields: boolean;
  prevActiveFields: ActiveFieldsType[] | null;
  setPrevActiveFields: (field: ActiveFieldsType[]) => void;
  historicalsYear: string;
  setSelectedPrevFields: (fields: string[]) => void;
  prevHarvestYields: OrganizedYields | null;
  prevHarvestYieldSummary: HarvestYieldSummary[] | null;
  prevHarvestTags: FilterDatum[] | [];
  loadingForecastedHarvestByField: boolean;
  forecastedActiveFields: ActiveFieldsType[] | null;
  setForecastedActiveFields: (field: ActiveFieldsType[]) => void;
  setSelectedForecastedFields: (fields: string[]) => void;
  setForecastedYieldsFilters: (filter: ForecastedYieldsFilters) => void;
  selectedForecastedFields: string[];
  forecastedHarvestYields: OrganizedYields | null;
  forecastedHarvestTags: FilterDatum[] | [];
  selectedForecast: number;
  setSelectedForecast: (forecast: number) => void;
  selectedPrevFields: string[];
  loadingForecastedActiveFields: boolean;
  currentForecastTab: string;
  setCurrentForecastTab: (type: string) => void;
  currentHistoricalsTab: string;
  setCurrentHistoricalsTab: (type: string) => void;
  fieldProductivityFilters: FieldProductivityFilters;
  setFieldProductivityFilters: (filters: FieldProductivityFilters) => void;
  currentProductivityTab: string;
  setCurrentProductivityTab: (type: string) => void;
  setLoadingFieldProductivity: (value: boolean) => void;
  fieldProductivityTags: FilterDatum[] | [];
  setFieldProductivityTags: (tag: FilterDatum[]) => void;
  fieldProductivity: OrganizedYields | null;
  setFieldProductivity: (item: any) => void;
  loadingFieldProductivity: boolean;
  graphColors: GraphColor[];
  selectedFieldProductivity: any;
  setSelectedFieldProductivity: (item: any) => void;
  productivityFields: ActiveFieldsType[] | null;
  setProductivityFields: (field: ActiveFieldsType[]) => void;
  quickActionFilters: any[];
  setQuickActionFilters: (filters: any[]) => void;
  originalFieldProductivity: OrganizedYields | null;
  primaryProductivityTags: string[];
  xAxisWidth: number;
  setXAxisWidth: (width: number) => void;
  actualVsForecastQuery: ActualsVsForecastQuery;
};

const MyGraphContext = createContext<GraphContextType>({
  loadingGraphData: false,
  loadingDemandData: false,
  activeYields: null,
  fetchLineData: () => {},
  loadingLineData: false,
  activePreds: null,
  timeTravel: () => {},
  selectedYear: '',
  duration: 'daily',
  totalsDuration: 'weekly',
  setDuration: () => {},
  setTotalsDuration: () => {},
  value: -1,
  error: null,
  lineError: null,
  graphData: null,
  demandData: null,
  lineData: null,
  sortedLines: null,
  setSortedLines: () => {},
  filterTags: [],
  setFilterTags: () => {},
  fetchGraphHarvests: () => {},
  gradesAndRejects: null,
  reportFilterState: {
    weeks: null,
    days: null,
    years: null
  },
  minimumExpectedData: undefined,
  setMinimumExpectedData: () => {},
  fetchMinimumExpectedHarvest: () => [],
  setReportFilterState: () => {},
  loadingGradesAndRejects: false,
  gradesAndRejectsTags: [],
  setGradesAndRejectsTags: () => {},
  setLoadingGradesAndRejects: () => {},
  loadingPrevHarvestByField: false,
  setLoadingPrevHarvestByField: () => {},
  loadingMinimumExpected: false,
  setLoadingMinimumExpected: () => {},
  loadingPrevActiveFields: false,
  prevActiveFields: null,
  setPrevActiveFields: () => {},
  historicalsYear: '',
  setSelectedPrevFields: () => {},
  prevHarvestYields: null,
  prevHarvestYieldSummary: null,
  prevHarvestTags: [],
  loadingForecastedHarvestByField: false,
  forecastedActiveFields: null,
  setForecastedActiveFields: () => {},
  setSelectedForecastedFields: () => {},
  setForecastedYieldsFilters: () => {},
  selectedForecastedFields: [],
  forecastedHarvestYields: null,
  forecastedHarvestTags: [],
  selectedForecast: 0,
  setSelectedForecast: () => {},
  selectedPrevFields: [],
  loadingForecastedActiveFields: false,
  currentForecastTab: '',
  setCurrentForecastTab: () => {},
  currentHistoricalsTab: '',
  setCurrentHistoricalsTab: () => {},
  fieldProductivityFilters: { stems: 'stems', fields: [] },
  setFieldProductivityFilters: () => {},
  currentProductivityTab: '',
  setCurrentProductivityTab: () => {},
  setLoadingFieldProductivity: () => {},
  fieldProductivityTags: [],
  setFieldProductivityTags: () => {},
  fieldProductivity: null,
  setFieldProductivity: () => {},
  loadingFieldProductivity: false,
  graphColors: [],
  selectedFieldProductivity: null,
  setSelectedFieldProductivity: () => {},
  productivityFields: null,
  setProductivityFields: () => {},
  quickActionFilters: [],
  setQuickActionFilters: () => {},
  originalFieldProductivity: null,
  primaryProductivityTags: [],
  xAxisWidth: 0,
  setXAxisWidth: () => {},
  actualVsForecastQuery: {
    barDatum: [],
    demandData: [],
    yMax: 0,
    isLoading: false,
    hasNext: false,
    hasPrevious: false
  }
});

export const useGraphContext = () => useContext(MyGraphContext);

// pass graph repository as props
export default function GraphContext({
  children,
  graphRepository
}: {
  children: ReactNode;
  graphRepository: GraphRepository;
}): JSX.Element {
  const {
    variables: { user },
    functions: { getToken }
  } = useAuthContext();
  const { variety, farm, limaActiveModules, varietyIsCyclic } = useAppContext();

  const [loadingGraphData, setLoadingGraphData] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [graphData, setGraphData] = useState<GraphData | null>(null);

  const [loadingLineData, setLoadingLineData] = useState(false);
  const [lineError, setLineError] = useState<string | null>(null);
  const [lineData, setLineData] = useState<LineData | null>([]);

  const [loadingDemandData, setLoadingDemandData] = useState(false);
  const [demandData, setDemandData] = useState<GraphData | null>(null);

  const [currYear] = useState(moment().year());
  const [currentDay] = useState<number>(moment().date());
  const [currentWeek] = useState<number>(moment().week());
  const [currentMonth] = useState<number>(moment().month());
  const [value, setValue] = useState<string | number>(-1);

  const [activeYields, setActiveYields] = useState<Yields | null>(null);
  const [selectedYear, setSelectedYear] = useState<string>(currYear.toString());
  const [duration, setDuration] = useState<'daily' | 'weekly' | 'monthly'>('weekly');
  const [totalsDuration, setTotalsDuration] = useState<'daily' | 'weekly' | 'monthly'>('weekly');

  const [activePreds, setActivePreds] = useState<Yields | null>(null);

  const [currentDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [fetchDate, setFetchDate] = useState<string>(currentDate);

  const [hasNextYear, setHasNextYear] = useState<boolean>(true);
  const [hasPrevYear, setHasPrevYear] = useState<boolean>(true);

  const [reportFilterState, setReportFilterState] = React.useState<ReportFilterState>({
    weeks: null,
    days: null,
    years: null
  });

  const [sortedLines, setSortedLines] = useState<any | null>(null);
  const initialTags: FilterDatum[] = [
    {
      id: 'actual_stems',
      label: 'Actual Production',
      active: true,
      fill: '#626262'
    },
    {
      id: 'predicted_stems',
      label: 'Forecast Production',
      active: true,
      fill: '#00B8FF'
    },
    // NB: Uncomment this code to re-enable day/weeks out milestone functionality
    // {
    //   id: duration == 'weekly' ? 'weeks_out_1' : 'days_out_7',
    //   label: '1 Week Forecast',
    //   active: false,
    //   fill: '#83DCFF'
    // },
    // {
    //   id: duration == 'weekly' ? 'weeks_out_2' : 'days_out_14',
    //   label: '2 Weeks Forecast',
    //   active: false,
    //   fill: '#C9D2DA'
    // },
    { id: 'production_plan', label: 'Production Plan', active: false, fill: '#66D998' }
  ];
  const [filterTags, setFilterTags] = useState<FilterDatum[] | []>(initialTags);
  const [gradesAndRejects, setGradesAndRejects] = useState<GradesAndRejectsType | null>(null);
  const [loadingGradesAndRejects, setLoadingGradesAndRejects] = useState(false);
  const [gradesAndRejectsTags, setGradesAndRejectsTags] = useState<FilterDatum[] | []>([]);
  const [loadingPrevHarvestByField, setLoadingPrevHarvestByField] = useState(false);
  const [loadingMinimumExpected, setLoadingMinimumExpected] = useState(false);
  const [loadingPrevActiveFields, setLoadingPrevActiveFields] = useState(false);
  const [loadingForecastedActiveFields, setLoadingForecastedActiveFields] = useState(false);
  const [prevActiveFields, setPrevActiveFields] = useState<ActiveFieldsType[] | null>(null);
  const [historicalsYear, setHistoricalsYear] = useState<string>(currYear.toString());
  const [selectedPrevFields, setSelectedPrevFields] = useState<string[]>([]);
  const [prevHarvestYields, setPrevHarvestYields] = useState<OrganizedYields | null>(null);
  const graphColors: GraphColor[] = [
    { icon: '#EE7777', bg: '#FDF1F1', border: '#EE7777' },
    { icon: '#6096B4', bg: '#E5F6FF', border: '#6096B4' },
    { icon: '#D3BFED', bg: '#F7F2FF', border: '#D3BFED' },
    { icon: '#FFC185', bg: '#FFF5EB', border: '#FFC185' },
    { icon: '#AD8B73', bg: '#FBF4EF', border: '#AD8B73' },
    { icon: '#94BEFE', bg: '#94BEFE1A', border: '#94BEFE' },
    { icon: '#37DAD3', bg: '#00B91D1A', border: '#37DAD3' },
    { icon: '#D3BFED', bg: '#A67EDA1A', border: '#D3BFED' },
    { icon: '#66CBC0', bg: '#66CBC01A', border: '#66CBC0' },
    { icon: '#E9F2FF', bg: '#CBD2D91A', border: '#E9F2FF' },
    { icon: '#53A5FF', bg: '#53A5FF1A', border: '#53A5FF' }
  ];
  const initialPrevHarvestSummary = [
    {
      label: 'Total Active Fields',
      value: 0,
      id: 'active_fields'
    },
    {
      label: 'Total Harvested Fields',
      value: 0,
      id: 'no_of_harvests'
    },
    {
      label: 'Total Harvest Days',
      value: 0,
      id: 'no_of_harvest_days'
    }
  ];
  const [prevHarvestYieldSummary, setPrevHarvestYieldSummary] =
    useState<HarvestYieldSummary[]>(initialPrevHarvestSummary);
  const [harvestYieldsColors, setHarvestYieldsColors] = useState<string[]>([]);
  const [prevHarvestTags, setPrevHarvestTags] = useState<FilterDatum[]>([]);
  const [loadingForecastedHarvestByField, setLoadingForecastedHarvestByField] = useState(false);
  const [forecastedActiveFields, setForecastedActiveFields] = useState<ActiveFieldsType[] | null>(
    null
  );
  const [maxActiveFieldsLength, setMaxActiveFieldsLength] = useState<number>(0);
  const [selectedForecastedFields, setSelectedForecastedFields] = useState<string[]>([]);
  const [forecastedYieldsFilters, setForecastedYieldsFilters] =
    useState<ForecastedYieldsFilters | null>(null);
  const [forecastedHarvestYields, setForecastedHarvestYields] = useState<OrganizedYields | null>(
    null
  );
  const [forecastedHarvestYieldsByMilestones, setForecastedHarvestYieldsByMilestones] = useState<{
    [key: string]: any;
  } | null>(null);
  const [forecastedHarvestTags, setForecastedHarvestTags] = useState<FilterDatum[]>([]);
  const [selectedForecast, setSelectedForecast] = useState<number>(0);
  const [currentForecastTab, setCurrentForecastTab] = useState<string>('');
  const [currentHistoricalsTab, setCurrentHistoricalsTab] = useState<string>('');
  const [currentProductivityTab, setCurrentProductivityTab] = useState<string>('');
  const [fieldProductivityFilters, setFieldProductivityFilters] =
    useState<FieldProductivityFilters>({ stems: 'stems', fields: [] });
  const [loadingFieldProductivity, setLoadingFieldProductivity] = useState<boolean>(false);
  const [productivityFields, setProductivityFields] = useState<ActiveFieldsType[] | null>(null);
  const [actualsVsForecastQueryResult, setActualsVsForecastQueryResult] =
    useState<ActualsVsForecastQuery>({
      barDatum: [],
      demandData: [],
      yMax: 0,
      isLoading: false,
      hasNext: false,
      hasPrevious: false
    });
  const productivityTileColors = {
    total_harvest: '#68E5AA',
    rejected: '#EE7777',
    field_rejects: '#5DC9FF'
  };
  const initialProductivityTags: any[] = [
    {
      id: 'total_harvest',
      label: 'Accepted',
      active: true,
      fill: productivityTileColors?.total_harvest,
      bg: '#EBFBF4'
    },
    {
      id: 'rejected',
      label: 'Rejected',
      active: true,
      fill: productivityTileColors?.rejected,
      bg: '#FDF1F1'
    },
    {
      id: 'field_rejects',
      label: 'Field Rejects',
      active: true,
      fill: productivityTileColors?.field_rejects,
      bg: '#E9F2FF'
    }
  ];
  const [fieldProductivityTags, setFieldProductivityTags] = useState<FilterDatum[] | []>(
    initialProductivityTags
  );
  const [fieldProductivity, setFieldProductivity] = useState<OrganizedYields | null>(null);
  const [unfilteredFieldProductivities, setUnfilteredFieldProductivities] = useState<{
    [key: string]: any;
  } | null>(null);
  const [originalFieldProductivity, setOriginalFieldProductivity] =
    useState<OrganizedYields | null>(null);
  const [selectedFieldProductivity, setSelectedFieldProductivity] = useState<any | null>(null);
  const quickActions: any[] = [
    { value: null, id: 'total_harvest', label: 'Top 5 Accepted', active: false },
    { value: null, id: 'rejected', label: 'Top 5 Rejected', active: false },
    {
      value: null,
      id: 'field_rejects',
      label: 'Top 5 Field Rejected',
      active: false
    }
  ];
  const [quickActionFilters, setQuickActionFilters] = useState<any[]>(quickActions);
  const primaryProductivityTags = ['total_harvest', 'rejected', 'field_rejects'];
  const [xAxisWidth, setXAxisWidth] = useState<number>(0);
  useEffect(() => {
    if (duration !== 'weekly') {
      const newTags = initialTags?.filter((tag: any) => tag.id !== 'production_plan');
      setFilterTags([...newTags]);
    } else setFilterTags(initialTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  useEffect(() => {
    setFieldProductivityTags([]);
    setSelectedFieldProductivity(null);
    const newTags: FilterDatum[] =
      variety?.production_categories
        ?.filter((grade: any) => grade.internal_name !== 'unknown_reject')
        ?.map((grade: any, ind: number) => {
          const newObj: { [key: string]: any } = {};
          newObj['id'] = grade.id;
          newObj['label'] = grade.external_name;
          newObj['active'] = true;
          newObj['fill'] = graphColors[ind + 1].icon;
          newObj['bg'] = graphColors[ind + 1].bg;
          return newObj as FilterDatum;
        }) ?? [];
    setFieldProductivityTags([...initialProductivityTags, ...newTags]);
    const unknown_production_category = variety?.production_categories.filter(
      (grade) => grade.external_name == 'Unknown Reject'
    )[0];
    newTags.unshift({
      id: unknown_production_category ? unknown_production_category.id + '' : 'rejected',
      label: 'Rejected',
      active: true,
      fill: graphColors[0].icon,
      bg: graphColors[0].bg
    });
    setGradesAndRejectsTags([...newTags]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variety]);

  useEffect(() => {
    if (selectedFieldProductivity?.period) {
      const found = fieldProductivity?.formattedData?.find(
        (field: any) => field.period === selectedFieldProductivity?.period
      );
      setSelectedFieldProductivity(found);
    } //reset selected field productivity values when we switch between stems and stems/m2
    if (
      !fieldProductivity?.formattedData?.some(
        (field: any) => field.max && field.period === selectedFieldProductivity?.period
      )
    )
      setSelectedFieldProductivity(null); //reset only when selected field is not in returned data
    if (productivityFields?.every((el: any) => el.selected)) {
      setOriginalFieldProductivity(fieldProductivity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldProductivity]);

  useEffect(() => {
    if (duration === 'weekly' && currentWeek > -1) {
      setValue(currentWeek.toString());
    } else if (duration === 'monthly' && currentMonth > -1) {
      const cMonth = moment().month(currentMonth).format('MMM');
      setValue(cMonth);
    } else if (duration === 'daily' && currentDay > -1) {
      const cMonth = moment().month(currentMonth).format('MMM');
      const formattedCurrentDay = `${cMonth} ${currentDay}`;
      setValue(formattedCurrentDay);
    }
  }, [duration, currentDay, currentMonth, currentWeek]);

  const durationToGranularity = (duration: FarmProductionDuration): 'days' | 'weeks' | 'months' => {
    if (duration === 'daily') {
      return 'days';
    } else if (duration === 'weekly') {
      return 'weeks';
    } else {
      return 'months';
    }
  };

  const getStartDate = (): string => {
    const selectedYearDigit = parseInt(selectedYear);
    if (duration == 'weekly') {
      return formatToServerFormat(
        moment().isoWeekYear(selectedYearDigit).isoWeek(1).startOf('isoWeek').toDate()
      );
    } else {
      return formatToServerFormat(moment().isoWeekYear(selectedYearDigit).startOf('year').toDate());
    }
  };

  const getEndDate = (): string => {
    const selectedYearDigit = parseInt(selectedYear);
    if (duration == 'weekly') {
      return formatToServerFormat(
        moment()
          .isoWeekYear(selectedYearDigit)
          .isoWeek(moment().isoWeekYear(selectedYearDigit).isoWeeksInYear())
          .endOf('isoWeek')
          .startOf('day')
          .toDate()
      );
    } else {
      return formatToServerFormat(
        moment().isoWeekYear(selectedYearDigit).endOf('year').startOf('day').toDate()
      );
    }
  };

  const actualsVsForecastQuery = useActualsVsForecastQuery({
    farmId: varietyIsValid(farm, variety) ? farm?.id ?? '' : '',
    currentUserEmail: user?.email ?? '',
    token: getToken(),
    varietyId: varietyIsValid(farm, variety) ? variety?.id ?? '' : '',
    startDate: getStartDate(),
    endDate: getEndDate(),
    granularity: durationToGranularity(duration),
    tenantHeader: farm?.organization?.internal_name ?? '',
    enabled: varietyIsValid(farm, variety)
    //forceRefresh: false
  });

  useEffect(() => {
    setLoadingGraphData(actualsVsForecastQuery.isLoading);
    if (!actualsVsForecastQuery.isLoading) {
      setActualsVsForecastQueryResult(actualsVsForecastQuery);
      setHasNextYear(actualsVsForecastQuery.hasNext);
      setHasPrevYear(actualsVsForecastQuery.hasPrevious);
    } else {
      setHasNextYear(false);
      setHasPrevYear(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actualsVsForecastQuery.isLoading,
    actualsVsForecastQuery.barDatum,
    actualsVsForecastQuery.yMax,
    actualsVsForecastQuery.demandData,
    actualsVsForecastQuery.hasNext,
    actualsVsForecastQuery.hasPrevious
  ]);

  const fetchGraphHarvests = async (queryParams: {
    start_date: any;
    end_date: any;
    granularity: string;
  }) => {
    if (
      variety != null &&
      farm != null &&
      queryParams.start_date !== 'Invalid date' &&
      queryParams.end_date !== 'Invalid date'
    ) {
      setLoadingGradesAndRejects(true);
      setLoadingPrevHarvestByField(true);
      const token = getToken();
      const data = await graphRepository.getGraphHarvests(
        token,
        farm?.organization?.internal_name,
        variety?.id,
        queryParams
      );
      const graphData = data?.data;
      if (graphData) {
        const organizedGradesAndRejects: GradesAndRejectsType =
          graphRepository.formatGradesAndRejects(graphData, totalsDuration);
        setGradesAndRejects(organizedGradesAndRejects);
        const organizedHarvestByFields: OrganizedYields = graphRepository.formatHarvestByFields(
          graphData,
          totalsDuration,
          prevActiveFields ?? []
        );
        const summary = prevHarvestYieldSummary?.map((el: any) => {
          if (el.id === 'active_fields') el.value = prevActiveFields?.length ?? 0;
          if (el.id === 'no_of_harvest_days') el.value = data?.data?.number_of_harvest_days ?? 0;
          if (el.id === 'no_of_harvests') el.value = organizedHarvestByFields.fields?.length ?? 0;
          return el;
        });
        setPrevHarvestYieldSummary(summary);
        setPrevHarvestYields(organizedHarvestByFields);
      } else {
        setGradesAndRejects(null);
        setPrevHarvestYields(null);
      }
      setLoadingGradesAndRejects(false);
      setLoadingPrevHarvestByField(false);
    }
  };

  const fetchPrevActiveFields = async () => {
    if (variety != null && farm != null && !loadingPrevActiveFields) {
      setLoadingPrevActiveFields(true);
      const token = getToken();
      const currYear =
        reportFilterState?.years?.[reportFilterState?.years?.length - 1] ?? moment().year();
      const data = await graphRepository.getActiveFields(token, variety.id, farm, `${currYear}`);
      if (data) {
        const fields: ActiveFieldsType[] = data.data ? [...data.data] : [];
        const finalFields: string[] = [];
        const finalActive: ActiveFieldsType[] = fields?.map((el: ActiveFieldsType) => {
          el['selected'] = true;
          finalFields.push(el.id);
          return el;
        });
        setSelectedPrevFields(finalFields);
        setPrevActiveFields(finalActive);
        setProductivityFields(finalActive);
        setLoadingPrevActiveFields(false);
      } else {
        setSelectedPrevFields([]);
        setPrevActiveFields(null);
        setProductivityFields(null);
      }
    }
  };

  const fetchForecastedActiveFields = async () => {
    if (variety != null && farm != null && !loadingForecastedActiveFields) {
      setLoadingForecastedActiveFields(true);
      const token = getToken();
      const currYear = moment().year();
      const data = await graphRepository.getActiveFields(token, variety.id, farm, `${currYear}`);
      if (data) {
        const fields: ActiveFieldsType[] = data.data ? [...data.data] : [];
        const finalFields: string[] = [];
        const finalActive: ActiveFieldsType[] = fields?.map((el: ActiveFieldsType) => {
          el['selected'] = true;
          finalFields.push(el.id);
          return el;
        });
        setSelectedForecastedFields(finalFields);
        setForecastedActiveFields(finalActive);
        setLoadingForecastedActiveFields(false);
      } else {
        setSelectedForecastedFields([]);
        setForecastedActiveFields(null);
      }
    }
  };

  const fetchForecastedHarvestByField = async () => {
    if (variety != null && farm != null) {
      setLoadingForecastedHarvestByField(true);
      const token = getToken();
      const startDay = moment()
        .add(1, 'weeks')
        .startOf('week')
        .add(1, 'day')
        .format('YYYY-MM-DD HH:mm:ss');
      const endDay = moment()
        .add(4, 'weeks')
        .endOf('week')
        .add(1, 'day')
        .format('YYYY-MM-DD HH:mm:ss');
      const queryParams: any = {
        start_date: startDay,
        end_date: endDay,
        granularity: duration === 'daily' ? 'days' : 'weeks',
        all_farms: farm?.id == 'all'
      };
      const latest = await graphRepository.getGraphForecasts(
        token,
        farm?.organization?.internal_name,
        variety.id,
        queryParams,
        'latest'
      );
      // NB: Uncomment this code to re-enable day/weeks out milestone functionality
      // const milestones = await graphRepository.getGraphForecasts(
      //   token,
      //   farm?.organization?.internal_name,
      //   variety.id,
      //   queryParams,
      //   'milestone'
      // );
      if (latest && latest?.status == 200) {
        // if (milestones && milestones?.status == 200 && latest && latest?.status == 200) {
        const finalYields = await graphRepository.formatForecastsByField(
          latest?.data,
          // milestones?.data,
          duration,
          forecastedActiveFields ?? []
        );
        setForecastedHarvestYieldsByMilestones(finalYields);
      } else {
        setForecastedHarvestYieldsByMilestones(null);
      }
    }
  };
  useEffect(() => {
    if (forecastedHarvestYieldsByMilestones && forecastedYieldsFilters?.forecast_duration) {
      setForecastedHarvestYields(
        forecastedHarvestYieldsByMilestones[forecastedYieldsFilters?.forecast_duration] ?? null
      );
      setLoadingForecastedHarvestByField(false);
    }
  }, [forecastedHarvestYieldsByMilestones, forecastedYieldsFilters?.forecast_duration]);

  const [minimumExpectedData, setMinimumExpectedData] = useState<MininumExpectedItem[]>();

  const computeMinimumExpectedHarvest = (_graphData: any, _currentWeeks: number[]) => {
    // Reformat minimum expected data to match MinimumExpectedItem type structure
    const minimiumExpectedItems: MininumExpectedItem[] = [];
    for (let index = 0; index < _graphData?.dates.length; index++) {
      minimiumExpectedItems.push({
        period:
          'CW ' +
          moment(_graphData.dates[index].substr(0, 10), 'YYYY-MM-DD').isoWeek() +
          // adding prefix for the next year if week falls in the next year
          (_currentWeeks[index] < _currentWeeks[0] - 1
            ? ' (' + moment().add(1, 'year').year() + ')'
            : ''),
        min_expected_yield: _graphData.minimum_expected_yields.forecasts[index],
        forecast: _graphData.latest_forecasts.forecasts[index],
        confidence: Object.values(_graphData.confidence_levels)[index],
        grading: _graphData.minimum_expected_yields.detailed_view[index]
      } as MininumExpectedItem);
    }
    return minimiumExpectedItems;
  };

  const fetchMinimumExpectedHarvest = async () => {
    if (variety != null && farm != null && !loadingMinimumExpected) {
      setLoadingMinimumExpected(true);
      const token = getToken();
      const data = await graphRepository.getMinimumExpectedConfidence(
        token,
        farm.organization.internal_name,
        variety.id,
        farm?.id == 'all'
      );
      if (data?.data) {
        const currentWeeks = [
          moment().add(1, 'weeks').week(),
          moment().add(2, 'weeks').week(),
          moment().add(3, 'weeks').week(),
          moment().add(4, 'weeks').week()
        ];
        const graphData = data?.data;
        let min_expected;
        if (window.Worker && !window.Cypress) {
          min_expected = await computeMinimumExpectedHarvestWork({ graphData, currentWeeks }).catch(
            () => {
              // fall back to the main thread
              return computeMinimumExpectedHarvest(graphData, currentWeeks);
            }
          );
        } else {
          min_expected = computeMinimumExpectedHarvest(graphData, currentWeeks);
        }
        setLoadingMinimumExpected(false);
        setMinimumExpectedData(min_expected);
      } else setMinimumExpectedData(undefined);
    }
  };

  const fetchFieldProductivity = async (queryParams: { start_date: any; end_date: any }) => {
    if (
      variety != null &&
      farm != null &&
      queryParams.start_date !== 'Invalid date' &&
      queryParams.end_date !== 'Invalid date'
    ) {
      setLoadingFieldProductivity(true);
      const token = getToken();

      const data = await graphRepository.getFieldProductivity(
        token,
        farm?.organization?.internal_name,
        variety?.id,
        queryParams
      );
      if (data?.data) {
        const finalData = await graphRepository.sortAndOrganizeProductivity(data?.data);
        setUnfilteredFieldProductivities(finalData);
      } else setUnfilteredFieldProductivities(null);
    }
  };

  useEffect(() => {
    if (unfilteredFieldProductivities) {
      setLoadingFieldProductivity(true);
      if (fieldProductivityFilters?.stems === 'stems')
        setFieldProductivity(unfilteredFieldProductivities?.data);
      else setFieldProductivity(unfilteredFieldProductivities?.data_pms);
    } else setFieldProductivity(null);
    setLoadingFieldProductivity(false);
  }, [unfilteredFieldProductivities, fieldProductivityFilters?.stems]);

  const getRandomHexColor = () => {
    // Define an array of hexadecimal digits
    const hexChars = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F'
    ];
    // Generate an array of six random indices from 0 to 15
    const hexIndices = Array.from({ length: 6 }, () => Math.floor(Math.random() * 16));
    // Map each index to its corresponding hex digit and join them into a string
    const hexCode = hexIndices.map((i) => hexChars[i]).join('');
    // Return the string with a "#" prefix
    return `#${hexCode}`;
  };

  useEffect(() => {
    const variety_is_valid = farm?.varieties?.some((farm_variety) => {
      return farm_variety?.id === variety?.id;
    });
    if (location.pathname == '/dashboard' && variety_is_valid && varietyIsCyclic()) {
      if (!fieldProductivityFilters?.fields?.length) setFieldProductivity(null);
      else {
        const queryParams: any = {
          start_date: moment(
            `${reportFilterState.days?.[0]} ${reportFilterState.years?.[0]}`
          ).format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(
            `${reportFilterState.days?.[reportFilterState.days?.length - 1]} ${
              reportFilterState.years?.[0]
            }`
          ).format('YYYY-MM-DD HH:mm:ss')
        };
        fetchFieldProductivity(queryParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, reportFilterState, fieldProductivityFilters?.fields]);

  useEffect(() => {
    const lengths: number[] = [prevActiveFields?.length ?? 0, forecastedActiveFields?.length ?? 0];
    const itemsLength = Math.max(...lengths) ?? 0;
    setMaxActiveFieldsLength(itemsLength);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevActiveFields?.length, forecastedActiveFields?.length]);

  useEffect(() => {
    const colors: string[] = [];
    if (maxActiveFieldsLength) {
      while (colors.length < maxActiveFieldsLength + 1) {
        const color = getRandomHexColor();
        if (!colors.includes(color)) colors.push(color);
      }
      setHarvestYieldsColors(colors);
    } else setHarvestYieldsColors([]);
  }, [maxActiveFieldsLength]);

  const getFormattedHarvestByFieldTags = async (
    _activeFields: ActiveFieldsType[],
    _harvestYieldsColors: string[]
  ) => {
    let tags: any[];
    if (window.Worker && !window.Cypress) {
      tags = await formatHarvestByFieldTagsWork({
        activeFields: _activeFields,
        harvestYieldsColors: _harvestYieldsColors
      }).catch(() => {
        // fall back to the main thread
        return formatHarvestByFieldTags(_activeFields, _harvestYieldsColors);
      });
    } else {
      tags = formatHarvestByFieldTags(_activeFields, _harvestYieldsColors);
    }
    return tags ?? [];
  };

  useEffect(() => {
    if (
      prevActiveFields &&
      harvestYieldsColors?.length &&
      harvestYieldsColors?.length === maxActiveFieldsLength + 1 //greater by one because we generate one extra color
    ) {
      (async () => {
        const tags = await getFormattedHarvestByFieldTags(prevActiveFields, harvestYieldsColors);
        setPrevHarvestTags(tags);
      })();
    } else {
      setPrevHarvestTags([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [harvestYieldsColors, prevActiveFields]);

  useEffect(() => {
    if (
      forecastedActiveFields &&
      harvestYieldsColors?.length &&
      harvestYieldsColors?.length === maxActiveFieldsLength + 1 //greater by one because we generate one extra color
    ) {
      (async () => {
        const tags = await getFormattedHarvestByFieldTags(
          forecastedActiveFields,
          harvestYieldsColors
        );
        setForecastedHarvestTags(tags);
      })();
    } else {
      setForecastedHarvestTags([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [harvestYieldsColors, forecastedActiveFields]);

  useEffect(() => {
    if (productivityFields) {
      setFieldProductivityFilters({
        ...fieldProductivityFilters,
        fields: productivityFields
          ?.filter((el: any) => el.selected)
          ?.map((el: ActiveFieldsType) => el.name)
      });
    } else {
      setFieldProductivityFilters({
        ...fieldProductivityFilters,
        fields: []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productivityFields]);

  useEffect(() => {
    if (reportFilterState?.years?.[0]) {
      const year = reportFilterState?.years?.[0];
      setHistoricalsYear(`${year}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportFilterState]);

  useEffect(() => {
    const variety_is_valid = farm?.varieties?.some((farm_variety) => {
      return farm_variety?.id === variety?.id;
    });
    if (location.pathname == '/dashboard' && variety_is_valid) {
      fetchPrevActiveFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variety?.id, farm?.id, location.pathname, reportFilterState?.years]);

  useEffect(() => {
    const variety_is_valid = farm?.varieties?.some((farm_variety) => {
      return farm_variety?.id === variety?.id;
    });
    if (location.pathname == '/dashboard' && variety_is_valid) {
      fetchForecastedActiveFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variety?.id, farm?.id, location.pathname]);

  useEffect(() => {
    const variety_is_valid = farm?.varieties?.some((farm_variety) => {
      return farm_variety?.id === variety?.id;
    });
    if (location.pathname == '/dashboard' && variety_is_valid) {
      const queryParams: any = {
        start_date: moment(`${reportFilterState.days?.[0]} ${reportFilterState.years?.[0]}`).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        end_date: moment(
          `${reportFilterState.days?.[reportFilterState.days?.length - 1]} ${
            reportFilterState.years?.[0]
          }`
        ).format('YYYY-MM-DD HH:mm:ss'),
        granularity: totalsDuration === 'daily' ? 'days' : 'weeks',
        all_farms: farm?.id == 'all'
      };
      fetchGraphHarvests(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalsDuration, reportFilterState, prevActiveFields]);

  useEffect(() => {
    const variety_is_valid = farm?.varieties?.some((farm_variety) => {
      return farm_variety?.id === variety?.id;
    });
    if (location.pathname == '/dashboard' && variety_is_valid) {
      fetchForecastedHarvestByField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, forecastedActiveFields]);

  const getYields = useCallback((): Yields | null => {
    if (graphData) {
      const years = Object.keys(graphData);
      if (years.includes(selectedYear)) {
        const yields = graphData[selectedYear];
        return yields;
      }
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData]);

  useEffect(() => {
    setActiveYields(getYields());
  }, [graphData, getYields]);

  useEffect(() => {
    if (farm && variety) {
      const variety_is_valid =
        farm?.varieties?.some((farm_variety) => {
          return farm_variety?.id === variety?.id;
        }) ?? false;
      if (location.pathname === '/dashboard' && variety_is_valid) {
        if (limaActiveModules.forecast_trend) {
          fetchLineData(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, variety?.id, farm?.id, duration, location.pathname]);

  const fetchLineData = async (forceRefresh?: boolean) => {
    if (forceRefresh && !loadingLineData) {
      // TODO: re-enable this
      // if (variety != null && farm != null) {
      //   setLoadingLineData(true);
      //   const token = getToken();
      //   const lineData = await graphRepository.getLineData(
      //     token,
      //     variety.id,
      //     farm.id,
      //     farm.organization,
      //     fetchDate,
      //     duration
      //   );
      //   setLoadingLineData(false);
      //   if (isEmpty(lineData?.data?.predictions_graph_data)) {
      //     // set error message accordingly with the year before or after the selected year
      //     setLineError(
      //       isBeforeCurrentYear(selectedYear)
      //         ? `No line data available for the year ${selectedYear} and before.`
      //         : `No line data available for the year ${selectedYear} and after.`
      //     );
      //     return; // if empty, do not set graph data
      //   }
      //   if (lineData?.name == 'AxiosError') {
      //     setLineData(null);
      //     setLineError('Error fetching line data');
      //   } else if (lineData?.data?.predictions_graph_data) {
      //     setLineError(null);
      //     setLineData(lineData?.data?.predictions_graph_data);
      //   }
      // }
    }
  };

  // extract year from fetchDate
  useEffect(() => {
    const year = moment(fetchDate).year();
    setSelectedYear(year.toString());
  }, [fetchDate]);

  // subtract 1 year from fetchDate using moment
  const timeTravel = (direction: Direction) => {
    const hasNext = hasNextYear; // || lineHasNextYear;
    const hasPrev = hasPrevYear; // || lineHasPrevYear;

    if (direction === Direction.YearBackward && hasPrev) {
      const newDate = moment(fetchDate).subtract(1, 'year').format('YYYY-MM-DD');
      setFetchDate(newDate);
    } else if (direction === Direction.YearForward && hasNext) {
      const newDate = moment(fetchDate).add(1, 'year').format('YYYY-MM-DD');
      setFetchDate(newDate);
    }
  };

  const getPreds = useCallback((): Yields | null => {
    if (lineData) {
      const years = Object.keys(lineData);
      if (years.includes(selectedYear)) {
        const preds = lineData[selectedYear];
        return preds;
      }
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineData]);

  useEffect(() => {
    setActivePreds(getPreds());
  }, [lineData, getPreds]);

  return (
    <MyGraphContext.Provider
      value={{
        loadingGraphData,
        loadingDemandData,
        activeYields,
        fetchLineData,
        loadingLineData,
        activePreds,
        timeTravel,
        selectedYear,
        duration,
        totalsDuration,
        setDuration,
        setTotalsDuration,
        value,
        error,
        lineError,
        graphData,
        lineData,
        demandData,
        sortedLines,
        setSortedLines,
        filterTags,
        setFilterTags,
        fetchGraphHarvests,
        minimumExpectedData,
        setMinimumExpectedData,
        fetchMinimumExpectedHarvest,
        gradesAndRejects,
        reportFilterState,
        setReportFilterState,
        loadingGradesAndRejects,
        gradesAndRejectsTags,
        setGradesAndRejectsTags,
        setLoadingGradesAndRejects,
        loadingPrevHarvestByField,
        setLoadingPrevHarvestByField,
        loadingMinimumExpected,
        setLoadingMinimumExpected,
        loadingPrevActiveFields,
        prevActiveFields,
        setPrevActiveFields,
        historicalsYear,
        setSelectedPrevFields,
        selectedPrevFields,
        prevHarvestYields,
        prevHarvestYieldSummary,
        prevHarvestTags,
        loadingForecastedHarvestByField,
        forecastedActiveFields,
        setForecastedActiveFields,
        setSelectedForecastedFields,
        setForecastedYieldsFilters,
        selectedForecastedFields,
        forecastedHarvestYields,
        forecastedHarvestTags,
        selectedForecast,
        setSelectedForecast,
        loadingForecastedActiveFields,
        currentForecastTab,
        setCurrentForecastTab,
        currentHistoricalsTab,
        setCurrentHistoricalsTab,
        fieldProductivityFilters,
        setFieldProductivityFilters,
        currentProductivityTab,
        setCurrentProductivityTab,
        setLoadingFieldProductivity,
        fieldProductivityTags,
        setFieldProductivityTags,
        fieldProductivity,
        setFieldProductivity,
        loadingFieldProductivity,
        graphColors,
        selectedFieldProductivity,
        setSelectedFieldProductivity,
        productivityFields,
        setProductivityFields,
        quickActionFilters,
        setQuickActionFilters,
        originalFieldProductivity,
        primaryProductivityTags,
        xAxisWidth,
        setXAxisWidth,
        actualVsForecastQuery: actualsVsForecastQueryResult
      }}
    >
      {children}
    </MyGraphContext.Provider>
  );
}
