import { round } from 'lodash';
import * as yup from 'yup';

const schema = yup.object().shape({
  stem_density_farm_pred: yup
    .number()
    .required('Stem density is required and only numbers are allowed.')
    .test('only_numbers', 'Only numbers are allowed', (value) => /\d/g.test(String(value)))
    .min(0, 'Must be greater than or equal to 0')
    .max(1000, 'Must be less than or equal to 1000'),
  area: yup
    .number()
    .required('Area is required.')
    .test('only_numbers', 'Only numbers are allowed', (value) => /\d/g.test(String(value))),
  dieback: yup.number().required('Dieback is required.').min(0).max(100),
  loss: yup
    .number()
    .nullable()
    .min(0)
    .max(100)
    .test('only_numbers', 'Only numbers are allowed', (value) => /\d/g.test(String(value))),
  grading: yup
    .array()
    .of(yup.object())
    .required()
    .test('sum', 'The grade split total should not exceed 100%', (rows = []) => {
      const total = rows.reduce((total, row) => {
        let grade_amount = 0;
        Object.keys(row).forEach((grade_item) => {
          if (row[grade_item] !== undefined && Number(row[grade_item])) {
            grade_amount += row[grade_item] as number;
          }
        });
        return total + grade_amount;
      }, 0);
      return total <= 100;
    }),
  first_date_harvest_pred: yup.date(),
  first_date_harvest_farm_pred: yup.date().default(null),
  last_date_harvest_farm_pred: yup
    .date()
    .default(null)
    .when(
      'first_date_harvest_farm_pred',
      (first_date_harvest_farm_pred, yup) =>
        first_date_harvest_farm_pred &&
        yup.min(first_date_harvest_farm_pred, 'End Date cannot be before Start Date')
    ),
  harvest_schedule: yup
    .array()
    //.of(yup.object())
    .required()
    .test('sum', 'The harvest schedule total must match the total stems', (rows = [], ctx) => {
      const total = rows.reduce((total, row) => {
        return total + (row.stems || 0);
      }, 0);
      // if yield_pred is not a number, this will return false
      if (!isNumber(ctx.parent.yield_pred)) {
        return false;
      }
      return round(total) <= round(ctx.parent.yield_pred);
    }),
  observation: yup.string().nullable()
  // harvest_frequency: yup.string().nullable()
});

// check if object is number
function isNumber(obj: any) {
  if (obj === undefined) {
    return false;
  }
  return !isNaN(parseFloat(obj));
}

export default schema;
