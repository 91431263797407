import * as yup from 'yup';

const schema = yup.object().shape({
  actual_inputs: yup.array().required()
  // .of(
  //   yup.object().shape({
  //     day: yup.date().required('Day is required'),
  //     grading: yup.array(),
  //     block: yup.string().required('Field is required'),
  //     stems: yup.number().required('Rejected is required'),
  //     rejected: yup.number().required('Rejected is required')
  //   })
  // )
  // .test('sum', 'The harvest schedule total must match the total stems', (rows = [], ctx) => {
  //   const total = rows.reduce((total, row) => {
  //     return total + (row.stems || 0);
  //   }, 0);

  //   return total <= ctx.parent.yield_pred;
  // })
});

export default schema;
