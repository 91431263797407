import { Box } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import guestBg from 'assets/images/guest-bg.webp';

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: minmax(521px, 4fr) 6fr;
  height: 100vh;
  max-height: 100vh;

  max-width: 2560px;
  margin: 0 auto;
  width: 100%;

  .right {
    @media screen and (min-width: 2100px) {
      background-position: center center;
    }
  }
`;

export default function Guest({ children }: { children: ReactNode }) {
  return (
    <Wrapper className="d-grid app-layout">
      <div className="left">{children}</div>
      <Box
        className="right"
        backgroundImage={guestBg}
        backgroundAttachment="fixed"
        backgroundPosition="center right"
        backgroundRepeat="no-repeat"
      ></Box>
    </Wrapper>
  );
}
