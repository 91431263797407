import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './react-datepicker.scss';

export default function DateInput({
  id,
  date,
  onChange,
  onBlur,
  showTime,
  hasError,
  disabled
}: {
  id: string;
  date?: Date | string;
  onChange: (date: string) => void;
  onBlur: () => void;
  showTime?: boolean;
  hasError: false;
  disabled: true;
}): JSX.Element {
  const [displayDate, setDisplayDate] = useState<Date | null>(null);

  const onDateChanged = (date: Date) => {
    if (date != null) {
      const newDate = moment(date);
      if (newDate.isValid()) {
        setDisplayDate(date);
        onChange(newDate.format('YYYY-MM-DD'));

        //on blur was introduced to wait for typing finish in harvest schedule then trigger another change handler
        try {
          onBlur();
        } catch (e: any) {
          // all other date pickers dont need this so the catch clause prevents it from crashing
        }
      }
    } else {
      setDisplayDate(null);
    }
  };

  useEffect(() => {
    if (date) {
      const dateFmt: Date = typeof date === 'string' ? new Date(date) : date;
      setDisplayDate(dateFmt);
    } else {
      setDisplayDate(null);
    }
  }, [date]);

  useEffect(() => {
    // pass date selection on initial load
    if (date) {
      onChange && onChange(moment(date).format('YYYY-MM-DD'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DatePicker
      data-cy={id}
      id={id}
      selected={displayDate}
      showTimeSelect={!!showTime}
      onChange={onDateChanged}
      dateFormat="dd-MMMM-yyyy"
      // placeholderText="dd-MMMM-yyyy"
      className={hasError ? 'is-invalid' : ''}
      disabled={disabled}
      calendarStartDay={1}
      showWeekNumbers={true}
    />
  );
}
