import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ProductionCategory } from '../Context/types';
import { httpClient } from './appDependenciesHelpers';

export type ProductionCategoryQueryInput = {
  currentUserEmail: string | null | undefined;
  token: string;
  tenantHeader: string;
  varietyId: string | null;
  forceInvalidateQuery?: boolean;
};

const useProductionCategoryQuery = (
  input: ProductionCategoryQueryInput
): UseQueryResult<ProductionCategory[], Error> => {
  const { currentUserEmail, forceInvalidateQuery, token, varietyId, tenantHeader } = input;
  const productionCategoriesQueryKeys =
    (currentUserEmail === null || currentUserEmail === undefined) && varietyId === null
      ? ['production_category']
      : ['production_category', currentUserEmail, varietyId, tenantHeader];

  async function getProductionCategories(): Promise<ProductionCategory[]> {
    let prodCategories: ProductionCategory[] = [];
    if (token) {
      const BASE_URL = `${process.env.REACT_APP_BE_URL}`;
      const res = await httpClient
        .getAxios()
        .get(`${BASE_URL}/varieties/${varietyId}/production_categories`, {
          headers: {
            Authorization: token,
            'Tenant-Header': tenantHeader
          }
        })
        .catch(() => {});
      if (res?.data && res.data.length) {
        prodCategories = res.data;
      }
    }
    return prodCategories;
  }

  const productionCategoryQuery = useQuery({
    queryKey: productionCategoriesQueryKeys,
    queryFn: getProductionCategories,
    enabled:
      token !== '' &&
      (currentUserEmail !== null || currentUserEmail !== undefined) &&
      varietyId !== null &&
      tenantHeader !== ''
  });

  if (forceInvalidateQuery) {
    productionCategoryQuery.refetch();
  }

  return productionCategoryQuery;
};

export default useProductionCategoryQuery;
