import { Box, Flex, Heading } from '@chakra-ui/react';
import moment from 'moment';
import React, { SyntheticEvent, useEffect, useMemo } from 'react';
import { DateRange, RangeType, ValueType } from 'rsuite/esm/DateRangePicker';
import LimaDateRangePicker from 'Components/DateRangePicker/LimaDateRangePicker';
import {
  createPreDefinedDateRanges,
  CustomPredefinedDateRange
} from 'Helpers/dateRangePickerHelpers';

export type ReportFilterState = {
  weeks: number[] | null;
  days: string[] | null;
  years: number[] | null;
};

export type ReportSectionCardProps = {
  currentlySelectedDuration: 'weekly' | 'daily' | 'monthly';
  updateFilterState: (filterState: ReportFilterState) => void;
};

const endOfCurrentMonthPlusTwoMonths = moment()
  .add(2, 'months')
  .endOf('month')
  .format('YYYY-MM-DD');
const maxDate = endOfCurrentMonthPlusTwoMonths;
const initialFilterState = {
  weeks: null,
  days: null,
  years: null
};

const lastQuarterRange: RangeType[] | undefined = createPreDefinedDateRanges(['Last Quarter']);
const lastQuarter: DateRange | ((value?: ValueType) => DateRange) | null = lastQuarterRange
  ? lastQuarterRange[0].value
  : null;

const ReportSectionCard = (props: ReportSectionCardProps) => {
  const { updateFilterState } = props;
  const [endYearLimit] = React.useState<number>(moment().year());
  const [filterState, setFilterState] = React.useState<ReportFilterState>(initialFilterState);
  const [value, setValue] = React.useState<DateRange | null>(lastQuarter);
  const predefinedDateRangesMemo = useMemo(() => {
    const customPredefinedRanges: CustomPredefinedDateRange[] = [
      'Last Week',
      'Last 2 Weeks',
      'Last Month',
      'Current Month',
      'Last Quarter'
    ];
    return createPreDefinedDateRanges(customPredefinedRanges);
  }, []);

  useEffect(() => {
    // set initial filter state
    handleOnOk(lastQuarter as DateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateFilterState(filterState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.weeks, filterState.days, filterState.years]);

  const handleOnOk = (values: DateRange) => {
    const startDate = values[0];
    const endDate = values[1];
    const years = [];
    // check if startDate and endDate are in the same year
    const sameYear = moment(startDate).year() === moment(endDate).year();
    if (sameYear) {
      years.push(moment(startDate).year());
    } else {
      const yearLeft = moment(startDate).year();
      const yearRight = moment(endDate).year();
      for (let i = yearLeft; i <= yearRight; i++) {
        years.push(i);
      }
    }

    // days between and including startDate and endDate in format 'MMM DD'
    const days = [];
    const leftDay = moment(startDate);
    const rightDay = moment(endDate);
    const diff = rightDay.diff(leftDay, 'days');
    for (let i = 0; i <= diff; i++) {
      days.push(moment(leftDay).add(i, 'days').format('MMM DD'));
    }

    const isoWeekNumbers = [];
    const _startDate = moment(startDate).startOf('isoWeek');
    // if _startDate is not the same as startDate, then it means startDate is not the first day of the week (Monday)
    if (_startDate.format('YYYY-MM-DD') !== moment(startDate).format('YYYY-MM-DD')) {
      _startDate.add(1, 'week');
    }
    const leftWeek = moment(_startDate).isoWeek();
    const rightWeek = moment(endDate).isoWeek();
    for (let i = leftWeek; i <= rightWeek; i++) {
      isoWeekNumbers.push(i);
    }

    setFilterState({
      weeks: isoWeekNumbers,
      days: days,
      years
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnChange = (values: DateRange | null, event: SyntheticEvent<Element, Event>) => {
    setValue(values);
    if (!values) {
      setFilterState({
        weeks: null,
        days: null,
        years: null
      });
    } else {
      handleOnOk(values);
    }
  };
  return (
    <Box
      width="100%"
      backgroundColor="white"
      boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
      py={4}
      px={6}
      borderRadius={8}
      data-cy="report-section-box"
    >
      <Flex justifyContent="space-between">
        <Box>
          <Heading as="h4" mb="2" fontSize="1.35rem" fontWeight="normal" color="dark.coolGray.600">
            Report
          </Heading>
        </Box>
        <Box>
          <LimaDateRangePicker
            onChange={handleOnChange}
            initialValue={value}
            allowedDateRange={['2022-01-01', maxDate]}
            startYearLimit={2022}
            endYearLimit={endYearLimit}
            predefinedRanges={predefinedDateRangesMemo}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default ReportSectionCard;
