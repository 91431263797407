import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  FormControl,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  Img,
  Spinner,
  Text,
  Link
} from '@chakra-ui/react';
import { Field, Formik, FormikProps } from 'formik';
import { Mail } from 'react-feather';
import { InputWithIcon, Button, FormLabel } from 'Components';
import { colors } from 'theme';

import { useAuthContext } from 'Context';
import Logo from 'assets/images/lima-icon.svg';
import { ForgotPasswordSchema } from 'Validations';
import { ForgotPasswordFields } from 'Context/types';
import { NotificationToast } from 'Components/Cards';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import useDocumentTitle from 'Helpers/useDocumentTitle';

export default function ForgotPassword(): JSX.Element {
  const { trackPageView } = useMatomo();
  useDocumentTitle('Forgot Password | Lima Asili');

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues: ForgotPasswordFields = {
    email: ''
  };

  const {
    loadingStates,
    variables: { hasError, responseInfo, forgotPasswordSuccess },
    functions: { forgotPassword, setHasError }
  } = useAuthContext();
  const { isLoading } = loadingStates;

  const handleLogin = (values: ForgotPasswordFields) => {
    forgotPassword(values);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (hasError) {
      timeout = setTimeout(() => {
        setHasError(false);
      }, 5000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hasError, setHasError]);

  return (
    <Flex
      className="login"
      direction="column"
      height="100%"
      justifyContent="center"
      alignItems="center"
      mx="auto"
    >
      <Box width="400px">
        <Flex
          className="login__logo"
          width="80px"
          height="80px"
          justifyContent="center"
          alignItems="center"
          mb={6}
        >
          <Img src={Logo} />
        </Flex>
        <Heading as="h1" fontSize="4xl" fontWeight={400} mb={16}>
          Welcome back
        </Heading>
        <Heading as="h1" fontSize="3xl" color="primary.500" fontWeight={400} mt={14} mb="10">
          Forgot Password
        </Heading>
        {hasError && <NotificationToast type="error" message={responseInfo} />}
        {forgotPasswordSuccess ? (
          <Box mb={4} h="50vh" color="info.500" fontSize="0.9rem" fontWeight="normal">
            <Text fontSize={20}>Please check your e-mail.</Text>
            <Text mt={3} color="gray.500" fontSize={18} data-cy="confirmation_email">
              We just sent you an{' '}
              <Text as="b" fontSize={18}>
                e-mail
              </Text>{' '}
              with a link to reset your password.
            </Text>
          </Box>
        ) : (
          <>
            <Formik
              initialValues={initialValues}
              onSubmit={handleLogin}
              validationSchema={ForgotPasswordSchema}
              validateOnBlur={true}
            >
              {({
                isValid,
                handleSubmit,
                errors,
                touched,
                dirty
              }: FormikProps<ForgotPasswordFields>) => (
                <form className="login__form form" onSubmit={handleSubmit}>
                  <FormControl mb={6} isInvalid={!!errors.email && touched.email}>
                    <FormLabel htmlFor="email">Email address</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <Mail color={colors.input.placeholder} />
                      </InputLeftElement>
                      <Field
                        as={InputWithIcon}
                        id="email"
                        type="email"
                        name="email"
                        autoComplete="true"
                        placeholder="user@email.com"
                      />
                    </InputGroup>
                    {errors && errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                  </FormControl>
                  <Box>
                    <Button
                      width="100%"
                      theme="primary"
                      disabled={!isValid || !dirty || isLoading}
                      type="submit"
                    >
                      Send Reset Instructions
                      {isLoading && (
                        <>
                          <Box px={1.5}></Box>
                          <Spinner color="white.500" size="sm" />
                        </>
                      )}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
            <Box pt={8}>
              {/* DO NOT DELETE , LINK WILL BE RE-ENABLE AT SOME POINT IN FUTURE */}
              {/* <Text as="p" color="dark.coolGray.400">
                Don’t have an account?{' '}
                <Link as={RouterLink} to="/register" color="primary.500">
                  Sign up
                </Link>
              </Text> */}
              <Text as="p" mt={2} color="dark.coolGray.400">
                Already a member?{' '}
                <Link as={RouterLink} to="/login" color="primary.500">
                  Log In
                </Link>
              </Text>
            </Box>
          </>
        )}
      </Box>
    </Flex>
  );
}
