import { Box, Heading, Text, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ResponsivePie } from '@nivo/pie';
import TotalHarvestVsLossLegend from './TotalHarvestVsLossLegend';
import { useGraphContext } from 'Context/GraphContext';
import moment from 'moment';

const margin = { top: 0, right: 30, bottom: 0, left: 30 };
const styles: any = {
  root: {
    textAlign: 'center',
    position: 'relative',
    width: '100%',
    height: 180,
    zIndex: 2
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: margin.right,
    bottom: 0,
    left: margin.left,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 96,
    color: '#475052',
    textAlign: 'center',
    // This is important to preserve the chart interactivity
    pointerEvents: 'none',
    zIndex: 1
  },
  lossPercent: {
    color: '#52606D',
    fontFamily: 'Be Vietnam Pro',
    fontSize: '27px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%' /* 40.5px */,
    letterSpacing: '-1.35px'
  },
  lossLabel: {
    color: '#52606D',
    fontFamily: 'Be Vietnam Pro',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: ' 150%' /* 27px */,
    letterSpacing: '-0.9px'
  }
};

const theme: any = {
  labels: {
    text: {
      fontSize: '18px',
      fontFamily: 'Be Vietnam Pro',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%' /* 27px */,
      letterSpacing: '-0.9px',
      color: '#464F51'
    }
  }
};

const TotalHarvestVsLoss = () => {
  const [durationText, setDurationText] = useState<string | undefined>(undefined);
  const [data, setData] = useState<any[]>([]);
  const { gradesAndRejects, reportFilterState, loadingGradesAndRejects, totalsDuration } =
    useGraphContext();
  const [totalHarvest, setTotalHarvest] = useState<number>(0);
  const [totalRejected, setTotalRejected] = useState<number>(0);
  const [lossPercentage, setLossPercentage] = useState<number>(0);

  const calculateLossPercentage = (harvest: number, rejected: number) => {
    return ((rejected / harvest) * 100).toFixed(1);
  };

  useEffect(() => {
    if (gradesAndRejects) {
      const { totalHarvest: _totalHarvest, rejected } = gradesAndRejects;
      setTotalHarvest(_totalHarvest);
      setTotalRejected(rejected);
      setLossPercentage(Number(calculateLossPercentage(_totalHarvest, rejected)));
      const _data = [
        {
          id: 'Harvest',
          label: _totalHarvest.toLocaleString(),
          value: _totalHarvest,
          color: 'hsl(152, 71%, 65%)',
          arcLabelsTextColor: 'hsl(191, 7%, 30%)',
          arcLinkLabelsColor: 'hsl(191, 7%, 30%)',
          arcLinkLabelsTextColor: 'hsl(191, 7%, 30%)'
        },
        {
          id: 'Rejected',
          label: rejected.toLocaleString(),
          value: rejected,
          color: 'hsl(0, 77%, 76%)',
          arcLabelsTextColor: 'hsl(191, 7%, 30%)',
          arcLinkLabelsColor: 'hsl(191, 7%, 30%)',
          arcLinkLabelsTextColor: 'hsl(191, 7%, 30%)'
        }
      ];
      setData(_data);
    }
  }, [gradesAndRejects]);

  useEffect(() => {
    const years = reportFilterState?.years ?? [moment().year()];
    const weeks = reportFilterState?.weeks ?? [];
    const days = reportFilterState?.days ?? [];
    const leftWeek = weeks[0] ?? 0;
    const rightWeek = weeks[weeks.length - 1] ?? 0;
    if (totalsDuration === 'weekly') {
      if (leftWeek === 0 || rightWeek === 0) {
        setDurationText(`${years[0]}`);
      } else {
        setDurationText(`${years[0]} Weeks | ${leftWeek} to ${rightWeek}`);
      }
    } else if (totalsDuration === 'daily') {
      if (days.length === 0) {
        setDurationText(`${years[0]}`);
      } else {
        setDurationText(`${years[0]} Days | ${days[0]} to ${days[days.length - 1]}`);
      }
    }
  }, [reportFilterState.days, reportFilterState.weeks, reportFilterState.years, totalsDuration]);

  return (
    <Box
      width="fit-content"
      //width="50%"
      backgroundColor="white"
      boxShadow="0px 1px 4px rgba(12, 12, 13, 0.15)"
      py={4}
      px={6}
      borderRadius={8}
      data-cy="total-harvest-vs-loss-box"
      mt="32px"
    >
      <Box>
        <Heading as="h4" mb="2" fontSize="1.35rem" fontWeight="normal" color="dark.coolGray.600">
          Total Harvest vs Loss
        </Heading>
        <Text as="p" color="dark.coolGray.500">
          {durationText || <Skeleton count={1} />}
        </Text>
      </Box>
      {loadingGradesAndRejects ? (
        <Skeleton height="400px" width="400px" count={1} />
      ) : (
        <>
          {totalHarvest > 0 || totalRejected > 0 ? (
            <Flex mt="20px" flexDir="column">
              <div style={styles.root}>
                <ResponsivePie
                  theme={theme}
                  data={data}
                  colors={['#68E5AA', '#EE7777']}
                  margin={margin}
                  startAngle={-220}
                  endAngle={140}
                  innerRadius={0.7}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]]
                  }}
                  enableArcLabels={false}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#475052"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor="#475052"
                  arcLabelsTextColor="#475052"
                  sortByValue={true}
                  valueFormat={(value) => {
                    // format number to have comma as thousand separator
                    return value.toLocaleString();
                  }}
                  arcLinkLabel={(arc) => {
                    return `${arc.value.toLocaleString()}`;
                  }}
                />
                <div style={styles.overlay}>
                  <span style={styles.lossPercent}>{`${lossPercentage}%`}</span>
                  <span>Loss</span>
                </div>
              </div>
              <Flex justifyContent="center" mt="30px" gap={'0.75rem'} flexWrap="wrap">
                <TotalHarvestVsLossLegend
                  cardBg={'rgba(53, 220, 142, 0.10)'}
                  circleFill={'#68E5AA'}
                  label={'Harvest'}
                  value={totalHarvest}
                />
                <TotalHarvestVsLossLegend
                  cardBg={'rgba(238, 119, 119, 0.10)'}
                  circleFill={'#EE7777'}
                  label={'Rejected'}
                  value={totalRejected}
                />
                <TotalHarvestVsLossLegend
                  cardBg={'rgba(93, 201, 255, 0.10)'}
                  circleFill={'#5DC9FF'}
                  label={'Loss'}
                  value={lossPercentage}
                  isPercent
                />
              </Flex>
            </Flex>
          ) : (
            <Flex mt="20px">No data available for the selected date range.</Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default TotalHarvestVsLoss;
