import {
  Flex,
  Image,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  Icon,
  Spacer,
  useToast,
  useBreakpointValue,
  useBreakpoint,
  useDimensions
} from '@chakra-ui/react';
import Button from 'Components/Button';
import React, { useState, useRef, useEffect } from 'react';
import styles from './RegisterOrganization.module.scss';
import LogoWithSlogan from 'assets/images/LogoWithSlogan.svg';
import { InputWithIcon, Input } from 'Components';
import { Formik, FormikProps, Field, FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { ArrowRight, MapPin, Power } from 'react-feather';
import { colors } from 'theme';
import { RegisterOrganizationSchema } from 'Validations';
import { useNavigate } from 'react-router-dom';
import ProgressCheckMark, {
  OrganizationRegistrationProgress
} from './Components/ProgressCheckMark';
import { useAuthContext } from 'Context';
import LimaToast from 'Components/Toast/LimaToast';
import useWindowDimensions from 'Helpers/UseWindowDimensions';
import { User } from 'Context/types';
import useDocumentTitle from 'Helpers/useDocumentTitle';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { countriesAndProvinces } from 'data/countries';
import { httpClient } from 'Helpers/appDependenciesHelpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type RegisterOrganizationFields = {
  orgName: string;
  cityAndCountry: string;
};

const initialInputValues: RegisterOrganizationFields = {
  orgName: '',
  cityAndCountry: ''
};

type OrgRegInputErrors = {
  orgNameErrActive: boolean;
  locationErrActive: boolean;
};

type AllErrorDimensions = {
  orgErrHeight: number;
  orgErrorMarginTop: number;
  locationErrHeight: number;
  locationErrorMarginTop: number;
};

const BASE_URL = `${process.env.REACT_APP_BE_URL}`;

const RegisterOrganization = () => {
  useDocumentTitle('Register Organization | Lima Asili');
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const {
    functions: { getToken, logout, setUserCookie }
  } = useAuthContext();
  const rootRef = useRef(null);
  const dimensions = useDimensions(rootRef, true);
  const { height } = useWindowDimensions();
  const orgErrContainer = useRef<any | undefined>(undefined);
  const locationErrContainer = useRef<any | undefined>(undefined);
  const [orgInputErrs, setOrgInputErrs] = useState<OrgRegInputErrors>({
    orgNameErrActive: false,
    locationErrActive: false
  });
  const [allErrorDimensions, setAllErrorDimensions] = useState<AllErrorDimensions>({
    orgErrHeight: 0,
    orgErrorMarginTop: 0,
    locationErrHeight: 0,
    locationErrorMarginTop: 0
  });
  const queryClient = useQueryClient();

  // remove spaces at the end and beginning of the string
  const trimStartEndString = (str: string) => {
    return str.replace(/^\s+|\s+$/gm, '');
  };

  // replace space with underscore if there is any
  const replaceSpaceWithUnderscore = (str: string) => {
    const trimmedStr = trimStartEndString(str);
    return trimmedStr.replace(/\s/g, '_');
  };

  const fetchUser = async (): Promise<boolean> => {
    let updatedSuccessfully = false;
    const res = await httpClient
      .getAxios()
      .get(`${BASE_URL}/auth/cognito-profile`, {
        headers: {
          Authorization: getToken()
        }
      })
      .catch(() => {
        updatedSuccessfully = false;
      });
    if (res?.data) {
      const user: User = res.data;
      setUserCookie('user', user);
      updatedSuccessfully = true;
    }
    return updatedSuccessfully;
  };

  const updateUserMutation = useMutation({
    mutationKey: ['updateUser'],
    mutationFn: fetchUser,
    onMutate: () => {
      // mutation is about to happen, reset the error
      updateUserMutation.reset();
    },
    onSuccess: (data: boolean) => {
      setIsLoading(false);
      const userUpdatedSuccessfully = data;
      if (userUpdatedSuccessfully) {
        navigate('/dashboard');
      } else {
        showErrorToast('Something went wrong when updating user');
      }
    },
    onError: () => {
      setIsLoading(false);
    },
    onSettled: () => {
      // error or success doesn't matter, this will always run last
      setIsLoading(false);
      queryClient.invalidateQueries({ queryKey: ['updateUser'] });
    }
  });

  const createOrganization = async (values: RegisterOrganizationFields): Promise<boolean> => {
    let addSuccess = false;
    const url = `${BASE_URL}/organizations`;
    const body = {
      external_name: replaceSpaceWithUnderscore(values.orgName),
      location: trimStartEndString(values.cityAndCountry)
    };
    const res = await httpClient
      .getAxios()
      .post(url, body, {
        headers: {
          Authorization: getToken()
        }
      })
      .catch(() => {
        addSuccess = false;
      });
    addSuccess = res?.status === 201 || res?.status === 200;
    return addSuccess;
  };

  const createOrganizationMutation = useMutation({
    mutationKey: ['createOrganization'],
    mutationFn: createOrganization,
    onMutate: () => {
      // mutation is about to happen, reset the error and loading states
      createOrganizationMutation.reset();
      setIsLoading(true);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data: boolean) => {
      const orgCreatedSuccessfully = data;
      if (orgCreatedSuccessfully) {
        updateUserMutation.mutate();
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError: (error: any) => {
      setIsLoading(false);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSettled: (data: any | undefined, error: any | undefined) => {
      // error or success doesn't matter, this will always run last
      setIsLoading(false);
      queryClient.invalidateQueries({ queryKey: ['createOrganization'] });
    }
  });

  const showErrorToast = (message: string) => {
    toast({
      position: 'top-right',
      render: () => <LimaToast status="error" message={message} />,
      duration: 5000,
      isClosable: true
    });
  };

  const removeSpacesFrontAndBackOfString = (str: string) => {
    return str.replace(/^\s+|\s+$/gm, '');
  };

  const isValidCityAndCountry = (cityAndCountry: string): boolean => {
    const country = cityAndCountry.split(',')[1];
    // check if country exists in countriesAndProvinces case insensitive
    let countryExists = false;
    for (let i = 0; i < countriesAndProvinces.length; i++) {
      if (
        countriesAndProvinces[i].name.toLowerCase() ===
        removeSpacesFrontAndBackOfString(country.toLowerCase())
      ) {
        countryExists = true;
        break;
      }
    }
    if (!countryExists) {
      showErrorToast('Country does not exist');
      return false;
    }
    return true;
  };

  const validateValues = (values: RegisterOrganizationFields) => {
    if (!values.orgName) {
      showErrorToast('Organization name is required');
      return;
    }
    if (!values.cityAndCountry) {
      showErrorToast('City and country is required');
      return;
    }
    // check for one comma in cityAndCountry
    const commaCount = values.cityAndCountry.split(',').length - 1;
    if (commaCount !== 1) {
      showErrorToast(
        'Please enter a valid city or town and country separated by one comma e.g. "Nairobi, Kenya'
      );
      return;
    }
    if (isValidCityAndCountry(values.cityAndCountry)) {
      createOrganizationMutation.mutate(values);
    } else {
      showErrorToast(
        'Please enter a valid city or town and country separated by one comma e.g. "Nairobi, Kenya'
      );
    }
  };

  const handleFormSubmit = (values: RegisterOrganizationFields) => {
    validateValues(values);
  };

  const getwidth = () => {
    if (dimensions) {
      return dimensions.borderBox.width;
    }
    return 0;
  };

  const parentFlex = useBreakpointValue({
    base: 1,
    md: 1,
    lg: 1,
    xl: 2,
    '2xl': 2
  });

  const inputFlexMl = useBreakpointValue({
    base: '1.25rem',
    md: getwidth() <= 882 ? '1.5rem' : '2rem',
    lg: getwidth() <= 1024 ? '2.5rem' : '3.5rem',
    xl: '12rem'
  });

  const inputFlexMr = useBreakpointValue({
    base: '1.25rem',
    md: getwidth() <= 882 ? '1.5rem' : '2rem',
    lg: getwidth() <= 1024 ? '2.5rem' : '3.5rem',
    xl: '12rem'
  });

  const calculate2XlMl = () => {
    if (dimensions) {
      const width = dimensions.borderBox.width;
      if (width <= 1495) {
        return '40px';
      }
      if (width <= 1630) {
        return '70px';
      }
      if (width <= 1740) {
        return '100px';
      }
      return `120px`;
    } else {
      return '120px';
    }
  };

  const calculateXlMl = () => {
    if (dimensions) {
      const width = dimensions.borderBox.width;
      if (width <= 1495) {
        return '50px';
      }
      return `60px`;
    } else {
      return '60px';
    }
  };

  const stepsFlexMl = useBreakpointValue({
    base: '10px',
    md: '30px',
    lg: '30px',
    xl: calculateXlMl(),
    '2xl': calculate2XlMl()
  });

  const breakPoint = useBreakpoint();

  const shouldShow = (breakPoint: string) => {
    switch (breakPoint) {
      case '2xl':
        return true;
      default:
        return false;
    }
  };

  const responsiveContentTextCss = () => {
    if (getwidth() <= 902) {
      return styles.contentSmallText;
    }
    if (getwidth() <= 980) {
      return styles.contentSmallerText;
    }
    return styles.contentNormalText;
  };

  const isEqual = (obj1: OrgRegInputErrors, obj2: OrgRegInputErrors) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const buttonFlexMt = (
    err: FormikErrors<RegisterOrganizationFields>,
    touched: FormikTouched<RegisterOrganizationFields>
  ): string => {
    const orgErr = err && err.orgName && touched.orgName;
    const locationErr = err && err.cityAndCountry && touched.cityAndCountry;
    const locationValOne =
      allErrorDimensions.locationErrHeight * 2 + allErrorDimensions.locationErrorMarginTop - 10;
    if (orgErr && locationErr) {
      if (getwidth() <= 1260 && err.cityAndCountry && err.cityAndCountry.length >= 80) {
        return `${160 - locationValOne}px`;
      }
      return `${160 - 26 * 2}px`;
    }
    if (orgErr || locationErr) {
      if (getwidth() <= 1260 && err.cityAndCountry && err.cityAndCountry.length >= 80) {
        return `${160 - 36 - 10 + 2}px`;
      }
      return `${160 - 26}px`;
    }
    return '160px';
  };

  const convertPxToNumber = (px: string) => {
    try {
      return parseInt(px.replace('px', ''));
    } catch (error) {
      return 0;
    }
  };

  useEffect(() => {
    // get both heights
    const orgErrCurr = orgErrContainer.current;
    const locationErrCurr = locationErrContainer.current;
    const orgErrHeight = orgErrCurr ? orgErrCurr.clientHeight : 0;
    const orgErrorMarginTop = orgErrCurr
      ? convertPxToNumber(window.getComputedStyle(orgErrCurr).marginTop)
      : 0;
    const locationErrHeight = locationErrCurr ? locationErrCurr.clientHeight : 0;
    const locationErrorMarginTop = locationErrCurr
      ? convertPxToNumber(window.getComputedStyle(locationErrCurr).marginTop)
      : 0;
    // log all the above
    const allDimens: AllErrorDimensions = {
      orgErrHeight: orgErrHeight,
      orgErrorMarginTop: orgErrorMarginTop,
      locationErrHeight: locationErrHeight,
      locationErrorMarginTop: locationErrorMarginTop
    };
    setAllErrorDimensions(allDimens);
  }, [orgInputErrs]);

  const FormObserver: React.FC = () => {
    const { errors, touched } = useFormikContext();
    useEffect(() => {
      const err = errors as FormikErrors<RegisterOrganizationFields>;
      const touch = touched as FormikTouched<RegisterOrganizationFields>;
      const orgErr = err && err.orgName && touch.orgName;
      const locationErr = err && err.cityAndCountry && touch.cityAndCountry;
      const orgRegInputErrors: OrgRegInputErrors = {
        orgNameErrActive: orgErr ? true : false,
        locationErrActive: locationErr ? true : false
      };
      // check if objects are not the same
      // if not, update state
      if (!isEqual(orgInputErrs, orgRegInputErrors)) {
        setOrgInputErrs(orgRegInputErrors);
      }
    }, [errors, touched]);
    return null;
  };

  return (
    <Flex ref={rootRef} w="100vw" minH="100vh">
      <Flex p={2} flex={1} bg="white">
        <Flex
          ml={stepsFlexMl}
          w="100%"
          h="100vh"
          flex={shouldShow(breakPoint) ? 3 : 1}
          flexDir="column"
        >
          <Image mt="116px" w="180px" objectFit="contain" alt="Logo" src={LogoWithSlogan} />
          <Text
            data-cy="register-organization-get-started"
            mt="41px"
            as="h5"
            color="primary.500"
            className={styles.heading}
          >
            Get started with Lima Asili
          </Text>
          <Flex flexDir="column" mt="87px">
            <Flex>
              <ProgressCheckMark progress={OrganizationRegistrationProgress.DONE} itemNumber={1} />
              <Flex ml="16px" flexDir="column">
                <Text data-cy="register-organization-step-1" className={styles.contentPrimaryText}>
                  Sign Up
                </Text>
                <Text
                  data-cy="register-organization-step-1-desc"
                  color="dark.coolGray.500"
                  className={responsiveContentTextCss()}
                >
                  Create an account using your e-mail address
                </Text>
              </Flex>
            </Flex>
            <Box h="100px" className={styles.dottedVerticalLine} />
            <Flex>
              <ProgressCheckMark progress={OrganizationRegistrationProgress.DONE} itemNumber={2} />
              <Flex ml="16px" flexDir="column">
                <Text data-cy="register-organization-step-2" className={styles.contentPrimaryText}>
                  Verify Email
                </Text>
                <Text
                  data-cy="register-organization-step-2-desc"
                  color="dark.coolGray.500"
                  className={responsiveContentTextCss()}
                >
                  Verify the e-mail belongs to you by clicking on the link
                </Text>
              </Flex>
            </Flex>
            <Box h="100px" className={styles.dottedVerticalLine} />
            <Flex>
              <ProgressCheckMark
                progress={OrganizationRegistrationProgress.IN_PROGRESS}
                itemNumber={3}
              />
              <Flex ml="16px" flexDir="column">
                <Text
                  data-cy="register-organization-step-3"
                  color="black.500"
                  className={styles.contentPrimaryText}
                >
                  Your Organization
                </Text>
                <Text
                  data-cy="register-organization-step-3-desc"
                  color="dark.coolGray.500"
                  className={responsiveContentTextCss()}
                >
                  What is your organization and where are you located?
                </Text>
              </Flex>
            </Flex>
            <Box h="50px" className={styles.dottedVerticalLine} />
            <Box h="50px" className={styles.dottedVerticalLineGray} />
            <Flex>
              <ProgressCheckMark
                progress={OrganizationRegistrationProgress.TO_BE_DONE}
                itemNumber={4}
              />
              <Flex ml="16px" flexDir="column">
                <Text
                  data-cy="register-organization-step-4"
                  color="dark.coolGray.500"
                  className={styles.contentPrimaryText}
                >
                  Setup Your Farm
                </Text>
                <Text
                  data-cy="register-organization-step-4-desc"
                  color="dark.coolGray.500"
                  className={responsiveContentTextCss()}
                >
                  Add varieties grown, farms, fields and invite members
                </Text>
              </Flex>
            </Flex>
          </Flex>
          {height <= 490 && <Box h="100px" />}
        </Flex>
      </Flex>
      <Flex
        p={2}
        flexDir="column"
        flex={getwidth() <= 1430 ? 1 : parentFlex}
        bg="dark.coolGray.10"
        borderLeft="1px"
        borderLeftColor="dark.coolGray.20"
      >
        <Flex ml={inputFlexMl} mr={inputFlexMr} mt="160px" flexDir="column">
          <Flex justifyContent="space-between">
            <Text
              data-cy="register-organization-title"
              className={styles.yourOrganizationHeading}
              as="h5"
            >
              Your Organization
            </Text>
            <Box>
              <Button
                width="auto"
                type="button"
                theme="cancel"
                data-cy="logout-button"
                onClick={() => {
                  logout();
                }}
                p={1}
                __css={{
                  border: '1px solid #818181',
                  borderColor: 'gray.400',
                  color: 'gray.400'
                }}
              >
                <Icon alignSelf="center" as={Power} mr={2} mb={-0.5} />
                Logout
              </Button>
            </Box>
          </Flex>
          <Text
            data-cy="register-organization-subtitle"
            color="dark.coolGray.500"
            mt="16px"
            className={styles.yourOrganizationDesc}
          >
            Welcome to Lima Asili. Register your organization.
          </Text>
          <Flex minH="556px" flexDir="column">
            <Formik
              initialValues={initialInputValues}
              onSubmit={handleFormSubmit}
              validationSchema={RegisterOrganizationSchema}
              validateOnBlur={true}
            >
              {({
                isValid,
                handleSubmit,
                errors,
                touched,
                dirty
              }: FormikProps<RegisterOrganizationFields>) => (
                <form className="login__form form" onSubmit={handleSubmit}>
                  <FormObserver />
                  <FormControl minW={340} mb={6} isInvalid={!!errors.orgName && touched.orgName}>
                    <FormLabel
                      fontWeight="normal"
                      mt="152px"
                      color="dark.coolGray.500"
                      htmlFor="orgName"
                    >
                      Organization Name
                    </FormLabel>
                    <Field
                      data-cy="register-organization-name-input"
                      bg="white"
                      as={Input}
                      id="orgName"
                      type="text"
                      name="orgName"
                      autoComplete="true"
                      width="400px"
                      placeholder="Enter Organization Name"
                      _placeholder={{ fontWeight: 'normal', color: 'dark.coolGray.300' }}
                    />
                    {errors && errors.orgName && (
                      <FormErrorMessage
                        data-cy="register-organization-name-error"
                        ref={orgErrContainer}
                      >
                        {errors.orgName}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl mb={6} isInvalid={!!errors.cityAndCountry && touched.cityAndCountry}>
                    <FormLabel
                      fontWeight="normal"
                      mt="60px"
                      color="dark.coolGray.500"
                      htmlFor="cityAndCountry"
                    >
                      Organization Headquarters
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <Icon as={MapPin} color={colors.input.placeholder} />
                      </InputLeftElement>
                      <Field
                        data-cy="register-organization-location-input"
                        bg="white"
                        as={InputWithIcon}
                        id="cityAndCountry"
                        type="text"
                        name="cityAndCountry"
                        autoComplete="true"
                        width="400px"
                        placeholder="Naivasha, Kenya"
                        _placeholder={{ fontWeight: 'normal', color: 'dark.coolGray.300' }}
                      />
                    </InputGroup>
                    {errors &&
                      errors.cityAndCountry &&
                      !errors.cityAndCountry.includes(
                        'Please enter your location in the format of "City, Country"'
                      ) && (
                        <FormErrorMessage
                          data-cy="register-organization-location-error"
                          ref={locationErrContainer}
                        >
                          {errors.cityAndCountry}
                        </FormErrorMessage>
                      )}
                    <Text
                      data-cy="register-organization-location-hint"
                      mb={0}
                      mt={1}
                      color="black.300"
                      as="p"
                      fontSize="14px"
                      fontWeight={400}
                    >
                      Please enter your location in the format of &quot;City, Country&quot;.
                      <br /> For example: Nanyuki, Kenya.
                    </Text>
                  </FormControl>

                  <Flex justifyContent="space-between" w="100%" mt={buttonFlexMt(errors, touched)}>
                    <Flex justifyContent="space-between" flexDir="column">
                      <Flex></Flex>
                      <Spacer />
                      <Flex justifyContent="space-evenly">
                        <div className={styles.progressDot}></div>
                        <div className={styles.progressDot}></div>
                        <div className={styles.progressDotCurrent}></div>
                        <div className={styles.progressDot}></div>
                      </Flex>
                    </Flex>
                    <Button
                      data-cy="register-organization-setup-farm-button"
                      width="auto"
                      theme="primary"
                      disabled={!isValid || !dirty || isLoading}
                      type="submit"
                    >
                      Setup your farm
                      {!isLoading && (
                        <>
                          <Box px={1.5}></Box>
                          <Icon as={ArrowRight} />
                        </>
                      )}
                      {isLoading && (
                        <>
                          <Box px={1.5}></Box>
                          <Spinner color="white.500" size="sm" />
                        </>
                      )}
                    </Button>
                  </Flex>
                </form>
              )}
            </Formik>
          </Flex>
          {height <= 850 && <Box h="100px" />}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RegisterOrganization;
