import * as yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(yup);

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required.')
    .matches(
      /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/']+$/,
      'Please enter valid name without special characters'
    )
    .max(40),
  email: yup.string().email('Invalid email address!').required('Email is required.'),
  password: yup
    .string()
    .required('Password is required.')
    .min(8)
    .minLowercase(1, 'password must contain at least 1 lower case letter')
    .minUppercase(1, 'password must contain at least 1 upper case letter')
    .minNumbers(1, 'password must contain at least 1 number')
    .minSymbols(1, 'password must contain at least 1 special character'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required.')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  agreeToTerms: yup.boolean().required().is([true], 'You must agree to the Terms & Conditions')
});

export default schema;
