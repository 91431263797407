import { useAuthContext } from 'Context';
import Authenticated from 'Layout/Authenticated';
import { Settings } from 'Pages';
import React from 'react';
import { Navigate } from 'react-router-dom';

function AuthenticatedAdmin() {
  const {
    variables: { user }
  } = useAuthContext();
  return user?.role === 'Admin' || user?.role === 'SuperAdmin' ? (
    <Authenticated>
      <Settings />
    </Authenticated>
  ) : (
    <Navigate to="/dashboard" />
  );
}

export default AuthenticatedAdmin;
