import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  FormControl,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  Img,
  Spinner,
  Text,
  Link,
  InputRightElement
} from '@chakra-ui/react';
import { Field, Formik, FormikProps } from 'formik';
import { Mail, Lock, User, EyeOff, Eye } from 'react-feather';
import { InputWithIcon, Button, Checkbox, FormLabel } from 'Components';
import { colors } from 'theme';

import Logo from 'assets/images/lima-icon.svg';
import { AcceptInvitationSchema } from 'Validations';
import { AcceptInvitationType } from 'Context/types';
import { NotificationToast } from 'Components/Cards';
import axios from 'axios';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import useDocumentTitle from 'Helpers/useDocumentTitle';

export default function AcceptInvitation(): JSX.Element {
  const { trackPageView } = useMatomo();
  useDocumentTitle('Accept Invitation | Lima Asili');

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const PREFIX_URL = `${process.env.REACT_APP_BE_URL}`;
  const [initialValues, setInitialValues] = useState<AcceptInvitationType>({
    full_name: '',
    password: '',
    agreeToTerms: false,
    confirm_password: '',
    email: '',
    code: ''
  });
  const [isVisible, setVisible] = useState(false);
  const [cIsVisible, setCVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [responseInfo, setResponseInfo] = useState('');
  const [shouldReloadValues, setShouldReloadValues] = useState(false);
  const [myHref] = useState(location.href); //using loaction href so that we're able to capture the possible hashtags in the code
  const query = myHref?.split('?')?.[1]?.split('=');
  const navigate = useNavigate();
  useEffect(() => {
    const userEmail = query?.[1]?.split('&')?.[0];
    const userCode = query?.[2];
    if (userEmail && userCode) {
      setInitialValues({ ...initialValues, email: userEmail, code: userCode });
      setShouldReloadValues(true);
    } else {
      setResponseInfo('You have not received an invitation. Kindly contact your administrator.');
      setHasError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (shouldReloadValues) {
      timeout = setTimeout(() => {
        setShouldReloadValues(false);
      }, 2000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shouldReloadValues]);

  const handleSubmit = async (values: AcceptInvitationType, resetForm: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setIsLoading(true);
    const res = await axios.post(`${PREFIX_URL}/auth/accept-invitation`, values).catch((error) => {
      const errorMsg = error?.response?.data?.detail;
      setResponseInfo(errorMsg);
      setHasError(true);
    });
    if (res && (res?.status === 200 || res?.status === 201)) {
      resetForm({ values: { ...initialValues } });
      navigate('/login', { replace: true });
      setHasError(false);
    }
    setIsLoading(false);
  };

  const onPasswordInput = (type: any, touched: any) => {
    if (type === 'confirm' && !touched.confirm_password) setCVisible(false);
    else if (type !== 'confirm' && !touched.password) setVisible(false);
  };

  return (
    <Flex
      className="login"
      direction="column"
      height="100%"
      justifyContent="center"
      alignItems="center"
      mx="auto"
      my="auto"
    >
      <Box width="400px">
        <Flex
          className="login__logo"
          width="80px"
          height="80px"
          justifyContent="center"
          alignItems="center"
          mb={6}
        >
          <Img src={Logo} />
        </Flex>
        <Heading as="h1" fontSize="2.6875rem" mb={16} fontWeight={400} color="black.500">
          Welcome to Lima!
        </Heading>
        <Heading as="h1" fontSize="2.125rem" color="primary.500" fontWeight={400} mt={14} mb="10">
          Accept Invitation
        </Heading>
        {hasError && <NotificationToast type="error" message={responseInfo} />}
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
          validationSchema={AcceptInvitationSchema}
          validateOnChange={true}
          enableReinitialize={shouldReloadValues}
        >
          {({
            isValid,
            handleSubmit,
            errors,
            touched,
            dirty
          }: FormikProps<AcceptInvitationType>) => (
            <form className="login__form form" onSubmit={handleSubmit}>
              <FormControl mb={6} isInvalid={!!errors.full_name && touched.full_name}>
                <FormLabel htmlFor="full_name">Name</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <User color={colors.input.placeholder} />
                  </InputLeftElement>
                  <Field
                    as={InputWithIcon}
                    id="full_name"
                    type="text"
                    name="full_name"
                    autoComplete="false"
                    placeholder="Jonathan Doe"
                  />
                </InputGroup>
                {errors && errors.full_name && (
                  <FormErrorMessage>{errors.full_name}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mb={6} isInvalid={!!errors.email && touched.email}>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <InputGroup pointerEvents="none">
                  <InputLeftElement pointerEvents="none">
                    <Mail color={colors.input.placeholder} strokeWidth={1.5} />
                  </InputLeftElement>
                  <Field
                    as={InputWithIcon}
                    id="email"
                    type="email"
                    name="email"
                    autoComplete="true"
                    placeholder="user@email.com"
                    readOnly
                  />
                </InputGroup>
                {errors && errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
              </FormControl>
              <FormControl mb={6} isInvalid={!!errors.password && touched.password}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Lock color={colors.input.placeholder} strokeWidth={1.5} />
                  </InputLeftElement>
                  <Field
                    as={InputWithIcon}
                    id="password"
                    name="password"
                    type={!isVisible ? 'password' : 'text'}
                    autoComplete="false"
                    placeholder="At least 8 characters"
                    onInput={() => onPasswordInput('', touched)}
                  />
                  <InputRightElement onClick={() => setVisible(!isVisible)}>
                    {isVisible ? (
                      <Eye color={colors.input.placeholder} strokeWidth={1.5} />
                    ) : (
                      <EyeOff color={colors.input.placeholder} strokeWidth={1.5} />
                    )}
                  </InputRightElement>
                </InputGroup>
                {errors && errors.password && (
                  <FormErrorMessage>{errors.password}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mb={6} isInvalid={!!errors.confirm_password && touched.confirm_password}>
                <FormLabel htmlFor="confirm_password">Confirm Password</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Lock color={colors.input.placeholder} strokeWidth={1.5} />
                  </InputLeftElement>
                  <Field
                    as={InputWithIcon}
                    id="confirm_password"
                    name="confirm_password"
                    type={!cIsVisible ? 'password' : 'text'}
                    autoComplete="false"
                    placeholder="Should match the password above"
                    onInput={() => onPasswordInput('confirm', touched)}
                  />
                  <InputRightElement onClick={() => setCVisible(!cIsVisible)}>
                    {cIsVisible ? (
                      <Eye color={colors.input.placeholder} strokeWidth={1.5} />
                    ) : (
                      <EyeOff color={colors.input.placeholder} strokeWidth={1.5} />
                    )}
                  </InputRightElement>
                </InputGroup>
                {errors && errors.confirm_password && (
                  <FormErrorMessage>{errors.confirm_password}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mb={6} isInvalid={!!errors.agreeToTerms && touched.agreeToTerms}>
                <Field
                  as={Checkbox}
                  colorScheme="primary"
                  outline="none"
                  shadow="0"
                  name="agreeToTerms"
                  className="where-be-me"
                  _checked={{
                    boxShadow: 'none',
                    svg: {
                      stroke: 'white !important'
                    }
                  }}
                >
                  I agree to the{' '}
                  <Link
                    as="a"
                    href="https://www.lima.ag/"
                    color="primary.500"
                    target="_blank"
                    referrerPolicy="no-referrer"
                  >
                    Terms &amp; Conditions
                  </Link>
                </Field>
                {errors && errors.agreeToTerms && (
                  <FormErrorMessage>{errors.agreeToTerms}</FormErrorMessage>
                )}
              </FormControl>
              <Box>
                <Button
                  width="100%"
                  theme="primary"
                  disabled={!isValid || !dirty || isLoading}
                  type="submit"
                >
                  Get Started
                  {isLoading && (
                    <>
                      <Box px={1.5}></Box>
                      <Spinner color="white.500" size="sm" />
                    </>
                  )}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Box pt={8}>
          <Text as="p" color="dark.coolGray.400">
            Already a member?{' '}
            <Link as={RouterLink} to="/login" color="primary.500" state={{ auth: '/login' }}>
              Log in
            </Link>
          </Text>
        </Box>
      </Box>
    </Flex>
  );
}
