import { useGraphContext } from 'Context/GraphContext';
import React, { useEffect, MouseEvent, useCallback, useMemo, createElement, useState } from 'react';
import { useTooltip } from '@nivo/tooltip';
import moment from 'moment';
import { kFormatter } from 'Helpers/helpers';

const CustomBarComponent = (props: any) => {
  const {
    bar: {
      x,
      y,
      width,
      height,
      data: {
        indexValue,
        id,
        data: { predicted_stems, actual_stems, ...rest }
      },
      color
    },
    isInteractive,
    onMouseEnter,
    onMouseLeave,
    onClick,
    tooltip
  } = props;

  const { value, selectedYear, filterTags, duration, selectedFieldProductivity } =
    useGraphContext();
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const toolTipRef = React.useRef<SVGGElement>(null);
  const [barColor, setBarColor] = useState<any>(color);
  useEffect(() => {
    if (duration === 'daily') {
      scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollIntoView = () => {
    if (toolTipRef && toolTipRef.current) {
      const options: ScrollIntoViewOptions = {
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
      };
      toolTipRef.current.scrollIntoView(options);
    }
  };

  const renderTooltip = useMemo(
    () => () => createElement(tooltip, { ...props.bar, ...props.data }),
    [tooltip, props.bar, props.data]
  );

  const handleTooltip = useCallback(
    (event: MouseEvent<SVGRectElement>) => showTooltipFromEvent(renderTooltip(), event),
    [showTooltipFromEvent, renderTooltip]
  );

  const handleMouseEnter = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseEnter?.(props.data, event);
      showTooltipFromEvent(renderTooltip(), event);
    },
    [props.data, onMouseEnter, showTooltipFromEvent, renderTooltip]
  );

  const handleMouseLeave = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseLeave?.(props.data, event);
      hideTooltip();
    },
    [props.data, hideTooltip, onMouseLeave]
  );

  const handleOnClick = useCallback(() => {
    if (onClick) onClick(props.bar.data.data);
  }, [props.bar, onClick]);

  const getProductionValue = (val: any): number => {
    if (val) {
      return val as number;
    }
    return 0;
  };

  /**
   * @description This function checks if the selected year is the current year using moment.js
   * @returns true if the current year is selected
   */
  const isCurrentYear = (): boolean => {
    const currYear = moment().year();
    return selectedYear === currYear.toString();
  };

  const tags = filterTags?.filter((el: any) => el.active)?.map((el: any) => el.id);

  const getTransform = () => {
    return width * 0.5 * filterTags?.filter((el: any) => el.active)?.length;
  };

  useEffect(() => {
    if (
      rest.graph_name === 'field_productivity' &&
      selectedFieldProductivity &&
      selectedFieldProductivity?.period !== indexValue
    )
      setBarColor(`${color}59`);
    else setBarColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFieldProductivity]);

  return (
    <>
      {indexValue === value && id === tags?.[0] ? (
        <g
          ref={toolTipRef}
          onMouseEnter={isInteractive ? handleMouseEnter : undefined}
          onMouseMove={isInteractive ? handleTooltip : undefined}
          onMouseLeave={isInteractive ? handleMouseLeave : undefined}
          onClick={isInteractive ? handleOnClick : undefined}
          cursor={isInteractive ? 'pointer' : 'initial'}
        >
          {/* bar */}
          <rect
            className="bar-graph-top-radius"
            x={x}
            y={y}
            width={width}
            height={height}
            stroke={'#00000000'}
            strokeWidth={4}
            fill={barColor}
          />
          {/* current pointer */}
          {isCurrentYear() && (
            <g transform={`translate(${x},${y})`}>
              <g
                transform={`translate(${0 - 85 + getTransform()},${0 - y + 80})`}
                width="170"
                height="120"
              >
                <rect
                  fill="#2C2E2D"
                  x={0}
                  y={-85}
                  width="170"
                  height="90"
                  overflow={'visible'}
                ></rect>
                <text
                  x={15}
                  y={-65}
                  fill="#ffffff"
                  fontWeight={300}
                  fontSize="14px"
                  letterSpacing="0.3"
                >
                  Actual: {kFormatter(getProductionValue(actual_stems), 0)}
                </text>
                <text
                  x={15}
                  y={-45}
                  fill="#ffffff"
                  fontWeight={300}
                  fontSize="14px"
                  letterSpacing="0.3"
                >
                  Forecast: {kFormatter(getProductionValue(predicted_stems), 0)}
                </text>
                <text
                  x={15}
                  y={-25}
                  fill="#ffffff"
                  fontWeight={300}
                  fontSize="14px"
                  letterSpacing="0.3"
                >
                  1 Week:{' '}
                  {kFormatter(
                    getProductionValue(rest?.weeks_out_1 ? rest?.weeks_out_1 : rest?.days_out_7),
                    0
                  )}
                </text>
                <text
                  x={15}
                  y={-5}
                  fill="#ffffff"
                  fontWeight={300}
                  fontSize="14px"
                  letterSpacing="0.3"
                >
                  2 Weeks:{' '}
                  {kFormatter(
                    getProductionValue(rest?.weeks_out_2 ? rest?.weeks_out_2 : rest?.days_out_14),
                    0
                  )}
                </text>
                <polygon
                  points={`${170 / 2 - 9.75},1,${170 / 2},-16, ${170 / 2 + 9.75}, 1`}
                  fill="#2C2E2D"
                  style={{
                    transform: 'scaleY(-1)'
                  }}
                />
              </g>
              <line
                x1={getTransform()}
                x2={getTransform()}
                y1={0}
                y2={0 - y + 85}
                strokeDasharray="6,6"
                strokeWidth={2}
                stroke="#2C2E2D"
              />
            </g>
          )}
          {/* current pointer */}
        </g>
      ) : (
        <g
          onMouseEnter={isInteractive ? handleMouseEnter : undefined}
          onMouseMove={isInteractive ? handleTooltip : undefined}
          onMouseLeave={isInteractive ? handleMouseLeave : undefined}
          onClick={isInteractive ? handleOnClick : undefined}
          cursor={isInteractive ? 'pointer' : 'initial'}
        >
          <rect
            className="bar-graph-top-radius"
            x={x}
            y={y}
            width={width}
            height={height}
            fill={barColor}
            stroke={'#00000000'}
            strokeWidth={4}
          />
        </g>
      )}
    </>
  );
};

export default CustomBarComponent;
