import { Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import styles from './CompleteRegistrationModal.module.scss';
import { Feather, Map, Users } from 'react-feather';

export type SetUpCardStepProps = {
  icon: 'map' | 'leaf' | 'people';
  done: boolean;
  isCurrStep: boolean;
  label: string;
};

const SetUpCardStep = (props: SetUpCardStepProps) => {
  const { icon, done, label, isCurrStep } = props;

  const borderCss = (_isCurrStep: boolean, _done: boolean) => {
    if (_isCurrStep && !_done) {
      return styles.cardBorder;
    } else if (_done) {
      return styles.cardBorderDone;
    } else {
      return styles.cardBorderGray;
    }
  };

  const iconAndTextColor = (_isCurrStep: boolean, _done: boolean) => {
    if (_isCurrStep && !_done) {
      return 'dark.coolGray.500';
    } else if (_done) {
      return 'dark.coolGray.500';
    } else {
      return 'dark.coolGray.300';
    }
  };

  const iconMapper = (icon: 'map' | 'leaf' | 'people') => {
    switch (icon) {
      case 'map':
        return Map;
      case 'leaf':
        return Feather;
      case 'people':
        return Users;
    }
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className={borderCss(isCurrStep, done)}
      flexDir="column"
      boxSize="150px"
    >
      <Icon
        strokeWidth="1px"
        as={iconMapper(icon)}
        boxSize="40px"
        color={iconAndTextColor(isCurrStep, done)}
      />
      <Text className={styles.cardText} color={iconAndTextColor(isCurrStep, done)} mt="20px">
        {label}
      </Text>
    </Flex>
  );
};

export default SetUpCardStep;
