import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  Spinner,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Select
} from '@chakra-ui/react';
import { useActualContext, useAppContext, useAuthContext } from 'Context';
import { Button, InputDate, MultiSelectDropdown } from 'Components';
import {
  Farm,
  GradingItem,
  Harvest,
  HarvestData,
  HarvestProductionCategory,
  ProductionCategory,
  Variety
} from 'Context/types';
import { Field, Formik, FormikProps, useFormikContext } from 'formik';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ActualInputSchema } from 'Validations';
import _, { debounce } from 'lodash';
import styles from './../../../Styles/Table.module.scss';
import { Save } from 'react-feather';
import { filterHarvests, formatToServerFormat, objectsSame } from 'Helpers/helpers';
import { Tab } from 'Components/Navigation';
import SummaryTable, { SummaryState } from './SummaryTable';
import LimaDateRangePicker from 'Components/DateRangePicker/LimaDateRangePicker';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { ActiveFieldsType } from 'data/GraphRepositoryImpl';
import moment from 'moment';
import {
  createPreDefinedDateRanges,
  CustomPredefinedDateRange
} from 'Helpers/dateRangePickerHelpers';
import { filterHarvestsWork } from 'Helpers/workerHelpers';
import Skeleton from 'react-loading-skeleton';
import { graphRepository, httpClient } from 'Helpers/appDependenciesHelpers';
import { useGraphContext } from 'Context/GraphContext';

type SummaryTableHeaderType = 'normal' | 'grade-split';

type SummaryTableHead = {
  label: string;
  type: SummaryTableHeaderType;
  gradeTitles?: string[];
};

export type SummaryTableProps = {
  farm: Farm;
  variety: Variety;
  period?: number; // default 15
  token: string;
  grades: ProductionCategory[];
  handleHasUnsavedEdits: (value: boolean) => void;
  actualTableNotSaved: boolean;
  setSelectedTab: (tab: string) => void;
  summaryState: SummaryState | null;
  defaultStartDate?: string;
  defaultEndDate?: string;
  onStartDateChange?: (new_start_date: string) => void;
};

export type CombinedFilters = {
  startDate: Date | null;
  endDate: Date | null;
  fields: ActiveFieldsType[];
};

const ActualInputTable = (props: SummaryTableProps) => {
  const currYearDateRange: DateRange = [
    moment(props.defaultStartDate).toDate(),
    moment(props.defaultEndDate).toDate()
  ];
  const { farm, variety, grades, actualTableNotSaved, setSelectedTab, summaryState } = props;
  const { varietyIsCyclic } = useAppContext();
  const farmId = farm.id;
  const varietyId = variety.id;
  const [initialValues, setInitialValues] = useState<HarvestData>({
    actual_inputs: [],
    summary: []
  });
  const [shouldReloadValues, setShouldReloadValues] = useState(false);
  const { actualsData, loadingActuals, updateActualData, fetchActualInputData } =
    useActualContext();
  const formRef = useRef();
  const [previousValues, setPreviousValues] = useState<HarvestData>({
    actual_inputs: [],
    summary: []
  });
  const [saveButtonHidden, setSaveButtonHidden] = useState<boolean>(false);
  const [combinedFilters, setCombinedFilters] = useState<CombinedFilters | null>({
    startDate: currYearDateRange[0],
    endDate: currYearDateRange[1],
    fields: []
  });
  const [dateRangeValue, setDateRangeValue] = React.useState<DateRange | null>(currYearDateRange);
  const predefinedDateRangesMemo = useMemo(() => {
    const customPredefinedRanges: CustomPredefinedDateRange[] = [
      'Last Week',
      'Last 2 Weeks',
      'Last Month',
      'Last 2 Months',
      'Last Quarter'
    ];
    return createPreDefinedDateRanges(customPredefinedRanges);
  }, []);
  const [triggerButtonText, setTriggerButtonText] = useState<string>('Field');
  const [selectedYears, setSelectedYears] = useState<number[]>([moment().year()]);
  const [loadingFields, setLoadingFields] = useState<boolean>(false);
  const newGrades: HarvestProductionCategory[] = [];
  if (grades != undefined) {
    grades.forEach((newGrade) => {
      newGrades.push({
        [newGrade.id]: { yield_value: 0, production_category_id: newGrade.id }
      });
    });
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setInitialValues({ actual_inputs: actualsData, summary: [] });
    setPreviousValues({ actual_inputs: actualsData, summary: [] });
    setShouldReloadValues(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualsData, setInitialValues]);

  const handleSubmit = async (values: HarvestData) => {
    if (combinedFilters?.startDate && combinedFilters?.endDate) {
      updateActualData(
        values.actual_inputs,
        formatToServerFormat(combinedFilters.startDate) ?? '',
        formatToServerFormat(combinedFilters?.endDate) ?? ''
      );
      setInitialValues(values);
    }
  };

  const handleOnChange = () => {};
  useEffect(() => {
    props.handleHasUnsavedEdits(formRef.current ? formRef.current : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef.current]);

  const FormObserver: React.FC = () => {
    const formik = useFormikContext();
    useEffect(() => {
      // updating state for unsaved edits
      props.handleHasUnsavedEdits(formik.dirty ? formik.dirty : false);

      // automatically add new column for add data when last column is edited
      const harvestData = formik.values as HarvestData;
      const lastIndex = harvestData.actual_inputs.length - 1;
      let newRowAdded = false;
      if (harvestData != null && harvestData.actual_inputs.length > 0) {
        Object.entries(harvestData.actual_inputs[lastIndex]).forEach((harvest, index) => {
          if (
            ((harvest[0] != 'day' && harvest[1] != undefined) ||
              (harvest[0] == 'day' && harvest[1] != '')) &&
            index < 5 &&
            index > 3
          ) {
            newRowAdded = true;
          }
        });
      }

      if (newRowAdded) {
        harvestData.actual_inputs.push({
          _id: null,
          name: '',
          field_id: '',
          farm_id: farmId,
          variety: varietyId,
          day: '',
          stems: 0,
          harvest_productions: newGrades,
          created_at: '',
          updated_at: ''
        });
      }

      // autosum and autofill total_graded/stems
      const reject_production_category = variety?.production_categories.find(
        (production_category) => production_category.category_type == 'reject'
      );
      harvestData.actual_inputs.forEach((harvest, index) => {
        // check if any grading has been edit
        if (
          harvest != undefined &&
          previousValues.actual_inputs[index] != undefined &&
          !objectsSame(
            harvest.harvest_productions,
            previousValues.actual_inputs[index].harvest_productions,
            null
          ) &&
          !shouldReloadValues
        ) {
          let grading_sum = 0;
          //update total for edited gradings
          harvest?.harvest_productions?.forEach((harvest_production_item) => {
            Object.values(harvest_production_item)?.forEach((grade) => {
              if (reject_production_category?.id != grade.production_category_id) {
                grading_sum += +grade.yield_value;
              }
            });
          });

          harvestData.actual_inputs[index].stems = grading_sum;
        }
      });
      setPreviousValues(harvestData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);
    return <></>;
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (shouldReloadValues) {
      // formik `enableReinitialize` is almost infinite so we only do it for a short time
      timeout = setTimeout(() => {
        setShouldReloadValues(false);
      }, 10);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shouldReloadValues]);

  const handleActualDataChange = async (
    _actualsData: Harvest[],
    _combinedFilters: CombinedFilters | null
  ) => {
    let _filteredActuals: Harvest[] = [];
    if (window.Worker && !window.Cypress) {
      _filteredActuals = await filterHarvestsWork(_actualsData, _combinedFilters).catch(() => {
        return [];
      });
    } else {
      _filteredActuals = filterHarvests(_actualsData, _combinedFilters);
    }

    const new_actuals_inputs = [..._filteredActuals];
    // add extra rows for adding data
    const updatedNewDataRows = [];
    for (let index = 0; index < 3; index++) {
      new_actuals_inputs.push({
        _id: null,
        field_id: '',
        name: '',
        farm_id: farmId,
        variety: varietyId,
        day: '',
        stems: 0,
        harvest_productions: newGrades,
        created_at: '',
        updated_at: ''
      });

      updatedNewDataRows.push({
        index: _actualsData.length + index + 1,
        editable: index == 0 ? true : false
      });
    }
    const safeData: HarvestData = {
      actual_inputs: new_actuals_inputs ?? [],
      summary: []
    };
    setInitialValues(safeData);
    setShouldReloadValues(true);
  };

  useEffect(() => {
    if (actualsData) {
      handleActualDataChange(actualsData, combinedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualsData, combinedFilters?.fields]);

  useEffect(() => {
    fetchActualInputData(
      combinedFilters?.startDate ? formatToServerFormat(combinedFilters?.startDate) : '',
      combinedFilters?.endDate ? formatToServerFormat(combinedFilters?.endDate) : ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedFilters?.startDate, combinedFilters?.endDate]);

  // scroll latest actuals into view
  const actualInputsEndRef = useRef<HTMLTableRowElement>(null);
  const scrollToBottom = () => {
    if (actualInputsEndRef && actualInputsEndRef.current) {
      const options: ScrollIntoViewOptions = {
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
      };
      actualInputsEndRef.current.scrollIntoView(options);
    }
  };
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (initialValues.actual_inputs.length > 0) {
      timeout = setTimeout(() => scrollToBottom(), 500);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [initialValues.actual_inputs.length]);

  const extractGradeValues = (grades: ProductionCategory[]): string[] => {
    const titles: string[] = [];
    for (let i = 0; i < grades.length; i++) {
      const grade = grades[i];
      const title = grade.external_name;
      titles.push(title);
    }
    return titles;
  };

  const tableHeaders: SummaryTableHead[] = [
    {
      label: '',
      type: 'normal'
    },
    {
      label: 'Harvest Date',
      type: 'normal'
    },
    {
      label: 'Field Name',
      type: 'normal'
    },
    {
      label: 'Actual Graded',
      type: 'grade-split',
      gradeTitles: extractGradeValues(grades)
    },
    {
      label: 'Total Graded',
      type: 'normal'
    }
  ];
  const handleTabsChange = (index: number) => {
    switch (index) {
      case 0:
        setSaveButtonHidden(false);
        break;
      case 1:
        setSaveButtonHidden(true);
        break;
      default:
        setSaveButtonHidden(false);
        break;
    }
  };

  const handleDateChange = (
    values: DateRange | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _event: React.SyntheticEvent<Element, Event>
  ) => {
    setDateRangeValue(values);
    if (values) {
      if (combinedFilters != null || combinedFilters != undefined) {
        setCombinedFilters({
          ...combinedFilters,
          startDate: values[0],
          endDate: values[1]
        });
      } else {
        setCombinedFilters({
          startDate: values[0],
          endDate: values[1],
          fields: []
        });
      }
    }
  };

  const [fields, setFields] = useState<ActiveFieldsType[]>([]);
  const {
    loadingStates: { loadingBlocks }
  } = useAuthContext();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);

  const handleSelectionChange = (selectedItemsIds: string | string[]) => {
    const ids = Array.isArray(selectedItemsIds) ? selectedItemsIds : [selectedItemsIds];
    setSelectedItemIds(ids);
    if (combinedFilters != null || combinedFilters != undefined) {
      setCombinedFilters({
        ...combinedFilters,
        fields: fields.filter((field) => selectedItemsIds.includes(field.id))
      });
    } else {
      setCombinedFilters({
        startDate: null,
        endDate: null,
        fields: fields.filter((field) => selectedItemsIds.includes(field.id))
      });
    }
  };

  useEffect(() => {
    if (combinedFilters?.fields) {
      const _fields = combinedFilters.fields;
      if (_fields.length > 0) {
        setTriggerButtonText('Fields');
      } else if (_fields.length === 1) {
        setTriggerButtonText('Field');
      }
    }
  }, [combinedFilters?.fields]);

  const allowedDateRange = (): DateRange => {
    // using moment from 2022 to current year
    const startDate = moment().year(2022).startOf('year').toDate();
    const endDate = moment().endOf('year').toDate();
    return [startDate, endDate];
  };

  const selectedFarmHasUpdatedVarieties = (farm: Farm, variety: Variety): boolean => {
    if (!farm || !variety) return false;
    const farmVarieties = farm?.varieties;
    const farmVarietiesIds = farmVarieties?.map((v) => v.id);
    const varietyId = variety.id;
    return farmVarietiesIds?.includes(varietyId) ?? false;
  };
  const fetchAllFields = async (token: string, variety_id: string, farm: Farm, year: string) => {
    setLoadingFields(true);
    const res = await graphRepository.getActiveFields(token, variety_id, farm, year.toString());
    if (res?.status === 200) {
      const data = res.data as ActiveFieldsType[];
      const fieldIds = data.map((field) => field.id);
      setSelectedItemIds(fieldIds);
      setFields(data);
    }
    setLoadingFields(false);
  };
  const fetchAllFieldsMultipleYears = async (
    token: string,
    variety_id: string,
    farm_id: string,
    years: number[]
  ) => {
    setLoadingFields(true);
    const promises = years.map((year) =>
      graphRepository.getActiveFields(token, variety_id, farm, year.toString())
    );
    const res = await Promise.all(promises);
    if (res !== undefined || res !== null) {
      const _fields: ActiveFieldsType[] = [];
      res.forEach((r) => {
        if (r?.status === 200) {
          const data = r.data as ActiveFieldsType[];
          data?.forEach((field: ActiveFieldsType) => {
            _fields.push(field);
          });
        }
      });
      const _fieldsWithoutDuplicates = _.uniqBy(_.flatten(_fields), 'id');
      const fieldIds = _fieldsWithoutDuplicates?.map((field) => field.id);
      setSelectedItemIds(fieldIds);
      setFields(_fieldsWithoutDuplicates);
    }
    setLoadingFields(false);
  };

  useEffect(() => {
    // check if variety belongs to farm
    const variety_is_valid = farm?.varieties?.some((farm_variety) => {
      return farm_variety?.id === variety?.id;
    });
    if (farm && !loadingBlocks && variety_is_valid) {
      if (selectedFarmHasUpdatedVarieties(farm, variety)) {
        if (selectedYears.length > 0 && selectedYears.length === 1) {
          fetchAllFields(props.token, varietyId, farm, selectedYears[0].toString());
        } else {
          fetchAllFieldsMultipleYears(props.token, varietyId, farmId, selectedYears);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm, variety, selectedYears, loadingBlocks]);

  useEffect(() => {
    if (combinedFilters?.startDate && combinedFilters?.endDate) {
      const years: number[] = [];
      const startYear = moment(combinedFilters.startDate).year();
      const endYear = moment(combinedFilters.endDate).year();
      for (let i = startYear; i <= endYear; i++) {
        years.push(i);
      }
      setSelectedYears(years);
    } else {
      setSelectedYears([moment().year()]);
    }
  }, [combinedFilters?.startDate, combinedFilters?.endDate]);

  // TODO: do proper error handling here
  if (!farmId || !varietyId) {
    return <div>Could&apos;nt get Actual Data</div>;
  }

  return (
    <Flex flexDir="column">
      <Flex alignItems="flex-start">
        <Box mr={2}>
          <LimaDateRangePicker
            startYearLimit={2020}
            endYearLimit={moment().year()}
            allowedDateRange={allowedDateRange()}
            initialValue={dateRangeValue}
            predefinedRanges={predefinedDateRangesMemo}
            onChange={handleDateChange}
          />
        </Box>
        <Box>
          {loadingFields ? (
            <Skeleton width={100} height={30} />
          ) : (
            <MultiSelectDropdown
              items={fields}
              selectedItems={selectedItemIds}
              name="harvest-by-field"
              buttonHeight="2.25rem"
              label={triggerButtonText}
              multi
              showSubmit
              searchable
              toggleAll
              onSelectFilter={handleSelectionChange}
              onSubmit={handleSelectionChange}
              onMouseLeave={() => {}}
              onBlur={() => {}}
              clearSelection={false}
              uniqueID="id"
              displayParam="name"
            />
          )}
        </Box>
      </Flex>
      {initialValues && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={ActualInputSchema}
          validateOnChange={false}
          validateOnMount={true}
          enableReinitialize={shouldReloadValues}
        >
          {({ isValid, handleSubmit, setFieldValue, values, dirty }: FormikProps<HarvestData>) => (
            <form
              id={'actuals_input_form'}
              className="login__form form"
              onSubmit={handleSubmit}
              onChange={debounce(handleOnChange, 500)}
            >
              <FormObserver />
              <Tabs
                onChange={handleTabsChange}
                pos="relative"
                align="start"
                variant="enclosed"
                mt={18}
              >
                <Box
                  w="100%"
                  pos="absolute"
                  sx={{
                    top: '0',
                    right: '0',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Box display={'flex'} justifyContent="flex-end">
                    <Button
                      hidden={saveButtonHidden}
                      data-cy="btn-save-entries"
                      width="auto"
                      theme="primary"
                      type="submit"
                      size="sm"
                      disabled={!dirty && !loadingActuals && isValid ? true : false}
                    >
                      {loadingActuals ? (
                        <>
                          <Box px={1.5}></Box>
                          <Spinner color="white.500" size="sm" />
                        </>
                      ) : (
                        <Icon as={Save} stroke={'white.500'} w={4} h={4} mr={1} />
                      )}
                      Save New Entries
                    </Button>
                  </Box>
                </Box>
                <TabList borderBottom={'0px'}>
                  <Tab
                    zIndex={1}
                    onClick={() => {
                      setSelectedTab('Actual');
                    }}
                  >
                    {actualTableNotSaved ? <Text className="green-circle" as="span"></Text> : ''}
                    Actual Harvest Input
                  </Tab>
                  <Tab
                    zIndex={1}
                    onClick={() => {
                      setSelectedTab('Summary');
                    }}
                  >
                    {!varietyIsCyclic() && 'Weekly'} Production Summary
                  </Tab>
                </TabList>

                <TabPanels pt={4} border={'1.5px solid #CBD2D9'} borderRadius={'4px'}>
                  <TabPanel>
                    <Box
                      className={`custom-scrollbar `}
                      overflowY="auto"
                      maxHeight={'60vh'}
                      minHeight={'10vh'}
                      marginTop={-25}
                    >
                      <Table className={`${styles.table__content} gray-border`} size="sm">
                        <Thead
                          backgroundColor="dark.coolGray.100"
                          pos="sticky"
                          top={-0.5}
                          zIndex="docked"
                        >
                          <Tr>
                            {tableHeaders.map((header, index) => {
                              if (header.type === 'normal') {
                                return (
                                  <Th className="gray-border" key={index + header.label}>
                                    <Text
                                      color="green.500"
                                      className={styles.table__actual__header}
                                    >
                                      {header.label}
                                    </Text>
                                  </Th>
                                );
                              } else if (header.type === 'grade-split') {
                                return (
                                  <Th p={0} className="gray-border" key={index + header.label}>
                                    <Flex flexDir="column">
                                      <Box
                                        as={Th}
                                        color="green.500"
                                        p={1}
                                        className="gray-border-bottom"
                                        textAlign="center"
                                      >
                                        <Text
                                          color="green.500"
                                          className={styles.table__actual__header}
                                        >
                                          {header.label}
                                        </Text>
                                      </Box>
                                      <Flex justifyContent="center">
                                        {grades?.map((grade, index) => (
                                          <Box
                                            p={1}
                                            as={Th}
                                            w="100%"
                                            h="100%"
                                            key={index + header.label}
                                            className={
                                              header.gradeTitles &&
                                              index === header.gradeTitles?.length - 1
                                                ? ''
                                                : 'gray-border-right'
                                            }
                                            textAlign="center"
                                            color="green.500"
                                          >
                                            <Text
                                              color="green.500"
                                              className={styles.table__actual__header}
                                            >
                                              {grade.external_name}
                                            </Text>
                                          </Box>
                                        ))}
                                      </Flex>
                                    </Flex>
                                  </Th>
                                );
                              }
                            })}
                          </Tr>
                        </Thead>
                        <Tbody className={`${styles.table__content__body}`}>
                          <>
                            {values.actual_inputs.length > 0 &&
                              values.actual_inputs.map((item, index) => (
                                <Tr
                                  key={
                                    index + (item != null && item._id != null ? item._id : 'added')
                                  }
                                >
                                  <Td className="gray-border">{index + 1}</Td>
                                  <Td
                                    className="gray-border"
                                    data-cy={'actual_inputs[' + index + '].day'}
                                  >
                                    <Field
                                      as={InputDate}
                                      data-cy={'actual_inputs[' + index + '].day'}
                                      id={'actual_inputs[' + index + '].day'}
                                      name={'actual_inputs[' + index + '].day'}
                                      variant="unstyled"
                                      placeholder="-"
                                      color="dark.coolGray.400"
                                      type={'date'}
                                      isRequired={true}
                                      isReadOnly={false}
                                      onChange={(value: string | undefined) =>
                                        setFieldValue &&
                                        setFieldValue(
                                          'actual_inputs[' + index + '].day',
                                          value,
                                          true
                                        )
                                      }
                                      date={
                                        _.get(values, 'actual_inputs[' + index + '].day') as string
                                      }
                                    />
                                  </Td>
                                  <Td className="gray-border">
                                    <Select
                                      placeholder="-"
                                      width={'7vw'}
                                      id={'actual_inputs[' + index + '].field_id'}
                                      name={'actual_inputs[' + index + '].field_id'}
                                      variant="unstyled"
                                      color="dark.coolGray.400"
                                      isRequired={false}
                                      isReadOnly={false}
                                      size={'sm'}
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          'actual_inputs[' + index + '].field_id',
                                          e.target.value
                                        );
                                      }}
                                    >
                                      {fields.map((field) => (
                                        <option
                                          key={field.id}
                                          value={field.id}
                                          selected={field.id == item?.field_id ? true : false}
                                        >
                                          {field.name}
                                        </option>
                                      ))}
                                    </Select>
                                  </Td>
                                  <Td p={0} className="gray-border">
                                    <Flex p={0} m={0} justifyContent="center">
                                      {variety?.production_categories?.map(
                                        (production_category, indexGrade) => (
                                          <Box
                                            as={Td}
                                            w="100%"
                                            h="100%"
                                            key={index + '_' + indexGrade}
                                            className={
                                              item.harvest_productions &&
                                              index === item.harvest_productions?.length - 1
                                                ? 'remove-bottom-border'
                                                : 'gray-border-right remove-bottom-border'
                                            }
                                            textAlign="center"
                                            color={'black.lighter.400'}
                                          >
                                            <Field
                                              as={Input}
                                              id={
                                                'actual_inputs[' +
                                                index +
                                                '].harvest_productions[' +
                                                indexGrade +
                                                '].[' +
                                                production_category.id +
                                                '].yield_value'
                                              }
                                              name={
                                                'actual_inputs[' +
                                                index +
                                                '].harvest_productions[' +
                                                indexGrade +
                                                '].[' +
                                                production_category.id +
                                                '].yield_value'
                                              }
                                              variant="unstyled"
                                              placeholder="-"
                                              color="dark.coolGray.400"
                                              type={'number'}
                                              isReadOnly={false}
                                            />
                                          </Box>
                                        )
                                      )}
                                    </Flex>
                                  </Td>
                                  <Td className="gray-border">
                                    <Field
                                      as={Input}
                                      id={'actual_inputs[' + index + '].stems'}
                                      name={'actual_inputs[' + index + '].stems'}
                                      variant="unstyled"
                                      placeholder="-"
                                      color="dark.coolGray.400"
                                      type={'number'}
                                      isReadOnly={false}
                                    />
                                  </Td>
                                </Tr>
                              ))}
                            <Tr
                              key={values.actual_inputs.length + 1 + 'last'}
                              ref={actualInputsEndRef}
                            ></Tr>
                          </>
                        </Tbody>
                      </Table>
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    {summaryState && (
                      <SummaryTable
                        state={summaryState}
                        grades={variety?.production_categories ?? []}
                        combinedFilters={combinedFilters}
                      />
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </form>
          )}
        </Formik>
      )}
    </Flex>
  );
};

export default ActualInputTable;
