import { extendTheme } from '@chakra-ui/react';
import { colors } from 'theme';
import styles from './styles';

const theme = extendTheme({
  colors: { ...colors },
  fonts: {
    body: "'Be Vietnam Pro', sans-serif",
    heading: 'Readex Pro'
  },
  breakPoints: {
    sm: '1280px',
    md: '1440px',
    lg: '1920px',
    xl: '2100px',
    '2xl': '2560px',
    '3xl': '3240px'
  },
  config: {
    cssVarPrefix: 'ck-lima'
  },
  styles: {
    global: {
      'body, body *': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        fontSize: '0.929rem',
        // color: '#2c2e2d', // TODO: find a better solution for this issue: the issue is that this color forces icon colors to be black
        fontFamily: "'Be Vietnam Pro', sans-serif",
        fontWeight: 400
      },
      'body, body > #root ': {
        width: '100%',
        overflowX: 'hidden',
        height: '100%',
        minHeight: '100vh',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
      },
      '#root': {
        maxWidth: '100%',
        margin: '0 auto'
      },
      '.table-content': {
        color: '#565857'
      },
      ...styles
    }
  }
  // components: {
  //   Tabs: {
  //     baseStyle: {
  //       tablist: {
  //         borderBottom: 'none'
  //       }
  //     }
  //   }
  // }
});

export const styledTheme = {
  primary: '#00a896',
  secondary: '#f38624',
  black: '#2c2e2d',
  accentGreen: 'e9f9f0',
  success: '#00b91d',
  info: '#53a5ff',
  warn: '#f8b610',
  error: '#d21122'
};

export default theme;
