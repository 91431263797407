import React, { useState, useEffect } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { GraphKeys } from 'Components';
import { useGraphContext } from 'Context/GraphContext';
import { separateComma } from 'Helpers/helpers';
import { Circle } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { Bar } from '@nivo/bar';
import CombinedBarAndLineChart from '../CombinedBarAndLineChart';
import GradesAndRejectsHeader from './GradesAndRejectsHeader';
import CustomTooltip from '../CustomTooltip';
import { nivoTheme } from '../FarmProductionBarChart';
import { GradesAndRejectsType } from 'data/GraphRepositoryImpl';

export type GradesAndRejectsProps = {
  data: GradesAndRejectsType | null;
  yMax?: number;
};

function GradesAndRejectsChart(props: GradesAndRejectsProps) {
  const { data, yMax } = props;
  const {
    reportFilterState,
    totalsDuration,
    loadingGradesAndRejects,
    setLoadingGradesAndRejects,
    gradesAndRejectsTags,
    setGradesAndRejectsTags
  } = useGraphContext();
  const today = new Date().toISOString().split('T')[0];
  const getSubLabel = () => {
    if (reportFilterState?.days?.length && totalsDuration == 'daily') {
      return `\u00A0 Days | ${reportFilterState?.days[0]} to ${
        reportFilterState?.days[reportFilterState?.days?.length - 1]
      }`;
    } else if (reportFilterState?.weeks?.length && totalsDuration == 'weekly') {
      return `\u00A0 Weeks | ${reportFilterState?.weeks[0]} to ${
        reportFilterState?.weeks[reportFilterState?.weeks?.length - 1]
      }`;
    }
    return '';
  };
  const [displayData, setDisplayData] = useState<any[]>(data?.actuals ?? []);
  const formatLeftAxisValue = (value: number): string => {
    return separateComma(value);
  };
  const getDurationKeys = (): string[] => {
    return gradesAndRejectsTags.filter((tag: any) => tag.active).map((tag: any) => tag.id);
  };
  const getDurationColors = (): string[] => {
    return gradesAndRejectsTags.filter((tag: any) => tag.active).map((tag: any) => tag.fill);
  };
  const setStatus = (id: any) => {
    setLoadingGradesAndRejects(true);
    gradesAndRejectsTags.map((tag: any) => {
      if (tag.id === id) {
        tag.active = !tag.active;
        return tag;
      }
      return tag;
    });
    setGradesAndRejectsTags([...gradesAndRejectsTags]);
    setDisplayData([]);
    setTimeout(() => {
      setDisplayData(data?.actuals ?? []);
      setLoadingGradesAndRejects(false);
    }, 10);
  };
  const activeTags = () => {
    return gradesAndRejectsTags?.filter((el: any) => el.active)?.length ?? 0;
  };
  useEffect(() => {
    setDisplayData(data?.actuals ?? []);
  }, [data]);
  return (
    <Box width="100%">
      <GradesAndRejectsHeader data={data} />
      {loadingGradesAndRejects ? (
        <Skeleton height={200} style={{ marginTop: '4px', marginBottom: '4px' }} />
      ) : (
        <>
          {!!data?.totalHarvest && activeTags() && !!yMax ? (
            <>
              <Box my="12px">
                <GraphKeys
                  showTooltip={true}
                  onClick={setStatus}
                  graphKeys={gradesAndRejectsTags}
                  leftOffset={'100'}
                  icon={Circle}
                  clickable
                ></GraphKeys>
              </Box>
              <Box style={{ display: 'flex', textAlign: 'center' }}>
                <Bar
                  theme={nivoTheme}
                  height={700}
                  width={100}
                  data={displayData}
                  layers={['axes']}
                  keys={getDurationKeys()}
                  margin={{
                    top: 10,
                    right: 0,
                    bottom: totalsDuration === 'daily' ? 56 : 60,
                    left: 100
                  }}
                  maxValue={yMax}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Stem count in thousands',
                    legendPosition: 'middle',
                    legendOffset: -80,
                    format: (value: number | undefined) =>
                      formatLeftAxisValue((value ? value / 1000 : 0) ?? 0)
                  }}
                  axisBottom={null}
                  fill={[
                    {
                      match: {
                        id: 'actual_stems'
                      },
                      id: 'dots'
                    }
                  ]}
                />
                <Box
                  style={{
                    height: 700,
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                    width: '100%'
                  }}
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                      height: '6px'
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '6px',
                      height: '12px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#D5D5D5',
                      borderRadius: '4px'
                    }
                  }}
                >
                  <CombinedBarAndLineChart
                    duration={totalsDuration}
                    data={displayData}
                    yMax={yMax ?? 0}
                    selectedYear={'2023'}
                    lineData={[]}
                    durationKeys={getDurationKeys()}
                    durationColors={getDurationColors()}
                    groupMode="stacked"
                    lineKey="rejected"
                    tooltip={CustomTooltip}
                    indexBy="period"
                  />
                </Box>
              </Box>
              {totalsDuration === 'daily' && (
                <Center>
                  <Text fontSize={20} color={'rgb(0, 0, 0)'}>
                    {`${reportFilterState?.years ?? today.split('-')[0]} ${getSubLabel()}` || (
                      <Skeleton count={1} />
                    )}
                  </Text>
                </Center>
              )}
            </>
          ) : (
            <Text align={'left'} mt="1rem">
              No data available for the selected filter parameters.
            </Text>
          )}
        </>
      )}
    </Box>
  );
}

export default GradesAndRejectsChart;
