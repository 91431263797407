import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().required('Variety name is required.'),
  frequency: yup.number().nullable().required('Frequency of harvest is required.').min(1),
  unit: yup.string().nullable().required('Unit is required.'),
  farms: yup.array().of(yup.string()).nullable().required('Farms grown is required.'),
  categories: yup
    .array()
    .of(yup.string())
    .nullable()
    .required('Grading categories are required.')
    .max(10, 'A maximum of 10 grading categories is allowed.'),
  orgs: yup.string().nullable().required('Organization is required.')
});

export default schema;
