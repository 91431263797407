import axios from 'axios';
import { User } from 'Context/types';
import AuthRepository from 'domain/AuthRepository';
import { ConfirmForgotPasswordResponse } from 'domain/models/ConfirmForgotPasswordResponse';
import { ForgotPasswordResponse } from 'domain/models/ForgotPasswordResponse';
import { GenericAuthResponse } from 'domain/models/GenericAuthResponse';
import { LoginResponse } from 'domain/models/LoginResponse';
import { RegisterResponse } from 'domain/models/RegisterResponse';

class AuthRepositoryImpl implements AuthRepository {
  private BASE_URL = `${process.env.REACT_APP_BE_URL}`;

  async confirmForgotPassword(
    email: string,
    code: string,
    new_password: string,
    confirmPassword: string
  ): Promise<ConfirmForgotPasswordResponse> {
    let response: ConfirmForgotPasswordResponse = {
      message: ''
    };
    const res = await axios
      .post(`${this.BASE_URL}/auth/confirm-forgot-password`, {
        email,
        code,
        new_password,
        new_password2: confirmPassword
      })
      .catch((error) => {
        response = {
          message: '',
          error: error.response?.data?.detail
        };
      });
    if (res && res.status === 200) {
      response = {
        message: res.data.message
      };
    }
    return response;
  }

  async forgotPassword(email: string): Promise<ForgotPasswordResponse> {
    let response: ForgotPasswordResponse = {
      message: ''
    };
    const res = await axios
      .post(`${this.BASE_URL}/auth/forgot-password`, { email })
      .catch((error) => {
        response = {
          message: '',
          error: error.response?.data?.detail
        };
      });
    if (res && res.status === 200) {
      response = {
        message: res.data.message
      };
    }
    return response;
  }

  async confirmRegister(email: string, code: string): Promise<GenericAuthResponse> {
    let hasError = false;
    let response;
    const res = await axios
      .post(`${this.BASE_URL}/auth/confirm-signup`, { email, code })
      .catch((error) => {
        hasError = true;
        response = error.response;
      });
    if (res && res.status === 200) {
      response = res;
    }
    return {
      hasError: hasError,
      response: response
    };
  }

  async register(
    full_name: string,
    email: string,
    password: string,
    confirmPassword: string,
    agreeToTerms: boolean
  ): Promise<RegisterResponse> {
    let response: RegisterResponse = {
      message: ''
    };
    const res = await axios
      .post(`${this.BASE_URL}/auth/signup`, {
        full_name: full_name,
        email: email,
        password: password,
        confirm_password: confirmPassword,
        agreeToTerms: agreeToTerms
      })
      .catch((error) => {
        response = {
          message: '',
          error: error.response?.data?.detail
        };
      });
    if (res && res.status === 200) {
      response = {
        message: res.data.message
      };
    }
    return response;
  }

  async logout(token: string): Promise<boolean> {
    let logoutSuccess = false;
    const response = await axios
      .post(
        `${this.BASE_URL}/auth/logout`,
        {},
        {
          headers: {
            Authorization: token
          }
        }
      )
      .catch(() => {
        logoutSuccess = false;
      });
    if (response && response.status === 200) {
      logoutSuccess = true;
    }
    return logoutSuccess;
  }

  async login(email: string, password: string): Promise<LoginResponse> {
    const defaultUser: User = {
      username: '',
      full_name: '',
      email: '',
      role: 'Member',
      is_active: false,
      user_group: null,
      tenant_header: null,
      onboarding_status: 'incomplete'
    };
    const defaultResponse = {
      id_token: '',
      refresh_token: '',
      access_token: '',
      user: defaultUser
    };
    let response: LoginResponse = defaultResponse;
    const res = await axios
      .post(`${this.BASE_URL}/auth/login`, { email, password })
      .catch((error) => {
        response = {
          ...defaultResponse,
          error: error.response?.data?.detail
        };
      });
    if (res && res.status === 200) {
      response = res.data;
    }
    return {
      id_token: response.id_token,
      refresh_token: response.refresh_token,
      access_token: response.access_token,
      user: response.user,
      error: response.error
    };
  }
}

export default AuthRepositoryImpl;
