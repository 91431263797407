import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email address!').required('Email is required.'),
  code: yup.string().required('Code is required.'),
  new_password: yup
    .string()
    .required('Password is required.')
    .min(8)
    .minLowercase(1, 'password must contain at least 1 lower case letter')
    .minUppercase(1, 'password must contain at least 1 upper case letter')
    .minNumbers(1, 'password must contain at least 1 number')
    .minSymbols(1, 'password must contain at least 1 special character'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required.')
    .oneOf([yup.ref('new_password'), null], 'Passwords must match')
});

export default schema;
